<template>
    <b-field    
        v-bind="{
            label: 'Email',
            type: needsValidation ? getValidateStyle : '',
            message: needsValidation ? getValidateMessage : '',
            ...$attrs.fieldAttrs,
        }"
    >
        <b-input 
            v-model="emailInput"
            v-bind="{
                placeholder: 'Email',
                minlength: 1,
                type: 'email',
                icon: 'email',
                iconRight: 'close-circle',
                iconRightClickable: true,
                ...$attrs,
            }"
            @keypress.native.enter="$emit('keypressEnter', $event)"
            @icon-right-click="clearEmail"
            @change.native="needsValidation=true"
        />
    </b-field>
</template>

<script>
import { isEmpty } from 'lodash';
import { validateEmail } from '../validation';

export default {
    inheritAttrs: false,
    name: 'AppInputFieldEmail',
    props: {
        label: String, // optional
        shouldValidate: Boolean,
        email: String | '',
    },
    emits: ['validEmail', 'update:email', 'cleared', 'keypressEnter'],
    data() {
        return {
            needsValidation: this.shouldValidate,
            emailInput: this.email,
            validated: false,
        }
    },
    computed: {
        getValidateStyle() {
            return this.validated ? 'is-success' : 'is-danger'
        },
        getValidateMessage() {
            return !this.validated ? 'Please enter a valid e-mail address.' : ''
        },
    },
    watch: {
        emailInput() {
            if (isEmpty(this.emailInput)) {
                this.clearEmail();
                return;
            }
            this.validated = validateEmail(this.emailInput);
            if (this.validated) {
                this.$emit('validEmail', this.emailInput);
                this.$emit('update:email', this.emailInput);
            } else {
                this.$emit('update:email', '');
            }
        },
    },
    methods: {
        validateEmail,
        clearEmail() {
            this.emailInput = '';
            this.needsValidation = false;
            this.$emit('cleared');
        },
    },
};
</script>
