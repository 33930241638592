var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",style:(_vm.windowWidth > 500 ? 'min-width: 500px;' : 'min-width: fit-content;'),on:{"close":function($event){return _vm.$emit('close')},"confirm":function($event){_vm.shouldSubmit = true}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Edit Auction ")]},proxy:true},{key:"body",fn:function(){return [_c('TheEditAuctionInput',_vm._b({on:{"validated":function($event){_vm.disableSubmit = $event},"toggleLoading":function($event){_vm.loadingSaveChanges = $event},"savedUpdates":function($event){_vm.$emit('savedUpdates', $event); _vm.$emit('close')}}},'TheEditAuctionInput',{
                triggerSubmit: _vm.shouldSubmit,
                ..._vm.$props,
            },false))]},proxy:true}])},'Modal',{
        isLoading: _vm.loadingSaveChanges,
        disableConfirm: _vm.disableSubmit,
        customLoading: true,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }