var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tooltip',_vm._b({staticClass:"custom-tooltip",attrs:{"append-to-body":""}},'b-tooltip',{
        position: 'is-right',
        type: 'is-light',
        multilined: true,
        ..._vm.$attrs,
    },false),[_vm._t("default",function(){return [_c('b-icon',_vm._b({},'b-icon',{
                icon: 'help-circle',
                size: 'mdi-18px',
                ..._vm.$attrs.iconAttrs,
            },false))]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }