import type { SRPDTO, StoresDTO } from '@/types';
import type { AxiosError } from 'axios';
import { fireEvent } from '@/segment';
import store from '@/vuex';
import { openErrorDialog } from './buefyUtils';

export function sortBidsByTime(bids) {
    return bids.sort((a,b) => {
        return b.created - a.created
    })
}

export function getPowerUserStoresFromStores(stores: StoresDTO[]): StoresDTO[] {
    const powerUserStores: StoresDTO[] = [];
    stores.forEach(store => {
        if (store.premiumStatuses?.some(status => status.value == 'PowerBuyer' || status.value == 'PowerSeller')) {
            powerUserStores.push(store);
        }
    });
    return powerUserStores;
}

export function isBuyItNowAvailable(details: {
    vehicleListingId?: number,
    buyItNowPrice?: number, 
    reservePrice?: number,
    startingPrice?: number,
    highestBid?: number,
    auctionEndDate?: Date, 
    dbCanBuyItNow?: boolean,
}): boolean {
    const { buyItNowPrice, startingPrice, reservePrice, highestBid, auctionEndDate, dbCanBuyItNow } = details;
    if (dbCanBuyItNow !== undefined && !dbCanBuyItNow) {
        return false;
    }

    if (startingPrice && reservePrice && (reservePrice - startingPrice < 500)) {
        return false;
    }


    if (buyItNowPrice) {
        // starting price >= BIN price
        if (startingPrice && (buyItNowPrice - startingPrice < 500)) {
            return false;
        }

        // highestBid >= BIN - 500
        if (highestBid && (highestBid >= buyItNowPrice - 500)) {
            return false;
        }

        if (highestBid && reservePrice && (highestBid >= reservePrice - 500)) {
            return false;
        }

        // Marketplace listing. Floor price >= BIN price
        if (!startingPrice && reservePrice && (reservePrice >= buyItNowPrice)) {
            return false;
        }
    }

    // within 30 min of auction end 
    if (auctionEndDate) {
        const now = new Date();
        const diffInMinutes = (new Date(auctionEndDate).getTime() - now.getTime()) / 60000;
        if (diffInMinutes <= 30) {
          return false;
        }
    }

    return true;
}

export function getBuyItNowUnavailableReason(details: {
    buyItNowPrice?: number, 
    reservePrice?: number,
    startingPrice?: number,
    highestBid?: number,
    auctionEndDate?: Date, 
    dbCanBuyItNow?: boolean,
}) {
    const { buyItNowPrice, startingPrice, reservePrice, highestBid, dbCanBuyItNow } = details;

    if (dbCanBuyItNow !== undefined && !dbCanBuyItNow) {
        return 'The Buy It Now option for this vehicle has been disabled';
    }

    if (startingPrice && reservePrice && (reservePrice - startingPrice < 500)) {
        return 'Reserve price must be at least $500 higher than the starting price for Buy It Now to be available';
    }

    if (buyItNowPrice) {
        // starting price >= BIN price
        if (startingPrice && (buyItNowPrice - startingPrice < 500)) {
            return 'The buy-it-now price must be at least $500 higher than the starting price';
        }

        // highestBid >= BIN - 500
        if (highestBid && (highestBid >= buyItNowPrice - 500)) {
            return 'The highest bid is within $500 of the buy-it-now price';
        }

        if (highestBid && reservePrice && (highestBid >= reservePrice - 500)) {
            return 'The highest bid is within $500 of the eserve price';
        }

        // Marketplace listing. Floor price >= BIN price
        if (!startingPrice && reservePrice && (reservePrice >= buyItNowPrice)) {
            return 'The buy-it-now price must be higher than the floor price';
        }
    }

    return '';
}

export function SrpDtoToListingCardDto(srpDTO: SRPDTO) {
    return {
        year: srpDTO.year,
        make: srpDTO.brand,
        model: srpDTO.model,
        trim: srpDTO.trim,
        vin: srpDTO.vin,
        city: srpDTO.city,
        state: srpDTO.state,
        heroPicUrl: srpDTO.heroImgUrl,
        vehicleListingId: srpDTO.id,
        marketplaceListingId: srpDTO.marketplaceListingId,
        miles: srpDTO.mileage,
        sellerPersonId: srpDTO.userProfile.id,
        reservePrice: srpDTO.auction.reservePrice,
        buyItNowPrice: srpDTO.auction.buyItNowPrice,
        currentOffer: srpDTO.currentOffer,
        watching: srpDTO.watching,
        marketplaceCanBuyItNow: srpDTO.marketplaceCanBuyItNow,
        inspectorPersonId: srpDTO.inspectorPersonId,
    };
}

export function isStoreCannotBidError(error: AxiosError) {
    return error.response?.data?.title?.includes('Store cannot bid');
}
export function isMarketplaceOfferError(error: AxiosError) {
    return error.response?.data?.title?.includes('Marketplace offer error');
}
export function isBiddingError(error: AxiosError) {
    return error.response?.data?.title?.includes('Bidding error');
}

export function showStoreCannotBidErrorDialog(error: AxiosError) {
    fireEvent('unconfigured_store_tried_bidding', {
        user_id: store.state.user.profile.id,
    });
    openErrorDialog({
        title: 'Oops.. You can\'t bid just yet.',
        message: error.response?.data?.message,
    });
}
