<template>
    <b-navbar-item 
        class="nav-item"
        tag="router-link"
        :to="{ path: toPath }"
        :class="{ 
            'display-flex': showAlertIcon,
            'flex-row align-center animate-icon-shake': icon,
            'is-poppins': true,
        }"
    >
        <slot>
          <b-icon v-if="icon" :icon="icon" custom-size="mdi-18px" />
          {{ label }}
          <AlertIcon
              v-if="showAlertIcon"
              v-bind="{
                ...$attrs.alertIconAttrs,
              }"
          />
        </slot>
    </b-navbar-item>
</template>

<script>
import AlertIcon from '../AlertIcon.vue';

export default {
    name: 'NavbarItem',
    props: {
        label: {
            type: String,
            required: true,
        },
        toPath: {
            type: String,
            required: false,
            default: '/home'
        },
        showAlertIcon: {
            type: Boolean,
            required: false,
            default: false,
        }, 
        icon: {
            type: String,
            required: false,
        },
    },
    components: {
        AlertIcon,
    },
}
</script>

<style scoped lang='postcss'>
.navbar-link {
  color: var(--primary);
}

.icon {
  font-size: 2rem;
}
.nav-item {
  & span {
    font-weight: 500 !important;

    &:hover {
        color: var(--primary-hover);
    }
  }

  & span.icon {
    color: inherit;
    margin-top: -10px;
    margin-left: 0;
    margin-right: 7px;
    margin-bottom: 2px;
  }

  &:hover {
    color: var(--primary-hover);
  }

  &:focus {
    color: var(--primary-dark);
  }

}

.icon {
  font-size: 2rem;
}
</style>
