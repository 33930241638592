
import { computed, ComputedRef, defineComponent, PropType, Ref, ref } from 'vue';
import { AnnouncementType, VehicleListingAnnouncement, VehicleListingAnnouncementCategorizedDTO } from '@/types';
import { getBackgroundColorByAnnouncementType, getIconColorByAnnouncementType, getMostSevereAnnouncementType, getMostSevereAnnouncementTypeFromList, orderAnnouncementsByType } from '@/utils';
import { groupBy, omit } from 'lodash';

import TheAnnouncement from './TheAnnouncement.vue';
import TheAnnouncementCategoryCard from './TheAnnouncementCategoryCard.vue';

export default defineComponent({
    name: 'TheVehicleListingAnnouncements',
    props: {
        announcements: {
            type: Object as PropType<VehicleListingAnnouncementCategorizedDTO>,
            required: false,
        },
        showAllAnnouncements: {
            type: Boolean,
            default: false,
        },
        isClickable: {
            type: Boolean,
            default: true,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TheAnnouncement,
        TheAnnouncementCategoryCard,
    },
    setup(props) {
        const announcementsRef: ComputedRef<VehicleListingAnnouncementCategorizedDTO | undefined> = computed(() => {
            if (!props.announcements) {
                return undefined;
            }
            return {
                ...(props.announcements as Object),
                unknown: (props.announcements as VehicleListingAnnouncementCategorizedDTO)?.unknown?.filter(announcement => !Boolean(announcement.href)),
            }
        });
        const announcementLinks: ComputedRef<VehicleListingAnnouncement[] | undefined> = computed(() => {
            return (props.announcements as VehicleListingAnnouncementCategorizedDTO)?.unknown?.filter(announcement => Boolean(announcement.href));
        });
        
        const hasUnknownAnnouncements = computed(() => Boolean(announcementsRef.value?.unknown?.length));
        const announcementsWithCategory = computed(() => omit(announcementsRef.value, 'unknown'));
        const getMostSevereIconColor: ComputedRef<string | undefined> = computed(() => {
            let mostSevereAnnouncementType = hasUnknownAnnouncements.value && !props.showAllAnnouncements
                ? getMostSevereAnnouncementTypeFromList(announcementsRef.value?.unknown)
                : getMostSevereAnnouncementType(announcementsRef.value);

            return getIconColorByAnnouncementType(mostSevereAnnouncementType);
        });

        const unknownAnnouncementsByType: ComputedRef<Record<AnnouncementType, VehicleListingAnnouncement[]>> = computed(() => {
            if (!announcementsRef.value?.unknown?.length || props.showAllAnnouncements) {
                return {} as Record<AnnouncementType, VehicleListingAnnouncement[]>;
            }
            return groupBy(orderAnnouncementsByType(announcementsRef.value?.unknown), 'announcementType') as Record<AnnouncementType, VehicleListingAnnouncement[]>;
        });
        
        return {
            orderAnnouncementsByType,
            announcementsRef,
            announcementLinks,
            hasUnknownAnnouncements,
            announcementsWithCategory,
            getMostSevereIconColor,
            unknownAnnouncementsByType,
            getBackgroundColorByAnnouncementType,
        };
    },
});
