<template>
    <b-field :label="label">
        <b-input 
            :type="type"
            v-model="inputValue"
            icon-right="close-circle"
            icon-right-clickable
            @icon-right-click="inputValue = null"
            @input="$emit('input', inputValue)"
            :placeholder="inputValue"
            :disabled="disabled"
        />
    </b-field>
</template>
<script>

// don't use this component anymore - use InputField with prop 'useCustomStyles' as true
export default {
    name: 'InputFieldUnstyled',
    props: {
        label: {
            type: String,
            required: false,
        },
        value: {
            type: String | Number,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        }
    },
    data() {
        return {
            inputValue: this.value,
        }
    },
}
</script>