
import { defineComponent, PropType } from 'vue';
import { HighestBidRefRecord } from '../types';
import { toCurrency } from '../utils';

import BidCurrent from './BidCurrent.vue';
import CountdownTimer from './CountdownTimer.vue';
import TheListingDetailsViewsAndCounts from './TheListingDetailsViewAndCounts.vue';

export default defineComponent({
    name: 'TheListingDetailsListingStats',
    props: {
        price: {
            type: Number,
            required: false,
        },
        priceLabel: {
            type: String,
            default: 'Current Bid',
        },
        views: {
            type: Number,
            default: 0,
        },
        bids: {
            type: Number,
            default: 0,
        },
        offers: {
            type: Number,
            required: false,
        },
        date: {
            type: Date,
            required: false,
        },
        highestBid: {
            type: Object as PropType<HighestBidRefRecord>,
            required: false,
        },
        orderBuyerPersonId: {
            type: Number,
            required: false,
        }
    },
    components: {
        BidCurrent,
        CountdownTimer,
        TheListingDetailsViewsAndCounts
    },
    setup(props, { emit, attrs }) {
        return {
            toCurrency,
        }
    },
});
