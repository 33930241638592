
import { computed, defineComponent, PropType, watch } from 'vue';
import { useCheckoutForm } from '../composables';
import { CheckoutForm, CheckoutFormData, CheckoutFormStep } from '../types';
import { capitalize, getDefaultArbitrationOption, orderAvailableCheckoutSteps, toCurrency } from '../utils';

import AppButton from './AppButton.vue';
import AccordionSection from './AccordionSection.vue';
import AppRadioButtonWithDescription from './AppRadioButtonWithDescription.vue';
import AppSwitch from './AppSwitch.vue';
import Modal from './Modal.vue';import TheCheckoutPaymentForm from './TheCheckoutPaymentForm.vue';

export default defineComponent({
    name: 'TheCheckoutModal',
    props: {
        vehicleName: {
            type: String,
            default: 'Car price'
        },
        checkoutData: {
            type: Object as PropType<CheckoutFormData>, 
            default: () => {
                return {
                    vehiclePrice: undefined,
                    buyerFee: 0,
                    photosPrice: 25,
                    floatFee: 0,
                    transportationCost: 0,
                    arbitrationOptions: [
                        getDefaultArbitrationOption('standard'),
                        getDefaultArbitrationOption('extended'),
                        getDefaultArbitrationOption('premium'),
                    ]
                }
            }
        },
        checkoutSteps: {
            type: Array as PropType<CheckoutFormStep[]>,
            default: () => {
                return [
                    'transportation',
                    'arbitrations',
                    'carmigoFloat',
                    'orderSummary',
                ]
            }
        },
        requiredFields: {
            type: Array as PropType<Array<keyof CheckoutForm>>,
            default: () => { return [] }
        },
    },
    components: {
        AppButton,
        AccordionSection,
        AppRadioButtonWithDescription,
        AppSwitch,
        Modal, 
        TheCheckoutPaymentForm,
    },
    setup(props) {
        // get the step numbers depending on what checkout steps are available
        let orderedCheckoutSteps = orderAvailableCheckoutSteps({
            availableCheckoutSteps: props.checkoutSteps,
            possibleCheckoutSteps: ['transportation', 'arbitrations', 'title', 'additionalDetails', 'orderSummary'],
            additionalDetailsSteps: ['carmigoFloat']
        });

        function getStepNumber(stepName: CheckoutFormStep) {
            return orderedCheckoutSteps.indexOf(stepName) + 1;
        }

        const { checkoutFormResponses, orderSummary, getSelectedArbitrationOption, disableSubmit } = useCheckoutForm({
            vehicleName: props.vehicleName,
            vehiclePrice: props.checkoutData.vehiclePrice,
            buyerFee: props.checkoutData.buyerFee,
            transportationCost: props.checkoutData.transportationCost,
            arbitrationOptions: props.checkoutData.arbitrationOptions,
            photosPrice: props.checkoutData.photosPrice,
            floatFee: props.checkoutData.floatFee,
            requiredFields: props.requiredFields,
        });

        const getAdditionalDetailsPrice = computed(() => {
            let selected = [];
            let price = 0;
            if (checkoutFormResponses.purchaseCarmigoPhotos) {
                selected.push('Photos');
                price += props.checkoutData.photosPrice;
            }
            if (checkoutFormResponses.useCarmigoFloat) {
                selected.push('Float fee');
                price += props.checkoutData.floatFee;
            }

            return selected.length ? `${selected.join(' & ')} - ${toCurrency(price)}` : ''; 
        });

        watch(() => checkoutFormResponses.useCarmigoTransportation, (value) => {
            // If the user opted-out of Carmigo transportation, set the arbitration plan to standard
            checkoutFormResponses.selectedArbitrationPlan = value ? undefined : 'standard';
        });

        return {
            capitalize,
            toCurrency,
            checkoutFormResponses,
            orderSummary,
            getStepNumber,
            getSelectedArbitrationOption,
            getAdditionalDetailsPrice,
            disableSubmit,
        }
    },
});
