var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",staticStyle:{"min-width":"400px"},on:{"close":function($event){return _vm.$emit('close')},"confirm":function($event){_vm.customConfirm ? _vm.$emit('confirm', { address: _vm.selectedAddress, placeId: _vm.addressPlaceId }) : _vm.saveAddress()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Add User's Address ")]},proxy:true},{key:"body",fn:function(){return [_c('TheAddressAutocompleteInput',{attrs:{"address":_vm.selectedAddress,"placeId":_vm.addressPlaceId,"appendToBody":""},on:{"update:address":function($event){_vm.selectedAddress=$event},"update:placeId":function($event){_vm.addressPlaceId=$event},"update:place-id":function($event){_vm.addressPlaceId=$event}}})]},proxy:true}])},'Modal',{
    customLoading: true,
    isLoading: _vm.loadingSaveAddress,
    confirmButtonAttrs: {
      loading: _vm.loadingSaveAddress,
      disabled: !_vm.selectedAddress,
    }
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }