var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loadingListing)?_c('AppListingDetailsLoading',_vm._b({},'AppListingDetailsLoading',_vm.$attrs,false)):_c('AppListingDetails',_vm._b({scopedSlots:_vm._u([{key:"sellerDetails",fn:function(){return [_c('div')]},proxy:true},{key:"sellerDetailsMobile",fn:function(){return [_c('div')]},proxy:true},{key:"listingStats",fn:function(){return [_c('div')]},proxy:true},{key:"bidHistory",fn:function(){return [_c('div')]},proxy:true},{key:"marketData",fn:function(){return [_c('div')]},proxy:true}])},'AppListingDetails',{
        listing: _vm.listing,
        vehiclePhotos: _vm.vehiclePhotos,
        announcements: _vm.announcements,
        loadingAnnouncements: _vm.loadingAnnouncements,
        bookoutOptions: _vm.bookoutOptions,
        loadingBookoutOptions: _vm.loadingBookoutOptions,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }