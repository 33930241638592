var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"confirm":_vm.toggleLoadingAndCreateCompany,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Add a Company ")]},proxy:true},{key:"body",fn:function(){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.toggleLoadingAndCreateCompany.apply(null, arguments)}}},[_c('AppInputFieldString',_vm._b({model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}},'AppInputFieldString',{
        fieldAttrs: { label: 'Company name '},
        placeholder: 'Company name',
        isClearable: true,
      },false))],1)]},proxy:true}])},'Modal',{
    isLoading: _vm.loadingCreateCompany,
    disableConfirm: !_vm.companyName,
    customLoading: true,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }