import { feedbackPropsDefault } from '@/types/feedbackPropsDefault';
import { FeedbackType } from '@/types/feedbackTypes';
import { updateEmailByPersonId, updatePhoneNumberByPersonId } from '../api';
import { undoCamelOrPascalCase } from './stringUtils';

export function updateFeedback(this: any, field: string, feedbackType: FeedbackType, message: string='') {
    this.feedback[field] = {
        ...feedbackPropsDefault[feedbackType],
        message
    }
}

export function clearFeedback(this: any, field?: string) {
    field
        ? this.feedback[field] = null
        : Object.keys(this.feedback).forEach(field => this.feedback[field] = null);
}

export function toggleKeys(this: any, fieldName?: string) {
    fieldName   
        ? this.keys[fieldName]++
        : Object.keys(this.keys).forEach(field => this.keys[field]++);
}

export function revertChanges(this: any, initEditableFields?: boolean) {
    if (initEditableFields) {
        this.initEditableFields();
    }
    this.clearFeedback();
    this.toggleKeys();
}

export function saveInputAndUpdateFeedback(
    this: any, 
    fieldName: string,
    updateFunction: Function,
    personId: number, 
    args: any, 
    getErrorMessage?: Function,
) {
    this.updateFeedback(fieldName, 'loading');
    return updateFunction(personId, args)
        .then((res: any) => {
            this.updateFeedback(fieldName, 'success');
            this.toggleKeys(fieldName);
            return res;
        }).catch((error: any) => {
            var errorMessage = '';
            if (getErrorMessage) {
                errorMessage = getErrorMessage(error); 
            }
            this.updateFeedback(fieldName, 'error', errorMessage);
            this.toggleKeys(fieldName);
        });
}

export function getPhoneNumberErrorMessage(error: any) {
    const violatesUniqueConstraint = error.response?.data?.title?.constraint == 'phone_number_unique';
    return violatesUniqueConstraint 
    ? 'Phone number is already in use by another account' 
    : 'Phone number is invalid or already in use';
}

export function getEmailErrorMessage(error: any) {
    const serverMessage = error.response?.data?.title?.message;
    return serverMessage ? serverMessage : 'Email is invalid or already in use'; 
}

export function saveEmailAndUpdateFeedback(this: any, personId: number, updatedEmail: string) {
    return this.saveInputAndUpdateFeedback('email', updateEmailByPersonId, personId, updatedEmail, getEmailErrorMessage);
}

export function savePhoneNumberAndUpdateFeedback(this: any, personId: number, updatedPhoneNumber: string) {
    return this.saveInputAndUpdateFeedback('phone', updatePhoneNumberByPersonId, personId, updatedPhoneNumber, getPhoneNumberErrorMessage);
}

/**
 * Used to display a failed API call's payload in the error dialog.
 * @param obj - any object
 * @returns an HTML string with the object's key-value pairs formatted as an unordered list. Keys and values are separated with a colon.
 */
export function formatErrorObj(
    obj: {[key: string]: any}, 
    { showKey=true }: { showKey?: boolean }={}
) {
    let str = '<ul>';
    Object.keys(obj).forEach(key => {
        let keyStr = showKey ? `${undoCamelOrPascalCase(key)}: ` : '';
        str += `<li>${keyStr}${obj[key]}</li>`;
    });
    str += '</ul>';
    return str;
}

export function formatCelebrateValidationError(error: any) {
    let errorBody = error?.response?.data;
    let invalidFieldsObj: {[key: string]: string} = {};
    if (errorBody?.message === 'celebrate request validation failed') {
        let validationMessage = errorBody.validation?.body?.message;
        let invalidFields = errorBody.validation?.body?.keys; // i think there's only ever one
        invalidFields?.forEach((field: string) => invalidFieldsObj[field] = validationMessage);
        return `
            <div>
                <p>One or more fields were invalid. Update fields and resubmit.</p>
                <div class="has-text-danger">${formatErrorObj(invalidFieldsObj)}</div>
            </div>`
    }
    return undefined;
}

export function formatEditVdpErrorMessage(error: any) {
    if (!error?.response?.data?.error?.message) {
        return undefined;
    }
    let errorText = '';
    if (error?.response?.data?.error?.message) {
        let errorFields = JSON.parse(error?.response?.data?.error?.message);
        if (errorFields) {
            Object.keys(errorFields).forEach(field => {
                errorText += `<li>${errorFields[field]}</li>`;
            });
        }
    }

    return errorText.length ? `<div><ul style="list-style-type: disc">${errorText}</ul></div>` : undefined;
}