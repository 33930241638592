<template>
  <Modal
    v-bind="{
      confirmButtonAttrs: {
        disabled: !companyUpdatedName,
        loading,
      },
      cancelButtonAttrs: {
        disabled: loading,
      },
    }"
    @confirm="updateCompany"
    @close="$emit('close')"
    class="carmigo-modal"
  >
    <template #header>
      Edit Company Name
    </template>

    <template #body>
      <form @submit.prevent="updateCompany">
        <AppInputFieldString 
          v-model="companyUpdatedName"
          v-bind="{
            fieldAttrs: { label: 'Company name' },
            placeholder: 'Company name',
            isClearable: true,
          }"
          @keypress.enter="updateCompany"
        />
      </form>
    </template>
  </Modal>
</template>

<script>
import { updateCompanyNameByCompanyId } from "../api";
import { openToast } from '../utils';

import AppInputFieldString from './AppInputFieldString.vue';
import Modal from './Modal.vue';

export default {
  name: "AddCompany",
  props: ['companyId', 'companyName', 'canCancel'],
  data() {
    return {
      companyUpdatedName: "",
      loading: false,
    }
  },
  components: {
    AppInputFieldString,
    Modal,
  },
  mounted() {
    this.companyUpdatedName = this.companyName;
  },
  methods: {
    updateCompany() {
      this.loading = true;
      updateCompanyNameByCompanyId(this.companyId, this.companyUpdatedName, {
        onSuccess: () => {
          this.loading = false;
          openToast('is-success', 'Company name updated!');
          this.$emit('close');
        },
        onError: () => {
          this.loading = false;
        }
      });
    },
  }
}
</script>
