var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppButton',_vm._b({on:{"click":_vm.openModal}},'AppButton',{
        label: 'View Bid History',
        type: 'is-primary',
        expanded: true,
        outlined: true,
        loading: _vm.getLoadingBidHistory,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }