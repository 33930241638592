
import { updateInspectionData } from '../../api';
import { openSubmitInspectionErrorDialog } from '../../utils';
import { PropType } from 'vue';
import { InspectionUpdateType } from '../../types';
import router from '@/router';

export default {
    name: 'InspectionForm',
    props: {
        allowEdits: {
            type: Boolean,
            default: true,
        },
        buttonAbsolute: {
            type: Boolean,
            default: false,
        },
        updateType: {
            type: String as PropType<InspectionUpdateType>
        },
        loading: {
            type: Boolean,
            default: false,
        },
        saved: {
            type: Boolean,
            default: false,
        },
        isEditing: {
            type: Boolean,
            default: false,
        },
        toggleIsEditingAfterSaving: {
            type: Boolean,
            default: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        }
    },
    components: {
    },
    data() {
        return {
            editingFields: this.isEditing,
            updatedData: {},
            loadingSave: this.loading,
            vehicleListingId: router.currentRoute.params?.vehicleListingId ?? router.currentRoute.params.id,
            updateKey: 0,
        }
    },
    mounted() {
        if (this.saved) {
            this.clearUpdatedData();
            this.updateKey++;
            this.editingFields = false;
            this.$emit('toggleEditData', this.editingFields);
            this.$emit('cleared');
        }
        if (this.isEditing){ 
            this.updateKey++;
        }
    },
    computed: {
        hasUpdatesToSave() {
            const updates = Object.keys(this.updatedData);
            return Boolean(updates.length);
        }
    },
    methods: {
        clearUpdatedData() {
            this.updatedData = {};
            this.$emit('clearedUpdates');
        },
        revertChangesAndToggleEditing() {
            this.clearUpdatedData();
            this.updateKey++;
            this.editingFields = !this.editingFields;
            this.$emit('toggleEditData', this.editingFields);
        },
        async saveEditsAndToggleEditing() {
            if (this.editingFields && this.hasUpdatesToSave) {
                await this.saveEdits();
                this.clearUpdatedData();
            } 
            if (this.toggleIsEditingAfterSaving) {
                this.editingFields = !this.editingFields;
                this.$emit('toggleEditData', this.editingFields);
            }
        },
        async saveEdits() {
            this.loadingSave = true;
            
            await updateInspectionData(this.vehicleListingId, this.updatedData, this.updateType, this.marketplaceListingId)
                .then((res) => {
                    this.$emit('saved', res, this.updateType);
                    this.loadingSave = false;
                }).catch((error) => {
                    let invalidFieldsObj = openSubmitInspectionErrorDialog(error.response?.data);
                    if (invalidFieldsObj) {
                        this.$emit('invalidFields', invalidFieldsObj);
                    }
                    this.loadingSave = false;
                });
        },
        updateInspectionData(updatedValues, replaceValue=false) {
            this.updatedData = replaceValue ? updatedValues
                : {
                    ...this.updatedData,
                    ...updatedValues
                };
            this.$emit('updatedData', this.updatedData, this.updateType);
        }   
    },
}
