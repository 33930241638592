
import Vue, { defineComponent, onMounted, PropType } from 'vue';
import { getIconColorByAnnouncementType, insertHyperlinkElement } from '../utils';
import { VehicleListingAnnouncement } from '@/types';

export default defineComponent({
    name: 'TheAnnouncement',
    props: {
        announcement: {
            type: Object as PropType<VehicleListingAnnouncement>,
            required: true,
        },
        isClickable: {
            type: Boolean,
            default: false,
        },
        clickToViewPhoto: {
            type: Boolean,
            default: true,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click', 'edit', 'delete'],
    setup(props, { emit, attrs }) {
        onMounted(() => {
            Vue.nextTick(() => {
                if (props.announcement.href) {
                    insertHyperlinkElement({
                        querySelector: `#comment-with-link-${attrs.id}`,
                        displayText: props.announcement.announcement,
                        href: props.announcement.href,
                    });
                }
            })
        });
        
        return {
            getIconColorByAnnouncementType,
        }
    },
});
