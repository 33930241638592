<template>
    <countdown
        v-if="countdown && date1"
        :time="countdown"
        :transform="transform"
        @progress="handleCountdownProgress"
    >
        <template slot-scope="props">
            <div
                class="is-poppins time-remaining"
                :class="timerColor" 
                :key="colorKey"
            >
                <slot name="countdown" v-bind:timeRemaining="props">
                    <div v-if="!showStacked">
                        <span v-if="showDaysLeft">{{ props.days | dayOrDays }}</span> {{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}
                    </div>

                    <div v-else class="flex-row justify-center">
                        <CountAndLabel 
                            class="px-3 text-center"
                            v-for="item in [
                                { label: 'day', count: props.days },
                                { label: 'hr', count: props.hours },
                                { label: 'min', count: props.minutes },
                                { label: 'sec', count: props.seconds },
                            ]"
                            :key="`count-and-label-${item.label}`"
                            :number="item.count"
                            :label="item.label"
                            numberColor="has-text-dark has-text-weight-semibold"
                            labelColor="has-text-primary has-text-weight-medium"
                        />
                    </div>
                </slot>
            </div>
        </template>
    </countdown>
    <div v-else-if="showExpiredDate">
        <strong class="has-text-danger">Past Due</strong>
    </div>
    <strong v-else :class="color ? color : ''">--:--</strong>
</template>

<script>
import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds/index.js';
Vue.component(VueCountdown.name, VueCountdown);
import CountAndLabel from './CountAndLabel.vue';

import { 
    getServerTimeStamp, 
    makePluralIfPlural, 
    serverSyncedNewDate, 
    setUtcTime, 
    formatAMPM 
} from '../utils';

export default {
    name: 'CountdownTimer',
    props: {
        date1: {
            type: Date | String,
            required: true,
        },
        date2: {
            type: Date | String,
            required: false,
        },
        checkTimeRemaining: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
            default: 'black'
        },
        showDaysLeft: {
            type: Boolean,
            required: false,
            default: true,
        },
        showExpiredDate: {
            type: Boolean,
            default: false,
        },
        showRedAtSecondsLeft: {
            type: Number,
            required: false,
            default: 60
        },
        showStacked: {
            type: Boolean,
            default: false,
        },
        shouldFetchServerTime: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    components: {
        CountAndLabel,
    },
    data() {
        return {
            timerColor: this.color,
            colorKey: 0,
        }
    },
    filters: {
        formatAMPM,
        dayOrDays(value) {
            const dayValue = Number(value);
            if (dayValue === 0) {
                return '';
            }
            if (dayValue === 1) {
                return `${dayValue} day`;
            }
            return `${dayValue} days`;
        },
    },
    mounted() {
        if (this.shouldFetchServerTime) {
            this.getServerTimeStamp();
        }
    },
    computed: {
        countdown() {
            if (!this.date1) {
                return false;
            }
            const date2 = this.date2 ? new Date(this.date2) : this.serverSyncedNewDate();

            const date1ToDate2 = differenceInMilliseconds(
                new Date(this.date1),
                date2,
            );
            if (date1ToDate2 > 0) {
                return date1ToDate2;
            }
            this.$emit('expired');
            return null;
        }
    },
    methods: {
        getServerTimeStamp,
        makePluralIfPlural,
        serverSyncedNewDate,
        setUtcTime,

        transform(props) {
            if (this.checkTimeRemaining) {
                const date2 = this.date2 ? new Date(this.date2) : this.serverSyncedNewDate();
    
                const date1ToDate2 = differenceInMilliseconds(
                    new Date(this.date1),
                    date2,
                ) / 60000;
    
                if (date1ToDate2 <= 30) {
                    this.$emit('thirtyMinRemaining');
                } 
            }
            Object.entries(props).forEach(([key, value]) => {
                const digits = value < 10 ? `0${value}` : value;
                props[key] = `${digits}`;
            });

            return props;
        },
        handleCountdownProgress(data) {
            if (data.totalSeconds <= 0) {
                this.$emit('expired');
            }

            if (data.totalSeconds >= this.showRedAtSecondsLeft) {
                this.timerColor = this.color;
                this.colorKey++;
                return;
            }

            if (data.totalSeconds < this.showRedAtSecondsLeft) {
                this.timerColor = 'red';
                this.colorKey++;
                return;
            }
        }
    },
}
</script>

<style scoped lang='postcss'>
.time-remaining {
  font-weight: 500;
  color: var(--dark);

  &.green {
      color: var(--primary);
  }

  &.red {
      color: var(--red);
  }
}
</style>
