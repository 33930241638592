<template>
  <div class="copy-text-button no-border" @click.stop="copy">
    <div  :class="color" class="vertical-align">
      <b-icon 
        v-bind="{
          icon: 'content-copy',
          size: 'is-small',
          ...$attrs.iconAttrs,
        }"
      ></b-icon>
      <p
        class="copy-text" 
        id="copyText" 
        :class="{ 'hide': iconOnly }"
      >
        {{ displayTextUpdated }}
      </p>
    </div>
  </div>
  
</template>

<script>
import { openToast } from "../utils"
export default {
  name: "CopyTextButton",
  props: [
    "iconOnly", // boolean
    "copyText", 
    "displayText",
    "color",
  ],
  data() {
    return {
      displayTextUpdated: this.displayText ? this.displayText : this.copyText,
    }
  },
  methods: {
    openToast,
    copy() {
      try {
        const textArea = document.createElement("textArea")
        textArea.value = this.copyText
        document.body.appendChild(textArea)

        textArea.select();
        document.execCommand("copy");
        textArea.remove();

        this.openToast('is-success', "Copied to clipboard!")
      } catch (err) {
        console.log(err)
        this.openToast('is-danger', "Unable to copy")
      }
    },
  }
}
</script>

<style scoped lang="postcss">
.vertical-align {
  display: flex;
  align-items: center;
}

.copy-text-button {
  border: none;
  padding: none;
  margin: 0 !important;
  color: var(--dark);
  
  &:hover {
    color: var(--primary);
  }
}

.copy-text {
  font-size: .8rem;
}

.hide {
  display: none !important;
}
</style>
