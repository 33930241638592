
import { defineComponent, getCurrentInstance, ref } from 'vue';
import { openModal } from '../utils';

import AppButtonWithSecondaryInfo from './AppButtonWithSecondaryInfo.vue';
import TheMarketplaceListingOffersModal from './TheMarketplaceListingOffersModal.vue';

export default defineComponent({
    name: 'TheMarketplaceManageOffersButton',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: true,
        },
        marketplaceOfferCount: {
            type: Number,
            required: false,
        },
        isSecondChance: {
            type: Boolean,
            default: false,
        },
        isReadOnly: {
            type: Boolean,
            required: false,
        },
    },
    components: {
        AppButtonWithSecondaryInfo,
    },
    setup(props) {
        const instance = getCurrentInstance();

        function openMarketplaceOffersModal() {
            openModal({
                // This proxy acts like `this` in Options API so store can be accessed inside the modal.
                parent: instance?.proxy,
                component: TheMarketplaceListingOffersModal,
                props,
            });
        }

        return {
            openMarketplaceOffersModal,
        };
    },
});
