
import { defineComponent, ref } from 'vue';
import { useEditableFields } from '../composables';

import EditableField from './Forms/EditableField.vue';
import TheEditableVehicleName from './TheEditableVehicleName.vue';
import TheListingDetailsVehicleBasics from './TheListingDetailsVehicleBasics.vue';

export default defineComponent({
    name: 'TheListingDetailsVehicleBasicsEditable',
    props: {
        isEditingMode: {
            type: Boolean,
            default: false,
        },
        year: {
            type: Number,
            required: false,
        },
        make: {
            type: String,
            required: false,
        },
        model: {
            type: String,
            required: false,
        },
        trim: {
            type: String,
            required: false,
        },
        vin: {
            type: String,
            required: false,
        },
        miles: {
            type: Number,
            required: false,
        },
        city: {
            type: String,
            required: false,
        },
        state: {
            type: String,
            required: false,
        },
        zip: {
            type: String,
            required: false,
        },
        estimatedShippingInDollars: {
            type: Number,
            required: false,
        },
    },
    components: {
        EditableField,
        TheEditableVehicleName,
        TheListingDetailsVehicleBasics,
    },
    emits: ['input'],
    setup(props, context) {
        const updatedFields = ref({
            year: props.year.toString(),
            make: props.make,
            model: props.model,
            trim: props.trim,
            vin: props.vin,
            mileage: props.miles,
            zip: props.zip,
        });

        const { unsavedUpdates, addToUnsavedUpdates, emitUpdates } = useEditableFields({ context });
 
        function emitUpdatedValues(updates) {
            addToUnsavedUpdates(updates);
            updatedFields.value = {
                ...updatedFields.value,
                ...updates,
            }
            emitUpdates(unsavedUpdates.value);
        }

        return {
            updatedFields,
            emitUpdatedValues,
        }
    },
})
