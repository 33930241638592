var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.displayComponent)?_c('div',{staticClass:"overdrive-container",class:{ 'level-failed': _vm.displayFailedOverdriveText},style:({ 'transitionDelay': _vm.failedTextBottomTransitionDelay })},[_c('transition',{attrs:{"name":_vm.transitionType,"mode":"out-in"}},[_c(_vm.displayComponent,_vm._b({key:`overdrive-${_vm.levelKey}`,tag:"component"},'component',{
                ..._vm.overdrive,
                currentLevel: _vm.displayLevel,
                playAudioCue: _vm.shouldPlayAudioCue,
                throwConfetti: _vm.throwConfettiOnCompleteOverdrive,
                ..._vm.$attrs,
            },false))],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }