
import { defineComponent, PropType, Ref, ref } from 'vue';
import { formatDeliveryStatusForDisplay, formatTitleStatusForDisplay } from '../utils';
import { DeliveryStatus, FixMe, TitleStatus } from '../types';
import { useCancelToken, useFetchDeliveryStatus, useFetchTitleStatus } from '../composables';

import AppStatusIcon from './AppStatusIcon.vue';

export default defineComponent({
    name: 'TheTitleAndDeliveryStatusIcons',
    props: {
        vehicleListingId: {
            type: Number,
            required: false
        }, 
        titleStatus: {
            type: String as PropType<TitleStatus>,
            required: false,
        },
        deliveryStatus: {
            type: String as PropType<DeliveryStatus>,
            required: false
        },
        facilitatingAuctionStoreName: {
            type: String,
            required: false,
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppStatusIcon,
    },
    setup(props) {
        const titleStatusFormatted: Ref<FixMe> = ref(formatTitleStatusForDisplay(props.titleStatus));
        const deliveryStatusFormatted: Ref<FixMe>= ref(formatDeliveryStatusForDisplay(props.deliveryStatus));

        const { cancelToken: titleStatusCancelToken, createNewCancelToken: createNewTitleStatusCancelToken } = useCancelToken();
        if (!props.titleStatus && props.vehicleListingId) {
            createNewTitleStatusCancelToken();
            useFetchTitleStatus(props.vehicleListingId, {
                onSuccess: (response, data) => {
                    titleStatusFormatted.value = formatTitleStatusForDisplay(data.value);
                },
                cancelToken: titleStatusCancelToken.value?.token,
            });
        }

        const { cancelToken: deliveryStatusCancelToken, createNewCancelToken: createNewDeliveryStatusCancelToken } = useCancelToken();
        if (!props.deliveryStatus && props.vehicleListingId) {
            createNewDeliveryStatusCancelToken();
            useFetchDeliveryStatus(props.vehicleListingId, {
                onSuccess: (response, data) => {
                    deliveryStatusFormatted.value = formatDeliveryStatusForDisplay(data.value);
                },
                cancelToken: deliveryStatusCancelToken.value?.token,
            });
        }

        return {
            titleStatusFormatted,
            deliveryStatusFormatted,
        }
    },
});
