
import { defineComponent, PropType } from 'vue';
import { useAuctionVDP, useCurrentNegotiationOffer, useSingleAuctionListener, useUpdateAuctionListingCardOnRdbChange } from '../composables';
import { SRPListing } from '../types';
import { isVehicleAuctioned, isVehicleSold, toCurrency } from '../utils';
import { pick } from 'lodash';

import AppListingDetails from '../components/AppListingDetails.vue';
import TheNegotiateButtonAuction from '../components/TheNegotiateButtonAuction.vue';
import ViewBidHistoryButton from '../components/Buttons/ViewBidHistoryButton.vue';
import ViewOrderSummaryButton from '../components/Buttons/ViewOrderSummaryButton.vue';

export default defineComponent({
    name: 'AppListingDetailsAdmin',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
    },
    components: {
        AppListingDetails,
        TheNegotiateButtonAuction,
        ViewBidHistoryButton,
        ViewOrderSummaryButton,
    },
    setup(props, { emit, attrs }) {
        const { highestBid, updateAuction } = useUpdateAuctionListingCardOnRdbChange();

        const { 
            vehiclePhotos,
            loadingVehiclePhotos,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            bidHistory,
            loadingBidHistory,
            fetchBidHistoryOnRdbChange,
            order,
            loadingOrder,
            titleStatus,
            deliveryStatus,
            purchasedVehicle,
            marketData,
            loadingMarketData,
            averageMarketDataCost,
        } = useAuctionVDP({ 
            vehicleListingId: props.listing.id,
            vehicleDetails: {
                year: props.listing.year.toString(),
                make: props.listing.brand,
                ...pick(props.listing, ['model', 'trim', 'vin']),
            }
        });

        useSingleAuctionListener(props.listing.id!, async (snapshot) => {
            updateAuction(snapshot);
            fetchBidHistoryOnRdbChange(snapshot);
            if (snapshot.newSnapshotValue?.status == 'Negotiating') {
                getUpdatedNegotiationOffer(props.listing.id);
            }
        });

        const {
            negotiationOffer,
            loadingNegotiationOffer,
            getUpdatedNegotiationOffer,
        } = useCurrentNegotiationOffer();

        return {
            isVehicleAuctioned,
            isVehicleSold,
            toCurrency,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            bidHistory,
            loadingBidHistory,
            vehiclePhotos,
            loadingVehiclePhotos,
            order,
            loadingOrder,
            titleStatus,
            deliveryStatus,
            highestBid,
            purchasedVehicle,
            marketData,
            loadingMarketData,
            averageMarketDataCost,
            negotiationOffer,
            loadingNegotiationOffer,
        }
    },
});
