<template>
  <section id="drag-and-drop-container" class="flex-column">
    <b-field 
      v-bind="{
        label: 'Attachments', 
        ...$attrs.fieldAttrs
      }"
      class="flex-column"
      :class="{ 'has-attachments': returnedAttachments.length }"
    >
      <slot name="instructions"></slot>
      <b-upload
        v-model="newAttachments"
        v-bind="{
          multiple: true,
          dragDrop: true,
          ...$attrs,
        }"
        class="justify-center"
        @input="attach"
      >
        <slot name="uploadInput">
          <div class="content has-text-centered">
            <b-icon icon="upload" :size="$attrs.size ?? 'is-large'" />
            <p :class="$attrs.size">Drop your files here or click to upload</p>
          </div>
        </slot>
      </b-upload>
    </b-field>
    <div v-if="returnedAttachments.length" class="added-attachments" :class="'flex-column'">
      <p class="num-attachments margin-1-em">{{ returnedAttachments.length }} attachments</p>
      <div class="attachments" :class="'flex-' + getAlignment">
        <AddedAttachment
          v-for="(file, index) in returnedAttachments"
          :key="index"
          :imageUrl="file.url"
          :fileName="file.filename"
          :fileType="file.fileType"
          :canDelete="true"
          :alignment="alignment"
          :imgSize="getImgSize"
          :deleteAttachment="deleteAttachment"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AddedAttachment from "./AddedAttachment.vue";
import { generateImageUrl, uploadAttachments, deleteAttachment } from "../../utils";
import { openToast } from "../../utils";
export default {
  name: "DragAndDropAttachments",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    returnAttached: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    attachmentsLoading: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    alignment: {
      type: String,
      default: 'column',
    }
  },
  components: {
    AddedAttachment,
  },
  data() {
    return {
      newAttachments: [],
      returnedAttachments: [],
    };
  },
  computed: {
    getAlignment() {
      if (this.alignment === "row") return "column align-center justify-end";
      if (this.alignment === "column") return "row";
    },
    getIconSize() {
      switch (this.$attrs.size) {
        case "is-medium":
          return "mdi-24px";
        case "is-large":
        default:
          return "large";
      }
    },
    getImgSize() {
      switch (this.$attrs.size) {
        case "small": 
          return "extra-small";
        case "is-medium":
          return "extra-small";
        case "is-large":
        default:
          return "small";
      }
    },
  },
  emits: ['update:loading', 'attached'],
  methods: {
    openToast,
    generateImageUrl,
    async attach() {
      this.$emit('update:loading', true);
      this.attachmentsLoading(true)
      try{
        await this.uploadAttachments(this.newAttachments)
      }catch(error){
        this.openToast('is-danger', error.message)
        this.$emit('update:loading', false);
        this.attachmentsLoading(false)
      }
      this.newAttachments = [];
      // call attachmentsLoading(false) from receiving view, after attachment images load
    },

    uploadAttachments,
    deleteAttachment,
  },
};
</script>

<style scoped lang="postcss">
#drag-and-drop-container {
  &.flex-row {
    align-items: flex-end !important;
  }
}
.is-medium {
  font-size: 0.75rem;
}

.is-large {
  font-size: 1rem;
}

.upload {
  /* margin: 1rem .5rem .75rem; */

  & .upload-text {
    padding: 1em;
  }
}

.added-attachments {
  margin: 0 .25rem;

  & .attachments {
    align-self: center;
  }

  & .flex-column {
    &.attachments {
      height: 100px;
      overflow: hidden auto;
    }
  }

  & .flex-row {
    &.attachments {
      max-width: 60%;
      overflow: auto hidden;
    }
  }

}

.num-attachments {
  font-size: .75rem;
  margin-top: .5em;
  color: #52aa57;
  font-style: italic;
  text-align: center;
}

:deep(.field.has-addons) {
  display: flex;
  flex-direction: column;
}
</style>
