
import { computed, defineComponent, onMounted, ref } from "vue";
import { GET, POST, PUT } from "../api";
import { negotiationModalWithMessagesProps } from "../types";
import { capitalize, isStoreCannotBidError, openErrorDialog, openToast, showStoreCannotBidErrorDialog, toCurrency } from '../utils';
import store from '@/vuex';

import InfoHeader from "./Arbitrations/InfoHeader.vue";
import InfoHighlight from "./Arbitrations/InfoHighlight.vue";
import TheNegotiationModalWithMessages from "./TheNegotiationModalWithMessages.vue";
import { MarketPlaceOffererTypeEnum } from "../enums";

export default defineComponent({
    name: 'TheNegotiationModalMarketplace',
    props: {
        ...negotiationModalWithMessagesProps,
        buyItNowPrice: {
            type: Number,
            required: true,
        },
        reservePrice: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: true,
        },
        marketplaceOfferId: {
            type: Number,
            required: true,
        },
        storeId: {
            type: Number,
            required: false,
        },
        isSecondChance: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        InfoHeader,
        InfoHighlight,
        TheNegotiationModalWithMessages,
    },
    setup(props, context) {
        const loadingSubmitCounterOffer = ref(false);
        const loadingRejectOffer = ref(false);
        const loadingAcceptOffer = ref(false);
        const personId = store.state.user.profile.id;
        const marketplaceOffererTypeId = MarketPlaceOffererTypeEnum[capitalize(props.negotiatingUser)];

        const disableAcceptOffer = computed(() => {
            if (!props.buyerOfferAmount && marketplaceOffererTypeId === MarketPlaceOffererTypeEnum.Seller) {
                return true;
            }
            if (!props.sellerOfferAmount && marketplaceOffererTypeId === MarketPlaceOffererTypeEnum.Buyer) {
                return true;
            }

            return false;
        });

        const allowCounterOffer = computed(() => {
            if (!props.isSecondChance) {
                return true;
            }

            // if second chance & buyer offer is higher than seller offer (or reserve), don't allow counter offers
            if (props.sellerOfferAmount) {
                return props.buyerOfferAmount < props.sellerOfferAmount;
            }
            return props.buyerOfferAmount < props.reservePrice;
        });

        async function acceptOffer() {
            loadingAcceptOffer.value = true;
            await PUT(`/marketplace/acceptMarketplaceOfferAmount`, {
                marketplaceOfferId: props.marketplaceOfferId,
                negotiatingUser: props.negotiatingUser,
            }).then(res => {
                loadingAcceptOffer.value = false;
                context.emit('acceptedOffer');
                context.emit('close');
                openToast('is-success', 'Offer accepted!');
            }).catch(error => {
                loadingAcceptOffer.value = false;
                openErrorDialog({
                    title: 'Could not accept offer',
                    message: `We encountered an error while accepting offer ${props.marketplaceOfferId} for ${props.negotiatingUser} ${personId}`,
                    error,
                });
            });
        }

        async function rejectOffer(info) {
            loadingRejectOffer.value = true;
            await POST(`/marketplace/rejectMarketplaceOfferAmount`, {
                marketplaceListingId: props.marketplaceListingId,
                marketplaceOfferId: props.marketplaceOfferId,
                marketplaceOffererTypeId,
            }).then(res => {
                loadingRejectOffer.value = false;
                context.emit('rejectedOffer');
                context.emit('close');
                openToast('is-success', 'Offer rejected');
            }).catch(error => {
                loadingRejectOffer.value = false;
                openErrorDialog({
                    title: 'Could not reject offer',
                    message: `We encountered an error while rejecting offer ${props.marketplaceOfferId} for ${props.negotiatingUser} ${personId}`,
                    error,
                });
            });
        }

        async function submitCounterOffer(counterOfferAmount, message) {
            loadingSubmitCounterOffer.value = true;
            await POST(`/marketplace/createMarketplaceOfferAmount`, {
                marketplaceListingId: props.marketplaceListingId,
                marketplaceOfferId: props.marketplaceOfferId,
                marketplaceOffererTypeId,
                storeId: props.storeId,
                offerAmount: {
                    amount: counterOfferAmount,
                    message: message ? message : undefined,
                }
            }).then(() => {
                context.emit('counterOfferMade');
                context.emit('close');
                openToast('is-success', 'Offer made!');
            }).catch((error) => {
                const isStoreCannotBid = isStoreCannotBidError(error);

                if (isStoreCannotBid) {
                    showStoreCannotBidErrorDialog(error);
                    return;
                }

                openErrorDialog({
                    title: 'Could not make offer',
                    message: `We encountered an error while making an offer of ${toCurrency(counterOfferAmount)} on vehicle ${props.vehicleListingId} for ${props.negotiatingUser} ${personId}`,
                    error,
                });
            }).finally(() => {
                loadingSubmitCounterOffer.value = false;
            });
        }

        const loadingMessages = ref(false);
        const negotiationMessages = ref(props.allMessages ? props.allMessages: []);
        onMounted(() => {
            if (!props.allMessages) {
                getAllNegotiationMessages();
            }
        });
        async function getAllNegotiationMessages() {
            loadingMessages.value = true;
            negotiationMessages.value = await GET(`/marketplace/getMarketplaceListing/offerMessages/${props.marketplaceOfferId}`)
                .then(res => {
                    loadingMessages.value = false;
                    return res.data;
                }).catch(error => {
                    openErrorDialog({
                        title: 'Failed to load negotiation messages',
                        message: `We encountered an error while fetching the negotiation messages for marketplace offer ${props.marketplaceOfferId}`,
                        error,
                    });
                });
        }

        return {
            loadingSubmitCounterOffer,
            loadingAcceptOffer,
            loadingRejectOffer,
            loadingMessages,
            negotiationMessages,
            submitCounterOffer,
            acceptOffer,
            rejectOffer,
            toCurrency,
            disableAcceptOffer,
            allowCounterOffer,
        }
    }
})
