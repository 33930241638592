import { render, staticRenderFns } from "./AuctionStat.vue?vue&type=template&id=1f778bdf&scoped=true&"
import script from "./AuctionStat.vue?vue&type=script&lang=ts&"
export * from "./AuctionStat.vue?vue&type=script&lang=ts&"
import style0 from "./AuctionStat.vue?vue&type=style&index=0&id=1f778bdf&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f778bdf",
  null
  
)

export default component.exports