
import { defineComponent, PropType } from 'vue';
import { NotificationSettingsDTO } from '../types';

import AppLoading from './AppLoading.vue';
import TheNotificationSetting from './TheNotificationSetting.vue';

export default defineComponent({
    name: 'AppNotificationSettings',
    props: {
        notificationSettings: {
            type: Array as PropType<NotificationSettingsDTO[]>,
            required: true,
        },
    },
    components: {
        AppLoading,
        TheNotificationSetting,
    },
});
