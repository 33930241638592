export enum MessageModality {
  email = 1,
  sms = 2,
  snackbar = 3,
  invisible = 4,
  toast = 5,
  message = 6,
  notification = 7
}

// keeping this here so we remember to update it if in-app notifs are updated
export const InAppNotificationModalityIds = [3, 4, 5, 6, 7];
