
import { defineComponent, ref, Ref } from 'vue';
import { createCompany } from "../api";
import { openToast } from '../utils';

import AppInputFieldString from "./AppInputFieldString.vue";
import Modal from "./Modal.vue";

export default defineComponent({
  name: "TheCreateCompanyModal",
  components: {
    AppInputFieldString,
    Modal,
  },
  emits: ['close', 'success'],
  setup(props, context) {
    const loadingCreateCompany = ref(false);
    const companyName: Ref<string | undefined> = ref(undefined);

    async function toggleLoadingAndCreateCompany() {
      loadingCreateCompany.value = true;
      await createCompany({ company: companyName.value }, {
        onSuccess: () => {
          loadingCreateCompany.value = false;
          openToast('is-success', 'New company created!');
          context.emit('success', companyName.value);
          context.emit('close');
        },
        onError: () => {
          loadingCreateCompany.value = false;
        }
      });
    }


    return {
      companyName,
      loadingCreateCompany,
      toggleLoadingAndCreateCompany,
    }
  },
});
