var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"gallery-container"},[_c('draggable',{attrs:{"move":_vm.isMoveAllowed},on:{"start":function($event){_vm.isDragging = true},"end":function($event){_vm.isDragging = false},"input":function($event){return _vm.$emit('update:photos', _vm.photosUpdated);}},model:{value:(_vm.photosUpdated),callback:function ($$v) {_vm.photosUpdated=$$v},expression:"photosUpdated"}},[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.photosUpdated),function(photo,photoIdx){return _c('figure',{key:`${_vm.name}-element-${photo.filename}`,staticClass:"position-relative",class:{ 
                    'is-clickable': _vm.isClickable,
                    'is-highlighted': _vm.highlightPhotos?.includes(photo.filename),
                    'is-selected': _vm.isPhotoSelected(photo),
                },on:{"click":function($event){return _vm.selectPhoto(photo)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectPhoto(photo)}}},[_c('div',{staticClass:"icon-container top"},[(_vm.isPhotoSelected(photo))?_c('b-icon',_vm._b({},'b-icon',{
                            icon: 'check-circle',
                            type: 'is-success',
                        },false)):_vm._e()],1),_c('div',{staticClass:"icon-container bottom"},[_c('p',{staticClass:"photo-idx"},[_vm._v(_vm._s(photoIdx + 1))])]),_c('img',{staticClass:"gallery-img",attrs:{"src":photo?.url}}),_vm._t("caption",null,null,{ photo, isSelected: _vm.isPhotoSelected(photo) })],2)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }