
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'UserMenuIcon',
    props: {
        icon: {
            type: String,
            default: 'menu',
            required: false,
        },
        text: {
            type: String,
            required: true,
        },
    },
});
