var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppListingDetailsEditable',_vm._b({key:`seller-vdp-${_vm.listingKey}`,class:{
        'pricing-row': _vm.windowWidth > 1000 || (_vm.windowWidth < 768 && _vm.windowWidth > 600),
        'pricing-two-columns': _vm.windowWidth <= 1000,
    },on:{"input":_vm.addToUnsavedUpdates,"updateVehiclePhotos":_vm.updateListingVehiclePhotos},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('AppButton',_vm._b({on:{"click":_vm.saveUpdatesAndRedirect}},'AppButton',{
                label: 'Submit Listing',
                iconLeft: 'check-all',
                type: 'is-primary',
                expanded: true,
                loading: _vm.loadingSaveUpdates,
            },false))]},proxy:true}])},'AppListingDetailsEditable',{
        vehicleListingId: _vm.vehicleListingId,
        marketplaceListingId: _vm.marketplaceListingId,
        listing: _vm.updatedListing,
        vehiclePhotos: _vm.vehiclePhotos,
        loadingVehiclePhotos: _vm.loadingVehiclePhotos,
        vehiclePhotosDTO: _vm.vehiclePhotosDTO,
        announcements: _vm.announcements,
        loadingAnnouncements: _vm.loadingAnnouncements,
        editableSections: ['photos', 'pricing', 'vehicleBasics', 'announcements'],
        fixedButtons: true,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }