var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.announcementLinks?.length)?_c('div',{staticClass:"announcement-link mt-2"},_vm._l((_vm.announcementLinks),function(announcementLink){return _c('div',{key:`announcement-link-${announcementLink.vehicleListingAnnouncementId}`,staticClass:"flex-row justify-space-between width-100"},[_c('TheAnnouncement',_vm._b({},'TheAnnouncement',{
                    announcement: announcementLink,
                    isClickable: true,
                    isEditable: false,
                },false)),_c('b-icon',{staticClass:"announcement-icon",attrs:{"icon":"open-in-new","custom-size":"mdi-18px","type":"is-dark-grey"}})],1)}),0):_vm._e(),(_vm.hasUnknownAnnouncements && _vm.$props.showAllAnnouncements)?_c('TheAnnouncementCategoryCard',_vm._b({staticClass:"py-1 unknown-announcements",on:{"viewPhoto":function($event){return _vm.$emit('viewPhoto', $event)},"edit":function($event){return _vm.$emit('edit', $event)},"delete":function($event){return _vm.$emit('delete', $event)}},scopedSlots:_vm._u([{key:"headerButton",fn:function(){return [_vm._t("cardHeaderButton")]},proxy:true}],null,true)},'TheAnnouncementCategoryCard',{
            category: 'unknown',
            announcements: _vm.announcementsRef?.unknown,
            open: true,
            isEditable: _vm.$props.isEditable,
            clickToViewPhoto: _vm.$props.isClickable,
            ..._vm.$attrs.cardAttrs,
            ..._vm.$attrs.unknownCardAttrs,
        },false)):(_vm.hasUnknownAnnouncements)?_c('div',{staticClass:"unknown-announcements-container flex-row",staticStyle:{"gap":"1rem"}},_vm._l((Object.keys(_vm.unknownAnnouncementsByType)),function(announcementType){return _c('div',{key:`${announcementType}-unknown-announcements`,staticClass:"unknown-announcements-by-type"},_vm._l((_vm.unknownAnnouncementsByType[announcementType]),function(announcement,idx){return _c('TheAnnouncement',_vm._b({key:`unknown-announcement-${idx}`,staticClass:"py-1 px-2 unknown-announcement mb-2",class:_vm.getBackgroundColorByAnnouncementType(announcement.announcementType),staticStyle:{"border-radius":"16px"},on:{"click":function($event){return _vm.$emit('viewPhoto', announcement.announcementPhoto?.vehicleListingPhotoId)},"edit":function($event){return _vm.$emit('edit', announcement)},"delete":function($event){return _vm.$emit('delete', { vehicleListingAnnouncementId: announcement.vehicleListingAnnouncementId, category: 'unknown' })}}},'TheAnnouncement',{
                    announcement,
                    isClickable: announcement.announcementPhoto && _vm.$props.isClickable,
                    isEditable: _vm.$props.isEditable,
                },false))}),1)}),0):_vm._e(),(!_vm.hasUnknownAnnouncements || _vm.$props.showAllAnnouncements)?_c('div',{staticClass:"announcements-with-category"},_vm._l((Object.keys(_vm.announcementsWithCategory)),function(category){return _c('TheAnnouncementCategoryCard',_vm._b({key:`${category}-announcements`,staticClass:"my-3",on:{"viewPhoto":function($event){return _vm.$emit('viewPhoto', $event)},"edit":function($event){return _vm.$emit('edit', $event)},"delete":function($event){return _vm.$emit('delete', $event)}}},'TheAnnouncementCategoryCard',{
                category,
                announcements: _vm.announcementsWithCategory[category],
                open: Boolean(_vm.announcementsWithCategory[category]?.length),
                isEditable: _vm.$props.isEditable,
                clickToViewPhoto: _vm.$props.isClickable,
                ..._vm.$attrs.cardAttrs,
            },false))}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }