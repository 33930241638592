
import { defineComponent } from 'vue';

import { useUserRole } from '@/composables';
import Modal from './Modal.vue';
import TheMarketplaceListingOffersTable from './TheMarketplaceListingOffersTable.vue';

export default defineComponent({
    name: 'TheMarketplaceListingOffersModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: true,
        },
        isSecondChance: {
            type: Boolean,
            default: false,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Modal,
        TheMarketplaceListingOffersTable,
    },
    setup() {
        const { isUserAdmin, isUserDsr } = useUserRole();

        return {
            isUserAdmin,
            isUserDsr,
        };
    },
});
