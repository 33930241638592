export enum VehicleStatusGroupSoldEnum {
    Checkout = 1,
    Sold = 2,
    Completed = 3,
    MarketplaceCheckout = 4,
    MarketplaceSold = 5,
    MarketplaceCompleted = 6,
    SecondChanceCheckout = 7,
    SecondChanceSold = 8,
    SecondChanceCompleted = 9,
}