var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tooltip)?_c('b-tooltip',_vm._b({staticClass:"status-tooltip status-icon-container",class:_vm.$attrs.class},'b-tooltip',{
    label: _vm.tooltip,
    position: 'is-bottom',
    ..._vm.$attrs.tooltipAttrs,
  },false),[_c('b-icon',_vm._b({},'b-icon',{
      icon: _vm.icon,
      size: 'is-medium',
      ..._vm.$attrs,
    },false))],1):_c('b-icon',_vm._b({},'b-icon',{
    icon: _vm.icon,
    size: 'is-medium',
    ..._vm.$attrs,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }