<template>
  <Modal 
    v-bind="{
      showButtons: ['submit', 'cancel'],
      customLoading: true,
      isLoading: loadingSubmitClaim,
      disableConfirm: loadingAttachments || missingInformation,
    }"
    @confirm="submitClaim"
    @close="$emit('close')"
    class="carmigo-modal"
  >
    <template #header>
      Open an Arbitration Claim
    </template>

    <template #body>
      <InfoHeader
        :imageUrl="heroPic.url"
        :title="loadingClaimDetails ? 'Loading vehicle data...' : vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model"
        :subtitle="loadingClaimDetails ? '' : vehicle.vin"
      >
        <template v-slot:details>
          <div class="highlights flex-row" :class="{ 'border-top': !loadingClaimDetails }">
            <InfoHighlight
              v-for="detail in Object.keys(vehicleDetails)"
              :key="detail"
              :label="detail"
              :info="vehicleDetails[detail]"
              color="green"
            />
          </div>
        </template>
      </InfoHeader>

      <div>
        <div class="input-section">
          <b-field label="Reason for Claim*" class="claim-reason flex-column">
            <DeleteEntryButtons
              v-bind="{
                entries: claimReasons,
                flexDirection: 'row',
                disabled: loadingSubmitClaim,
              }"
              @deleteEntryByIndex="deleteReason"
            />
            <b-dropdown 
              required 
              multiple 
              v-model="claimReasons"
              :key="`claim-options-${optionsKey}`"
              :disabled="loadingClaimDetails || loadingSubmitClaim"  
            >
              <template #trigger="props">
                <b-button :icon-right="props.open ? 'menu-up' : 'menu-down'">
                  <p>Select</p>
                </b-button>
              </template>
              <b-dropdown-item
                class="dropdown-item"
                v-for="option in claimReasonOptions"
                :key="option"
                :value="option"
                aria-role="list-item"
              >
                <p>{{ option }}</p>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </div>
        <div class="input-section">
          <b-field 
            label="Additional Details*"
          >
            <b-input 
              v-model="claimDescription"
              :disabled="loadingClaimDetails || loadingSubmitClaim"  
            ></b-input>
          </b-field>
        </div>
        <div class="input-section">
          <DragAndDropAttachments
            @attached="attachments=$event"
            :loading.sync="loadingAttachments"
            :disabled="loadingClaimDetails || loadingSubmitClaim"
          >
            <template v-slot:instructions>
              <p class="italic margin-bottom">
                Please provide any additional photos or PDF attachments that may
                be relevant to your claim.
              </p>
            </template>
          </DragAndDropAttachments>
          <AnimatedLoadingText
            text="Uploading attachments"
            class="margin-1-em"
            :class="!loadingAttachments ? 'hidden' : ''"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { POST, GET } from "../../api";
import store from '@/vuex';

import InfoHeader from "../../components/Arbitrations/InfoHeader.vue";
import InfoHighlight from "../../components/Arbitrations/InfoHighlight.vue";
import DragAndDropAttachments from "../../components/Arbitrations/DragAndDropAttachments.vue";
import BuefyDropdown from "../../components/BuefyDropdown.vue";
import DeleteEntryButtons from "../../components/Arbitrations/DeleteEntryButtons.vue";
import AnimatedLoadingText from "../../components/Arbitrations/AnimatedLoadingText.vue";
import Modal from '../../components/Modal.vue';

import {
  dateStrWithDots,
  formatDollarAmount,
  getDifferenceInDays,
  openToast,
} from "../../utils"
import { postgresDate2DateStr } from '../../utils/postgresUtils';

export default {
  name: "OpenArbitrationsClaimModal",
  props: {
    vehicleListingId: {
      type: Number | String,
      required: true
    }
  },
  components: {
    AnimatedLoadingText,
    BuefyDropdown,
    DeleteEntryButtons,
    DragAndDropAttachments,
    InfoHeader,
    InfoHighlight,
    Modal,
  },
  data() {
    return {
      loadingClaimDetails: true,
      loadingSubmitClaim: false,
      claimId: 0,
      vehicle: {},
      vehicleDetails: {},
      claimReasons: [],
      claimDescription: "",
      attachments: [],
      isCosmeticTimeExpired: false,
      claimReasonOptions: [],
      claimId: 0,
      loadingAttachments: false,
      heroPic: {},
      order: {},
      optionsKey: 0,
    };
  },
  async mounted() {
    await Promise.all([
      GET(`/vehicles/heroPic/${this.vehicleListingId}`).then(res => this.heroPic = res.data),
      GET(`/vehicles/headerDetails/${this.vehicleListingId}`).then(res => { 
        this.vehicle = {
          ...this.vehicle,
          ...res.data
        };
      }),
      GET(`/vehicles/order/${this.vehicleListingId}`).then(res => this.order = res.data),
      GET(`/arbitration/cosmeticExpiration/${this.vehicleListingId}`).then(res => {
        const cosmeticDaysLeft = getDifferenceInDays(new Date(res.data), new Date());
        this.isCosmeticTimeExpired = cosmeticDaysLeft <= 0;
      })
    ]);
    this.loadingClaimDetails = false;
    this.vehicleDetails = {
      'Total Paid': formatDollarAmount(this.order.total),
      Purchased: dateStrWithDots(postgresDate2DateStr(this.order.purchaseDate)),
      Delivered: dateStrWithDots(postgresDate2DateStr(this.vehicle.deliveryDate)),
    };
    this.setClaimReasons();
    await this.generateClaimId();
  },
  computed: {
    missingInformation() {
      return !this.claimReasons.length || !this.claimDescription.length;
    },
  },
  methods: {
    openToast,
    setClaimReasons() {
      if (!this.isCosmeticTimeExpired) {
        this.claimReasonOptions = [
          "Engine Problem",
          "Cosmetic - Exterior",
          "Cosmetic - Interior",
          "Other Mechanical Issue",
          "Structural",
          "Title",
          "Transmission",
          "Vehicle History",
        ];
      } else {
        this.claimReasonOptions = [
          "Engine Problem",
          "Other Mechanical Issue",
          "Structural",
          "Title",
          "Transmission",
          "Vehicle History",
        ];
      }
      this.optionsKey++;
    },
    async generateClaimId() {
      this.claimId = await GET(`/arbitration/generateNewClaimId`).then(
        (response) => response.data
      );
    },
    deleteReason(reasonIdx) {
      this.claimReasons.splice(reasonIdx, 1);
    },
    async submitClaim() {
      if(!this.order.store.id) {
          this.openToast(
          "is-danger",
          "Failed to open claim, the store id is missing from the order."
        );
        return;
      }
      const claimInformation = {
        reason: this.claimReasons,
        description: this.claimDescription,
        attachments: this.attachments,
        vehicleId: this.vehicleListingId.toString(),
        contactUserId: store.state.user.profile.id,
        claimId: this.claimId,
        storeId: this.order.store.id.toString(),
      };
      try {
        this.loadingSubmitClaim = true;
        await POST(`/arbitration/create`, claimInformation);
        this.loadingSubmitClaim = false;
        this.openToast(
          "is-success",
          "Claim submitted! You will be notified via email when an arbitrator reviews your claim."
        );
        this.$emit("close");
        this.$emit("claimOpened", this.claimId);
      } catch (error) {
        console.log(error);
        this.openToast(
          "is-danger",
          "Failed to open claim. Please complete all required fields."
        );
        this.loadingSubmitClaim = false;
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.input-section {
  margin: 1rem 1.5rem;
}

.margin-bottom {
  margin-bottom: 1rem;
}

:deep(#info-header) {
  & .info-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark-blue);
  }
  
  & .info-subtitle {
    font-size: 14px;
  }
}

.claim-reason {
  & :deep(.field.has-addons) {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 500px) {
  .input-section {
    margin: 0;

    &:first-child {
      margin-top: 0.75rem;
    }
  }

  :deep(#info-header) {
    align-items: flex-start !important;

    & .info-subtitle {
      font-size: 13px;
    }

    & .image-thumbnail {
      margin-left: 0;
      min-width: 4.5rem;
      width: 4.5rem;
      height: 4.5rem;
    }

    & .highlights {
      flex-direction: column !important;

      &.border-top {
        border-top: 1px solid var(--primary);
        margin-top: 0.25rem;
      }
    }

    & .info-highlight {
      flex-direction: row !important;
      justify-content: space-between !important;
      margin: 0 !important;
      padding: 0 !important;

      & .info {
        font-size: 12px !important;
      }
    }
  }
}
</style>
