
import { defineComponent, PropType, reactive } from 'vue';
import { ListingType } from '../composables';

import EditableAuctionPricing, { AuctionPriceFields } from './Forms/EditableAuctionPricing.vue';
import Modal from './Modal.vue';

export default defineComponent({
    name: 'TheEditListingPricingModal',
    emits: ['close'],
    props: {
        listingType: {
            type: String as PropType<ListingType>,
            default: 'auction',
        },
        startingPrice: {
            type: Number,
            required: false,
        },
        reservePrice: {
            type: Number, // used as floor price if listingType == 'marketplace'
            required: false,
        },
        showFields: {
            type: Array as PropType<(AuctionPriceFields)[]>,
            required: false,
        },
        buyItNowPrice: {
            type: Number,
            required: false, 
        },
        canBuyItNow: {
            type: Boolean,
            required: false,
        },
    },
    components: {
        EditableAuctionPricing,
        Modal,
    },
    setup(props, { emit, attrs }) {
        const pricingUpdated = reactive({
            startingPrice: props.startingPrice,
            reservePrice: props.reservePrice,
            buyItNowPrice: props.buyItNowPrice,
            canBuyItNow: props.canBuyItNow,
        });

        return {
            pricingUpdated,
        }
    },
});
