<template>
    <div class="width-100">
        <div class="flex-row justify-space-between point9-rem list-row"
            v-for="label, idx in Object.keys(listObj)" 
            :key="idx"
            :class="{
                'background': delineated && idx%2==0,
                'is-large': isLarge,
                'is-total': label === 'Total'
            }"
        >
            <p class="label" :class="{
                capitalize: capitalizeItems,
                'ml-5': indentedLabels.includes(label)
            }">
                {{ useStartCase ? startCase(label) : label }}
            </p>
            <p class="value" :class="{ 'weight-600': boldValues }" >{{ listObj[label] }}</p>
        </div>
    </div>
</template>

<script>
import { startCase } from 'lodash';
export default {
    name: "ItemizedList",
    props: {
        "listObj": Object,              // {"Label": item}
        "delineated": Boolean,           
        "capitalizeItems": Boolean,     
        "boldValues": Boolean,
        "isLarge": Boolean,
        "useStartCase": Boolean, // uses lodash start case
    },
    setup() {
        // TODO: Make this a prop in the future if needed.
        const indentedLabels = ['Reason', 'Offer Amount', 'Payoff Amount'];
        return {
            startCase,
            indentedLabels,
        };
    },
}
</script>

<style scoped lang="postcss">
.background {
    background: rgba(82, 170, 87, 0.1)
}

.list-row {
    padding: .15em .5em;

    & .label {
        max-width: 40%;
    }

    & .value {
        text-align: right;
        max-width: 57%;
        word-break: break-all;
    }

    &.is-large {
        padding: .5em 1.5em;
    }

    &.is-total {
        text-transform: uppercase;
        font-weight: bold !important;

        .label {
            font-weight: 200;
            font-size: 1em;
        }
    }

    & .label {
        font-weight: 300;
    }
}

.weight-600 {
    font-weight: 600
}

.capitalize {
    text-transform: uppercase;
    font-size: .85em;
    font-weight: 500;
}
</style>
