var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({staticClass:"modal-card modal-component",staticStyle:{"width":"auto","max-height":"80vh"}},'div',_vm.$attrs,false),[(!_vm.hideHeader)?_c('header',{staticClass:"modal-card-head flex-row justify-space-between"},[_c('h1',{staticClass:"modal-card-title"},[_vm._t("header")],2),_c('div',{staticClass:"has-text-white flex-row align-center"},[(_vm.showButtons.includes('share'))?_c('ButtonIcon',{attrs:{"iconName":"share-variant","icon-color":"has-text-white"},on:{"click":function($event){return _vm.$emit('share')}}}):_vm._e(),(_vm.showButtons.includes('expand'))?_c('ButtonIcon',{attrs:{"iconName":"open-in-new","icon-color":"has-text-white"},on:{"click":function($event){return _vm.$emit('expand')}}}):_vm._e(),_c('ButtonIcon',{attrs:{"iconName":"close","icon-color":"has-text-white"},on:{"click":function($event){return _vm.$emit('close')}}})],1)]):_vm._e(),_c('section',{staticClass:"modal-card-body",class:{ 'modal-body-bottom-rounded' : _vm.hideFooter }},[(_vm.isLoading && !_vm.customLoading)?_c('Loading'):_c('div',{staticClass:"modal-card-body-custom"},[_vm._t("body")],2)],1),(!_vm.hideFooter)?_c('footer',{staticClass:"modal-card-foot justify-space-between"},[_c('div',{staticClass:"buttons-left"},[_vm._t("buttonsLeft")],2),(_vm.buttonsMiddle)?_c('div',{staticClass:"buttons-middle"},[_vm._t("buttonsMiddle")],2):_vm._e(),_c('div',{staticClass:"buttons-right"},[(_vm.customButtons)?_vm._t("buttonsRight"):_c('div',[(_vm.showButtons.includes('close'))?_c('b-button',{attrs:{"label":"Close","disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),(_vm.showButtons.includes('cancel'))?_c('b-button',_vm._b({on:{"click":function($event){return _vm.$emit('close', { isCancelled: true })}}},'b-button',{
                        label: 'Cancel',
                        type: 'is-danger',
                        disabled: _vm.isLoading,
                        ..._vm.$attrs.cancelButtonAttrs,
                    },false)):_vm._e(),(_vm.showButtons.includes('confirm'))?_c('b-button',_vm._b({on:{"click":function($event){return _vm.$emit('confirm')}}},'b-button',{
                        label: 'Confirm',
                        type: 'is-primary',
                        loading: _vm.isLoading,
                        disabled: _vm.disableConfirm,
                        ..._vm.$attrs.confirmButtonAttrs,
                    },false)):_vm._e(),(_vm.showButtons.includes('submit'))?_c('b-button',_vm._b({on:{"click":function($event){return _vm.$emit('confirm')}}},'b-button',{
                        label: 'Submit',
                        type: 'is-primary',
                        loading: _vm.isLoading,
                        disabled: _vm.disableConfirm,
                        ..._vm.$attrs.submitButtonAttrs,
                    },false)):_vm._e()],1)],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }