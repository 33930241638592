
import { defineComponent, PropType } from 'vue';
import { useVdpModal } from '../composables';
import { SRPListing } from '../types';
import { fireEvent } from '../segment';
import { useRoute } from 'vue-router/composables';
import VueRouter from 'vue-router';

import AppListingDetailsBase from '../views/AppListingDetailsBase.vue';
import Modal from './Modal.vue';

export default defineComponent({
    name: 'AppListingDetailsModal',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
        router: { // this doesn't work within modals ??
            type: Object as PropType<VueRouter>,
            required: true
        },
        expandedCards: {
            type: Array as PropType<String[]>,
            default: () => { return [] },
        },
    },
    components: {
        AppListingDetailsBase,
        Modal,
    },
    emits: ['close', 'watching'],
    setup(props, { emit, attrs }) {
        const route = useRoute();
        fireEvent('Auction Details', { route: route.fullPath }); // see router index - similar events fire after router navigation but not for VDP modal, so added here

        const { shareListing, expandListing } = useVdpModal({ 
            vehicleListingId: props.listing.id,
            router: props.router,
        });

        return {
            shareListing,
            expandListing,
        }
    },
});
