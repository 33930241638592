import { APIConfig, AuctionCurrentBidDTO, ModifierOptions } from '@/types';
import { GET, PUT } from '.';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess } from '@/utils';

export async function getUserCurrentBid(vehicleListingId: number, modifiers: ModifierOptions={}, config: APIConfig={}): Promise<AuctionCurrentBidDTO | void> {
    return await PUT(`/listings/${vehicleListingId}/currentBidAndProxyBid`, modifiers, { cancelToken: config.cancelToken })
        .then(res => {
            let currentBid: AuctionCurrentBidDTO = {
                bidAmount: res.data.requestorCurrentHighestBid.amount,
                storeName: res.data.requestorCurrentHighestBid.storeName,
                proxyBidAmount: res.data.requestorCurrentHighestProxyBid?.amount,
            }
            applyAPIConfigOnSuccess(currentBid, config);
            return currentBid;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}