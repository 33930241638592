import { daysToWeeks, format } from "date-fns"
import { GET } from "../api"
import store from '@/vuex';

export function dateStrWithDots(dateStr: string) {
    var result = dateStr.replaceAll('-', '.')
    result = dateStr.replaceAll('/', '.')
    return result
  }
  
  export function dateToDateStringWithDots(date: Date) {
    const localeDateString = new Date(date).toLocaleDateString('en-US');
    return dateStrWithDots(localeDateString);
  }

  export function getDifferenceInDays(date1: Date, date2: Date) {
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    const timeDiff = time1 - time2;
    return timeDiff / (1000 * 3600 * 24);
  }

  export function formatAMPM(date) {
    if (typeof date == 'string') {
      const dateStr = date;
      date = new Date(date);
      if (date == 'Invalid Date') return dateStr;
    };
    if (date == 'Invalid Date') return '--';
    if (!date) return "Not defined yet";
    return format(date, " MMMM dd' at' p");
  }
  
  export function formatTime(dateStr, timeZone) {
    var date = new Date(dateStr)
    var timeStr = date.toLocaleTimeString('en-US', { timeZone })
    const split = timeStr.split(' ')
    var time = split[0]
    var ampm = split[1]
    time = time.slice(0, time.length-3)
    return `${time} ${ampm}`
  }
  
  // do better
  // export function formatTime(dateStr) {
  //   // const date = new Date(dateStr)
  //   // var timeStr = date.toTimeString()
  //   // timeStr = timeStr.split(" ")[0] as string
  //   // timeStr = timeStr.split(":")
  //   // timeStr = timeStr[0] + ":" + timeStr[1]
  //   return dateStr
  // }
  
  export function getTimeBeforeOrAfter(amount: number, time: string, beforeOrAfter: string, date: Date) {
    var multiplier = 60
    if (time==='hrs') multiplier = 60*60
    switch(beforeOrAfter) {
      case "before":
        return new Date(date.getTime() - amount * multiplier * 1000)
      case 'after':
        return new Date(date.getTime() + amount * multiplier * 1000)
    }
  }
  
  export function getDaysBeforeOrAfter(amount: number, beforeOrAfter: string, date: Date) {
    if (amount === 0) return date
  
    const newDate = new Date(date)
    switch (beforeOrAfter) {
      case "before": 
        newDate.setDate(date.getDate() - amount)
        break;
      case "after": 
        newDate.setDate(date.getDate() + amount)
        break;
    }
    return newDate
  }
  
  /** 
   * Gets next day from startDate (e.g. next Saturday)
   * @param startDate - (Date) 
   * @param nextDateIdx - (number) idx of the day you need (Sunday = 0)
   * @param allowSameDay - (bool) if true & start/next date are the same day (e.g. both Monday), will return same day. Iff false, will return following Monday
   */
  export function getNextDate(start: Date, nextDateIdx: number, allowSameDay: boolean) {
    if (nextDateIdx < 0 || nextDateIdx > 6) throw new Error('nextDateIdx must be between 0 and 6')
    const startDate = start.getDate()
    var startDay = start.getDay()
  
    const newDate = new Date()
    var daysUntilDate = (nextDateIdx + (7-startDay)) 
    if (startDay !== nextDateIdx || allowSameDay) daysUntilDate = daysUntilDate % 7
    newDate.setDate(startDate + daysUntilDate)
    return newDate
  }
  
  /**
   * Returns <date> + <minutes>, rounded to nearest <ceiling> increment.
   * E.g., minutes=30, ceiling=15, date=08:04:00 ---> 08:34:00 => 08:45:00
   * @param date - starting date
   * @param minutes - num minutes to add
   * @param increment - time interval to round to
   * @returns 
   */
  export function getRoundedHalfHrFromDate(date: Date, minutes: number, ceiling: number) {
    // add <minutes> to <date>
    var ms = 1000 * 60 * minutes
    var minAfterDate = date.getTime() + ms
    var newDate = new Date(minAfterDate)
  
    var newMin = newDate.getMinutes()
    if (newMin % ceiling !== 0) { // if not rounded, round up to ceiling
      var nextApptTime = newMin + (ceiling - newMin % ceiling)
      newDate.setMinutes(nextApptTime)  
    }
  
    return newDate
  }

// This gets a timestamp from the firebase server (Everything is done in milliseconds && in UTC)
export async function getServerTimeStamp() {
  try {
    // This is the local machines time
    const beforeCallTime = setUtcTime().getTime();

    // This is the server time GET
    const serverTimeStamp = (await GET("serverTime")).data;

    // This is the amount of time the request took to get back to us (current time - time before the call)
    let roundtripTime = setUtcTime().getTime() - beforeCallTime;

    // Divide the roudtrip time by 2. (This is to remove the server response time. We only want to subtract the server request time)
    // The response time is not subtracted because we want to include the time that has passed from when we got the timeStamp from the server.
    //  We are making a bit of an assumption here with the request time taking the same amount as the response time.
    roundtripTime /= 2;

    // This is the server timeStamp with roundtripTime time removed (send/response)
    const roundTripRemoved = serverTimeStamp - roundtripTime;

    // This is the calculated difference between local machine time & server time with the send/response removed
    const serverVsLocalTimeDiffInMilliseconds =
      roundTripRemoved - beforeCallTime;

    // This sets the syncedServerDifferenceInMilliseconds. (It shows how far off our local machine is from the server)
    store.commit(
      "setServerTimeDifference",
      serverVsLocalTimeDiffInMilliseconds
    );
  } catch (error) {
    console.log(error);
    store.commit("setServerTimeDifference", 0);
  }
}

export function serverSyncedNewDate() {
  return new Date(
    setUtcTime().getTime() +
      (store.state.syncedServerDifferenceInMilliseconds ?? 0)
  );
}

export function setUtcTime() {
  let date = new Date();
  let now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(now_utc);
}

/**
 * Can be the body of a sort statement involving dates
 * @param {Date|string} date1
 * @param {Date|string} date2 
 * @returns {number} difference between the values of date1 & date2
 */
export function getDifferenceBetweenDateValues(date1, date2) {
  return (new Date(date2)).valueOf() - (new Date(date1)).valueOf();
}

export function getNextDay2pmCst(startingDate) {
  startingDate = new Date(startingDate);
  const utc = startingDate;
  const cdt = new Date(startingDate.toLocaleString("EN-US", { timeZone: "America/Chicago" }));

  const timeDiff = utc.getTime() - cdt.getTime();
  const hourDiff = Math.round(timeDiff / 6000000);
  const twoPM = 14 + hourDiff;

  const nextDayDate = startingDate.getDate() + 1;
  const nextDay = new Date(startingDate.setDate(nextDayDate));
  const finalDate = new Date(nextDay.setHours(twoPM, 0, 0, 0));
  
  return finalDate;
}

export function getMonthBeforeOrAfter(startDate: Date, numMonths: number=1, beforeOrAfter: 'before' | 'after'='before') {
  var newDate = new Date();
  var month; 
  switch(beforeOrAfter) {
    case 'before':
    default:
      month = startDate.getMonth() - numMonths;
      break;
    case 'after':
      month = startDate.getMonth() + numMonths;
      break;
  }
  newDate.setMonth(month);
  return newDate;
}

export function getFirstOfTheMonth(date?: Date) {
  date = date ? date : new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month, 1);
}

export function dateHasPassed(date1?: string | Date): boolean {
  if (!date1) {
    return false;
  }
 return setUtcTime() > new Date(date1);
}

export function formatDate(date: Date, formatAs: string='MM/dd/yyyy') {
  return format(new Date(date), formatAs);
}
