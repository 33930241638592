
import { defineComponent } from 'vue';

import AuctionStat from './AuctionStat.vue';

export default defineComponent({
    name: 'TheListingDetailsViewAndCounts',
    props: {
        viewCount: {
            type: Number,
            default: 0,
        },
        bidCount: {
            type: Number,
            required: false,
        },
        offerCount: {
            type: Number,
            required: false,
        },
    },
    components: {
        AuctionStat,
    },
});
