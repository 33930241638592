
import draggable from 'vuedraggable';
import { openToast } from '../../utils';
import AddAttachmentsButton from '../AddAttachmentsButton.vue';
import ConfirmDeletePhotoModal from './ConfirmDeletePhotoModal.vue';
import { PropType } from 'vue';
import BuefyDropdown from '../BuefyDropdown.vue';
import { useBreakpoint } from '@/composables';

type PhotoSection = 'Interior' | 'Exterior' | 'Damage';

export default {
    name: 'EditableGallery',
    props: {
        headerText: {
            type: String,
            required: false,
            default: 'Photos'
        },
        photos: {
            type: Array,
            required: true,
        },
        filePath: {
            type: String,
            required: false,
        },
        numColumns: {
            type: Number,
            default: 3,
        },
        moveToSectionOptions: {
            type: Array as PropType<PhotoSection[]>,
            required: false,
        },
        isStickyHeader: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    components: {
        draggable,
        AddAttachmentsButton,
        BuefyDropdown,
    },
    data() {
        return {
            photosUpdated: [...this.photos],
            selectedPhotos: [],
            photosKey: 0,
            originalPhotos: [],
            selectedMoveToSection: undefined,
            inProgressPhotos: [] as string[],
        }
    },
    setup() {
        const { bulmaBreakpoint } = useBreakpoint();
        return {
            bulmaBreakpoint,
        }
    },
    beforeMount() {
        this.photosUpdated = this.photos.map((photo, idx) => {
            return {
                image: photo,
                order: idx+1,
                originalOrder: idx+1,
                id: idx
            }
        })
        this.originalPhotos = [...this.photosUpdated];
        this.photosKey++;
    },
    computed: {
        hasChanges() {
            this.photosKey;
            return this.photosUpdated.some((photo, idx) => {
                if (!photo.originalOrder) {
                    return true;
                }
                if (photo.order !== idx+1) {
                    return true;
                }
                if (photo.delete || photo.moveTo) {
                    return true;
                }
            });
        },
        deletingSelectedPhotos() {
            return this.selectedPhotosAction == 'Delete';
        },
        getMoveToLabel() {
            return this.selectedMoveToSection ? `Move to ${this.selectedMoveToSection}` : 'Move to';
        }
    },
    watch: {
        photosUpdated: {
            deep: true,
            handler: function() {
                this.emitUpdates();
            }
        }
    },
    methods: {
        openToast,
        isSelected(imageId: number) {
            return this.selectedPhotos.some(image => image.id == imageId);
        },
        emitUpdates() {
            this.$emit('input', this.headerText, this.photosUpdated, this.hasChanges);
        },
        handleUploadedFiles(newPhotos) {
            newPhotos.forEach(newPhoto => {
                const isDuplicate = this.photosUpdated.some(photo => photo.filename == newPhoto.filename);
                if (isDuplicate) {
                    this.openToast('is-danger', 'Duplicate photo could not be uploaded. You may need to rename the file.')
                } else {
                    this.photosUpdated.push({
                        image: newPhoto,
                        order: this.photosUpdated.length + 1,
                        originalOrder: null,
                        id: this.photosUpdated.length
                    });
                }
            });
            
            this.photosKey++;
        },
        hasBeenMoved(elementOrder, elementIdx) {
            return Boolean(elementOrder !== elementIdx + 1);
        },
        checkIfMoveIsAllowed({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;

            return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
        },
        orderPhotos(photos) {
            return photos.map((photo, idx) => {
                return {
                    image: photo,
                    order: idx+1, 
                    fixed: false,
                    id: idx, 
                }
            });
        },
        selectOrDeselectImage(selectedImage) {
            const selectedPhotosIdx = this.selectedPhotos.findIndex(entry => entry.id == selectedImage.id);
            const updatedPhoto = this.photosUpdated.find(photo => photo.id == selectedImage.id);

            if (updatedPhoto?.delete || updatedPhoto?.moveTo) {
                updatedPhoto.delete = false;
                updatedPhoto.moveTo = undefined;
                this.emitUpdates();
                this.photosKey++;
                return;
            }
            const isAlreadySelected = selectedPhotosIdx >= 0;
            isAlreadySelected
                ? this.selectedPhotos.splice(selectedPhotosIdx, 1)
                : this.selectedPhotos.push(selectedImage);
            // this.$emit('delete', this.headerText, this.selectedPhotos);
        },
        markSelectedPhotos(action: string, suboption: string) {
            this.selectedPhotos.forEach(selectedPhoto => {
                let updatePhoto = this.photosUpdated.find(updatePhoto => updatePhoto.id == selectedPhoto.id);
                if (updatePhoto) {
                    updatePhoto.delete = action == 'delete';
                    updatePhoto.moveTo = action == 'moveTo' && suboption ? suboption : undefined;
                }
            })
            this.selectedPhotos = [];
            this.selectedMoveToSection = undefined;
            this.photosKey++;
            this.emitUpdates();
        },
        openConfirmDeleteModal() {
            this.$buefy.modal.open({
                parent: this,
                hasModalCard: true,
                trapFocus: true,
                component: ConfirmDeletePhotoModal,
                props: {
                    numVehicles: this.selectedPhotos.length,
                },
                events: {
                    confirm: () => {
                        this.deletePhotos(this.selectedPhotos);
                    }
                }
            });
        },
        deletePhotos(photosToDelete) { // ids
            this.photosOrdered = this.photosOrdered.filter(photo => !photosToDelete.includes(photo.id));
            this.selectedPhotos = [];
            this.photosKey++;
        },
        handleUploading(fileNames: string[]) {
            // this is commented out because it breaks events from AddAttachmentsButton
            // if we want the Uploading feedback to display, we need to figure out an alternate solution to this
                // fileNames.forEach((fileName) => {
                    // this.inProgressPhotos.unshift(fileName); 
                // });
            this.$emit('uploading');
        },
        clearUploadedFiles(fileNames: string | string[]) {
            if (typeof fileNames == 'string') {
                fileNames = [fileNames];
            }
            fileNames.forEach((fileName) => {
                const idx = this.inProgressPhotos.findIndex((photo) => photo === fileName);
                this.inProgressPhotos.splice(idx, 1);
            });
        },
    },
}
