var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheNegotiationModalWithMessages',_vm._b({on:{"close":function($event){return _vm.$emit('close')},"counter":_vm.submitCounterOffer,"reject":_vm.rejectOffer,"accept":_vm.acceptOffer},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('InfoHeader',{attrs:{"vehicleListingId":_vm.vehicleListingId,"size":"sm-medium","imgSize":"sm-medium"},scopedSlots:_vm._u([{key:"details",fn:function(){return [(_vm.isSecondChance)?_c('div',{staticClass:"flex-row"},[_c('InfoHighlight',{attrs:{"label":"Reserve Price","info":_vm.toCurrency(_vm.reservePrice),"color":"green"}})],1):_c('div',{staticClass:"flex-row"},[_c('InfoHighlight',{attrs:{"label":"Floor Price","info":_vm.toCurrency(_vm.reservePrice),"color":"green"}}),_c('InfoHighlight',{attrs:{"label":"Buy-It-Now Price","info":_vm.toCurrency(_vm.buyItNowPrice),"color":"green"}})],1)]},proxy:true}])})]},proxy:true}])},'TheNegotiationModalWithMessages',{ 
        ..._vm.$props,
        isMarketplace: true,
        sellerOfferAmount: _vm.sellerOfferAmount ? _vm.sellerOfferAmount : _vm.buyItNowPrice,
        allowMessages: true,
        allowCounterOffer: _vm.allowCounterOffer,
        negotiationMessages: _vm.negotiationMessages,
        loadingMessages: _vm.loadingMessages,
        loadingSubmitCounterOffer: _vm.loadingSubmitCounterOffer,
        loadingRejectOffer: _vm.loadingRejectOffer,
        loadingAcceptOffer: _vm.loadingAcceptOffer,
        disableAcceptOffer: _vm.disableAcceptOffer,
        highlightDetails: [
            {
                label: `Seller's Offer`,
                value: _vm.sellerOfferAmount ? _vm.sellerOfferAmount : _vm.buyItNowPrice,
                filter: _vm.toCurrency,
            },
            {
                label: `Buyer's Offer`,
                value: _vm.buyerOfferAmount,
                filter: _vm.toCurrency,
            },
        ],
        displayRejectedMessage: true
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }