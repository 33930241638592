<template>
  <Modal
    class="carmigo-modal"
    v-bind="{
      showButtons: ['cancel', 'confirm'],
      disableConfirm: disableSubmit,
      isLoading: isLoading,
      customLoading: true,
      cancelButtonAttrs: {
        type: 'is-light',
      }
    }"
    @close="$emit('close')"
    @confirm="onSubmit"
  >
    <template #body v-if="bidType == 'bid'">
      <!-- BIDDING = $100 -->
      <div v-if="bidType === 'bid'" class="modal-card" style="width: auto">
        <h1 class="modal-card-title">
          Place a bid for {{ toCurrency(+highestBidAmount + 100) }}
        </h1>

        <section class="modal-card-body">
          <p>You'll increase the current bid price by $100</p>
          <template>
            <b-field
              v-if="stores.length > 1"
              label="Which store are you bidding for?"
            >
              <b-select placeholder="Choose store" v-if="loading" loading>
              </b-select>
              <b-select
                v-else
                v-model="selectedStore"
                expanded
                placeholder="Choose store"
                :disabled="loadingBid"
              >
                <option
                  v-for="store in stores"
                  :key="store.id"
                  :value="store.id"
                >
                  {{ store.name }}
                </option>
              </b-select>
            </b-field>
            <b-field v-else label="Your Store Name">
              <b-input v-model="selectedStoreName" disabled> </b-input>
            </b-field>
          </template>

          <div v-if="showWarningText" class="warning-text" :class="`has-text-${warningTextType}`"></div>
        </section>
      </div>
    </template>

    <template #body v-else-if="bidType == 'proxy'">
      <!-- PROXY BID -->
      <div
        v-if="bidType === 'proxy'"
        class="modal-card"
        id="proxy-bid"
        style="width: auto"
        v-on:keydown.13="proxyBidEnterPress"
      >
        <h1 v-if="!existingProxy" class="modal-card-title">
            Place a proxy bid
        </h1>
        <h1 v-else class="modal-card-title">
            Edit your proxy bid
        </h1>
        <!-- Current Bid -->
        <section class="modal-card-body">
          <p v-if="existingProxy" class="current-proxy">
            <span class="current-bid-header">Current Proxy Bid</span>
            <span id="current-bid">{{ proxyBidFormatted }}</span>
          </p>
          <p v-else class="current-proxy">
            <span class="current-bid-header">Current Highest Bid</span>
            <span id="current-bid">{{ highestBidAmountFormatted }}</span>
          </p>
          <template>
            <b-field
              v-if="stores.length > 1"
              label="Which store are you bidding for?"
            >
              <b-select placeholder="Choose store" v-if="loading" loading>
              </b-select>
              <b-select
                v-else
                v-model="selectedStore"
                expanded
                placeholder="Choose store"
                :disabled="loadingProxy"
              >
                <option
                  v-for="store in stores"
                  :key="store.id"
                  :value="store.id"
                >
                  {{ store.name }}
                </option>
              </b-select>
            </b-field>
            <b-field v-else label="Your Store Name">
              <b-input v-model="selectedStoreName" disabled> </b-input>
            </b-field>
          </template>
          <b-field
            v-if="stores.length > 0"
            label="Enter a max auto-bid amount ($100 multiples)"
            class="increment-bid-field"
          >
            <b-input
              step="100"
              :placeholder="nextProxyBidAmountFormatted"
              v-model="proxyBid"
              id="increment-bid-input"
              @input="formatProxyBid"
              :disabled="loadingProxy"
            >
            </b-input>
          </b-field>

          <div v-if="showWarningText" class="warning-text" :class="`has-text-${warningTextType}`"></div>
        </section>
      </div>

    </template>
    <template #body v-else-if="bidType == 'buyItNow'">
      <!-- BUY IT NOW -->
      <div v-if="bidType === 'buyItNow'" class="modal-card" style="width: auto;">
        <h1 class="modal-card-title">
          Confirm your purchase
        </h1>

        <section class="modal-card-body">
          <p class="is-size-6 text-center">
            Please confirm your purchase of this vehicle for <span class="bold has-text-primary">{{ buyItNowPrice | currency }}</span>.
          </p>
          <InfoHeader 
            class="pt-3"
            :vehicleListingId="carId"
            imgSize="sm-medium"
          />
          <div class="mt-3">
            <b-field
              v-if="stores.length"
              label="Which store are you buying for?"
            >
              <b-select
                expanded
                v-model="selectedStore"
                placeholder="Choose store"
                :disabled="loadingBuyItNow"
                :loading="loading"
              >
                <option
                  v-for="store in stores"
                  :key="store.id"
                  :value="store.id"
                >
                  {{ store.name }}
                </option>
              </b-select>
            </b-field>
            <b-field v-else label="Your Store Name">
              <b-input v-model="selectedStoreName" disabled> </b-input>
            </b-field>
          </div>

          <div v-if="showWarningText" class="warning-text" :class="`has-text-${warningTextType}`"></div>
        </section>
      </div>
    </template>
  </Modal>
</template>

<script>
import { database } from '@/firebase';
import store from '@/vuex';
import Vue from 'vue';
import {
    toCurrency,
    openToast,
    openDialogAlert,
    getPowerUserStoresFromStores,
    isUserDsr,
    openConfirmationDialog,
    isStoreCannotBidError,
    showStoreCannotBidErrorDialog,
    isBiddingError,
    openErrorDialog,
} from '@/utils';
import { GET, POST } from '../api';
import { validateDropdown } from '../validation';
import { fireEvent } from '../segment';

import InfoHeader from '../components/Arbitrations/InfoHeader.vue';
import Loading from "../components/ComponentLoading.vue";
import Modal from '../components/Modal.vue';
import * as Sentry from '@sentry/vue';

export default {
  name: "BidModal",
  props: [
    "carId",
    "highestBidAmount",
    "storesInitial",
    "bidType",
    "existingProxy",
    "car",
    "buyItNowPrice",
    "canCancel",
    'canBuyItNow',
  ],
  data() {
    return {
      storeselection: 0,
      selectedStoreName: "",
      selectedStore: 0,
      proxyBid: null,
      loading: false,
      loadingBid: false,
      loadingBuyItNow: false,
      loadingProxy: false,
      shouldValidate: {
        selectedStore: false,
      },
      proxyAccept: false,
      proxyBidFormatted: "",
      highestBidAmountFormatted: "",
      nextProxyBidAmountFormatted: "",
      proxyInt: null,
      success: true,
      highestBid: {},
      highestBidRef: {},
      showWarningText: false,
      warningTextType: 'danger',
      stores: []
    };
  },
  components: {
    InfoHeader,
    Loading,
    Modal,
  },
  async mounted() {
    this.onChangeHighestBid();
    if (this.bidType == "bid") {
      this.loadingBid = true;
    }
    if (this.bidType == "proxy") {
      this.loadingProxy = true;
    }
    if (this.bidType == "buyItNow") {
      this.loadingBuyItNow = true;
    }
    await this.getMissingBuyerStores();
    if (this.selectedStore != 0) {
      this.selectedStoreName = this.stores.filter(
        (store) => store.id == this.selectedStore
      )[0].name;
    }
    if (this.stores.length === 1) {
      this.selectedStore = this.stores[0].id;
      this.selectedStoreName = this.stores[0].name;
    }
    if (this.existingProxy) {
      this.proxyInt = this.existingProxy;
      this.proxyBidFormatted = this.toCurrency(this.existingProxy);
    }
    this.highestBidAmountFormatted = this.toCurrency(this.highestBidAmount);
    this.nextProxyBidAmountFormatted = this.toCurrency(
      this.highestBidAmount + 100
    );
    this.loadingProxy = false;
    this.loadingBid = false;
    this.loadingBuyItNow = false;
  },
  watch: {
    proxyBid: function() {
      if (
        this.proxyInt > this.highestBidAmount &&
        this.proxyInt % 100 === 0 
      ) {
        this.proxyAccept = true;
        return;
      }
      this.proxyAccept = false;
      return;
    },
    proxyInt: function() {
      if (!this.canBuyItNow || !this.buyItNowPrice) {
        this.showWarningText = false;
        return;
      }
      if (this.proxyInt >= this.buyItNowPrice) {
        this.displayBuyItNowWarningText();
        return;
      } 
      this.showWarningText = false;
    }
  },
  beforeDestroy(){
    document.removeEventListener('keydown', this.proxyBidEnterPress)
  },
  computed: {
    isUserDsr,
    highestBidder() {
      if (!this.highestBid?.bidHistory?.length) {
        return undefined;
      }
      return this.highestBid.bidHistory[0];
    },
    isLoading() {
      switch (this.bidType) {
        case 'bid':
          return this.loadingBid;
        case 'proxy':
          return this.loadingProxy;
        case 'buyItNow':
          return this.loadingBuyItNow;
      }
    },
    disableSubmit() {
      switch (this.bidType) {
        case 'bid':
          return !this.selectedStore || this.isLoading;
        case 'proxy':
          if (this.existingProxy) {
            return this.disableProxyBid 
              || (this.proxyInt < (this.highestBidAmount + 100)) 
              || !this.proxyAccept
              || this.proxyInt <= this.existingProxy 
              || !this.selectedStore
              || this.isLoading;
          } else {
            return !(this.proxyAccept && this.selectedStore) 
              || this.isLoading
              || this.disableProxyBid;
          }
        case 'buyItNow':
          return !this.selectedStore;
      }
    },
    disableProxyBid() {
      if (!this.canBuyItNow || !this.buyItNowPrice) {
        return false;
      }
      return this.proxyInt >= this.buyItNowPrice;
    },
  },
  methods: {
    onSubmit() {
      switch(this.bidType) {
        case 'bid':
          // open confirmation dialog if bidding for highest bidder store
          if (this.highestBidder?.storeId == this.selectedStore) {
            openConfirmationDialog({
              title: `You're already the high bid on this car`, 
              message: `You're placing a bid on a car that ${this.selectedStoreName} is already the highest bidder on. Are you sure you want to bid again?`,
              confirmText: 'Yes, bid',
              cancelText: 'Cancel bid',
              onConfirm: () => this.confirmBid(this.selectedStore),
              onCancel: () => this.$emit('close'),
            });
          } else {
            this.confirmBid(this.selectedStore);
          }
          break;
        case 'proxy':
          if (this.existingProxy) {
            this.confirmAndCloseProxyEdit(this.selectedStore, this.proxyInt);
          } else {
            this.confirmAndCloseProxy(this.selectedStore, this.proxyInt);
          }
          break;
        case 'buyItNow':
          this.confirmBuyItNow(this.selectedStore);
          break;
      }
    },
    async getMissingBuyerStores() {

      if (this.storesInitial?.length) {
        this.stores = this.storesInitial;
        return;
      } 

      if (store.state.user.profile.stores?.length) {
        this.stores = store.state.user.profile.stores?.length;
        return;
      }
      if (!this.stores?.length) {
        if (!store.state.user.profile.stores?.length && store.getters.isUserBuyer) {
          const storesData = await GET(`/company/buyerStores/${store.state.user?.profile?.id}`)
            .then(res => {
                return res.data;
            }).catch((error) => {
              console.log('Error while getting the stores for buyer', error);
              this.$emit('close');
              openDialogAlert('Error linking stores', `Something went wrong while fetching your store information. If the problem persists, try logging in again.`);
            });
          store.commit('updateStores', storesData);
          this.stores = storesData
        } else if (store.getters.isUserAdmin || this.isUserDsr){
          openToast('is-danger',`Admin users can't place bids`);
          this.$emit('close');
        }
      }
    },
    onChangeHighestBid() {
      this.highestBidRef = database.ref("highestBid/" + this.carId);

      this.highestBidRef.on("value", (snapshot) => {
        if (!snapshot.val()) return;
        this.highestBid = snapshot.val();

        // set the store here if it exists
        if (!this.highestBid.bidHistory) {
          return;
        }
        let i = this.highestBid.bidHistory.length;
        const currentUserId = Number(store.state.user.profile.id);
        for (let j=0; j<i; j++){
          if (this.highestBid.bidHistory[j].buyer.id === currentUserId) {
            this.selectedStore = this.highestBid.bidHistory[j].storeId;
            break;
          }
        }
      });
    },
    validateDropdown,
    toCurrency,
    displayWarningText(text, type='danger') {
      this.warningTextType = type;
      this.showWarningText = true;
      Vue.nextTick(() => {
        const warningTextContainers = document.getElementsByClassName('warning-text');
        Array.from(warningTextContainers).forEach(container => {
          container.innerHTML = text;
        })
      })
    },
    displayBuyItNowWarningText() {
      this.displayWarningText(`
        <p>You cannot place a proxy bid higher or equal to the Buy It Now price.</p>
        <p>To save time & money, just buy it now.</p>
      `);
    },
    proxyBidEnterPress(){
      const noDollarSignProxyBid = this.proxyBid.replace(/[$,]+/g,"")
      const proxyBidInt = parseInt(noDollarSignProxyBid)
      if(proxyBidInt > 0){
        this.confirmAndCloseProxy(this.selectedStore, this.proxyInt)
      }
      
    },
    validateForm() {
      return validateDropdown(this.selectedStore);
    },
    confirmBid(selectedStore) {
      this.loadingBid = true;
      if (this.highestBid.amount >= this.highestBidAmount + 100) {
        this.$emit("close");
        this.$buefy.toast.open({
          message:
            "The highest bid increased on this vehicle before we were able to submit your bid. Please try again",
          duration: 5000,
          type: "is-danger",
        });
        return;
      }
      this.$emit("doNotUpdateKey");
      fireEvent('Bid');
      POST(`vehicles/bid/${this.carId}`, {
        amount: this.highestBidAmount + 100,
        storeId: selectedStore,
      })
        .then(async (response) => {
          let storeTotalBidCount = response.data.storeTotalBidCount;
          if (storeTotalBidCount <= 10) {
            fireEvent('First 10');
          }

          if (response.data.httpStatus) {
            if (response.data.httpStatus === 400) {
              this.$buefy.toast.open({
                message: response.data.data.message,
                duration: 5000,
                type: "is-danger",
              });
              this.loadingBid = false;
              this.success = false;
              this.$emit("close");
            }
          }
        })
        .then((response) => {
          if (this.success) {
            this.$buefy.toast.open({
              message: "Bid placed successfully!",
              type: "is-success",
            });
            let updatedInfo = {
              store: selectedStore,
              storeName: this.stores.find(store => store.id == selectedStore).name,
              amount: this.highestBidAmount + 100,
            };
            this.$emit("onConfirmBid", updatedInfo);
            this.$emit("close");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            return tokenExpires();
          }

          this.loadingBid = false;
          this.$emit('close');

          const isStoreCannotBid = isStoreCannotBidError(error);

          if (isStoreCannotBid) {
            showStoreCannotBidErrorDialog(error);
            return;
          }
        if (isBiddingError(error)) {
            openErrorDialog({
                title: 'Error',
                message: error.response?.data?.message,
                error,
            });
            return;
        }

          this.$buefy.toast.open({
            message: error.response.data.message,
            duration: 5000,
            type: "is-danger",
          });
        });
    },
    confirmBuyItNow(selectedStore) {
      this.loadingBuyItNow = true;
      POST('orders/buyItNow', {
        vehicleListingId: this.carId.toString(),
        storeId: parseInt(selectedStore)
      }).then(() => {
          this.$buefy.toast.open({
            message: "Purchase done successfully!",
            duration: 5000,
            type: "is-success",
          });
          this.$emit("onConfirmBuyItNow");
          this.$emit("close");
        })
        .catch((error) => {
            this.loadingBuyItNow = false;
            this.$emit('close');
            const isStoreCannotBid = isStoreCannotBidError(error);

          if (isStoreCannotBid) {
              showStoreCannotBidErrorDialog(error);
            return;
          }
        if (isBiddingError(error)) {
            openErrorDialog({
                title: 'Error',
                message: error.response?.data?.message,
                error,
            });
            return;
        }

          this.$buefy.toast.open({
            message:
              "There was problem processing your purchase. Please, try again.",
            duration: 5000,
            type: "is-danger",
          });
        });
    },

    formatProxyBid() {
      this.proxyInt = parseInt(this.proxyBid.replace(/\$|,/g, ""));
      if (!this.proxyInt) this.proxyInt = 0;
      this.proxyBid = this.toCurrency(this.proxyInt);
    },
    confirmAndCloseProxy(selectedStore, proxyBid) {
      this.$emit("doNotUpdateKey");
      if (proxyBid >= this.highestBidAmount * 10) {
        this.$buefy.dialog.confirm({
          title: `High Bid Detected`,
          message: `You are about to place a proxy bid for ${proxyBid}. Would you like to continue with this amount?`,
          confirmText: "Submit Bid",
          type: "is-danger",
          hasIcon: true,
          cancelText: "Cancel",

          onConfirm: () => {
            this.onConfirmProxy(selectedStore, proxyBid);
            return;
          },
        });
        return;
      } else {
        this.onConfirmProxy(selectedStore, proxyBid);
        return;
      }
    },
    confirmAndCloseProxyEdit(selectedStore, proxyBid) {
      this.loading = true;
      this.$emit("doNotUpdateKey");
      if (proxyBid >= this.highestBidAmount * 10) {
        this.$buefy.dialog.confirm({
          title: `High Bid Detected`,
          message: `You are about to place a proxy-bid for ${proxyBid}. Would you like to continue with this amount?`,
          confirmText: "Submit Bid",
          type: "is-danger",
          hasIcon: true,
          cancelText: "Cancel",

          onConfirm: () => {
            this.onEditProxy(selectedStore, proxyBid);
            return;
          },
        });
        return;
      } else {
        this.onEditProxy(selectedStore, proxyBid);
        return;
      }
    },
    onConfirmProxy(selectedStore, proxyBid) {
      this.loadingProxy = true;
      if (this.highestBid.amount >= proxyBid) {
        this.$emit("close");
        this.$buefy.toast.open({
          message:
            "The highest bid increased on this vehicle before we were able to submit your bid. Please try again",
          duration: 5000,
          type: "is-danger",
        });
        return;
      }
      fireEvent('Proxy Bid');
      POST(`vehicles/proxybid/${this.carId}`, {
        amount: proxyBid,
        storeId: selectedStore,
      })
        .then(async (response) => {
          let storeTotalBidCount = response.data.storeTotalBidCount;
          if (storeTotalBidCount <= 10) {
            fireEvent('First 10');
          }

          if (response.data.httpStatus) {
            if (response.data.httpStatus === 400) {
              this.$buefy.toast.open({
                message: response.data.data.message,
                duration: 5000,
                type: "is-danger",
              });
              this.loadingBid = false;
              this.success = false;
              this.$emit("close");
              return;
            }
          }
        })
        .then(() => {
          this.$buefy.toast.open({
            message: "Proxy bid placed successfully!",
            type: "is-success",
          });
          let updatedInfo = {
            store: selectedStore,
            storeName: this.stores.find(store => store.id == selectedStore).name,
            amount: proxyBid,
          };
          this.$emit("onConfirmProxy", updatedInfo);
          this.$emit("close");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            return tokenExpires();
          }

          if (error.response?.data?.title?.includes('Illegal Proxy')) {
            this.$buefy.toast.open({
              message: error.response.data.message,
              duration: 5000,
              type: "is-danger",
            });
            Sentry.captureException(error);
            this.loadingProxy = false;
            return;
          }

          this.loadingProxy = false;
          this.$emit("close");

          const isStoreCannotBid = isStoreCannotBidError(error);

          if (isStoreCannotBid) {
            showStoreCannotBidErrorDialog(error);
            return;
          }

        if (isBiddingError(error)) {
            openErrorDialog({
                title: 'Error',
                message: error.response?.data?.message,
                error,
            });
            return;
        }

          this.$buefy.toast.open({
            message: error.response.data.message,
            duration: 5000,
            type: "is-danger",
          });
        });
    },
    onEditProxy(selectedStore, proxyBid) {
      this.loadingProxy = true;
      if (this.highestBid.amount >= proxyBid) {
        this.$emit("close");
        this.$buefy.toast.open({
          message:
            "The highest bid increased on this vehicle before we were able to submit your bid. Please try again",
          duration: 5000,
          type: "is-danger",
        });
        return;
      }
      POST(`vehicles/proxybid/${this.carId}`, {
        amount: proxyBid,
        storeId: selectedStore,
      })
        .then(async (response) => {
          if (response.data.httpStatus) {
            if (response.data.httpStatus === 400) {
              this.$buefy.toast.open({
                message: response.data.data.message,
                duration: 5000,
                type: "is-danger",
              });
              this.loadingBid = false;
              this.success = false;
              this.$emit("close");
              return;
            }
          }
        })
        .then(() => {
          this.$buefy.toast.open({
            message: "Proxy bid edited successfully!",
            type: "is-success",
          });
          let updatedInfo = {
            store: selectedStore,
            storeName: this.stores.find(store => store.id == selectedStore).name,
            amount: proxyBid,
          };
          this.$emit("onConfirmProxy", updatedInfo);
          this.$emit("close");
        })
        .catch((error) => {
          if (error.response.status === 401) {
            return tokenExpires();
          }
            this.loadingProxy = false;
            this.$emit("close");

          if (error.response?.data?.title?.includes('Illegal Proxy')) {
            this.$buefy.toast.open({
              message: error.response.data.message,
              duration: 5000,
              type: "is-danger",
            });
            Sentry.captureException(error);
            return;
          }

          const isStoreCannotBid = isStoreCannotBidError(error);

          if (isStoreCannotBid) {
            showStoreCannotBidErrorDialog(error);
            return;
          }
        if (isBiddingError(error)) {
            openErrorDialog({
                title: 'Error',
                message: error.response?.data?.message,
                error,
            });
            return;
        }

          this.$buefy.toast.open({
            message: error.response.data.message,
            duration: 5000,
            type: "is-danger",
          });
        });
    },
  },
};
</script>

<style scoped lang="postcss">
@media screen and (max-width: 768px) {
  .carmigo-modal {
    min-width: 80vw !important;
  }

  :deep(.modal-card-foot) {
    & .button {
      margin-bottom: 0;
    }
  }
}

.carmigo-modal {
  min-width: 400px;
}
h1 {
  font-size: 18px;
  padding-bottom: 0.25rem;
  font-weight: 600;
  color: var(--dark-blue);
}

/* styles in bulma.scss */
#proxy-bid.modal-card {

  @media screen and (max-width: 769px) {
    width: auto !important;

    & .modal-card-body {
      padding: 0 0 1rem;

      & .current-proxy {
        margin: 0;
        margin-top: 1rem;
      }
    }
  }
}

.current-proxy {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  & .current-bid-header {
    font-weight: normal;
    font-size: 1rem;
    &::after {
      content: ':';
    }
  }

  & #current-bid {
    font-weight: 500;
    color: #52aa57;
    margin-left: 0.50rem;
    font-size: 1.25rem;
    font-family: 'Poppins';
  }
}

.increment-bid-field {
  margin: 1rem 0;
}

.modal-card-foot {
  justify-content: flex-end;
}

.warning-text {
  font-size: 0.75rem;
  font-weight: 500;
}

:deep(.field) {
  margin-top: 1.25rem;
  & .label {
    font-size: 14px;
    font-weight: 600;
  }
}

:deep(#info-header) {
  & .image-thumbnail {
    border-radius: 10px !important;
    margin-left: 0.50rem;
  }

  & h1.info-title {
    font-size: 1rem !important;
    font-weight: 600;
  }
  & .info-subtitle {
    font-size: 13px;
  }
}
</style>

