const firebaseConfig = {
  dev: {
    apiKey: 'AIzaSyD2g_A9UYZfbYx_jBAcJ3SfyesM42eQv2s',
    authDomain: 'carmunity-dev.firebaseapp.com',
    databaseURL: 'https://carmunity-dev.firebaseio.com',
    projectId: 'carmunity-dev',
    storageBucket: 'carmunity-dev.appspot.com',
    messagingSenderId: '575779824223',
    appId: '1:575779824223:web:0e3c2fc11c7e9f828d31ea',
    measurementId: 'G-T460NH1PKK',
  },
  staging: {
    apiKey: 'AIzaSyCymc-Cqp821DAInhULqMrIDIlcFwoAdDI',
    authDomain: 'carmigo-staging.firebaseapp.com',
    databaseURL: 'https://carmigo-staging-default-rtdb.firebaseio.com',
    projectId: 'carmigo-staging',
    storageBucket: 'carmigo-staging.appspot.com',
    messagingSenderId: '212152816822',
    appId: '1:212152816822:web:e3ce8b1a5d070a87794217',
    measurementId: 'G-3EL0JZ088J',
  },
  prod: {
    apiKey: 'AIzaSyBOsHsKL6soCLjJl_0scuXgJX4PpYmCKl0',
    authDomain: 'carmunity-prod.firebaseapp.com',
    databaseURL: 'https://carmunity-prod.firebaseio.com',
    projectId: 'carmunity-prod',
    storageBucket: 'carmunity-prod.appspot.com',
    messagingSenderId: '669774811308',
    appId: '1:669774811308:web:a2e5a3e8230e7b5df2cf09',
    measurementId: 'G-NQ9XGYT2LK',
  },
};

export default firebaseConfig;
