<template>
  <div class='root'>
    <Loading v-if="loading" />

    <div>
      <div class="plaid-link" v-if="initial">

        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Link Bank Account</p>
          </header>
          <div class="cardContainer">

            <div class="card">

              <div class="card-content" v-if="updateMode">
                  There was an error via Plaid during the process of authenticating your bank account.
                  Please click the button below to re-authenticate your bank account.
              </div>
              <div class="card-content" v-if="!missingBank && !updateMode">
                  Please link a bank account as a funding source for automated ACH transfers.
                  Note that Carmigo does not collect or store your bank account information.
                  Press the button below to get started.
              </div>
              <div class="card-content" v-if="missingBank && !updateMode">
                  If your bank was missing from the list in Plaid, please hit the Bank Missing button below.
                  To re-launch Plaid Link, hit Register ACH.
                  <!-- <p>You will be prompted to input your bank account information submit.</p>

                  <p><b>Reminder that Carmigo does not collect or store your bank account information.</b></p> -->
              </div>
            </div>
          </div>
          <div class="modal-card-foot">
            <div class="buttonWrapper">  
              <b-button type="is-primary" v-show="!plaidLinkToken" :loading="!plaidLinkToken">Register ACH</b-button>
              <PlaidLink
                v-if="plaidLinkToken"
                :key="plaidLinkKey"
                clientName="Carmigo"
                :env="this.getPlaidEnvironmentValue()"
                :token="plaidLinkToken"
                :products="['auth']"
                :onLoad='plaidLinkOnLoad'
                :onSuccess='plaidLinkOnSuccess'
                :onExit='plaidLinkOnExit'
                :onEvent='plaidLinkOnEvent'
                >
                <template #bankMissingButton>
                  <b-button type="is-danger is-light" v-if="missingBank"  @click="bankIsMissing">Bank Missing</b-button>
                </template>
                <template #registerAchButton="{handleOnClick}">
                  <b-button type="is-primary" @click="handleOnClick">Register ACH</b-button>
                </template>
              </PlaidLink>
                
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { 
  // POST,
  // PUT,
  GET,
  savePlaidAccessToken,
  getPlaidEnvironment,
  getPlaidAccessToken,
  // checkHasBankInfo, 
  isPlaidItemValid} from '../api';
import PlaidLink from  '../components/PlaidLink'
import Loading from './Loading.vue'
import ComponentLoading from '../components/ComponentLoading.vue'
import { isUserSeller } from '../utils';


export default {
    name: "SelectPayment",
    //props: ['vehicle'],
    props: {
        CarmigoUserID: String,
        CarmigoStoreID: String,
        DestinationRepository: String,
        dwollaCustomerURL: String,
        directLaunchUpdateMode: Boolean,
    },
  data() {
    return {
      plaidLinkToken: "",
      plaidLinkKey: 0,
      CarmigoID: "",
      dwollaComplete: false,
      loading: false,
      componentLoading: false,
      initial: true,
      hasFundingSource: false,
      needsPlaidLinkUpdateMode: false,
      accountNameSave: "",
      accountIDSave: "",
      dwollaManual: false,
      missingBank: false,
      canHaveDwollaManual: true,
      updateMode: false,
    }
  },
  components: {
    PlaidLink,
    Loading,
    ComponentLoading,

  },

  async beforeMount(){
    if (this.DestinationRepository == 'profile') {
      this.CarmigoID = this.CarmigoUserID;
    }
    else if (this.DestinationRepository == 'company') {
      this.CarmigoID = this.CarmigoStoreID;
    }

    let existingPlaidInfo = await getPlaidAccessToken(this.CarmigoUserID, 'profile')
    let existingPlaidAccessToken = existingPlaidInfo.plaidAccessToken
    // console.log(existingPlaidAccessToken)

    if (existingPlaidAccessToken && existingPlaidAccessToken !== 'NO_TOKEN') {
      // console.log('in existing plaid token handler')
      // this.initial = false;
      this.updateMode = true;
      this.accountIDSave = existingPlaidInfo.plaidAccountId

      await Promise.all([this.getPlaidLinkTokenUpdate(existingPlaidAccessToken, this.CarmigoUserID), this.checkFundingSource()]);
    }
    else {
      await Promise.all([this.getPlaidLinkToken(), this.checkFundingSource()]);
    }
    
  },
  mounted() {

    

    // console.log(this.$store.state.user)

    // console.log(this.DestinationRepository, this.CarmigoID)
    this.loading = false;
  },

  methods: {
      isUserSeller,
      getPlaidEnvironmentValue() {
        return getPlaidEnvironment()
      },
      async exitPayment() {
        this.$buefy.toast.open({
          message: 'Success',
          type: 'is-primary',
          duration: 8000,
        });

        if(this.isUserSeller()) {
          // Leaving this commented out code in case we want to re-enable the routing in the future
          // this.$router.push('/dashboard/selling/0')
        } else {
          this.$router.push('/dashboard/buying/0')
        }
        this.$emit('close')
      },

      async ACH() {
        PlaidLink.handleOnClick();
      },

      async plaidLinkOnSuccess(access_token, metadata){
        this.loading = true;
        // console.log("ACCESS: ", access_token)
        // console.log("ACCOUNT: ", metadata.account_id)
        // console.log("METADATA: ", metadata)
        // console.log("ACCOUNT NAME: ", metadata.account.name)
        
        if (!metadata.account_id) {
          metadata.account_id = this.accountIDSave;
          metadata.account.name = this.accountNameSave;
        }
        const accountName = metadata.account.name;

        //We want to exit if there was already a funding source and not attempt to resave or re-add the funding source
        // This will flag true in the scenario that a user has already gone through and added their funding source but the plaid item ended up in error state
        if (this.hasFundingSource) {
          this.exitPayment();
          this.loading = false;
          return;
        }
        

        // console.log("PROCESSOR TOKEN FROM BACKEND: ", processorToken)
        await savePlaidAccessToken(access_token, metadata.account_id, this.CarmigoID, this.DestinationRepository)

        await setTimeout(async () => {
          const plaidItemCheck = await isPlaidItemValid(this.CarmigoUserID, 'profile')
          if (!plaidItemCheck) {
            this.needsPlaidLinkUpdateMode = true;
            this.needsRetry = true;
          }

          if (this.needsPlaidLinkUpdateMode) {
            this.$buefy.toast.open({
              message: 'Plaid Error - Please re-authenticate your account',
              type: 'is-danger',
              duration: 8000,
            })
            this.accountIDSave = metadata.account_id
            await this.getPlaidLinkTokenUpdate(access_token, this.CarmigoUserID);
            await this.rerenderPlaidLink();
            this.needsPlaidLinkUpdateMode = false;
            this.loading = false;
            return;
          }

          this.exitPayment();
          this.loading = false;

        }, 2000)


        

        // this.exitPayment();
        // this.loading = false;

      },
      async plaidLinkOnLoad(){

      },
      async plaidLinkOnExit(metadata){
        // console.log(metadata)
        this.missingBank = true;

        
      },
      async plaidLinkOnEvent(){

      },
      async getPlaidLinkToken() {
        this.componentLoading = true;
        await GET('/plaid/createPlaidLinkToken').then((resp) => {
          this.plaidLinkToken = resp.data.token;
          // console.log(this.plaidLinkToken);
        })
        this.componentLoading = false;
      },
      async getPlaidLinkTokenUpdate(plaidAccessToken, userID) {
        this.componentLoading = true;
        await GET(`/plaid/createPlaidLinkTokenUpdateMode/${plaidAccessToken}/${userID}`).then((resp) => {
          this.plaidLinkToken = resp.data.token;
          // console.log("second plaid token: ", this.plaidLinkToken);
        })
        this.componentLoading = false;
      },
      async checkFundingSource() {
        this.hasFundingSource = await isPlaidItemValid(this.CarmigoID, 'profile')
      },
      rerenderPlaidLink() {
        // console.log("re-initializing?")
        this.plaidLinkKey += 1;
      },
      bankIsMissing() {
        this.$buefy.dialog.confirm({
          title: 'Bank Not Listed',
          message: `If you continue, you will no longer be able to automatically link your bank account. 
            If, in the future, you wish to link a bank account, please contact support for assistance.
            Are you sure you want to continue?`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'information-outline',
          ariaRole: "alertdialog",
          ariaModal: true,
          confirmText: 'Bank Missing',
          cancelText: 'Cancel',
          onConfirm: () => {
            //we want to save something in the plaid related fields so we know they were not unintentionally left blank
            savePlaidAccessToken('BANK_MISSING', 'BANK_MISSING', this.CarmigoID, this.DestinationRepository);
            this.exitPayment();
          }
        });
      },
      
  },
};
</script>

<style scoped lang="postcss">
.cardContainer {
  display: flex;
  justify-content: space-between;
}

.card-content {
  text-align: justify;
  text-align-last: center;
}

.radioContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* padding: 10px; */
}
</style>
