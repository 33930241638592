
import { computed, defineComponent, onMounted, PropType } from 'vue';
import store from '@/vuex';
import { useUser } from '@/composables';
import { marketplaceCurrentOfferDTO } from '../types';
import { 
    isMarketplaceOfferPending, 
    isUserAdmin, 
    openModal, 
    shouldOpenMarketplaceOfferModal, 
    toCurrency, 
    updateMarketplaceModalWithIncomingOffer, 
    updateMarketplaceNegotiationModalWithCurrentOffer 
} from '../utils';

import AppButton from './AppButton.vue';
import AppButtonWithSecondaryInfo from './AppButtonWithSecondaryInfo.vue';
import TheBuyItNowButton from './TheBuyItNowButton.vue';
import TheMarketplaceBuyItNowModal from './TheMarketplaceBuyItNowModal.vue';
import TheMarketplaceListingOfferModal from './TheMarketplaceListingOfferModal.vue';
import TheNegotiationModalMarketplace from './TheNegotiationModalMarketplace.vue';
import TheNegotiateButtonMarketplace from './TheNegotiateButtonMarketplace.vue';
import TheMarketplaceManageOffersButton from './TheMarketplaceManageOffersButton.vue';
import { useUserRole } from '../composables';

export default defineComponent({
    inheritAttrs: false,
    name: 'TheMarketplaceListingButtons',
    props: {
        marketplaceListingId: {
            type: Number,
            required: true,
        },
        vehicleListingId: {
            type: Number,
            required: true,
        },
        reservePrice: {
            type: Number,
            required: true,
        },
        buyItNowPrice: {
            type: Number,
            required: true,
        },
        currentOffer: {
            type: Object as PropType<marketplaceCurrentOfferDTO>,
            required: false,
        },
        isDashboardButton: {
            type: Boolean,
            default: false,
        },
        sellerPersonId: {
            type: Number,
            required: true,
        },
        sellerStoreId: {
            type: Number,
            required: false,
        },
        marketplaceOfferCount: {
            type: Number,
            required: false,
        },
        showSellerManageOffers: {
            type: Boolean,
            default: false,
        },
        canBuyItNow: {
            type: Boolean,
            default: false,
        },
        isSecondChance: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppButton,
        AppButtonWithSecondaryInfo,
        TheBuyItNowButton,
        TheMarketplaceManageOffersButton,
        TheNegotiateButtonMarketplace,
    },
    emits: ['offerMade', 'incomingOffer'],
    setup(props, context) {
        const user = useUser();
        const { isUserDsr } = useUserRole();
        const isAdmin = computed(() => isUserAdmin(user.value));
        const isVehicleSeller = computed(() => {
            if (Number(user.value?.uid) === props.sellerPersonId) {
                return true;
            }
            
            return false;
        });
        const buyerStores = computed(() => user.value.profile.stores || []);

        const buyerHasMadeOffer = computed(() => props.currentOffer?.buyerOfferAmount);
        const sellerHasMadeOffer = computed(() => props.currentOffer?.sellerOfferAmount);

        function openMarketplaceOfferModal() {
            openModal({
                component: TheMarketplaceListingOfferModal,
                customClass: `marketplace-offer-modal-${props.marketplaceListingId}`,
                props: {
                    isSecondChance: props.isSecondChance,
                    marketplaceListingId: props.marketplaceListingId,
                    vehicleListingId: props.vehicleListingId,
                    reservePrice: props.reservePrice,
                    buyItNowPrice: props.buyItNowPrice,
                    stores: buyerStores.value,
                    initialStoreId: props.currentOffer?.buyerStoreId,
                    sellerPersonId: props.sellerPersonId,
                    currentOffer: props.currentOffer 
                        ? {
                            marketplaceOfferId: props.currentOffer?.marketplaceOfferId,
                            buyerOfferAmount: props.currentOffer?.buyerOfferAmount,
                            offerExpirationDate: props.currentOffer?.offerExpirationDate,
                            mostRecentOffererTypeId: props.currentOffer?.mostRecentOffererTypeId,
                            sellerOfferAmount: props.currentOffer.sellerOfferAmount,
                          }
                        : undefined,
                }, 
                events: {
                    offerMade: () => {
                        context.emit('offerMade');
                    }
                }
            });
        }

        function openBuyItNowModal() {
            openModal({
                component: TheMarketplaceBuyItNowModal,
                props: {
                    marketplaceListingId: props.marketplaceListingId,
                    vehicleListingId: props.vehicleListingId,
                    buyItNowPrice: props.buyItNowPrice,
                    stores: buyerStores.value,
                }
            })
        }

        const userType = computed(() => (isVehicleSeller.value ? 'seller' : 'buyer'));
        function openMarketplaceNegotiationModal() {
            if (!props.currentOffer) {
                return;
            }

            openModal({
                component: TheNegotiationModalMarketplace,
                customClass: `marketplace-negotiate-modal-${props.marketplaceListingId}`,
                props: {
                    vehicleListingId: props.vehicleListingId,
                    marketplaceListingId: props.marketplaceListingId,
                    marketplaceOfferId: props.currentOffer.marketplaceOfferId,
                    storeId: props.currentOffer.buyerStoreId,
                    negotiationStatus: isMarketplaceOfferPending(props.currentOffer.offerStatusId, userType.value) ? 'in progress' : 'rejected',
                    negotiatingUser: userType.value,
                    buyerOfferAmount: props.currentOffer.buyerOfferAmount,
                    sellerOfferAmount: props.currentOffer.sellerOfferAmount,
                    buyItNowPrice: props.buyItNowPrice,
                    reservePrice: props.reservePrice,
                    displayMessage: props.currentOffer.mostRecentMessage,
                    allMessages: props.currentOffer.messages,
                    isSecondChance: props.isSecondChance,
                }
            });
        }

        onMounted(() => {
            if (!isVehicleSeller.value && props.currentOffer?.sellerOfferAmount) {
                updateOpenModal();
            }
        });

        function updateOpenModal() {
            updateMarketplaceModalWithIncomingOffer({
                modalClass: `marketplace-offer-modal-${props.marketplaceListingId}`,
                userType: userType.value,
                displayUpdatedToast: true,
                callback: (modalContext) => {
                    modalContext?.close();
                    openMarketplaceNegotiationModal();
                }
            });
            updateMarketplaceModalWithIncomingOffer({
                modalClass: `marketplace-negotiate-modal-${props.marketplaceListingId}`,
                displayUpdatedToast: true,
                userType: userType.value,
                callback: (modalContext) => {
                    updateMarketplaceNegotiationModalWithCurrentOffer({
                        modalContext,
                        currentOffer: props.currentOffer,
                        userType: userType.value
                    });
                }
            });
        }

        function openMarketplaceModal() {
            if (shouldOpenMarketplaceOfferModal({
                offerExpirationDate: props.currentOffer?.offerExpirationDate,
                sellerOfferAmount: props.currentOffer?.sellerOfferAmount,
                buyerOfferAmount: props.currentOffer?.buyerOfferAmount,
                sellerPersonId: props.sellerPersonId,
            })) {
                openMarketplaceOfferModal();
            } else {
                openMarketplaceNegotiationModal();
            }
        }

        return {
            toCurrency,
            openBuyItNowModal,
            openMarketplaceOfferModal,
            openMarketplaceNegotiationModal,
            buyerHasMadeOffer,
            sellerHasMadeOffer,
            isVehicleSeller,
            isAdmin,
            isUserDsr,
            userType,
            openMarketplaceModal,
        };

    }
});
