<template>
    <div v-if="$attrs.loading" class="info-highlight loading-skeletons flex-column"> 
        <AppLoadingSkeleton height="15px" width="75px" />
        <AppLoadingSkeleton height="21px" :width="$attrs.skeletonWidth ?? '100px'" />
    </div>
    <div v-else class="info-highlight display-flex flex-column" :class="color + ' ' + background + ' ' + size">
        <div class="label-and-flag flex-row align-center">
            <p class="label">{{ label }}</p>
            <div v-if="flag" class="flag">{{ flag }}</div>
        </div>
        <slot>
            <p class="is-poppins info">{{ info }}</p>
        </slot>
    </div>
</template>

<script>
import AppLoadingSkeleton from '../AppLoadingSkeleton.vue';

export default {
    name: "InfoHighlight",
    props: [
        "label",
        "info",
        "flag", // optional
        "color",
        "background", // 'fill' or 'blank'
        "size", // 'large' or leave empty
    ],
    components: {
        AppLoadingSkeleton,
    }
}
</script>

<style scoped lang="postcss">
.green {
    color: #52aa57;
}

.orange {
    color: #FFA959;
}

.info-highlight {
    font-size: .9rem;
    line-height: 1.5em;

    margin-left: 0;
    padding: 1.25em .75em;
    padding-top: 1em;

    &:first-child {
        padding-left: 0;
    }

    &:not(:last-child) {
        margin-right: .75rem;
    }

    &.large {
        font-size: 1.15rem;
    }

    &.blank {
        padding: 0;
        margin-top: .3em;
    }

    & .label {
        text-transform: uppercase;
        font-size: .7em;
        font-weight: 500;
        margin-bottom: 0 !important;
        line-height: 1rem;
    }

    & .flag {
        color: white;
        background-color: #52aa57;
        text-transform: uppercase;
        font-size: .6em;
        font-weight: 700;
        padding: 5px;
        margin-left: 5px;
        line-height: .5rem;
    }

    & .info {
        font-size: 1em;
    }
}

.fill {
    position: relative;
    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        border-radius: 8px;
        background: currentColor;
        opacity: .1;
    }
}
</style>