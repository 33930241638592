var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppButtonWithSecondaryInfo',_vm._b({staticClass:"marketplace-offers-button",on:{"click":_vm.openMarketplaceOffersModal}},'AppButtonWithSecondaryInfo',{
        label: _vm.isReadOnly ? 'View Offers' : 'Manage Offers',
        info: _vm.marketplaceOfferCount ? `(${_vm.marketplaceOfferCount})` : '',
        disabled: !_vm.marketplaceOfferCount,
        type: 'is-primary',
        expanded: true,
        ..._vm.$attrs
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }