
import { defineComponent, PropType } from 'vue';

import AppFlag from './AppFlag.vue';

export default defineComponent({
    name: 'TheListingDetailsVehicleAttributes',
    props: {
        isInoperable: {
            type: Boolean,
            required: false,
        },
        isWholesale: {
            type: Boolean,
            required: false,
        },
        isFrontline: {
            type: Boolean,
            required: false,
        },
    },
    components: {
        AppFlag,
    },
});
