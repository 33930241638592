var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-notification',_vm._b({on:{"close":function($event){return _vm.$emit('close')}}},'b-notification',{
        ..._vm.notification,
        ..._vm.$attrs,
    },false),[_c('div',{staticClass:"flex-row align-center justify-space-between"},[_c('div',{staticClass:"notification-message",domProps:{"innerHTML":_vm._s(_vm.notification?.message)}}),_vm._t("button",function(){return [(_vm.notification.onAction && _vm.notification.actionText)?_c('AppButton',_vm._b({staticClass:"carmigo-button ml-2",on:{"click":function($event){_vm.notification.onAction($event); _vm.$emit('onAction', $event); _vm.$emit('close', $event)}}},'AppButton',{
                        label: _vm.notification.actionText,
                        type: _vm.notification.type,
                        size: 'is-small',
                    },false)):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }