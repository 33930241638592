
import { computed, ComputedRef, defineComponent } from 'vue';
import store from '@/vuex';

export default defineComponent({
  name: 'Loading',
  props: {
    showOverlay: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const getImgSrc: ComputedRef<string> = computed(() => {
      const logoUrlSquare = store?.state?.branding?.logoUrlSquare;
      if (logoUrlSquare) {
        return logoUrlSquare;
      }

      return require('@/assets/icon.png');
    });

    return {
      getImgSrc,
    }
  }
});
