
import { computed, defineComponent, PropType } from 'vue';
import { NegotiationMessage } from '../types';
import { formatDate } from '../utils';

import ChatMessageBody from './Chat/ChatMessageBody.vue';
import ChatMessageSender from './Chat/ChatMessageSender.vue';

export default defineComponent({
    name: 'TheNegotiationMessage',
    props: {
        messages: {
            type: Array as PropType<NegotiationMessage[]>,
            required: true,
        },
        sender: {
            type: Object as PropType<{ firstName: string, lastName: string }>,
            required: true,
        },
        senderIsMe: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ChatMessageBody,
        ChatMessageSender,
    },
    setup(props) {
        const color = computed(() => {
            return props.senderIsMe ? 'primary' : 'primary-dark';
        });

        return {
            formatDate,
            color,
        }
    }
});
