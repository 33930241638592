import { ancillaryOption, CheckoutFormData, CheckoutForm, CheckoutFormStep, OrderOrderFeatureDTO } from "@/types";

export function getDefaultArbitrationOption(arbitrationOptionName: 'standard' | 'extended' | 'premium', overridePrice?: number): ancillaryOption & { description?: string } {
    let defaultArbitrationOptions: { [name: string]: ancillaryOption & { description?: string } } = {
        standard: {
            name: 'standard',
            price: 0,
            selected: false,
            description: 'Our standard arbitration plan gives you a 3-day window of arbitration*',
        },
        extended: {
            name: 'extended',
            price: 150,
            selected: false,
            description: 'Extend our standard arbitration to last for 10 days*',
        },
        premium: {
            name: 'premium',
            price: 195,
            selected: false,
            description: 'Extend our standard arbitration to last for 17 days*',
        },
    }

    let arbitrationOption = defaultArbitrationOptions[arbitrationOptionName];
    arbitrationOption.price = overridePrice ??  arbitrationOption.price;
    return arbitrationOption;
}

export function setupOrderOrderFeaturesForCheckout(checkoutFormResponses: CheckoutForm, orderOrderFeatures: OrderOrderFeatureDTO[]) {
    orderOrderFeatures.forEach(feature => {
        switch(feature.name) {
            case 'photos':
                feature.selected = checkoutFormResponses.purchaseCarmigoPhotos ?? false;
                break;
            case 'carmigoFloatFee': 
                feature.selected = checkoutFormResponses.useCarmigoFloat ?? false;
                break;
            case 'standard arbitration':
                feature.selected = checkoutFormResponses.selectedArbitrationPlan == 'standard';
                break;
            case 'extended arbitration':
                feature.selected = checkoutFormResponses.selectedArbitrationPlan == 'extended';
                break;
            case 'premium arbitration':
                feature.selected = checkoutFormResponses.selectedArbitrationPlan == 'premium';
                break;
            case 'transportation':
                feature.selected = checkoutFormResponses.useCarmigoTransportation ?? false;
                break;
        }
    });

    return orderOrderFeatures;
}

export function getStoreFacilitatedCheckoutServiceMessage(serviceType: 'transportation' | 'arbitrations' | 'title' | 'payment', facilitatingStoreName: string): string {
    switch (serviceType) {
        case 'transportation':
            return `Transportation is organized by ${facilitatingStoreName}. They will reach out to you to finalize transportation details.`;
        case 'arbitrations':
            return `Arbitrations for this purchase will go through ${facilitatingStoreName}. If this purchase needs arbitrated, contact ${facilitatingStoreName}.`;
        case 'title':
            return `The title will be transferred and processed by ${facilitatingStoreName}. They will reach out to you to confirm the mailing address.`;
        case 'payment':
            return `Payment is collected and distributed by ${facilitatingStoreName}. They will contact you to collect payment information`;
    }
}

export function getCheckoutDataAndStepsFromOrderOrderFeatures(orderOrderFeatures: OrderOrderFeatureDTO[], isFacilitatingAuction: boolean=false): {
    checkoutData: CheckoutFormData,
    checkoutSteps: CheckoutFormStep[]
} {
    const checkoutData: CheckoutFormData = {};
    const checkoutSteps: CheckoutFormStep[] = ['orderSummary'];

    orderOrderFeatures.forEach(oof => {
        let price = oof.overridePrice ?? oof.price;
        switch (oof.name) {
            case 'transportation':
                checkoutSteps.push('transportation');
                checkoutData.transportationCost = price;
                break;
            case 'buyerFee':
                checkoutData.buyerFee = price;
                break;
            case 'photos':
                if (!isFacilitatingAuction) {
                    checkoutData.photosPrice = price;
                    checkoutSteps.push('photos');
                }
                break;
            case 'carmigoFloatFee':
                if (!isFacilitatingAuction) {
                    checkoutData.floatFee = price;
                    checkoutSteps.push('carmigoFloat');
                }
                break;
            case 'standard arbitration':
            case 'extended arbitration':
            case 'premium arbitration':
                if (checkoutData.arbitrationOptions == undefined) {
                    checkoutData.arbitrationOptions = Array(3);
                    checkoutSteps.push('arbitrations');
                }
                let arbitrationType = oof.name.substring(0, oof.name.indexOf(' arbitration')) as 'standard' | 'extended' | 'premium';
                let arbitrationOption = getDefaultArbitrationOption(arbitrationType, price);
                // can't do a regular sort because arbitration option ids are also out of order, so have to find the array idx based on arbitrationType and insert directly there
                let idx = ['standard', 'extended', 'premium'].indexOf(arbitrationType);
                checkoutData.arbitrationOptions[idx] = arbitrationOption;
                break;
            default: 
                break;
        }
    });

    return {
        checkoutData,
        checkoutSteps,
    }
}

export function orderAvailableCheckoutSteps({ availableCheckoutSteps, possibleCheckoutSteps, additionalDetailsSteps=['carmigoFloat'] }: {
    availableCheckoutSteps: CheckoutFormStep[], 
    possibleCheckoutSteps: CheckoutFormStep[], 
    additionalDetailsSteps?: CheckoutFormStep[]
}): CheckoutFormStep[] {
    // filter out steps that aren't available, except additional details (that gets handled below)
    let orderedCheckoutSteps = possibleCheckoutSteps.filter((step: CheckoutFormStep) => step == 'additionalDetails' || availableCheckoutSteps.includes(step));

    // remove additionalDetails if there is not an available field
    let hasAdditionalDetails = additionalDetailsSteps.some((additionalDetail: CheckoutFormStep) => availableCheckoutSteps.includes(additionalDetail));
    if (!hasAdditionalDetails) {
        let idxToRemove = orderedCheckoutSteps.indexOf('additionalDetails');
        if (idxToRemove >= 0) {
            orderedCheckoutSteps.splice(idxToRemove, 1);
        }
    }
    return orderedCheckoutSteps;
}