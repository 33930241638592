import { postAnalyticsData } from './utils/analyticsUtils';

export function fireEvent(event: string, body?: Record<string, any>) {
  postAnalyticsData(event);


    if (typeof window["analytics"] == "undefined"){
        return;
    }
    window["analytics"].track(event, body);
    window.dataLayer.push({
        event: event,
        ecData: body,
      });
}

export function fireIdentify(id: string, body: any){
    if(typeof window["analytics"] == "undefined"){
        return;
    }
    window["analytics"].identify(id, body);
}
