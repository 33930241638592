<template>
    <AuctionStat 
        name="Views"
        icon="eye"
        :value="viewCount"
        :showLabel="showLabel"
        useIcon
    />
</template>

<script>
import AuctionStat from "./AuctionStat.vue"
export default {
    name: 'AuctionStatViews',
    props: {
        viewCount: Number,
        showLabel: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        AuctionStat
    },
}
</script>

<style scoped lang='postcss'>
.auction-stats {
    color: #a3a3a3;
}

.icon {
    color: currentColor;
}

.auction-stats {
    & h2 {
        font-weight: 400;
    }
  & img {
    margin-right: 5px;
  }

  color: #b9b9b9;
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;

  @media screen and (max-width: 380px) {
      font-size: 12px;
  }
}

</style>