var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-field',_vm._b({},'b-field',{
        label: 'Email',
        type: _vm.needsValidation ? _vm.getValidateStyle : '',
        message: _vm.needsValidation ? _vm.getValidateMessage : '',
        ..._vm.$attrs.fieldAttrs,
    },false),[_c('b-input',_vm._b({on:{"icon-right-click":_vm.clearEmail},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keypressEnter', $event)},"change":function($event){_vm.needsValidation=true}},model:{value:(_vm.emailInput),callback:function ($$v) {_vm.emailInput=$$v},expression:"emailInput"}},'b-input',{
            placeholder: 'Email',
            minlength: 1,
            type: 'email',
            icon: 'email',
            iconRight: 'close-circle',
            iconRightClickable: true,
            ..._vm.$attrs,
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }