
import { defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { assignInspectorToListing, getProfileLiteByRole } from '../api';
import { openToast } from '../utils';

import AutocompleteInput from './AutocompleteInput.vue';

export default defineComponent({
    name: 'TheAssignInspectorInput',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        inspectorPersonId: {
            type: Number,
            required: false,
        },
        customSubmit: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AutocompleteInput,
    },
    emits: ['select', 'assignInspector'],
    setup(props, { emit, attrs }) {
        const assignedInspectorPersonId = ref(props.inspectorPersonId);
        const assignedInspector = ref(undefined);
        
        // get inspector options
        const inspectorOptions = ref([]);
        const loadingInspectorOptions: Ref<boolean> = ref(false);
        const inspectorKey = ref(0);
        onMounted(async() => {
            loadingInspectorOptions.value = true;
            await getProfileLiteByRole(['inspector', 'admin'], undefined, {
                onSuccess: (res) => {
                    loadingInspectorOptions.value = false;
                    inspectorOptions.value = res.map(value => {
                        return {
                            personId: value.personId,
                            name: `${value.firstName} ${value.lastName}`,
                        }
                    });
                },
                onError: () => loadingInspectorOptions.value = false,
            });
            if (assignedInspectorPersonId.value) {
                assignedInspector.value = inspectorOptions.value.find(user => user.personId == assignedInspectorPersonId.value);
                emit('select', assignedInspector.value);
                inspectorKey.value++;
            }
        });

        const loadingSaveInspector: Ref<boolean> = ref(false);
        async function saveAssignedInspector(inspector) {
            if (!inspector) {
                return;
            }
            emit('select', inspector)
            loadingSaveInspector.value = true;
            await assignInspectorToListing(inspector?.personId, props.vehicleListingId, {
                onSuccess: () => {
                    emit('assignInspector', inspector)
                    loadingSaveInspector.value = false;
                    inspector.personId 
                        ? openToast('is-success', 'Inspector assigned!')
                        : openToast('is-success', 'Inspector unassigned');
                },
                onError: () => loadingSaveInspector.value = false,
            });
        }

        return {
            inspectorOptions,
            loadingInspectorOptions,
            assignedInspector,
            saveAssignedInspector,
            loadingSaveInspector,
            inspectorKey,
        }
    },
});
