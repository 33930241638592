var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"views-and-counts"},[_c('AuctionStat',_vm._b({class:{ 'opacity-50': !_vm.$props.viewCount }},'AuctionStat',{
            name: 'views',
            value: _vm.$props.viewCount,
            ..._vm.$attrs,
        },false)),(_vm.offerCount === undefined)?_c('AuctionStat',_vm._b({class:{ 'opacity-50': !_vm.$props.bidCount }},'AuctionStat',{
            name: 'bids',
            value: _vm.$props.bidCount,
            ..._vm.$attrs,
        },false)):_c('AuctionStat',_vm._b({class:{ 'opacity-50': !_vm.$props.offerCount }},'AuctionStat',{
            name: 'offers',
            value: _vm.$props.offerCount,
            ..._vm.$attrs,
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }