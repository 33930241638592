
import { defineComponent, ref } from 'vue';
import { negotiationModalWithMessagesProps } from '../types';

import AppButton from './AppButton.vue';
import TheNegotiationModal from './TheNegotiationModal.vue';
import TheNegotiationMessageInput from './TheNegotiationMessageInput.vue';
import TheNegotiationMessageHistory from './TheNegotiationMessageHistory.vue';
import TheNegotiationMessageMostRecent from './TheNegotiationMessageMostRecent.vue';

export default defineComponent({
    name: 'TheNegotiationModalWithMessages',
    props: {
        ...negotiationModalWithMessagesProps,
    },
    components: {
        AppButton,
        TheNegotiationModal,
        TheNegotiationMessageInput,
        TheNegotiationMessageHistory,
        TheNegotiationMessageMostRecent,
    },
    setup(props, context) {
        const viewAllMessages = ref(false);
        return {
            viewAllMessages,
        }
    }
})
