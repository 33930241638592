
import { computed, defineComponent, onMounted, PropType, watch } from 'vue';
import { useNotificationFeed, useNotificationListener } from '../composables';
import { MessageModality } from '../enums';
import { InAppNotification } from '../types';
import { markAllNotificationsAsRead, updateNotification } from '../api';
import store from '@/vuex';

import AppButton from './AppButton.vue';
import AppLoading from './AppLoading.vue';
import AppNotification from './AppNotification.vue';

export default defineComponent({
    name: 'TheNotificationFeed',
    props: {},
    components: {
        AppButton,
        AppLoading,
        AppNotification,
    },
    emits: ['close'],
    setup(props, { emit, attrs }) {
        const userId = computed(() => store?.state?.user?.profile?.userId);
        const { notifications, loadingNotifications, getUserNotifications, removeNotification, removeAllNotifications, notificationError } = useNotificationFeed(userId.value);
        onMounted(getUserNotifications);

        function markNotificationRead(notification: InAppNotification) {
            updateNotification({
                notificationId: notification.id,
                modalityId: MessageModality[notification.modality],
                notificationRead: true,
            });
        }
        
        useNotificationListener(userId, {
            onSnapshot: ({ newSnapshotValue }) => {
                if (newSnapshotValue?.inAppNotifications) {
                    getUserNotifications(false);
                }
            }
        });

        function clearAllNotifications() {
            markAllNotificationsAsRead();
            removeAllNotifications();
        }

        return {
            notifications,
            loadingNotifications,
            removeNotification,
            markNotificationRead,
            getUserNotifications,
            notificationError,
            clearAllNotifications,
        }
    },
});
