import { BidHistoryBid, BidHistoryListing, HighestBidRefRecord } from "@/types";
import { reactive, Ref, ref, SetupContext } from "vue";
import { useFetchBidHistory } from "./vdp";
import { openModal, OpenModalConfig } from "@/utils";
import { BModalComponent } from "buefy/types/components";

import TheBidHistoryModal from '@/components/TheBidHistoryModal.vue';

export type BidHistoryHighlight = {
    title: 'Highest Bid' | 'Highest Proxy' | 'Reserve Price' | 'Buy-It-Now Price',
    value: number, 
    flag?: string,
}

export function useBidHistoryHighlights({ bidHistory, highestBidAmount, highestProxyBidAmount, reservePrice, reserveMet, buyItNowPrice,  }: {
    bidHistory: BidHistoryBid[],
    highestBidAmount?: number,
    highestProxyBidAmount?: number,
    buyItNowPrice?: number,
    reservePrice: number,
    reserveMet?: boolean,
}) {
    const highlights = reactive({
        highestBid: {
            title: 'Highest Bid',
            value: highestBidAmount,
        },
        highestProxy: {
            title: 'Highest Proxy',
            value: highestProxyBidAmount,
        },
        reservePrice: {
            title: 'Reserve Price',
            value: reservePrice,
            flag: reserveMet ? 'met' : '',
        },
        buyItNowPrice: {
            title: 'Buy-It-Now Price',
            value: buyItNowPrice,
        }
    });

    return {
        highlights,
    }
}

export function useBidHistoryBuyers({ bidHistory }: {
    bidHistory: BidHistoryBid[],
}) {
    const uniqueBuyers: { name: string, storeName: string, personId: number }[] = [];
    const uniqueStores: string[] = [];
    bidHistory.forEach(bid => {
        if (!uniqueBuyers.some(buyer => buyer.name == bid.buyerName)) {
            uniqueBuyers.push({
                name: bid.buyerName,
                storeName: bid.storeName,
                personId: bid.personId,
            });
        }
        if (!uniqueStores.includes(bid.storeName)) {
            uniqueStores.push(bid.storeName)
        }
    });
    
    return {
        buyers: uniqueBuyers,
        stores: uniqueStores,
    }
}

export function useBidHistoryModal({ context }: {
    context: SetupContext<(any)[]>,
}) {
    const openedModal: Ref<BModalComponent | null> = ref(null);
    const loadingBidHistory = ref(false);
    const loadingBidHistoryVehicleListingId: Ref<number | undefined> = ref(undefined);

    async function openBidHistoryModal({ vehicleListingId, vehicle, bidHistory, highestBid, highestProxyBid, modalConfig={} }: {
        vehicleListingId: number,
        vehicle: BidHistoryListing,
        bidHistory?: BidHistoryBid[],
        highestBid?: HighestBidRefRecord,
        highestProxyBid?: BidHistoryBid | null,
        modalConfig?: OpenModalConfig,
    }) {

        if (openedModal.value) {
            return false;
        }

        if (!bidHistory) {
            openBidHistoryModalWithFetch({ vehicleListingId, vehicle, highestBid, highestProxyBid, modalConfig });
            return;
        } else {
            openedModal.value = openModal({
                component: TheBidHistoryModal,
                props: {
                    vehicleListingId,
                    vehicle,
                    bidHistory,
                    highestBid,
                    highestProxyBid,
                },
                events: {
                    close: () => {
                        openedModal.value = null;
                        context.emit('close');
                    }
                },
                ...modalConfig,
            });
            context.emit('open', openedModal.value);
        }
    }

    function openBidHistoryModalWithFetch({ vehicleListingId, vehicle, highestBid, highestProxyBid, modalConfig={} }: {
        vehicleListingId: number,
        vehicle: BidHistoryListing,
        highestBid?: HighestBidRefRecord,
        highestProxyBid?: BidHistoryBid | null,
        modalConfig?: OpenModalConfig,
    }) {
        loadingBidHistoryVehicleListingId.value = vehicleListingId;
        loadingBidHistory.value = true;

        useFetchBidHistory(vehicleListingId, {
            onSuccess: (bidHistoryFetched) => {
                loadingBidHistoryVehicleListingId.value = undefined;
                loadingBidHistory.value = false;

                openedModal.value = openModal({
                    component: TheBidHistoryModal,
                    props: {
                        vehicleListingId,
                        vehicle,
                        bidHistory: bidHistoryFetched,
                        highestBid,
                        highestProxyBid,
                    },
                    events: {
                        close: () => {
                            openedModal.value = null;
                            context.emit('close');
                        }
                    },
                    ...modalConfig,
                });
                context.emit('open', openedModal.value);
            },
            onError: () => {
                loadingBidHistory.value = false;
                loadingBidHistoryVehicleListingId.value = undefined;
            },
        });
    }

    return {
        openBidHistoryModal,
        loadingBidHistory,
        loadingBidHistoryVehicleListingId,
    }
}