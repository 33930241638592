<template>
  <b-field label="User name">
    <b-input
      @input="nameCheck"
      type="is-success"
      placeholder="John Doe"
      :has-counter="false"
      value=""
      v-model="userName"
    ></b-input>
  </b-field>
</template>

<script>
import { GET } from "../api";
export default {
  name: "SearchByUserName",

  // "startSearch" is passed in to start the getUsers() function. This way we can use custom buttons in other components to begin the search function. When this value is true, then the search function will fire.
  // Be sure to reset this from wherever it was set after this component has returned the its array.
  props: ["startSearch"],
  data() {
    return {
      userName: "",
      validName: false,
      users: [],
      number: 0,
    };
  },
  methods: {
    // Make sure that the search is at least 2 characters long.
    nameCheck() {
      if (this.userName.length > 1) {
        this.validName = false;
      } else {
        this.validName = true;
      }
      // Let parent component know if this is a valid length.
      this.$emit("validName", this.validName);
    },

    // Get users by username. This will emit ProfileDTO[] | []
    async getUser() {
      try {
        const response = await GET(`/auth/userByName/${this.userName}`);
        this.users = response.data;
        this.$emit("users", this.users);
      } catch (error) {
        console.log(error);
        this.users = [];
        // Let parent component know the array is ready. (This array could be empty, handle accordingly on parent component.)
        this.$emit("users", this.users);
      }
    },
  },

  watch: {
    // This is where we watch for the startSearch prop to be updated. When it is changed to true, then we search for the users
    startSearch: async function() {
      if (this.startSearch) {
        this.startSearch = false;
        return await this.getUser();
      }
    // This is for when searchStart is reset on the parent component. We do not want to search if searchStart is false.
      return;
    },
  },
};
</script>

<style></style>
