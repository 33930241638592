export function addInfoToArray(array: any[], title: string, value: any, canEdit: boolean=true) {
    array.push({
      title,
      value,
      canEdit
    });
    return array;
};

export function isListingPreview(this: Vue) {
  if (!this.$route.name) {
    return false;
  }
  let previewRouteNames = ['Inspection Details', 'Review Your Listing'];
  return previewRouteNames.includes(this.$route.name);
}