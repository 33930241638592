
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { GET } from '../api';
import store from '@/vuex';

import InputField from './InputField.vue';
import { openErrorDialog } from '../utils';

export default defineComponent({
    inheritAttrs: false,
    name: 'TheStoreSelectInput',
    props: {
        stores: {
            type: Array as PropType<{ id: number, name: string, zip: string }[]>,
            required: false,
        },
        actionType: {
            type: String, 
            default: 'buying',
        },
        initialStoreId: {
            type: Number,
            required: false
        }
    },
    components: {
        InputField,
    },
    setup(props, context) {
        const buyerPersonId = store.state.user.profile.id;
        const selectedStoreId = ref(props.initialStoreId);
        const storeOptions = ref([]);
        const loadingStoreOptions = ref(false);

        onMounted(async() => {
            if (!props.stores?.length || props.stores?.some(store => !store.id)) {
                loadingStoreOptions.value = true;
                await GET(`/company/buyerStores/${buyerPersonId}`)
                    .then(res => {
                        loadingStoreOptions.value = false;
                        storeOptions.value = res.data;
                    }).catch(error => {
                        loadingStoreOptions.value = false;
                        openErrorDialog({
                            title: 'Failed to link stores',
                            message: `We encountered an error while fetching store information for buyer ${buyerPersonId}. 
                            If the problem persists, try logging in again.`,
                            error,
                        });
                    })
            } else {
                storeOptions.value = props.stores;
            }
        });

        return {
            selectedStoreId,
            storeOptions,
            loadingStoreOptions,
        }
    }
});
