<template>
    <Modal 
        :loading="loading" 
        :disableConfirm="!startTime || !endTime"
        style="min-width: 500px; min-height: 685px;"
        @confirm="shouldScheduleAuction = true;"
        @close="$emit('close')"
    >
        <template #header>
            Schedule Auction
        </template>

        <template #body>
            <p class="text-center bold">Schedule an auction for vehicle {{ vehicleListingId }}</p>
            <ScheduleAuctionDateTimePickers 
                @startTime="setStartTime"
                @endTime="setEndTime"
                :vehicleListingIds='[vehicleListingId]'
                :customConfirm="shouldScheduleAuction"
                @success="$emit('close'); $emit('success');"
                @cancel="shouldScheduleAuction = false;"
                :initialStartTime="autofillEndTime && nextDay2pm ? startTime : null"
                :initialEndTime="autofillEndTime && nextDay2pm ? nextDay2pm : null"
                :key="`datepicker-${updateKey}`"
            />
        </template>
    </Modal> 
</template>

<script>
import { getNextDay2pmCst } from '../utils';
import Modal from './Modal.vue';
import ScheduleAuctionDateTimePickers from './ScheduleAuctionDateTimePickers.vue';

export default {
    name: 'ModalScheduleAuction',
    props: {
        vehicleListingId: Number,
        hasMobileView: {
            type: Boolean,
            required: false,
            default: false,
        },
        autofillEndTime: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    components: {
        Modal,
        ScheduleAuctionDateTimePickers,
    },
    data() {
        return {
            loading: false,
            startTime: null,
            endTime: null,
            shouldScheduleAuction: false,
            nextDay2pm: null,
            updateKey: 0,
        }
    },
    watch: {
        startTime(newValue) {
            if (this.autofillEndTime) {
                this.nextDay2pm = getNextDay2pmCst(this.startTime);
                this.setEndTime(this.nextDay2pm);
                this.updateKey++;
            }
        }
    },
    methods: {
        setStartTime(selectedTime) {
            this.startTime = selectedTime;
        },
        setEndTime(selectedTime) {
            this.endTime = selectedTime;
        },
    },
}
</script>

<style scoped lang='postcss'>
.datepicker {
    margin: 1rem auto;
}
</style>