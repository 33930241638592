
import { defineComponent, reactive, ref } from 'vue';

import EditableField from './Forms/EditableField.vue';

export default defineComponent({
    name: 'TheEditableVehicleName',
    props: {
        year: {
            type: String,
            required: false,
        },
        make: {
            type: String,
            required: false,
        },
        model: {
            type: String,
            required: false,
        },
        trim: {
            type: String,
            required: false,
        },
        isEditingInitial: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        EditableField,
    },
    setup(props, { emit, attrs }) {
        const isEditingMode = ref(props.isEditingInitial);

        const updatedVehicleName = reactive({
            year: props.year,
            make: props.make,
            model: props.model,
            trim: props.trim,
        });

        function emitUpdatedValue(field: string, updatedValue: string) {
            updatedVehicleName[field] = updatedValue;
            emit(`update:${field}`, updatedValue);
            emit('input', { [field]: updatedValue });
        }
        return {
            isEditingMode,
            updatedVehicleName,
            emitUpdatedValue,
        }
    },
})
