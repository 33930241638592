import { getNotifications, getUserNotificationSettings } from "@/api";
import { InAppNotificationModalityIds } from "@/enums";
import { database } from "@/firebase";
import { InAppNotification, NotificationRefRecord, NotificationRefSnapshot } from "@/types";
import { NotificationSettingsDTO } from "@/types/NotificationSettingsDTO";
import { convertNotificationSearchToModifierSchema } from "@/utils";
import { onMounted, onUnmounted, ref, Ref, watch } from "vue";
import store from '@/vuex';

type DatabaseRef = ReturnType<typeof database['ref']>;

export function useNotificationListener(
    userId: Ref<number | undefined>, 
    { onSnapshot, onBeforeConnectListener }: {
        onSnapshot: (payload: NotificationRefSnapshot) => void,
        onBeforeConnectListener?: (...args: any[]) => any,
    }
) {
    let notificationRef: DatabaseRef | null = null;
    let notificationListener: ReturnType<DatabaseRef['on']> | null = null;
    const notificationSnapshot: Ref<NotificationRefRecord | null> = ref(null);
    
    onMounted(onNotificationRefChange);
    onUnmounted(() => disconnectNotificationListener(false));
    
    function disconnectNotificationListener(disconnectAll?: boolean) {
        if (notificationRef && notificationListener) {
            disconnectAll 
                ? notificationRef.off('value')
                : notificationRef.off('value', notificationListener);
        }
    }
    
    watch(() => userId.value, () => {
        userId.value 
            ? onNotificationRefChange()
            : disconnectNotificationListener(true);
    });

    async function onNotificationRefChange() {
        if (!userId.value) {
            return;
        }
        if (onBeforeConnectListener) {
            await onBeforeConnectListener();
        }
        notificationRef = database.ref(`notification/${userId.value}`);
        notificationListener = notificationRef.on('value', (snapshot) => {
            const oldValue = notificationSnapshot.value as NotificationRefRecord

            if (!snapshot.exists()) {
                notificationSnapshot.value = null;
                onSnapshot({
                    isInitialSnapshot: !oldValue,
                    oldSnapshotValue: oldValue,
                    newSnapshotValue: null,
                });
                return;
            }

            const newValue = snapshot.val() as NotificationRefRecord;
            onSnapshot({
                isInitialSnapshot: !oldValue,
                oldSnapshotValue: oldValue,
                newSnapshotValue: newValue,
            });
            notificationSnapshot.value = newValue;
        });
    }
}


export function useNotificationFeed(userId?: number) {
    const notifications: Ref<InAppNotification[]> = ref([]);
    const notificationError: Ref<boolean> = ref(false);
    const loadingNotifications: Ref<boolean> = ref(false);

    async function getUserNotifications(useLoading: boolean=true) {
        if (!userId) {
            return;
        }
        if (useLoading) {
            loadingNotifications.value = true;
        }
        let userIdFilter = convertNotificationSearchToModifierSchema('userId', userId);
        let modalityIdFilter = convertNotificationSearchToModifierSchema('modalityId', InAppNotificationModalityIds);
        await getNotifications({
            filters: [
                ...(userIdFilter ?? []),
                ...(modalityIdFilter ?? []),
            ],
            orderBy: [{
                property: 'notification.createdDate',
                order: 'desc',
                nulls: 'last',
            }],
        }, {
            onSuccess: (res) => {
                const displayedInAppNotificationIds = store.state.notificationIdsRead;
                // filter out notifications that have already been marked as read, but API call has not completed yet
                // (e.g., already dismissed from notification feed, or already displayed as a popup notification)
                notifications.value = res.filter((notification: InAppNotification) => !displayedInAppNotificationIds.includes(notification.id));
                loadingNotifications.value = false;
                notificationError.value = false;
            },
            onError: (error) => {
                loadingNotifications.value = false;
                notificationError.value = true;
            },
        });
    }

    function removeNotification(notificationId: number) {
        store.commit('addReadNotificationIds', [notificationId]);
        let idx = notifications.value.findIndex(notification => notification.id == notificationId);
        if (idx >= 0) {
            notifications.value.splice(idx, 1);
        }
    }

    function removeAllNotifications() {
        store.commit('addReadNotificationIds', notifications.value.map(notification => notification.id));
        notifications.value = [];
    }

    return {
        notifications,
        loadingNotifications,
        getUserNotifications,
        removeNotification,
        removeAllNotifications,
        notificationError,
    }
}

export function useNotificationSettings() {
    const notificationSettings: Ref<NotificationSettingsDTO[]> = ref([]);
    const loadingNotificationSettings: Ref<boolean> = ref(false);

    async function getNotificationSettings() {
        loadingNotificationSettings.value = true;
        notificationSettings.value = await getUserNotificationSettings({
            onSuccess: () => loadingNotificationSettings.value = false,
            onError: () => loadingNotificationSettings.value = false,
        });
    }

    return {
        notificationSettings,
        loadingNotificationSettings,
        getNotificationSettings,
    }
}