
import { defineComponent, PropType } from 'vue';
import { MarketDataDTO } from '../types';
import { toCurrencyShowDashesIfNull } from '../utils';

import TheListingDetailsCard from './TheListingDetailsCard.vue';
import TheListingMarketDataTable from './TheListingMarketDataTable.vue';

export default defineComponent({
    name: 'TheListingDetailsMarketData',
    props: {
        marketData: {
            type: Object as PropType<MarketDataDTO>,
            required: false,
        },
        averageMarketDataCost: {
            type: Number,
            required: false,
        },
    },
    components: {
        TheListingDetailsCard,
        TheListingMarketDataTable,
    },
    setup() {
        return {
            toCurrencyShowDashesIfNull,
        }
    }
});
