
import { computed, defineComponent, PropType, ref, Ref } from 'vue';
import { AnnouncementCategory, AnnouncementSubcategory, AnnouncementType, ImageDTO, VehicleListingAnnouncement, VehicleListingAnnouncementUpdate } from '@/types';
import { validateAnnouncement } from '@/utils';
import { AnnouncementTypeEnum } from '@/enums';

import Modal from './Modal.vue';
import TheCreateAnnouncementForm from './TheCreateAnnouncementForm.vue';

export default defineComponent({
    name: 'TheCreateAnnouncementModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        announcement: {
            type: Object as PropType<Partial<VehicleListingAnnouncement>>,
            required: false,
        },
        vehicleListingPhotos: {
            type: Array as PropType<ImageDTO[]>,
            required: false,
        },
        bulkAddCategories: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Modal,
        TheCreateAnnouncementForm,
    },
    emits: ['close', 'addPhoto', 'saveAnnouncement', 'saveAndCreateAnother'],
    setup(props, context) {
        const announcementRef: Ref<Partial<VehicleListingAnnouncement> | undefined> = ref(props.announcement);
        const newAnnouncement: Ref<VehicleListingAnnouncementUpdate> = ref({});
        if (announcementRef.value) {
            newAnnouncement.value = {
                announcementTypeId: announcementRef.value.announcementType ? AnnouncementTypeEnum[announcementRef.value.announcementType.toUpperCase() as keyof typeof AnnouncementTypeEnum] as number : undefined,
                announcementSubcategoryId: announcementRef.value.categories?.subcategoryId,
                vehicleListingPhotoId: announcementRef.value.announcementPhoto?.vehicleListingPhotoId,
                vehicleListingAnnouncementId: announcementRef.value.vehicleListingAnnouncementId as number | undefined,
                announcement: announcementRef.value.announcement,
            }
        }

        const prefillFields: Ref<{
            announcementType: AnnouncementType,
            category: AnnouncementCategory,
            subcategory: AnnouncementSubcategory,
        } | undefined> = ref(undefined);

        const isAnnouncementValid = computed(() => validateAnnouncement(newAnnouncement.value));
        const vehicleListingPhotoType = ref(undefined);
        const uploadPhoto = ref(undefined);

        return {
            newAnnouncement,
            isAnnouncementValid,
            vehicleListingPhotoType,
            uploadPhoto,
            prefillFields,
        }
    }
});
