
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { NegotiationMessage } from '../types';
import store from '@/vuex';

import TheNegotiationMessage from './TheNegotiationMessage.vue';

interface ConsecutiveMessagesFromSender {
    sender: {
        personId: number,
        firstName: string,
        lastName: string,
    },
    messages: Array<ConsecutiveMessage>
}
interface ConsecutiveMessage {
    offerAmount?: number,
    message: string,
    date: Date,
}

export default defineComponent({
    name: 'TheNegotiationMessageHistory',
    props: {
        loadingMessages: {
            type: Boolean,
            default: false,
        },
        negotiationMessages: {
            type: Array as PropType<NegotiationMessage[]>,
            required: true,
        }
    },
    components: {
        TheNegotiationMessage,
    },
    setup(props, context) {
        const loggedInPersonId = store.state.user.profile.id;

        let formattedMessages = ref([]);
        onMounted(() => {
            let senderPersonId = props.negotiationMessages[0].sender.personId;
            consolidateConsecutiveMessages(0, senderPersonId, []);
        })

        function consolidateConsecutiveMessages(
            idx: number, 
            previousSenderPersonId: number, 
            consecutiveMessages: ConsecutiveMessage[]
        ) {
            if (idx == props.negotiationMessages.length) {
                return;
            }
            
            const currentMessage = props.negotiationMessages[idx];
            const messageDetails = {
                message: currentMessage.message,
                date: currentMessage.date,
                offerAmount: currentMessage.offerAmount,
            }
            const messagePersonId = currentMessage.sender.personId;
            if (idx == 0 || messagePersonId !== previousSenderPersonId) {
                const newSender = {
                    sender: currentMessage.sender,
                    messages: [messageDetails],
                }
                formattedMessages.value.push(newSender);
                return consolidateConsecutiveMessages(idx+1, messagePersonId, newSender.messages)
            }

            if (messagePersonId == previousSenderPersonId) {
                consecutiveMessages.push(messageDetails);
                return consolidateConsecutiveMessages(idx+1, messagePersonId, consecutiveMessages);
            } 
        }

        function checkPreviousMessage(idx: number) {
            if (idx == 0) {
                return false;
            }
            const messageSenderPersonId = props.negotiationMessages[idx].sender.personId;
            const previousPersonId = props.negotiationMessages[idx-1].sender.personId;
            return messageSenderPersonId == previousPersonId;
        }
        
        return {
            loggedInPersonId,
            checkPreviousMessage,
            formattedMessages,
        }
    }
});
