import { DialogProgrammatic } from "buefy";

export function openWarningIfUnsavedEdits(onConfirm=() => {}) {
    DialogProgrammatic.confirm({
        title: 'Cancel template edit',
        message: 'Are you sure?',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        onConfirm
    });
}

export function feeStructureHasChanges(originalFeeStructure: any, editedFeeStructure: any): boolean {
    if (!originalFeeStructure?.length || !editedFeeStructure?.length) {
        return false;
    }

    if (originalFeeStructure.length !== editedFeeStructure.length) {
        return true;
    }
    return originalFeeStructure.some((row: any, rowIdx: number) => {
        return Object.keys(row).some(column => {
            const deletedRow = !editedFeeStructure[rowIdx];
            return deletedRow || editedFeeStructure[rowIdx][column] !== row[column]
        });
    });
} 