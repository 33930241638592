import { HighestBidRefRecord } from "@/types";
import { PropType } from "vue/types/v3-component-props";
import { marketplaceCurrentOfferDTO } from "./MarketplaceCurrentOfferDTO";
import { SellerType } from "./SellerType";
import { TitleStatus } from "./TitleStatus";
import { VehicleStatus } from "./VehicleStatus";

export const SRPListingProps = {
    id: {
        type: Number,
        required: true,
    },
    year: {
        type: Number,
        required: true,
    },
    brand: {
        type: String,
        required: true,
    },
    model: {
        type: String,
        required: true,
    },
    trim: {
        type: String,
        required: false,
    },
    vin: {
        type: String,
        required: true,
    },
    status: {
        type: String as PropType<VehicleStatus>,
        default: 'Auctioning',
    },
    titleStatus: {
        type: String as PropType<TitleStatus>,
        required: false,
    },
    heroImgUrl: {
        type: String,
        required: false,
    },
    auction: {
        type: Object as PropType<{
            auctionStart: Date,
            auctionEnd: Date,
            askingPrice: number,
            reservePrice: number,
            buyItNowPrice?: number,
            canBuyItNow?: boolean,
            highestBid?: number,
        }>,
        default: () => {},
    },
    mileage: {
        type: Number,
        required: false,
    },
    city: {
        type: String,
        required: false,
    },
    state: {
        type: String,
        required: false,
    },
    carmigoCertified: {
        type: Boolean,
        default: false,
    },
    orderSummary: {
        type: Object as PropType<{
            vehiclePrice: number,
            buyerId: number,
        }>,
        required: false,
    },
    userProfile: {
        type: Object as PropType<{
            id: number,
        }>,
        required: false,
    },
    isPowerSeller: {
        type: Boolean,
        default: false,
    },
    isConsumerSeller: {
        type: Boolean,
        default: false,
    },
    sellerStore: {
        type: Object as PropType<{ name: string, id: number }>,
        required: true,
    },
    sellerType: {
        type: String as PropType<SellerType>,
        default: 'Individual',
    },
    zip: {
        type: String,
        required: false,
    },
    marketplaceListingId: {
        type: Number,
        required: false,
    },
    currentOffer: {
        type: Object as PropType<marketplaceCurrentOfferDTO>,
        required: false,
    },
    watching: {
        type: Boolean,
        default: false,
    },
    marketplaceCanBuyItNow: {
        type: Boolean,
        default: false,
    },
    sellerPersonId: {
        type: Number,
        required: false,
    },
    highestBid: {
        type: Object as PropType<HighestBidRefRecord>,
        required: false,
    },
    viewCount: {
        type: Number,
        default: 0,
    },
    requestorCurrentHighestBid: {
        type: Object as PropType<{
            amount: number,
            storeId: number,
            storeName: number,
        }>,
        required: false,
    },
    requestorCurrentHighestProxyBid: {
        type: Object as PropType<{
            amount: number,
            storeId: number,
            storeName: string,
        }>,
        required: false,
    },
}