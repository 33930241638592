
import { defineComponent } from 'vue';
import { useWatchPinchZoomEvent } from '../composables';

import PinchZoom from 'vue-pinch-zoom';

export default defineComponent({
    name: 'AppPinchZoom',
    components: {
        PinchZoom,
    },
    emits: ['pinchZoom'],
    setup(props, { attrs, emit }) {
        useWatchPinchZoomEvent(`${attrs.id}`, { emit });
    }
});
