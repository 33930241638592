<template>
    <div id="info-header" class="info-header-container flex-row align-center" :key="headerKey">
        <!-- img with/without hyperlink -->
        <div v-if="useVehicleGraphic" class="vehicle-graphic">
            <b-image :src="getImageUrl()" :alt="imageUrl"></b-image>
        </div>
        <div v-else-if="!hideImage" class="info-header-image">
            <a v-if="href" :href="headerDetails ? headerDetails.href : href" target="_blank">
                <img 
                    class="image-thumbnail flex-row align-center" 
                    :class="imgSize"
                    :src="imageUrl" 
                    alt="image"
                />
            </a>
            <img v-else 
                class="image-thumbnail flex-row align-center"
                :class="imgSize"
                :src="headerDetails ? headerDetails.imageUrl : imageUrl" 
                alt="image" 
            />
        </div>
        
        <div class="info flex-column justify-center">
            <InfoTitle 
                :title="headerDetails ? headerDetails.title : title" 
                :subtitle="headerDetails ? headerDetails.subtitle : subtitle" 
                :href="headerDetails ? headerDetails.href : href"
                :size="size"
            >
                <slot />
            </InfoTitle>
            <slot name="details">
                <p v-if="date" class="info-subtitle">Date of Sale: {{date}}</P>
            </slot>
        </div>
    </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { GET } from '../../api';
import InfoTitle from "./InfoTitle.vue";

export default {
    name: "InfoHeader",
    props: {
        vehicleListingId: {
            type: Number,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            default: 'medium',
        },
        date: {
            type: Date,
            required: false,
        },
        href: {
            type: String,
            required: false,
        },
        imageUrl: {
            type: String,
            required: false,
        },
        imgSize: {
            type: String,
            default: 'medium',
        },
        useVehicleGraphic: {
            type: Boolean,
            default: false,
        },
        hideImage: {
            type: Boolean,
            default: false,
        }
        
    },
    components: {
        InfoTitle,
    },
    data() {
        return {
            headerKey: 0,
            headerDetails: undefined,
        }
    },
    async mounted() {
        if (this.vehicleListingId) {
            this.fetchVehicleDetails();
        }
    },
    methods: {
        async fetchVehicleDetails() {
            this.headerDetails = {};
            await Promise.all([
                this.getHeaderDetails(),
                this.getHeaderImage()
            ]);
        },
        async getHeaderDetails() {
            const vehicle = await GET(`/vehicles/headerDetails/${this.vehicleListingId}`).then(res => res.data);
            this.headerDetails.title = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
            this.headerDetails.subtitle = vehicle.vin;
            this.headerKey++;
        },
        async getHeaderImage() {
            const heroPic = await GET(`/vehicles/heroPic/${this.vehicleListingId}`).then(res => res.data);
            const url = heroPic?.url ? heroPic.url : require(`../../assets/defaultimg.jpg`);

            this.headerDetails.imageUrl = url;
            this.headerKey++;
        },
        getImageUrl() {
            try {
                return require(`@/assets/VehicleGraphics/${this.imageUrl}`);
            } catch {
                Sentry.captureMessage(`Missing vehicle graphic URL: ${this.imageUrl}`, {
                    level: 'error',
                });
                return require(`@/assets/VehicleGraphics/GraySedan.png`);
            }
        },
    }
}
</script>

<style scoped lang="postcss">
#info-header {
    /* margin: 1rem; */
}
.image-thumbnail {
    margin-left: 1rem;
    border-radius: 10px;
}
.info-subtitle {
    font-size: .65em;
}
.info {
    margin-left: 1rem;
}
.vehicle-graphic {
    max-width: 180px;
    width: 40vw;
    margin-right: 1vw;
}

/* 
@media screen and (max-width: 650px) {
    .image-thumbnail {
        width: 4em;
        height: 4em;
    }

    #info-header {
        align-items: flex-start !important;
    }
  } */
</style>
