
import { defineComponent, PropType } from 'vue';
import { overdriveService } from '../service/overdrive.service';
import { toCurrency } from '../utils';

export default defineComponent({
    name: 'TheFailedOverdriveText',
    props: {
        currentDiscount: {
            type: Number,
            required: false,
        },
    },
    setup(props, { emit, attrs }) {
        const { openOverdriveDetailsModal } = overdriveService;

        return {
            toCurrency,
            openOverdriveDetailsModal,
        }
    },
});
