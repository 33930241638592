
import { defineComponent, PropType } from 'vue';
import { toCurrency } from '../utils';
import { overdriveService } from '../service/overdrive.service';

import AppProgressBar from './AppProgressBar.vue';

export default defineComponent({
    name: 'TheOverdriveProgressBar',
    props: {
        currentBidCount: {
            type: Number,
            default: 0,
        },
        currentGoalBidCount: {
            type: Number,
            required: true,
        },
        currentDiscount: {
            type: Number,
            required: false,
        },
        upcomingDiscount: {
            type: Number,
            required: false,
        },
        upcomingLevel: {
            type: Number,
            required: false,
        },
    },
    components: {
        AppProgressBar,
    },
    setup(props, { emit, attrs }) {
        const { openOverdriveDetailsModal } = overdriveService;

        return {
            toCurrency,
            openOverdriveDetailsModal,
        }
    },
});
