<template>
    <div class="flex-row align-start">
        <InputField
            class="width-100"
            v-bind="{
                ...$attrs.inputAttrs,
                type: $attrs.inputAttrs?.type ?? 'textarea',
                rows: $attrs.inputAttrs?.rows ?? 1,
                useCustomStyles: false,
            }"
            :value.sync="messageInput"
            @update:value="$emit('update:value', $event)"
        />
        <AppButton 
            v-if="showSubmitButton"
            v-bind="{
                ...$attrs.buttonAttrs,
                label: $attrs.buttonAttrs?.label ?? 'Send',
                type: 'is-primary'
            }"
            class="ml-2 carmigo-button"
            :disabled="$attrs.buttonAttrs?.disabled ?? ($attrs.inputAttrs?.required && !messageInput.length)"
            @click="$emit('submit', messageInput)"
        />
    </div>
</template>

<script>
import { debounce } from "lodash";
import { defineComponent, ref, watch } from "vue"

import AppButton from './AppButton.vue';
import InputField from './InputField.vue';

export default defineComponent({
    inheritAttrs: false, 
    name: 'TheNegotiationMessageInput',
    props: {
        showSubmitButton: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppButton,
        InputField,
    },
    setup(props, context) {
        const messageInput = ref('');

        if (!props.showSubmitButton) {
            watch(messageInput, () => debouncedEmitInput());
        }

        const debouncedEmitInput = debounce(function() {
            context.emit('input', messageInput.value);
        }, 200);

        return {
            messageInput,
        }
    }
});
</script>
