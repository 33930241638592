
import { computed, defineComponent, PropType } from 'vue';
import { getMarketplaceOfferButtonLabel, getMarketplaceOfferButtonType, getMarketplaceOfferType, isMarketplaceOfferPending } from '../utils';

import TheNegotiateButton from './TheNegotiateButton.vue';

export default defineComponent({
    name: 'TheNegotiateButtonMarketplace',
    props: {
        offerStatusId: {
            type: Number,
            required: true,
        },
        negotiatingUser: {
            type: String as PropType<'buyer' | 'seller'>,
            required: true,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        offererTypeId: {
            type: Number,
            required: true,
        }
    },
    components: {
        TheNegotiateButton,
    },
    setup(props) {
        const getButtonLabel = computed(() => {
            return getMarketplaceOfferButtonLabel(props.offerStatusId, props.offererTypeId, props.negotiatingUser);
        });

        const getButtonType = computed(() => {
            return getMarketplaceOfferButtonType(props.offerStatusId, props.offererTypeId, props.negotiatingUser);
        });
        
        const isOfferPending = computed(() => {
            return isMarketplaceOfferPending(props.offerStatusId, props.negotiatingUser);
        });

        return {
            isOfferPending,
            getButtonLabel,
            getButtonType,
        }
    }
});
