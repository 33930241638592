<template>
        <b-field :label="label">
            <b-datetimepicker
                v-model="selectedTime"
                v-bind="{
                    ...$attrs,
                    ...$attrs.datetimepickerAttrs,
                    icon: 'calendar-today',
                    minDatetime: minPickableTime,
                    timepicker,
                    placeholder,
                }"
                @input="returnSelectedTime(label, selectedTime)"
                @focus="isInModal ? fixPositionInModal() : ''"
                @blur="$emit('blur', selectedTime)"
                ref="datepicker"
                class="datetimepicker"
            >
                <template slot="left">
                    <button class="button is-danger" @click="selectedTime = null">
                        <span>Clear</span>
                    </button>
                </template>
                <template slot="right">
                    <button class="button is-primary" @click="onConfirm()">
                        <span>Confirm</span>
                    </button>
                </template>

            </b-datetimepicker>
        </b-field>
</template>

<script>
export default {
    name: "DateTimePicker",
    props: {
        label: String,
        placeholder: String,
        minPickableTime: Date,
        timepicker: {
            type: Object,
            required: false,
            default: () => {
                return {
                    incrementMinutes: 1, 
                    incrementHours: 1,
                    hourFormat: '12'
                }
            }
        },
        returnSelectedTime: Function,
        initialValue: Date,
        isInModal: Boolean,
        openOnMount: Boolean
    },
    data() {
        return {
            selectedTime: null,
        }
    },
    mounted() {
        if (this.initialValue) {
            this.selectedTime = new Date(this.initialValue);
        }
        if (this.openOnMount) {
            window.setTimeout(() => {
                this.$refs.datepicker.toggle();
                this.fixPositionInModal();
            }, 200);
        }
    },
    methods: {
        onConfirm() {
            this.returnSelectedTime(this.label, this.selectedTime)
            this.$refs.datepicker.toggle()
        },
        // This is for showing the datepicker overflow within a modal
        // Only the first 'dropdown-content' element should have fixed position, otherwise the time is not shown.
        fixPositionInModal(event) {
            window.setTimeout(() => {
                const datepicker = document.querySelector('.datetimepicker .is-active.is-mobile-modal');
                const dropdownContent = datepicker.querySelector('.dropdown-content');
                dropdownContent.style.position = 'fixed';
            }, 100);
        }
    }
}
</script>

<style scoped lang="postcss">
.field {
    margin: 0;
    width: 100%;
}

.datepicker {
    width: 100%;
}

> button {
    min-width: 225px;
}

@media screen and (max-width: 420px) {
    .datepicker {
        min-width: 200px;
        width: 100%;
    }
}
</style>
