
import { defineComponent } from 'vue';
import AppFastBookDropdown from '@/components/AppFastBookDropdown.vue';
import CopyTextButton from '../CopyTextButton.vue';
import store from '@/vuex';

export default defineComponent({
    name: 'InfoTitle',
    props: {
        title: String,
        subtitle: String,
        size: String,
        href: String,
        titleClass: String,
        copySubtitle: {
            type: Boolean,
            default: false,
        },
        customClickEvent: {
            type: Boolean,
            default: false,
        },
        useFastbookExtension: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        CopyTextButton,
        AppFastBookDropdown,
    },
    setup() {
        return {
            store,
        }
    }
});
