
import { defineComponent } from 'vue';
import { useOverdriveDisplay, useOverdriveListener, useUserRole } from '../composables';
import { toCurrency } from '../utils';

export default defineComponent({
    name: 'AppOverdrive',
    setup(props, { emit, attrs }) {
        const { isUserAdmin, isUserDsr } = useUserRole();
        // listen to Overdrive RDB and display the correct Progress Bar component
        const {
            overdrive,
        } = useOverdriveListener();

        const {
            displayComponent,
            transitionType,
            throwConfettiOnCompleteOverdrive,
            displayFailedOverdriveText,
            displayLevel,
            levelKey,
            shouldPlayAudioCue,
            failedTextBottomTransitionDelay,
        } = useOverdriveDisplay(overdrive);

        return {
            toCurrency,
            overdrive,
            isUserAdmin,
            isUserDsr,
            displayComponent,
            transitionType,
            displayFailedOverdriveText,
            throwConfettiOnCompleteOverdrive,
            displayLevel,
            levelKey,
            shouldPlayAudioCue,
            failedTextBottomTransitionDelay,
        }
    },
});
