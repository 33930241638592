import { APIConfig, InspectionUpdate, InspectionUpdateType, ModifierOptions, VehicleListingAnnouncement } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, openErrorDialog, openSubmitInspectionErrorDialog, openToast } from "@/utils";
import { DELETE, GET, POST, PUT } from ".";
import { VehicleListingAnnouncementUpdate } from "@/types/VehicleListingAnnouncementUpdate";

export async function submitInspection(vehicleListingId: number, config: APIConfig={}) {
    await PUT(`/inspection/submit/${vehicleListingId}`)
      .then(res => {
        applyAPIConfigOnSuccess(res.data, config);
        return res.data;
      }).catch(error => {
        applyAPIConfigOnError(error, config);
        openSubmitInspectionErrorDialog(error?.response?.data);
      });
}


export async function updateInspectionData(vehicleListingId: number, updates: InspectionUpdate, updateType: InspectionUpdateType, marketplaceListingId?: number) {
    const savedUpdates = await PUT(`/inspection/${updateType}/${vehicleListingId}`, {
      ...updates,
      marketplaceListingId: updateType == 'saveBasicInfo' ? marketplaceListingId : undefined
    }).then(res => {
        return res.data;
      }).catch(error => {
        throw error;
      });
    return savedUpdates;
}

export async function requestInspectionByInventoryId(inventoryId: number, config: APIConfig={}) {
    return await PUT(`/inspection/requestInspection`, { inventoryId })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to request inspection',
                message: celebrateValidationMessage ?? `We encountered an error while requesting an inspection for inventory ID ${inventoryId}.`,
                error,
            });
        });
}

export async function cancelInspectionRequestByInventoryId(inventoryId: number, config: APIConfig={}) {
    return await PUT(`/inspection/cancelInspectionRequest`, { inventoryId })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Cancellation failed',
                message: `We encountered an error while cancelling the inspection request for inventory ID ${inventoryId}`,
                error,
            });
        });
}

export async function getAnnouncementPrediction(searchText: string, config: APIConfig={}): Promise<string[]> {
  return await PUT(`/inspection/announcementSearch`, { searchText }, { cancelToken: config.cancelToken })
    .then(res => {
      applyAPIConfigOnSuccess(res.data, config);
      return res.data;
    }).catch(error => {
      let useDefaultErrorHandling = applyAPIConfigOnError(error, config);
      if (!useDefaultErrorHandling) {
        return;
      }
      openToast('is-danger', `Announcement prediction failed for input: ${searchText}`);
    });
}

export async function getFeaturePrediction(searchText: string, config: APIConfig={}) {
  return await PUT(`/inspection/featureSearch`, { searchText })
    .then(res => {
      applyAPIConfigOnSuccess(res.data, config);
      return res.data;
    }).catch(error => {
      applyAPIConfigOnError(error, config);
      openToast('is-danger', `Feature prediction failed for input: ${searchText}`);
    });
}

export async function getAnnouncementCategories(config: APIConfig={}) {
  return await GET(`/inspection/announcementCategories`, undefined, { cancelToken: config.cancelToken })
      .then(res => {
        applyAPIConfigOnSuccess(res.data, config);
        return res.data;
      }).catch(error => {
        applyAPIConfigOnError(error, config);
      });
}

export async function getAnnouncementSubcategories(announcementCategoryId: number, config: APIConfig={}) {
  return await GET(`/inspection/${announcementCategoryId}/announcementSubcategories`, undefined, { cancelToken: config.cancelToken })
    .then((res) => {
      applyAPIConfigOnSuccess(res.data.subCategories, config);
      return res.data.subCategories;
    }).catch(error => {
      applyAPIConfigOnError(error, config);
    });
}

export async function saveAnnouncement(vehicleListingId: number, announcement: VehicleListingAnnouncementUpdate, config: APIConfig={}): Promise<VehicleListingAnnouncement[]> {
  return await POST(`/inspection/${vehicleListingId}/saveAnnouncement`, announcement, { cancelToken: config.cancelToken })
    .then(res => {
      applyAPIConfigOnSuccess(res.data, config);
      return res.data;
    }).catch(error => {
      applyAPIConfigOnError(error, config)
      const celebrateValidationError = formatCelebrateValidationError(error);
      openErrorDialog({
        title: 'Failed to save announcement', 
        message: celebrateValidationError ?? `We encountered an error while saving your announcement.`, 
        error,
        displayErrorInDialog: true,
      });
    });
}

export async function deleteAnnouncementById(vehicleListingAnnouncementId: number, config: APIConfig={}) {
  return await DELETE(`/inspection/${vehicleListingAnnouncementId}/deleteAnnouncement`)
    .then(res => {
      applyAPIConfigOnSuccess(res.data, config);
      return res.data;
    }).catch(error => {
      applyAPIConfigOnError(error, config);
    });
}