import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import store from '@/vuex';
import router from '../router';
import { 
  applyAPIConfigOnError,
  applyAPIConfigOnSuccess,
  openErrorDialog, 
  openToast, 
  removeListingTypeFromSellFeeDtoArray, 
} from '../utils';
import { 
  APIConfig,
  BBDrilldownDTO,
  BuyFeeDTO, 
  CompanyEmployeeDTO,
  CreateVehicleDTO, 
  InspectionDashboardDTO, 
  NotificationSetting,
  PremiumStatus, 
  QuestionsAndResponses, 
  Roles,
  SellFeeDTO, 
  storeLite,
  StoreNameAndId, 
  StoresDTO, 
  VehicleDTO,
  VehicleStatus,
} from '../types';
import { VehicleFilterPreset } from '../types/VehicleFilterPreset';
import { tokenExpires } from './auth';

let expiringToken = false;


export * from '../APIRoutes';

export const BASE_URL = process.env.VUE_APP_API_ROOT;
export const APP_TARGET = process.env.VUE_APP_TARGET;

// Internal Helper Functions
const isRelativeURL = (url: string) => !(url.substring(0, 4) === 'http' && url.charAt(6) === '/');

// Helper Functions that may be used externally, for testing purposes or whatever
export function getBaseURL() {
  return BASE_URL;
}

export function getURL(urlOrRelativeURL: string) {
  if (isRelativeURL(urlOrRelativeURL)) {
    const preSlashedRelativeURL = urlOrRelativeURL.charAt(0) === '/'
      ? urlOrRelativeURL
      : `/${urlOrRelativeURL}`;
    return BASE_URL + preSlashedRelativeURL;
  }
  return urlOrRelativeURL;
}

export async function request<T = any>(
  urlOrRelativeURL: string,
  method: Method = 'get',
  dataArg = {},
  requestConfig: AxiosRequestConfig = {},
): Promise<AxiosResponse<T>> {
  const token = localStorage.getItem('accessToken');
  const authorizationHeader = token ? { authorization: token } : {};
  const headers = { ...authorizationHeader, ...requestConfig.headers };
  const data = JSON.parse(JSON.stringify(dataArg));
  const url = getURL(urlOrRelativeURL);
  const now = Date.now();
  const expires = store.state.user?.stsTokenManager?.expirationTime as number;

  if (expires - now < 1800000) {
    expiringToken = true;
  }

  if (expires) {
    if (expiringToken) {
      expiringToken = false;
      await tokenExpires();
    }
  }

  try {
    const res = await axios(url, {
      responseType: 'json',
      method,
      data,
      headers,
      ...requestConfig,
    });
    return res;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        await tokenExpires();
        return request(urlOrRelativeURL, method, dataArg, requestConfig);
      }
      if (error.response?.status === 403) {
        store.commit('logout');
        router.push('/login');
      }
    }
    throw error;
  }
}

export function GET<T = any>(urlOrRelativeURL: string, id: string | null = null, requestConfig?: AxiosRequestConfig) {
  const finalURL = id ? `${urlOrRelativeURL}/${id}` : urlOrRelativeURL;
  return request<T>(finalURL, 'get', undefined, requestConfig);
}

export function POST<T = any>(urlOrRelativeURL: string, data: any, requestConfig?: AxiosRequestConfig) {
  return request<T>(urlOrRelativeURL, 'post', data, requestConfig);
}

export function DELETE<T = any>(urlOrRelativeURL: string, id: string | null = null) {
  const finalURL = id ? `${urlOrRelativeURL}/${id}` : urlOrRelativeURL;
  return request<T>(finalURL, 'delete');
}

export function PUT<T = any>(urlOrRelativeURL: string, data?: Record<string, any>, requestConfig?: AxiosRequestConfig) {
  return request<T>(urlOrRelativeURL, 'put', data, requestConfig);
}


// Actually Used Functions

export function createCar(data: CreateVehicleDTO): Promise<{data: VehicleDTO}> {
  return POST('vehicles', data);
}

// This function gets cars? I believe this route is the same as getCar()
export function updateCar(data: any) {
  return PUT('vehicles', data);
}

export function getCar(id: string) {
  return GET('vehicles', id);
}

export function decodeVIN(vin: string) {
  return GET('vin', vin);
}

export function decodeVINWithPricingInformation(vin: string) {
  return GET('vin/getVinInfoWithPricingData', vin);
}

export function getDrilldown(data: BBDrilldownDTO) {
  return POST('blackbook/drilldown', data);
}

export function decodeUVC(uvc: string) {
  return POST(`vin/${uvc}`, '');
}
export function decodeUVCWithPricingInformation(uvc: string) {
  return GET(`vin/getUvcInfoWithPricingData/${uvc}`);
}

export function getMyHighestBid(vehicleId: string) {
  return GET(`vehicles/${vehicleId}/highest-bid/me`);
}

export function getRandomVin() {
  return GET('vin/randomVin');
}

export function getCompanies() {
  return GET('company/getAll');
}

export function getCompaniesByName(companyName: string) {
    return GET(`company/getCompaniesByName/${companyName}`);
}

export function addManager(manager: CompanyEmployeeDTO) {
  return POST('company/manager', manager);
}

export function addBuyer(buyer: CompanyEmployeeDTO) {
  return POST('company/buyer', buyer);
}

export function addStore(storeObj: StoresDTO) {
  return POST('company/store', storeObj);
}

export async function savePlaidAccessToken(plaidAccessToken: string, account_id: string, userID: string, destinationRepository: string) {
  return POST('plaid/savePlaidAccessToken', {
    userID, plaidAccessToken, plaidAccountID: account_id, destinationRepository,
  })
    .then((res) => {
      console.log(res);
      return res.data;
    });
}

// I don't think this is being used anywhere and there is no route for this in the plaid router
export async function generatePlaidProcessorToken(plaidAccountID: string, plaidToken: string, userID: string, destinationRepository: string) {
  console.log('plaid account ID: ', plaidAccountID);
  console.log('plaid access token: ', plaidToken);

  return POST('plaid/generatePlaidProcessorToken', {
    plaidAccountID, plaidAccessToken: plaidToken, CarmigoUserID: userID, destinationRepository,
  })
    .then((res) => {
      console.log(res);
      return res.data;
    });
}

// Separate function from above since this route does not require app authentication
export async function generateStorePlaidProcessorToken(plaidAccountID: string, plaidToken: string, userID: string, destinationRepository: string) {
  // console.log("plaid account ID: ", plaidAccountID);
  // console.log("plaid access token: ", plaidToken);

  return POST('plaid/generateStorePlaidProcessorToken', {
    plaidAccountID, plaidAccessToken: plaidToken, CarmigoUserID: userID, destinationRepository,
  })
    .then((res) => {
      console.log(res);
      return res.data;
    });
}

export async function generateStorePlaidProcessorTokenUpdate(plaidAccountID: string, plaidToken: string, userID: string, destinationRepository: string) {
  // console.log("plaid account ID: ", plaidAccountID);
  // console.log("plaid access token: ", plaidToken);

  return POST('plaid/generateStorePlaidProcessorToken', {
    plaidAccountID, plaidAccessToken: plaidToken, CarmigoUserID: userID, destinationRepository,
  })
    .then((res) => {
      console.log(res);
      return res.data;
    });
}

export async function loadFinalSaleInfo(vehicleID: string) {
  return GET(`orders/getFinalSale/${vehicleID}`)
    .then((res) =>
      // console.log(res);
      res.data);
}


export async function needsSellerPaymentInfo(uid: string) {
  // return true; // temp until backend is written

  return GET(`plaid/needsPaymentInfo/${uid}`)
    .then((res) =>
    // console.log(res)
      res.data);
}

export async function getPlaidAccountInfo(sellerUserID: string, buyerStoreID: string) {
  return POST('plaid/getPlaidAccountInfo', { sellerUserID, buyerStoreID })
    .then((res) =>
      // console.log(res.data)
      res.data)
    .catch((error) => {});
}

export async function getSinglePlaidAccountInfo() {
  const PLAID_TOKEN = 'access-sandbox-27b0b2a2-4045-401a-9e97-9de4e094d01d';

  console.log(await POST('/plaid/getSinglePlaidAccountInfo', { plaidAccessToken: PLAID_TOKEN }));
}

// We can still use this, but when a user logs in we save their stores under user/profile/stores, so we probably shouldn't use this
export function getStoresByBuyerId(buyerId: string, companyId: string) {
  return GET(`company/stores/${buyerId}?companyId=${companyId}`);
}

// export function getDwollaEnvironment() {
//   if (APP_TARGET === "prod") {
//     return "production"
//   }

//   return "sandbox"
// }

export function getPlaidEnvironment() {
  if (APP_TARGET === 'prod') {
    return 'production';
  }

  return 'sandbox';
}

export function checkFundingSetupProgress(storeID: string, status: string) {
  return GET(`company/checkFundingSetupProgress/${storeID}/${status}`)
    .then((res) => res.data);
}


export function checkStoreEmailForACHSetup(storeID: string, officeManagerEmail: string) {
  return GET(`company/checkStoreEmailForACHSetup/${storeID}/${officeManagerEmail}`)
    .then((res) =>
      // console.log(res)
      res.data);
}

export function resetPlaidLogin(access_token: string) {
  return POST('auth/resetLogin', { accessToken: access_token })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getPlaidLinkTokenUpdateMode(uid: string, destinationRepository: string) {
  return GET(`plaid/plaidLinkTokenDirectUpdateMode/${uid}/${destinationRepository}`)
    .then((res) =>
      // console.log(res)
      res.data.token);
}


export function createHubspotCustomer(email: string, year: string, make: string, model: string, trim: string) {
  return POST(
    `appointments/createHubspotCustomer/${email}`,
    {
      year,
      make,
      model,
      trim,
    },
  );
}


export function getPlaidAccessToken(uid: string, repoType: string) {
  return GET(`plaid/getPlaidAccessToken/${uid}/${repoType}`)
    .then((res) => res.data);
}

export function updatePlaidItemStatus(storeID: string, status: string) {
  return POST('company/updatePlaidItemStatus', { storeID, status })
    .then((res) => res.data);
}

export function isPlaidItemValid(uid: string, repoType: string) {
  return GET(`plaid/checkPlaidItemIsValid/${uid}/${repoType}`)
    .then((res) => res.data);
}

export function checkHasBankInfo(userId: string, repoType: string) {
  return GET(`plaid/checkHasBankInfo/${userId}/${repoType}`)
    .then((res) => {
      console.log('checkHasBankInfo: ', res);
      return res.data;
    });
}

export function createNewReferralFromAccountCreation(referralCode: string, vehicleListingId: number) {
  return POST('/referrals/createNewReferralFromAccountCreation', {
    vehicleListingId,
    referralCode,
  }).then((response) => {
    store.commit('resetReferralCode');
    return response.data;
  }).catch((error) => {
    console.log(error);
  });
}

export function getOriginIdByPromotionCode(promotionCode: string): Promise<string> {
  return GET(`/promotion/getOriginIdByPromotionCode/${promotionCode}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

export async function saveQuestionnaireResponses(vehicleListingId: number, questionnaireName: string, updatedResponses: QuestionsAndResponses, oldResponses: QuestionsAndResponses) {
  if (!Object.keys(updatedResponses).length) {
    return;
  }
  const responsesToUpdate: { name: string, value: string | null }[] = [];
  Object.keys(updatedResponses).forEach(question => {
    const sameValue = oldResponses[question] == updatedResponses[question];
    if (!sameValue) {
      responsesToUpdate.push({
        name: question,
        value: updatedResponses[question]
      });
    }
  })
  if (!responsesToUpdate.length) {
    return
  }
  await PUT(`/questionnaires/saveResponses`, {
    responses: responsesToUpdate,
    vehicleListingId,
    questionnaireName
  }).catch(error => {
    console.log('Error saving questionnaire responses', error);
    openToast('is-danger', 'One or more responses failed to save');
  });
  const isLeased = responsesToUpdate.some(response => response.value == 'Lease');
  if (isLeased) {
    throw new Error('rejectLeased');
  }
}

export async function questionnaireStatusUpdate(
  currentPageName: string,
  percentageComplete: number,
  questionnaireName: string,
  vehicleListingId: number,
  isCarmigoDirect: boolean,
) {
  await PUT(`/questionnaires/statusUpdate`, {
    currentPageName,
    percentageComplete,
    questionnaireName,
    vehicleListingId,
    isCarmigoDirect,
  });
}

interface AskingPriceInfo {
  askingPriceId: number;
  askingPriceReasonDescription?: string;
  competingOffererId?: number;
  competingOffererAmount?: number;
  payoffAmount?: number;
}

export async function saveReserve(vehicleListingId: number, reservePrice: number, askingPriceInformation?: AskingPriceInfo) {
  return await POST('vehicles/saveReservePrice', {
    id: vehicleListingId,
    reservePrice,
    askingPriceInformation,
  })
    .then(res => res.data)
    .catch((error) => {
      throw error.response.data.error;
    });
}

export async function searchByVinAndStatus(vin: string, statuses: VehicleStatus[]) {
  var vinNormalized = vin.replace(/ /g, '');
  return await PUT<InspectionDashboardDTO[]>(`/inspection/searchByVin/${vinNormalized}`, { statuses });
}


export async function getStoresBySellerPersonId(sellerPersonId: number) {
  const stores: storeLite[] = await GET(`/company/getSellerStores/${sellerPersonId}`)
    .then(res => res.data)
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });

    return stores;
}

export async function updatePhoneNumberByPersonId(personId: number, newPhoneNumber: string) {
  return await POST(`/auth/updatePhoneNumber`, {
    personId,
    newPhoneNumber,
  }).then(res => res.data)
    .catch(error => {
      throw error;
    });
}

export async function updateEmailByPersonId(personId: number, newEmail: string) {
  return await POST(`/auth/updateEmail`, {
    personId,
    newEmail
  }).then(res => res.data)
    .catch(error => {
      throw error;
    });
}


export async function addOrRemoveStorePremiumStatus(storeId: number, premiumStatus: PremiumStatus, addOrRemove: 'add' | 'remove'='add') { 
  var premiumStatusId;
  switch (premiumStatus) {
    case 'PowerBuyer':
      premiumStatusId = 1;
      break;
    case 'PowerSeller':
      premiumStatusId = 2;
      break;
  }

  if (!premiumStatusId) {
    throw new Error('no status id provided');
  }

  return await POST(`/company/${addOrRemove}PremiumStatus`, {
    storeId,
    premiumStatusId
  }).then(res => {
    return res;
  }).catch(error => {
    console.log('ERROR', error);
    openErrorDialog({
      title: `Failed to ${addOrRemove} Store ${storeId}'s premium status`,
      error,
      displayErrorInDialog: true,
    });
  });
}

export async function saveFeeTemplate(feeType: 'buyer' | 'seller', dto: {
  storeId?: number,
  id?: number,
  name: string,
  reusable: boolean,
  fees: BuyFeeDTO[] | SellFeeDTO[]
}) {
  const route = feeType == 'seller' ? 'saveSellFeeTemplate' : 'saveBuyFeeTemplate'

  if (feeType == 'seller') {
    dto.fees = removeListingTypeFromSellFeeDtoArray(dto.fees as SellFeeDTO[]);
  }

  return await PUT(`/fee/${route}`, dto)
    .then(res => res.data)
    .catch(error => {
      let violatesUniqueNameConstraint = error.response?.data?.error?.constraint == 'sell_fee_template_name_key' || error.response?.data?.error?.constraint == 'buy_fee_template_name_key';
      if (violatesUniqueNameConstraint) {
        openErrorDialog({
          title: 'Failed to save fee template',
          message: `A ${feeType} fee template named '${dto.name}' already exists. Select a different name or contact support.`,
          error,
        });
      } else {
        openErrorDialog({
          title: 'Failed to save selected fee structure',
          message: `Unable to save ${feeType} fee structure for store: ${dto.storeId} and template: ${dto.id} (reusable: ${dto.reusable})`,
          error,
          displayErrorInDialog: true,
        });
      }
    });
}

export async function updateStoreFeeTemplateId(feeType: 'seller' | 'buyer', storeId: number, templateId: number) {
  const route = feeType == 'seller' ? 'updateStoreSellFeeTemplateId' : 'updateStoreBuyFeeTemplateId';
  const userFeeTemplateId = feeType == 'seller' ? 'sellFeeTemplateId' : 'buyFeeTemplateId';
  return await PUT(`/company/${route}`, {
    [userFeeTemplateId]: templateId,
    storeId,
  }).then(res => res)
  .catch(error => {
    console.log('ERROR', error);
    throw error;
  })
}

export async function updateOrderSellFee(vehicleListingId: number, sellFee: number) {
  await POST(`/orders/updateSellFee`, {
    vehicleListingId,
    sellFee,
  });
}

export async function getStoresUsingSellFeeTemplateId(templateId: number): Promise<StoreNameAndId[]> {
  return (await GET(`company/getStoresUsingSellFeeTemplate/${templateId}`)).data;
}

export async function getStoresUsingBuyFeeTemplateId(templateId: number): Promise<StoreNameAndId[]> {
  return (await GET(`company/getStoresUsingBuyFeeTemplate/${templateId}`)).data;
}

export async function getStoreEmployeeRoles(personId: number, storeId: number) {
  return await GET(`/company/rolesByStore/${personId}/${storeId}`)
    .then(res => res.data)
    .catch(error => openErrorDialog({
      title: `Failed to fetch user's roles`,
      message: `We encountered a problem while retrieving the roles for person ${personId}.`,
      error,
      displayErrorInDialog: true,
    }));
}

export async function addEmployeeRole(personId: number, role: Roles, storeId: number) {
  return await POST(`/company/addEmployeeRole`, {
    personId,
    role,
    storeId,
  })
    .then(res => res.data)
    .catch(error => openErrorDialog({
      title: `Failed to add employee role`,
      message: `We encountered a problem while adding the '${role}' role for person ${personId} and store ${storeId}.`,
      error,
      displayErrorInDialog: true,
    }));
}

export async function getTransportationCostUsingZipCodes(payload: { zip1: string, zip2: string, vehicleListingId: number }, config: APIConfig={}): Promise<number> {
  return await PUT(`/distance/calculateTransportationCostUsingZipCodes`, payload)
    .then(res => {
      applyAPIConfigOnSuccess(res.data, config);
      return res.data;
    }).catch(error => {
      applyAPIConfigOnError(error, config);
    });
}

export async function getStoreNameByStoreId(storeId: number): Promise<string | undefined> {
  return (await GET(`company/storeName/${storeId}`)).data?.name;
}

export async function getFilterPresetsForUser(): Promise<VehicleFilterPreset[]> {
  return await GET(`filters/getUserSavedFilters`)
    .then(res => res.data)
    .catch(error => {
      openErrorDialog({
        title: 'Failed to fetch saved filters',
        message: `We encountered an error while fetching the saved presets for person ${store.state.user?.profile?.id}`,
        error,
        displayErrorInDialog: true,
      });
    });
}

export async function saveUserFilterPreset(filter: VehicleFilterPreset, notificationSettings: NotificationSetting[]) {
  return await POST(`/filters/saveUserFilter`, {
    filter, 
    notificationSettings,
  }).then(res => res.data?.filterId)
  .catch(error => {
    openErrorDialog({
      title: 'Failed to save filter',
      message: `We encountered an error while saving your filter preset`,
      error,
      displayErrorInDialog: true,
    });
  })
}

export async function updateUserFilterPreset(presetId: number, filter: VehicleFilterPreset) {
  await POST(`/filters/updateUserSavedFilter`, {
    id: presetId,
    ...filter
  }).catch(error => openErrorDialog({
    title: 'Failed to update saved filter',
    message: `We encountered an error while saving your changes to preset ${presetId}`,
    error,
    displayErrorInDialog: true,
  }));
}

export async function updateFilterName(filterId: number, filterName: string) {
  await POST(`/filters/updateFilterName`, {
    filterId,
    filterName
  }).catch(error => openErrorDialog({
    title: 'Failed to update filter name',
    message: `We encountered an error while updating the name for preset ${filterId} to: ${filterName}`,
    error,
    displayErrorInDialog: true,
  }));
}

export async function deleteUserFilterPreset(filterId: number) {
  await DELETE(`/filters/deleteUserSavedFilter/${filterId}`)
    .catch(error => openErrorDialog({
      title: 'Failed to delete saved filter',
      message: `We encountered an error while deleting filter preset ${filterId}`,
      error,
      displayErrorInDialog: true,
    }));
}

export async function getMarketplaceListingDetails(marketplaceListingId: number, config: APIConfig={}) {
  let attempts = 0;
  const maxAttempts = 3;

  while (attempts < maxAttempts) {
    try {
      const response = await GET(`/marketplace/getMarketplaceListing/marketplaceVDP/${marketplaceListingId}`, undefined, { cancelToken: config?.cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }
      attempts++;
      if (attempts >= maxAttempts) {
        return null; // Exit the function without throwing an error
      }

      // Wait for 10 second before the next attempt
      await new Promise(resolve => setTimeout(resolve, 10000));
    }
  }
}

export async function getMarketplaceBuyer(vehicleListingId: number) {
  return await GET(`/marketplace/getMarketplaceOrder/buyer/${vehicleListingId}`)
    .then(res => res.data)
    .catch(error => {
      openErrorDialog({
        title: 'Failed to fetch marketplace buyer details',
        message: `We encountered an error while fetching the buyer details for vehicle ${vehicleListingId}`,
        error,
      });
    });
}

export async function getMarketplaceOrder(vehicleListingId: number) {
  return await GET(`/marketplace/getMarketplaceOrder/orderSummary/${vehicleListingId}`) 
    .then(res => res.data)
    .catch(error => {
      openErrorDialog({
        title: 'Failed to fetch marketplace order details',
        message: `We encountered an error while fetching the order details for vehicle ${vehicleListingId}`,
        error,
      });
    })
}

export async function getMarketplaceListingHighestOffer(marketplaceListingId: number) {
  try {
      const { data } = await GET<{ marketplaceOfferId: number, highestOfferAmount: number }>(`/marketplace/getMarketplaceListing/highestOffer/${marketplaceListingId}`);
      return data;
  } catch (error) {
      openErrorDialog({
          title: 'Failed to fetch marketplace highest offer',
          message: `We encountered an error while fetching the marketplace listing highest offer for listing with ID ${marketplaceListingId}`,
          error,
      });
      return null
  }
}

export * from './admin';
export * from './auction';
export * from './auth';
export * from './buyer';
export * from './company';
export * from './inspector';
export * from './inspection';
export * from './inventory';
export * from './listings';
export * from './marketplace';
export * from './negotiation';
export * from './notification';
export * from './order';
export * from './overdrive';
export * from './place';
export * from './seller';
export * from './user';
export * from './vehicles';