
import { computed, defineComponent, PropType, ref } from 'vue';
import { useNotificationListener } from '../composables';
import store from '@/vuex';

import AlertIcon from './AlertIcon.vue';
import AppButton from './AppButton.vue';

export default defineComponent({
    name: 'AppNavbarNotifications',
    props: {
        displayNotificationFeed: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AlertIcon,
        AppButton,
    },
    emits: ['update:displayNotificationFeed'],
    setup(props, { emit, attrs }) {
        const userId = computed(() => store?.state?.user?.profile?.userId);
        const numNotifications = ref(0);

        useNotificationListener(userId, {
            onSnapshot: updateUnreadNotificationCount,
        });

        function updateUnreadNotificationCount({ newSnapshotValue }) {
            if (!newSnapshotValue?.inAppNotifications) {
                numNotifications.value = 0;
                return;
            }
            const { inAppNotifications } = newSnapshotValue;
            // filter out notifications that are already being displayed from App.vue (snackbar, etc),
            let numUndisplayedNotifications = 0;
            Object.keys(inAppNotifications).forEach(value => {
                if (!store.state.notificationIdsRead.includes(parseInt(value))) {
                    numUndisplayedNotifications++;
                }
            });
            numNotifications.value = numUndisplayedNotifications;
        }
        return {
            numNotifications,
        }
    },
});
