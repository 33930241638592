import { InputMask } from '@/types';
import { dollarAmountToInt } from './priceUtils';

export function capitalize(string: string) {
    return capitalizeWords(string.split(' '));
  }
  
export function capitalizeWords(words: string[], capitalized: string = ''): string {
  if (!words.length) return capitalized;

  var word = ` ${capitalizeWord(words[0])}`;
  if (!capitalized) word = word.slice(1);
  capitalized += word;

  return capitalizeWords(words.slice(1), capitalized);
}

export function capitalizeWord(word: string) {
  if (!word) {
    return '';
  }
  return word[0].toUpperCase() + word.substring(1, word.length);
}

export function makeCamelCase(string: string) {
  const words = string.split(' ');
  const firstWord = words[0].toLowerCase();
  const otherWordsCapitalized = capitalizeWords(words.slice(1)).replace(/\s/g, '');
  return `${firstWord}${otherWordsCapitalized}`;
}

export function makePascalCase(string: string) {
  return string.replace(/(\w)(\w*)/g,
        function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();}).replace(' ', '');
}


export function undoCamelOrPascalCase(string: string) {
  var spacesAdded = string.replace( /([A-Z])/g, " $1" );
  var regularWords = spacesAdded.charAt(0).toUpperCase() + spacesAdded.slice(1);
  return regularWords;
}

export function capitalizeFirstLetter(str:string) {
  if(str.length > 0){
      // converting first letter to uppercase
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  
      return capitalized;
  }else{
      return '';
  };
};

export function makeFirstLetterLowerCase(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function isGuid(value: string): boolean {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(String(value));
}

export function makePlural(string: string): string {
  var pluralize = require('pluralize');
  return pluralize(string);
}

export function makePluralIfPlural(string: string, number: number, showNum=true): string {
  if (number == 1) {
    return showNum ? `${number} ${string}` : string;
  }

  return showNum ? `${number} ${makePlural(string)}` : makePlural(string);
}

export function normalizeString(str: string) {
  if (str) {
    return str.replace(/[^a-zA-Z0-9]/g, '');
  }
}

export function formatPhoneNumber(str?: string) {
  if (!str) {
    return str;
  }
  return str.replace(/\D+/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function createHyperlinkElementFromString({ displayText, href, openInNewTab }: {
  displayText: string,
  href: string,
  openInNewTab?: boolean
}): HTMLElement {
  let targetAttr = openInNewTab ? 'target="_blank"' : '';
  let linkElementAsString = `<a href="${href}" ${targetAttr}>${displayText}</a>`;
  let divElementAsString = `<div>${linkElementAsString}</div>`;
  let newDoc = new DOMParser().parseFromString(divElementAsString, 'text/html');
  let newElement = Array.from(newDoc.getElementsByTagName('div'))[0];
  return newElement;
}

export function numDaysOrDashesIfNull(numDays?: number | string | null): string {
  if (numDays == null || numDays == undefined) {
    return '--';
  }
  return numDays == 1 
    ? `${numDays} day` 
    : `${numDays} days`;
}

export function removeInputMask(mask: InputMask, value: string): any {
  switch (mask) {
    case 'currency':
      return dollarAmountToInt(value);
    case 'localeString':
      return parseInt(value.replace(',', ''));
    default: 
      return parseInt(value);
  }
}