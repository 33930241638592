import { getArbitrationRateBySellerStoreId, getSellRateBySellerStoreId, getTimeToTitleBySellerStoreId, PUT } from "@/api";
import { GetActiveListingsFilterDTO, ListingRowTableType, SellerStat, SRPListing, TimeDTO } from "@/types";
import { formatSellerStat, openErrorDialog, UpdateUrlParams } from "@/utils";
import { computed, onBeforeMount, onMounted, Ref, ref, SetupContext, watch } from "vue";
import { useRoute } from 'vue-router/composables';
import { LooseRequired } from "vue/types/common";
import { useUser } from "./user";
import { useCancelToken, useFetch } from './fetch';
import { useStore } from "./useStore";

export interface UpdateListingsCallbackParams {
    listings: any[],
    fromFilterUpdate?: boolean
}

/**
 * Handles pagination for the SRP - fetching listings on ScrollObserver or filters update 
 * @param props - used to watch the loadMoreListingsKey, which is used to fetch more paginated listings
 * @param context - used to emit 'canLoadMore' and 'loadingMore' 
 * @param status - the status of the listings to fetch ('Auctioning' or 'InMarketplace')
 * @param updateListingsCallback - when the listings have been fetched, perform custom actions
 * @param customPaginationLimit - default 36
 * @returns an array of listings from vehicles/getActiveListings
 */
export function useGetActiveListings({ props, context, status, updateListingsCallback, customPaginationLimit, initialFilters }: {
    props: Readonly<Readonly<LooseRequired<{ loadMoreListingsKey: number; } & {}>>>,
    context: SetupContext<("canLoadMore" | "loadingMore")[]>, 
    status: 'Auctioning' | 'InMarketplace',
    updateListingsCallback: (payload: UpdateListingsCallbackParams) => void,
    customPaginationLimit?: number,
    initialFilters?: GetActiveListingsFilterDTO,
}) {
    let paginationOffset = 0;
    let paginationLimit = customPaginationLimit ?? 36;
    let canLoadMoreListings = false;
    let currentFilters: GetActiveListingsFilterDTO | undefined = initialFilters;
    const loadingListings = ref(false);

    const user = useUser();

    onMounted(() => {
        if (initialFilters) {
            loadingListings.value = true;
        } else {
            getActiveListings();
        }
    });

    // toggle the component's loadMoreListingsKey prop to load more paginated listings
    watch(() => props.loadMoreListingsKey, () => {
        getActiveListings(currentFilters, true);
    });

    // call from component on filter update to get listings matching the filter
    function getActiveListingsFromFilter(filter: GetActiveListingsFilterDTO | undefined = undefined) {
        getActiveListings(filter, false, true);
    }

    async function getActiveListings(filters: GetActiveListingsFilterDTO | undefined = undefined, fromPagination: boolean=false, fromFilterUpdate: boolean=false) {
        if (fromFilterUpdate) {
            context.emit('canLoadMore', false);
            paginationOffset = 0;
        }
        toggleLoadingListings(fromPagination, true);
        currentFilters = filters;
        await PUT(`/vehicles/getActiveListings`, {
            filters: currentFilters,
            status,
            paginationOffset,
            paginationLimit,
        }).then(res => {
            toggleLoadingListings(fromPagination, false);
            canLoadMoreListings = res.data.length >= paginationLimit;
            const validListings = res.data.filter((listing: any) => Boolean(listing));
            paginationOffset += validListings.length;
            context.emit('canLoadMore', canLoadMoreListings);
            updateListingsCallback({
                listings: validListings,
                fromFilterUpdate
            });
        }).catch(error => {
            toggleLoadingListings(fromPagination, false);
            const listingType = status == 'Auctioning' ? 'Live Auctions' : 'Marketplace listings';
            openErrorDialog({
                title: `Could not fetch ${listingType}`,
                message: `We encountered an error while fetching ${listingType} for person ${user.value?.profile?.id}`,
                error,
            });
        });
    }

    // toggles loading feedback on the component
    // or emits 'loadingMore' to toggle SRP loading feedback while fetching more paginated listings
    function toggleLoadingListings(fromPagination: boolean, value: boolean) {
        fromPagination
            ? context.emit('loadingMore', value)
            : loadingListings.value = value;
    }

    return {
        getActiveListings,
        getActiveListingsFromFilter,
        loadingListings,
    }
}

export function useGetListingTypeFromUrlParams(validListingTypes: string[]) {
    let route = useRoute();
    
    let urlParams = route.query;
    
    // GET LISTING TYPE
    const urlListingType: Ref<ListingType> = ref('auction'); 
    if (validListingTypes.includes(urlParams.listingType as string)) {
        urlListingType.value = urlParams.listingType as ListingType;
    }

    return {
        urlListingType,
    }
}

export type ListingType = 'auction' | 'marketplace' | 'secondChance';

export function useListingType({ context, updateUrlParams, initialListingType, validListingTypes}: {
    context: SetupContext<('update:listingType' | 'selectedListingType' | 'updateVehicleStatus' | any)[]>,
    updateUrlParams?: ({}: UpdateUrlParams) => void, // leave undefined if you don't want to update URL params
    initialListingType?: ListingType | ListingRowTableType,
    validListingTypes: string[],
}) {
    const selectedListingType: Ref<ListingType | ListingRowTableType> = ref(initialListingType ?? 'auction');
    const route = useRoute();
    const listingTypeKey = ref(0);

    function updateSelectedListingType(listingType: ListingType) {
        selectedListingType.value = listingType;
    }

    watch(() => selectedListingType.value, () => {
        context.emit('update:listingType', selectedListingType.value);
        context.emit('updateVehicleStatus', getVehicleStatusFromListingType.value);
        if (updateUrlParams) {
            updateUrlParams({
                route, 
                newQueryParams: { 'listingType': selectedListingType.value }, 
                maintainAllParams: true,
            });
        }
    });

    const { urlListingType } = useGetListingTypeFromUrlParams(validListingTypes);
    onBeforeMount(() => {
        selectedListingType.value = urlListingType.value ?? 'auction';
    });

    const getVehicleStatusFromListingType = computed(() => {
        let status;
        switch (selectedListingType.value) {
            case 'auction':
                status = 'Auctioning';
                break;
            case 'marketplace':
                status = 'InMarketplace';
                break;
            case 'secondChance':
                status = 'SecondChance';
                break;
        }
        return status;
    });

    return {
        selectedListingType,
        updateSelectedListingType,
        listingTypeKey,
        getVehicleStatusFromListingType,
    }
}

export function useRemoveListingRow(listings: Ref<SRPListing[]>) {
    function removeListingRow(vehicleListingId: number) {
        window.setTimeout(() => {
            listings.value = listings.value.filter(listing => listing.id !== vehicleListingId);
        }, 500);
    }

    const removeListingRowQueue: Ref<number[]> = ref([]);
    function queueRemoveListingRow(vehicleListingId: number) {
        removeListingRowQueue.value.push(vehicleListingId);
    }

    function executeRemoveListingRowQueue() {
        removeListingRowQueue.value.forEach(vehicleListingId => {
            removeListingRow(vehicleListingId);
        });
    }

    return {
        removeListingRow,
        removeListingRowQueue,
        queueRemoveListingRow,
        executeRemoveListingRowQueue,
    }
}

export function useTransporationCost({ listingZipCode, vehicleListingId, context }: { 
    listingZipCode?: string, 
    vehicleListingId: number,
    context?: SetupContext<( 'transportationCost' | any )[]>,
}) {
    const store = useStore();
    const zip1 = store.getters.getTransportationZip;
    if (!listingZipCode || !zip1) {
        return {
            transportationCost: ref(undefined),
            isLoadingTransportationCost: ref(false),
        }
    }

    const { cancelToken, createNewCancelToken } = useCancelToken();
    createNewCancelToken();

    const {
        data,
        loading,
    } = useFetch<number>(`/distance/calculateTransportationCostUsingZipCodes`, {
            method: 'PUT',
            data: {
                zip1, 
                zip2: listingZipCode,
                vehicleListingId,
            },
            cancelToken: cancelToken.value?.token,
            skipErrorOnCancel: true,
            onSuccess: () => {
                    if (context) {
                        context.emit('transportationCost', data.value);
                    }
                }
            });

    return {
        transportationCost: data,
        isLoadingTransportationCost: loading,
    };
}

export function useFetchSellingStat({ sellerStoreId, statName }: {
    sellerStoreId: number,
    statName: SellerStat,
}) {
    const sellingStat: Ref<number | string | undefined> = ref(undefined);
    const loadingStat: Ref<boolean> = ref(false);

    const fetchStatFunction: { [key: string]: (sellerStoreId: number) => Promise<number | TimeDTO | undefined>} = {
        'Arbitration Rate': getArbitrationRateBySellerStoreId,
        'Sell Rate': getSellRateBySellerStoreId,
        'Time to Title': getTimeToTitleBySellerStoreId,
    }

    onBeforeMount(async () => {
        loadingStat.value = true;
        let fetchFunction = fetchStatFunction[statName];
        if (!fetchFunction) {
            loadingStat.value = false;
            return;
        }
        await fetchFunction(sellerStoreId)
            .then(res => {
                sellingStat.value = formatSellerStat(statName, res);
                loadingStat.value = false;
            }).catch(error => {
                loadingStat.value = false;
                console.log('error', error);
            });
    });
    return {
        sellingStat,
        loadingStat,
    }
}
