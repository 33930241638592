

import { computed, defineComponent, PropType, ref, } from 'vue';
import { POST } from '../api';
import { dollarAmountToInt, openErrorDialog, toCurrency, isMultipleOfHundred, openToast, dateHasPassed, isStoreCannotBidError, showStoreCannotBidErrorDialog, isMarketplaceOfferError } from '../utils';
import { MarketPlaceOffererTypeEnum } from '../enums';
import { useAuctionTriggerWarningDialog } from '../composables';
import store from '@/vuex';

import InfoHeader from './Arbitrations/InfoHeader.vue';
import InfoHighlight from './Arbitrations/InfoHighlight.vue';
import InputField from './InputField.vue';
import Modal from './Modal.vue';
import TheNegotiationMessageInput from './TheNegotiationMessageInput.vue';
import TheStoreSelectInput from './TheStoreSelectInput.vue';
import AppButton from './AppButton.vue';

export default defineComponent({
    name: 'TheMarketplaceListingOfferModal',
    props: {
        marketplaceListingId: {
            type: Number,
            required: true,
        },
        vehicleListingId: {
            type: Number,
            required: true,
        },
        reservePrice: {
            type: Number,
            required: true,
        },
        buyItNowPrice: {
            type: Number,
            required: true,
        },
        stores: {
            type: Array as PropType<{ id: string, name: string, zip: string }[]>,
            required: false,
        },
        initialStoreId: {
            type: Number,
            required: false,
        },
        currentOffer: {
            type: Object as PropType<{ marketplaceOfferId: number, buyerOfferAmount?: number, sellerOfferAmount?: number, offerExpirationDate?: string, mostRecentOffererTypeId?: MarketPlaceOffererTypeEnum }>,
            required: false,
        },
        sellerPersonId: {
            type: Number,
            required: true,
        },
        isSecondChance: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        InfoHeader,
        InfoHighlight,
        InputField,
        Modal,
        TheNegotiationMessageInput,
        TheStoreSelectInput,
        AppButton,
    },
    emits: ['close', 'offerMade'],
    setup(props, context) {
        const resetOffer = ref(false);
        const buyerPersonId = store.state.user.profile.id;
        const initialStoreIdUpdated = ref(props.initialStoreId);
        const disableStoreSelect = ref(false);
        const selectedStoreId = ref(initialStoreIdUpdated);
        if (props.stores?.length == 1) {
            initialStoreIdUpdated.value = parseInt(props.stores[0].id);
            disableStoreSelect.value = true;
        }
        const hasCurrentOffer = Boolean(props.currentOffer?.marketplaceOfferId);
        const messageInput = ref('');
        const isUserSeller = parseInt(buyerPersonId) === props.sellerPersonId;

        const { showAuctionTriggerWarningDialog } = useAuctionTriggerWarningDialog();
        const loadingSubmitOffer = ref(false);


        async function submitOffer() {
            if ((offerAmountInt.value >= props.reservePrice) && !isUserSeller && !props.isSecondChance) {
                showAuctionTriggerWarningDialog(
                    offerAmountInt.value,
                    props.reservePrice, 
                    () => updateOrCreateMarketplaceOffer(),
                    () => {}
                )
            } else {
                updateOrCreateMarketplaceOffer();
            }
        }

        function updateOrCreateMarketplaceOffer() {
            hasCurrentOffer ? updateMarketplaceOffer() : createNewMarketplaceOffer();
        };

        async function updateMarketplaceOffer() {
            loadingSubmitOffer.value = true;
            await POST(`/marketplace/createMarketplaceOfferAmount`, {
                marketplaceListingId: props.marketplaceListingId,
                storeId: selectedStoreId.value,
                marketplaceOfferId: props.currentOffer?.marketplaceOfferId,
                marketplaceOffererTypeId: isUserSeller ? MarketPlaceOffererTypeEnum['Seller'] : MarketPlaceOffererTypeEnum['Buyer'],
                offerAmount: {
                    amount: offerAmountInt.value,
                    message: messageInput.value ? messageInput.value : undefined,
                }
            }).then(() => {
                context.emit('offerMade');
                context.emit('close');
                openToast('is-success', 'Offer made!');
            }).catch((error) => {
                const isStoreCannotBid = isStoreCannotBidError(error);

                if (isStoreCannotBid) {
                    showStoreCannotBidErrorDialog(error);
                    return;
                }

                if (isMarketplaceOfferError(error)) {
                    openErrorDialog({
                        title: 'Error',
                        message: error.response?.data?.message,
                        error,
                    });
                    return;
                }

                openErrorDialog({
                    title: 'Could not make offer',
                    message: `We encountered an error while making an offer of ${toCurrency(offerAmountInt.value)} on vehicle ${props.vehicleListingId} for buyer ${buyerPersonId}`,
                    error,
                });
            }).finally(() => {
                loadingSubmitOffer.value = false;
            });
        }

        async function createNewMarketplaceOffer() {
            loadingSubmitOffer.value = true;
            await POST(`/marketplace/createNewMarketplaceOffer`, {
                marketplaceListingId: props.marketplaceListingId,
                storeId: selectedStoreId.value,
                offerAmount: {
                    amount: offerAmountInt.value,
                    message: messageInput.value ? messageInput.value : undefined,
                }
            }).then(() => {
                loadingSubmitOffer.value = false;
                context.emit('offerMade');
                context.emit('close');
                openToast('is-success', 'Offer made!');
            }).catch((error) => {
                const isStoreCannotBid = isStoreCannotBidError(error);

                if (isStoreCannotBid) {
                    showStoreCannotBidErrorDialog(error);
                    return;
                }

                if (isMarketplaceOfferError(error)) {
                    openErrorDialog({
                        title: 'Error',
                        message: error.response?.data?.message,
                        error,
                    });
                    return;
                }

                openErrorDialog({
                    title: 'Could not make offer',
                    message: `We encountered an error while making an offer of ${offerAmountInt.value} on vehicle ${props.vehicleListingId} for buyer ${buyerPersonId}`,
                    error,
                });
            }).finally(() => {
                loadingSubmitOffer.value = false;
            })
        }
        
        // Set up offer amount
        const offerAmountStr = ref();
        const offerAmountInt = computed(() => {
            return dollarAmountToInt(offerAmountStr.value);
        });
        const lowestPossibleOffer = computed(() => {
            if (props.currentOffer?.buyerOfferAmount && !resetOffer.value) {
                return props.currentOffer?.buyerOfferAmount;
            }
            return props.reservePrice * 0.8;
        });

        const invalidOfferMessage = ref('');
        const showInvalidMessageBuyItNow = ref(false);
        function validateOfferAmount(inputValue) {
            const intValue = dollarAmountToInt(inputValue);
            if (!isMultipleOfHundred(intValue)) {
                invalidOfferMessage.value = 'Please use multiples of $100';
                showInvalidMessageBuyItNow.value = false;
                return false;
            }

            if (intValue < lowestPossibleOffer.value) {
                invalidOfferMessage.value = `Offer can't be lower than ${toCurrency(lowestPossibleOffer.value)}`;
                showInvalidMessageBuyItNow.value = false;
                return false;
            }

            if (!props.isSecondChance && intValue >= props.buyItNowPrice) {
                invalidOfferMessage.value = '';
                showInvalidMessageBuyItNow.value = true;
                return false;
            }
            invalidOfferMessage.value = '';
            showInvalidMessageBuyItNow.value = false;
            return true;
        }

        const offerExpirationDateHasExpired = computed(() => dateHasPassed(props.currentOffer?.offerExpirationDate));

        return {
            selectedStoreId,
            offerAmountStr,
            offerAmountInt,
            lowestPossibleOffer,
            validateOfferAmount,
            invalidOfferMessage,
            showInvalidMessageBuyItNow,
            toCurrency,
            loadingSubmitOffer,
            submitOffer,
            hasCurrentOffer,
            initialStoreIdUpdated,
            disableStoreSelect,
            messageInput,
            offerExpirationDateHasExpired,
            resetOffer,
            isUserSeller,
        }
    }
});
