export enum VehicleStatusesEnum {
    Registered = 1,
    InspectionScheduled = 2,
    Inspected = 3,
    AuctionScheduled = 4,
    Auctioning = 5,
    Negotiating = 6,
    Checkout = 7,
    Sold = 8,
    Completed = 9,
    NotSold = 10,
    Inactive = 11,
    DirectSubmitted = 12,
    DirectAwaitingOfferResponse = 13,
    DirectAwaitingCounterOfferResponse = 14,
    DirectCounterOfferExpired = 15,
    DirectAwaitingDropoff = 16,
    DirectDropoffWindowExpired = 17,
    DirectAccepted = 18,
    DirectDeclined = 19,
    InstantOfferAccepted = 20,
    InstantOfferCompleted = 21,
    InstantOfferLost = 22,
    MarketplacePending = 23,
    InMarketplace = 24,
    MarketplaceCheckout = 25,
    MarketplaceSold = 26,
    MarketplaceCompleted = 27,
    MarketplaceNotSold = 28,
    SecondChance = 29,
    SecondChanceCheckout = 30,
    SecondChanceSold = 31,
    SecondChanceCompleted = 32,
    SecondChanceNotSold = 33,
  }