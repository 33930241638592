var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheNegotiationModal',_vm._b({on:{"close":function($event){return _vm.$emit('close')},"counter":function($event){return _vm.$emit('counter', $event)},"reject":function($event){return _vm.$emit('reject', $event)},"accept":function($event){return _vm.$emit('accept', $event)}},scopedSlots:_vm._u([{key:"modalHeader",fn:function(){return [_vm._t("modalHeader")]},proxy:true},{key:"header",fn:function(){return [_vm._t("header")]},proxy:true},{key:"details",fn:function(){return [_vm._t("details")]},proxy:true},{key:"additionalDetails",fn:function(){return [_vm._t("additionalDetails")]},proxy:true},{key:"buttons",fn:function(){return [_vm._t("buttons")]},proxy:true},{key:"counterOfferDetails",fn:function(slotProps){return [(_vm.allowMessages)?_c('section',[_c('TheNegotiationMessageInput',{staticClass:"mt-2",attrs:{"inputAttrs":{
                    placeholder: 'Add an optional message'
                },"buttonAttrs":{
                    label: 'Send Offer',
                    disabled: slotProps.disableSubmitCounterOffer,
                    loading: slotProps.loadingSubmitCounterOffer,
                },"showSubmitButton":""},on:{"update:value":function($event){return _vm.$emit('update:note', $event)},"submit":function($event){return _vm.$emit('counter', slotProps.counterOfferInput, $event)}}})],1):_vm._e()]}},{key:"mostRecentMessage",fn:function(slotProps){return [(_vm.displayMessage)?_c('TheNegotiationMessageMostRecent',_vm._b({class:{ 'mt-3': !slotProps.makingCounterOffer },attrs:{"canViewAllMessages":_vm.loadingMessages || Boolean(_vm.negotiationMessages?.length)},on:{"click":function($event){_vm.viewAllMessages=true}}},'TheNegotiationMessageMostRecent',{ 
                ..._vm.displayMessage, 
                loadingMessages: _vm.loadingMessages,
                date: new Date(_vm.displayMessage.date)
            },false)):_vm._e()]}},{key:"modalBody",fn:function(){return [(_vm.viewAllMessages)?_c('div',{staticStyle:{"min-height":"200px"}},[_c('AppButton',{staticClass:"back-to-negotiations-button animate-icon-shake",attrs:{"label":"Back to Negotiations","icon-left":"chevron-left","type":"is-primary-light","expanded":""},on:{"click":function($event){_vm.viewAllMessages = false}}}),_c('TheNegotiationMessageHistory',{staticClass:"message-history",attrs:{"negotiationMessages":_vm.negotiationMessages}})],1):_vm._e()]},proxy:true}],null,true)},'TheNegotiationModal',{
        ..._vm.$props,
        ..._vm.$attrs,
        showSendOfferButton: false,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }