import { cancelInspectionRequestByInventoryId, GET, requestInspectionByInventoryId } from '@/api';
import { APIConfig, CelebrateValidation, VehicleListingAnnouncement, AnnouncementType, VehicleListingAnnouncementUpdate, VehicleListingAnnouncementCategorizedDTO } from '@/types';
import { InspectionConditionsDTO, InspectionDTO } from '@/types/InpectionDTO';
import { VehicleDTO } from "@/types/VehicleDTO";
import { BDialogConfig } from 'buefy/types/components';
import { has, isEmpty } from 'lodash';
import { openAlertDialog, openConfirmationDialog, openErrorDialog, openToast } from './buefyUtils';
import { formatCelebrateValidationError, formatErrorObj } from './feedbackUtils';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess } from './otherUtils';
import { addInfoToArray } from "./vdpUtils";

export function updateInspectionDataCategory(vehicle: VehicleDTO, categoryName: string, featureName: string, featureUpdates: any) {
    const category = getInspectionCategory(vehicle, categoryName);
    const featureIdx = getInspectionDataFeature(category, featureName, true) as number | undefined;
    if (featureIdx && category) {
        category.conditions[featureIdx] = featureUpdates;
    }
    return vehicle;
}

export function getInspectionDataFeature(inspectionCategory: InspectionDTO | undefined, featureName: string, findIndex: Boolean=false): number | InspectionConditionsDTO | undefined {
    if (!inspectionCategory) {
        return;
    }

    return findIndex 
        ? inspectionCategory.conditions?.findIndex(condition => condition.feature == featureName)
        : inspectionCategory.conditions?.find(condition => condition.feature == featureName);
}

export function getInspectionCategory(vehicle: VehicleDTO, categoryName: string) {
    return vehicle?.inspectionData?.find(category => category.title == categoryName);
}


export function checkMissingInspectionFields(vehicle: any) {
    return true;

}


export function getTitleStatusToSave(value: {titleStatus: string}): { titleStatus: string } {
    switch (value.titleStatus) {
      case 'With Bank':
        value.titleStatus = 'Loan';
        break;
      case 'With Seller':
        value.titleStatus = 'Self';
        break;
      case 'Unknown':
      default:
        value.titleStatus = 'lost';
        break;
    }
    return value;
}

export function addMissingInspectorFieldsSellerInfo(sellerInfo: any[]) {
    // Seller Info
    addMissingInspectorField(sellerInfo, 'Title Status', '');
    addMissingInspectorField(sellerInfo, 'Seller Type', '');
    addMissingInspectorField(sellerInfo, 'Seller Reason', '');
    return sellerInfo;
};

export function addMissingInspectorFieldsTireInfo(tireTreadInfo: any[]) {
    if (!tireTreadInfo.length) {
        const leftColumn: any[] = [];
        const rightColumn: any[] = [];
        addInfoToArray(leftColumn, 'Front Driver Value', '');
        addInfoToArray(rightColumn, 'Front Passenger Value', '');
        addInfoToArray(leftColumn, 'Rear Driver Value', '');
        addInfoToArray(rightColumn, 'Rear Passenger Value', '');
        tireTreadInfo = [leftColumn, rightColumn];
    }
    return tireTreadInfo;
}
  
export function addMissingInspectorField(arrayToUpdate: any[], title: string, value: any) {
    const isMissing = checkMissingValue(title, arrayToUpdate);
    if (isMissing) {
        arrayToUpdate = addInfoToArray(arrayToUpdate, title, value);
    }
    return arrayToUpdate;
};

export function checkMissingValue(title: string, arrayToCheck: { title: string }[]) {
    if (!arrayToCheck.length) {
      return true;
    }

    return !arrayToCheck.some(info => info.title == title);
}

export function isInspectionFieldInvalid(invalidFields: { [key: string]: string }, fieldName: string ) {
    return has(invalidFields, fieldName);
}

export function openSubmitInspectionErrorDialog({ error, message, validation }: { error?: Error, message?: string, validation?: CelebrateValidation }): { [key: string]: string } | void {
    let invalidFieldsObj = error?.message ? JSON.parse(error.message) : {};
    try {
        if (error?.name == 'Invalid inspection data') {
            openErrorDialog({
                title: 'Invalid inspection data',
                message: `
                    <div>
                        <p>One or more fields of the inspection were invalid. Update the fields and resubmit.</p>
                        <div class="has-text-danger">
                            ${formatErrorObj(invalidFieldsObj, { showKey: false })}
                        </div>    
                    </div>
                `,
                displayErrorInDialog: true,
                error,
            });
        } else if (error?.name === 'Vehicle violates Buy-It-Now conditions') {
            openErrorDialog({
                title: 'Listing violates Buy-It-Now conditions',
                message: `
                    <div>
                        <p class="bold">Unable to update Buy It Now. This vehicle is not eligible for Buy It Now due to auction conditions.</p>
                        <p class="py-3">
                            <span class="bold">(1)</span> The reserve must be at least $500 higher than the starting price, 
                            <span class="bold">(2)</span> the highest bid can't be within $500 of the reserve, and 
                            <span class="bold">(3)</span> the remaining auction time must be more than 30 minutes.
                        </p>
                        <p class="bold has-text-danger">Please review the auction details and try again.</p>
                    </div>
                `,
                displayErrorInDialog: true,
                error,
            });
        } else if (message == 'celebrate request validation failed') { 
            let celebrateValidationMessage = validation?.body.message;
            let invalidFields = validation?.body.keys; // I think there's only ever one
            invalidFields?.forEach(field => {
                invalidFieldsObj[field] = celebrateValidationMessage;
            });
            openErrorDialog({
                title: 'Initial validation failed',
                message: `
                    <div>
                        <p>One or more fields of the inspection were invalid. Update the fields and resubmit.</p>
                        <div class="has-text-danger">${formatErrorObj(invalidFieldsObj)}</div>
                    </div>`,
                error,
                displayErrorInDialog: true, 
            });
        } else {
            openErrorDialog({
                title: 'Error submitting inspection',
                message: `An unknown error occurred while submitting your inspection. Please review your inspection and resubmit. If the problem persists, submit a bug ticket.`,
                error,
                displayErrorInDialog: true,
            });
        }
        return !isEmpty(invalidFieldsObj) ? invalidFieldsObj : undefined;
    } catch(error) {
        openErrorDialog({
            title: 'Error submitting inspection',
            message: `A timeout or unknown error occurred while submitting your inspection. Please review your inspection and resubmit. If the problem persists, contact support.`,
            error,
            displayErrorInDialog: true,
        });
    }
}

export async function getInspectorPersonIdByVehicleListingId(vehicleListingId: number, config: APIConfig={}): Promise<number | null> {
    return await GET(`/inspection/${vehicleListingId}/inspectorPersonId`)
        .then((res: any) => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch((error: Error) => {
            applyAPIConfigOnError(error, config);
            let celebrateValidationError = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to get inspector',
                message: celebrateValidationError ?? `We encountered an error while fetching inspector details for vehicle ${vehicleListingId}`,
                error,
            });
        });
}

export async function openRequestInspectionConfirmationDialog(inventoryId: number, dialogConfig: Partial<BDialogConfig>={}, apiConfig: APIConfig={}) {
    console.log('OPEN CONFIRMATION')
    openConfirmationDialog({
        title: 'Confirm your request for an inspection', 
        message: `We'll create your listing for you and get it live on our platform in a jiffy`, 
        onConfirm: async(value, dialog) => {
            await requestInspectionByInventoryId(inventoryId, {
                ...apiConfig,
                onSuccess: (res) => {
                    if (apiConfig.onSuccess) {
                        applyAPIConfigOnSuccess(res, apiConfig);
                    }
                    openAlertDialog({
                        title: `Your inspection has been requested`,
                        message: `We'll have your car inspected soon!`, 
                    });
                },
            })
        },
        ...dialogConfig,
    });
}

export async function openCancelInspectionRequestConfirmationDialog(inventoryId: number, dialogConfig: Partial<BDialogConfig>={}, apiConfig: APIConfig={}) {
    openConfirmationDialog({
        title: 'Cancel your inspection request?',
        message: `Are you sure you want to cancel your inspection request?`, 
        onConfirm: async (value, dialog) => {
            await cancelInspectionRequestByInventoryId(inventoryId, {
                ...apiConfig,
                onSuccess: (res) => {
                    if (apiConfig.onSuccess) {
                        applyAPIConfigOnSuccess(res, apiConfig);
                    }
                    openAlertDialog({
                        title: 'Your inspection has been cancelled',
                        message: `You can request another inspection at any time`, 
                    });
                },
            });
        },
        ...dialogConfig,
    });
}

export function orderAnnouncementsByType(announcements: VehicleListingAnnouncement[]) {
    return announcements.sort((a, b) => {
        const order = { 'positive': 1, 'neutral': 2, 'negative': 3, 'as_is': 4 };
        return order[a.announcementType] - order[b.announcementType];
    });
}

export function getIconColorByAnnouncementType(announcementType?: AnnouncementType) {
    switch (announcementType) {
        case 'positive':
            return 'has-text-primary';
        case 'negative':
            return 'has-text-yellow';
        case 'as_is':
            return 'has-text-danger';
        case 'neutral':
            return 'has-text-grey';
        default:
            return 'has-text-grey-light';
    }
}

export function getBackgroundColorByAnnouncementType(announcementType?: AnnouncementType) {
    switch (announcementType) {
        case 'positive':
            return 'has-background-primary-light';
        case 'negative':
            return 'has-background-yellow-light';
        case 'as_is':
            return 'has-background-danger-light';
        case 'neutral':
        default:
            return 'has-background-grey-lighter';
    }
}

export function getMostSevereAnnouncementTypeFromList(announcements?: VehicleListingAnnouncement[]): AnnouncementType | undefined {
    if (!announcements?.length) {
        return undefined;
    }
    const sortedAnnouncements = announcements.sort((a, b) => {
        const order = { 'as_is': 1, 'negative': 2, 'neutral': 3, 'positive': 4 };
        return order[a.announcementType] - order[b.announcementType];
    });

    return sortedAnnouncements[0].announcementType;
}

export function validateAnnouncement({ announcement, announcementTypeId, announcementSubcategoryId, vehicleListingPhotoId }: VehicleListingAnnouncementUpdate): boolean {
    if (!announcementTypeId || !announcementSubcategoryId || !announcement) {
        return false;
    }
    return true;
}

export function getMostSevereAnnouncementType(announcements?: VehicleListingAnnouncementCategorizedDTO): AnnouncementType | undefined {
    if (!announcements) {
        return undefined;
    }
    return getMostSevereAnnouncementTypeFromList(Object.values(announcements).flat());
} 