<template>
    <b-switch v-bind="$attrs" @input="$emit('input', $event)">
        <slot />
    </b-switch>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AppSwitch',
});
</script>