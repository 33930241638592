
import { computed, defineComponent, PropType, reactive, ref, Ref, WritableComputedRef } from 'vue';
import { updateUserNotificationSetting } from '../api';
import { MessageModality } from '../enums';
import { NotificationModalitySetting, NotificationSettingsDTO } from '../types';

import AppCheckboxInput from './AppCheckboxInput.vue';

export type DisplayModality = 'email' | 'sms' | 'inApp';
export default defineComponent({
    name: 'TheNotificationSetting',
    props: {
        notificationSetting: {
            type: Object as PropType<NotificationSettingsDTO>,
            required: true,
        }
    },
    components: {
        AppCheckboxInput,
    },
    setup(props, { emit, attrs }) {
        const updatedNotificationSetting: Ref<NotificationSettingsDTO> = ref(props.notificationSetting);

        const emailModality: WritableComputedRef<boolean | undefined> = computed({
            get: () =>  findNotificationModalitySetting('email')?.enabled,
            set: ((enabled: boolean) => updateNotificationSetting('email', enabled)),
        });

        const smsModality: WritableComputedRef<boolean | undefined> = computed({
            get: () =>  findNotificationModalitySetting('sms')?.enabled,
            set: ((enabled: boolean) => updateNotificationSetting('sms', enabled)),
        });

        const inAppModality: WritableComputedRef<boolean | undefined> = computed({
            get: () =>  findNotificationModalitySetting('inApp')?.enabled,
            set: ((enabled: boolean) => updateNotificationSetting('inApp', enabled)),
        });


        const allInAppModalityIds = [3, 4, 5, 6, 7];
        function findNotificationModalitySetting(modalityStr: string) {
            return updatedNotificationSetting.value.modalities?.find(modality => {
                if (modalityStr == 'inApp') {
                    return allInAppModalityIds.includes(modality.modalityId);
                }
                return modality.modalityId == MessageModality[modalityStr];
            });
        }

        function updateNotificationSetting(modality: DisplayModality, enabled: boolean) {
            let modalitySetting = findNotificationModalitySetting(modality);
            if (modalitySetting) {
                modalitySetting.enabled = enabled;
                emit('update:notificationSetting', updatedNotificationSetting.value);
                saveUpdatedNotificationSetting({
                    notificationSettingId: modalitySetting.notificationSettingId,
                    modalityId: modalitySetting.modalityId,
                    modality,
                    enabled,
                });
            }
        }

        const loadingModalities = reactive({
            email: false,
            sms: false,
            inApp: false,
        });

        function toggleModalityLoading(modality: DisplayModality, loading: boolean) {
            loadingModalities[modality] = loading;
        }

        async function saveUpdatedNotificationSetting({ notificationSettingId, modality, modalityId, enabled }: NotificationModalitySetting & { modality: DisplayModality }) {
            toggleModalityLoading(modality, true);

            updateUserNotificationSetting({ notificationSettingId, modalityId, enabled }, {
                onSuccess: () => toggleModalityLoading(modality, false),
                onError: () => toggleModalityLoading(modality, false),
            });
        }

        return {
            emailModality,
            smsModality,
            inAppModality,
            loadingModalities,
        }
    },
});
