/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import Cleave from 'cleave.js';

function updateHook(el: HTMLElement) {
  const input = el.querySelector('input');
  const event = new Event('input', { bubbles: true });

  setTimeout(() => {
    input?.dispatchEvent(event);
  }, 100);
}

Vue.directive('cleave', {
  bind(el, binding) {
    if (!binding.value) {
      return;
    }

    const input = el.querySelector('input') as HTMLInputElement;
    // @ts-expect-error: _vCleave added
    input._vCleave = new Cleave(input, binding.value);

    updateHook(el);
  },
  update: updateHook,
  unbind(el) {
    const input = el.querySelector('input');
    // @ts-expect-error: _vCleave added
    input?._vCleave?.destroy();
  },
});
