import { capitalize } from 'lodash';
import { GET, PUT } from './api';

const validationMethod = {
  email: validateEmail,
  firstName: validateText,
  lastName: validateText,
  phoneNumber: validatePhone,
  password: validatePassword,
  howDidYouHear: validateDropdown,
  referralCode: validateReferralCode,
};

export function validateAll(fields: string[], values: Object) {
  const hasInvalid = fields.some((field) => {
    if (field === 'referralCode') return;
    const validation = validationMethod[field];
    if (validation) {
      const isValid = validation(values[field]);
      return !isValid;
    }
  });
  return !hasInvalid;
}

export function validateText(field?: string) {
  if (field === undefined) { return false; }
  return field?.length > 0;
}

export function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone: string | null) {
  const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return re.test(String(phone));
}

export function validateDropdown(value) {
  return value.length > 0; // Ehhh... this is iffy
}

export async function validateReferralCode(value: string) {
  if (value.length) {
    try {
      const result = (await GET(`referrals/validateReferralCode/${value}`));
      return result.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  return false;
}

/**
 * Converts formatted phone number to digits only.
 * @param {String} value - Phone number with country code or without.
 * @returns {String} - 10-digit non-formatted phone number.
 */
export function formattedPhoneNumberToDigits(value: string | null) {
  const onlyDigits = value.replace(/[^\d]/g, '');
  return onlyDigits.slice(-10);
}

/**
 * Converts formatted or non-formatted phone number to formatted number.
 * @param {String} value - Formatted/Non-formatted phone number.
 * @returns {String} - 10-digit formatted number.
 */
export function phoneFormatter(value: string) {
  if (!value) {
    return null;
  }
  const onlyDigits = formattedPhoneNumberToDigits(value);
  const x = onlyDigits.replace(/[^0-9\.]+/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/) as RegExpMatchArray;
  const result = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
  return result;
}
export function validatePassword(value: string) {
  return value.length >= 8;
}

export function validateZip(value: string, required?: boolean) {
  if (required) {
    return value.length === 5;
  }
  return value.length === 5 || !value.length;
}

export function validateBankRouting(value) {
  const re = /[0-9]{9}/;
  return re.test(String(value));
}

export function validateBankAccountNumber(value) {
  const re = /[0-9]{4,17}/;
  return re.test(String(value));
}

export function validateVin(field: string, fullLengthVinOnly: boolean) {
  if (fullLengthVinOnly) return field.length == 17;
  return field.length == 17 || field.length == 9;
}

export function validateDollarAmount(field: string, canBeNegative = false) {
  const validDollarAmount = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
  const isValidDollarAmountFormat = validDollarAmount.test(field);

  const fieldAsNumber = Number(field);
  if (!canBeNegative && fieldAsNumber) return fieldAsNumber >= 0 && isValidDollarAmountFormat;
  return isValidDollarAmountFormat;
}



export function validatePrice(
  price: number | undefined, 
  priceType: 'reserve' | 'starting' | 'buy-it-now',
  { isUndefinedInvalid = false }: { isUndefinedInvalid?: boolean }={}
): string | undefined {
  let priceName = `${capitalize(priceType)} Price`;
  if (price == undefined) {
      return !isUndefinedInvalid ? undefined : `Required`;
  }
  if (price < 0) {
      return `Cannot be negative`;
  }
  if (price == 0) {
      return `Required`;
  }
  if (price % 100 !== 0) {
      return `Must be a multiple of 100`;
  }
}