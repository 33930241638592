import { AuctionPartner } from "@/types";

export function getAuctionPartnerDetailsByOriginPublicId(originId: string): AuctionPartner | undefined {
    switch(originId) {
        case '36a2a486-c039-43e6-9d6c-c19d2164309d': 
            return {
                name: 'carmart',
                href: 'https://www.car-mart.com/',
                imgSrc: 'https://www.car-mart.com/wp-content/themes/carmart/images/car-mart-logo-new.png',
                imgAlt: 'Car-mart',

            };
        case 'fc62eee0-4d99-4eaf-96a4-0ee0c7ebd2fb': 
            return {
                name:'motorcloud',
                href: 'https://motorcloud.com/',
                imgSrc: 'https://motorcloud-static.s3.amazonaws.com/static/images/motorcloud-logo.png',
                imgAlt: 'Motorcloud',
            }
        default: 
            return undefined;
    }
}