import { APIConfig, ImageDTO } from "@/types";
import { AttachmentDTO } from "@/types/attachment";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, openAlertDialog, openToast, uploadAttachmentsToStorage, UploadedFile } from "@/utils";
import { BNoticeComponent } from "buefy/types/components";
import { pick } from "lodash";
import { Ref, ref, SetupContext } from "vue";

export function useUploadImage({ showToasts, context }: {
    showToasts: boolean,
    context: SetupContext<(any)[]>,
}) {
    const uploadedFiles = ref<ImageDTO[]>([]);
    const numSuccessfulUploads = ref(0);
    const attachmentUploadQueue = ref<(File & { file: AttachmentDTO })[]>([]);
    const attachmentUploadQueueIdx = ref(-1);
    const newAttachments = ref<AttachmentDTO[]>([]);
    const failedUploadsIdx = ref<number[]>([]);
    const openedToast: Ref<BNoticeComponent | null> = ref(null);
    const loadingUploadImages: Ref<boolean> = ref(false);

    function addToUploadQueue(file: File & { file: AttachmentDTO }) {
        attachmentUploadQueue.value.push(file);
    }

    function executeUploadQueue(filePath?: string, config: APIConfig={}) {
        if (!filePath) {
            return;
        }
        if (attachmentUploadQueueIdx.value == -1) {
            attachmentUploadQueueIdx.value = 0;
            uploadFilesAndRetryOnFailure({ filePath, config });
        }
    }

    async function uploadFilesAndRetryOnFailure({ filePath, config, uploadAttemptNum }: { filePath: string, config?: APIConfig, uploadAttemptNum?: number }) {
        loadingUploadImages.value = true;

        if (attachmentUploadQueueIdx.value >= attachmentUploadQueue.value.length) {
            context.emit('upload', uploadedFiles.value);
            applyAPIConfigOnSuccess(uploadedFiles.value, config);
            showUploadSummaryToastOrAlert();
            loadingUploadImages.value = false;
            attachmentUploadQueueIdx.value = -1;
            attachmentUploadQueue.value = [];
            newAttachments.value = [];
            uploadedFiles.value = [];
            return;
        }
        showUploadingToast();
        const fileToUpload = attachmentUploadQueue.value[attachmentUploadQueueIdx.value]?.file;
        await uploadAttachmentsToStorage(filePath, [fileToUpload])
            .then((res: UploadedFile[]) => {
                let uploadedImageDTO: ImageDTO = pick(res[0], ['filename', 'url', 'largeUrl', 'mediumUrl', 'smallUrl', 'progress'])
                uploadedFiles.value = uploadedFiles.value.concat(uploadedImageDTO);
                context.emit('uploaded', fileToUpload.name);
                numSuccessfulUploads.value++;
                attachmentUploadQueueIdx.value++;
                uploadFilesAndRetryOnFailure({ filePath, config });
            }).catch(error => {
                console.log('ERROR', error);
                // re-attempts twice (total of 3 attempts)
                // if fails on third attempt, moves on to the next attachment
                if ((uploadAttemptNum ?? 1) >= 3) {
                    loadingUploadImages.value = false;
                    applyAPIConfigOnError(error, config);
                    attachmentUploadQueueIdx.value++;
                    uploadFilesAndRetryOnFailure({ filePath, config });
                } else {
                    failedUploadsIdx.value.push(attachmentUploadQueueIdx.value + 1);
                    uploadFilesAndRetryOnFailure({ 
                        filePath, 
                        uploadAttemptNum: uploadAttemptNum ? uploadAttemptNum + 1 : 2,
                        config,
                    });
                }
            });
    }

    function showUploadingToast() {
        if (!showToasts || !attachmentUploadQueue.value.length) {
            return;
        }
        let failedText = ` | ${failedUploadsIdx.value.length} failed`;
        openedToast.value = openToast(
            'is-info',
            `Uploading ${numSuccessfulUploads.value + 1} of ${attachmentUploadQueue.value.length} attachments${failedUploadsIdx.value.length > 0 ? failedText : '...'}`,
            'indefinite'
        )
    }

    function showUploadSummaryToastOrAlert() {
        if (!showToasts || !attachmentUploadQueue.value.length) {
            return;
        }
        closeOpenToast();
        if (failedUploadsIdx.value.length > 0) {
            showFailedUploadsAlert();
        } else {
            openedToast.value = openToast('is-success', `All ${numSuccessfulUploads.value} attachments uploaded successfully`);
        }
        resetUploadCount();
    }

    function showFailedUploadsAlert() {
        if (!showToasts || !attachmentUploadQueue.value.length) {
            return;
        }
        let commaSeparatedIndexes = failedUploadsIdx.value.join(', ').replace(/, ([^,]*)$/, ' and $1');
        openAlertDialog({
            title: 'Some attachments failed to upload',
            message: `
                <p>Uploaded ${numSuccessfulUploads.value}/${attachmentUploadQueue.value.length} attachments successfully.</p>
                <p>Failed to upload attachments ${commaSeparatedIndexes} (${failedUploadsIdx.value.length} total).</p>
            `,
        });
    }

    function resetUploadCount() {
        numSuccessfulUploads.value = 0;
        failedUploadsIdx.value = [];
    }

    function closeOpenToast() {
        if (openedToast.value) {
            openedToast.value.close();
            openedToast.value = null;
        }
    }

    return {
        addToUploadQueue,
        executeUploadQueue,
        uploadedFiles,
        loadingUploadImages,
    }
}
