<template>
  <section class="carmigo-modal">
    <Loading v-if="loading" />
    <div v-else class="modal-card modalWidth">
      <header class="modal-card-head">
        <h1 class="modal-card-title">{{existingOrder.hasExistingOrder ? "Edit Order" : "Create Order"}}</h1>
      </header>
      <div class="modal-card-body">
        <b-tabs expanded v-model="activeTabIdx">
          <b-tab-item label="Buyer Details">
            <div>
              <b-field label="Highest Bid">
                <b-numberinput
                  type="number"
                  v-model="highestBid"
                  minlength="1"
                  icon="cash-100"
                  icon-right="close-circle"
                  icon-right-clickable
                  @icon-right-click="updatedHighestBid = null"
                  :placeholder="highestBid"
                  :controls="false"
                  required
                >
                </b-numberinput>
              </b-field>
              <b-field label="Highest Bidder">
                <b-autocomplete
                  v-model="highestBidder"
                  :data="filteredBuyersArray"
                  
                  :open-on-focus="true"
                  field="selector"
                  :placeholder="highestBidderPlaceHolder
                  "
                  icon="account"
                  icon-right-clickable
                  @icon-right-click="highestBidder = null"
                  clearable
                  @select="(option) => handleSelectedBuyer(option)"
                >
                  <template #empty>No results found</template>
                </b-autocomplete>
              </b-field>

                <div>
                  <div v-for="(item, i) in ancillaryOptions" :key="i">
                    <div class="options">
                      <b-field :label="ancillaryOptions[i].name">
                        <div>

                            <!-- This Radio is for the arbitrations -->
                            <b-radio v-if="ancillaryOptions[i].name !== 'buyerFee' && ancillaryOptions[i].name !== 'transportation' && ancillaryOptions[i].name !== 'photos' && ancillaryOptions[i].name !== 'carmigoFloatFee'" v-model="newOrder.arbitrationOption" :native-value="ancillaryOptions[i].name"></b-radio>
                            
                            <!-- This radio button is for the photos -->
                            <div class="addPhotosButtonDiv">
                            <b-radio-button v-if="ancillaryOptions[i].name === 'photos'" :native-value="false" v-model="newOrder.addPhotos" type="is-danger"><span>No</span> </b-radio-button>
                            <b-radio-button v-if="ancillaryOptions[i].name === 'photos'" :native-value="true" v-model="newOrder.addPhotos" type="is-primary"><span>Yes</span> </b-radio-button>
                            </div>

                            <div class="addPhotosButtonDiv">
                            <b-radio-button v-if="ancillaryOptions[i].name === 'carmigoFloatFee'" :native-value="false" v-model="newOrder.floatingFee" type="is-danger"><span>No</span> </b-radio-button>
                            <b-radio-button v-if="ancillaryOptions[i].name === 'carmigoFloatFee'" :native-value="true" v-model="newOrder.floatingFee" type="is-primary"><span>Yes</span> </b-radio-button>
                            </div>

                            <!-- This radio button is for the transpo -->
                            <div class="addPhotosButtonDiv">
                            <b-radio-button v-if="ancillaryOptions[i].name === 'transportation'" :native-value="false" v-model="newOrder.addtransportation" type="is-danger"><span>No</span> </b-radio-button>
                            <b-radio-button v-if="ancillaryOptions[i].name === 'transportation'" :native-value="true" v-model="newOrder.addtransportation" type="is-primary"><span>Yes</span> </b-radio-button>
                            </div>

                            <b-numberinput
                            type="number"
                            v-model="ancillaryOptions[i].price"
                            minlength="1"
                            icon="currency-usd"
                            icon-right="close-circle"
                            icon-right-clickable
                            @icon-right-click="ancillaryOptions[i].price = null"
                            :placeholder="ancillaryOptions[i].name"
                            :controls="false"
                            >
                            </b-numberinput>
                        </div>
                      </b-field>
                    </div>
                  </div>
                </div>
              <!-- </b-card> -->
            </div>
          </b-tab-item>

          <b-tab-item label="Seller Details">
            <b-field label="Seller Fee">
                <b-numberinput
                  type="number"
                  v-model="sellerFee"
                  minlength="1"
                  icon="cash-multiple"
                  icon-right="close-circle"
                  icon-right-clickable
                  @icon-right-click="sellerFee = null"
                  placeholder="sellerFee"
                  :controls="false"
                >
                </b-numberinput>
              </b-field>
          </b-tab-item>
        </b-tabs>
        
      </div>
      <footer class="modal-card-foot">
        <b-button label="Cancel" type="is-danger" @click="$emit('close')" />
        <b-button
          label="Submit"
          type="is-primary"
          :disabled="disableSubmit()"
          :loading="loadingSaveEdits"
          @click="submitAndRefresh"
        />
      </footer>
    </div>
  </section>
</template>

<script>
import { GET, POST, PUT, updateOrderSellFee } from "../api";
import Loading from "./ComponentLoading.vue";
import SearchByUserName from "./SearchByUserName.vue";
import ReferralUserDetails from "./ReferralUserDetails.vue";
import { openDialogAlert, openToast } from '../utils';
export default {
  name: "ModalCreateOrder",
  props: {
    vehicleListingId: {
      type: Number,
      required: true,
    },
    marketplaceListingId: {
      type: Number,
      required: false, 
    }
  },
  components: {
    Loading,
    SearchByUserName,
    ReferralUserDetails,
  },
  data() {
    return {
      activeTabIdx: 0,
      loadingSaveEdits: false,
      newOrder: {
        arbitrationOption: "",
        addPhotos: false,
        addtransportation: true,
        floatingFee: false
      },
        existingOrder: {},
        loading: false,
        ancillaryOptions: [],
        highestBid: 0,
        highestBidder: "",
        sellerFee: 0,
        buyerFee: 0,
        buyersArray: [],
        highestBidderPlaceHolder: "Please select a buyer",
        highestBidderSelected: {
          buyerStoreEmployeeId: null,
          firstName: "",
          lastName: "",
          storeId: null,
          storeName: ""
        }
    };
  },
  async mounted() {
    this.loading = true;
      await Promise.all([
        this.getOrderFeatures(),
        this.organizeBuyers()
    ]).then(() => {
      this.setInitialHighestBidder();
      this.loading = false;
    }).catch((error) => {
      this.$emit('close');
      console.log(error);
      this.$buefy.toast.open({
        message: 'There was a problem when trying to open the create order modal, please try again',
        type: "is-danger",
        duration: 8000
      });

      this.loading = false;
    });
  },
  methods: {
    setInitialHighestBidder() {

      // If the buyerStireEmployyeId === 0, then we are using the unkown buyer, and want to leave the highestBidder as undefined
      if(this.existingOrder.buyerStoreEmployeeId !== 0) {

        // Loop thru the buyers Array
        for(let i = 0; i < this.buyersArray.length; i++) {

          // If there is an id that matches our existing order, then we want that buyer to be pre-selected
          if(this.buyersArray[i].buyerStoreEmployeeId === this.existingOrder.buyerStoreEmployeeId) {

            // Set the highestBidder 
            this.highestBidderSelected = this.buyersArray[i];

            // Set the highestBidders name
            this.highestBidder = this.buyersArray[i].selector;
            break;
          };
        };
      };
    },
    handleSelectedBuyer(option) {
      this.highestBidderPlaceHolder = option ? option.firstName + " " + " " + option.lastName + " " + '- ' + option.storeName : "Please select a buyer";
      this.highestBidderSelected = option;
    },
    disableSubmit(){
      // If anything is missing then return disabled
      if(
      !this.highestBidderSelected 
      || !this.highestBidderSelected.buyerStoreEmployeeId 
      || !this.highestBidderSelected.storeId
      || !this.vehicleListingId
      || !this.highestBid
      || !this.sellerFee && this.sellerFee !== 0
      || !this.existingOrder.sellerPersonId
      || !this.newOrder.arbitrationOption
      ) {
        return true;
      }
        return false;
    },
    async submitAndRefresh() {
      if (this.activeTabIdx == 1 && this.existingOrder.hasExistingOrder) {
        this.loadingSaveEdits = true;
        await updateOrderSellFee(this.vehicleListingId, this.sellerFee)
          .catch(error => {
            console.log(`Error in updateOrderSellFee for vehicleListingId ${this.vehicleListingId} and sellFee ${this.sellerFee}`, error);
            this.loadingSaveEdits = false;
            const message = error?.response?.data?.error;
            openDialogAlert('Error saving sell fee', `${message}`);
          });
        
        this.loadingSaveEdits = false;
        openToast('is-success', 'Updated sell fee!');
        this.$emit('updatedSellFee');
        this.$emit('close');
        return;
      }
      this.loading = true;
      await this.createOrEditOrder()
      .then(async () => {
        this.loading = false;
        this.$emit("getAuctions");
        this.$emit("close");
      })
        .catch((err) => {
          if(err.response.data.error) {
            this.$buefy.toast.open({
              message: err.response.data.error,
              type: "is-danger",
              duration: 8000
            });
          }

          this.loading = false;
        });
    },

    async createOrEditOrder() {
        // type = {buyerStoreEmployeeId: number, firstName: string, lastName: string, storeId: number, storeName: string}
        const selectedBuyer = this.highestBidderSelected;

        let features = [];
        this.ancillaryOptions.forEach((option) => {
          // Go thru each ancillary and check against the selected in this.newOrder
          // If the option is photos or transpo then we need to check their 
          switch(option.name) {
            case "photos":
              option.selected = this.newOrder.addPhotos;
              break;

            case "carmigoFloatFee":
              option.selected = this.newOrder.floatingFee;
              break;

            case "transportation":
              option.selected = this.newOrder.addtransportation;
              break;

            case "standard arbitration":
            case "extended arbitration":
            case "premium arbitration":
              option.selected = this.newOrder.arbitrationOption === option.name;
              break;

            default:
              break;
          }  

          // Push the features into the features array
          features.push({
            selected: option.selected,
            orderFeatureId: option.orderFeatureId,
            price: option.price,
          });
        });

        // Set if the route we are going to be using. Edit or create
        const editOrCreateRoute = this.existingOrder.hasExistingOrder ? "orders/editExistingOrder" : "orders/createNewOrder";

        await POST(editOrCreateRoute, {
            vehicleListingId: this.vehicleListingId,
            marketplaceListingId: this.marketplaceListingId,
            sellerPersonId: this.existingOrder.sellerPersonId,
            buyerStoreEmployeeId: selectedBuyer.buyerStoreEmployeeId,
            price: this.highestBid,
            storeId: selectedBuyer.storeId,
            sellFee: this.sellerFee,
            features: features,
        });
    },

    async getOrderFeatures(){

    // NewCreateOrderDTO: {
    //  vehicleListingId: number,
    //  sellerPersonId: number,
    //  buyerStoreEmployeeId: number,
    //  price: number,
    //  checkout?: CheckoutDTO,
    //  storeId: number,
    //  sellFee?: number,
    //  features?: OrderOrderFeatureDTO[],
    // }

      // Get the order
      const orderQuery = await GET(`orders/getOrGenerateOrderByVehicleListingId/${this.vehicleListingId}`);
      

      // Set the response from the server to a global variable
      this.existingOrder = orderQuery.data;

      // Set the ancillaryOptions and Remove the holdingLots
      this.ancillaryOptions = this.existingOrder.features.filter((option)=>{
        return option.orderFeatureId !== 7
      });

      // Parse the values
      for(let i = 0; i < this.ancillaryOptions.length; i++) {

          // Set the transpo to true or false
          if(this.ancillaryOptions[i].orderFeatureId === 2) {
            this.newOrder.addtransportation = this.ancillaryOptions[i].selected;
          }

          // Set the photos to true or false
          if(this.ancillaryOptions[i].orderFeatureId === 3) {
            this.newOrder.addPhotos = this.ancillaryOptions[i].selected;
          }

          // Set the floatingFee to true or false
          if(this.ancillaryOptions[i].orderFeatureId === 8) {
            this.newOrder.floatingFee = this.ancillaryOptions[i].selected;
          }

          // Parse the prices
          this.ancillaryOptions[i].price = parseFloat(this.ancillaryOptions[i].price);
      }

      // Set the highestBid
      this.highestBid = parseFloat(this.existingOrder.price);

      // Set the sellFee
      this.sellerFee = parseFloat(this.existingOrder.sellFee);
    },

  async organizeBuyers() {

    // This POST response will be type:storeEmployeeReturn[] [{buyerStoreEmployeeId: number, firstName: string, lastName: string, storeId: number, storeName: string}]
    await POST('company/getAllStoreEmployees', {role: "buyer"}).then((response) => {

      response.data.forEach((buyer) => {
                let tempBuyerObj = {
                  ...buyer,
                  get selector() {
                    return (
                      this.firstName +
                      " " +
                      this.lastName +
                      " - " +
                      this.storeName
                    );
                  },
                };
                this.buyersArray.push(tempBuyerObj);
      });
    });

    return;
    },
  },
  computed: {
    filteredBuyersArray() {
      return this.buyersArray.filter((option) => {
        return (
          option.selector
            .toString()
            .toLowerCase()
            .indexOf(this.highestBidder) >= 0
        );
      });
    },
  },
}
</script>

<style scoped lang="postcss">
.addPhotosButtonDiv {
  display: flex;
  padding-bottom: 5px;
}
.spaceLeft {
  margin-left: 0.25rem;
}
.spaceTop {
  margin-top: 0.25rem;
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}
.titleContainer {
  display: flex;
  justify-content: center;
}
.userContainer {
  padding: 0 2.5rem 0.5rem;
}

.field.has-addons {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
}

.referralOptions:hover {
  background: #c4c4c4;
}
.referralOptions {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
}

.carmigo-modal {
  width: 35rem;
  margin: auto !important;
}
.modal-card-body {
  max-height: 60vh;
}

@media screen and (max-width: 768px) {
  .carmigo-modal {
    width: 85vw !important;
  }
}
</style>
