import { GET } from '@/api';
import { 
    APIConfig,
} from '@/types';
import { 
    applyAPIConfigOnSuccess, 
    applyAPIConfigOnError,
} from '@/utils';

export async function getOrderByVehicleListingId(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/vehicles/order/${vehicleListingId}`, undefined, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}