import { openModal } from './buefyUtils';

import OpenArbitrationsClaimModal from '@/views/Arbitrations/OpenArbitrationsClaimModal.vue';
import { BModalConfig } from 'buefy/types/components';

export function openArbitrationClaimModal(vehicleListingId: number, config: Partial<BModalConfig>) {
    openModal({
        component: OpenArbitrationsClaimModal,
        props: {
            vehicleListingId,
        },
        ...config,
    });
}