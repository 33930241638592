<template>
    <TheBuyItNowModal 
        v-bind="{
            ...$props,
            loadingSubmit
        }"
        @selectedStore="selectedStoreId = $event"
        @submit="submitBuyItNowMarketplace"
        @close="$emit('close')"
    />
</template>

<script>
import { defineComponent, ref } from 'vue';
import { PUT } from '../api';
import { BuyItNowModalProps } from '../types';
import { isStoreCannotBidError, openErrorDialog, openToast, showStoreCannotBidErrorDialog, isMarketplaceOfferError } from '../utils';
import store from '@/vuex';

import TheBuyItNowModal from './TheBuyItNowModal.vue';
export default defineComponent({
    name: 'TheMarketplaceBuyItNowModal',
    props: {
        ...BuyItNowModalProps,
        marketplaceListingId: {
            type: Number,
            required: true,
        }
    },
    components: {
        TheBuyItNowModal,
    },
    setup(props, context) {
        const selectedStoreId = ref(undefined);
        const loadingSubmit = ref(false);

        async function submitBuyItNowMarketplace() {
            loadingSubmit.value = true;
            await PUT(`/marketplace/marketplaceListingBuyItNow`, {
                marketplaceListingId: props.marketplaceListingId,
                storeId: selectedStoreId.value
            }).then(() => {
                context.emit('close');
                openToast('is-success', 'Purchase successful!');
            }).catch((error) => {
                const isStoreCannotBid = isStoreCannotBidError(error);

                if (isStoreCannotBid) {
                    showStoreCannotBidErrorDialog(error);
                    return;
                }
                if (isMarketplaceOfferError(error)) {
                    openErrorDialog({
                        title: 'Error',
                        message: error.response?.data?.message,
                        error,
                    });
                    return;
                }

                openErrorDialog({
                    title: 'Purchase failed',
                    message: `We encountered an error while purchasing marketplace listing ${props.marketplaceListingId} via Buy It Now for person ${store.state.user.profile.id}`,
                    error,
                });
            }).finally(() => {
                loadingSubmit.value = false;
            });
        }

        return {
            selectedStoreId,
            loadingSubmit,
            submitBuyItNowMarketplace,
        }
    }
});
</script>
