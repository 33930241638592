
import { defineComponent, onMounted } from 'vue';
import { playAudioCue } from '../utils';

import TheOverdriveProgressBar from './TheOverdriveProgressBar.vue';

export default defineComponent({
    name: 'TheApproachingOverdriveProgressBar',
    props: {
        currentBidCount: {
            type: Number,
            default: 0,
        },
        currentGoalBidCount: {
            type: Number,
            required: true,
        },
        currentDiscount: {
            type: Number,
            required: false,
        },
        upcomingDiscount: {
            type: Number,
            required: false,
        },
        upcomingLevel: {
            type: Number,
            required: false,
        },
        playAudioCue: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TheOverdriveProgressBar,
    },
    setup(props) {
        onMounted(() => {
            if (props.playAudioCue) {
                playAudioCue('overdrive-approaching', { volume: 1 });
            }
        });
    }
});
