<template>
    <div class="animated-loading flex-row justify-center">
        <p class="loading-attachments-text italic text-right">{{ text }}</p>
        <p class="loading-ellipses"></p>
    </div>
</template>

<script>
export default {
    name: "AnimatedLoadingText",
    props: ["text"],
}
</script>

<style scoped lang="postcss">

.animated-loading {
    color: #52aa57;
    padding-top: .5em;
}
.loading-ellipses {
    width: 1em;
    &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4,end) 1500ms infinite;
        animation: ellipsis steps(4,end) 1500ms infinite;
        content: "\2026";
        width: 0px;
    }
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}
</style>