import { getCurrentInstance } from 'vue';

export function useStore() {
  const vm = getCurrentInstance();

  if (!vm) {
    throw new Error('You must use this function within the "setup()" method, or insert the store as first argument.');
  }

  return vm.proxy.$store;
}
