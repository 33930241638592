<template>
    <div>
        <div class="bullet-item flex-row"
            v-for="item, index in listItems" 
            :key="index"
        >
            <b-icon icon="circle-small" size="mdi-24px"/>
            <p>{{ item }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "BulletedList",
    props: [
        "listItems"
    ],
    mounted() {
    }
}
</script>

<style scoped lang='postcss'>
.bullet-item {
    margin: 2px 0;
    text-align: left;
}
.icon.mdi-24px {
    margin-right: .35em;
    margin-top: .25em;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}
</style>
