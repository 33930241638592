import { getAdditionalPricingDetailsByVehicleListingId, getCurrentNegotiationOffer, getNegotiationAutocompleteOptionsForSellerOrBuyerName, getNegotiationAutocompleteOptionsForStoreName, getNegotiations, PUT } from '@/api';
import { APIConfig, CurrentNegotiationDTO, FilterFieldOptionsSchema, HighestBidRefSnapshot, Negotiation, NegotiationTableDTO, NegotiationTableUserDTO } from '@/types';
import { negotiationgStatus } from '@/types/NegotiationStatus';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, calculateBuyerOfferPercentOfMmr, cancelPreviousRequest, convertNegotiationsSearchToModifierSchema, createVehiclePricingDetailsModal, dollarAmountToInt, isMultipleOfHundred, openAdminNegotiationModal, openErrorDialog, openModal, OpenModalConfig, openToast, toCurrency, updateUrlParams } from '@/utils';
import { capitalize } from 'lodash';
import { computed, ComputedRef, onMounted, reactive, Ref, ref, SetupContext, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useCancelToken, useFetch } from './fetch';
import { useUser, useUserRole } from './user';
import axios, { CancelTokenSource } from 'axios';
import Vue from 'vue';
import { NegotiationTypeEnum } from '@/enums';

import TheNegotiationModalAuction from '@/components/TheNegotiationModalAuction.vue';

export function useGetOfferDisplayValues(negotiatingUser: 'buyer' | 'seller', buyerOfferAmount: number, sellerOfferAmount: number) {
    const yourOffer = computed(() => {
        return negotiatingUser == 'buyer' ? buyerOfferAmount : sellerOfferAmount;
    });
    const theirOffer = computed(() => {
        return negotiatingUser == 'buyer' ? sellerOfferAmount : buyerOfferAmount;
    });
    const otherUser = computed(() => {
        return negotiatingUser == 'seller' ? 'buyer' : 'seller';
    });

    return {
        yourOffer,
        theirOffer,
        otherUser,
    }
}

export function useValidateCounterOffer(buyerOfferAmount: number, sellerOfferAmount: number, isMarketplace: boolean=false) {
    function offerTooLow(offer: number) {
        return offer <= buyerOfferAmount;
    }

    function offerTooHigh(offer: number) {
        if (!sellerOfferAmount) {
            return false;
        }
        return isMarketplace 
            ? offer > sellerOfferAmount 
            : offer >= sellerOfferAmount;
    }

    const invalidCounterOfferMessage = ref('');

    function validateCounterOffer(inputValueStr: string) {
        const inputValueInt = dollarAmountToInt(inputValueStr);
        if (!inputValueInt) {
            return true;
        }
        if (!isMultipleOfHundred(inputValueInt)) {
            invalidCounterOfferMessage.value = 'Please use multiples of $100';
            return false;
        }
        if (offerTooLow(inputValueInt)) {
            invalidCounterOfferMessage.value = `Offer should be greater than ${toCurrency(buyerOfferAmount)}`;
            return false;
        }
        if (offerTooHigh(inputValueInt)) {
            invalidCounterOfferMessage.value = `Offer should be less than ${toCurrency(sellerOfferAmount)}`;
            return false;
        }
        return true;
    }

    return {
        validateCounterOffer,
        invalidCounterOfferMessage,
    }
}

export function useFetchNegotiationHistory({ vehicleListingId, negotiationHistory }: { 
    vehicleListingId: number ,
    negotiationHistory?: Negotiation[],
}): { 
    negotiationHistory: Ref<Negotiation[] | undefined>, 
    loadingNegotiationHistory: Ref<boolean> 
} {
    if (negotiationHistory) {
        return {
            negotiationHistory: ref(negotiationHistory),
            loadingNegotiationHistory:  ref(false),
        }
    }
    const { data, loading } = useFetch<Negotiation[]>(`/vehicles/${vehicleListingId}/getNegotiationHistory`, {
        onError: (error) => {
            openErrorDialog({
                title: `Failed to fetch negotiation data`,
                message: `We encountered an error while getting negotiation data for vehicle listing ${vehicleListingId}`,
                error,
            });
        }
    });
    
    return {
        negotiationHistory: data,
        loadingNegotiationHistory: loading,
    }
}

export function useAuctionNegotiations({ vehicleListingId, negotiatingUser, buyerOfferAmount, sellerOfferAmount, buyerPersonId, sellerPersonId, context }: {
    vehicleListingId: number,
    negotiatingUser: 'buyer' | 'seller',
    buyerOfferAmount: number,
    sellerOfferAmount: number,
    buyerPersonId: number,
    sellerPersonId: number,
    context: SetupContext<('close' | 'submitted' | any)[]>
}) {
    const otherUser = negotiatingUser == 'buyer' ? 'seller' : 'buyer';
    const sendNotifications: Ref<boolean> = ref(false);

    const buyerOfferAmountUpdated: Ref<number> = ref(buyerOfferAmount);
    const sellerOfferAmountUpdated: Ref<number> = ref(sellerOfferAmount);

    const negotiatingUserOfferAmount: ComputedRef<number> = computed(() => {
        switch (negotiatingUser) {
            case 'buyer':
                return buyerOfferAmountUpdated.value;
            case 'seller':
                return sellerOfferAmountUpdated.value;
        }
    });

    const otherUserOfferAmount: ComputedRef<number> = computed(() => {
        switch(negotiatingUser) {
            case 'seller':
                return buyerOfferAmountUpdated.value;
            case 'buyer':
                return sellerOfferAmountUpdated.value;
        }
    });

    function updateOfferAmount(amount: number, updateUser?: 'buyer' | 'seller') {
        switch (updateUser ?? negotiatingUser) {
            case 'buyer':
                buyerOfferAmountUpdated.value = amount;
                break;
            case 'seller':
                sellerOfferAmountUpdated.value = amount;
                break;
        }
    }

    const loadingSubmitCounterOffer = ref(false);
    async function submitCounterOffer(amount: number) {
        loadingSubmitCounterOffer.value = true;
        updateOfferAmount(amount);
        return await submitNegotiation({ 
            toastMessage: 'Counter offer sent!',  
            negotiationStatus: 'negotiating',
        });
    }

    const loadingAcceptOffer = ref(false);
    async function acceptNegotiationOffer() {
        loadingAcceptOffer.value = true;
        const buyerOffer = buyerOfferAmountUpdated.value;
        updateOfferAmount(sellerOfferAmountUpdated.value, 'buyer');
        updateOfferAmount(buyerOffer, 'seller');

        buyerOfferAmountUpdated
        submitNegotiation({
            toastMessage: 'Accepted negotiation offer!',
            negotiationStatus: 'accepted',
        });
    }

    const loadingRejectOffer = ref(false);
    async function rejectNegotiationOffer() {
        loadingRejectOffer.value = true;
        submitNegotiation({
            toastMessage: `Your final offer of ${toCurrency(negotiatingUserOfferAmount.value)} has been sent to the ${otherUser}.`,
            negotiationStatus: 'rejected',
        });
    }

    async function submitNegotiation({ toastMessage, negotiationStatus }: {
        toastMessage: string,
        negotiationStatus: negotiationgStatus,
    }) {
        await PUT(`/vehicles/negotiating/${vehicleListingId}`, {
            buyerId: buyerPersonId.toString(),
            sellerId: sellerPersonId.toString(),
            highestBuyerOffer: buyerOfferAmountUpdated.value,
            lowestSellerOffer: sellerOfferAmountUpdated.value,
            onBuyer: negotiatingUser == 'buyer' ? false : true, // flip current onBuyer value
            status: negotiationStatus,
            disableNotifications: !sendNotifications.value,
        }).then(res => {
            openToast('is-success', toastMessage);
            toggleLoading(false);
            context.emit('submitted', {
                buyerOfferAmount: buyerOfferAmountUpdated.value,
                sellerOfferAmount: sellerOfferAmountUpdated.value,
            });
            context.emit('close');
        }).catch(error => {
            toggleLoading(false);
            openErrorDialog({
                title: 'Could not complete negotiation',
                message: `We encountered an error while updating negotiations for vehicle ${vehicleListingId}. 
                    Update attempted: ${capitalize(negotiationStatus)} 
                    with buyer offer of ${toCurrency(buyerOfferAmountUpdated.value)}
                    and seller offer of ${toCurrency(sellerOfferAmountUpdated.value)}`,
                error,
            });
        });
    }

    function toggleLoading(isLoading: boolean) {
        loadingSubmitCounterOffer.value = isLoading;
        loadingAcceptOffer.value = isLoading;
        loadingRejectOffer.value = isLoading;
    }

    return {
        sendNotifications,
        negotiatingUserOfferAmount,
        otherUser,
        otherUserOfferAmount,
        updateOfferAmount,
        submitCounterOffer,
        acceptNegotiationOffer,
        rejectNegotiationOffer,
        submitNegotiation,
        toggleLoading,
        loadingSubmitCounterOffer,
        loadingAcceptOffer,
        loadingRejectOffer,
    }
}

export function useSingleNegotiationDashboardListing(vehicleListingId: number) {
    const { tableData, loadingTableData:loadingNegotiatingListing, getTableData } = useNegotiationDashboardTable();
    
    getTableData(convertNegotiationsSearchToModifierSchema('vehicleListingId', vehicleListingId));
    const negotiatingListing = computed(() => tableData.value?.[0] ?? {});

    return {
        negotiatingListing,
        tableData,
        loadingNegotiatingListing,
    }
}

export function useNegotiationDashboardTable() {
    // get table data 
    const rdbUpdateKey: Ref<number> = ref(0);
    const tableData: Ref<NegotiationTableDTO[]> = ref([]);
    const loadingTableData: Ref<boolean> = ref(false);
    const cancelToken: Ref<CancelTokenSource | undefined> = ref(undefined);

    function cancelPreviousRequest() {
        if (cancelToken.value) {
            cancelToken.value.cancel('Previous request to negotiations cancelled');
        }
        cancelToken.value = axios.CancelToken.source();
    }

    async function getTableData(modifiers: FilterFieldOptionsSchema={}): Promise<void> {
        cancelPreviousRequest();
        loadingTableData.value = true;
        tableData.value = await getNegotiations(modifiers, {
            onSuccess: (res) => loadingTableData.value = false,
            onError: () => loadingTableData.value = false,
        }, {
            cancelToken: cancelToken.value?.token,
        });
    }

    async function updateTableRow(vehicleListingId: number) {
        let rowIdx = tableData.value.findIndex(row => row.vehicleListingId == vehicleListingId);
        if (rowIdx >= 0) {
            cancelPreviousRequest();
            const modifier = convertNegotiationsSearchToModifierSchema('vehicleListingId', vehicleListingId);
            await getNegotiations(modifier!, {
                onSuccess: (res) => {
                    if (res?.length) {
                        Vue.set(tableData.value, rowIdx, res[0]);
                        rdbUpdateKey.value++;
                    }
                },
            });
        }
    }

    function updateTableRowOnRealtimeUpdate(vehicleListingId: number, { isInitialSnapshot, oldSnapshotValue, newSnapshotValue }: HighestBidRefSnapshot) {
        if (isInitialSnapshot || !newSnapshotValue) {
            return;
        }

        if (oldSnapshotValue?.note !== newSnapshotValue.note) {
            updateTableRow(vehicleListingId);
        }
    }

    return {
        tableData,
        getTableData,
        loadingTableData,
        updateTableRowOnRealtimeUpdate,
        rdbUpdateKey,
        cancelToken,
    }
}

export function useAdminNegotiationTableSearchAndFilters({ tableData, loadingTableData, getTableData, selectedRows, cancelToken }: {
    tableData: Ref<NegotiationTableDTO[]>,
    loadingTableData?: Ref<boolean>,
    getTableData: (modifiers?: FilterFieldOptionsSchema) => Promise<void>,
    selectedRows?: Ref<NegotiationTableDTO[]>,
    cancelToken?: Ref<CancelTokenSource | undefined>,
}) {
    const userKey = ref(0);
    const searchKey = ref(0);
    
    // select user's negotiations 
    const route = useRoute();
    const selectedNegotiatingEmployees: Ref<('View All' | NegotiationTableUserDTO)[]> = ref([]); 
    watch(() => selectedNegotiatingEmployees.value, async (newValue) => {
        if (selectedRows) {
            selectedRows.value =  [];
        }

        if (newValue.includes('View All')) {
            return await getTableData().then(res => userKey.value++);
        }

        if (!selectedNegotiatingEmployees.value.length) {
            if (cancelToken?.value) {
                cancelToken.value = cancelPreviousRequest(cancelToken.value, 'Previous request to negotiations cancelled');
            }
            if (loadingTableData) {
                loadingTableData.value = false;
            }
            tableData.value = [];
            return;
        }

        // update query params
        let updatedQueryParams = { negotiations: (selectedNegotiatingEmployees.value as NegotiationTableUserDTO[]).map(value => value?.personId.toString()) } ;
        updateUrlParams({
            route,
            newQueryParams: updatedQueryParams,
            maintainAllParams: true,
        });

        // clear search 
        if (searchBy.value) {
            searchBy.value = undefined;
            searchKey.value++;
        }

        // get updated table data
        let modifiers = undefined;
        if (selectedNegotiatingEmployees.value.length) {
            modifiers = convertNegotiationsSearchToModifierSchema('negotiatingEmployeePersonIds', (selectedNegotiatingEmployees.value as NegotiationTableUserDTO[]).map(employee => employee.personId));
        }
        await getTableData(modifiers).then(res => userKey.value++);
    }, { deep: true });

    function removeSelectedNegotiatingEmployee(personId: number) {
        const idx = selectedNegotiatingEmployees.value.findIndex(value => value !== 'View All' && value.personId == personId);
        if (idx >= 0) {
            selectedNegotiatingEmployees.value.splice(idx, 1);
        }
    }

    // search
    const isDisplayingSearchResults: Ref<boolean> = ref(false);
    const searchBy: { field: 'vin' | 'vehicleListingId' | 'personName' | 'storeName', value: string | undefined } = reactive({
        field: 'vin',
        value: undefined,
    });
    watch(() => searchBy.field, () => {
        searchBy.value = undefined;
    });

    async function getTableDataFromSearch(searchValue?: { field: string, value: string }) {
        selectedNegotiatingEmployees.value = [];
        searchBy.value = searchValue?.value ? searchValue.value.trim() : undefined;
        let modifiers = convertNegotiationsSearchToModifierSchema(searchBy.field, searchBy.value);
        await getTableData(modifiers).then(() => isDisplayingSearchResults.value = true);
    }

    // search autocomplete/typeaheads
    const autocompleteValue: Ref<{ name: string | undefined }> = ref({ name: undefined });
    const searchAutocompleteOptions: Ref<{ result: string, count: number }[]> = ref([]);
    const loadingSearchAutocompleteOptions: Ref<boolean> = ref(false);
    async function getSearchAutocompleteOptions(value: string) {
        if (!value) {
            return;
        }
        autocompleteValue.value.name = value;
        loadingSearchAutocompleteOptions.value = true;
        switch(searchBy.field) {
            case 'personName':
                searchAutocompleteOptions.value = await getNegotiationAutocompleteOptionsForSellerOrBuyerName(value, {
                    onSuccess: () => loadingSearchAutocompleteOptions.value = false,
                    onError: () => loadingSearchAutocompleteOptions.value = false,
                });
                break;
            case 'storeName':
                searchAutocompleteOptions.value = await getNegotiationAutocompleteOptionsForStoreName(value, {
                    onSuccess: () => loadingSearchAutocompleteOptions.value = false,
                    onError: () => loadingSearchAutocompleteOptions.value = false,
                });
                break;
        }
    }

    return {
        selectedNegotiatingEmployees,
        removeSelectedNegotiatingEmployee,
        searchBy,
        getTableDataFromSearch,
        autocompleteValue,
        searchAutocompleteOptions,
        loadingSearchAutocompleteOptions,
        getSearchAutocompleteOptions,
        isDisplayingSearchResults,
        userKey,
        searchKey,
    }
}

export function useAdminNegotiationTableActions({ tableData }: {
    tableData: Ref<NegotiationTableDTO[]>
}) {
    const expandedRows: Ref<number[]> = ref([]); // vehicleListingIds

    // bulk options
    const selectedRows: Ref<NegotiationTableDTO[]> = ref([]);
    function updateTableData(updateType: 'isUpvoted' | 'markedNotSold' | 'reinspect' | 'orderCreated' | 'sentToSecondChance', vehicleListingId?: number) {
        if (vehicleListingId) { // single row
            updateTableRowByVehicleListingId(updateType, vehicleListingId);
        } else { // bulk update
            selectedRows.value.forEach(({ vehicleListingId } : NegotiationTableDTO) => updateTableRowByVehicleListingId(updateType, vehicleListingId));
        }
    }

    function updateTableRowByVehicleListingId(updateType: 'isUpvoted' | 'markedNotSold' | 'reinspect' | 'orderCreated' | 'sentToSecondChance', vehicleListingId: number) {
        switch(updateType) {
            case 'isUpvoted':
                let updateRow: NegotiationTableDTO | undefined = tableData.value.find((row: NegotiationTableDTO) => row.vehicleListingId == vehicleListingId);
                if (updateRow) {
                    (updateRow as NegotiationTableDTO).isUpvoted = true;
                    (updateRow as NegotiationTableDTO).numUpvotes++;
                }
                break;
            case 'markedNotSold':
            case 'reinspect':
            case 'orderCreated':
            case 'sentToSecondChance':
                let rowIdx = tableData.value.findIndex((row: NegotiationTableDTO) => row.vehicleListingId == vehicleListingId);
                if (rowIdx >= 0) {
                    tableData.value.splice(rowIdx, 1);
                }
                openToast('is-success', 'Listing removed from table due to updated status');
                break;
        }
    }

    // update MMR
    const loadingMmrVehicleListingId: Ref<number | undefined> = ref(undefined);
    async function openAdditionalPricingModal(vehicleListingId: number) {
        loadingMmrVehicleListingId.value = vehicleListingId;
        await getAdditionalPricingDetailsByVehicleListingId(vehicleListingId, {
            onSuccess: async (pricingDetails) => {
                loadingMmrVehicleListingId.value = undefined;
                let updatedPricing = await createVehiclePricingDetailsModal(vehicleListingId, { props: { pricingDetails }});
                if (updatedPricing?.mmr) {
                    let rowIdx = tableData.value.findIndex((row: NegotiationTableDTO) => row.vehicleListingId == vehicleListingId);
                    if (rowIdx >= 0) {
                        let row = tableData.value[rowIdx];
                        tableData.value[rowIdx].mmr = updatedPricing.mmr;
                        tableData.value[rowIdx].percentMmr = calculateBuyerOfferPercentOfMmr(updatedPricing.mmr, row.highestBuyerOfferAmount);
                    }
                }
            },
            onError: () => loadingMmrVehicleListingId.value = undefined,
        });
    }

    // open Negotiation modal
    function openNegotiationModal(negotiatingUser: 'buyer' | 'seller', { vehicleListingId, vin, highestBuyerOfferAmount, lowestSellerOfferAmount, seller, buyer }: NegotiationTableDTO) {
        openAdminNegotiationModal({
            vehicleListingId,
            buyerOfferAmount: highestBuyerOfferAmount,
            sellerOfferAmount: lowestSellerOfferAmount,
            buyerPersonId: buyer.personId,
            sellerPersonId: seller.personId,
            negotiatingUser,
            vin,
        }, {
            submitted: ({ buyerOfferAmount, sellerOfferAmount }) => {
                const rowIdx = tableData.value.findIndex(row => row.vehicleListingId == vehicleListingId);
                if (rowIdx >= 0) {
                    const row = tableData.value[rowIdx];
                    tableData.value[rowIdx].highestBuyerOfferAmount = buyerOfferAmount;
                    tableData.value[rowIdx].lowestSellerOfferAmount = sellerOfferAmount;
                    tableData.value[rowIdx].mostRecentNegotiationType = negotiatingUser;
                    tableData.value[rowIdx].percentMmr = calculateBuyerOfferPercentOfMmr(row.mmr, row.highestBuyerOfferAmount);
                }
            }
        });
    }

    return {
        expandedRows,
        selectedRows,
        openNegotiationModal,
        openAdditionalPricingModal,
        loadingMmrVehicleListingId,
        updateTableData,
    }
}

export function useNegotiationNeedsAttention({ tableData, selectedNegotiatingEmployees }: {
    tableData: Ref<NegotiationTableDTO[]>,
    selectedNegotiatingEmployees: Ref<('View All' | NegotiationTableUserDTO)[]>,
}) {
    const user = useUser();
    const loggedInUserPersonId: number | undefined = user.value?.profile?.id ? Number(user.value.profile.id) : undefined;

    const showNeedsAttentionOnly: Ref<boolean> = ref(false);
    const needsAttentionTableData = computed(() => {
        return tableData.value.filter(({ vehicleListingId }) => {
            return hasNoteVehicleListingIds.value.includes(vehicleListingId) || hasSellerOfferVehicleListingIds.value.includes(vehicleListingId) || hasBuyerOfferVehicleListingIds.value.includes(vehicleListingId);
        });
    });
    function openNeedsAttentionToast() {
        if (showNeedsAttentionOnly.value && needsAttentionTableData.value.length) {
            openToast('is-success', `Displaying ${needsAttentionTableData.value.length} cars that need attention`);
        } 
    }

    // if mostRecentNote type is 'admin' and the senderPersonId is not me
    const hasNoteVehicleListingIds: ComputedRef<number[]> = computed(() => {
        let vehicleListingIds: number[] = [];
        tableData.value.forEach(row => {
            if (row.mostRecentNote?.noteType == 'admin' && row.mostRecentNote?.senderPersonId !== loggedInUserPersonId) {
                vehicleListingIds.push(row.vehicleListingId);
            }
        });
        return vehicleListingIds;
    });

    // if selected user corresponding with this row has role 'am' and mostRecentNegotiationType is 'seller'
    // Used for the 'Seller At' icon
    const hasSellerOfferVehicleListingIds: ComputedRef<number[]> = computed(() => {
        let vehicleListingIds: number[] = [];

        if (selectedNegotiatingEmployees.value.includes('View All')) {
            return [];
        }
    
        tableData.value.forEach(row => {
            const isSelectedAccountManager = (selectedNegotiatingEmployees.value as NegotiationTableUserDTO[]).some(employee => employee.role.includes('am') && employee.personId == row.accountManager.personId);
            if (row.mostRecentNegotiationType == 'seller' && isSelectedAccountManager) {
                vehicleListingIds.push(row.vehicleListingId);
            }
        });
        return vehicleListingIds;
    });

    // if selected user corresponding with this row has role 'dsr' and mostRecentNegotiationType is 'buyer'    
    // Used for the 'Buyer At' icon
    const hasBuyerOfferVehicleListingIds: ComputedRef<number[]> = computed(() => {
        let vehicleListingIds: number[] = [];


        if (selectedNegotiatingEmployees.value.includes('View All')) {
            return [];
        }
    
        tableData.value.forEach(row => {
            const isSelectedDsr = (selectedNegotiatingEmployees.value  as NegotiationTableUserDTO[]).some(employee => employee.role.includes('dsr') && employee.personId == row.dealerSalesRepresentative.personId);
            if (row.mostRecentNegotiationType == 'buyer' && isSelectedDsr) {
                vehicleListingIds.push(row.vehicleListingId);
            }
        });
        return vehicleListingIds;
    });

    return {
        hasNoteVehicleListingIds,
        hasSellerOfferVehicleListingIds,
        hasBuyerOfferVehicleListingIds,
        showNeedsAttentionOnly,
        needsAttentionTableData,
        openNeedsAttentionToast,
    }
}

export function useCurrentNegotiationOffer({ currentNegotiation }: {
    currentNegotiation?: CurrentNegotiationDTO,
}={}) {
    const negotiationOffer: Ref<CurrentNegotiationDTO | undefined> = ref(currentNegotiation);
    const loadingNegotiationOffer: Ref<boolean> = ref(false);

    const { cancelToken, cancelPreviousRequest} = useCancelToken();
    async function getUpdatedNegotiationOffer(vehicleListingId: number, config: APIConfig={}) {
        cancelPreviousRequest();
        await getCurrentNegotiationOffer(vehicleListingId, {
            cancelToken: cancelToken.value?.token,
            skipErrorOnCancelRequest: true,
            ...config,
            onSuccess: (res) => {
                loadingNegotiationOffer.value = false;
                negotiationOffer.value = res;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: (error) => {
                loadingNegotiationOffer.value = false;
                applyAPIConfigOnError(error, config);
            },
        });
    }

    return {
        negotiationOffer,
        loadingNegotiationOffer,
        getUpdatedNegotiationOffer,
    }
}

export function useAuctionNegotiateButton({ negotiatingPersonId, negotiationOffer }: {
    negotiatingPersonId?: Ref<number | undefined>, // usually loggedInUser, can be left undefined if user is admin/dsr
    negotiationOffer: Ref<CurrentNegotiationDTO | undefined>,
}) {
    const { isUserAdmin, isUserDsr } = useUserRole();
    
    const offerSenderType: ComputedRef<'buyer' | 'seller' | undefined> = computed(() =>{
        if (negotiationOffer.value?.mostRecentNegotiationTypeId) {
            return NegotiationTypeEnum[negotiationOffer.value.mostRecentNegotiationTypeId] as 'buyer' | 'seller';
        }
        return undefined;
    });

    const offerRecipientType: ComputedRef<'buyer' | 'seller' | undefined> = computed(() => offerSenderType.value == 'seller' ? 'buyer' : 'seller');
    const personIds: ComputedRef<{ offerSender: number | undefined, offerRecipient: number | undefined } | undefined> = computed(() => {
        switch (offerSenderType.value) {
            case 'seller':
                return {
                    offerSender: negotiationOffer.value?.seller?.personId,
                    offerRecipient: negotiationOffer.value?.buyer?.personId,
                }
            case 'buyer':
                return {
                    offerSender: negotiationOffer.value?.buyer?.personId,
                    offerRecipient: negotiationOffer.value?.seller?.personId,
                }
            default:
                undefined;
        }
    }); 

    const negotiatingUser: ComputedRef<'buyer' | 'seller' | undefined> = computed(() => {
        if (!negotiationOffer.value) {
            return undefined;
        }
        if (isUserAdmin.value || isUserDsr.value || (negotiatingPersonId?.value && negotiatingPersonId?.value == personIds.value?.offerRecipient)) {
            return offerRecipientType.value;
        }
        return undefined;
    });

    // ADMIN BUTTON
    const adminButtonLabel = computed(() => {
        if (isUserAdmin.value || isUserDsr.value) {
            return `Negotiate for ${negotiatingUser.value}`;
        }
    });

    const isOfferPending = computed(() => {
        if (isUserAdmin.value || isUserDsr.value) {
            return false;
        }
        if (negotiationOffer.value && negotiatingPersonId?.value) {
            return personIds.value?.offerSender == negotiatingPersonId?.value;
        }
        return true;
    });

    function openNegotiationModal(listing: { vehicleListingId: number, vin?: string }, config: OpenModalConfig={}) {
        if (!negotiationOffer.value) {
            return;
        }
        openModal({
            ...config,
            component: TheNegotiationModalAuction,
            props: {
                ...listing,
                negotiatingUser: negotiatingUser.value,
                sellerOfferAmount: negotiationOffer.value.sellerOfferAmount,
                buyerOfferAmount: negotiationOffer.value.buyerOfferAmount,
                negotiationStatus: negotiationOffer.value.negotiationStatus == 'rejected' ? 'rejected' : 'in progress',
                displayRejectedMessage: true,
                sellerPersonId: negotiationOffer.value.seller.personId,
                buyerPersonId: negotiationOffer.value.buyer.personId,
                ...config.props,
            },
        });
    }

    return {
        openNegotiationModal,
        isOfferPending,
        adminButtonLabel,
        negotiatingUser,
    }
}