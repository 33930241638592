var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheNegotiateButton',_vm._b({on:{"click":function($event){return _vm.$emit('click')}}},'TheNegotiateButton',{
        buttonLabel: _vm.getButtonLabel,
        isPending: _vm.isOfferPending,
        type: _vm.getButtonType,
        noBorder: _vm.noBorder,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }