var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheNegotiateButton',_vm._b({nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openNegotiationModal({
        vehicleListingId: _vm.$props.vehicleListingId,
        vin: _vm.$props.vin,
    })}}},'TheNegotiateButton',{
        isPending: _vm.isOfferPending,
        buttonLabel: _vm.adminButtonLabel, // otherwise has default button label
        disabled: _vm.$attrs.loading || _vm.isOfferPending || !_vm.negotiatingUser,
        type: _vm.isOfferPending ? 'is-light' :'is-primary',
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }