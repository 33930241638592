
import { defineComponent, PropType, Ref, ref } from 'vue';
import { updateVehicleLocation } from '../api';
import { openToast } from '../utils';

import Modal from './Modal.vue';
import TheEditLocationInputs from './TheEditLocationInputs.vue';

export default defineComponent({
    name: 'TheEditListingLocationModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        zipCode: {
            type: String,
            required: false,
        },
        city: {
            type: String,
            required: false,
        },
        state: {
            type: String,
            required: false,
        }
    },
    components: {
        Modal,
        TheEditLocationInputs,
    },
    setup(props, { emit, attrs }) {
        const zipCodeUpdated = ref(props.zipCode);
        const cityUpdated = ref(props.city);
        const stateUpdated = ref(props.state);

        const loadingSaveLocation: Ref<boolean> = ref(false);
        async function saveListingLocation() {
            loadingSaveLocation.value = true;
            await updateVehicleLocation(props.vehicleListingId, { 
                zip: zipCodeUpdated.value,
                city: cityUpdated.value,
                state: stateUpdated.value,
            }, {
                onSuccess: () => {
                    loadingSaveLocation.value = false;
                    openToast('is-success', 'Location updated!');
                    emit('close');
                },
                onError: () => loadingSaveLocation.value = false,
            });
        }
        return {
            saveListingLocation,
            loadingSaveLocation,
            zipCodeUpdated,
            cityUpdated,
            stateUpdated,
        }
    },
});
