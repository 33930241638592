<template>
    <b-field :label="!isButton ? label : ''" :class="dropdownClass">
        <b-dropdown 
            v-model="selectedOption"
            v-bind="{
                position: 'is-bottom-left',
                disabled,
                ...$attrs,
            }"
            :key="updateKey"
            @active-change="$emit('activeChange', $event)"
            @click.native.stop="toggleOnTouchDevice"
            ref="dropdown"
        >
            <template #trigger="{ active }">
                <slot name="trigger" v-bind:active="active">
                    <b-button
                        v-bind="{
                            iconRight: active ? 'menu-up' : 'menu-down',
                            type: isButton ? getButtonType : '',
                            disabled,
                            outlined,
                            ...$attrs.buttonAttrs,
                        }"
                        class="dropdown-button carmigo-button"
                        :class="outlined ? 'bold justify-center' : ''"
                        @blur="handleclick(isDropDownOpened)"
                    >
                        <slot name="buttonLabel">
                            {{ getButtonLabel }}
                        </slot>
                    </b-button>
                </slot>
            </template>

            <div v-if="!options.length" class="ml-2">
                <slot name="empty" />
            </div>

            <b-dropdown-item aria-role="listitem"
                v-for="option, idx in options"
                :key="`dropdown-item-${idx}`"
                :value="option.name ?? option" 
            >
                <slot 
                    name="dropdownItem" 
                    v-bind:option="option"
                >
                    {{ option }}
                </slot>
            </b-dropdown-item>

        </b-dropdown>
    </b-field>
</template>

<script>
import breakPointMixin from '@/mixins/breakpoint';
import { makeCamelCase } from "../utils";

export default {
    inheritAttrs: false,
    name: 'BuefyDropdown',
    mixins: [breakPointMixin],
    props: {
        options: Array,             // required
        isButton: Boolean,          // optional
        label: String,              // optional
        buttonType: String,         // optional; default is-primary
        initialValue: [String, Array],       // optional; array for 'multiple' select
        name: [String, Number],      // optional
        vehicleListingId: Number,   // optional
        disabled: Boolean,          // optional
        outlined: Boolean,
        disableDoNotGo:{
            type:Function,
            required:false,
            default: () => {}
        },
        dropdownClass: {
            type: String,
            default: 'primary-border',
        }
    },
    data() {
        return {
            selectedOption: this.initialValue,
            updateKey: 0,
            isDropDownOpened:false
        }
    },
    computed: {
        getButtonType() {
            return this.buttonType ? this.buttonType : 'is-primary';
        },
        getButtonLabel() {
            if (this.isButton) return this.label;
            return this.selectedOption ?? this.$attrs.placeholder;
        }
    },
    methods:{

        handleclick(active) {
            if(!active) {
                this.disableDoNotGo(active);
            }
        },
        toggleOnTouchDevice() {
            if (this.bulmaBreakpoint.mobile || this.bulmaBreakpoint.tablet) {
                // Prevents bubbling up
                this.$refs.dropdown.toggle();
            }
        },
    },
    watch: {
        selectedOption: function() {
            this.$emit('update:initialValue', this.selectedOption);
            this.$emit('select', this.selectedOption, this.name);
            if (this.selectedOption && typeof this.selectedOption == 'string') this.$emit(makeCamelCase(this.selectedOption), this.name);
            
            // for button dropdowns, have to clear active option otherwise it stays selected after the dropdown closes
            if (this.isButton && !this.initialValue) {
                this.selectedOption = null;
                this.updateKey++;
            };
        },
        initialValue: function() {
            this.selectedOption = this.initialValue;
        }
    },
}
</script>

<style scoped lang='postcss'>
:deep(.dropdown-menu) {
    & .dropdown-item {
        text-align: left;
    }
}
</style>