
import Vue from 'vue';

export default Vue.extend({
  props: ['loaderWidth', 'loaderHeight', 'loaderBorderRadius'],
  data() {
    return {
      pulseLoader: null as null | string,
    };
  },
  mounted() {
    if (this.loaderWidth && this.loaderHeight && this.loaderBorderRadius) {
      this.pulseLoader = `width: ${this.loaderWidth}; height: ${this.loaderHeight}; border-radius: ${this.loaderBorderRadius}`;
    } else {
      this.pulseLoader = 'width: 18em; height: 18em; border-radius: 18em';
    }
  },
  methods: {
    getImgSrc() {
      if (this.$store?.state.isCarmigoDirect) {
        const branding = this.$store.state.branding;
        if (branding && branding.logoUrlSquare) {
          return branding.logoUrlSquare;
        }
      } else {
        var img = require.context('../assets/', false, /\.png$/);
        return img('./icon.png');
      }
    }
  }
})
