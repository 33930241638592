import { TabButton } from "@/components/AppPageControlPanel.vue";
import { ListingRowTableType, ModifierOptions, ModifierOptionsFilterObject, ModifierOptionsOrderBy, PaginationDTO, VehicleListingAnnouncementCategorizedDTO, VehicleStatus } from "@/types";
import { startCase } from "lodash";

export function getActiveListingsModifiersByTableType({ tableType, personId, filters=[], orderBy=[], pagination}: {
    tableType: ListingRowTableType,
    personId: number,
    filters?: ModifierOptionsFilterObject[],
    orderBy?: ModifierOptionsOrderBy[],
    pagination?: PaginationDTO,
}): ModifierOptions {
    switch(tableType) {
        default:
            return {};
        case 'auction':
            return {
                filters: [
                    {
                        property: 'vehicleStatus.value',
                        comparator: '=',
                        values: 'Auctioning',
                    },
                    {
                        property: 'vehicleListing.sellerId',
                        comparator: '!=',
                        values: personId,
                    },
                    ...filters,
                ],
                orderBy,
                pagination,
            };
        case 'marketplace':
            return {
                filters: [
                    {
                        property: 'vehicleStatus.value',
                        comparator: '=',
                        values: 'InMarketplace',
                    },
                    {
                        property: 'vehicleListing.sellerId',
                        comparator: '!=',
                        values: personId,
                    },
                    ...filters,
                ],
                orderBy,
                pagination,
            }
        case 'secondChance':
            return {
                filters: [
                    {
                        property: 'vehicleStatus.value',
                        comparator: '=',
                        values: 'SecondChance',
                    },
                    {
                        property: 'vehicleListing.sellerId',
                        comparator: '!=',
                        values: personId,
                    },
                    ...filters,
                ],
                orderBy,
                pagination,
            }
    }
}

export function getListingTypeLabel(listingType: ListingRowTableType, { useFirstLetter }: {
    useFirstLetter?: boolean,
}={}): string {
    if (useFirstLetter) {
        return listingType.charAt(0);
    }

    return startCase(listingType);
}

export function getListingTypeColor(listingType: ListingRowTableType): string {
    switch(listingType) {
        case 'auction':
            return 'has-text-primary';
        case 'marketplace':
            return 'has-text-dark-blue';
        case 'secondChance':
            return 'has-text-primary-dark';
        case 'negotiating':
            return 'has-text-grey';
        default:
            return 'has-text-dark';
    }
}

export function getVehicleStatusesForListingType(listingType: ListingRowTableType): VehicleStatus[] | undefined {
    switch(listingType) {
        case 'auction':
            return ['Auctioning'];
        case 'marketplace':
            return ['InMarketplace'];
        case 'secondChance':
            return ['SecondChance'];
        case 'negotiating':
            return ['Negotiating'];
        case 'pending':
            return [
                'InspectionScheduled',
                'Inspected',
                'AuctionScheduled',
                'MarketplacePending',
            ];
        default: 
            return undefined;
    }
}

export function getNumAnnouncements(announcements?: VehicleListingAnnouncementCategorizedDTO): number | undefined {
    if (!announcements) {
        return undefined;
    }
    return Object.values(announcements).reduce((acc, curr) => acc + curr.length, 0);
}