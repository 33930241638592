var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheListingDetailsBookoutOptions',_vm._b({},'TheListingDetailsBookoutOptions',{ bookoutOptions: _vm.bookoutOptions },false),[_vm._t("default",function(){return [(_vm.isEditingMode)?_c('div',{key:`bookout-options-${_vm.bookoutOptionsKey}`},[_vm._l((_vm.updatedBookoutOptions),function(bookoutOption,idx){return _c('div',{key:`bookout-option-${idx}`,staticClass:"flex-row align-center"},[(bookoutOption.selected)?_c('EditableField',_vm._b({on:{"typing":function($event){return _vm.debouncedGetAutocompleteOptions($event, idx)},"input":function($event){return _vm.updateBookoutOptionsAndEmit('name', $event, idx)},"delete":function($event){_vm.deleteBookoutOption(idx); _vm.emitBookoutOptions();}}},'EditableField',{
                        value: bookoutOption.name,
                        customInputHandler: true,
                        canDelete: true,
                        inputType: 'autocomplete',
                        data: _vm.autocompleteOptions,
                        loading: _vm.loadingAutocomplete === idx,
                        appendToBody: true,
                        expanded: true,
                    },false)):_vm._e()],1)}),_c('div',{staticClass:"flex-row justify-center"},[_c('AppButton',_vm._b({staticClass:"no-border is-size-7 text-thin underline",on:{"click":_vm.addBookoutOption}},'AppButton',{
                        iconLeft: 'plus',
                        label: 'add a feature',
                        type: 'is-primary',
                        disabled: !_vm.canAddBookoutOption,
                        outlined: true,
                    },false))],1)],2):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }