
import { defineComponent, onMounted, PropType } from 'vue';
import { playAudioCue, rainConfettiFromElementLeftSide, rainConfettiFromElementRightSide, toCurrency } from '../utils';

import TheOverdriveProgressBar from './TheOverdriveProgressBar.vue';

export default defineComponent({
    name: 'TheCompleteOverdriveProgressBar',
    props: {
        currentGoalBidCount: {
            type: Number,
            required: false,
        },
        currentDiscount: {
            type: Number,
            required: false,
        },
        upcomingLevel: {
            type: Number,
            required: false,
        },
        throwConfetti: {
            type: Boolean,
            default: false,
        },
        confettiDirection: {
            type: String as PropType<'in' | 'out'>,
            default: 'out',
        },
    },
    components: {
        TheOverdriveProgressBar,
    },
    setup(props, { emit, attrs }) {
        onMounted(() => {
            if (props.throwConfetti) {
                window.setTimeout(rainOverdriveConfetti, 1000);
            }
        });

        function rainOverdriveConfetti() {
            const element = document.querySelector(`#complete-overdrive`);
            if (!element) {
                return;
            }
            
            switch(props.confettiDirection) {
                case 'out':
                default:
                    rainConfettiFromElementLeftSide(element, { offsetPixelsX: 10 });
                    rainConfettiFromElementRightSide(element, { offsetPixelsX: -10 });
                    break;
                case 'in':
                    rainConfettiFromElementLeftSide(element, { 
                        offsetPixelsX: -20,
                        confettiConfig: {
                            angle: 60, 
                        }
                    });
                    rainConfettiFromElementRightSide(element, { 
                        offsetPixelsX: 20,
                        confettiConfig: {
                            angle: 120,
                        }
                    });
                    break;
            }
            playAudioCue('overdrive-completed', { volume: 0.20 });
        }

        return {
            toCurrency,
        }
    },
});
