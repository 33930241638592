var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"flex-column",attrs:{"id":"drag-and-drop-container"}},[_c('b-field',_vm._b({staticClass:"flex-column",class:{ 'has-attachments': _vm.returnedAttachments.length }},'b-field',{
      label: 'Attachments', 
      ..._vm.$attrs.fieldAttrs
    },false),[_vm._t("instructions"),_c('b-upload',_vm._b({staticClass:"justify-center",on:{"input":_vm.attach},model:{value:(_vm.newAttachments),callback:function ($$v) {_vm.newAttachments=$$v},expression:"newAttachments"}},'b-upload',{
        multiple: true,
        dragDrop: true,
        ..._vm.$attrs,
      },false),[_vm._t("uploadInput",function(){return [_c('div',{staticClass:"content has-text-centered"},[_c('b-icon',{attrs:{"icon":"upload","size":_vm.$attrs.size ?? 'is-large'}}),_c('p',{class:_vm.$attrs.size},[_vm._v("Drop your files here or click to upload")])],1)]})],2)],2),(_vm.returnedAttachments.length)?_c('div',{staticClass:"added-attachments",class:'flex-column'},[_c('p',{staticClass:"num-attachments margin-1-em"},[_vm._v(_vm._s(_vm.returnedAttachments.length)+" attachments")]),_c('div',{staticClass:"attachments",class:'flex-' + _vm.getAlignment},_vm._l((_vm.returnedAttachments),function(file,index){return _c('AddedAttachment',{key:index,attrs:{"imageUrl":file.url,"fileName":file.filename,"fileType":file.fileType,"canDelete":true,"alignment":_vm.alignment,"imgSize":_vm.getImgSize,"deleteAttachment":_vm.deleteAttachment}})}),1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }