
import { removeIsClippedTouch } from '@/utils';
import ButtonIcon from './ButtonIcon.vue';
import Loading from './Loading.vue';

export default {
    inheritAttrs: false,
    name: 'Modal',
    props: {
        isLoading: Boolean,         // optional
        customButtons: Boolean,     // optional
        disableConfirm: Boolean,    // optional
        customLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        jumpToList: Array,
        buttonsMiddle: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
        useJumpToIcon: {
            type: Boolean,
            default: true,
        },
        showJumpToOnScrollUp: {
            type: Boolean,
            default: true,
        },
        showButtons: {
            type: Array,
            default: () => ['cancel', 'confirm']
        },
    },
    components: {
        ButtonIcon,
        Loading,
    },
    emits: ['confirm', 'close', 'share', 'expand'],
    data() {
        return {
            makeNotSticky: true,
        }
    },
    unmounted() {
        removeIsClippedTouch();
    },
    methods: {
        updateMakeNotSticky(value) {
            if (!this.showJumpToOnScrollUp) {
                return;
            }
            this.makeNotSticky = value;
        }
    }
}
