
import { defineComponent } from 'vue';
import { useAuctionNegotiations } from '../composables';
import { negotiationModalProps } from '../types';
import { capitalize, toCurrency } from '../utils';

import AppCheckboxInput from './AppCheckboxInput.vue';
import TheNegotiationModal from './TheNegotiationModal.vue';

export default defineComponent({
    name: 'TheNegotiationModalAuction',
    props: {
        ...negotiationModalProps,
        buyerPersonId: {
            type: Number,
            required: true,
        },
        sellerPersonId: {
            type: Number,
            required: true,
        },
    },
    components: {
        AppCheckboxInput,
        TheNegotiationModal,
    },
    emits: ['close', 'submitted'],
    setup(props, context) {
        const {
            sendNotifications,
            submitCounterOffer,
            acceptNegotiationOffer,
            rejectNegotiationOffer,
            loadingAcceptOffer,
            loadingRejectOffer,
            loadingSubmitCounterOffer,
            negotiatingUserOfferAmount,
            otherUser,
            otherUserOfferAmount,
        } = useAuctionNegotiations({
            vehicleListingId: props.vehicleListingId,
            negotiatingUser: props.negotiatingUser,
            buyerOfferAmount: props.buyerOfferAmount,
            sellerOfferAmount: props.sellerOfferAmount,
            buyerPersonId: props.buyerPersonId,
            sellerPersonId: props.sellerPersonId,
            context,
        })

        return {
            capitalize,
            toCurrency,
            sendNotifications,
            submitCounterOffer,
            acceptNegotiationOffer,
            rejectNegotiationOffer,
            loadingAcceptOffer,
            loadingRejectOffer,
            loadingSubmitCounterOffer,
            negotiatingUserOfferAmount,
            otherUser,
            otherUserOfferAmount,
        }
    },
});
