
import { defineComponent, PropType, ref } from 'vue';
import { TableColumn } from '../../types'

import AppFastBookDropdown from '../AppFastBookDropdown.vue';
import EditableTableField from '../EditableTableField.vue';
import InfoTitle from '../Arbitrations/InfoTitle.vue'

interface VehicleColumn {
    imageUrl?: string, // field name for imageUrl
    title?: string[], // field names for title
    description?: string[] // field names for description
    subtitle?: string[], // field names for subtitle
    showCopySubtitleButton?: boolean, // show copy subtitle button
    customClickEvent?: boolean, // custom click event
}

export default defineComponent({
    inheritAttrs: false,
    name: 'TableSpecialColumns',
    props: {
        textColumns: {
            type: Array as PropType<TableColumn[]>,
            required: true,
        },
        displayEmptyText: {
            type: Boolean,
            default: true,
        },
        hasVehicleColumn: { // uses vehicleColumn prop to create our standard Vehicle column (title + subtitle, and optional image)
            type: Boolean,
            default: false,
        },
        vehicleColumn: {
            type: Object as PropType<VehicleColumn>,
            default: () => {
                return {
                    imageUrl: 'heroPicUrl',
                    title: ['year', 'make', 'model'],
                    subtitle: ['trim'],
                    showCopySubtitleButton: false,
                }
            }
        },
    },
    components: {
        AppFastBookDropdown,
        EditableTableField,
        InfoTitle,
    },
    emits: ['update', 'click'],
    setup(props, { emit }) {
        function getFieldsFromData(rowData, type) {
            let fields = props.vehicleColumn[type].map(field => rowData[field]);
            return fields.join(' ');
        }

        const editingRowIdx = ref(undefined);
        function updateRow(rowData, fieldName, updatedValue) {
            emit('update', {
                rowData, 
                fieldName,
                updatedValue,
            });
        }
        return {
            getFieldsFromData,
            editingRowIdx,
            updateRow,
        }
    },
});
