
import { defineComponent, PropType } from 'vue';
import { focusOnElement } from '@/utils';
import Modal from './Modal.vue';

interface PricingDetails {
  blackbook_trade: number;
  retail: number;
  jd_power: number;
  mmr: number;
  kbb_trade: number;
  kbb_private: number;
}

export default defineComponent({
  name: 'EditVehiclePricingDetailsModal',
  components: {
    Modal,
  },
  props: {
    pricingDetails: {
      type: Object as PropType<PricingDetails>,
      required: true,
    },
  },
  data() {
    return {
      pricingDetailsForm: this.pricingDetails,
    };
  },
  mounted() {
    window.setTimeout(() => {
      focusOnElement('pricing-details-blackbook-trade');
    }, 300);
  },
});
