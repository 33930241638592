
import { computed, defineComponent, ref } from 'vue';
import Modal from '@/components/Modal.vue';
import route from '@/router';
import { focusOnElement } from '../../utils';

const __default__ = defineComponent({
    name: 'TheFilterEditPresetModal',
    props: {
      canUpdateExisting: {
        type: Boolean,
        default: true,
      }
    },
    components: { Modal },
    emits: ['updatePreset', 'addPreset', 'close'],
    setup(props, { emit }) {
        const presetName = ref('');
        const notifications = ref({
            email: false,
            text: false,
        });
        const showPresetInput = ref(false);

        const hasSavedFilterQueryParam = computed(() => route.currentRoute.query.savedFilter);

        function closeModal() {
            emit('close');
        }

        function handleUpdatePreset() {
            emit('updatePreset', presetName.value);
            closeModal();
        }

        function handleNewPreset() {
            emit('addPreset', {
                presetName: presetName.value,
                notifications: notifications.value,
            });
            closeModal();
        }

        const modalWidth = computed(() => (showPresetInput.value ? '460px' : '500px'));

        const showNameNewPresetInput = function () {
            showPresetInput.value = true;
            window.setTimeout(() => {
                focusOnElement('preset-name');
            }, 100);
        };
        
        if (!props.canUpdateExisting) {
          showNameNewPresetInput();
        }

        return {
            presetName,
            notifications,
            showPresetInput,
            showNameNewPresetInput,
            handleUpdatePreset,
            handleNewPreset,
            modalWidth,
            closeModal,
            hasSavedFilterQueryParam,
        };
    },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "221ff6c0": (_vm.modalWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__