var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AuctionStat',_vm._b({},'AuctionStat',{
        name: _vm.text,
        value: _vm.bidCount,
        icon: 'gavel',
        useIcon: true,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }