<template>
    <div 
        v-bind="$attrs"
        :style="divStyles"
        class="app-loading-skeleton"
    >
        <b-skeleton 
            :animated="$attrs.animated ?? true"
            :height="height"
            :expanded="$attrs.expanded"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AppLoadingSkeleton',
    props: {
        isButton: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        let divStyles = '';
        let height = context.attrs.height ?? (props.isButton ? '40px' : '');
        if (context.attrs.height) {
            divStyles = `height: ${height}; `;
        }
        if (context.attrs.width) {
            divStyles += `width: ${context.attrs.width};`;
        }
        return {
            divStyles,
            height,
        }
    }
});
</script>