<template>
    <AppButton
        class="no-border"
        @click="$emit('click', $event)"
        v-bind="$attrs"
    >
        <b-icon
            :icon="iconName"
            :class="iconColor"
            v-if="!$attrs.loading"
            v-bind="$attrs.iconAttrs"
        />
    </AppButton>
</template>

<script>
import AppButton from './AppButton.vue';

export default {
    inheritAttrs: false,
    name: 'ButtonIcon',
    props: {
        iconName: {
            type: String
        },
        iconColor: {
            type: String,
            default: 'has-text-grey-light'
        },
    },
    components: {
        AppButton,
    },
}
</script>
