
import { AnnouncementCategoryKeys, VehicleListingAnnouncement } from '@/types';
import { computed, defineComponent, PropType } from 'vue';
import { startCase } from 'lodash';
import { getIconColorByAnnouncementType, getMostSevereAnnouncementTypeFromList } from '@/utils';

import AppAccordion from '@/components/AppAccordion.vue';
import TheAnnouncement from '@/components/TheAnnouncement.vue';

export default defineComponent({
    name: 'TheAnnouncementCategoryCard',
    props: {
        announcements: {
            type: Array as PropType<VehicleListingAnnouncement[]>,
            default: () => { return [] },
        },
        category: {
            type: String as PropType<AnnouncementCategoryKeys>,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
        clickToViewPhoto: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        AppAccordion,
        TheAnnouncement,
    },
    emits: ['edit', 'delete', 'viewPhoto'],
    setup(props, context) {
        const getMostSevereIconColor = computed(() => {
            let mostSevereAnnouncementType = getMostSevereAnnouncementTypeFromList(props.announcements);
            return getIconColorByAnnouncementType(mostSevereAnnouncementType);
        });
        return {
            startCase,
            getMostSevereIconColor,
        }
    }
});
