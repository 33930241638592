import { ArbitrationOptionName, OpenArbitrationClaim, VehicleStatus } from "@/types";
import { orderSummary } from "@/types/OrderSummary";
import { getDifferenceInDays, isVehicleInCheckoutStatus, openModal, serverSyncedNewDate } from "@/utils";
import { computed, ComputedRef, Ref } from "vue";
import { useFetch } from "./fetch";

import OrderSummaryPdf from "@/views/OrderSummaryPdf.vue";

export function useFetchOrder(vehicleListingId: number) {
    const { data, loading } = fetchOrder();

    function fetchOrder(onSuccess?: (res: orderSummary) => void) {
        const { data, loading } = useFetch<orderSummary>(`/vehicles/order/${vehicleListingId}`, { onSuccess });
        return {
            data, 
            loading,
        }
    }

    return {
        order: data,
        loadingOrder: loading,
        fetchOrder,
    }
}

export function useIsArbitrationClaimAvailable({ hasArbitrationClaim, selectedArbitrationOption, vehicleDeliveryDate, arbitrationExpirationDate, buyerStoreId }: {
    hasArbitrationClaim: Ref<boolean>,
    selectedArbitrationOption?: ArbitrationOptionName,
    vehicleDeliveryDate?: Date,
    arbitrationExpirationDate?: Date,
    buyerStoreId?: number,
}) {
    const arbitrationTypesAndLengths: { [key: string]: number } = {
        'standardArbitration': 3,
        'extendedArbitration': 10,
        'premiumArbitration': 17,
    }
    const arbitrationLengthInDays: number | undefined = selectedArbitrationOption ? arbitrationTypesAndLengths[selectedArbitrationOption] : undefined;

    const isArbitrationAvailable: ComputedRef<boolean> = computed(() => {
        if (hasArbitrationClaim.value) {
            return false;
        }
        if (!vehicleDeliveryDate) {
            return false;
        }
        if (!hasArbitrationTimeRemaining.value) {
            return false;
        }
        return true;
    });

    const arbitrationTimeRemaining: ComputedRef<number | undefined> = computed(() => {
        if (!vehicleDeliveryDate || !arbitrationLengthInDays) {
            return undefined;
        }
        const today = serverSyncedNewDate();
        const daysSinceDelivery = getDifferenceInDays(today, vehicleDeliveryDate);
        return arbitrationLengthInDays - daysSinceDelivery;
    });

    const hasArbitrationTimeRemaining: ComputedRef<boolean> = computed(() => Boolean(arbitrationTimeRemaining.value && arbitrationTimeRemaining.value > 0));

    const arbitrationUnavailableReason: ComputedRef<string | undefined> = computed(() => {
        if (hasArbitrationClaim.value) {
            return 'An arbitration claim has already been submitted for this car';
        }
        if (!buyerStoreId) {
            return 'This car is missing some store information. Please contact support.';
        }
        if (!arbitrationLengthInDays) {
            return 'No Buyer Assurance Plan was found. Please contact support.';
        }
        if (!vehicleDeliveryDate) {
            return `When your vehicle has been delivered, you will have 24 hours to submit a cosmetic claim, and 
            ${Math.round(arbitrationLengthInDays)} days to submit a non-cosmetic claim.`;
        }
        if (!hasArbitrationTimeRemaining.value) {
            return `Your arbitration claim window has closed`;
        }
    });

    return {
        arbitrationLengthInDays,
        isArbitrationAvailable,
        arbitrationUnavailableReason,
        hasArbitrationTimeRemaining,
        arbitrationTimeRemaining,
    }
}

export function useGetArbitrationDetails(vehicleListingId: number): {
    arbitrationDetails?: Ref<OpenArbitrationClaim | undefined>,
    loadingArbitrationDetails: Ref<boolean>,
} {
    const { data: arbitrationDetails, loading: loadingArbitrationDetails } = useFetch<OpenArbitrationClaim>(`/arbitration/${vehicleListingId}/openClaimDetails`, {
        onSuccess: (response, data) => {
            const { vehicleDeliveryDate, arbitrationExpirationDate } = response;
            data.value = {
                ...response,
                vehicleDeliveryDate: vehicleDeliveryDate ? new Date(vehicleDeliveryDate) : undefined,
                arbitrationExpirationDate: arbitrationExpirationDate ? new Date(arbitrationExpirationDate) : undefined,
            }
        }
    })

    return {
        arbitrationDetails,
        loadingArbitrationDetails,
    }
}

export function useCheckoutModal() {

}

export function useOrderSummaryModal({ vehicleListingId, vehicleStatus, isUserAdminOrDsr }: {
    vehicleListingId: number,
    vehicleStatus?: VehicleStatus,
    isUserAdminOrDsr?: boolean,
}) {
    function openOrderSummaryModal() {
        let orderSummaryMessage;
        if (isUserAdminOrDsr && isVehicleInCheckoutStatus(vehicleStatus)) {
            orderSummaryMessage = 'This vehicle has not been checked out. Values may not be final.';
        }
    
        openModal({
            component: OrderSummaryPdf,
            props: {
                vehicleListingId,
                orderSummaryMessage,
            },
            hasModalCard: false,
        });
    }

    return {
        openOrderSummaryModal,
    }
}