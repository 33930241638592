var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppEditableField',_vm._b({key:`assigned-inspector-${_vm.inspectorKey}`,scopedSlots:_vm._u([{key:"selected",fn:function(){return [(_vm.loadingInspectorOptions || _vm.loadingSaveInspector)?_c('ButtonIcon',{attrs:{"loading":_vm.loadingInspectorOptions || _vm.loadingSaveInspector}}):_vm._e()]},proxy:true},{key:"selectedValue",fn:function({ value }){return [_vm._v(" "+_vm._s(value.firstName)+" "+_vm._s(value.lastName)+" ")]}},{key:"input",fn:function({ onSelect }){return [_c('AutocompleteInput',_vm._b({on:{"select":function($event){_vm.updateAssignedInspector($event); _vm.assignInspector($event); onSelect($event)}},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_vm._v(" "+_vm._s(option.firstName)+" "+_vm._s(option.lastName)+" ")]}}],null,true)},'AutocompleteInput',{
                value: _vm.assignedInspector,
                options: _vm.inspectorOptions,
                loading: _vm.loadingInspectorOptions,
                ..._vm.$attrs.autocompleteAttrs,
            },false))]}}])},'AppEditableField',{
        value: _vm.assignedInspector,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }