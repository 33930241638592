import { render, staticRenderFns } from "./TheBuyItNowModal.vue?vue&type=template&id=cfdfe716&scoped=true&"
import script from "./TheBuyItNowModal.vue?vue&type=script&lang=ts&"
export * from "./TheBuyItNowModal.vue?vue&type=script&lang=ts&"
import style0 from "./TheBuyItNowModal.vue?vue&type=style&index=0&id=cfdfe716&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfdfe716",
  null
  
)

export default component.exports