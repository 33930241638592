import { DialogProgrammatic } from 'buefy';
import { GET, getBaseURL, PUT } from "./api";
import { fireEvent } from './segment';
import router from '@/router'
import { ToastProgrammatic } from 'buefy'

import {
  getDifferenceInDays,
  firebaseDate2Date,
  capitalizeWord,
} from "./utils"

// Arbitration Functions

export function markClaimOpened(newClaimId) {
  this.hasArbitrationClaim = newClaimId;
}

export function hasArbitrationTimeRemaining(arbitrationExpirationDate: Date) {
  if (!arbitrationExpirationDate) {
    return false;
  }

  const today = new Date();
  const daysUntilArbitrationExpires = getDifferenceInDays(new Date(arbitrationExpirationDate), today);
  return daysUntilArbitrationExpires >= 0;
}

export function getArbitrationSelectionDays(vehicle) {
  const selectedArbitration = this.getArbitrationSelection(vehicle);
  if (!selectedArbitration.length) return 0;
  var assuranceLength = 3;
  switch (selectedArbitration[0].name) {
    case "extendedArbitration":
      assuranceLength = 14;
      break;
    case "premiumArbitration":
      assuranceLength = 21;
      break;
  }
  return assuranceLength;
}

export function getOpenClaimTooltip(vehicle) {
  if (this.hasArbitrationClaim > 0) {
    return `An arbitration claim has already been submitted for this vehicle.`;
  }
  if (!vehicle.auction.storeId) {
    return `This vehicle is missing an associated store ID. Please contact a Carmigo administrator for assistance.`;
  }

  if (!this.getArbitrationSelection(vehicle)) {
    return `Missing Buyer Assurance Plan selection. Please contact a Carmigo administrator for assistance.`;
  }

  if (!vehicle.deliveryDate) {
    return `When your vehicle has been delivered, you will have 24 hours to submit a cosmetic claim, and ${Math.round(
      this.getArbitrationSelectionDays(vehicle)
    )} days to submit a non-cosmetic claim.`;
  }

  if (this.getArbitrationTimeRemaining(vehicle) < 0) {
    return "Your arbitration claim window has closed.";
  }
  return "Nope";
}

export function getArbitrationTimeRemaining(vehicle) {
  const today = new Date();
  const arbitrationExpires = new Date(vehicle.arbitrationExpires);
  const daysForarbitrationExpires= getDifferenceInDays(arbitrationExpires,today);
  return daysForarbitrationExpires;
}

export function getArbitrationCosmeticTimeRemaining(vehicle) {
  const today = new Date();
  const deliveryDate = new Date(vehicle.deliveryDate);
  const daysSinceDelivery = getDifferenceInDays(today, deliveryDate);
  if (daysSinceDelivery > 1) {
    vehicle.cosmeticDaysLeft = false;
  } else {
    vehicle.cosmeticDaysLeft = true;
  }

  return daysSinceDelivery;
}

export function getArbitrationSelection(vehicle) {
  const ancillaryOptions = vehicle.orderSummary.ancillaryOptions;
  return getArbitrationSelectionUsingAncillaryOption(ancillaryOptions);
}

export function getArbitrationSelectionUsingAncillaryOption(ancillaryOptions) {
  const selectedArbitration = ancillaryOptions
    .filter(
      (option) =>
        option.name === "standardArbitration" ||
        option.name === "extendedArbitration" ||
        option.name === "premiumArbitration"
    )
    .filter((arbitration) => arbitration.selected === true);
  return selectedArbitration;
}

export function orderSummaryDisplayObjectUsingOrder(order){
  let orderSummaryDisplay = {
    "Vehicle Price": order.vehiclePrice,
    "Buyer Fee": order.buyerFee,
  };
  order.ancillaryOptions.forEach((option,index)=>{
    if(option.name === 'carmigoFloatFee'){
      orderSummaryDisplay[(getOptionName(option.name))] = option.selected ? option.price:0;
    }
    else if(option.selected){
      orderSummaryDisplay[(getOptionName(option.name))] = option.price;
    };
  });
  orderSummaryDisplay['Total'] = Object.keys(orderSummaryDisplay).map(key => orderSummaryDisplay[key]).reduce((a,b) => a+b);
  return orderSummaryDisplay;
}

export function getOptionName(option) {
  let name;
  switch (option) {
    case "standardArbitration":
      name = "Standard Buyer Assurance"
      break;
    case "extendedArbitration":
      name = "Extended Buyer Assurance"
      break;
    case "premiumArbitration":
      name = "Premium Buyer Assurance"
      break;
    case "lotTransportation":
      name = "Lot Transportation Fee"
      break;
    case "carmigoFloatFee":
      name = "Carmigo Float Fee"
      break;
    default:
      name = capitalizeWord(option);
      break;
  }
  return name;
}

function showDashboardErrorDialog() {
  return DialogProgrammatic.confirm({
    title: 'Error loading dashboard',
    message: 'There was an error loading the app. Please refresh the page.',
    type: 'is-danger',
    hasIcon: true,
    icon: 'close-circle',
    ariaRole: 'alertdialog',
    confirmText: 'Refresh',
    ariaModal: true,
    canCancel: false,
    onConfirm: () => window.location.reload(),
  });
}

// Dashboard Listing Functions
export function getDashboardListings(status, num) {
  switch (status) {
    case 'Bidding':
      PUT(`/vehicles/dashboard/Bidding`).then(res => {
        const biddingVehicles = res.data;
        this.moreBiddingCars = Boolean(biddingVehicles.length);
        biddingVehicles.forEach(vehicle => {
          this.bidding.push(vehicle);
        });

        this.isLoadingBiddingInitial = false;
      });
      break;
    case 'Purchased':
      PUT(`/vehicles/dashboard/Purchased`, { 
        paginationAmount: this.purchased.length,
        paginationLimit: num 
      }).then(res => {
          const purchasedVehicles = res.data;
          this.morePurchasedCars = Boolean(purchasedVehicles.length);
          this.isFetching = false;
          purchasedVehicles.forEach(vehicle => {
            this.purchased.push(vehicle);
          });

          this.isLoadingPurchasedInitial = false;
        });
      break;
    case "Selling":
      if (this.$store.state.isCarmigoDirect) {
        const branding = this.$store.state.branding;
        PUT(`${getBaseURL()}/carmigoDirect/sellerListings`, {
          storeId: parseInt(branding.storeId),
          paginationLimit: num
        }).then(response => { 
          this.getAllAuctionsForDashboardSellingArray = response.data;
          if (!response.data.length || response.data.length < num) {
            const difference = response.data.length - num;
            this.inactivePaginationNumber = Math.abs(difference);
            this.moreSellingCars = false;
          }
          this.selling = this.getAllAuctionsForDashboardSellingArray;
          this.isLoadingSellingInitial = false;
        }).catch(() => {
          this.moreSellingCars = false;
          this.isLoadingSellingInitial = false;
          showDashboardErrorDialog();
        });
      } else {
        PUT(
          `${getBaseURL()}/vehicles/dashboard/Selling`,
          {
            paginationLimit: num,
          }
        ).then((response) => {
          this.getAllAuctionsForDashboardSellingArray = response.data;
          if (!response.data.length || response.data.length < 20) {
            const difference = response.data.length - 20;
            this.inactivePaginationNumber = Math.abs(difference);
            this.moreSellingCars = false;
          }
          this.selling = this.getAllAuctionsForDashboardSellingArray;
          this.isLoadingSellingInitial = false;
        }).catch(() => {
          this.moreSellingCars = false;
          this.isLoadingSellingInitial = false;
          showDashboardErrorDialog();
        });
      }
      break;
    case "Inactive":
      PUT(
        `${getBaseURL()}/vehicles/dashboard/Inactive`,
        {
          paginationLimit: num,
        }
      ).then((response) => {
        this.getAllAuctionsForDashboardInactiveArray = response.data;
        if (!response.data.length || response.data.length < 20) {
          this.moreInactiveCars = false;
        }
        this.getAllAuctionsForDashboardInactiveArray.forEach((vehicle) => {
          this.selling.push(vehicle);
        });
        this.isLoadingInactiveInitial = false;
      }).catch(() => {
        this.moreInactiveCars = false;
        this.isLoadingSellingInitial = false;
        showDashboardErrorDialog();
      });
      break;
    case 'Negotiating':
      PUT(`/vehicles/dashboard/Negotiating`).then(res => {
        const negotiatingVehicles = res.data;
        this.moreNegotiatingCars = Boolean(negotiatingVehicles.length);
        negotiatingVehicles.forEach(vehicle => {
          this.negotiating.push(vehicle);
          vehicle.negotiationOnBuyer
            ? this.userIsBuyer ? this.numNegotiations++ : ''
            : this.userIsSeller ? this.numNegotiations++ : '';
          
        });

        this.isLoadingNegotiatingInitial = false;
        this.negotiationPaginationNumber += negotiatingVehicles.length;
        this.negotiationPaginationAmount += negotiatingVehicles.length;
      });
      break;
    default:
      return;
  }
}

export function loadMore(category) {
  switch (category) {
    case 'Bidding':
      if (this.moreBiddingCars) {
        this.isLoadingBidding = true;
        // Paginate
        this.getMoreAuctions(this.biddingPaginationNumber + 9, 9, "Bidding");
      }
      break;
    case 'Purchased':
      if (this.morePurchasedCars) {
        this.isLoadingPurchased = true;
        // Paginate
        this.getMoreAuctions(this.purchasedPaginationNumber + 9, 9, "Purchased");
      }
      break;
    case "Selling":
      if (this.moreSellingCars) {
        this.isLoadingSelling = true;
        // Paginate
        this.getMoreAuctions(this.sellingPaginationNumber + 20, 20, "Selling");
      }
      break;
    case "Inactive":
      if (this.moreInactiveCars) {
        this.isLoadingInactive = true;
        // Paginate
        this.getMoreAuctions(
          this.InactivePaginationNumber + 20,
          20,
          "Inactive"
        );
      }
      break;
    case 'Negotiating':
      if (this.moreNegotiatingCars) {
        this.isLoadingNegotiating = true;
        // Paginate
        this.getMoreAuctions(this.negotiatingPaginationNumber + 9, 9, "Negotiating");
      }
      break;
  }
}


export function getSellerInfoBoxText(sellerFirstName, vehicle) {
  return `${sellerFirstName}'s ${vehicle.year} ${vehicle.make} ${vehicle.model}`;
}

export function fireSegmentEvent(eventName, vehicleListingId, auctionStatus) {
  fireEvent(eventName, {
    vehicle_id: vehicleListingId,
    auction_status: auctionStatus
  });
}

// eslint-disable-next-line consistent-return
export async function getSellerAndVehicleName(userId, vehicleListingId) {
  try {
    const sellerData = await GET(`vehicles/getBasicSellerVehicleDTO/${vehicleListingId}/${userId}`).then((res) => res.data);
    return `${sellerData.sellerFirstName}'s ${sellerData.vehicleYear} ${sellerData.vehicleMake} ${sellerData.vehicleModel}`;
  } catch (error) {
    ToastProgrammatic.open({
      message: `Error getting seller data for vehicleListingId ${vehicleListingId} and userId ${userId}`,
      type: 'is-danger',
    });
    router.push('/');
  }
}

export async function goToDetails({ vehicleListingId, marketplaceListingId, activeTab, openInNewTab }: {
  vehicleListingId: string, 
  activeTab: string, 
  marketplaceListingId?: string,
  openInNewTab?: boolean
}) {
  let params: { id: string, activeTab: string, marketplaceListingId?: string} = {
    id: vehicleListingId,
    activeTab,
  }
  if (marketplaceListingId) {
    params.marketplaceListingId = marketplaceListingId;
  }

  if (openInNewTab) {
    const routeData = router.resolve({
      name: 'Auction Details',
      params,
    });
    
    window.open(routeData.href, '_blank');
    return;
  }
  
  router.push({
    name: 'Auction Details',
    params,
  });
}

