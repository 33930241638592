<template>
    <TheCheckoutButton 
        v-bind="{
            vehicleListingId,
            marketplaceListingId,
            vehicleStatus,
            facilitatingAuctionStoreName,
            label: 'View Order Summary',
            type: 'is-grey',
            isReadOnly: !facilitatingAuctionStoreName,
            ...$attrs,
        }"
    />
</template>

<script>
import TheCheckoutButton from './TheCheckoutButton.vue';

export default {
    name: 'ViewOrderSummaryButton',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehicleStatus: {
            type: String,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
        facilitatingAuctionStoreName: {
            type: String,
            required: false,
        }
    },
    components: {
        TheCheckoutButton,
    },
}
</script>
