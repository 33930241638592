import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import firebaseConfig from './config';

const target = process.env.VUE_APP_TARGET as 'dev' | 'staging' | 'prod';

const app = firebase.initializeApp(firebaseConfig[target ?? 'dev']);

// Below Code will connect to real time emulator
// if (location.hostname === "localhost") {
//   var db = firebase.database();
//   db.useEmulator("localhost", 9000);
// }

export const auth = firebase.auth(app);
export const database = firebase.database(app);
export const storage = firebase.storage(app);
