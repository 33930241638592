
import { computed, ComputedRef, defineComponent, nextTick, onMounted, PropType, Ref, ref, watch } from 'vue';
import { getRebateDetailsForLoggedInBuyer } from '../api';
import { useBreakpoint, useNotificationListener } from '../composables';
import { BuyerRebate } from '../types';
import store from '@/vuex';
import { 
    addTransformVerticalScrollToHorizontalScrollEventListener, 
    openModal, 
    toCurrency,
} from '../utils';

import AppDropdown from './AppDropdown.vue';
import TheBuyerRebateDetailsModal from './TheBuyerRebateDetailsModal.vue';

export default defineComponent({
    name: 'TheBuyerRebateProgressBar',
    props: {
        displayWindowStepCount: {
            type: Number,
            default: 6,
        },
        refreshKey: {
            type: Number,
            default: 0,
        },
    },
    components: {
        AppDropdown,
    },
    setup(props) {
        const { bulmaBreakpoint } = useBreakpoint();
        onMounted(getRebatesForLoggedInBuyerStores);
        watch(() => props.refreshKey, getRebatesForLoggedInBuyerStores);

        const rebatesForLoggedInBuyerStores: Ref<BuyerRebate[]> = ref([]);
        async function getRebatesForLoggedInBuyerStores() {
            rebatesForLoggedInBuyerStores.value = await getRebateDetailsForLoggedInBuyer();
            if (!rebatesForLoggedInBuyerStores.value?.length) {
                return;
            }

            // Set the displayStoreId to the first store if not defined
            if (displayStoreId.value == undefined) {
                let defaultStoreId = rebatesForLoggedInBuyerStores.value[0].storeId;
                displayStoreId.value = defaultStoreId;
            }

            nextTick(updateProgressBarScrolling);
        }

        /** Store to display rebates for */
        const displayStoreId: Ref<number | undefined> = ref(undefined);
        const displayStoreRebate: ComputedRef<BuyerRebate | undefined> = computed(() => {
            if (displayStoreId.value == undefined) {
                return;
            }
            return rebatesForLoggedInBuyerStores.value.find(rebate => rebate.storeId == displayStoreId.value);
        });

        function updateDisplayStoreId(storeId?: number) {
            if (storeId) {
                displayStoreId.value = storeId;
                nextTick(updateProgressBarScrolling);
            }
        }

        function openBuyerRebateDetailsModal() {
            openModal({
                component: TheBuyerRebateDetailsModal,
            });
        }

        // progress bar styling
        const progressBarStepWidth = 50;
        const progressBarStepWidthPx = `${progressBarStepWidth}px`;
        const progressBarLeftOffsetPx = '10px';
        const progressBarContainerWidth = `calc(${props.displayWindowStepCount} * ${progressBarStepWidthPx} + ${progressBarLeftOffsetPx})`;
        const progressBarWidth = `calc(${props.displayWindowStepCount} * ${progressBarStepWidthPx} - ${progressBarLeftOffsetPx})`;

        function updateProgressBarScrolling() {
            addTransformVerticalScrollToHorizontalScrollEventListener(`#buyer-rebate-progress-bar-${displayStoreRebate.value.storeId}`);
            scrollToStartingIndex();
        }

        /* progress bar display window (last complete step + props.displayWindowStepCount) */
        function scrollToStartingIndex() {
            let progressBarElement = document.getElementById(`buyer-rebate-progress-bar-${displayStoreRebate.value.storeId}`);
            if (progressBarElement) {
                const startingIndex = getStartingIndex();
                progressBarElement.scrollLeft = progressBarStepWidth * startingIndex;
            }
        }

        function getStartingIndex() {
            // reverse the bonuses array and get the first completed step (= last completed step in original array)
            let reversedBonuses = displayStoreRebate.value.bonuses.slice().reverse();
            let reversedStepIdx = reversedBonuses.findIndex(bonus => bonus.isComplete);
            // if none are completed, start from the beginning
            if (reversedStepIdx < 0) {
                return 0;
            }
            // get the index in the original array 
            let lastCompleteStepIndex = -(reversedStepIdx - displayStoreRebate.value.bonuses.length);
            return lastCompleteStepIndex > 0 ? lastCompleteStepIndex : 0;
        }

        /** RDB Updates - on notification/actionRequired update (vehicle enters Checkout), re-fetch rebates 
         * This will only update Rebates for purchases made by the logged-in buyer
         * Other buyers' purchases are handled on route change from NavTop
        */
        const userId = computed(() => store?.state?.user?.profile?.userId);
        useNotificationListener(userId, {
            onSnapshot: ({ newSnapshotValue, oldSnapshotValue }) => {
                if (!newSnapshotValue || !oldSnapshotValue) {
                    return;
                }
                const { actionRequired: actionRequiredNew } = newSnapshotValue;
                const { actionRequired: actionRequiredOld } = oldSnapshotValue;

                if (actionRequiredNew?.checkout?.updatedDate !== actionRequiredOld?.checkout?.updatedDate) {
                    getRebatesForLoggedInBuyerStores();
                }
            }
        });

        return {
            bulmaBreakpoint,
            toCurrency,
            displayStoreRebate,
            updateDisplayStoreId,
            rebatesForLoggedInBuyerStores,
            openBuyerRebateDetailsModal,

            progressBarStepWidthPx,
            progressBarLeftOffsetPx,
            progressBarContainerWidth,
            progressBarWidth,
        }
    },
});
