import { POST } from '@/api';
import store from '@/vuex';
import router from '@/router';

async function getIpAddress() {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();

    if (!data.ip) {
      return '';
    }

    return data.ip;
  } catch {
    return '';
  }
}

export async function postAnalyticsData(eventName: string) {
  const date = new Date();
  const userId = store.state?.user?.profile?.userId;
  const domainName = window.location.hostname;
  const currentRoute = router.currentRoute.fullPath;
  const ipAddress = await getIpAddress();

  try {
    await POST('/analytics/', {
      eventName,
      url: currentRoute,
      date,
      userId,
      ipAddress,
      domainName,
    });
  } catch(e){e}
}
