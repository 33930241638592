<template>
    <div class="chat-message-body" :class="messageStyles.color + ' ' + messageStyles.solid">
        <!-- Text -->
        <slot name="body">
            <p class="chat-message-text">{{ messageText }}</p>
        </slot>
        
        <!-- Content -->
        <div v-if="messageContent && messageContent.contentBody" class="chat-message-content text-center">
            <p v-if="messageContent.type === 'string' && messageContent.contentBody !== messageText">
                {{ messageContent.contentBody }}
            </p>
            <p v-if="messageContent.type === 'USD'">
                {{ formatDollarAmount(messageContent.contentBody) }}
            </p>
            <BulletedList
                v-if="messageContent.type === 'stringArray'"
                :listItems="messageContent.contentBody" 
            />
            <div 
                v-if="messageContent.type==='attachments'"
                class="flex-row attachments"
            >
                <AddedAttachment
                    v-for="attachment, idx in messageContent.contentBody"
                    :key="idx"
                    :imageUrl="attachment.url"
                    :fileName="attachment.filename"
                    :fileType="attachment.fileType"
                    :canDelete="false"
                    alignment="column"
                    imgSize="small"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { formatDollarAmount } from "../../utils"
import AddedAttachment from "../Arbitrations/AddedAttachment.vue"
import BulletedList from "../Arbitrations/BulletedList.vue"
export default {
    name: "ChatMessageBody",
    props: [
        "messageText",
        "messageContent",
        "messageStyles"
    ],
    components: {
        AddedAttachment,
        BulletedList,
    },
    methods: {
        formatDollarAmount,
    },
    mounted() {
    }
    
}
</script>

<style scoped lang="postcss">
.chat-message-body {
    padding: .75em;
    border-radius: 10px;
    font-size: .75rem;
    font-weight: 500;
    max-width: 65%;

    & .chat-message-text {
        color: white;
    }

    & .chat-message-content {
        margin: .5em 1em;
    }
}

.outline {
    position: relative;
    background: white;
    border: 3px solid currentColor;
    & .chat-message-text {
        color: inherit;
        font-weight: 700;
    }

    &:before {
        content: ' ';
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}

.grey-transparent {
    background: rgba(196, 196, 196, 0.1);
    border-color: rgba(196, 196, 196);
    color: rgba(196, 196, 196);
}
.green-transparent {
    background: rgba(82, 170, 86, 0.1);
    border-color: rgba(82, 170, 87);
    color: rgba(82, 170, 87);
}
.yellow-transparent {
    background: rgba(249, 203, 85, 0.1);
    border-color: rgba(249, 203, 85);
    color: rgba(249, 203, 85);
}
.red-transparent {
    background: rgba(248, 126, 113, 0.1);
    border-color: rgba(248, 126, 113);
    color: rgba(248, 126, 113);
}
.orange-transparent {
    background: rgba(255, 169, 89, 0.1);
    border-color: rgba(255, 169, 89,);
    color: rgba(255, 169, 89);
}
.solid {
    background: currentColor;
}

.attachments {
    margin: 1em;
    overflow-x: scroll;
    overflow-y: hidden;
    z-index: 100;
}
::-webkit-scrollbar {
  /* display: none; */
}
</style>