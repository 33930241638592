
import { defineComponent, PropType, Ref, ref } from 'vue';
import { assignInspectorToListing } from '../api';
import { openToast } from '../utils';

import AppButton from './AppButton.vue';
import Modal from './Modal.vue';
import TheAssignInspectorInput from './TheAssignInspectorInput.vue';

export default defineComponent({
    name: 'TheAssignInspectorModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        inspectorPersonId: {
            type: Number,
            required: false,
        }
    },
    components: {
        AppButton,
        Modal,
        TheAssignInspectorInput,
    },
    emit: ['close'],
    setup(props, { emit, attrs }) {
        const assignedInspector = ref(undefined);

        const loadingSaveInspector: Ref<boolean> = ref(false);
        async function saveAssignedInspector() {
            loadingSaveInspector.value = true;
            await assignInspectorToListing(assignedInspector.value?.personId, props.vehicleListingId, {
                onSuccess: () => {
                    loadingSaveInspector.value = false;
                    openToast('is-success', 'Inspector assigned!') 
                    emit('assignInspector', assignedInspector.value);
                    emit('close');
                },
                onError: () => loadingSaveInspector.value = false
            });
        }

        const loadingRemoveInspector: Ref<boolean> = ref(false);
        async function removeAssignedInspector() {
            loadingRemoveInspector.value = true;
            await assignInspectorToListing(null, props.vehicleListingId, {
                onSuccess: () => {
                    loadingRemoveInspector.value = false;
                    openToast('is-success', 'Inspector unassigned');
                    emit('assignInspector', undefined);
                    emit('close');
                }, 
                onError: () => loadingRemoveInspector.value = false
            });
        }
        return {
            assignedInspector,
            saveAssignedInspector,
            loadingSaveInspector,
            removeAssignedInspector,
            loadingRemoveInspector,
        }
    },
});
