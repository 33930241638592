<template>
    <b-tooltip 
    :active="showDefaultTooltip"
    multilined
    label="Carmigo Certified Vehicle. This car will meet reserve quicker than similar listings"
    position="is-bottom"
    >
        <div class="tooltip" @click="$emit('click')">
            <img class="certified-badge"
            src="../assets/icons/carmigo-certified-badge-90px.png"
            />
            <div v-if="showLargeTooltip" class="tooltip-container">
                <div class="tooltip-header flex-row align-center">
                    <b-icon class="tooltip-icon" icon="information" custom-size="mdi-18px" />
                    <span class="tooltip-title">Carmigo Certified Vehicle</span>
                </div>
                <span class="tooltip-text">
                    This car will meet reserve quicker than similar listings
                </span>
            </div>
        </div>
    </b-tooltip>
</template> 

<script>
export default {
    name: 'AuctionCertifiedBadge',
    props: {
        showLargeTooltip: {
            type: Boolean,
            required: false,
            default: true
        },
        showDefaultTooltip: {
            type: Boolean,
            required: false,
            default: false
        }
    },
}
</script>

<style scoped lang='postcss'>
.tooltip-container {
    width: 230px;
    padding: .25rem .5rem;
    background-color: white;
    color: #266112;
    border: 2px solid currentColor;
    border-radius: 5px;
    font-size: 13px;
    visibility: hidden;
    position: relative;
    left: -20%;


    & .tooltip-header {
        color: currentColor;
        margin-left: -3px;

        & .tooltip-title {
            margin-left: 2px;
            font-weight: 800;
        }
    }

    & .tooltip-text {
        color: #363636;
        font-weight: 400;
    }
}
.certified-badge {
    min-width: 46px;
} 
.tooltip:hover .tooltip-container {
    visibility: visible;
}

@media screen and (max-width: 340px) {
    .certified-badge {
        position: absolute;
        right: 15%;
    }
}
</style>