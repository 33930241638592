import { SellFeeDTO } from '@/types';

export function removeListingTypeFromSellFeeDtoArray(fees: SellFeeDTO[]): SellFeeDTO[] {
  fees.forEach((fee) => {
    if (fee.listingType) {
      delete fee.listingType;
    }
  });

  return fees;
}