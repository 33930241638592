
import { computed, defineComponent, PropType, Ref, ref  } from 'vue';
import { useBreakpoint, useEditableFields, useFetchVehiclePhotos } from '../composables';
import { AdditionalPricingDTO, ImageDTO, InspectionConditionsDTO, SRPListing, TitleStatus, VehicleListingAnnouncement, VehicleListingAnnouncementCategorizedDTO, VehiclePhotosDTO } from '../types'; 
import { BBVehicleFeature } from '../types/bbVehicleFeature';
import { convertVehiclePhotosDtoToImageDtoArray, getNumAnnouncements } from '../utils';

import AppButton from '../components/AppButton.vue';
import AppListingDetails from '../components/AppListingDetails.vue';
import TheEditListingPhotosButton from '../components/TheEditListingPhotosButton.vue';
import TheListingDetailsAnnouncementsEditable from '../components/TheListingDetailsAnnouncementsEditable.vue';
import TheListingDetailsBookoutOptionsEditable from '../components/TheListingDetailsBookoutOptionsEditable.vue';
import TheListingDetailsPricingEditable from '../components/TheListingDetailsPricingEditable.vue';
import TheListingDetailsSellerDetailsEditable from '../components/TheListingDetailsSellerDetailsEditable.vue';
import TheListingDetailsVehicleAttributesEditable from '../components/TheListingDetailsVehicleAttributesEditable.vue';
import TheListingDetailsVehicleBasicsEditable from '../components/TheListingDetailsVehicleBasicsEditable.vue';

export default defineComponent({
    name: 'AppListingDetailsEditable',
    props: {
        isEditingMode: {
            type: Boolean,
            default: true,
        },
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
        editableSections: {
            type: Array as PropType<String[]>,
            required: false, // if undefined, all sections are editable
        },
        vehiclePhotos: {
            type: Array as PropType<ImageDTO[]>,
            required: false,
        },
        vehiclePhotosDTO: {
            type: Object as PropType<VehiclePhotosDTO>,
            required: false,
        },
        announcements: {
            type: Object as PropType<VehicleListingAnnouncementCategorizedDTO>,
            required: false,
        },
        bookoutOptions: {
            type: Array as PropType<BBVehicleFeature[]>,
            required: false,
        },
        titleStatus: {
            type: String as PropType<TitleStatus>,
            required: false,
        },
        sellerReason: {
            type: String,
            required: false,
        },
        additionalPricingDetails: {
            type: Object as PropType<AdditionalPricingDTO>,
            required: false,
        },
    },
    components: {
        AppButton,
        AppListingDetails,
        TheEditListingPhotosButton,
        TheListingDetailsAnnouncementsEditable,
        TheListingDetailsBookoutOptionsEditable,
        TheListingDetailsPricingEditable,
        TheListingDetailsSellerDetailsEditable,
        TheListingDetailsVehicleAttributesEditable,
        TheListingDetailsVehicleBasicsEditable,
    },
    emits: ['input', 'updateVehiclePhotos', 'addAnnouncement'],
    setup(props, context) {
        const vehicleKey = ref(0);

        function isSectionEditable(sectionName: string) {
            if (!props.editableSections) {
                return true;
            }
            return props.editableSections.includes(sectionName);
        }

        const { emitUpdates } = useEditableFields({ context });
        const { windowWidth } = useBreakpoint();

        function updatePhotosAfterSaving(event: VehiclePhotosDTO) {
            context.emit('updateVehiclePhotos', {
                vehiclePhotos: convertVehiclePhotosDtoToImageDtoArray(event),
                vehiclePhotosDTO: event,
            });
        }

        const displayVehicleListingPhotoId: Ref<number | undefined> = ref(undefined);
        function showAnnouncementPhoto(vehicleListingPhotoId: number) {
            displayVehicleListingPhotoId.value = vehicleListingPhotoId;
        }
        const numAnnouncements = computed(() => getNumAnnouncements(props.announcements));

        const {
            vehiclePhotos: vehiclePhotosUpdated,
            fetchVehiclePhotos, 
        } = useFetchVehiclePhotos(props.vehicleListingId, { immediate: false });

        return {
            isSectionEditable,
            updatePhotosAfterSaving,
            emitUpdates,
            windowWidth,
            vehicleKey,
            showAnnouncementPhoto,
            displayVehicleListingPhotoId,
            fetchVehiclePhotos,
            vehiclePhotosUpdated,
            numAnnouncements,
        }
    }
});
