import { render, staticRenderFns } from "./ComponentLoading.vue?vue&type=template&id=2a0391ee&scoped=true&"
import script from "./ComponentLoading.vue?vue&type=script&lang=ts&"
export * from "./ComponentLoading.vue?vue&type=script&lang=ts&"
import style0 from "./ComponentLoading.vue?vue&type=style&index=0&id=2a0391ee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0391ee",
  null
  
)

export default component.exports