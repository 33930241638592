
import { defineComponent, ref, Ref, onBeforeMount } from 'vue';
import { overdriveService } from '../service/overdrive.service';
import { getOverdriveDiscountLevels } from '../api';

import Modal from './Modal.vue';
import { toCurrency } from '../utils';

export default defineComponent({
    name: 'TheOverdriveDetailsModal',
    components: {
        Modal,
    },
    setup(props, { emit, attrs }) {
        const discounts: Ref<{
            levelOneDiscount: number,
            levelTwoDiscount: number,
            levelThreeDiscount: number,
        } | undefined>= ref(undefined);

        onBeforeMount(async() => {
            discounts.value = await getOverdriveDiscountLevels();
        });

        const { getOverdriveIconByLevel } = overdriveService;

        return {
            getOverdriveIconByLevel,
            discounts,
            toCurrency,
        }
    },
});
