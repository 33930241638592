
import { defineComponent, PropType, ref } from 'vue';

import AppButton from './AppButton.vue';
import { watch } from 'vue';

export default defineComponent({
    name: 'AppDropdown',
    props: {
        options: {
            type: Array,
            default: () => ([]),
        },
        optionLabelKey: {
            type: String,
            required: false,
        },
        optionValueKey: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        value: {
            type: [String, Number, Object, Array], // array for 'multiple' select
            required: false,
        }
    },
    emits: ['update:value', 'input', 'select'],
    components: {
        AppButton,
    },
    setup(props, { emit, attrs }) {
        const selectedOption = ref(props.value);
        watch(selectedOption, (newVal) => {
            emit('update:value', newVal);
            emit('input', newVal);
            emit('select', newVal);
        });
        
        function isOptionSelected(option: any) {
            if (props.optionValueKey) {
                return option[props.optionValueKey] === selectedOption.value;
            }
            return option === selectedOption.value;
        }

        return {
            isOptionSelected,
            selectedOption,
        }
    },
});
