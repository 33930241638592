
import { computed, ComputedRef, defineComponent, PropType, Ref, ref, watch } from 'vue';
import { AnnouncementCategory, AnnouncementCategoryKeys, AnnouncementSubcategory, AnnouncementType, ImageDTO, VehicleListingAnnouncement, VehicleListingAnnouncementCategorizedDTO, VehicleListingAnnouncementUpdate } from '../types';
import { openConfirmationDialog, openModal } from '@/utils';
import { omit } from 'lodash';
import { deleteAnnouncementById } from '@/api';
import { BModalComponent } from 'buefy/types/components';
import { useCreateAnnouncementForm, useUploadImage } from '@/composables';
import { VehicleListingPhotoTypeEnum } from '@/enums';
import { AttachmentDTO } from '@/types/attachment';

import AppButton from './AppButton.vue';
import TheListingDetailsAnnouncements from './TheListingDetailsAnnouncements.vue';
import TheCreateAnnouncementModal from './TheCreateAnnouncementModal.vue';

export default defineComponent({
    name: 'TheListingDetailsAnnouncementsEditable',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        numAnnouncements: {
            type: Number,
            required: false,
        },
        announcements: {
            type: Object as PropType<VehicleListingAnnouncementCategorizedDTO>,
            default: () => { return {} as VehicleListingAnnouncementCategorizedDTO },
        },
        isEditingMode: {
            type: Boolean,
            default: false,
        },
        vehicleListingPhotos: {
            type: Array as PropType<ImageDTO[]>,
            required: false,
        },
    },
    components: {
        AppButton,
        TheListingDetailsAnnouncements,
    },
    emits: ['viewPhoto', 'input', 'addAnnouncement', 'addPhotoToAnnouncement'],
    setup(props, context) {
        watch(() => props.vehicleListingPhotos, () => {
            if (announcementModal) {
                announcementModal.props.vehicleListingPhotos = props.vehicleListingPhotos;
                announcementModal.$forceUpdate();
            }
        }, { deep: true } );

        const updatedAnnouncements = ref(props.announcements);
        const announcementsKey = ref(0);
        
        const { addToUploadQueue, executeUploadQueue } = useUploadImage({
            context,
            showToasts: true,
        });
        
        const { 
            saveOrUpdateAnnouncement, 
            loadingSaveAnnouncement,
        } = useCreateAnnouncementForm({
            vehicleListingId: props.vehicleListingId,
            context,
        });

        let announcementModal: BModalComponent | null = null;
        function openCreateAnnouncementModal(announcement?: Partial<VehicleListingAnnouncement>, bulkAddCategories=false) {
            announcementModal = openModal({
                component: TheCreateAnnouncementModal,
                props: {
                    vehicleListingId: props.vehicleListingId,
                    vehicleListingPhotos: props.vehicleListingPhotos,
                    announcement,
                    bulkAddCategories,
                },
                events: {
                    saveAnnouncement({ announcement, uploadPhoto, vehicleListingPhotoType, prefillNextAnnouncement }: {
                        announcement: VehicleListingAnnouncementUpdate,
                        uploadPhoto: File & { file: AttachmentDTO },
                        vehicleListingPhotoType: keyof VehicleListingPhotoTypeEnum,
                        prefillNextAnnouncement?: {
                            announcementType: AnnouncementType,
                            category: AnnouncementCategory,
                            subcategory: AnnouncementSubcategory,
                        },
                    }) {
                        let saveAnnouncementsPayload: VehicleListingAnnouncementUpdate = announcement;

                        let imageUploadFilePath = `${props.vehicleListingId}/inspection/${vehicleListingPhotoType}`;
                        if (uploadPhoto) {
                            addToUploadQueue(uploadPhoto);
                        }
                        executeUploadQueue(imageUploadFilePath, {
                            onSuccess: (uploadedFiles) => {
                                if (uploadedFiles?.length) {
                                    saveAnnouncementsPayload.uploadPhoto = uploadedFiles[0];
                                    saveAnnouncementsPayload.vehicleListingPhotoTypeId = VehicleListingPhotoTypeEnum[vehicleListingPhotoType as keyof typeof VehicleListingPhotoTypeEnum];
                                }
                                saveOrUpdateAnnouncement(saveAnnouncementsPayload, {
                                    onSuccess: (allAnnouncements: VehicleListingAnnouncementCategorizedDTO) => {
                                        updatedAnnouncements.value = allAnnouncements;
                                        announcementsKey.value++;
                                        if (uploadedFiles?.length || announcement.vehicleListingPhotoId) {
                                            context.emit('addPhotoToAnnouncement', { announcement, vehicleListingPhotoType });
                                        }
                                    }
                                });
                            }
                        });

                        if (bulkAddCategories) {
                            unknownAnnouncementIdx++;
                            addUnknownCategories();
                        }
                        if (prefillNextAnnouncement) {
                            openCreateAnnouncementModal({
                                announcementType: prefillNextAnnouncement.announcementType,
                                categories: {
                                    categoryId: prefillNextAnnouncement.category.id,
                                    category: prefillNextAnnouncement.category.value,
                                    categoryDisplay: prefillNextAnnouncement.category.displayValue,
                                    subcategoryId: prefillNextAnnouncement.subcategory.id,
                                    subcategory: prefillNextAnnouncement.subcategory.value,
                                    subcategoryDisplay: prefillNextAnnouncement.subcategory.displayValue,
                                }
                            });
                        }
                    },
                },
            });
        }

        function openDeleteAnnouncementConfirmationDialog({ vehicleListingAnnouncementId, category }: { vehicleListingAnnouncementId: number, category: AnnouncementCategoryKeys }) {
            openConfirmationDialog({
                title: 'Delete announcement?',
                message: `This action cannot be undone.`, 
                onConfirm: () => deleteAnnouncement(vehicleListingAnnouncementId, category),
            });
        }

        function deleteAnnouncement(vehicleListingAnnouncementId: number, category: AnnouncementCategoryKeys) {
            let categoryAnnouncements = updatedAnnouncements.value[category];
            if (!categoryAnnouncements?.length) {
                return;
            }
            let deleteIdx = categoryAnnouncements.findIndex(announcement => announcement.vehicleListingAnnouncementId == vehicleListingAnnouncementId);
            
            updatedAnnouncements.value[category]!.splice(deleteIdx, 1);
            deleteAnnouncementById(vehicleListingAnnouncementId);
        }

        const hasUnknownAnnouncements = computed(() => Boolean((updatedAnnouncements.value as VehicleListingAnnouncementCategorizedDTO).unknown?.length));
        const announcementsWithCategory: ComputedRef<VehicleListingAnnouncementCategorizedDTO> = computed(() => omit(updatedAnnouncements.value, 'unknown'));

        let unknownAnnouncementIdx = 0;
        function addUnknownCategories() {
            if (!(props.announcements as VehicleListingAnnouncementCategorizedDTO).unknown?.length || unknownAnnouncementIdx >= (props.announcements?.unknown?.length ?? 0)) {
                return;
            }
            if (announcementModal) {
                announcementModal.close();
            }
            let nextUnknownAnnouncement = ((props.announcements as VehicleListingAnnouncementCategorizedDTO).unknown as VehicleListingAnnouncement[])[unknownAnnouncementIdx];
            openCreateAnnouncementModal(nextUnknownAnnouncement, true);
        }

        return {
            updatedAnnouncements,
            openCreateAnnouncementModal,
            openDeleteAnnouncementConfirmationDialog,
            hasUnknownAnnouncements,
            announcementsWithCategory,
            addUnknownCategories,
            announcementsKey,
        }
    },
});
