import { onBeforeUnmount, onMounted, ref, Ref } from 'vue';

export function useKeyboardGalleryNavigation({ emit, onToggleGalleryView }: {
    emit: (event: 'toggleGalleryView' | 'increment', value?: any) => void,
    onToggleGalleryView: (isGalleryView: boolean) => void,
}) {
    onMounted(() => connectKeyboardEventListener());
    onBeforeUnmount(() => disconnectKeyboardEventListener());

    function connectKeyboardEventListener() {
        document.addEventListener('keydown', handleKeyboardNavigation);
    }

    function disconnectKeyboardEventListener() {
        document.removeEventListener('keydown', handleKeyboardNavigation);
    }

    function isModalOpen() {
        return document.querySelectorAll('.modal:not(.vdp-modal)')?.length;
    }

    function handleKeyboardNavigation(event: KeyboardEvent) {
        if (!event?.key) {
            return;
        }
        if (isModalOpen()) {
            return;
        }
        
        if (['ArrowLeft', 'ArrowRight', 'Escape'].includes(event.key)) {
            event.preventDefault();
            event.stopPropagation();
        }
        
        switch(event.key) {
            case 'ArrowLeft':
                emit('increment', -1);
                break;
            case 'ArrowRight':
                emit('increment', 1);
                break;
            case 'Enter':
                onToggleGalleryView(true);
                break;
            case 'Escape':
                onToggleGalleryView(false);
                break;
        }
    }
}

export function useImageMagnifyingGlass({ zoomImageUrl, enableZoom=true, zoomFactor=2, imageId, height=200, width=200, useTouchEvents=false }: {
    zoomImageUrl?: string,
    enableZoom?: boolean,
    imageId?: string,
    zoomFactor?: number,
    height?: number,
    width?: number,
    useTouchEvents?: boolean,
}) {
    // reference: https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp
    const removeEventListeners: Ref<() => void> = ref(() => {});
    function addMagnifyingGlass() {
        if (!enableZoom) {
            return;
        }
        let zoom = zoomFactor;
        
        // get the image to magnify
        let image = document.querySelector(`#${imageId}`) as HTMLImageElement;
        if (!image) {
            return;
        }

        // check for existing magnifying glass element
        let magnifyingGlass = document.querySelector(`#${imageId}-magnifying-glass`) as HTMLElement;
        if (magnifyingGlass?.style.display == 'none') {
            magnifyingGlass.style.display = 'block';
        } else if (!magnifyingGlass) {
            // create magnifying glass element if DNE
            magnifyingGlass = document.createElement('div');
            magnifyingGlass.setAttribute('class', 'magnifying-glass');
            magnifyingGlass.setAttribute('id', `${imageId}-magnifying-glass`);
            magnifyingGlass.style.position = 'absolute';
            magnifyingGlass.style.width = `${height}px`;
            magnifyingGlass.style.height = `${width}px`;
            magnifyingGlass.style.cursor = 'none';
            magnifyingGlass.style.border = '1px solid black';
            magnifyingGlass.style.zIndex = '100';
            image.parentElement?.insertBefore(magnifyingGlass, image);
        }

        // set magnifying glass background image
        magnifyingGlass.style.backgroundImage = `url(${zoomImageUrl ?? image.src})`;;
        magnifyingGlass.style.backgroundRepeat = 'no-repeat';
        magnifyingGlass.style.backgroundSize = `${image.width * zoom}px ${image.height * zoom}px`;

        let borderOffset = 3;
        let magnifyingGlassCenterWidth = magnifyingGlass.offsetWidth/2;
        let magnifyingGlassCenterHeight = magnifyingGlass.offsetHeight/2;

        // handle movement of magnifier glass
        magnifyingGlass.addEventListener('mousemove', moveMagnifier);
        image.addEventListener('mousemove', moveMagnifier);
        if (useTouchEvents) {
            magnifyingGlass.addEventListener('touchmove', moveMagnifier);
            image.addEventListener('touchmove', moveMagnifier);
        }

        // set removeEventListeners value for mouseleave
        removeEventListeners.value = () => {
            magnifyingGlass.removeEventListener('mousemove', moveMagnifier);
            image.removeEventListener('mousemove', moveMagnifier);
            magnifyingGlass.removeEventListener('touchmove', moveMagnifier);
            image.removeEventListener('touchmove', moveMagnifier);
        }

        function moveMagnifier(event: MouseEvent | TouchEvent) {
            event.preventDefault();

            // get cursor position
            let { x: xPosition, y: yPosition } = getCursorPosition(event);

            // prevent magnifier glass from being positioned outside the image
            if (xPosition > image.width - (magnifyingGlassCenterWidth/zoom)) {
                xPosition = image.width - (magnifyingGlassCenterWidth/zoom);
            }
            if (xPosition < (magnifyingGlassCenterWidth / zoom)) {
                xPosition = (magnifyingGlassCenterWidth / zoom);
            }
            if (yPosition > image.height - (magnifyingGlassCenterHeight / zoom)) {
                yPosition = image.height - (magnifyingGlassCenterHeight / zoom);
            }
            if (yPosition < (magnifyingGlassCenterHeight / zoom)) {
                yPosition = (magnifyingGlassCenterHeight / zoom);
            }

            // set magnifying glass position
            magnifyingGlass.style.left = `${xPosition - magnifyingGlassCenterWidth}px`;
            magnifyingGlass.style.top = `${yPosition - magnifyingGlassCenterHeight}px`;

            // set magnifier display
            magnifyingGlass.style.backgroundPosition = "-" + ((xPosition * zoom) - magnifyingGlassCenterWidth + borderOffset) + "px -" + ((yPosition * zoom) - magnifyingGlassCenterHeight + borderOffset) + "px";
        }

        function getCursorPosition(event: MouseEvent | TouchEvent) {
            let x = 0, y = 0;
            let mouseEvent = (event || window.event) as MouseEvent;

            let imgRect = image.getBoundingClientRect();
        
            // calculate cursor's x and y coordinates, relative to the image
            x = mouseEvent.pageX - imgRect.left;
            y = mouseEvent.pageY - imgRect.top;

            // handle page scrolling
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return { x, y };
        }
    }

    function hideMagnifyingGlass(event?: MouseEvent) {
        removeEventListeners.value();
        let magnifyingGlass = document.querySelector(`#${imageId}-magnifying-glass`) as HTMLElement;
        if (magnifyingGlass) {
            magnifyingGlass.style.display = 'none';
        }
    }

    onBeforeUnmount(() => {
        hideMagnifyingGlass();
    });

    return {
        addMagnifyingGlass,
        hideMagnifyingGlass,
    }
}

export function useWatchPinchZoomEvent(id: string, { emit }: {
    emit: (event: 'pinchZoom', value: boolean) => void,
}) {
const pinchZoomWatcherTimer = setInterval(watchForPinchEvent, 200);

    const isZoomedIn: Ref<boolean> = ref(false);
    function watchForPinchEvent() {
        let pinchZoomContent = document.querySelector(`#${id} .pinch-zoom-content`) as HTMLElement;

        if (pinchZoomContent) {
            // there is no pinch event from vue-pinch-zoom, so we have to check the transform style to tell if it's zoomed
            let isPinched = Boolean(pinchZoomContent.style?.transform) && (pinchZoomContent.style?.transform !== 'matrix(1, 0, 0, 1, 0, 0)');
            if (isPinched !== isZoomedIn.value) {
                isZoomedIn.value = isPinched;
                emit('pinchZoom', isZoomedIn.value);
            }
        }
    }

    onBeforeUnmount(() => {
        clearInterval(pinchZoomWatcherTimer);
    });

    return {
        isZoomedIn,
    }
}

// this is adapted from Buefy's carousel drag events
// reference: https://github.com/buefy/buefy/blob/dev/src/components/carousel/Carousel.vue
export function useDragNavigation({ onDragForward, onDragBackward }: {
    onDragForward: () => void,
    onDragBackward: () => void,
}) {
    const dragX: Ref<number | undefined> = ref(undefined);
    function dragStart(event: MouseEvent | TouchEvent) {
        dragX.value = (event as TouchEvent).touches ? (event as TouchEvent).changedTouches[0].pageX : (event as MouseEvent).pageX;
        if (!(event as TouchEvent).touches) {
            event.preventDefault();
        }
    }

    function dragEnd(event: MouseEvent | TouchEvent) {
        if (dragX.value == undefined) {
            return;
        }

        const detected = (event as TouchEvent).touches ? (event as TouchEvent).changedTouches[0].pageX : (event as MouseEvent).pageX;
        const diffX = detected - (dragX.value ?? 0);
        if (Math.abs(diffX) > 30) {
            diffX < 0 
                ? onDragForward()
                : onDragBackward();
        } 
        dragX.value = undefined;
    }

    return {
        dragStart,
        dragEnd,
    }
}