
import FastBookIcon from '@/assets/fastbook.webp';
import { defineComponent } from 'vue';
import { copyText } from '@/utils';
import store from '@/vuex';
import AppImage from './AppImage.vue';

export default defineComponent({
    name: 'AppFastBookDropdown',
    props: {
        vin: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        function bookSelectedVinInNewWindow() {
            window.open(`https://www.dealerslink.com/network/fastbook/valuator/index.cfm?vin=${props.vin}`, '_blank');
        }

        function bookSelectedVinInSidePanel() {
            store.commit('setFastBookBookingPanel', { isOpen: true, selectedVin: props.vin });
        }

        function copyVINToClipboard() {
            copyText(props.vin);
        }

        const dropdownItems = [
            { text: 'Copy VIN', action: copyVINToClipboard },
            { text: 'Open New Tab', action: bookSelectedVinInNewWindow },
            { text: 'Book It', action: bookSelectedVinInSidePanel },
        ];

        return {
            copyText,
            bookSelectedVinInNewWindow,
            bookSelectedVinInSidePanel,
            FastBookIcon,
            dropdownItems,
        };
    },
    components: { AppImage },
});
