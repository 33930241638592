
import { defineComponent, onBeforeMount } from 'vue';
import { usePublicVDP } from '@/composables';

import AppListingDetails from '@/components/AppListingDetails.vue';

export default defineComponent({
    name: 'AppListingDetailsPublic',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
    },
    components: {
        AppListingDetails,
    },
    setup(props, context) {
        const {
            listing,
            loadingListing,
            getListingForPublicVDP,
            vehiclePhotos,
            loadingVehiclePhotos,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
        } = usePublicVDP({
            vehicleListingId: props.vehicleListingId,
        });

        onBeforeMount(getListingForPublicVDP);

        return {
            vehiclePhotos,
            loadingVehiclePhotos,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            listing,
            loadingListing,
        }
    }
})
