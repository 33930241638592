var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'flex-row justify-space-between align-center': _vm.displayType == 'inspector',
    }},[_c('div',{staticClass:"flex-column"},[(_vm.listingType === 'auction' && _vm.showFields.includes('startingPrice'))?[(_vm.editable)?_c('EditableField',_vm._b({staticClass:"mb-3",attrs:{"id":"starting-price-input"},on:{"input":updatedValue => _vm.emitUpdatedValues('startingPrice', _vm.startingPriceUpdatedInt)},model:{value:(_vm.startingPriceUpdated),callback:function ($$v) {_vm.startingPriceUpdated=$$v},expression:"startingPriceUpdated"}},'EditableField',{
                    ..._vm.$attrs,
                    label: 'Starting price',
                    inputType: 'currency',
                    customInputHandler: true,
                    ..._vm.getValidationAttrs('startingPrice'),
                },false)):_c('div',{staticClass:"preview-pricing"},[_c('h4',[_vm._v("Starting price")]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(_vm.startingPriceUpdated)))])])]:_vm._e(),(_vm.showFields.includes('reservePrice'))?_c('div',[(_vm.editable)?_c('EditableField',_vm._b({on:{"input":updatedValue => _vm.emitUpdatedValues('reservePrice', _vm.reservePriceUpdatedInt)},model:{value:(_vm.reservePriceUpdated),callback:function ($$v) {_vm.reservePriceUpdated=$$v},expression:"reservePriceUpdated"}},'EditableField',{
                    ..._vm.getAttrs('reservePrice'),
                    label: _vm.reservePriceLabel,
                    inputType: 'currency',
                    customInputHandler: true,
                    ..._vm.getValidationAttrs('reservePrice'),
                },false)):_c('div',{staticClass:"preview-pricing"},[_c('h4',[_vm._v(_vm._s(_vm.reservePriceLabel))]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(_vm.reservePriceUpdated)))])])],1):_vm._e(),(_vm.showFields.includes('buyItNowPrice'))?_c('div',[(_vm.editable)?_c('div',{staticClass:"my-3",class:{ 'is-missing': _vm.isInspectionFieldInvalid(_vm.invalidFields, 'canBuyItNow') }},[(_vm.displayType == 'inspector')?_c('div',{staticClass:"flex-column"},[_c('b-radio',_vm._b({attrs:{"name":"canBuyItNow","native-value":true},model:{value:(_vm.canBuyItNowUpdated),callback:function ($$v) {_vm.canBuyItNowUpdated=$$v},expression:"canBuyItNowUpdated"}},'b-radio',_vm.$attrs,false),[_vm._v(" Buy It Now enabled ")]),_c('b-radio',_vm._b({attrs:{"name":"canBuyItNow","native-value":false},model:{value:(_vm.canBuyItNowUpdated),callback:function ($$v) {_vm.canBuyItNowUpdated=$$v},expression:"canBuyItNowUpdated"}},'b-radio',_vm.$attrs,false),[_vm._v(" Buy It Now disabled ")])],1):_c('div',[(_vm.isBuyItNowOptional)?_c('b-switch',_vm._b({model:{value:(_vm.canBuyItNowUpdated),callback:function ($$v) {_vm.canBuyItNowUpdated=$$v},expression:"canBuyItNowUpdated"}},'b-switch',_vm.$attrs,false),[_vm._v(" Buy It Now available? ")]):_vm._e()],1)]):_vm._e(),(_vm.editable)?_c('EditableField',_vm._b({key:`buy-it-now-${_vm.buyItNowPriceKey}`,class:{'mt-3': _vm.isBuyItNowOptional},attrs:{"id":"buy-it-now-price-input"},on:{"input":updatedValue => _vm.emitUpdatedValues('buyItNowPrice', _vm.buyItNowPriceUpdatedInt)},model:{value:(_vm.buyItNowPriceUpdated),callback:function ($$v) {_vm.buyItNowPriceUpdated=$$v},expression:"buyItNowPriceUpdated"}},'EditableField',{
                    ..._vm.$attrs,
                    label: 'Buy It Now price',
                    inputType: 'currency',
                    disabled: !_vm.canBuyItNowUpdated && _vm.isBuyItNowOptional,
                    customInputHandler: true,
                    ..._vm.getValidationAttrs('buyItNowPrice'),
                    isMissing: _vm.invalidBuyItNowPrice || _vm.isInspectionFieldInvalid(_vm.invalidFields, 'buyItNowPrice'),
                },false)):_c('div',{staticClass:"flex-column",class:_vm.canBuyItNowUpdated ? 'mt-2' : ''},[_c('div',{staticClass:"preview-pricing"},[_c('h4',[_vm._v("Buy It Now")]),_c('p',[_vm._v(_vm._s(_vm.canBuyItNowUpdated ? 'Enabled' : 'Disabled'))])]),(_vm.canBuyItNowUpdated)?_c('div',{staticClass:"preview-pricing"},[_c('h4',[_vm._v("Buy It Now Price")]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(_vm.buyItNowPriceUpdated)))])]):_vm._e()]),(_vm.invalidBuyItNowPrice)?_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(_vm.getInvalidBuyItNowPriceReason)+" ")]):_vm._e()],1):_vm._e()],2),(_vm.editable && _vm.displayType !== 'seller' && _vm.showFields.includes('carmigoCertified'))?_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"margin-auto flex-row align-center"},[(_vm.editable)?_c('div',{staticClass:"flex-column align-center"},[_c('b-field',{staticClass:"mt-1"},[_c('b-checkbox',_vm._b({on:{"input":updatedValue => _vm.$emit('input', { carmigoCertified: updatedValue })},model:{value:(_vm.carmigoCertifiedUpdated),callback:function ($$v) {_vm.carmigoCertifiedUpdated=$$v},expression:"carmigoCertifiedUpdated"}},'b-checkbox',_vm.$attrs,false))],1)],1):_vm._e(),_c('AuctionCertifiedBadge',{staticClass:"certified-badge",class:{ 'opacity-50': !_vm.carmigoCertifiedUpdated },attrs:{"showLargeTooltip":false,"showDefaultTooltip":true}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }