import { OverdriveRefRecord } from '@/types';
import { computed, reactive, Ref, ref, watch } from 'vue';
import { useRdbListener } from './firebase';
import { playAudioCue } from '@/utils';
import { overdriveService } from '@/service/overdrive.service';

import TheApproachingOverdriveProgressBar from '../components/TheApproachingOverdriveProgressBar.vue';
import TheCompleteOverdriveProgressBar from '../components/TheCompleteOverdriveProgressBar.vue';
import TheEngagedOverdriveProgressBar from '../components/TheEngagedOverdriveProgressBar.vue';
import TheFailedOverdriveText from '../components/TheFailedOverdriveText.vue';

export function useOverdriveListener() {
    useRdbListener<OverdriveRefRecord>('global/overdrive', onSnapshot);

    const overdrive: Partial<OverdriveRefRecord>= reactive({
        currentBidCount: undefined,
        currentGoalBidCount: undefined,
        currentDiscount: undefined,
        currentLevel: undefined,
        upcomingLevel: undefined,
        upcomingDiscount: undefined,
        isApproachingOverdriveActive: undefined,
        isOverdriveActive: undefined,
        isOverdriveCompleted: undefined,
        disengageOverdriveDate: undefined,
    });

    function onSnapshot({ isInitialSnapshot, oldSnapshotValue, newSnapshotValue }: {
        isInitialSnapshot: boolean,
        oldSnapshotValue: OverdriveRefRecord | null,
        newSnapshotValue: OverdriveRefRecord | null,
    }) {
        if (!newSnapshotValue) {
            return;
        }

        overdrive.isApproachingOverdriveActive = newSnapshotValue.isApproachingOverdriveActive;
        overdrive.isOverdriveActive = newSnapshotValue.isOverdriveActive;
        overdrive.isOverdriveCompleted = newSnapshotValue.isOverdriveCompleted;
        overdrive.disengageOverdriveDate = newSnapshotValue.disengageOverdriveDate;
        overdrive.currentBidCount = newSnapshotValue.currentBidCount;
        overdrive.currentGoalBidCount = newSnapshotValue.currentGoalBidCount;
        overdrive.currentLevel = newSnapshotValue.currentLevel;
        overdrive.currentDiscount = newSnapshotValue.currentDiscount;
        overdrive.upcomingLevel = newSnapshotValue.upcomingLevel;
        overdrive.upcomingDiscount = newSnapshotValue.upcomingDiscount;
    }

    return {
        overdrive,
    }
}

export function useOverdriveDisplay(overdrive: Partial<OverdriveRefRecord>) {
    const levelFailed = ref(false);
    const allLevelsDisengaged = computed(() => !overdrive.isApproachingOverdriveActive && !overdrive.isOverdriveActive && !overdrive.isOverdriveCompleted);
    const displayFailedOverdriveText = computed(() => levelFailed.value || (allLevelsDisengaged.value && overdrive.currentDiscount));
    const transitionType = computed(() => levelFailed.value ? 'fade' : 'slide-fade');
    const failedTextBottomTransitionDelay = computed(() => levelFailed.value ? '1s' : '0');

    // displayComponent 
    const overdriveComponent = computed(() => {
        if (overdrive.isOverdriveCompleted) {
            return TheCompleteOverdriveProgressBar;
        }
        if (overdrive.isOverdriveActive) {
            return TheEngagedOverdriveProgressBar;
        }
        if (overdrive.isApproachingOverdriveActive) {
            return TheApproachingOverdriveProgressBar;
        }
        if (displayFailedOverdriveText.value) {
            return TheFailedOverdriveText;
        }
        return undefined;
    });

    const { wasFalseAndIsTrue } = overdriveService;
    const shouldPlayAudioCue = ref(false);
    watch(() => overdrive.isApproachingOverdriveActive, (isApproaching, wasApproaching) => {
        levelFailed.value = Boolean(wasApproaching && allLevelsDisengaged.value);
        shouldPlayAudioCue.value = wasFalseAndIsTrue(isApproaching, wasApproaching);
    });

    watch(() => overdrive.isOverdriveActive, (isEngaged, wasEngaged) => {
        levelFailed.value = Boolean(wasEngaged && allLevelsDisengaged.value);
        shouldPlayAudioCue.value = wasFalseAndIsTrue(isEngaged, wasEngaged);
    });

    const throwConfettiOnCompleteOverdrive = ref(false);
    watch(() => overdrive.isOverdriveCompleted, (isComplete, wasComplete) => {
        throwConfettiOnCompleteOverdrive.value = wasFalseAndIsTrue(isComplete, wasComplete);
    });

    const displayComponent = ref(overdriveComponent.value);
    watch(overdriveComponent, (newComponent, oldComponent) => {
        if (newComponent?.name == 'TheEngagedOverdriveProgressBar' && oldComponent) {
            playAudioCue('overdrive-engaged', { volume: 1 });
            window.setTimeout(() => displayComponent.value = newComponent, 500);
        } else if (newComponent?.name == 'TheFailedOverdriveText' && levelFailed.value) {
            displayComponent.value = newComponent;
            window.setTimeout(() => {
                playAudioCue('overdrive-failed', { volume: 1 })
            }, 1000);
        } else if (newComponent !== oldComponent) {
            // delay updating display component so we can update the transition type
            window.setTimeout(() => {
                displayComponent.value = newComponent;
            }, 200)
        }
    });

    /** Display the current level (with a delay and audio cue if levelling up) */
    const displayLevel = ref(overdrive.currentLevel);
    const levelKey = ref(0);
    watch(() => overdrive.currentLevel, (newLevel, oldLevel) => {
        if (oldLevel && newLevel && newLevel > oldLevel) {
            playAudioCue('overdrive-level-up', { volume: 1 });
            window.setTimeout(() => {
                // re-render key is to ensure a transition plays between levels, even though they're the same component (TheEngagedOverdriveProgressBar)
                levelKey.value++;
                displayLevel.value = overdrive.currentLevel;
            }, 500);
        } else {
            displayLevel.value = overdrive.currentLevel
        }
    });

    return {
        displayComponent,
        transitionType,
        throwConfettiOnCompleteOverdrive,
        displayFailedOverdriveText,
        displayLevel,
        levelKey,
        shouldPlayAudioCue,
        failedTextBottomTransitionDelay,
    }
}
