
import { defineComponent } from 'vue';
import { useGetAndSaveCheckoutData } from '../composables';
import { CheckoutFormStep } from '../types';
import { getStoreFacilitatedCheckoutServiceMessage } from '../utils';

import Loading from './Loading.vue';
import TheCheckoutModal from './TheCheckoutModal.vue';
import TheCheckoutPaymentForm from './TheCheckoutPaymentForm.vue';

export default defineComponent({
    name: 'TheCheckoutModalFacilitatingAuction',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
        storeName: {
            type: String,
            required: true,
        },
        vehicleName: {
            type: String,
            required: false,
        },
        vehiclePrice: {
            type: Number,
            required: false,
        },
    },
    components: {
        Loading,
        TheCheckoutModal,
        TheCheckoutPaymentForm,
    },
    emits: ['submitted', 'close'],
    setup(props, context) {
        let displayCheckoutSteps: CheckoutFormStep[] = ['transportation', 'arbitrations', 'title', 'orderSummary'];
        const { checkoutSteps: carmigoFacilitatedCheckoutServices, checkoutData, isLoadingCheckoutData, isLoadingSubmitCheckout, formatAndSubmitCheckout } = useGetAndSaveCheckoutData({ 
            isFacilitatingAuction: true,
            vehicleListingId: props.vehicleListingId, 
            marketplaceListingId: props.marketplaceListingId, 
            context
        });

        function isCarmigoFacilitating(service: CheckoutFormStep) {
            return carmigoFacilitatedCheckoutServices.value.includes(service);
        }

        return {
            displayCheckoutSteps,
            carmigoFacilitatedCheckoutServices,
            checkoutData,
            isLoadingCheckoutData,
            formatAndSubmitCheckout,
            isLoadingSubmitCheckout,
            isCarmigoFacilitating,
            getStoreFacilitatedCheckoutServiceMessage,
        }
    },
});
