
import Modal from './Modal.vue';
import EditableGallery from './Forms/EditableGallery.vue';
import InspectionForm from '../views/Inspector/InspectionForm.vue';
import { PropType } from 'vue';
import { VehiclePhotosSimple } from '../types';
import breakpointMixin from '@/mixins/breakpoint';

const __default__ = {
    name: 'EditPhotosModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: false,
        },
        photos: {
            type: Object as PropType<VehiclePhotosSimple>, 
            required: false,
        }
    },
    components: {
        Modal,
        EditableGallery,
        InspectionForm,
    },
    mixins: [ breakpointMixin ],
    data() {
        return {
            photosCopy: this.photos,
            photosUpdated: [],
            hasChanges: false,
            photosKey: 0,
            jumpToList: [],
            uploadingPhotos: false,
            windowWidth: window.innerWidth as number,
            numColumns: 3,
            hasOpenDropdown: false,
        }
    },
    mounted() {
        if (this.windowWidth <= 675) {
            this.numColumns = 2;
        }
        // add jump to menu
        Object.keys(this.photos).forEach(sectionName => {
            this.jumpToList.push({
                sectionId: `${sectionName}-photos`,
                tooltipText: sectionName,
                icon: 'car-side'
            });
        });
    },
    watch: {
        windowWidth: function() {
            this.numColumns = this.windowWidth <= 675 ? 2 : 3;
        }
    },
    methods: {
        getMoveToSectionOptions(displaySection: 'exterior' | 'interior' | 'damage') {
            let moveToSections = ['Exterior', 'Interior', 'Damage'];
            return moveToSections.filter(moveToSection => moveToSection.toLowerCase() !== displaySection);
        },
        resetGallery() {
            this.photosUpdated = [];
            this.hasChanges = false;
            this.photosKey++;
        },
        updateAfterSavingInspection(savedValues, updateType) { 
            this.photosCopy = {
                exterior: savedValues.exterior,
                interior: savedValues.interior,
                damage: savedValues.damage,
            };
            this.resetGallery();
            this.$emit('savedEdits', savedValues, updateType); 
        },

        async saveChanges(updateInspectionData, saveData) {
            const photos = {
                damage: { images: [] },
                exterior: { images: [] },
                interior: { images: [] }
            };

            // move photos to new section
            Object.keys(this.photosUpdated).forEach(section => {
                this.photosUpdated[section].forEach(photo => {
                    if (photo.moveTo) {
                        this.movePhoto(photo.moveTo, photo);
                    }
                })
            })

            // remove deleted or moved photos
            Object.keys(this.photosUpdated).forEach(section => {
                let updatedPhotos = this.photosUpdated[section].filter(photo => !photo.delete && !photo.moveTo);
                photos[section]['images'] = updatedPhotos.map(photo => photo.image);
            })

            updateInspectionData({ ...photos });
            this.$emit('saved', photos);
            await saveData();
        },
        updatePhotos(section, newPhotos, hasChanges) {
            this.uploadingPhotos = false;
            this.hasChanges = hasChanges;
            this.photosUpdated[section] = newPhotos;
        },
        movePhoto(moveTo: string, image) {
            if (!moveTo) {
                return;
            }
            let newSectionName = moveTo.toLowerCase();
            const isDuplicate = this.photosUpdated[newSectionName].some(existingImage => existingImage.url == image.image.url);
            if (!isDuplicate) {
                let newSection = this.photosUpdated[newSectionName];
                newSection.push({
                    ...image,
                    moveTo: undefined,
                    delete: false,
                    order: newSection.length
                });
            }
        }
    },
    computed: {
        isStickyHeader() {
            return !this.hasOpenDropdown || (this.hasOpenDropdown && !this.bulmaBreakpoint.mobile);
        },
        stickyBackgroundZIndex() {
            // 100 is the default z-index of .sticky-background.
            // 40 is less than the dropdown z-index.
            return this.isStickyHeader ? 100 : 40;
        }
    }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "306499bc": (_vm.stickyBackgroundZIndex)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__