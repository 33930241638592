import { DealerInventoryDTO, FilterSchema, Negotiation } from '@/types';
import { ModalProgrammatic } from 'buefy';
import Negotations from '@/components/Negotiations.vue';
import Vue from 'vue';
import { PUT } from '@/api';
import { openErrorDialog, openToast, toCurrency } from './index';
import { openModal } from './buefyUtils';
import router from '../router';

import TheNegotiationModalAdmin from '../components/TheNegotiationModalAdmin.vue';

export interface Vehicle extends DealerInventoryDTO {
  negotiations: Negotiation[];
  lastNegotiation: NonNullable<DealerInventoryDTO['lastNegotiation']>;
  highestProxyBidAmount: number;
  highestBidAmount: number;
}

async function saveNegotiation({
  vehicle,
  toastMessage,
}: {
  vehicle: Vehicle,
  toastMessage: string
}) {
  const { lastNegotiation, vehicleListingId } = vehicle;

  try {
    await PUT(`vehicles/negotiating/${vehicleListingId}`, {
      buyerId: lastNegotiation.buyerId.toString(),
      sellerId: lastNegotiation.sellerId,
      highestBuyerOffer: lastNegotiation.highestBuyerOffer,
      lowestSellerOffer: lastNegotiation.lowestSellerOffer,
      onBuyer: lastNegotiation.onBuyer,
      status: lastNegotiation.status,
    });
    openToast('is-success', toastMessage);
    return vehicle;
  } catch (error) {
    openErrorDialog({
      title: 'Error saving negotiation',
      message: 'We encountered an error while updating negotiaton data.',
      error,
      displayErrorInDialog: true,
    });
  }
}

function setBuyerIdToHighestBidderId(vehicle: Vehicle) {
  if (
    vehicle.lastNegotiation
    && vehicle.highestBidderId
    && vehicle.lastNegotiation.buyerId !== vehicle.highestBidderId.toString()
  ) {
    return vehicle.highestBidderId.toString();
  }

  return vehicle.lastNegotiation!.buyerId;
}

function acceptNegotiationOffer(vehicle: Vehicle) {
  // We want to clone the vehicle object to prevent mutating properties inside components
  const updatedVehicle = { ...vehicle };

  const { lastNegotiation } = updatedVehicle;

  lastNegotiation.buyerId = setBuyerIdToHighestBidderId(vehicle);

  if (lastNegotiation.onBuyer) {
    lastNegotiation.highestBuyerOffer = lastNegotiation.lowestSellerOffer;
  }
  if (!lastNegotiation.onBuyer) {
    lastNegotiation.lowestSellerOffer = lastNegotiation.highestBuyerOffer;
  }

  lastNegotiation.status = 'accepted';

  // Flip onBuyer value because the backend treats onBuyer == true to be an offer from the seller and vice versa
  lastNegotiation.onBuyer = !lastNegotiation.onBuyer;

  return saveNegotiation({
    vehicle: updatedVehicle,
    toastMessage: 'Accepted offer!',
  });
}

function rejectNegotiationOffer(vehicle: Vehicle) {
  // We want to clone the vehicle object to prevent mutating properties inside components
  const updatedVehicle = { ...vehicle };

  const { lastNegotiation } = updatedVehicle;

  lastNegotiation.buyerId = setBuyerIdToHighestBidderId(vehicle);

  lastNegotiation.status = 'rejected';

  // Flip onBuyer value because the backend treats onBuyer == true to be an offer from the seller and vice versa
  lastNegotiation.onBuyer = !lastNegotiation.onBuyer;

  const value = lastNegotiation.onBuyer ? lastNegotiation.lowestSellerOffer : lastNegotiation.highestBuyerOffer;
  const toastMessage = `Your final offer of ${toCurrency(value)} was sent to the ${lastNegotiation.onBuyer ? 'buyer' : 'seller'} to approve or reject`;

  return saveNegotiation({
    vehicle: updatedVehicle,
    toastMessage,
  });
}

function confirmRejectNegotiationOffer(vehicle: Vehicle) {
  // We want to clone the vehicle object to prevent mutating properties inside components
  const updatedVehicle = { ...vehicle };
  const { lastNegotiation } = updatedVehicle;

  lastNegotiation.status = 'rejected';

  return saveNegotiation({
    vehicle: updatedVehicle,
    toastMessage: 'Rejected offer!',
  });
}

interface CounterOffer {
  counterOffer: number;
  disableNotifications: boolean;
}

function counterNegotiationOffer(vehicle: Vehicle, counterOffer: CounterOffer) {
  // We want to clone the vehicle object to prevent mutating properties inside components
  const updatedVehicle = { ...vehicle };
  const { lastNegotiation } = updatedVehicle;

  if (lastNegotiation.onBuyer) {
    lastNegotiation.highestBuyerOffer = counterOffer.counterOffer;
    lastNegotiation.buyerId = setBuyerIdToHighestBidderId(vehicle);
  } else {
    lastNegotiation.lowestSellerOffer = counterOffer.counterOffer;
  }

  // Flip onBuyer value because the backend treats onBuyer == true to be an offer from the seller and vice versa
  lastNegotiation.onBuyer = !lastNegotiation.onBuyer;

  updatedVehicle.highestBidAmount = lastNegotiation.highestBuyerOffer;

  return saveNegotiation({
    vehicle: updatedVehicle,
    toastMessage: 'Counter offer sent!',
  });
}

export function createNegotiationModal(parent: Vue, vehicle: Vehicle): Promise<Vehicle | undefined> {
  return new Promise((resolve) => {
    ModalProgrammatic.open({
      parent,
      props: {
        negotiationData: vehicle.lastNegotiation,
        firstNegotiation: vehicle.negotiations?.length === 1,
        highestProxyBid: vehicle.highestProxyBidAmount,
        vin: vehicle.vin,
      },
      component: Negotations,
      hasModalCard: true,
      trapFocus: true,
      canCancel: ['escape', 'outside'],
      events: {
        accept: async () => {
          const updatedVehicle = await acceptNegotiationOffer(vehicle);
          resolve(updatedVehicle);
        },
        reject: async () => {
          const updatedVehicle = await rejectNegotiationOffer(vehicle);
          resolve(updatedVehicle);
        },
        rejectConfirm: async () => {
          const updatedVehicle = await confirmRejectNegotiationOffer(vehicle);
          resolve(updatedVehicle);
        },
        counter: async (counterOffer: CounterOffer) => {
          const updatedVehicle = await counterNegotiationOffer(vehicle, counterOffer);
          resolve(updatedVehicle);
        },
      },
    });
  });
}

export function openAdminNegotiationModal(
  props: {
    vehicleListingId: number,
    buyerOfferAmount: number,
    sellerOfferAmount: number,
    negotiatingUser: 'seller' | 'buyer',
    buyerPersonId: number,
    sellerPersonId: number,
    vin?: string,
  }, 
  events: {
    submitted?: (response: { buyerOfferAmount: number, sellerOfferAmount: number }) => void
  }={}
) {
  openModal({
    component: TheNegotiationModalAdmin,
    props,
    events,
  });
}

export function convertNegotiationsSearchToModifierSchema(searchByField: 'vin' | 'vehicleListingId' | 'personName' | 'storeName' | 'negotiatingEmployeePersonIds', searchByValue?: string | number | number[] | string[]) {
  if (!searchByValue) {
    return undefined;
  }
  let filters: FilterSchema[] = [];
  switch(searchByField) {
      case 'vin':
          filters.push({
              property: 'vehicle.vin',
              comparator: 'iLike',
              values: searchByValue,
          });
          break;
      case 'vehicleListingId':
          filters.push({
              property: 'vehicleListing.id',
              comparator: '=',
              values: searchByValue,
          });
          break;
      case 'personName':
          filters.push({
              property: 'n/a',
              comparator: '=',
              values: searchByValue,
              customFilterName: 'negotiationsDashboardSellerOrBuyerFullName',
          });
          break;
      case 'storeName':
          filters.push({
              property: 'n/a',
              comparator: '=', 
              values: searchByValue,
              customFilterName: 'negotiationsDashboardSellerOrBuyerStoreName',
          });
          break;
      case 'negotiatingEmployeePersonIds':
        filters.push({
          property: 'accountManagerOrDsrSearch',
          comparator: '=',
          values: searchByValue,
          customFilterName: 'nestedOr'
        });
        break;
      default: 
          return undefined;
  }

  return { modifiers: { filters }};
}

export function calculateBuyerOfferPercentOfMmr(mmr: number, buyerOffer: number) {
  return (buyerOffer / mmr) * 100;
}

export function redirectToNegotiatingDashboard() {
  router.push({ name: 'Admin', query: { tab: '0', status: 'Negotiating' } });
}
