<template>
    <div class="flex-column align-center">
       <AppInitialsIcon :name="senderName"/>
       <div v-if="timeStr" class="sent-time">{{ timeStr }}</div>
    </div>
</template>

<script>
import AppInitialsIcon from "../AppInitialsIcon.vue"
export default {
    name: "ChatMessageSender",
    props: [
        "senderName",
        "timeStr",
        // "color",
    ],
    components: {
        AppInitialsIcon,
    },
}
</script>

<style scoped lang="postcss">
.sent-time {
    font-size: .65rem;
    font-weight: 600;
    margin-top: 5px;
    width: 6em;
    text-align: center;
}

</style>