import { APIConfig } from '@/types'
import { applyAPIConfigOnError } from './otherUtils';

export function playAudioCue(audioCue: string, config: APIConfig & { volume?: number }={}) {
    try {
        const audio = new Audio(require(`@/assets/sounds/${audioCue}.mp3`));
        if (config.volume) {
            audio.volume = config.volume;
        }
        audio.play();
    } catch(error) {
        applyAPIConfigOnError(error, config);
    }
}