
import { defineComponent, ref } from 'vue';
import { BuyItNowModalProps } from '../types';
import { toCurrency } from '../utils';

import InfoHeader from './Arbitrations/InfoHeader.vue';
import Modal from './Modal.vue';
import TheStoreSelectInput from './TheStoreSelectInput.vue';

export default defineComponent({
    name: 'TheBuyItNowModal',
    props: {
        ...BuyItNowModalProps
    },
    components: {
        InfoHeader,
        Modal,
        TheStoreSelectInput,
    },
    emits: ['selectedStore'],
    setup(props, context) {
        const initialStoreId = ref(undefined);
        const disableStoreSelect = ref(false);

        if (props.stores?.length == 1) {
            initialStoreId.value = parseInt(props.stores[0].id);
            context.emit('selectedStore', initialStoreId.value);
            disableStoreSelect.value = true;
        }
        const selectedStoreId = ref(initialStoreId.value);
        return {
            selectedStoreId,
            initialStoreId,
            disableStoreSelect,
            toCurrency,
        }
    }
});
