import Vue, { nextTick } from "vue";
import { storage } from '@/firebase';
import { GET } from "../api";
import { openToast } from '@/utils/buefyUtils';
import { APIConfig } from "@/types";
import { createHyperlinkElementFromString } from "./stringUtils";
import axios from "axios";

// import { getStorage, ref, getMetadata } from "firebase/storage";

// const storage = getStorage();


// const imageRef = ref(storage, 'logos/')

// This function will return true if the device is mobile and false if it is not
export function detectMobile(a = '') {

  const isMobile = /iPhone|iPad|iPod|Android|(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto/i.test(navigator.userAgent);

  return isMobile;
}

export function mergeJSONs(slave, ...masters) {
    const merged = { ...slave };
    masters.map((master) => {
      Object.keys(master).map((key) => (merged[key] = master[key]));
    });
    return merged;
}
  
export const sleep = (m) => new Promise((r) => setTimeout(r, m * 1_000));
  
export function resetStore() {
    this.$store.commit('logout')
    this.$store.commit('resetMyNewCar')
}

export function assignABTestingGroup() {
    const rng = Math.ceil(Math.random() * 2)
    return rng % 2 === 0
}

export function getKeyFromValue(object: Object, value: any) {
  return Object.keys(object).find(key => object[key] === value);
}

export function getMatchingIdx(searchArr: Array<any>, matchField: string, matchValue: any): number {
  var matchesValue = (elem:any) => elem[matchField] === matchValue
  const idx = searchArr.findIndex(matchesValue)
  return idx
}

export function getMatching(searchArr: Array<any>, matchField: string, matchValue:any ): any {
  var matchesValue = (elem: any) => elem[matchField] === matchValue
  const idx = searchArr.findIndex(matchesValue)
  const match = searchArr[idx]
  return match
}

export function focusOnElement(id: string, timeout: number=0) {
  const element = document.getElementById(id);
  if (element) {
    window.setTimeout(() => {
      element.focus();
    }, timeout)
  }
}

export function scrollToElement(id, scrollTo:ScrollLogicalPosition="start") {
  const element = document.getElementById(id);
  if (!element) return null;
  element.scrollIntoView({
    behavior: 'smooth',
    block: scrollTo,
  });
  return element;
}

export function scrollToFirstElement(className, config: ScrollIntoViewOptions={}) {
  const elements = document.getElementsByClassName(className)
  if (!elements.length) return null

  const firstElement = elements[0]
  firstElement.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    ...config,
  })
  return firstElement
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

export function scrollToBottom() {
  window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: 'smooth'
  });
}

export function watchScreenSize(action) {
  window.addEventListener('resize', (event) => {
    action()
  }, true)
}

export function watchWindowWidth() {
  this.$nextTick(() => {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    })
  })
}

export function arraysAreEqual (first: [], second: []) {
  if(first.length !== second.length){
      return false;
  };
  for(let i = 0; i < first.length; i++){
      if(!second.includes(first[i])){
          return false;
      };
  };
  return true;
}

export function updateFavicon(href: string) {
  const newLink = document.createElement('link');
  const oldLink = document.getElementById('favicon');

  newLink.id = 'favicon';
  newLink.rel = 'icon';
  newLink.href = href;

  if (oldLink) document.head.removeChild(oldLink);
  document.head.appendChild(newLink);
}

export async function preloadImage(url) {
  // update image metadata to keep cached image fresh for 1hr (3600s)
  const imageRef = storage.refFromURL(url);
  imageRef.updateMetadata({
    cacheControl: 'private,max-age=3600'
  }).catch(error => {
    console.log('Error updating metadata', error);
  });

  // create preload link to cache image
  // https://html.spec.whatwg.org/multipage/links.html#link-type-preload
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = url;
  document.head.appendChild(link);
}

export async function requireStylesheetForOrigin() {
  if (!this.$store.state.origin || !this.$store.state.origin.publicId) {
    return false;
  }

  const originPublicId = this.$store.state.origin.publicId;
  try {
    await import(`@/assets/branding/${originPublicId}.css`);
    console.log(`${originPublicId}.css applied from App.vue`)
  } catch {
    await import('@/assets/branding/carmigo.css');
    console.log('carmigo.css applied from App.vue')
  }

  let branding = this.$store.state.branding;
  if (!branding || (branding && branding.originPublicId !== originPublicId)) {
   return false;
  }
  if (branding && branding.logoUrlSquare) {
    updateFavicon(branding.logoUrlSquare);
    preloadImage(branding.logoUrlSquare);
  }
  if (branding && branding.logoUrlWide) {
    preloadImage(branding.logoUrlWide);
  }
  return true;
}

export async function applyBrandingForOrigin(originPublicId=null) {
  if (!originPublicId && (!this.$store.state.origin || !this.$store.state.origin.publicId)) {
    return;
  }
  if (!originPublicId) {
    originPublicId = this.$store.state.origin.publicId;
  }

  // apply stylesheet
  try {
    await import(`@/assets/branding/${originPublicId}.css`);
    console.log(`${originPublicId}.css applied`)
  } catch {
    await import('@/assets/branding/carmigo.css');
    console.log('carmigo.css applied');
  }

  // get other branding details
  let branding = await GET(`origin/site/${originPublicId}`)
    .then(res => res.data)
    .catch(error => {
      console.log(`Error getting branding for originPublicId: ${originPublicId}`);
    });

  // update logos
  if (branding && branding.logoUrlSquare) {
    updateFavicon(branding.logoUrlSquare);
    preloadImage(branding.logoUrlSquare);
  }
  if (branding && branding.logoUrlWide) {
    preloadImage(branding.logoUrlWide);
  }

  this.$store.commit('updateBranding', branding);
  this.$root.$emit('brandingApplied');

  return branding;
}

export function getProfileStepStatus(percentComplete: number) {
  return percentComplete === 0 
    ? 'Not Started'
    : percentComplete < 100
      ? 'Started'
      : 'Completed';
}

export function getImgSrc(filename: string, type: 'png' | 'gif') {
  var img = type === 'png' 
    ? require.context('../assets/', false, /\.png$/)
    : require.context('../assets/', false, /\.gif$/)
  return img(`./${filename}`);
}

export function prependZero(val: number) {
  return String(val).padStart(2, '0');
}

export function copyText(text: string) {
  try {
    const textArea = document.createElement('textArea') as HTMLTextAreaElement;
    textArea.value = text;
    document.body.appendChild(textArea);

    textArea.select();
    document.execCommand('copy');
    textArea.remove();

    openToast('is-success', 'Copied to clipboard!');
  } catch(error) {
    openToast('is-danger', 'Unable to copy content');
  }
}

export function preserveScrollPosition({ querySelector, callback, timeout }: {
  querySelector: string,
  callback?: () => void,
  timeout?: number,
}) {
  let [ scrollPositionX, scrollPositionY ] = getScrollPosition(querySelector);
  if (callback) {
    callback();
  }
  window.setTimeout(() => {
    setScrollPosition(querySelector, scrollPositionX, scrollPositionY);
  }, timeout ?? 0);
}

export function getScrollPosition(querySelector: string) {
  let element = document.querySelector(querySelector);
  let x = 0;
  let y = 0;
  if (element) {
    x = element.scrollLeft;
    y = element.scrollTop;
  }
  return [x, y];
}

export function setScrollPosition(querySelector: string, x: number, y: number) {
  Vue.nextTick(() => {
    const element = document.querySelector(querySelector);
    if (element) {
      element.scrollLeft = x;
      element.scrollTop = y;
    }
  });
}
export function hubspotUTKFinder() {
  const allCookieArray = document.cookie.split(';');
  allCookieArray.forEach((cookie, idx) => {
      const temp = allCookieArray[idx].split('=');
      if (temp[0] === ' hubspotutk') {
          return temp[1];
      }
  })
  return '';
}


/**
 * Checks if the FastBook VIN finder extension is installed and enabled.
 * https://chromewebstore.google.com/detail/fastbook-vin-finder/lbpbapeokogbjghjgalgkijflcocbpkj
 *
 * @returns true if the FastBook VIN finder extension is installed and enabled.
 */
export async function isFastBookExtensionInstalled() {
    const fastbookExtensionId = 'lbpbapeokogbjghjgalgkijflcocbpkj';
    try {
        await fetch(`chrome-extension://${fastbookExtensionId}/html/frame.html`);
        return true;
    } catch {
        return false;
    }
}

export function deduplicateObjectArray(array: Object[]) {
  return array.filter((obj, idx, self) => {
    return idx == self.findIndex(o => JSON.stringify(o) === JSON.stringify(obj));
  });
}

export function convertSomeObjectValuesToInt(
  object: { [key: string]: any }, 
  intFields: string[]
): { [key: string]: any } {
  let objWithInts: { [key: string]: any } = {};

  Object.keys(object).forEach(fieldName => {
    let value = object[fieldName];
    objWithInts[fieldName] = intFields.includes(fieldName) && (value || value === '0')
      ? parseInt(value)
      : value;
  });

  return objWithInts;
}

export function deepCopyObject(object: Object): Object {
  return JSON.parse(JSON.stringify(object));
}

export const colorKeywordToRGB = (colorKeyword: string) => {
    // CREATE TEMPORARY ELEMENT
    const el = document.createElement('div');

    // APPLY COLOR TO TEMPORARY ELEMENT
    el.style.color = colorKeyword;

    // APPEND TEMPORARY ELEMENT
    document.body.appendChild(el);

    // RESOLVE COLOR AS RGB() VALUE
    const rgbValue = window.getComputedStyle(el).color;

    // REMOVE TEMPORARY ELEMENT
    document.body.removeChild(el);

    return rgbValue;
}

export function getVueContextFromElement(element: Element) {
  // @ts-ignore
  return element.__vue__?.$vnode?.context;
}

export function getPaginationOffset(page: number, limit: number, totalCount?: number) {
    const totalPages = totalCount ? Math.ceil(totalCount / limit) : page;

    if (page > totalPages) {
        // Handle invalid page, e.g., set to last page or return an error
        return (totalPages - 1) * limit;
    }

    return (page - 1) * limit;
}

export function getPaginationCountDisplayed({ pageNumber=1, paginationLimit, numOnCurrentPage }: {
  pageNumber: number,
  paginationLimit: number,
  numOnCurrentPage: number,
}): { min: number, max: number } | undefined {
  if (numOnCurrentPage < 1) {
    return undefined;
  }
  let numOnPastPages = (pageNumber - 1) * paginationLimit;
  return {
    min: numOnPastPages + 1,
    max: numOnPastPages + numOnCurrentPage,
  }
}

export function applyAPIConfigOnSuccess(data: any, config: APIConfig={}) {
  if (config.onSuccess) {
    config.onSuccess(data);
  }
}

export function applyAPIConfigOnError(error?: Error, config: APIConfig={}): boolean {
  if (axios.isCancel(error) && config.skipErrorOnCancelRequest) {
    return false;
  }
  if (config.onError) {
    config.onError(error);
  }
  if (config.customErrorHandling) {
    throw error;
  }
  return true;
}

export function getEnumArray(enumObj: any, mapValues?: (value: any) => any): any[] {
  let enumArray =  Object.keys(enumObj).filter(key => isNaN(Number(key)));
  return mapValues 
    ? enumArray.map(value => mapValues(value))
    : enumArray;
}

export function insertHyperlinkElement({ querySelector, href, displayText, openInNewTab=true }: { querySelector: string, href: string, displayText: string, openInNewTab?: boolean }) {
  let parentElement = document.querySelector(querySelector);
  if (!parentElement || parentElement.hasChildNodes()) {
    return;
  }

  let newHyperlinkElement = createHyperlinkElementFromString({
    displayText,
    href,
    openInNewTab,
  });

  parentElement.appendChild(newHyperlinkElement);
}

export function normalizeCoordinates(coordinates: { x: number, y: number }, windowHeight: number, windowWidth: number): { x: number, y: number } {
    return {
      x: coordinates.x / windowWidth,
      y: coordinates.y / windowHeight,
    }
}

export function addTransformVerticalScrollToHorizontalScrollEventListener(querySelector: string) {
  let element = document.querySelector(querySelector);
  if (element) {
    // event listener 'passive' needs to be false in order to preventDefault (prevent normal page scrolling)
    element.addEventListener('wheel', transformVerticalScrollToHorizontalScroll as EventListenerOrEventListenerObject, { passive: false });
  }
}

export function transformVerticalScrollToHorizontalScroll(event: WheelEvent) {
  if (!event.deltaY) {
    return;
  }

  //@ts-ignore
  event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
  event.preventDefault();
}


//Used to convert an enum into an array of its keys
//Typically called from routers for use in Joi.valid() methods to avoid hard coding lists of values that might change
//ToLowerCase, when specified true, converts the return keys to all lowercase. Otherwise it returns as the keys are defined in the enum (vehicle statuses are PascalCase for example)
export function commaSeparatedEnum(enumObj: any, toLowerCase: boolean = false) {
  const enumArray = Object.keys(enumObj) //get all values of enum
  .filter((key) => isNaN(Number(key))) // filter out numbers (i.e. `NEGATIVE = 1` would consider both 'NEGATIVE' and '1' to be a key)
  .map((key) => {
    if (toLowerCase) {
      return key.toLowerCase();
    } else {
      return key
    }
  });

  return enumArray;
}

export function toPercentage(number: number | undefined, { isDecimal=true, formatPercentage=true }: { isDecimal?: boolean, formatPercentage?: boolean } = {}): string | undefined {
  if (!number) {
    return formatPercentage ? '--' : undefined;
  }
  let percentage = Math.floor(number * (isDecimal ? 100 : 1)).toString();
  return formatPercentage ? `${percentage}%` : percentage;
}