
import { defineComponent } from 'vue';

import AppRadioButton from './AppRadioButton.vue';

export default defineComponent({
    name: 'AppRadioButtonWithDescription',
    props: {
        title: {
            type: String,
            required: false,
        },
        flag: {
            type: String,
            required: false,
        },
        description: {
            type: String,
            required: false,
        }
    },
    components: {
        AppRadioButton
    },
});
