var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"marketplace-buttons mb-0"},[(_vm.isAdmin || _vm.isUserDsr || (_vm.isVehicleSeller && _vm.showSellerManageOffers))?_c('TheMarketplaceManageOffersButton',_vm._b({},'TheMarketplaceManageOffersButton',{
            vehicleListingId: _vm.vehicleListingId,
            marketplaceListingId: _vm.marketplaceListingId,
            marketplaceOfferCount: _vm.marketplaceOfferCount
        },false)):(_vm.buyerHasMadeOffer && !_vm.isDashboardButton)?_c('AppButtonWithSecondaryInfo',_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.openMarketplaceModal.apply(null, arguments)}}},'AppButtonWithSecondaryInfo',{
            label: 'Manage Offer',
            info: _vm.toCurrency(_vm.currentOffer.buyerOfferAmount),
            type: 'is-primary',
            expanded: true,
            ..._vm.$attrs,
        },false)):(!_vm.isVehicleSeller && _vm.buyerHasMadeOffer && _vm.isDashboardButton)?_c('TheNegotiateButtonMarketplace',_vm._b({nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openMarketplaceModal.apply(null, arguments)}}},'TheNegotiateButtonMarketplace',{
            offerStatusId: _vm.currentOffer?.offerStatusId,
            offererTypeId: _vm.currentOffer?.marketplaceOffererTypeId,
            negotiatingUser: _vm.userType,
            ..._vm.$attrs,
        },false)):_c('AppButton',_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.openMarketplaceModal()}}},'AppButton',{
            label: 'Make an Offer',
            type: 'is-primary',
            expanded: true,
            disabled: _vm.isAdmin || _vm.isVehicleSeller || _vm.$attrs.disabled,
            ..._vm.$attrs,
        },false)),(_vm.canBuyItNow && (!_vm.isVehicleSeller || !_vm.showSellerManageOffers) && !_vm.isAdmin)?_c('TheBuyItNowButton',_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.openBuyItNowModal.apply(null, arguments)}}},'TheBuyItNowButton',{
            type: 'is-primary',
            outlined: true,
            expanded: true,
            buyItNowPrice: _vm.buyItNowPrice,
            ..._vm.$attrs,
            disabled: _vm.isAdmin || _vm.isVehicleSeller || _vm.$attrs.disabled,
            ..._vm.$attrs.buyItNowButtonAttrs,
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }