export function postgresDate2Str(postgresDate) {
    const d = new Date(postgresDate)
    const format = (num: number, len = 2, add = 0) =>
        (num + add).toString().padStart(len, "0");

    return (
        `` +
        `${format(d.getMonth(), 2, 1)}` +
        `-${format(d.getDate(), 2, 0)}` +
        `-${format(d.getFullYear(), 4, 0)}` +
        " - " +
        `${format(d.getHours(), 2, 0)}` +
        `:${format(d.getMinutes(), 2, 0)}` +
        `:${format(d.getSeconds(), 2, 0)}`
    );
}

export function postgresDate2DateStr(postgresDate) {
    const dateStr = postgresDate2Str(postgresDate);
    return dateStr.split(" - ")[0];
}

export function postgres2DateStrLong(postgresDate: Date) {
    return postgresDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })

}

export function postgresDate2TimeStr(dateString) {
    const postgresDate = new Date(dateString)
    var hrs = postgresDate.getHours();
    var mins = postgresDate.getMinutes();

    // convert 24 hr to 12 hr, get AM/PM
    var hrs12 = hrs % 12;
    var ampm = "AM";
    if (hrs12 !== hrs) ampm = "PM";
    if (hrs12 === 0) hrs12 = 12;

    // add leading 0 to minutes if < 10
    var minString = "";
    mins < 10 ? (minString = `0${mins}`) : (minString = `${mins}`);

    const time = `${hrs12}:${minString} ${ampm}`;
    return time;
}