
import { PropType } from 'vue';
import { openDeveloperActionRequiredDialog } from '../utils';
type ToggleableRole = 'buyer' | 'wholesaler' // from Roles;

export default {
    inheritAttrs: false, 
    name: 'TheEmployeeRoleToggles',
    props: {
        roles: {
            type: Array as PropType<ToggleableRole[]>,
            default: () => [],
        },
        preventRoleRemoval: {
            type: Boolean,
            default: false,
        },
        buyerPersonId: {
            type: Number,
            required: false, // required if preventRoleRemoval is true
        },
        storeId: {
            type: Number,
            required: false, // optional, used if preventRoleRemoval is true 
        }
    },
    data() {
        return {
            rolesKey: 0,
        }
    },
    computed: {
        hasBuyerRole: {
            get: function() {
                return this.roles.includes('buyer');
            },
            set: function(newValue) {
                if (!newValue && this.preventRoleRemoval) {
                    this.openPreventRemovalDialog('buyer');
                    this.updateRolesAndEmit('buyer', true);
                    this.rolesKey++;
                    return;
                }
                this.updateRolesAndEmit('buyer', newValue);
            }
        },
        hasWholesalerRole: {
            get: function() {
                return this.roles.includes('wholesaler');
            },
            set: function(newValue) {
                if (!newValue && this.preventRoleRemoval) {
                    this.openPreventRemovalDialog('wholesaler');
                    this.updateRolesAndEmit('wholesaler', true);
                    this.rolesKey++;
                    return;
                }
                this.updateRolesAndEmit('wholesaler', newValue);
            }
        }
    },
    methods: {
        openPreventRemovalDialog(role: ToggleableRole) {
            openDeveloperActionRequiredDialog(
                `Removing the <span class="bold">"${role}"</span> role from person <span class="bold">${this.buyerPersonId}</span>${this.storeId ? ` and store <span class="bold">${this.storeId}</span>` : ''}`
            );
        },
        updateRolesAndEmit(role: ToggleableRole, shouldAdd: boolean) {
            const updatedRoles = this.addOrRemoveRole(role, shouldAdd);
            this.$emit('update:roles', updatedRoles);
        },
        addOrRemoveRole(role: ToggleableRole, shouldAdd: boolean) {
            let updatedRoles = [...this.roles];
            let roleIdx = this.roles.findIndex(value => value == role);
            if (shouldAdd && roleIdx < 0) {
                updatedRoles.push(role);
            } else if (!shouldAdd && roleIdx >= 0) {
                updatedRoles.splice(roleIdx, 1);
            }
            return updatedRoles;
        }
    }
}
