export function toCurrency(value: number | string): string {
  let currencyStr = '';
    if (typeof value == "string") {
      currencyStr = `$${value}`;
    }
    if (typeof value == "number") {
      const options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      };
      currencyStr = "$" + value.toLocaleString("en", options);
    }
    return currencyStr.replace('$-', '-$'); // handle negative dollar amounts
  }

  export function formatDollarAmount(amount, trailingZero?: boolean, withDollarSign=true) {
    if (!parseInt(amount)) {
      return amount;
    }
    if (!amount && amount!=0) return;
    if (typeof amount === "number") amount = amount.toString()
    amount = amount.replace(/(\$+)/g, '') // remove $ if it exists
    amount = amount.replace(/(\+)/g, '') // remove + if it exists
    amount = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") // format dollars & cents
    if (trailingZero && !amount.includes('.')) {
      amount += '.00'
    }

    if (!withDollarSign) return amount;
    return `$${amount}` // add $
  }

  export function roundToNearestHundred(amount, roundDown=true) {
    if (roundDown) return Math.floor(amount/100) * 100;
    return Math.ceil(amount/100) * 100;
  }

  export function dollarAmountToInt(string) {
    if (!string || string == '$') {
      return undefined;
    }
    if (typeof string == 'number') {
      return string;
    }
    let str = string.replace(/\$|,/g, "");
    return parseInt(str);
  }

export function isMultipleOfHundred(input: number | string) {
  let inputAsInt = typeof input == 'string' ? dollarAmountToInt(input) : input;
  if (inputAsInt == undefined) {
    return false;
  }
  return inputAsInt % 100 == 0;
}

export function toCurrencyShowDashesIfNull(value?: number | null) {
  if (!value && value !== 0) {
    return '--'
  }
  return toCurrency(value);
}

/**
 * Converts an object's currency string values to int
 * @param obj - an object where some values are currency strings (with $ sign)
 * @param propertiesToConvert - an array of the keys that need to be formatted using dollarAmountToInt
 * @returns - the same object, with the above keys converted to int
 */
export function dollarAmountToIntObj(obj: {[key: string]: any}, propertiesToConvert: string[]) {
  let finalObj = { ...obj };

  propertiesToConvert.forEach(currencyProp => {
    finalObj[currencyProp] = dollarAmountToInt(obj[currencyProp]);
  });
  
  return finalObj;
}
