var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"add-attachments-button"},[(!_vm.$props.isDropdown)?_c('b-field',_vm._b({staticClass:"drag-and-drop-button"},'b-field',_vm.$attrs,false),[_c('b-upload',_vm._b({on:{"input":function($event){_vm.$props.multiple ? _vm.uploadAttachments($event) : _vm.uploadAttachments([$event])}},model:{value:(_vm.newAttachments),callback:function ($$v) {_vm.newAttachments=$$v},expression:"newAttachments"}},'b-upload',{
                accept: '.jpg,.jpeg,.pdf,.png,.webp',
                ..._vm.$attrs.uploadAttrs,
                multiple: _vm.$props.multiple,
            },false),[_vm._t("uploadBody",function(){return [_c('div',{staticClass:"button flex-row justify-center has-text-white has-background-primary pl-3 pr-4 py-2"},[_c('p',{staticClass:"is-poppins is-size-6 pr-2"},[_vm._v("Add Photos")]),_c('b-icon',{attrs:{"icon":"plus"}})],1)]})],2)],1):_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left","trap-focus":"","append-to-body":""},on:{"active-change":function($event){return _vm.$emit('activeChange', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_vm._t("trigger",function(){return [_c('b-button',{attrs:{"label":"Add Photos","type":"is-primary","position":"is-bottom-left","icon-right":active ? 'menu-up' : 'menu-down'}})]},null,{ active })]}}],null,true)},[(_vm.$props.uploadTypes.includes('library'))?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"}},[_c('b-upload',_vm._b({on:{"input":function($event){_vm.$props.multiple ? _vm.uploadAttachments($event) : _vm.uploadAttachments([$event])}},model:{value:(_vm.newAttachments),callback:function ($$v) {_vm.newAttachments=$$v},expression:"newAttachments"}},'b-upload',{
                    accept: '.jpg,.jpeg,.pdf,.png,.webp',
                    ..._vm.$attrs.uploadAttrs,
                    multiple: _vm.$props.multiple,
                },false),[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"image-multiple"}}),_c('span',{staticClass:"is-size-6"},[_vm._v("Photo Library")])],1)])],1):_vm._e(),(_vm.$props.uploadTypes.includes('camera') && (_vm.bulmaBreakpoint.mobile || _vm.bulmaBreakpoint.tablet))?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.onTakePhotoSelect}},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"camera"}}),_c('span',{staticClass:"is-size-6"},[_vm._v("Take Photo")])],1)]):_vm._e(),(_vm.$props.uploadTypes.includes('listing'))?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.openSelectListingPhotosModal}},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"car-door"}}),_c('span',{staticClass:"is-size-6"},[_vm._v("Listing photos")])],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }