import confetti from 'canvas-confetti';
import { normalizeCoordinates } from './otherUtils';

export function throwConfetti(config: { 
    particleCount?: number,
    angle?: number,
    spread?: number,
    origin?: { x?: number, y?: number },
    colors?: string[],
  }={}) {
    let confettiConfig = {
      particleCount: 200,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: ['#52aa57', '#b0d6b2', '#1a9621', '#6adf70'],
      ...config,
    }
    confetti(confettiConfig);
}

export function rainConfetti(config: {
    particleCount?: number,
    angle?: number,
    spread?: number,
    origin?: { x?: number, y?: number },
    colors?: string[],
  }={}, duration=10000) {
    var animationEnd = Date.now() + duration;
  
    (function frame() {
        var timeLeft = animationEnd - Date.now();
  
        confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: ['#52aa57', '#b0d6b2', '#1a9621', '#6adf70'],
            ...config,
        });
  
        if (timeLeft > 0) {
            requestAnimationFrame(frame)
        }
    }())
}
  
export function startConfetti(duration=10000) {
    var colors = ["#52aa57", '#b0d6b2', '#1a9621', '#6adf70']
    var animationEnd = Date.now() + duration;
  
    (function frame() {
        var timeLeft = animationEnd - Date.now();
  
        confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors,
        });
        confetti({
            particleCount: 1,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors,
        })
  
        if (timeLeft > 0) {
            requestAnimationFrame(frame)
        }
    }())
}

export function throwConfettiFromSides() {
    var colors = ["#52aa57", '#b0d6b2', '#1a9621', '#6adf70']
    confetti({
        particleCount: 200,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
    });
    confetti({
        particleCount: 200,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
    });
}

export function getConfettiOriginLeftSide(element: Element, { offsetPixelsX=0, offsetPixelsY=0 }) {
    let boundingRect = element.getBoundingClientRect();
    let coordinates = {
        x: boundingRect.left + offsetPixelsX,
        y: (boundingRect.top + boundingRect.height/2) + offsetPixelsY,
    }
    return normalizeCoordinates(coordinates, window.innerHeight, window.innerWidth);
}

export function getConfettiOriginRightSide(element: Element, { offsetPixelsX=0, offsetPixelsY=0 }) {
    let boundingRect = element.getBoundingClientRect();
    let coordinates = {
        x: boundingRect.right + offsetPixelsX,
        y: (boundingRect.top + boundingRect.height/2) + offsetPixelsY,
    }
    return normalizeCoordinates(coordinates, window.innerHeight, window.innerWidth);
}


export function rainConfettiFromElementRightSide(element: Element, { durationMs = 3000, offsetPixelsX=0, offsetPixelsY=0, confettiConfig={} }: {
    durationMs?: number,
    offsetPixelsX?: number,
    offsetPixelsY?: number,
    confettiConfig?: { [key: string]: any },
}={}) {
    rainConfetti({
        origin: getConfettiOriginRightSide(element, { offsetPixelsX, offsetPixelsY }),
        angle: 60,
        ...confettiConfig,
    }, durationMs);
}

export function rainConfettiFromElementLeftSide(element: Element, { durationMs = 3000, offsetPixelsX=0, offsetPixelsY=0, confettiConfig={} }: {
    durationMs?: number,
    offsetPixelsX?: number,
    offsetPixelsY?: number,
    confettiConfig?: { [key: string]: any },
}={}) {
    rainConfetti({
        origin: getConfettiOriginLeftSide(element, { offsetPixelsX, offsetPixelsY }),
        angle: 120,
        ...confettiConfig,
    }, durationMs);
}

