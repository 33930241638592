
import { meanBy } from 'lodash';
import { computed, defineComponent, PropType } from 'vue';
import { fireEvent } from '../segment';
import { MarketDataDTO } from '../types';
import { formatDate, toCurrencyShowDashesIfNull } from '../utils';

import AppButton from './AppButton.vue';

export default defineComponent({
    name: 'TheListingMarketDataTable',
    props: {
        marketData: {
            type: Object as PropType<MarketDataDTO>,
            required: false,
        },
        hideColumns: {
            type: Array as PropType<String[]>,
            default: () => { return [] },
        },
        showAverageCost: {
            type: Boolean,
            default: false,
        },
    },
    components: { 
        AppButton,
    },
    setup(props, { emit, attrs }) {
        const averageCost = computed(() => meanBy(props.marketData?.soldVehicles, v => v.price));

        function goToVincue() {
            fireEvent('Redirect to Vincue');

            window.open('https://vincue.com/teamvincue/integration-partners/carmigo/', '_blank');
        }

        return {
            averageCost,
            formatDate,
            toCurrencyShowDashesIfNull,
            goToVincue,
        }
    },
});
