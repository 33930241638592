var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"confirm":_vm.submitClaim,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Open an Arbitration Claim ")]},proxy:true},{key:"body",fn:function(){return [_c('InfoHeader',{attrs:{"imageUrl":_vm.heroPic.url,"title":_vm.loadingClaimDetails ? 'Loading vehicle data...' : _vm.vehicle.year + ' ' + _vm.vehicle.make + ' ' + _vm.vehicle.model,"subtitle":_vm.loadingClaimDetails ? '' : _vm.vehicle.vin},scopedSlots:_vm._u([{key:"details",fn:function(){return [_c('div',{staticClass:"highlights flex-row",class:{ 'border-top': !_vm.loadingClaimDetails }},_vm._l((Object.keys(_vm.vehicleDetails)),function(detail){return _c('InfoHighlight',{key:detail,attrs:{"label":detail,"info":_vm.vehicleDetails[detail],"color":"green"}})}),1)]},proxy:true}])}),_c('div',[_c('div',{staticClass:"input-section"},[_c('b-field',{staticClass:"claim-reason flex-column",attrs:{"label":"Reason for Claim*"}},[_c('DeleteEntryButtons',_vm._b({on:{"deleteEntryByIndex":_vm.deleteReason}},'DeleteEntryButtons',{
              entries: _vm.claimReasons,
              flexDirection: 'row',
              disabled: _vm.loadingSubmitClaim,
            },false)),_c('b-dropdown',{key:`claim-options-${_vm.optionsKey}`,attrs:{"required":"","multiple":"","disabled":_vm.loadingClaimDetails || _vm.loadingSubmitClaim},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('b-button',{attrs:{"icon-right":props.open ? 'menu-up' : 'menu-down'}},[_c('p',[_vm._v("Select")])])]}}]),model:{value:(_vm.claimReasons),callback:function ($$v) {_vm.claimReasons=$$v},expression:"claimReasons"}},_vm._l((_vm.claimReasonOptions),function(option){return _c('b-dropdown-item',{key:option,staticClass:"dropdown-item",attrs:{"value":option,"aria-role":"list-item"}},[_c('p',[_vm._v(_vm._s(option))])])}),1)],1)],1),_c('div',{staticClass:"input-section"},[_c('b-field',{attrs:{"label":"Additional Details*"}},[_c('b-input',{attrs:{"disabled":_vm.loadingClaimDetails || _vm.loadingSubmitClaim},model:{value:(_vm.claimDescription),callback:function ($$v) {_vm.claimDescription=$$v},expression:"claimDescription"}})],1)],1),_c('div',{staticClass:"input-section"},[_c('DragAndDropAttachments',{attrs:{"loading":_vm.loadingAttachments,"disabled":_vm.loadingClaimDetails || _vm.loadingSubmitClaim},on:{"attached":function($event){_vm.attachments=$event},"update:loading":function($event){_vm.loadingAttachments=$event}},scopedSlots:_vm._u([{key:"instructions",fn:function(){return [_c('p',{staticClass:"italic margin-bottom"},[_vm._v(" Please provide any additional photos or PDF attachments that may be relevant to your claim. ")])]},proxy:true}])}),_c('AnimatedLoadingText',{staticClass:"margin-1-em",class:!_vm.loadingAttachments ? 'hidden' : '',attrs:{"text":"Uploading attachments"}})],1)])]},proxy:true}])},'Modal',{
    showButtons: ['submit', 'cancel'],
    customLoading: true,
    isLoading: _vm.loadingSubmitClaim,
    disableConfirm: _vm.loadingAttachments || _vm.missingInformation,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }