
import { PropType } from 'vue';

import AuctionCertifiedBadge from '../AuctionCertifiedBadge.vue';
import EditableField from './EditableField.vue';
import { isBuyItNowAvailable, getAttrs, getBuyItNowUnavailableReason, focusOnElement, dollarAmountToInt, isInspectionFieldInvalid } from '../../utils';
import { debounce } from 'lodash';
import { InvalidInspectionFields } from '../../types';

export type AuctionPriceFields = 'startingPrice' | 'reservePrice' | 'buyItNowPrice' | 'carmigoCertified';

export default {
    inheritAttrs: false,
    name: 'EditableAuctionPricing',
    props: {
        startingPrice: {
            type: [Number, String],
            required: false,
        },
        reservePrice: {
            type: [Number, String],
            required: false,
        },
        carmigoCertified: {
            type: Boolean,
            required: false,
        },
        editable: {
            type: Boolean,
            required: false,
        },
        invalidFields: {
            type: Object as PropType<InvalidInspectionFields>,
            default: () => {
                return {};
            }
        },
        isBuyItNowOptional: {
            type: Boolean,
            default: false,
        },
        canBuyItNow: {
            type: Boolean,
            required: false,
            default: true,
        },
        buyItNowPrice: {
            type: [Number, String],
            required: false
        },
        showFields: {
            type: Array as PropType<AuctionPriceFields[]>,
            default: () => ['startingPrice', 'reservePrice', 'buyItNowPrice', 'carmigoCertified'],
        },
        displayType: {
            type: String as PropType<'seller' | 'inspector'>,
            default: 'inspector'
        },
        initBuyItNowPriceWithReservePriceIfMissing: {
            type: Boolean,
            default: true,
        },
        listingType: {
            type: String,
            required: true,
        },
    },
    components: {
        AuctionCertifiedBadge,
        EditableField,
    },
    data() {
        return {
            startingPriceUpdated: this.startingPrice,
            reservePriceUpdated: this.reservePrice,
            buyItNowPriceUpdated: this.buyItNowPrice,
            carmigoCertifiedUpdated: this.carmigoCertified,
            buyItNowPriceKey: 0,
        }
    },
    
    methods: {
        getAttrs,
        isInspectionFieldInvalid,

        emitUpdatedValues: debounce( function(field, value) {
            // have to emit canBuyItNow object when starting/reserve/BIN price are updated, so canBuyItNow can be recalculated on the backend
            const emitObj = {
                [field]: value,
                canBuyItNowObject: this.canBuyItNowObject
            }
            this.$emit('input', emitObj);
        }, 100),
        setBuyItNowPriceAndEmitUpdatedValue(price?: string) {
            this.buyItNowPriceUpdated = price;
            this.buyItNowPriceKey++;
            this.emitUpdatedValues('buyItNowPrice', price ? this.buyItNowPriceUpdatedInt : undefined);
        },
        
        getValidationAttrs(fieldName: 'startingPrice' | 'reservePrice' | 'buyItNowPrice') {
            let isInvalid = isInspectionFieldInvalid(this.invalidFields, fieldName);
            return {
                isMissing: isInvalid,
                type: isInvalid ? 'is-danger' : '',
                message: this.invalidFields[fieldName]
            }
        },
    },
    mounted() {
        if (this.initBuyItNowPriceWithReservePriceIfMissing && !this.buyItNowPrice) {
            this.setBuyItNowPriceAndEmitUpdatedValue(this.reservePriceUpdated);
        }
    },
    computed: {
        startingPriceUpdatedInt() {
            return dollarAmountToInt(this.startingPriceUpdated);
        },
        reservePriceUpdatedInt() {
            return dollarAmountToInt(this.reservePriceUpdated);
        },
        buyItNowPriceUpdatedInt() {
            return dollarAmountToInt(this.buyItNowPriceUpdated);
        },
        getInvalidBuyItNowPriceReason() {
            return getBuyItNowUnavailableReason({
                startingPrice: this.startingPriceUpdatedInt,
                reservePrice: this.reservePriceUpdatedInt,
                buyItNowPrice: this.buyItNowPriceUpdatedInt,
            });
        },
        invalidBuyItNowPrice() {
            if (!this.canBuyItNowUpdated && this.listingType === 'auction') {
                return false;
            }

            if (this.buyItNowPriceUpdated) {
                let validPrice = isBuyItNowAvailable({
                    startingPrice: this.startingPriceUpdatedInt,
                    reservePrice: this.reservePriceUpdatedInt,
                    buyItNowPrice: this.buyItNowPriceUpdatedInt,
                });
                return !validPrice;
            }

            return false;
        },
        canBuyItNowObject() {
            return {
                canBuyItNow: this.canBuyItNowUpdated,
                startingPrice: this.startingPriceUpdatedInt,
                reservePrice: this.reservePriceUpdatedInt,
                buyItNowPrice: this.buyItNowPriceUpdatedInt
            }
        },
        canBuyItNowUpdated: {
            get() {
                return this.canBuyItNow;
            },
            set(newValue) {
                if (newValue) {
                    window.setTimeout(() => focusOnElement('buy-it-now-price-input'), 200);
                    if (!this.buyItNowPriceUpdated) {
                        this.setBuyItNowPriceAndEmitUpdatedValue(this.reservePriceUpdated);
                    }
                }
                if (!newValue && !this.initBuyItNowPriceWithReservePriceIfMissing) {
                    this.setBuyItNowPriceAndEmitUpdatedValue(undefined);
                }
                this.$emit('update:canBuyItNow', newValue);
                this.$emit('input', {
                    canBuyItNow: newValue,
                    canBuyItNowObject: {
                        canBuyItNow: newValue,
                        startingPrice: this.startingPriceUpdatedInt,
                        reservePrice: this.reservePriceUpdatedInt,
                        buyItNowPrice: this.buyItNowPriceUpdatedInt,
                    }
                });
            }
        },
        reservePriceLabel() {
            return this.listingType === 'marketplace' ? 'Floor price' : 'Reserve price';
        }
    },
}
