
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { getFeaturePrediction } from '../api';
import { useAutocomplete, useEditableFields } from '../composables';
import { InspectionUpdateBookoutOptions } from '../types';
import { dollarAmountToInt } from '../utils';

import AppButton from './AppButton.vue';
import EditableField from './Forms/EditableField.vue';
import TheListingDetailsBookoutOptions from './TheListingDetailsBookoutOptions.vue';

export default defineComponent({
    name: 'TheListingDetailsBookoutOptionsEditable',
    props: {
        bookoutOptions: {
            type: Array as PropType<InspectionUpdateBookoutOptions>,
            default: () => { return [] },
        },
        isEditingMode: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppButton,
        EditableField,
        TheListingDetailsBookoutOptions,
    },
    emits: ['input'],
    setup(props, context) {
        const updatedBookoutOptions: Ref<InspectionUpdateBookoutOptions> = ref(props.bookoutOptions);
        const bookoutOptionsKey = ref(0);

        const { emitUpdates } = useEditableFields({ context });

        function emitBookoutOptions() {
            let bookoutOptions = updatedBookoutOptions.value;
            Object.keys(bookoutOptions).forEach(tire => {
                bookoutOptions[tire].price = dollarAmountToInt(bookoutOptions[tire].price);
            });
            emitUpdates(bookoutOptions);
        }

        function updateBookoutOption(field: 'name' | 'price', value: string | number, idx: number) {
            switch(field) {
                case 'name':
                    updatedBookoutOptions.value[idx].name = value as string;
                    break;
                case 'price':
                    updatedBookoutOptions.value[idx].price = value as number;
                    break;
            }
        }

        function updateBookoutOptionsAndEmit(field: 'name' | 'price', value: string | number, idx: number) {
            const originalValue = props.bookoutOptions[idx][field];
            if (field == 'price') {
                value = dollarAmountToInt(value);
            }
            if (originalValue !== value) {
                updateBookoutOption(field, value, idx); 
                emitBookoutOptions();
            }
        }

        function addBookoutOption() {
            updatedBookoutOptions.value.push({
                name: '',
                price: undefined,
                selected: true,
            });
        }

        function deleteBookoutOption(idx: number) {
            if (!updatedBookoutOptions.value[idx].name) {
                updatedBookoutOptions.value.splice(idx, 1);
            } else {
                updatedBookoutOptions.value[idx].selected = false;
            }
            bookoutOptionsKey.value++;
        }

        const canAddBookoutOption = computed(() => {
            if (!updatedBookoutOptions.value.length) {
                return true;
            }
            let lastBookoutOption = updatedBookoutOptions.value[updatedBookoutOptions.value.length-1];
            return lastBookoutOption && lastBookoutOption.name;
        });

        const { autocompleteOptions, loadingAutocomplete, debouncedGetAutocompleteOptions } = useAutocomplete({
            callback: getFeaturePrediction,
        });

        return {
            updateBookoutOptionsAndEmit,
            updatedBookoutOptions,
            emitBookoutOptions,
            updateBookoutOption,
            addBookoutOption,
            deleteBookoutOption,
            bookoutOptionsKey,
            canAddBookoutOption,
            autocompleteOptions,
            loadingAutocomplete,
            debouncedGetAutocompleteOptions,
        }
    },
});
