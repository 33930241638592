import { PropType } from 'vue';
import { NegotiationMessage } from './NegotiationMessage';

export interface NegotiationHighlight {
    label: string,
    value: string | number,
    filter?: Function
}

export const negotiationModalProps = {
    vehicleListingId: {
        type: Number,
        required: false,
    },
    buyerOfferAmount: {
        type: Number,
        required: false,
    },
    sellerOfferAmount: {
        type: Number,
        required: false,
    },
    negotiatingUser: {
        type: String as PropType<'seller' | 'buyer'>,
        required: true,
    },
    negotiationStatus: {
        type: String as PropType<'in progress' | 'rejected'>,
        default: 'in progress'
    },
    highlightDetails: {
        type: Array as PropType<NegotiationHighlight[]>,
        required: false,
    },
    vin: {
        type: String,
        required: false,
    },
    disableRejectedNegotiations: {
        type: Boolean,
        default: false,
    },
    isMarketplace: {
        type: Boolean,
        default: false,
    },
    displayRejectedMessage: {
        type: Boolean,
        default: false,
    },
    makingCounterOfferInitial: {
        type: Boolean,
        default: false,
    },
    allowCounterOffer: {
        type: Boolean,
        default: true,
    },
}

export const negotiationModalWithMessagesProps = {
    ...negotiationModalProps,
    allowMessages: {
        type: Boolean,
        default: false,
    },
    displayMessage: {
        type: Object as PropType<NegotiationMessage>,
        required: false,
    },
    loadingMessages: {
        type: Boolean,
        required: false,
    },
    negotiationMessages: {
        type: Array as PropType<NegotiationMessage[]>,
        required: false,
    },
    allMessages: {
        type: Array as PropType<NegotiationMessage[]>,
        required: false,
    },
}