var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLoadingCheckoutData)?_c('Loading'):_c('TheCheckoutModal',_vm._b({on:{"close":function($event){return _vm.$emit('close')},"confirm":_vm.formatAndSubmitCheckout}},'TheCheckoutModal',{
        vehicleName: _vm.vehicleName,
        vehiclePrice: _vm.vehiclePrice,
        checkoutData: _vm.checkoutData,
        checkoutSteps: _vm.checkoutSteps,
        requiredFields: ['useCarmigoTransportation', 'selectedArbitrationPlan'],
        isLoading: _vm.isLoadingSubmitCheckout,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }