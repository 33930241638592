
import { defineComponent } from 'vue';
import ItemizedBill from './ItemizedBill.vue';

export default defineComponent({
    name: 'TheCheckoutPaymentForm',
    props: {
        orderSummary: {
            type: Object,
            required: true,
        },
        showOrderSummaryTotal: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        ItemizedBill,
    },
});
