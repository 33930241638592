import { APIConfig } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess } from "@/utils";
import { GET } from ".";

export async function getOverdriveDiscountLevels(config: APIConfig={}) {
    return await GET(`/overdrive/overdriveDiscountLevels`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}