var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"confirm":function($event){_vm.$emit('saveAnnouncement', { announcement: _vm.newAnnouncement, vehicleListingPhotoType: _vm.vehicleListingPhotoType, uploadPhoto: _vm.uploadPhoto }); _vm.$emit('close');},"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$props.announcement ? 'Edit Announcement' : 'Create Announcement')+" ")]},proxy:true},{key:"body",fn:function(){return [_c('TheCreateAnnouncementForm',_vm._b({on:{"update":function($event){_vm.newAnnouncement = $event.announcement; _vm.prefillFields = $event.prefillFields;},"addPhoto":function($event){_vm.uploadPhoto = $event},"update:vehicleListingPhotoType":function($event){_vm.vehicleListingPhotoType = $event}}},'TheCreateAnnouncementForm',{
                vehicleListingId: _vm.$props.vehicleListingId,
                announcement: _vm.$props.announcement,
                vehicleListingPhotos: _vm.$props.vehicleListingPhotos,
            },false)),(!_vm.$props.bulkAddCategories)?_c('a',{staticClass:"add-another-announcement flex-row align-center justify-center mt-4",class:{ 'opacity-50': !_vm.isAnnouncementValid },on:{"click":() => {
                if (_vm.isAnnouncementValid) {
                    _vm.$emit('saveAnnouncement', { announcement: _vm.newAnnouncement, vehicleListingPhotoType: _vm.vehicleListingPhotoType, uploadPhoto: _vm.uploadPhoto, prefillNextAnnouncement: _vm.prefillFields });
                    _vm.$emit('close');
                }
            }}},[_c('b-icon',{attrs:{"icon":"plus","size":"is-small"}}),_c('span',{staticClass:"underline"},[_vm._v("save & add another")])],1):_vm._e()]},proxy:true}])},'Modal',{
        disableConfirm: !_vm.isAnnouncementValid,
        confirmButtonAttrs: {
            label: _vm.$props.bulkAddCategories ? 'Next' : 'Save',
        },
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }