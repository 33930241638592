
import { defineComponent, PropType, ref } from 'vue';
import { createAdminNote } from '../api';
import { useAuctionNegotiations } from '../composables';
import { negotiationModalWithMessagesProps } from '../types';
import { capitalize, toCurrency } from '../utils';

import TheNegotiationModalWithMessages from './TheNegotiationModalWithMessages.vue';

export default defineComponent({
    name: 'TheNegotiationModalAdmin',
    props: {
        ...negotiationModalWithMessagesProps,
        buyerPersonId: {
            type: Number,
            required: true,
        },
        sellerPersonId: {
            type: Number,
            required: true,
        },
    },
    components: {
        TheNegotiationModalWithMessages,
    },
    emits: ['close', 'submitted'],
    setup(props, context) {
        const negotiationNote = ref(undefined);

        const {
            negotiatingUserOfferAmount,
            otherUserOfferAmount,
            otherUser,
            loadingSubmitCounterOffer,
            submitCounterOffer,
        } = useAuctionNegotiations({
            vehicleListingId: props.vehicleListingId,
            negotiatingUser: props.negotiatingUser,
            buyerOfferAmount: props.buyerOfferAmount,
            sellerOfferAmount: props.sellerOfferAmount,
            buyerPersonId: props.buyerPersonId,
            sellerPersonId: props.sellerPersonId,
            context,
        });

        async function submitNegotiationAndNote(offerAmount: number) {
            await submitCounterOffer(offerAmount)
                .then(() => {
                    if (!negotiationNote.value) {
                        return;
                    }
                    createAdminNote(props.vehicleListingId, negotiationNote.value);
                });
        }
        
        return {
            capitalize,
            toCurrency,

            negotiationNote,
            submitNegotiationAndNote,

            negotiatingUserOfferAmount,
            otherUserOfferAmount,
            otherUser,
            loadingSubmitCounterOffer,
        }
    },
});
