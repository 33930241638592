
import { defineComponent, ref } from 'vue';
import { useEditableFields, useUserRole, useValidatePricing } from '../composables';
import { createVehiclePricingDetailsModal, dollarAmountToInt, toCurrency } from '../utils';

import AppButton from './AppButton.vue';
import EditableField from './Forms/EditableField.vue';
import TheBuyItNowToggle from './TheBuyItNowToggle.vue';

export default defineComponent({
    name: 'TheListingDetailsPricingEditable',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        startingPrice: {
            type: Number,
            required: false,
        },
        reservePrice: {
            type: Number,
            required: false,
        },
        buyItNowPrice: {
            type: Number,
            required: false,
        },
        canBuyItNow: {
            type: Boolean,
            default: false,
        },
        isMarketplaceListing: {
            type: Boolean,
            default: false,
        },
        isEditingMode: {
            type: Boolean,
            default: false,
        },
        additionalPricingDetails: {
            type: Object,
            required: false,
        },
    },
    components: {
        AppButton,
        EditableField,
        TheBuyItNowToggle,
    },
    emits: ['input', 'update:additionalPricingDetails'],
    setup(props, context) {
        const { isUserAdmin, isUserInspector, isUserDsr } = useUserRole();
        const updatedPricing = ref({
            startingPrice: props.isMarketplaceListing ? undefined : toCurrency(props.startingPrice),
            reservePrice: toCurrency(props.reservePrice),
            buyItNowPrice: toCurrency(props.buyItNowPrice),
            canBuyItNow: props.canBuyItNow,
        });

        const { emitUpdates } = useEditableFields({ context });
        function emitPricing() {
            emitUpdates({
                startingPrice: props.isMarketplaceListing ? undefined : dollarAmountToInt(updatedPricing.value.startingPrice),
                reservePrice: dollarAmountToInt(updatedPricing.value.reservePrice),
                buyItNowPrice: dollarAmountToInt(updatedPricing.value.buyItNowPrice),
                canBuyItNow: updatedPricing.value.canBuyItNow,
            });
        }

        const { 
            isStartingPriceValid,
            startingPriceInvalidReason,
            isReservePriceValid,
            reservePriceInvalidReason,
            isBuyItNowPriceValid,
            buyItNowPriceInvalidReason,
        } = useValidatePricing(updatedPricing);

        async function openPricingDetailsModal() {
            const updatedPricingDetails = await createVehiclePricingDetailsModal(props.vehicleListingId, {
                props: { 
                    pricingDetails: props.additionalPricingDetails,
                }
            });
            if (updatedPricingDetails) {
                context.emit('update:additionalPricingDetails', updatedPricingDetails);
            }
        }

        return {
            toCurrency,
            isUserAdmin, 
            isUserDsr,
            isUserInspector,
            
            updatedPricing,
            emitPricing,
            openPricingDetailsModal,

            isStartingPriceValid,
            startingPriceInvalidReason,
            isReservePriceValid,
            reservePriceInvalidReason,
            isBuyItNowPriceValid,
            buyItNowPriceInvalidReason,
        }
    },
});
