
import { defineComponent, onMounted, ref } from 'vue';
import { useAssignInspector, useGetInspectorOptions } from '../composables';

import AppEditableField from './AppEditableField.vue';
import AutocompleteInput from './AutocompleteInput.vue';
import ButtonIcon from './ButtonIcon.vue';

export default defineComponent({
    name: 'TheAssignInspectorEditableField',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        inspectorPersonId: {
            type: Number,
            required: false,
        },
        selfAssignIfLoggedInInspector: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        AppEditableField,
        AutocompleteInput,
        ButtonIcon,
    },
    emits: ['select'],
    setup(props, context) {
        const isLoading = ref(true);
        const { inspectorOptions, getInspectorOptions, loadingInspectorOptions } = useGetInspectorOptions();
        onMounted(() => getInspectorOptions({
            onSuccess: (res) => {
                if (props.inspectorPersonId) {
                    assignInspectorFromInspectorOptions(props.inspectorPersonId);
                } else if (props.selfAssignIfLoggedInInspector) {
                    assignLoggedInInspector();
                }
            }
        }));

        const { 
            assignedInspector, 
            assignedInspectorPersonId, 
            assignInspectorFromInspectorOptions, 
            assignLoggedInInspector, 
            assignInspector, 
            updateAssignedInspector,
            inspectorKey, 
            loadingSaveInspector, 
        } = useAssignInspector({
            vehicleListingId: props.vehicleListingId,
            inspectorPersonId: props.inspectorPersonId,
            inspectorOptions,
            context,
        });

        return {
            isLoading,
            assignInspector,
            assignedInspector,
            assignedInspectorPersonId,
            inspectorKey,
            updateAssignedInspector,

            inspectorOptions,
            loadingInspectorOptions,
            loadingSaveInspector,
        }
    },
});
