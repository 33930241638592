
import { defineComponent } from 'vue';

import ButtonIcon from './ButtonIcon.vue';

export default defineComponent({
    name: 'TheListingWatchlistButton',
    props: {
        isToggled: {
            type: Boolean,
            required: true,
        },
    },
    components: { ButtonIcon },
    emits: ['toggle', 'update:isToggled'],
    setup(props, { emit }) {
        function toggle() {
            emit('toggle', !props.isToggled);
            emit('update:isToggled', !props.isToggled);
        }

        return {
            toggle,
        };
    },
});
