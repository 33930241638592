export type VehicleStatus =
  | 'Registered'
  | 'Reviewed'
  | 'InspectionScheduled'
  | 'Inspected'
  | 'AuctionScheduled'
  | 'Auctioning'
  | 'Negotiating'
  | 'Sold'
  | 'Checkout'
  | 'Completed'
  | 'Purchased'
  | 'NotSold'
  | 'Inactive'
  | 'DirectSubmitted'
  | 'DirectAwaitingOfferResponse'
  | 'DirectAwaitingCounterOfferResponse'
  | 'DirectCounterOfferExpired'
  | 'DirectAwaitingDropoff'
  | 'DirectDropoffWindowExpired'
  | 'DirectAccepted'
  | 'DirectDeclined'
  | 'InstantOfferAccepted'
  | 'InstantOfferCompleted'
  | 'InstantOfferLost'
  | 'MarketplacePending'
  | 'InMarketplace'
  | 'MarketplaceCheckout'
  | 'MarketplaceSold'
  | 'MarketplaceCompleted'
  | 'MarketplaceNotSold'
  | 'SecondChance'
  | 'SecondChanceCheckout'
  | 'SecondChanceSold'
  | 'SecondChanceCompleted'
  | 'SecondChanceNotSold';

export const SecondChanceStatuses = [
  'SecondChance',
  'SecondChanceCheckout',
  'SecondChanceSold',
  'SecondChanceCompleted',
  'SecondChanceNotSold',
]