import { APIConfig } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, openErrorDialog } from "@/utils";
import { GET } from ".";

export async function getAddressAutocomplete(partialAddress: string, config: APIConfig={}) {
    return await GET(`/appointments/googlePlacesAutocompleteRestrictAddresses/${partialAddress}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to match address',
                message: `We're unable to find matches for address: ${partialAddress}. Please try again or contact support.`,
                error,
            });
        });
}
