import { getTransportationCostUsingZipCodes } from '@/api';
import store from '@/vuex';

export function setDefaultTransportationZip() {
  if (store.state.transportation.zip) {
    return;
  }
  const stores = store.getters.getStores;
  if (stores.length) {
    store.commit('updateTransportation', { zip: stores[0].transportZip });
  }
}

export async function getTransportationCostFromSavedUserZip({ listingZip, vehicleListingId }: { listingZip: string, vehicleListingId: number }): Promise<number | undefined> {
  const zip1 = store?.getters.getTransportationZip; // Buyers zip
  if (!zip1 || !listingZip) {
    return undefined;
  }
  
  return await getTransportationCostUsingZipCodes({
    zip1,
    zip2: listingZip,
    vehicleListingId,
  });
}
