
import { computed, defineComponent, PropType } from 'vue';
import { ListingActivityType } from '@/types';

export default defineComponent({
    name: 'AuctionStat',
    props: {
        name: {
            type: String as PropType<ListingActivityType>,
            required: true,
        },
        value: {
            type: [String, Number],
            default: 0,
        },
        icon: {
            type: String,
            required: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const iconForActivityType = computed(() => {
            if (props.icon) {
                return props.icon;
            }
            switch(props.name) {
                case 'views':
                    return 'eye';
                case 'bids':
                case 'offers':
                    return 'gavel';
                default:
                    return undefined;
            }
        });

        return {
            iconForActivityType,
        }
    },
});
