
import { BidHistoryBid, BidHistoryListing, HighestBidRefSnapshot } from '../../types';
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { useBidHistoryModal, useSingleAuctionListener } from '@/composables';

import AppButton from '../AppButton.vue';

export default defineComponent({
    name: 'ViewBidHistoryButton',
    props: {
        vehicleListingId: {
            type: Number as PropType<number>,
            required: false,
        },
        vehicle: {
            type: Object as PropType<BidHistoryListing>,
            required: true,
        },
        highestBid: {
            type: Object,
            required: false,
        },
        highestProxyBid: {
            type: [Object, null] as PropType<BidHistoryBid | null>,
            required: false,
        },
        bidHistory: {
            type: Array as PropType<BidHistoryBid[]>,
            required: false,
        }
    },
    components: {
        AppButton,
    },
    emits: ['open', 'close'],
    setup(props, context) {
        const fetchedBidHistory: Ref<BidHistoryBid[]> = ref([]);
        const vehicleListingIdProp = props.vehicleListingId ?? props.vehicle.id;
        const bidHistoryRef = computed(() => fetchedBidHistory.value ?? props.bidHistory ?? []);

        const {
            openBidHistoryModal,
            loadingBidHistory,
            loadingBidHistoryVehicleListingId,
        } = useBidHistoryModal({
            context,
        });

        const highestBidRef = ref(props.highestBid);
        if (!props.highestBid) {
            function updateHighestBidRef(payload: HighestBidRefSnapshot) {
                highestBidRef.value = payload.newSnapshotValue;
            }
            useSingleAuctionListener(props.vehicle.id, updateHighestBidRef);
        }

        function openModal() {
            openBidHistoryModal({
                vehicleListingId: vehicleListingIdProp,
                vehicle: props.vehicle,
                highestBid: highestBidRef.value,
                bidHistory: props.bidHistory,
                highestProxyBid: props.highestProxyBid,
            });
        }

        const getLoadingBidHistory = computed(() => {
            if (loadingBidHistoryVehicleListingId.value !== undefined) {
                return vehicleListingIdProp == loadingBidHistoryVehicleListingId.value;
            }
            return loadingBidHistory.value;
        });
        
        return {
            openBidHistoryModal,
            getLoadingBidHistory,
            bidHistoryRef,
            openModal,
        }
    },
});
