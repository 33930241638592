
import { defineComponent, PropType } from 'vue';
import { useFetchSellingStat } from '../composables';
import { SellerStat } from '../types';

import AppLoadingSkeleton from './AppLoadingSkeleton.vue';

export default defineComponent({
    name: 'TheListingDetailsSellerStat',
    props: {
        sellerStoreId: {
            type: Number,
            required: false,
        },
        stat: {
            type: String as PropType<SellerStat>,
            required: true,
        },
        statValue: {
            type: String,
            required: false,
        }
    },
    components: {
        AppLoadingSkeleton,
    },
    emits: [],
    setup(props, context) {
        const { sellingStat, loadingStat } = useFetchSellingStat({
            sellerStoreId: props.sellerStoreId,
            statName: props.stat,
        });

        return {
            sellingStat,
            loadingStat,
        }
    },
});
