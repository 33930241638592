<template>
    <div class="schedule-auction-container flex-row align-center justify-center">
        <ScheduleAuctionDateTimePickers 
            @startTime="setStartTime"
            @endTime="setEndTime"
            :vehicleListingIds="vehicleListingIds"
            :customConfirm="shouldSubmit"
            @success="$emit('success', vehicleListingIds); shouldSubmit = false;"
            @cancel='handleCancel'
            :initialStartTime="initialStartTime"
            :initialEndTime="initialEndTime"
            :key="`datepicker-${updateKey}`"
            :autofillEndTime="autofillEndTime"
        />

        <button
            v-if="hasButton"
            :disabled="!startTime || !endTime || forceDisableConfirm"
            class="button is-primary"
            @click="shouldSubmit = true;"
          >
            <span>Confirm</span>
        </button>
    </div>
</template>

<script>
import ScheduleAuctionDateTimePickers from './ScheduleAuctionDateTimePickers.vue';

export default {
    name: 'ScheduleAuction',
    props: {
        vehicleListingIds: {
            type: Array, // Number array
            required: true,
        },
        hasButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        autofillEndTime: {
            type: Boolean,
            required: false,
            default: false,
        },
        initialStartTime: {
            type: Date,
            required: false,
        },
        initialEndTime: {
            type: Date,
            required: false,
        },
        forceDisableConfirm: {
            type: Boolean,
            required: false,
            default: false,
        },
        customConfirm: {
            type: Boolean,
            required: false,
            default: false,
            // true if scheduleAuction should be triggered
        }
    },
    components: {
        ScheduleAuctionDateTimePickers,
    },
    data() {
        return {
            startTime: null,
            endTime: null,
            shouldSubmit: false,
            updateKey: 0,
        }
    },

    mounted() {
        if (this.initialStartTime) this.setStartTime(this.initialStartTime);
        if (this.initialEndTime) this.setEndTime(this.initialEndTime);

        if (this.customConfirm) {
            this.shouldSubmit = true;
            this.updateKey++;
        }
    },
    methods: {
        setStartTime(selectedTime) {
            this.startTime = selectedTime;
            this.$emit('startTime', selectedTime);
        },
        setEndTime(selectedTime) {
            this.endTime = selectedTime;
            this.$emit('endTime', selectedTime);
        },
        handleCancel() {
            this.shouldSubmit = false;
            this.$emit('cancel');
        }
    },
}
</script>

<style scoped lang='postcss'>
.schedule-auction-container {
    margin-bottom: 1.5rem;
    width: 100%;
}
.button {
    margin-left: 1rem;
}
@media screen and (max-width: 830px) {
    .schedule-auction-container {
        align-items: center !important;
    }
}

@media screen and (max-width: 680px) {
    .schedule-auction-container {
        flex-direction: column !important;
    }

    .button {
        width: 200px;
        height: 2.5em;
        margin-top: 1rem;
        margin-left: 0;
    }
}
</style>