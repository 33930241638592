
import { defineComponent } from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import { formatDate } from '../utils';

import AppButton from './AppButton.vue';

interface NegotiationMessageSender {
    personId: number,
    firstName: string,
    lastName: string,
}

export default defineComponent({
    name: 'TheNegotiationMessageMostRecent',
    props: {
        sender: {
            type: Object as PropType<NegotiationMessageSender>,
            required: true,
        },
        date: {
            type: Date,
            required: false,
        },
        message: {
            type: String,
            required: true,
        },
        canViewAllMessages: {
            type: Boolean,
            default: false,
        },
        loadingMessages: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppButton,
    },
    setup() {
        return {
            formatDate,
        }
    }
})
