var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"create-announcement-form flex-column"},[_c('AddAttachmentsButton',_vm._b({staticClass:"width-fit-content margin-auto position-relative",on:{"select":function($event){return _vm.addSelectedPhoto($event.length ? $event[0] : undefined)},"snapshot":function($event){return _vm.addSelectedPhoto({ file: $event })},"selectListingPhotos":_vm.openSelectListingPhotosModal},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [(_vm.loadingUploadImages)?_c('div',{staticClass:"position-relative my-2",staticStyle:{"height":"50px","width":"50px"}},[_c('div',{staticClass:"loading-container center-content"},[_c('AppLoading',{attrs:{"active":"","isFullPage":false}})],1)]):_c('a',{staticClass:"is-flex width-fit-content"},[_c('AppImage',_vm._b({staticClass:"announcement-preview-image",class:{ 'is-preview': _vm.uploadedImageUrl }},'AppImage',{
                        src: _vm.uploadedImageUrl ?? require('@/assets/upload.png'),
                        radio: '1by1',
                    },false))],1)]},proxy:true}])},'AddAttachmentsButton',{
            filePath: _vm.imageUploadFilePath,
            immediate: false,
            isDropdown: true,
            uploadTypes: _vm.$props.vehicleListingPhotos?.length ? ['library', 'camera', 'listing'] : ['library', 'camera'],
            multiple: false,
        },false)),(_vm.uploadedImageUrl)?_c('a',{staticClass:"underline has-text-dark flex-row align-end justify-center is-size-7",on:{"click":_vm.removePhoto}},[_c('b-icon',{attrs:{"icon":"close","size":"is-small"}}),_c('span',[_vm._v("remove")])],1):_vm._e(),(_vm.uploadedImageUrl && _vm.hasPhotoToUpload)?_c('AppDropdown',_vm._b({on:{"select":function($event){return _vm.updateVehicleListingPhotoType($event)}},model:{value:(_vm.vehicleListingPhotoType),callback:function ($$v) {_vm.vehicleListingPhotoType=$$v},expression:"vehicleListingPhotoType"}},'AppDropdown',{
            options: ['Exterior', 'Interior', 'Damage'],
            appendToBody: true,
            fieldAttrs: {
                label: 'Photo Type*',
                required: true,
            },
            buttonAttrs: {
                label: _vm.vehicleListingPhotoType ?? 'Select photo type',
            },
        },false)):_vm._e(),_c('div',{staticClass:"flex-row align-end"},[_c('AppDropdown',_vm._b({staticClass:"mr-1 category-input",staticStyle:{"width":"80%"},model:{value:(_vm.selectedCategoryId),callback:function ($$v) {_vm.selectedCategoryId=$$v},expression:"selectedCategoryId"}},'AppDropdown',{
                options: _vm.categoryOptions,
                optionLabelKey: 'displayValue',
                optionValueKey: 'id',
                appendToBody: true,
                fieldAttrs: {
                    label: 'Category*',
                    required: true,
                },
                buttonAttrs: {
                    label: _vm.selectedCategory ? _vm.selectedCategory.displayValue : 'Select',
                },
                placeholder: _vm.selectedCategory ? _vm.selectedCategory.displayValue : 'Select',
            },false)),_c('AppDropdown',_vm._b({staticStyle:{"width":"20%"},scopedSlots:_vm._u([{key:"buttonLabel",fn:function(){return [_c('b-icon',{staticClass:"mr-2",class:_vm.getIconColorByAnnouncementType(_vm.selectedAnnouncementType?.type),attrs:{"icon":_vm.selectedAnnouncementType ? 'alert-circle' : 'alert-circle-outline'}})]},proxy:true},{key:"dropdownItem",fn:function({ option, isSelected }){return [_c('div',{staticClass:"flex-row align-center"},[_c('b-icon',{staticClass:"mr-2",class:isSelected ? 'is-white' : _vm.getIconColorByAnnouncementType(option.type),attrs:{"icon":"alert-circle"}}),_c('span',[_vm._v(_vm._s(option.displayValue))])],1)]}}]),model:{value:(_vm.selectedAnnouncementTypeId),callback:function ($$v) {_vm.selectedAnnouncementTypeId=$$v},expression:"selectedAnnouncementTypeId"}},'AppDropdown',{
                options: _vm.announcementTypeOptions,
                optionLabelKey: 'displayValue',
                optionValueKey: 'id',
                appendToBody: true,
            },false))],1),_c('AppDropdown',_vm._b({staticClass:"mr-1",model:{value:(_vm.selectedSubcategoryId),callback:function ($$v) {_vm.selectedSubcategoryId=$$v},expression:"selectedSubcategoryId"}},'AppDropdown',{
                options: _vm.subcategoryOptions,
                optionLabelKey: 'displayValue',
                optionValueKey: 'id',
                appendToBody: true,
                fieldAttrs: {
                    label: 'Subcategory*',
                    required: true,
                },
                disabled: !_vm.selectedCategoryId,
                buttonAttrs: {
                    loading: _vm.loadingSubcategoryOptions,
                    label: _vm.selectedSubcategory ? _vm.selectedSubcategory.displayValue : 'Select',
                },
                placeholder: _vm.selectedSubcategory ? _vm.selectedSubcategory.displayValue : 'Select',
            },false)),_c('AutocompleteInput',_vm._b({model:{value:(_vm.announcementText),callback:function ($$v) {_vm.announcementText=$$v},expression:"announcementText"}},'AutocompleteInput',{
            options: _vm.announcementTextAutocompleteOptions,
            loading: _vm.loadingAutocompleteOptions,
            autofocus: false,
            initialInput: _vm.announcementText,
            placeholder: 'Add a note',
            clearable: true,
            field: 'text',
            optionValueKey: 'text',
            appendToBody: true,
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }