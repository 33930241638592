
import { computed, defineComponent, PropType } from 'vue';
import { toCurrencyShowDashesIfNull } from '../utils';

export default defineComponent({
    name: 'ItemizedBill',
    props: {
        title: {
            type: String,
            required: false,
        },
        items: {
            type: Object as PropType<{ [item: string]: number }>,
            required: true,
        },
        showTotal: {
            type: Boolean,
            default: true,
        }
    },
    setup(props) {
        const totalCost = computed(() => Object.values(props.items).reduce((total, itemCost) => total + itemCost, 0));

        return {
            toCurrencyShowDashesIfNull,
            totalCost,
        }
    },
});
