import { openModal } from "../utils/buefyUtils";
import TheOverdriveDetailsModal from '../components/TheOverdriveDetailsModal.vue';

export const overdriveService = {
    getOverdriveIconByLevel: (overdriveLevel: number): string | undefined => {
        switch(overdriveLevel) {
            case 1:
                return `\u{1F68C}`;
            case 2:
                return `\u{1F6A8}`;
            case 3:
                return `\u{1F525}`;
            default: 
                return undefined;
        }
    },
    openOverdriveDetailsModal: () => {
        openModal({
            component: TheOverdriveDetailsModal,
        });
    },
    wasFalseAndIsTrue: (newValue?: boolean, oldValue?: boolean) => {
        return Boolean(oldValue == false && newValue);
    }
}