import axios from "axios";
import store from '@/vuex';
import router from '@/router';
import { User } from "@/composables";
import { formattedPhoneNumberToDigits, phoneFormatter } from "@/validation";
import { capitalize } from "lodash";
import { GET, getURL, POST } from ".";
import { fireEvent } from "@/segment";
import firebase from 'firebase/compat/app';
import { auth } from '@/firebase';
import { 
    APIConfig, 
    CreateProfileDTO, 
    RegisterPayloadDTO, 
    UserProfileDTO,
} from "@/types";
import { 
    applyAPIConfigOnError, 
    applyAPIConfigOnSuccess, 
    formatCelebrateValidationError, 
    openErrorDialog, 
    setDefaultTransportationZip,
} from "@/utils";
interface GoogleProfile {
    given_name: string;
    family_name: string;
  }
  
interface TokenPayloadDTO {
    accessToken: string;
    expiresIn: string;
    refreshToken: string;
    userId: string;
}

export function isLoggedIn() {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem('accessToken')) {
        return reject('No token is stored locally');
      }
      GET('/auth/check-authentication')
        .then(() => resolve(localStorage.getItem('accessToken')))
        .catch((error) => reject(error));
    });
}

export async function checkForExistingPhoneNumber(phoneNumber: string): Promise<boolean> {
    const response = await GET(`auth/checkIfPhoneNumberHasUser/${phoneNumber}`)
        .catch(() => {
            return { data: false};
        });

    return response.data;
}

export const checkLogin = isLoggedIn; // alias

export async function tokenExpires() {
    if ((await refreshToken()) !== 'ok') {
      const lastKnownLocation = store.state.lastPath;
      store.commit('logout');
      store.commit('updateMyLastPath', lastKnownLocation);
      return router.push('/login');
    }
}

export async function refreshToken() {
    if (!isLoggedIn) {
        return 'notLoggedIn';
    }

    try {
        store.state.user.stsTokenManager.expirationTime = Date.now() + 3600000;
        const refreshRes = await POST<TokenPayloadDTO>('/auth/refresh-token', {
        refreshToken: store.state.user.stsTokenManager.refreshToken,
        });
        const { accessToken, refreshToken } = refreshRes.data;
        store.commit('refreshToken', { accessToken, refreshToken });
        return 'ok';
    } catch (error) {
        return `error refreshing token: ${error}`;
    }
}

export async function logout() {
    try {
        if (store.state.user.stsTokenManager.stashedRefreshToken) {
            await refreshStashedToken();
            await resetUserWithStashedToken();
            return router.push('/');
        }
    } catch (error) {
        console.log(error);

        //remove the stashed token to avoid a loop that logs the user back in
        store.commit('removeStashedToken');
    }

    fireEvent('User Logged Out', {});
    store.commit('logout');
    localStorage.removeItem('carmunity-web-app');
    localStorage.removeItem('accessToken');
    // window.location.href = '/#/';
    return router.push('/');
}

async function refreshStashedToken() {
    store.commit('resetTokensWithStash');
    await refreshToken();
}

export async function resetUserWithStashedToken() {
    const response = await GET('/auth/returnUserByAccessToken');

    const user = {
        ...response.data.user,
        isNewUser: response.data.additionalUserInfo.isNewUser,
        profile: response.data.profile,
    };

    store.commit('clearReadNotificationIds');
    store.commit('login', user);
    setDefaultTransportationZip();
}

export function login(data: { username: string, password: string }) {
    return new Promise((resolve, reject) => {
        axios
            .post(getURL('/auth/login'), {
                email: data.username.toLowerCase(),
                password: data.password,
            })
            .then(async (response) => {
                let user: Partial<User> = {};
                const userToken = response.data.user.stsTokenManager.accessToken;
                // deprecated
                localStorage.setItem('accessToken', userToken);
                user = {
                    ...response.data.user,
                    isNewUser: response.data.additionalUserInfo.isNewUser,
                    profile: response.data.profile,
                };
                store.commit('login', user);
                setDefaultTransportationZip();
                resolve(user);
            })
            .catch((error) => {
                if (error?.response?.data) {
                    const errorFormatted = error.response.data;
                    if (errorFormatted.message === 'Wrong identifier.') {
                        reject(new Error('No User Found'));
                    } else if (errorFormatted.message === 'Wrong password.') {
                        reject(new Error('Wrong Password'));
                    }
                }
                reject(error);
            });
    });
}


export async function oauthLogin(providerName: 'google') {
    const provider = (() => {
        switch (providerName) {
            case 'google': return new firebase.auth.GoogleAuthProvider();
            default: return null;
        }
    })();

    if (!provider) {
        throw new Error(`The provider "${providerName}" is unsupported`);
    }

    const result: { error: null | string } = { error: null };

    try {
        const userCredential = await auth.signInWithPopup(provider);

        if (!userCredential.user) {
            // This should not happen. Weird firebase user types.
            throw new Error('No User Found.');
        }

        const { isNewUser, profile } = userCredential.additionalUserInfo as firebase.auth.AdditionalUserInfo;
        const { given_name: givenName, family_name: lastName } = profile as GoogleProfile;

        const userToken = await userCredential.user.getIdToken();
        localStorage.setItem('accessToken', userToken);

        let userProfile: UserProfileDTO | null = null;

        if (isNewUser) {
            const response = await POST<UserProfileDTO>('/auth/profile', {
                firstName: givenName,
                lastName,
                email: userCredential.user.email,
            });
            userProfile = response.data;
        } else {
            const response = await GET<UserProfileDTO>('/auth/profiles/me');
            userProfile = response.data;
        }

        const user = {
            ...userCredential.user.toJSON(),
            isNewUser,
            profile: userProfile,
        };
        store.commit('login', user);
        setDefaultTransportationZip();
    } catch (error) {
        // Handle Errors here.
        const { code: errorCode, message } = error as firebase.auth.Error;
        result.error = message;

        if (errorCode === 'auth/account-exists-with-different-credential') {
            // ... link with existing account
        }
    }

    return result;
}

export async function getPhoneCode(phoneNumber: string) {
    //I think this makes sense here to check that the number exists in the database before attempting a login
    //If this is being hit from the login screen, they definitely would not be in the middle of attempting to submit a vehicle
    //If this is being hit from vehicle submission, it's coming after the lead has been registered so the number should be in the db

    const phoneRecordExists = await checkForExistingPhoneNumber(phoneNumber);
    if (!phoneRecordExists) {
        throw new Error(`No user found with number: ${phoneFormatter(phoneNumber)}`);
    }

    fireEvent('Requested Code', { phoneNumber : phoneNumber });
    const phoneWithCountryCode = `+1${phoneNumber}`;

    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;
    return auth
        .signInWithPhoneNumber(phoneWithCountryCode, appVerifier)
        .then(function(confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        //@ts-ignore
        window.confirmationResult = confirmationResult;
    });
}

export async function confirmPhoneCode(phoneNumber: string, code: string) {
    fireEvent('Entered Code', { phoneNumber : phoneNumber, code : code });
    //@ts-ignore
    const result = await window.confirmationResult.confirm(code)
        .then(async (result: any) => {

    if (result.additionalUserInfo.isNewUser) {
        await POST('/auth/savePhoneAuth', {
            phoneNumber: phoneNumber,
            phoneAuthId: result.user.uid,
        });
    }

    const phoneLoginResponse = await POST('/auth/loginWithPhone', {
            //@ts-ignore
            confirmationResult: window.recaptchaVerifier,
            phoneNumber: phoneNumber,
            result: result,
        }).then(async (response) => {
            let user: Partial<User> = {};
            const userToken = response.data.user.stsTokenManager.accessToken;
            localStorage.setItem('accessToken', userToken);

            user = {
                ...response.data.user,
                isNewUser: response.data.additionalUserInfo.isNewUser,
                profile: response.data.profile
            };

            store.commit('login', user);
            setDefaultTransportationZip();
            return user;
        });

        return phoneLoginResponse;
    });
    return result;
}

export async function loginWithPhone(phoneNumber: any, code: string) {
    if (code) {
        // @ts-ignore
        const result = await window.confirmationResult.confirm(code);
        const userToken = await result.user.getIdToken();

        localStorage.setItem('accessToken', userToken);
    } else {
        // @ts-ignore
        const appVerifier = window.recaptchaVerifier;
        return auth
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            // @ts-ignore
            window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export async function addAddressToProfileByEmail(payload: { email: string, address: string, placeId: string }, config: APIConfig={}) {
    return await POST(`/auth/addAddressToProfile`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to add address',
                message: celebrateValidationMessage ??  `We encountered an error adding address ${payload.address} to profile ${payload.email}. Please try again or contact support.`,
                error,
            });
        });
}

export async function getResetPasswordLinkByEmail(email: string, config: APIConfig={}) {
    return await GET(`/auth/getResetPasswordLink/${email}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch reset password link',
                message: `We encountered an error fetching the Reset Password link for email ${email}. Please try again later or contact support.`,
                error,
            });
        });
}


// Throws an error if reset password is unavailable, otherwise returns an firebase-admin UserRecord
export async function getIsResetPasswordAvailableByEmail(email: string, config: APIConfig={}) {
    return await GET(`/auth/authUser/${email}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function signUpByEmail(payload: RegisterPayloadDTO, config: APIConfig={}) {
    return await POST(`/auth/signUpEmail`, {
        ...payload,
        firstName: capitalize(payload.firstName),
        lastName: capitalize(payload.lastName),
        email: payload.email.toLowerCase(),
        phoneNumber: formattedPhoneNumberToDigits(payload.phoneNumber).toString(),
    }).then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            let formattedError = error.response?.data;
            if (formattedError.error.constraint == 'phone_number_unique') {
                return openErrorDialog({
                    title: 'This phone number is already in use',
                    message: 'Log in using your existing account or use a different phone number.',
                    error,
                });
            }
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: formattedError.title ?? 'Sign up failed',
                message: celebrateValidationMessage ?? formattedError.message ?? `We encountered an error while registering your account. Please try again or contact support.`, 
                error,
            });
        });
}

export async function getNewAuthProfile(userId: number, config: APIConfig={}) {
    return await GET(`/auth/newProfile/${userId}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch profile',
                message: `We encountered an error fetching the profile for user ${userId}. Please try again or contact support.`, 
                error,
            });
        });
}

export async function registerLead(data: CreateProfileDTO, config: APIConfig={}) {
    return await POST(`/auth/registerLead`, data)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}