
import { computed, ComputedRef, defineComponent, PropType, ref, Ref } from 'vue';
import { VehicleListingAnnouncementCategorizedDTO } from '../types';
import { getIconColorByAnnouncementType, getMostSevereAnnouncementType, getMostSevereAnnouncementTypeFromList } from '@/utils';
import { omit } from 'lodash';

import TheListingDetailsCard from './TheListingDetailsCard.vue';
import TheVehicleListingAnnouncements from './TheVehicleListingAnnouncements.vue'

export default defineComponent({
    name: 'TheListingDetailsAnnouncements',
    props: {
        announcements: {
            type: Object as PropType<VehicleListingAnnouncementCategorizedDTO>,
            required: false,
        },
        numAnnouncements: {
            type: Number,
            required: false,
        },
        showAllAnnouncements: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TheListingDetailsCard,
        TheVehicleListingAnnouncements,
    },
    emits: ['viewPhoto', 'edit', 'delete'],
    setup(props, { emit, attrs }) {
        const announcementsRef: ComputedRef<VehicleListingAnnouncementCategorizedDTO | undefined> = computed(() => props.announcements as VehicleListingAnnouncementCategorizedDTO);
        const hasUnknownAnnouncements = computed(() => Boolean(announcementsRef.value?.unknown?.length));
        const announcementsWithCategory: ComputedRef<VehicleListingAnnouncementCategorizedDTO> = computed(() => omit(announcementsRef.value, 'unknown'));
        const getMostSevereIconColor: ComputedRef<string | undefined> = computed(() => {
            let mostSevereAnnouncementType = hasUnknownAnnouncements.value && !props.showAllAnnouncements
                ? getMostSevereAnnouncementTypeFromList(announcementsRef.value?.unknown)
                : getMostSevereAnnouncementType(announcementsRef.value);

            return getIconColorByAnnouncementType(mostSevereAnnouncementType);
        });

        return {
            getIconColorByAnnouncementType,
            hasUnknownAnnouncements,
            announcementsWithCategory,
            getMostSevereIconColor,
            announcementsRef,
        }
    },
});
