
import { defineComponent, PropType, Ref, ref } from 'vue';
import { dollarAmountToInt, focusOnElement, toCurrency } from '../utils';

import AppRadioButton from './AppRadioButton.vue';
import AppInputFieldNumber from './AppInputFieldNumber.vue';
import Modal from './Modal.vue';

type PriceType = 'reservePrice' | 'negotiatedPrice' | 'customPrice';

export default defineComponent({
    name: 'TheSendToSecondChanceModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehicleName: {
            type: String,
            required: false,
        },
        reservePrice: {
            type: Number,
            required: true,
        },
        lowestNegotiatedPrice: {
            type: Number,
            required: false,
        },
    },
    components: {
        AppRadioButton,
        AppInputFieldNumber,
        Modal,
    },
    emits: ['confirm', 'close'],
    setup(props, { emit, attrs }) {
        const selectedReservePrice = ref(props.reservePrice);
        const selectedPriceType: Ref<PriceType | undefined> = ref('reservePrice');
        const customPrice = ref(undefined);

        function focusCustomPrice() {
            selectPrice('customPrice');
            focusOnElement('custom-price-input');
        }

        function selectPrice(priceType: PriceType) {
            selectedPriceType.value = priceType;
            switch(priceType) {
                case 'reservePrice':
                    selectedReservePrice.value = props.reservePrice;
                    break;
                case 'negotiatedPrice':
                    selectedReservePrice.value = props.lowestNegotiatedPrice;
                    break;
                case 'customPrice':
                    selectedReservePrice.value = dollarAmountToInt(customPrice.value);
                    break;
            }
        }

        function updateCustomPrice(input) {
            customPrice.value = input;
            if (selectedPriceType.value == 'customPrice') {
                selectPrice('customPrice');
            }
        }
        
        return {
            toCurrency,
            focusCustomPrice,
            selectedReservePrice,
            selectedPriceType,
            selectPrice,
            customPrice,
            updateCustomPrice,
        }
    },
});
