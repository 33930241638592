
import { defineComponent, PropType } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useBreakpoint, useEditableFields, useEditableVDP, useSellerVDP } from '../composables';
import { SRPListing } from '../types';
import { openAlertDialog, redirectTo } from '../utils';

import AppButton from '../components/AppButton.vue';
import AppListingDetailsEditable from './AppListingDetailsEditable.vue';

export default defineComponent({
    name: 'AppListingDetailsSeller',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
    },
    components: {
        AppButton,
        AppListingDetailsEditable,
    },
    emits: ['input'],
    setup(props, context) {
        const route = useRoute();
        const vehicleListingId = Number(route.params.id);
        const { windowWidth } = useBreakpoint();

        const {
            vehiclePhotos,
            loadingVehiclePhotos,
            vehiclePhotosDTO,
            announcements,
            loadingAnnouncements,
        } = useSellerVDP({ vehicleListingId });


        const { unsavedUpdates, addToUnsavedUpdates } = useEditableFields({ context });

        const { saveUpdates, loadingSaveUpdates, movePricingToBasicInfoForInspectionPayload, updatedListing, updateListingFromSaveUpdatesResponse, updateListingVehiclePhotos, listingKey } = useEditableVDP({
            vehicleListingId,
            originalListing: props.listing,
            unsavedUpdates,
            announcements, 
            vehiclePhotos,
            vehiclePhotosDTO,
        });

        async function saveUpdatesAndRedirect(vehicleListingId: number, vehicleName: string, storeId: number=0) {
            const pricing = movePricingToBasicInfoForInspectionPayload();
            await saveUpdates({
                pricing,
                onSuccess: ({ response, sectionName, pricing }) => {
                    updateListingFromSaveUpdatesResponse({ response, sectionName, pricing });
                },
                onPromiseSuccess: () => {
                    const vehicleName = `${updatedListing.value.year} ${updatedListing.value.brand}`;
                    openAlertDialog({
                        title: 'Listing created!',
                        message: `Your ${vehicleName}'s listing has been submitted and is being reviewed.`,
                        confirmText: 'Back to Turn',
                        onConfirm: () => {
                            redirectTo({
                                name: 'Turn', 
                                query: {
                                    storeId: route.query.storeId,
                                    tab: route.query.fromTab,
                                }
                            });
                        },
                    });
                }
            });

        }

        return {
            addToUnsavedUpdates,
            loadingSaveUpdates,
            saveUpdatesAndRedirect,
            vehicleListingId,
            vehiclePhotos,
            loadingVehiclePhotos,
            vehiclePhotosDTO,
            announcements,
            loadingAnnouncements,
            updatedListing,
            updateListingVehiclePhotos,
            listingKey,
            windowWidth,
        }
    },
});
