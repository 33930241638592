import { openConfirmationDialog, openModal, OpenModalConfig } from "./buefyUtils";
import { groupByType, ModifierOptions, ModifierOptionsFilterObject } from '@/types';
import { BDialogConfig } from "buefy/types/components";
import { assignStorePointOfContact } from "@/api";

import AddManager from '../components/AddManager.vue';
import EditCompany from '../components/EditCompany.vue';
import TheCreateCompanyModal from '../components/TheCreateCompanyModal.vue';
import TheDealerImportModal from '../components/TheDealerImportModal.vue'

export function openDealerImportModal(config: OpenModalConfig) {
    openModal({
        component: TheDealerImportModal, 
        ...config,
    });
}

export function openCreateCompanyModal(config: OpenModalConfig) {
    openModal({
        component: TheCreateCompanyModal,
        ...config,
    });
}

export function openAddManagerModal(companyId: number, config: OpenModalConfig) {
    openModal({
        component: AddManager,
        props: {
            companyId,
        },
        ...config,
    });
}

export function openEditCompanyNameModal(companyId: number, companyName: string, config: OpenModalConfig) {
    openModal({
        component: EditCompany,
        props: {
            companyId,
            companyName,
        },
        ...config,
    });
}

export function openDeleteCompanyConfirmationDialog(companyId: number, companyName: string) {
    openConfirmationDialog({
        title: 'Delete company?',
        message: `Are you sure you want to delete ${companyName}? This action cannot be undone.`, 
        onConfirm: () => {
            console.log('CONFIRM DELETE COMPANY', companyId);
        }
    });
}

export function convertCompaniesSearchToModifierSchema(searchField?: 'companyName' | 'storeName', searchValue?: string): ModifierOptions | undefined {
    if (!searchField || !searchValue) {
        return undefined;
    }

    let filters: ModifierOptionsFilterObject[] | undefined = undefined;
    let groupBy: groupByType | undefined = undefined;

    switch (searchField) {
        case 'companyName':
            filters = [{ 
                property: 'company.name',
                comparator: 'iLike',
                values: searchValue,
            }];
            groupBy = ['company.name', 'company.id'];
            break;

        case 'storeName':
            filters = [{
                property: 'store.name',
                comparator: 'iLike',
                values: searchValue,
            }];
            groupBy = ['company.name', 'company.id'];
            break;
        default: 
            return undefined;
    }

    return {
        filters,
        groupBy,
    }
}

export function openAssignPointOfContactConfirmationDialog({ storeName, storeId, firstName, lastName, userId }: { 
    storeName: string, 
    storeId: number, 
    firstName: string,
    lastName: string,
    userId: number,
}, modalConfig: Partial<BDialogConfig>) {
    openConfirmationDialog({
        title: 'Assign point of contact',
        message: `Are you sure you want to add ${firstName} ${lastName} as the point of contact for ${storeName}?`,
        onConfirm: () => assignStorePointOfContact({ storeId, userId }),
        ...modalConfig,
    });
}