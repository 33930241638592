
import { defineComponent, PropType, Ref, ref } from 'vue';

import AppButton from './AppButton.vue';
import AutocompleteInput from './AutocompleteInput.vue';
import EditableField from './Forms/EditableField.vue';

export default defineComponent({
    name: 'AppEditableField',
    props: {
        value: {
            type: Object, // can be any object, but the value of displayKey will be displayed if selected
            required: false, 
        },
        displayKey: {
            type: String,
            default: 'name',
        },
        options: {
            type: Array as PropType<Object[]>, // the same object type as value
            default: () => { return [] },
        },
        isEditingInitial: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppButton,
        AutocompleteInput,
        EditableField,
    },
    emits: ['select', 'update:value'],
    setup(props, { emit, attrs }) {
        const selectedValue = ref(props.value);
        const isEditing: Ref<boolean> = ref(props.isEditingInitial);
        return {
            selectedValue,
            isEditing,
        }
    },
});
