
import { defineComponent } from 'vue';
import { useGetAndSaveCheckoutData } from '../composables';

import Loading from './Loading.vue';
import TheCheckoutModal from './TheCheckoutModal.vue';

export default defineComponent({
    name: 'TheCheckoutModalRegularListing',
    props: {
        vehicleName: {
            type: String,
            required: false,
        },
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
        vehiclePrice: {
            type: Number,
            required: true,
        }
    },
    components: {
        Loading,
        TheCheckoutModal,
    },
    emits: ['submitted', 'close'],
    setup(props, context) {
        const { checkoutSteps, checkoutData, isLoadingCheckoutData, isLoadingSubmitCheckout, formatAndSubmitCheckout } = useGetAndSaveCheckoutData({ vehicleListingId: props.vehicleListingId, marketplaceListingId: props.marketplaceListingId, context });

        return {
            checkoutSteps,
            checkoutData,
            isLoadingSubmitCheckout,
            isLoadingCheckoutData,
            formatAndSubmitCheckout,
        }
    },
});
