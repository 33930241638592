import { saveQuestionnaireResponses } from '@/api';
import type { 
    AccordionQuestionnaireData, 
    QuestionnaireAdditionalOptions, 
    QuestionnaireQuestion, 
    QuestionnaireResponseOption, 
    QuestionnaireResponseValue, 
    QuestionsAndResponses, 
    QuestionType,
    SaveLater,
    SaveLaterQuestion
} from '@/types';

// FORMAT QUESTIONNAIRE
export function formatShortQuestionnaire(questions: QuestionsAndResponses) {
    const questionNames = ['WhoHasTitle', 'MechanicalElectrical', 'Interior'];

    const questionnaireData: Array<AccordionQuestionnaireData> = [
        {
            stepNumber: 1, 
            title: 'Are you still making payments on your car?',
            isComplete: false,
            details: {
                type: 'suboptions',
                isSaveOnClick: true,
                options: [
                    addQuestionnaireResponseOption(questions, questionNames[0], 'Yes', ['Loan', 'Lease'], {
                        hasSuboptions: true,
                        suboptions: [
                            addQuestionnaireResponseSuboption(questions, questionNames[0], 'Loan', 'Loan'),
                            addQuestionnaireResponseSuboption(questions, questionNames[0], 'Lease', 'Lease')
                        ]
                    }),
                    addQuestionnaireResponseOption(questions, questionNames[0], 'No', 'Self')
                ]
            },
        },
        {
            stepNumber: 2, 
            title: 'Do you have 2 sets of keys?',
            isComplete: false,
            details: {
                type: 'bool',
                isSaveOnClick: true,
                options: [
                    addQuestionnaireResponseOption(questions, 'HasTwoKeys', 'Yes', 'true'),
                    addQuestionnaireResponseOption(questions, 'HasTwoKeys', 'No', 'false')
                ]
            },
        },
        {
            stepNumber: 3,
            title: 'Are there any aftermarket parts?',
            isComplete: false,
            details: {
                type: 'bool',
                isSaveOnClick: true,
                options: [
                    addQuestionnaireResponseOption(questions, 'AftermarketParts', 'Yes', 'true', { hasComment: true }),
                    addQuestionnaireResponseOption(questions, 'AftermarketParts', 'No', 'false'),
                ]
            }
        },
        {
            stepNumber: 4,
            title: 'mechanical/electrical',
            subtitle: 'Mark any issues with mechanical or electrical',
            isComplete: false,
            details: {
                type: 'multiselect',
                isSaveOnClick: true,
                options: [
                    addQuestionnaireResponseOption(questions, 'NoMechanicalOrElectricalIssues', 'no issues', 'true', { deselectOthers: true }),
                    addQuestionnaireResponseOption(questions, 'EngineIssues', 'engine', 'true', { hasComment: true, secondaryText: 'check engine light, oil light, etc.' }),
                    addQuestionnaireResponseOption(questions, 'TransmissionIssues', 'transmission', 'true', { hasComment: true, secondaryText: 'drive train issues, shifts poorly, etc.' }),
                    addQuestionnaireResponseOption(questions, 'AirConditioningHeatIssues', 'A/C and heat', 'true', { hasComment: true, secondaryText: `doesn't blow cold air, fan broken, etc.` }),
                    addQuestionnaireResponseOption(questions, 'ElectricalIssues', 'electrical', 'true', { hasComment: true, secondaryText: 'airbag/SRS light, dim headlights, radio, etc.' }),
                    // addQuestionnaireResponseOption(questions, 'TirePressureIssues', 'tire pressure', 'true', { hasComment: true, secondaryText: 'tire pressure light/TPMS' }),
                    addQuestionnaireResponseOption(questions, 'BeenInAccident', 'been in an accident', 'true', { hasComment: true }),
                    addQuestionnaireResponseOption(questions, 'IssuesPreventDriving', 'issues that prevent driving', 'true', { hasComment: true }),
                    // addQuestionnaireResponseOption(questions, 'PowerControlsIssues', 'power controls', 'true', { hasComment: true, secondaryText: 'power windows, sunroof, locks, seats, etc.' }),
                ]
            }
        }, 
        {
            stepNumber: 5,
            title: 'interior condition',
            subtitle: 'Let us know if any of the following apply to the interior',
            isComplete: false,
            details: {
                type: 'multiselect',
                isSaveOnClick: true,
                options: [
                    addQuestionnaireResponseOption(questions, 'Interior.NoDamage', 'no issues', 'true', { deselectOthers: true }),
                    addQuestionnaireResponseOption(questions, 'Interior.NoticeableStains', 'noticeable stains', 'true', { hasComment: true }),
                    // addQuestionnaireResponseOption(questions, 'Interior.PersistentOdors', 'persistent odors', 'true', { hasComment: true}),
                    addQuestionnaireResponseOption(questions, 'Interior.UpholsteryDamage', 'rips or tears in upholstery', 'true', { hasComment: true }),
                    addQuestionnaireResponseOption(questions, 'Interior.AudioVisualSystemDamage', 'damaged A/V system', 'true', { hasComment: true, secondaryText: 'entertainment, navigation, etc.' }),
                    addQuestionnaireResponseOption(questions, 'Interior.BeenSmokedIn', 'been smoked in', 'true', { hasComment: true, secondaryText: 'entertainment, navigation, etc.' }),
                ]
            }
        },
        {
            stepNumber: 6,
            title: 'exterior condition',
            subtitle: 'Tell us anything we need to know about the exterior',
            isComplete: false,
            details: {
                type: 'bool',
                isSaveOnClick: true,
                options: [
                    addQuestionnaireResponseOption(questions, 'ExteriorDamage', 'no issues', 'false'),
                    addQuestionnaireResponseOption(questions, 'ExteriorDamage', 'some damage', 'true', { hasComment: true }),
                ]
            },
        }
    ];

    // update isComplete for each question
    questionnaireData.forEach(question => {
        question.isComplete = isQuestionComplete(question.details);
    });
    return questionnaireData;
}

export function addQuestionnaireResponseOption(questionnaireQuestions: QuestionsAndResponses, questionName: string, text: string, value: QuestionnaireResponseValue, additionalOptions: QuestionnaireAdditionalOptions={}) {
    var commentDetails = additionalOptions.hasComment
        ? getQuestionnaireCommentDetails(questionnaireQuestions, questionName)
        : {};
        
        const option: QuestionnaireResponseOption = {
            questionName,
            text,
            value,
            isSelected: isQuestionnaireOptionSelected(questionnaireQuestions, value, questionName),
            hasComment: additionalOptions.hasComment,
            ...commentDetails,
            isLoading: false,
            isDisabled: false,
        }
    
        if (additionalOptions.hasSuboptions && additionalOptions.suboptions?.length) {
            option.suboptions = additionalOptions.suboptions;
        }
        if (additionalOptions.secondaryText) {
            option.secondaryText = additionalOptions.secondaryText;
        }
        if (additionalOptions.deselectOthers) {
            option.deselectOthers = additionalOptions.deselectOthers;
        }
    return option;
}
  
export function addQuestionnaireResponseSuboption(questions: QuestionsAndResponses, questionName: string, text: string, value: string) {
    return {
        text,
        value,
        isSelected: questions[questionName] == value
    };
}
  
export function addQuestionnaireSuboption(questions: any, questionName: string, text: string, value: string) {
    return {
        text,
        value,
        isSelected: questions[questionName] == value
    };
}

// COMMENTS
export function getQuestionnaireCommentDetails(questions: QuestionsAndResponses, questionName: string) {
    const commentQuestionName = `${questionName}Comment`;
    return {
        commentQuestionName,
        commentValue: questions[commentQuestionName]
    };
}

export function addCommentToQuestionnaireData(questionIdx: number, commentValue: string | null, commentQuestionName: string, questionsAndResponses: QuestionsAndResponses, responseOptions: Array<QuestionnaireResponseOption>) {
    var updatedQuestionsAndResponses: QuestionsAndResponses = Object.assign({}, questionsAndResponses);

    updatedQuestionsAndResponses[commentQuestionName] = commentValue;
    const updatedResponseOptions = responseOptions.map(option => {
        if (`${option.questionName}Comment` == commentQuestionName) {
            return {
                ...option, 
                commentValue,
                commentQuestionName, 
                updated: true
            };
        } else {
            return option;
        }
    });
    return {
        updatedQuestionsAndResponses, 
        updatedResponseOptions
    };
}

export function addCommentToSaveLater(
    updatedResponses: QuestionsAndResponses, 
    oldResponses: QuestionsAndResponses,
    oldSaveLater: SaveLater,
    questionIdx: number, 
    commentQuestionName: string, 
    commentValue: string, 
    questionToUpdate=undefined
) {
    const questions: Array<SaveLaterQuestion> = [{
        questionToUpdate,
        updatedValue: commentValue,
        questionName: commentQuestionName
    }];
    return updateSaveLater(
        { questionIdx, questions, updatedResponses, oldResponses },
        oldSaveLater
    );
}

export function commentMissing(responseOptions: Array<QuestionnaireResponseOption>) {
    const isCommentMissing = responseOptions.some(option => {
        if (option.isSelected && option.hasComment) {
            return !option.commentValue;
        }
    });
    return isCommentMissing;
}

// UPDATING UI
export function updateQuestionnaireData(
    questionName: string,
    updatedValue: QuestionnaireResponseValue,
    questionToUpdate: QuestionnaireQuestion,
    questionsAndResponses: QuestionsAndResponses,
    additionalOptions: QuestionnaireAdditionalOptions={},
) {
    var updatedQuestionsAndResponses: QuestionsAndResponses, updatedResponseOptions: Array<QuestionnaireResponseOption>;
    var changedResponses: Array<SaveLaterQuestion> = [];

    switch (questionToUpdate.type) {
        case 'suboptions':
            var { updatedResponseOptions, updatedQuestionsAndResponses } = updateSuboptionSelection(
                questionsAndResponses,
                questionToUpdate, 
                questionName,
                updatedValue,
                additionalOptions,
            );
            break;
        case 'multiselect':
            var { updatedResponseOptions, updatedQuestionsAndResponses, changedResponses } = updateMultiselectSelection(
                questionsAndResponses,
                questionToUpdate,
                questionName,
                updatedValue,
                additionalOptions
            );
            break;
        case 'input':
            var copy: QuestionsAndResponses = Object.assign({}, questionsAndResponses);
            updatedQuestionsAndResponses = defaultUpdateQuestionAndResponse(copy, questionName, updatedValue);
            updatedResponseOptions = updateInputSelection(questionToUpdate.options, questionName, updatedValue);
            changedResponses = [{ questionName, updatedValue, questionToUpdate }];
            break;
        case 'bool':
            var copy: QuestionsAndResponses = Object.assign({}, questionsAndResponses);
            updatedQuestionsAndResponses = defaultUpdateQuestionAndResponse(copy, questionName, updatedValue);
            updatedResponseOptions = updateBoolSelection(
                questionToUpdate.options,
                questionToUpdate.type,
                questionName,
                updatedValue,
                additionalOptions
            );
            changedResponses = [{ questionName, questionToUpdate, updatedValue }]
            break;
        default:
            updatedQuestionsAndResponses = {};
            updatedResponseOptions = [];
            break;

    }
    return {
        updatedQuestionsAndResponses,
        updatedResponseOptions,
        changedResponses
    };
}

export function defaultUpdateQuestionAndResponse(questionsAndResponses: QuestionsAndResponses, questionName: string, updatedValue: QuestionnaireResponseValue) {
    if (typeof updatedValue !== 'string') {
        return questionsAndResponses;
    }
    questionsAndResponses[questionName] = updatedValue;
    return questionsAndResponses;
}

export function updateBoolSelection(
    responseOptions: Array<QuestionnaireResponseOption>,
    questionType: QuestionType,
    questionName: string,
    updatedValue: QuestionnaireResponseValue,
    additionalOptions: QuestionnaireAdditionalOptions,
) {
    var updatedResponseOptions = toggleBinaryOption(responseOptions, updatedValue, questionName);
    if (!additionalOptions.isAwaitingComment) {
        updatedResponseOptions = loadAndDisableBinaryOption(responseOptions, updatedValue, questionName, questionType, true)
    }
    return updatedResponseOptions;
}

export function updateInputSelection(
    responseOptions: Array<QuestionnaireResponseOption>,
    questionName: string,
    updatedValue: QuestionnaireResponseValue,
) {
    const updatedResponseOptions = [...responseOptions];

    if (updatedValue == 'invalidInput') {
        return updatedResponseOptions;
    }

    const option = responseOptions.find(option => option.questionName == questionName);
    if (option) {
        option.isSelected = true;
        option.value = updatedValue;
    }
    return updatedResponseOptions;
}

export function updateMultiselectSelection(
    questionsAndResponses: QuestionsAndResponses,
    questionToUpdate: QuestionnaireQuestion,
    questionName: string,
    updatedValue: QuestionnaireResponseValue,
    additionalOptions: QuestionnaireAdditionalOptions,
) {
    // update QuestionsAndResponses
    var updatedQuestionsAndResponses: QuestionsAndResponses = Object.assign({}, questionsAndResponses);
    var changedResponses: Array<SaveLaterQuestion> = [];

    if (questionsAndResponses[questionName] == updatedValue) { // already selected; unselect
        updatedQuestionsAndResponses[questionName] = null;
        changedResponses.push({ questionName, questionToUpdate, updatedValue: null });
    } else if (typeof updatedValue == 'string') {
        updatedQuestionsAndResponses[questionName] = updatedValue;
        changedResponses.push({ questionName, questionToUpdate, updatedValue });
    }


    // update ResponseOptions
    var updatedResponseOptions: Array<QuestionnaireResponseOption> = [...questionToUpdate.options];
    updatedResponseOptions = questionToUpdate.options.map(option => {
        // select the updated question
        if (option.questionName == questionName) {
            option.isSelected = questionToUpdate.type == 'multiselect' ? !option.isSelected : true;
            return option;
        }

        // deselect others if needed
        if ((additionalOptions.deselectOthers || option.deselectOthers) && option.isSelected) {
            option.isSelected = false;
            if (option.questionName) {
                updatedQuestionsAndResponses[option.questionName] = null;
                changedResponses.push({ questionName: option.questionName, questionToUpdate, updatedValue: null });
            }
        }
        return option;
    });

    return {
        updatedResponseOptions,
        updatedQuestionsAndResponses,
        changedResponses,
    };
}

export function updateSuboptionSelection(
    questionsAndResponses: QuestionsAndResponses, 
    questionToUpdate: QuestionnaireQuestion, 
    questionName: string, 
    updatedValue: QuestionnaireResponseValue,
    additionalOptions: QuestionnaireAdditionalOptions
) {
    // update QuestionsAndResponses
    var updatedQuestionsAndResponses = Object.assign({}, questionsAndResponses);

    const isSuboption = additionalOptions.isSuboption || !additionalOptions.hasSuboptions;
    if (isSuboption && typeof updatedValue == 'string') {
        defaultUpdateQuestionAndResponse(updatedQuestionsAndResponses, questionName, updatedValue);
    } else { // is not suboption; has suboptions
        var selectedSuboption = null;
        
        questionToUpdate.options.forEach(option => {
            if (!option.suboptions || !option.suboptions.length) {
                return;
            }
            selectedSuboption = option.suboptions.find(suboption => suboption.isSelected);
            if (selectedSuboption && typeof selectedSuboption.value == 'string') {
                updatedQuestionsAndResponses[questionName] = selectedSuboption.value;
            } else { // if no suboption was selected, value is an array of suboption values, so don't update the value
                updatedQuestionsAndResponses[questionName] = typeof updatedValue == 'string' 
                    ? updatedValue 
                    : 'true';
            }
        })
    }    
    // update ResponseOptions for responses that have suboptions

    var updatedResponseOptions = questionToUpdate.options.map((option, idx) => {
        // option was selected
        // can be selectedWithSuboption (button clicked was a suboption button), or selectedWithoutSuboption (button clicked had suboptions)
        const optionHasSuboptions = typeof option.value != 'string' && Boolean(option.suboptions?.length);
        const selectedWithSuboption = optionHasSuboptions && typeof updatedValue == 'string' && option.value?.includes(updatedValue);
        const selectedWithoutSuboption = optionHasSuboptions && typeof updatedValue == 'object' && option.value == updatedValue;
        option.isSelected = selectedWithSuboption || selectedWithoutSuboption;

        if (selectedWithSuboption) {
            option.suboptions = option.suboptions?.map(suboption => {
                suboption.isSelected = suboption.value == updatedValue;
                return suboption;
            });
        }

        return option;
    });

    // update ResponseOptions for responses that have no suboptions
    if (!additionalOptions.isSuboption) {
        updatedResponseOptions = toggleBinaryOption(updatedResponseOptions, updatedValue, questionName);
    }
    return {
        updatedResponseOptions,
        updatedQuestionsAndResponses
    };
    
}

export function updateSelectedResponses(questionnaireData: Array<AccordionQuestionnaireData>, questionsUpdated: QuestionsAndResponses, questionIdx: number, questionType: string, questionName: string, updatedValue: QuestionnaireResponseValue, additionalOptions: QuestionnaireAdditionalOptions={}) {
    const hasSuboptions = questionType == 'suboptions' && !additionalOptions.isSuboption && additionalOptions.hasSuboptions;
    console.log('has suboptions', hasSuboptions);

    // select/deselect option
    if (questionType == 'multiselect' && questionsUpdated[questionName] == updatedValue) {
        questionsUpdated[questionName] = null;
    } else if (hasSuboptions) {
        // get selected suboption
        var selectedSuboption = null;
        questionnaireData[questionIdx].details.options.forEach((option: { suboptions?: Array<any> }) => {
            if (!option.suboptions || !option.suboptions.length) {
                return;
            }
            selectedSuboption = option.suboptions.find((suboption: {isSelected?: boolean}) => suboption.isSelected);
            if (!selectedSuboption) {
                return;
            }
            questionsUpdated[questionName] = selectedSuboption ? selectedSuboption.value : updatedValue;
        });
    } else if (typeof updatedValue == 'string') {
        questionsUpdated[questionName] = updatedValue;
    }

    const updated = {
        questionnaireData,
        questionsUpdated
    };
    // console.log('updated questionnaire data', questionnaireData, 'questionsUPdated', questionsUpdated)
    return updated;
}

export function updateSelectedOption(questionData: QuestionnaireQuestion, questionsUpdated: QuestionsAndResponses, questionType: string, questionName: string, updatedValue: QuestionnaireResponseValue, additionalOptions: QuestionnaireAdditionalOptions={}) {
    switch (questionType) {
        case 'bool':
            questionData.options = toggleBinaryOption(questionData.options, updatedValue, questionName);
            if (!additionalOptions.isAwaitingComment) {
                questionData.options = loadAndDisableBinaryOption(questionData.options, updatedValue, questionName, questionType, true);
            }
            return questionData;
        case 'multiselect':
            // find matching option & get its old isSElected value to reverse
            questionData.options = questionData.options.map(option => {
                if (option.questionName !== questionName) {
                    if (additionalOptions.deselectOthers || option.deselectOthers) {
                        option.isSelected = false;
                        if (option.questionName) {
                            questionsUpdated[option.questionName] = null;
                        }
                    }
                }
                return option;
            })
            return questionData;
        case 'suboptions':
            questionData.options = questionData.options.map(option => {
                const hasSuboptions = typeof option.value == 'object' && option.suboptions?.length;
                const hasSelectedSuboption = hasSuboptions && typeof updatedValue == 'string' && option.value?.includes(updatedValue);

                if (hasSelectedSuboption) {
                    option.isSelected = true;
                    option.suboptions = option.suboptions?.map(suboption => {
                        suboption.isSelected = suboption.value == updatedValue;
                        return suboption;
                    });
                } else {
                    option.isSelected = false;
                }
                return option;
            })
            if (!additionalOptions.isSuboption) {
                questionData.options = toggleBinaryOption(questionData.options, updatedValue, questionName)
            }
            if (!additionalOptions.hasSuboptions && !additionalOptions.isSuboption) {
                questionData.options = loadAndDisableBinaryOption(questionData.options, updatedValue, questionName, questionType, true);
            }
            if (additionalOptions.hasSuboptions && additionalOptions.isSuboption) {
                questionData.options = loadAndDisableBinaryOption(questionData.options, updatedValue, questionName, questionType, false);
            }
            return questionData;
        case 'input':
            if (updatedValue == 'invalidInput') {
                return;
            }
            const option = questionData.options.find(option => option.questionName == questionName);
            if (option) {
                option.isSelected = true;
                option.value = updatedValue;
            }
            return questionData;
    }
}

export function toggleBinaryOption(responseOptions: Array<QuestionnaireResponseOption>, updatedValue: QuestionnaireResponseValue, questionName: string) {
    responseOptions = responseOptions.map(option => {
        const shouldToggleOption = option.value == updatedValue && option.questionName == questionName;
        option.isSelected = shouldToggleOption;
        return option;
    });
    return responseOptions;
}

export function loadAndDisableBinaryOption(responseOptions: Array<QuestionnaireResponseOption>, updatedValue: QuestionnaireResponseValue, questionName: string, questionType: string, awaitingCommentOrSuboption: boolean) {
    switch (questionType) {
        case 'bool':
        case 'suboptions':
            if (awaitingCommentOrSuboption) {
                responseOptions = responseOptions.map(option => {
                    const shouldLoadOption = option.value == updatedValue && option.questionName == questionName;
                    option.isDisabled = !shouldLoadOption ? !option.isDisabled : false;
                    option.isLoading = shouldLoadOption ? !option.isLoading : false;
                    return option;
                });
            } else {
                responseOptions = responseOptions.map(option => {
                    option.isDisabled = false;
                    option.isLoading = false;
                    return option;
                });
            }
            return responseOptions;
        default: 
            return responseOptions;
    }

}

export function toggleLoadingAndDisabled(
    question: QuestionnaireQuestion,
    updatedValue: QuestionnaireResponseValue,
    questionName: string,
) {
    switch (question.type) {
        case 'bool':
        case 'suboptions':
            question.options = loadAndDisableBinaryOption(question.options, updatedValue, questionName, question.type, false);
            return question;
    }
}

export function getQuestionnairePercentComplete(allQuestions: Array<{ isComplete: boolean, details: QuestionnaireQuestion }>) {
    const numQuestions = allQuestions.length;
    const completedQuestions = allQuestions.filter(question => isQuestionComplete(question.details)).length;
    return completedQuestions / numQuestions;
}

// SAVING
export async function saveResponse(
    allQuestions: Array<{
        isComplete: boolean,
        details: QuestionnaireQuestion
    }>, // don't use Array<AccordionQuestionnaireData> to keep it more general than Accordions only
    vehicleListingId: number,
    questionnaireName: string,
    questionIdx: number,
    questionName: string,
    questionToUpdate: QuestionnaireQuestion,
    updatedValue: QuestionnaireResponseValue,
    questionsAndResponses: QuestionsAndResponses,
    saveImmediately: boolean=true,
    additionalOptions: QuestionnaireAdditionalOptions={},
) {
    // update UI data
    var { updatedQuestionsAndResponses, updatedResponseOptions, changedResponses } = updateQuestionnaireData(
        questionName, 
        updatedValue, 
        questionToUpdate,
        questionsAndResponses,
        additionalOptions
    );
    allQuestions[questionIdx].details.options = updatedResponseOptions;

    var saveLater: SaveLater = {};
    if (saveImmediately) {
        await saveQuestionnaireResponses(vehicleListingId, questionnaireName, updatedQuestionsAndResponses, questionsAndResponses)
            .catch(error => {
                console.log('Error saving vehicle');
                throw new Error(error);
            });
        toggleLoadingAndDisabled(questionToUpdate, updatedValue, questionName);
        updateIsQuestionComplete(allQuestions[questionIdx], questionToUpdate);
    } else {
        saveLater = {
            questionIdx,
            questions: changedResponses, 
            updatedResponses: updatedQuestionsAndResponses,
            oldResponses: questionsAndResponses
        };
    }

    return {
        updatedQuestionsAndResponses,
        allQuestions,
        saveLater
    };

}

export function updateIsQuestionComplete(questionnaireData: { isComplete: boolean, details: QuestionnaireQuestion }, questionToUpdate: QuestionnaireQuestion) {
    return questionnaireData.isComplete = isQuestionComplete(questionToUpdate);
}

export function updateSaveLater(newSaveLater: SaveLater, oldSaveLater: SaveLater) {
    const oldResponses = oldSaveLater.oldResponses;

    var olderUpdatedResponses: QuestionsAndResponses = {};
    if (oldSaveLater.updatedResponses) {
        oldSaveLater.questions?.forEach(question => {
            const oldValue = oldSaveLater.updatedResponses![question.questionName];
            olderUpdatedResponses[question.questionName] = oldValue;
        })
    }

    var newerUpdatedResponses: QuestionsAndResponses = {};
    if (newSaveLater.updatedResponses) {
        newSaveLater.questions?.forEach(question => {
            const newValue = newSaveLater.updatedResponses![question.questionName];
            newerUpdatedResponses[question.questionName] = newValue;
        });
    }

    const updatedResponses = { ...oldSaveLater.updatedResponses, ...olderUpdatedResponses, ...newerUpdatedResponses }

    var combinedQuestions: Array<SaveLaterQuestion> = [];
    if (oldSaveLater.questions && newSaveLater.questions) {
        combinedQuestions = oldSaveLater.questions;
        newSaveLater.questions.forEach(question => {
            const alreadyAdded = combinedQuestions.find(q => q.questionName == question.questionName);
            if (alreadyAdded) {
                alreadyAdded.questionToUpdate = question.questionToUpdate;
                alreadyAdded.updatedValue = question.updatedValue;
            } else {
                combinedQuestions.push(question);
            }
        });
    }

    var questions = !oldSaveLater.questions 
        ? newSaveLater.questions
        : !newSaveLater.questions
            ? oldSaveLater.questions
            : combinedQuestions;

    const saveLater: SaveLater = {
        questionIdx: newSaveLater.questionIdx,
        updatedResponses,
        oldResponses,
        questions
    };

    return saveLater;
}

function removeDeselectedCommentFromSaveLater(saveLater: SaveLater) {
    // if saveLater includes questions that deselect other options,
    // remove the comments from the deselected options if they're in saveLater
    if (!saveLater.questions) {
        return saveLater;
    }

    saveLater.questions?.forEach(question => {
        const responseOptions = question.questionToUpdate?.options;
        if (!responseOptions) {
            return;
        }
        const selectedOption = responseOptions.find(option => option.questionName == question.questionName);
        const shouldDeselectOthers = selectedOption?.deselectOthers && selectedOption.isSelected;

        if (shouldDeselectOthers) {
            responseOptions.forEach(option => {
                if (option.questionName == question.questionName) {
                    return;
                }
    
                if (saveLater.updatedResponses && option.commentQuestionName) {
                    saveLater.updatedResponses[option.commentQuestionName] = null;
                }
            })
        }
    });
    return saveLater;
}

export async function saveResponsesFromSaveLater(saveLater: SaveLater, vehicleListingId: number, questionnaireName: string) {
    if (!saveLater || !saveLater.updatedResponses || !saveLater.oldResponses || !saveLater.questions?.length) {
        return;
    }
    saveLater = removeDeselectedCommentFromSaveLater(saveLater);
    await saveQuestionnaireResponses(vehicleListingId, questionnaireName, saveLater.updatedResponses!, saveLater.oldResponses!);
    
    saveLater.questions = saveLater.questions!.map(question => {
        if (question.questionToUpdate) {
            toggleLoadingAndDisabled(question.questionToUpdate, question.updatedValue, question.questionName);
        }
        return question;
    });
}

// SIMPLE HELPERS
export function isQuestionnaireOptionSelected(questions: QuestionsAndResponses, value: QuestionnaireResponseValue, questionName: string) {
    if (!value) {
        return false;
    }

    const response = questions[questionName];
    if (typeof value == 'string') {
        return response == value;
    }

    if (typeof value == 'object' && response) {
        return value.includes(response);
    }
}

export function isQuestionComplete(question: QuestionnaireQuestion) {
    return !disableNextIfNoOptionSelected(question);
}

export function disableNextIfNoOptionSelected(question: QuestionnaireQuestion) {
    // (for suboption type) disable if no suboption is selected
    if (question.type == 'suboptions') {
        const isAwaitingSuboption = question.options.some(option => {
            if (!option.isSelected) {
                return false;
            }
            if (!option.suboptions || !option.suboptions.length) {
                return false;
            }
            const selectedSuboption = option.suboptions.some(suboption => suboption.isSelected);
            return !selectedSuboption;
        });

        if (isAwaitingSuboption) {
            return true;
        }
    }

    // disable if no option is selected
    const hasSelectedValue = question.options.some(option => option.isSelected);
    if (!hasSelectedValue) {
        return true;
    }


    if (commentMissing(question.options)) {
        return true;
    }

    if (isSomeOptionLoadingOrDisabled(question.options)) {
        return true;
    }

    return false;
}

export function isSomeOptionLoadingOrDisabled(responseOptions: Array<QuestionnaireResponseOption>) {
    return responseOptions.some(option => option.isLoading || option.isDisabled);
}

export function isVehicleLeased(questionsAndResponses: QuestionsAndResponses): boolean {
    return Object.values(questionsAndResponses).some(response => response == 'Lease');
}
