var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-sidebar',_vm._b({attrs:{"id":"notification-feed"},on:{"close":_vm.toggleSidebar,"input":_vm.toggleSidebar},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},'b-sidebar',{
        type: 'is-dark-blue',
        fullheight: true,
        overlay: false,
        canCancel: true,
        right: true,
        ..._vm.$attrs,
    },false),[(!_vm.displayNotificationSettings)?_c('div',[_c('div',{staticClass:"flex-row align-center justify-space-between"},[_c('div',{staticClass:"flex-row align-center"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"bell","size":"is-medium","type":"is-primary"}}),_c('h1',{staticClass:"is-size-4 bold has-text-white"},[_vm._v("Notifications")])],1),_c('div',[_c('ButtonIcon',{attrs:{"iconName":"cog","iconColor":"has-text-white"},on:{"click":function($event){return _vm.toggleNotificationSettings(true)}}}),_c('ButtonIcon',{attrs:{"iconName":"close","iconColor":"has-text-white"},on:{"click":function($event){return _vm.toggleSidebar(false)}}})],1)]),(_vm.isOpen)?_c('TheNotificationFeed',{staticClass:"pt-4"}):_vm._e()],1):_c('div',[_c('div',{staticClass:"flex-row align-center justify-space-between"},[_c('div',{staticClass:"flex-row align-center"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"cog","size":"is-medium","type":"is-primary"}}),_c('h1',{staticClass:"is-size-4 bold has-text-white"},[_vm._v("Settings")])],1),_c('div',[_c('ButtonIcon',{attrs:{"iconName":"bell","iconColor":"has-text-white"},on:{"click":function($event){return _vm.toggleNotificationSettings(false)}}}),_c('ButtonIcon',{attrs:{"iconName":"close","iconColor":"has-text-white"},on:{"click":function($event){return _vm.toggleSidebar(false)}}})],1)]),(_vm.isOpen)?_c('AppNotificationSettings',_vm._b({staticClass:"pt-4"},'AppNotificationSettings',{
                notificationSettings: _vm.notificationSettings,
                loading: _vm.loadingNotificationSettings,
            },false)):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }