var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isInoperable || _vm.isFrontline || _vm.isWholesale)?_c('div',{staticClass:"flex-row align-center"},[(_vm.isInoperable)?_c('AppFlag',_vm._b({staticClass:"has-background-danger-light has-text-danger"},'AppFlag',{
            title: 'Inoperable',
            iconAttrs: {
                icon: 'alert-circle',
                type: 'is-danger',
                customSize: 'mdi-18px',
            },
        },false)):_vm._e(),(_vm.isWholesale)?_c('AppFlag',{staticClass:"has-background-primary-light has-text-primary",attrs:{"title":"Fresh Trade"}}):_vm._e(),(_vm.isFrontline)?_c('AppFlag',{staticClass:"has-background-primary-light has-text-primary",attrs:{"title":"Frontline"}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }