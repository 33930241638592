import { GET } from "@/api";
import { openErrorDialog, toCurrency } from "@/utils";
import { omit } from "lodash";
import { computed, onMounted, Ref, ref } from "vue";

export function useGetTableData(storeId: number) {
    onMounted(() => getTableData());
    
    const tableData = ref([]);
    const loadingTableData = ref(false);

    async function getTableData() {
        loadingTableData.value = true;
        await GET(`/dealerInventory/marketplaceInventoryAutomationTEmplate/${storeId}`)
            .then(res => {
                loadingTableData.value = false;
                tableData.value = res.data.map((template: any) => {
                    return {
                        ...omit(template, []),
                        inventoryPricingBase: {
                            id: template.inventoryPricingBaseId,
                            text: template.inventoryPricingBaseDisplayValue,
                        }
                    }
                });
            }).catch(error => {
                loadingTableData.value = false;
                openErrorDialog({
                    title: 'Could not fetch inventory automation templates',
                    message: `We encountered an error while fetching the automation templates for store ${storeId}`,
                    error,
                });
            });
    }

    return {
        tableData,
        loadingTableData,
        getTableData,
    }
}

interface InventoryPricingBase {
    id: number;
    value: string;
    displayValue: string;
}

export function useInventoryPricingBase() {
    const inventoryPricingBase = ref<InventoryPricingBase[]>([]);
    const isLoadingInventoryPricingBase = ref(false);

    async function getInventoryPricingBase() {
        isLoadingInventoryPricingBase.value = true;
        try {
            const { data } = await GET<InventoryPricingBase[]>('/dealerInventory/getInventoryPricingBase');
            inventoryPricingBase.value = data;
        } catch (error) {
            openErrorDialog({
                title: 'Could not fetch inventory pricing base list',
                message: 'We encountered an error while fetching the inventory pricing base list',
                error,
            });
        }
        isLoadingInventoryPricingBase.value = false;
    }

    getInventoryPricingBase();

    return {
        inventoryPricingBase,
        isLoadingInventoryPricingBase,
    };
}

export function useGetTableConfig(inventoryPricingBase: Ref<InventoryPricingBase[]>) {
    const tableColumns = computed(() => {
        let defaultColumnAttrs = {
            centered: true,
            sortable: true,
            width: 200,
        }
        let defaultEditableFieldAttrs = {
            isInModal: true,
            isRequired: true,
        }
        return [
            {
                label: 'Inventory Age (days)',
                field: 'ageTrigger',
                attrs: defaultColumnAttrs,
                editableFieldAttrs: {
                    ...defaultEditableFieldAttrs,
                    type: 'text',
                }
            },
            {
                label: 'Base Price as',
                field: 'inventoryPricingBase',
                attrs: {
                    ...defaultColumnAttrs,
                    customSort: (a: any, b: any, isAsc: boolean) => {
                        const inventoryPricingBaseIdA = a.inventoryPricingBase.id;
                        const inventoryPricingBaseIdB = b.inventoryPricingBase.id;
                        return isAsc 
                            ? inventoryPricingBaseIdA - inventoryPricingBaseIdB
                            : inventoryPricingBaseIdB - inventoryPricingBaseIdA;
                    }
                },
                editableFieldAttrs: {
                    ...defaultEditableFieldAttrs,
                    type: 'dropdown',
                    possibleValues: inventoryPricingBase.value.map((i) => ({ id: i.id, text: i.displayValue })),
                    filter: (value: { id: number, text: string }) => value.text,
                    appendToBody: true,
                    expanded: true,
                }
            },
            {
                label: 'Floor Adjustment',
                field: 'floorPriceAdjustment',
                attrs: defaultColumnAttrs,
                editableFieldAttrs: {
                    ...defaultEditableFieldAttrs,
                    type: 'currency',
                    filter: toCurrency,
                },
            },
            {
                label: 'Buy-It-Now Adjustment',
                field: 'buyItNowPriceAdjustment',
                attrs: defaultColumnAttrs,
                editableFieldAttrs: {
                    ...defaultEditableFieldAttrs,
                    type: 'currency',
                    filter: toCurrency,
                }
            }
        ]
    });

    return {
        tableColumns,
    };
}

export function useTableValidation(tableData: Ref<any[]>, inventoryPricingBase: Ref<InventoryPricingBase[]>) {
    const columnsToValidate = ['ageTrigger', 'inventoryPricingBase'];
    const invalidCellMessage = ref('');
    const inventoryPricingBaseTexts = computed(() => inventoryPricingBase.value.map((i) => i.displayValue));

    function validateCell(value: any, fieldName: string, updatedRow: any) {
        switch(fieldName) {
            case 'ageTrigger':
                let intValue = parseInt(value);
                let isPositive = intValue >= 0;
                let isUnique = !tableData.value.some(otherRow => (otherRow.id !== updatedRow.id) && (otherRow.ageTrigger == value));
                let isValid = isPositive && isUnique;
                if (!isValid) {
                    invalidCellMessage.value = !isPositive ? 'Value must be positive' : 'Duplicate inventory age trigger';
                }
                return isValid;
            case 'inventoryPricingBase':
                return inventoryPricingBaseTexts.value.includes(value?.text);
            default: 
                return true;
        }
    }

    function validateRow(rowData: any) {
        return !Object.keys(rowData).some(fieldName => !validateCell(rowData[fieldName], fieldName, rowData));
    }

    return {
        columnsToValidate,
        invalidCellMessage,
        validateRow,
        validateCell,
    }
}
