var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar-item',{staticClass:"nav-item",class:{ 
        'display-flex': _vm.showAlertIcon,
        'flex-row align-center animate-icon-shake': _vm.icon,
        'is-poppins': true,
    },attrs:{"tag":"router-link","to":{ path: _vm.toPath }}},[_vm._t("default",function(){return [(_vm.icon)?_c('b-icon',{attrs:{"icon":_vm.icon,"custom-size":"mdi-18px"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.showAlertIcon)?_c('AlertIcon',_vm._b({},'AlertIcon',{
            ..._vm.$attrs.alertIconAttrs,
          },false)):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }