
import { defineComponent, PropType, ref, Ref, watch } from 'vue';

import AppRadioButton from './AppRadioButton.vue';
import AppSwitch from './AppSwitch.vue';

export default defineComponent({
    name: 'TheBuyItNowToggle',
    props: {
        buyItNowEnabled: {
            type: Boolean,
            default: false,
        },
        useRadioButtons: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppRadioButton,
        AppSwitch,
    },
    emits: ['input'],
    setup(props, { emit, attrs }) {
        const updatedBuyItNowEnabled: Ref<boolean> = ref(props.buyItNowEnabled);
        
        return {
            updatedBuyItNowEnabled,
        }
    },
});
