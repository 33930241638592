var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-field',_vm._b({},'b-field',{
    label: _vm.label ?? 'Phone number',
    type: _vm.phoneNumberInput ? _vm.validationType : '',
    message: _vm.phoneNumberInput ? _vm.validationMessage : '',
    ..._vm.$attrs.fieldAttrs,
  },false),[_c('b-input',_vm._b({attrs:{"id":"phone-number-input"},on:{"iconRightClick":_vm.clearPhoneNumber},nativeOn:{"input":function($event){return _vm.formatInput.apply(null, arguments)},"change":function($event){_vm.needsValidation=true},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.isValid ? _vm.$emit('keypressEnter', $event) : ''}},model:{value:(_vm.phoneNumberInput),callback:function ($$v) {_vm.phoneNumberInput=$$v},expression:"phoneNumberInput"}},'b-input',{
        placeholder: 'Phone number',
        type: 'tel',
        icon: 'phone',
        iconRight: 'close-circle',
        iconRightClickable: true,
        hasCounter: false,
        ..._vm.$attrs,
      },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }