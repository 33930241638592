var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.refreshKey},[(!_vm.bidHistorySorted?.length)?_c('p',[_vm._v("There are no bids on this listing")]):_vm._e(),_vm._l((_vm.bidHistorySorted),function(bid,idx){return _c('div',{key:`bid-${idx}`},[_c('TheBidInfoCard',_vm._b({scopedSlots:_vm._u([{key:"flag",fn:function(){return _vm._l((_vm.getBidFlags(bid)),function(flag){return _c('span',{key:flag,staticClass:"flag"},[_vm._v(_vm._s(flag))])})},proxy:true}],null,true)},'TheBidInfoCard',{
                bid,
                isHighlighted: _vm.highestBid?.amount == bid.amount,
                highestBidAmount: _vm.highestBid?.amount,
                reservePrice: _vm.reservePrice,
                focusedClassName: _vm.focusedClassName,
            },false))],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }