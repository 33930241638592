import { getAddressAutocomplete } from '@/api/place';
import { computed, ComputedRef, ref, Ref } from 'vue';

type AddressOption = {
    address: string,
    placeId: string,
}

export function useAddressAutocomplete({ initialAddress }: {
    initialAddress: string,
}) {
    const addressUpdated: Ref<string | undefined> = ref(initialAddress);
    const autocompleteOptions: Ref<AddressOption[]> = ref([]);
    const loadingAutocompleteOptions: Ref<boolean> = ref(false);

    const selectedAddress: ComputedRef<AddressOption | undefined> = computed(() => {
        if (!addressUpdated.value || !autocompleteOptions.value.length) {
            return undefined;
        }
        return autocompleteOptions.value.find(option => option.address == addressUpdated.value);
    });

    async function getAddressAutocompleteOptions() {
        if (!addressUpdated.value) {
            return;
        }
        loadingAutocompleteOptions.value = true;
        await getAddressAutocomplete(addressUpdated.value, {
            onSuccess: (res) => {
                autocompleteOptions.value = res.map((prediction: any) => {
                    return {
                        address: prediction.description,
                        placeId: prediction.place_id,
                    }
                });
                loadingAutocompleteOptions.value = false;
            },
            onError: () => loadingAutocompleteOptions.value = false,
        });
    }

    return {
        addressUpdated,
        selectedAddress,
        autocompleteOptions,
        loadingAutocompleteOptions,
        getAddressAutocompleteOptions,
    }
}