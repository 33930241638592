import { FeedbackProp } from "./feedbackProps";

export const feedbackPropsDefault: { [key: string]: FeedbackProp } = {
    success: {
        type: 'success',
        icon: 'check-circle',
        label: 'Saved!',
        class: 'has-text-primary',
    },
    error: {
        type: 'error',
        icon: 'alert-circle',
        label: 'Not saved',
        class: 'has-text-danger',
    },
    loading: {
        type: 'loading',
        icon: 'content-save-outline',
        label: 'Saving...',
        class: 'has-text-primary'
    },
    invalid: {
        type: 'invalid',
        class: 'has-text-danger'
    }
}
