import { APIConfig, FilterFieldOptionsSchema, listAllUsersForAdminDTO, ModifierOptions, ProfileLiteDTO } from '@/types';
import { Roles } from '@/types/Roles';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, openErrorDialog } from '@/utils';
import { PUT } from '.';

export async function getProfileLiteByRole(roles: Roles[], modifierOptionSchema?: FilterFieldOptionsSchema, config: APIConfig={}): Promise<ProfileLiteDTO[]> {
    return await PUT(`/profiles/getProfileLiteByRole`, modifierOptionSchema ?? {
            modifiers: {
                filters: [
                    {
                        property: 'userRoles.id',
                        comparator: 'in',
                        values: roles
                    },
                ],
                orderBy: [
                    {
                        property: 'person.id',
                        order: 'asc',
                        nulls: 'last'
                    }
                ],
            }
        }).then(res => {
            // remove duplicates
            let uniqueUsers = res.data.reduce((acc: ProfileLiteDTO[], curr: ProfileLiteDTO) => {
                let isDuplicate = acc.find(profileLite => profileLite.personId == curr.personId);
                if (!isDuplicate) {
                    acc.push(curr);
                }
                return acc;
            }, []);

            applyAPIConfigOnSuccess(uniqueUsers, config);
            return uniqueUsers;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch users',
                message: `We encountered an error while fetching users with role: ${roles.join(', ')}`,
                error,
            });
        });
}
