import {
    computed,
    onMounted,
    ref,
    watchEffect,
} from 'vue';
import { useUserRole } from './user';
import { useStore } from './useStore';

declare global {
    interface Window {
        HubSpotConversations?: {
            widget: {
                /**
                 * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk#widget-load
                 */
                remove: () => void;
                /**
                 * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk#widget-load
                 */
                load: () => void;
            };
        };
        /**
         * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk#configure-conversations-settings
         */
        hsConversationsOnReady?: Array<() => void>
        hsConversationsSettings?: { loadImmediately: boolean }
    }
}

export function useHubSpot() {
    const { isUserAdmin } = useUserRole();
    const store = useStore();
    const isChatWidgetReady = ref(false);

    const isAdminOrImpersonatingUser = computed(() => {
        const isAdminImpersonating = store.state.user?.stsTokenManager?.stashedRefreshToken !== undefined;
        return isUserAdmin.value || isAdminImpersonating;
    });

    function embedScript() {
        if (document.getElementById('hs-script-loader')) {
            return;
        }

        const body = document.querySelector('body');
        const hubspotScript = document.createElement('script');

        hubspotScript.setAttribute('type', 'text/javascript');
        hubspotScript.setAttribute('id', 'hs-script-loader');
        hubspotScript.setAttribute('async', '');
        hubspotScript.setAttribute('defer', '');
        hubspotScript.setAttribute('src', '//js.hs-scripts.com/9061394.js');

        body?.appendChild(hubspotScript);
    }

    function removeWidget() {
        window.HubSpotConversations?.widget.remove();
    }

    function showWidget() {
        window.HubSpotConversations?.widget.load();
    }

    function onConversationsAPIReady() {
        isChatWidgetReady.value = true;
    }

    onMounted(() => {
        // HubSpot looks for this and will not append the chat widget immediately.
        // We want this behavior so there won't be any race conditions.
        window.hsConversationsSettings = { loadImmediately: false };

        // HubSpot looks for this and will call the functions when the chat widget is ready.
        window.hsConversationsOnReady = [onConversationsAPIReady];

        embedScript();
    });

    watchEffect(() => {
        if (!isChatWidgetReady.value) {
            return;
        }

        if (isAdminOrImpersonatingUser.value) {
            removeWidget();
        } else {
            showWidget();
        }
    });
}
