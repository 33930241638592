
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { toCurrency } from '../utils';
import store from '@/vuex';

import AppFastBookDropdown from './AppFastBookDropdown.vue';
import ButtonIcon from './ButtonIcon.vue';
import CopyTextButton from './CopyTextButton.vue';

export default defineComponent({
    name: 'TheListingDetailsCardVehicleBasics',
    components: {
        AppFastBookDropdown,
        ButtonIcon,
        CopyTextButton,
    },
    props: {
        showShareListingButton: {
            type: Boolean,
            default: false,
        },
        year: {
            type: Number,
            required: false,
        },
        make: {
            type: String,
            required: false,
        },
        model: {
            type: String,
            required: false,
        },
        trim: {
            type: String,
            required: false,
        },
        vin: {
            type: String,
            required: false,
        },
        miles: {
            type: Number,
            required: false,
        },
        city: {
            type: String,
            required: false,
        },
        state: {
            type: String,
            required: false,
        },
        estimatedShippingInDollars: {
            type: Number,
            required: false,
        },
    },
    setup(props, context) {
        const vehicleName = ref('');
        onBeforeMount(() => {
            const vehicleNamePropValues = [props.year, props.make, props.model];
            vehicleName.value = vehicleNamePropValues.join(' ');
        });

        const isFastBookExtensionInstalled = computed(() => store.state.isFastBookExtensionInstalled);

        return {
            isFastBookExtensionInstalled,
            vehicleName,
            toCurrency,
        };
    },
});
