
import { computed, defineComponent, nextTick, ref, watch } from 'vue';
import { phoneFormatter, validatePhone } from '@/validation';
import { isEmpty } from 'lodash';
import { focusOnElement } from '../utils';

export default defineComponent({
  inheritAttrs: false,
  name: 'AppInputFieldPhoneNumber',
  props: {
    label: String, // optional - remove this eventually and replace with attrs.fieldAttrs.label
    phoneNumber: String,                                                                                                                
  },
  setup(props, { emit }) {
    const phoneNumberInput = ref(phoneFormatter(props.phoneNumber));
    const inputKey = ref(0);

    watch(phoneNumberInput, (value, oldValue) => {
      if (isEmpty(value)) {
        clearPhoneNumber();
        return;
      }
    });

    function formatInput(event: Event) {
      const input = (event.target as HTMLInputElement).value;
      // trim input, maxlength 14
      if (phoneNumberInput.value.length >= 14) {
        phoneNumberInput.value = input.slice(0, 14);
      }
      
      // remove invalid characters
      phoneNumberInput.value = phoneFormatter(phoneNumberInput.value);
    }

    function clearPhoneNumber() {
      phoneNumberInput.value = '';
      emit('cleared');
    }

    // validation
    const isValid = computed(() => {
      if (!phoneNumberInput.value) {
        return false;
      }
      const validInput = validatePhone(phoneNumberInput.value);
      if (validInput) {
        emit('validPhone', phoneNumberInput.value);
        emit('update:phoneNumber', phoneNumberInput.value);
      } else {
        emit('update:phoneNumber', '');
      }
      return validInput;
    });

    const validationType = computed(() => isValid.value ? '' : 'is-danger');
    const validationMessage = computed(() => isValid.value ? '' : 'Please enter a valid phone number');

    return {
      phoneNumberInput,
      inputKey,
      isValid,
      validationType,
      validationMessage,
      formatInput,
      clearPhoneNumber,
      phoneFormatter,
    }
  },
});
