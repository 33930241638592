import { AchInitiateDataDTO, APIConfig, InAppNotification, InAppNotificationConfig, ModifierOptions, NotificationModalitySetting, NotificationSettingsDTO } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, openErrorDialog } from "@/utils";
import { formatInAppNotificationConfig } from "@/utils/notificationUtils";
import store from '@/vuex';
import { uniq } from "lodash";
import { GET, POST, PUT } from ".";

export async function getUserNotificationSettings(config: APIConfig={}): Promise<NotificationSettingsDTO[]> {
    return await GET(`/notifications/userNotificationSettings`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch notification settings',
                message: `We encountered an error fetching notification settings for person ${store.state.user.profile.id}`, 
            });
        });
}

export async function updateUserNotificationSetting(payload: NotificationModalitySetting, config: APIConfig={}) {
    return await PUT(`/notifications/updateUserNotificationSetting`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to update notification settings',
                message: `We encountered an error updating notification: ${payload.notificationSettingId}-${payload.modalityId}. Enabled: ${payload.enabled}`, 
            });
        });
}

export async function getNotifications(modifiers: ModifierOptions={}, config: APIConfig={}): Promise<InAppNotification[]> {
    return await PUT(`/notifications/getNotifications`, { modifiers })
        .then(res => {
            let notifications = res.data.map((notification: InAppNotification) => {
                return {
                    ...notification,
                    config: formatInAppNotificationConfig(notification) as InAppNotificationConfig,
                }
            });
            applyAPIConfigOnSuccess(notifications, config);
            return notifications;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function updateNotification(payload: { notificationId: number, modalityId: number, notificationRead?: boolean }, config: APIConfig={}) {
    return await PUT(`/notifications/updateNotification`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function markAllNotificationsAsRead(config: APIConfig={}) {
    return await PUT('/notifications/markAllNotificationsAsRead')
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function sendAchInitiatedEmail(payload: AchInitiateDataDTO, config: APIConfig={}) {
    return await POST(`/notifications/sendAchInitiatedEmail`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to send ACH Initiated email',
                message: celebrateValidationMessage 
                    ?? `We encountered an error while sending the ACH Initiated email to email ${payload.buyer.email}. Please try again or contact support.`,
                error,
            });
        });
}
