
import { defineComponent, onMounted, watch } from 'vue';
import { useAddressAutocomplete } from '../composables/place';

import AutocompleteInput from './AutocompleteInput.vue';

type AddressOption = {
    address: string,
    placeId: string,
}

export default defineComponent({
    name: 'TheAddressAutocompleteInput',
    props: {
        address: {
            type: String,
            required: false,
        }
    },
    emits: ['update:address', 'update:placeId', 'select'],
    components: {
        AutocompleteInput,
    },
    setup(props, context) {
        const {
            addressUpdated,
            selectedAddress,
            autocompleteOptions,
            loadingAutocompleteOptions,
            getAddressAutocompleteOptions,
        } = useAddressAutocomplete({ initialAddress: props.address });

        watch(addressUpdated, getAddressAutocompleteOptions);

        function emitSelectedAddress({ address, placeId }: AddressOption) {
            addressUpdated.value = address;
            context.emit('update:address', address);
            context.emit('update:placeId', placeId);
            context.emit('select', selectedAddress.value);
        }

        return {
            addressUpdated,
            selectedAddress,
            autocompleteOptions,
            loadingAutocompleteOptions,
            emitSelectedAddress,
        }
    },
});
