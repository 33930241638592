import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Vue from 'vue';
import VueRouter from 'vue-router';

export function initializeSentry(app: typeof Vue, router: VueRouter) {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  Sentry.init({
    Vue: app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: [
      /Redirected when going from/,
      /Avoid redundant navigation/,
      /Navigation cancelled/,
    ],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['app.carmigo.io'],
      }),
      new Sentry.Replay({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // More info here https://carmigo.sentry.io/getting-started/javascript-vue/javascript-vue/
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,
  });
}
