var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-field',_vm._b({},'b-field',{
        ..._vm.$attrs,
        ..._vm.$attrs.fieldAttrs,
    },false),[(_vm.type == 'currency')?_c('b-input',_vm._b({directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.getCleaveMask),expression:"getCleaveMask"}],attrs:{"id":_vm.id},on:{"input":function($event){return _vm.$emit('input', $event)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keypressEnter', $event)}}},'b-input',_vm.$attrs,false)):(_vm.type == 'localeString')?_c('b-input',_vm._b({directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.getCleaveMask),expression:"getCleaveMask"}],attrs:{"id":_vm.id},on:{"input":function($event){return _vm.$emit('input', $event)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keypressEnter', $event)}}},'b-input',_vm.$attrs,false)):_c('b-numberinput',_vm._b({attrs:{"id":_vm.id},on:{"input":function($event){return _vm.$emit('input', $event)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keypressEnter', $event)}}},'b-numberinput',{
            controls: _vm.$attrs.controls ?? false,
            ..._vm.$attrs
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }