<template>
    <Modal 
        :loading="loading" 
        :disableConfirm="!startTime || !endTime"
        style="min-width: 500px; min-height: 685px;"
        @confirm="shouldScheduleAuction = true;"
        @close="$emit('close')"
        :class="{
            'mobile-width': hasMobileView,
            'mobile-height': hasMobileView,
        }"
    >
        <template #header>
            Schedule Auction
        </template>

        <template #body>
            <p class="text-center bold">Schedule an auction for vehicle {{ vehicleListingId }}</p>
            <ScheduleAuction 
                :vehicleListingIds="vehicleListingIds"
                :initialStartTime="startTime"
                :initialEndTime="endTime"
                :hasButton="false"
                :customConfirm="shouldScheduleAuction"
                @startTime="setStartTime"
                @endTime="setEndTime"
                @success="$emit('close'); $emit('success');"
                @cancel='shouldScheduleAuction = false;'
                :autofillEndTime="autofillUpdated"
                class="schedule-auction"
                :key="`schedule-auction-${updateKey}`"
            />
        </template>
    </Modal> 
</template>

<script>
import Modal from './Modal.vue';
import ScheduleAuction from './ScheduleAuction.vue';

export default {
    name: 'ScheduleAuctionModal',
    props: {
        vehicleListingId: Number,
        hasMobileView: {
            type: Boolean,
            required: false,
            default: false,
        },
        autofillEndTime: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    components: {
        Modal,
        ScheduleAuction,
    },
    data() {
        return {
            vehicleListingIds: [this.vehicleListingId],
            loading: false,
            startTime: null,
            endTime: null,
            shouldScheduleAuction: false,
            updateKey: 0,
            autofillUpdated: this.autofillEndTime,
        }
    },
    watch: {
        shouldScheduleAuction(newValue) {
            this.autofillUpdated = !this.shouldScheduleAuction;
            this.updateKey++;
        }
    },
    methods: {
        setStartTime(selectedTime) {
            this.startTime = selectedTime;
        },
        setEndTime(selectedTime) {
            this.endTime = selectedTime;
        },
    },
}
</script>

<style scoped lang='postcss'>

.schedule-auction {
    width: 100%;
    margin: 1rem auto;
    max-width: 600px;
}

@media screen and (max-width: 830px) {
    .schedule-auction {
        width: 85%;
    }
}

@media screen and (max-width: 500px) {
    .mobile-width {
        min-width: 300px !important;
    }
}

@media screen and (max-height: 700px) {
    .mobile-height {
        min-height: fit-content !important;
    }
}

@media screen and (max-width: 325px) {
    .schedule-auction {
        width: 200px;
        margin: auto;
    }
}

</style>