
import { defineComponent, PropType, ref } from 'vue';
import { useEditableFields } from '../composables';

import AppSwitch from './AppSwitch.vue';

export default defineComponent({
    name: 'TheListingDetailsVehicleAttributesEditable',
    props: {
        isInoperable: {
            type: Boolean,
            required: false,
        },
        isWholesale: {
            type: Boolean,
            required: false,
        },
        isFrontline: {
            type: Boolean,
            required: false, 
        },
    },
    components: {
        AppSwitch,
    },
    emits: ['input', 'update:isInoperable', 'update:isWholesale', 'update:isFrontline'],
    setup(props, context) {
        const updatedFields = ref({
            isInoperable: props.isInoperable,
            isWholesale: props.isWholesale,
            isFrontline: props.isFrontline,
        });

        const { unsavedUpdates, addToUnsavedUpdates, emitUpdates } = useEditableFields({ context });

        function emitUpdatedValues(updates) {
            addToUnsavedUpdates(updates);
            updatedFields.value = {
                ...updatedFields.value,
                ...updates,
            }
            emitUpdates(unsavedUpdates.value);
        }
        return {
            updatedFields,
            emitUpdatedValues,
        }
    },
});
