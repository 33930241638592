var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"consecutive-messages flex-row",class:{
        'flex-row-reverse justify-start': _vm.senderIsMe,
    }},[_c('ChatMessageSender',{class:`has-text-${_vm.color} ${_vm.senderIsMe ? 'ml-2' : 'mr-2'}`,attrs:{"senderName":`${_vm.sender.firstName} ${_vm.sender.lastName}`}}),_c('div',_vm._l((_vm.messages),function({ message, date },idx){return _c('ChatMessageBody',{key:`message-${date}`,class:{
                'mt-1': idx > 0
            },attrs:{"messageText":message,"messageStyles":{
                color: `has-background-${_vm.color}`,
                solid: 'solid'
            }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"has-text-white"},[_vm._v(_vm._s(message))]),_c('p',{staticClass:"text-right",class:_vm.senderIsMe ? 'has-text-dark' : 'has-text-grey-lighter'},[_vm._v(" "+_vm._s(_vm.formatDate(date, 'M/dd/yy p')))])]},proxy:true}],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }