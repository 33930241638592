var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"close":function($event){return _vm.$emit('close')},"confirm":_vm.addManagerToCompany},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Add Manager ")]},proxy:true},{key:"body",fn:function(){return [_c('form',[_c('AppInputFieldString',_vm._b({model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}},'AppInputFieldString',{
          fieldAttrs: { label: 'First name '},
          placeholder: 'First name',
          iconRight: 'close-circle',
          iconRightClickable: true,
          iconRightClick: () => _vm.firstName = undefined,
          required: true,
        },false)),_c('AppInputFieldString',_vm._b({model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}},'AppInputFieldString',{
          fieldAttrs: { label: 'Last name '},
          placeholder: 'Last name',
          iconRight: 'close-circle',
          iconRightClickable: true,
          iconRightClick: () => _vm.lastName = undefined,
          required: true,
        },false)),_c('AppInputFieldEmail',{attrs:{"email":_vm.email,"required":""},on:{"update:email":function($event){_vm.email=$event}}}),_c('AppInputFieldPhoneNumber',{attrs:{"phoneNumber":_vm.phoneNumber,"required":""},on:{"update:phoneNumber":function($event){_vm.phoneNumber=$event},"update:phone-number":function($event){_vm.phoneNumber=$event}}})],1)]},proxy:true}])},'Modal',{
    confirmButtonAttrs: {
      loading: _vm.loading,
      disabled: !_vm.validateForm,
    },
    cancelButtonAttrs: {
      disabled: _vm.loading,
    },
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }