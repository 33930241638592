import Vue from 'vue';
import App from './App.vue';

import store from './vuex';
import router from './router';
import { initializeSentry } from './utils';
import './styles/bulma.scss';
import './styles/dealer-widget.scss';
import './styles/default-variables.css';
import '@mdi/font/css/materialdesignicons.css';
import '@/plugins/buefy';
import '@/plugins/cleave';
import '@/plugins/currency';

Vue.config.productionTip = false;

initializeSentry(Vue, router);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
