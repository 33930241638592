import { PropType } from 'vue';
import { FixMe } from '.';
import { VehicleFilterPreset } from './VehicleFilterPreset';

export const ListingFiltersProps = {
    filterFieldsKey: {
        type: Number,
        default: 0,
    },
    savedFiltersKey: {
        type: Number,
        default: 0,
    },
    isModal: {
        type: Boolean,
        default: false,
    },
    presetOptions: {
        type: Array as PropType<VehicleFilterPreset[]>,
        required: false,
    },
    loadingPresetOptions: {
        type: Boolean,
        default: false,
    },
    initialSelectedPresetId: {
        type: Number,
        required: false,
    },
    filterHasChanges: {
        type: Boolean,
        default: false,
    },
    loadingSaveChanges: {
        type: Boolean,
        default: false,
    },
}