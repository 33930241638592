var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheNegotiationModalWithMessages',_vm._b({attrs:{"note":_vm.negotiationNote},on:{"update:note":function($event){_vm.negotiationNote=$event},"counter":_vm.submitNegotiationAndNote,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div')]},proxy:true}])},'TheNegotiationModalWithMessages',{
        ..._vm.$props,
        highlightDetails: [
            {
                label: 'Your Offer',
                value: _vm.negotiatingUserOfferAmount,
                filter: _vm.toCurrency,
            },
            {
                label: `${_vm.capitalize(_vm.otherUser)} Offer`,
                value: _vm.otherUserOfferAmount,
                filter: _vm.toCurrency,
            },
        ],
        makingCounterOfferInitial: true,
        allowMessages: true,
        negotiationStatus: 'in progress',
        loadingSubmitCounterOffer: _vm.loadingSubmitCounterOffer,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }