import { defineComponent } from 'vue';

// We only use this if CSS isn't enough.
export default defineComponent({
  data: () => ({
    windowWidth: window.innerWidth,
  }),

  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },

  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  computed: {
    // Adapted from https://bulma.io/documentation/overview/responsiveness/#breakpoints
    bulmaBreakpoint() {
      return {
        mobile: this.windowWidth <= 768,
        tablet: this.windowWidth >= 769 && this.windowWidth <= 1023,
        desktop: this.windowWidth >= 1024 && this.windowWidth <= 1215,
        widescreen: this.windowWidth >= 1216 && this.windowWidth <= 1407,
        fullhd: this.windowWidth >= 1408,
      };
    },
  },
});
