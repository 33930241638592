
import { defineComponent } from 'vue';
import IconAndLabel from './Arbitrations/IconAndLabel.vue';

const __default__ = defineComponent({
  components: { IconAndLabel },
  props: {
    stepNumber: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    isComplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showToggleIcon: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    icon() {
      return this.isComplete ? 'check' : `numeric-${this.stepNumber}`;
    },
    iconType() {
      return this.isComplete ? 'is-primary' : '';
    },
    label() {
      return this.subtitle !== '' ? [this.title, this.subtitle] : this.title;
    },
    iconBorderColor() {
      return this.isOpen ? '#585958' : '#C4C4C4';
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "77c1cab6": (_vm.iconBorderColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__