<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head flex-row justify-space-between">
            <p class="modal-card-title">Confirm Delete</p>
            <b-icon icon="close-circle" class="has-text-grey-light" />
        </header>

        <section class="modal-card-body text-center">
            Are you sure you want to delete {{ numVehicles }} photo(s)? 
            <br>
            <span class="bold">This action cannot be undone.</span>
        </section>

        <footer class="modal-card-foot">
            <b-button label="Cancel" type="is-primary" @click="$emit('close')" />
            <b-button label="Delete" type="is-danger" @click="$emit('confirm'); $emit('close')" />
        </footer>
    </div>
</template>

<script>
export default {
    name: 'ConfirmDeletePhotoModal',
    props: {
        numVehicles: {
            type: Number,
            required: true,
        }
    },
}
</script>