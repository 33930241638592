
import { isCarmigoDomain, mapUserRoleGetters } from '../../utils';
import vue from 'vue';
import { mapGetters } from 'vuex';
import SelectPayment from '../SelectPayment.vue';
export default {
    name: 'SetUpACHButton',
    data() {
        return {
            paymentOpen: false,
        }
    },
    components: {
        SelectPayment
    },
    computed: {
        ...mapUserRoleGetters(),
        ...mapGetters({
            isAuthenticated: 'isAuthenticated',
        }),
        showACH() {
            if (
                !this.paymentOpen && isCarmigoDomain() && this.isAuthenticated && this.isUserSeller && this.$store.state.needACH
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        updateStateVariable() {
            this.$store.commit('SET_NEED_ACH', false);
        },
        openPaymentModal() {
            this.$buefy.modal.open({
                parent: this as unknown as vue,
                props: {
                    CarmigoUserID: this.$store.state.user.profile.id,
                    DestinationRepository: 'profile',
                    dwollaCustomerURL: this.$store.state.user?.profile.dwollaCustomerURL,
                },
                component: SelectPayment,
                hasModalCard: true,
                trapFocus: false,
                canCancel: ['p'],
            });
        },
    }
}
