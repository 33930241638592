
import { computed, defineComponent } from 'vue';
import AppButton from './AppButton.vue';

export default defineComponent({
    inheritAttrs: false,
    name: 'TheNegotiateButton',
    props: {
        isPending: {
            type: Boolean,
            default: false,
        },
        labelActive: {
            type: String,
            default: 'Negotiate'
        },
        labelPending: {
            type: String,
            default: 'Offer Sent'
        },
        disablePending: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        buttonLabel: {
            type: String,
            required: false,
        }
    },
    components: {
        AppButton,
    },
    setup(props, context) {
        const getButtonLabel = computed(() => {
            if (props.buttonLabel) {
                return props.buttonLabel;
            }
            return props.isPending ? props.labelPending : props.labelActive;
        });

        const getButtonType = computed(() => {
            return props.isPending ? 'is-light' : 'is-primary';
        });

        return {
            getButtonLabel,
            getButtonType,
        }
    }
});
