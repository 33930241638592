import { getPhoneCode, confirmPhoneCode, login, signUpByEmail } from "@/api";
import { handleLoginError, openErrorDialog, openSnackbar, redirectByRole, registerLeadFromLocalStorage } from "@/utils";
import { formattedPhoneNumberToDigits } from "@/validation";
import { computed, ComputedRef, onMounted, reactive, Ref, ref, watch } from "vue";
import { fireEvent, fireIdentify } from "@/segment";
import { User } from "@sentry/types";
import firebase from 'firebase/compat/app';
import { RegisterPayloadDTO } from "@/types";

declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier,
    }
}

export function usePhoneLogin(phoneNumber: string='', { emit, registerNewUser=false }: { 
    emit: (event: 'loggedIn' | 'codeSent' | 'registeredLead' | string, ...args: any[]) => void,
    registerNewUser?: boolean, // if false, throws an error if phone number is not registered. If true, registers the lead and proceeds with login
}) {
    const phoneNumberInput: Ref<string> = ref(phoneNumber);
    const phoneNumberInt = computed(() => formattedPhoneNumberToDigits(phoneNumberInput.value));
    
    // send phone code 
    const displayCodeInput: Ref<boolean> = ref(false);
    const loadingSendCode: Ref<boolean> = ref(false);
    const registeredLead: Ref<boolean> = ref(false);
    async function sendPhoneCode() {
        loadingSendCode.value = true;
        await getPhoneCode(phoneNumberInt.value)
            .then(res => {
                loadingSendCode.value = false;
                displayCodeInput.value = true;
                emit('codeSent');
            }).catch(error => {
                if (registerNewUser && !registeredLead.value && error.message.includes('No user found with number')) {
                    registerLeadFromLocalStorage({
                        onSuccess: (profileDTO) => {
                            registeredLead.value = true; // prevent loops
                            emit('registeredLead', profileDTO);
                            sendPhoneCode();
                        },
                        onError: () => loadingSendCode.value = false,
                    });
                    return;
                }
                loadingSendCode.value = false;
                handleLoginError(error);
            });
    }

    function resendPhoneCode() {
        displayCodeInput.value = false;
        codeInput.value = undefined;
        sendPhoneCode();
    }
    
    // confirm phone code + login
    const loadingConfirmCode: Ref<boolean> = ref(false);
    const codeInput: Ref<string | undefined> = ref(undefined);
    async function confirmCodeAndLogIn() {
        if (!codeInput.value) {
            return;
        }
        loadingConfirmCode.value = true;
        await confirmPhoneCode(phoneNumberInt.value, codeInput.value)
            .then(() => {
                emit('loggedIn');
            }).catch(error => {
                loadingConfirmCode.value = false;
                openErrorDialog({
                    title: 'Login failed',
                    message: 'The code you provided was invalid',
                    error,
                    confirmText: 'Send a new code',
                    onConfirm: resendPhoneCode,
                });
            });
    }

    function applyRecaptchaVerifier(containerId: string="recaptcha-container") {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(containerId, { size: 'invisible' });
    }

    return {
        displayCodeInput,
        loadingSendCode,
        phoneNumberInput,
        phoneNumberInt,
        sendPhoneCode,
        resendPhoneCode,
        confirmCodeAndLogIn,
        loadingConfirmCode,
        codeInput,
        applyRecaptchaVerifier,
    }
}

export function useEmailLogin(email: string='', { emit }: { emit: (event: 'loggedIn', ...args: any[]) => void }) {
    const emailInput: Ref<string> = ref(email);
    const passwordInput: Ref<string> = ref('');

    const disableSubmitLogin: ComputedRef<boolean> = computed(() => !emailInput.value || !passwordInput.value);

    const loadingSubmitLogin: Ref<boolean> = ref(false);
    async function submitLogin() {
        loadingSubmitLogin.value = true;
        login({
            username: emailInput.value,
            password: passwordInput.value,
        }).then((loggedInUser) => {
            let user = loggedInUser as unknown as User;
            fireIdentify(user.uid, {
                name: `${user.profile.firstName} ${user.profile.lastName}`,
                email: user.email,
                phone: user.profile.phoneNumber,
            });
            fireEvent('User Logged In');
            openSnackbar({
                message: `Welcome ${user.profile.firstName}!`,
                type: 'is-success', 
                position: 'is-top',
            });
            loadingSubmitLogin.value = false;
            emit('loggedIn');
        }).catch(error => {
            console.error('ERROR', error);
            loadingSubmitLogin.value = false;
            handleLoginError(error?.response?.data ?? error);
        });
    }
    
    return {
        emailInput,
        passwordInput,
        submitLogin,
        loadingSubmitLogin,
        disableSubmitLogin,
    }

}

export function useSignUp({ firstName='', lastName='', email='', phoneNumber='' }: { 
    firstName?: string,
    lastName?: string,
    email?: string,
    phoneNumber?: string,
}={}, { emit }: { emit: (event: 'loggedIn', ...args: any[]) => void }) {
    const signUpForm: RegisterPayloadDTO = reactive({
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        password: '',
    });
    const { emailInput, passwordInput, submitLogin } = useEmailLogin(email, { emit });

    const disableSubmit: ComputedRef<boolean> = computed(() => {
        return Object.keys(signUpForm).some(field => !signUpForm[field as keyof RegisterPayloadDTO]?.length);
    });

    const loadingSignUp: Ref<boolean> = ref(false);
    async function signUp() {
        loadingSignUp.value = true;
        await signUpByEmail(signUpForm, {
            onSuccess: async (signedUpUser) => {
                fireIdentify(signedUpUser.profile.id, {
                    name: `${signedUpUser.profile.firstName} ${signedUpUser.profile.lastName}`, 
                    email: signedUpUser.profile.email,
                    phone: signedUpUser.profile.phoneNumber,
                });
                fireEvent('User Registered');
                emailInput.value = signedUpUser.profile.email;
                passwordInput.value = signUpForm.password;
                await submitLogin();
                redirectByRole();
                loadingSignUp.value = false;
            },
            onError: (error: any) => {
                loadingSignUp.value = false;
                if (error?.response?.data?.error?.constraint == 'phone_number_unique') {
                    signUpForm.phoneNumber = '';
                    return;
                }
            }
        });
    }
    
    return {
        signUpForm,
        signUp,
        loadingSignUp,
        disableSubmit,
    }
}