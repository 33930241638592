import type { APIConfig, FixMe, ModifierOptions, ModifierOptionsFilterObject, Roles } from '@/types';
import { mapGetters } from 'vuex';
import { checkFirebaseAccountStatusWithVehicleListingId, impersonateUser, resetBankInfoByPersonId } from '../api';
import store from '@/vuex';
import { GET } from '../api';
import { openConfirmationDialog, openModal, OpenModalConfig, openToast } from './buefyUtils';
import { BDialogConfig, BModalConfig } from 'buefy/types/components';

import TheAddAddressModal from '../components/TheAddAddressModal.vue';

/**
 * @param user Current user
 * @param requiredRoles Role/s to check - returns true if *any* of the requiredRoles are included, not *all* of them
 * @param checkIsOnlyRole (optional, default false) check if the requiredRole(s) is the only role that user has
 * @returns Boolean
 */
export function hasRole(user: FixMe, requiredRoles: Roles | Roles[], checkIsOnlyRole: boolean=false): boolean {
  if (!user?.profile?.role) {
    return false;
  }

  const { profile } = user;
  const userRoles: Roles[] = Array.isArray(profile.role) ? profile.role : [profile.role];
  const requiredRolesArr: Roles[] = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles];

  if (checkIsOnlyRole) {
    return userRoles.length > 1
      ? false 
      : requiredRolesArr.includes(userRoles[0]);
  }

  return requiredRolesArr.some(role => userRoles.includes(role));
}

export type RoleGetter = `isUser${Capitalize<Roles>}`;

export const userRoleGetters: RoleGetter[] = [
    'isUserBuyer',
    'isUserAdmin',
    'isUserSeller',
    'isUserInspector',
    'isUserStoreManager',
    'isUserCarmigoDirect',
    'isUserWholesaler',
    'isUserDsr',
];

/**
 * Mix the user role getters to local computed properties.
 */
export function mapUserRoleGetters<T extends RoleGetter[]>(
    pick?: T,
): Record<T extends undefined ? RoleGetter : T[number], () => boolean> {
    const roles: RoleGetter[] = pick || userRoleGetters;
    return mapGetters(roles);
}

export function isLoggedIn() {
    return Boolean(this.$store.state.user.profile);
}
export function isUserCarmigoDirectDealer() {
    if (!this.$store.state.user?.profile) {
        return false;
    }
    return this.$store.state.user.profile.role.includes('carmigoDirect');
}

export function isUserBuyer() {
    if (!store.state.user.profile) return false;
    const role = store.state.user.profile.role
    return role.includes('buyer') || role === 'buyer';
}

export function isUserAdmin(user=undefined) {
    if (!user || !user?.profile) {
      user = store.state.user;
    }
    if (!user) {
      return false;
    }
    const role = user.profile.role;
    return role === 'admin' || role.includes('admin');
}

export function isUserInspector() {
    if (!store.state.user.profile) return false;
    const role = store.state.user.profile.role
    return role.includes('inspector') || role === 'inspector';
}

export function isUserDsr() {
  console.log('IS USER DSR')
  if (!store.state.user.profile) {
    return false;
  }
  const role = store.state.user.profile.role;
  console.log('IS DSR??', role.includes('dsr') || role === 'dsr');
  return role.includes('dsr') || role === 'dsr';
}

export function isUserImposter() {
  if (!store?.state?.user?.profile) {
    return false;
  }
  const role = store.state.user.profile.role;
  return role.includes('imposter') || role === 'imposter';
}

export function isUserHighestBidder() {
    if (!this.$store.state.user.profile) return false;
    if (this.vehicle) {
        return this.$store.state.user.profile.id === this.vehicle.auction.highestBidder.id;
    }
    if (this.carDetails) {
        return this.$store.state.user.profile.id === this.carDetails.auction.highestBidder.id;
    }
}

export function isUserOriginalSeller() {
    if (!this.$store.state.user.profile) return false;
    return this.$store.state.user.profile.id === this.negotiationData.sellerId;
}

export function isUserSeller() {
    if (!this.$store.state.user.profile) return false;
    const role = this.$store.state.user.profile.role
    return role === 'seller' || role.includes('seller');
}

export function isUserCarSeller(carSellerId) {
    return this.$store.state.user.profile.id === carSellerId;
}

export function isUserLogged() {
    return !!Object.keys(store.state.user).length;
}

export function isUserWinner() {
    const userProfile = this.$store.state.user.profile;

    if (this.vehicle) {
        if (!userProfile || !this.vehicle.buyer) {
            return false;
        }
        return userProfile.id == this.vehicle.buyer.id;
    }

    if (this.carDetails) {
        if (!userProfile || !this.carDetails.buyer) {
            return false;
        }
        return userProfile.id === this.carDetails.buyer.id;
    }
}

// must import to component's methods
export async function didSellerAcceptTermsAndConditions(vehicleListingId) {
  return await GET(`/vehicles/getTermsAcceptedBySellerForVehicleListing/${vehicleListingId}`)
    .then(res => {
      if (!res.data) {
        openToast(
          'is-danger',
          'Seller has not accepted the terms & conditions'
        );  
      }
        if (res.data) return res.data;
    })
    .catch(err => {
      console.log(err);
      openToast(
        'is-danger',
        "Something went wrong while checking seller's terms & conditions",
      );
    })
}

export async function checkAccountStatus(vehicleListingId) {
    if (!vehicleListingId) {
        return false;
    }
    return await checkFirebaseAccountStatusWithVehicleListingId({vehicleListingId})
        .then(res => res.length)
        .catch(error => {
            console.log(`Error in checkAccountStatus for vehicleListingId: ${vehicleListingId}`, error);
            throw new Error(error);
        })
}


export function convertUserSearchToModifierSchema(searchField?: 'email' | 'phoneNumber', searchValue?: string): ModifierOptions | undefined {
  if (!searchField || !searchValue) {
    return undefined;
  }

  let filters: ModifierOptionsFilterObject[] | undefined = undefined;
  switch(searchField) {
    case 'email':
      filters = [{
        property: 'person.email',
        comparator: 'iLike',
        values: searchValue,
      }];
      break;
    case 'phoneNumber':
      filters = [{
        property: 'person.phoneNumber',
        comparator: 'iLike',
        values: searchValue,
      }];
      break;
      break;
  }

  return { filters };
}

export function openImpersonateUserDialog(
  { personId, firstName, lastName }: { personId: number, firstName: string, lastName: string }, 
  dialogConfig: Partial<BDialogConfig>={},
  apiConfig: APIConfig={},
) {
  openConfirmationDialog({
    title: 'Impersonate User?',
    message: `Are you sure you want to impersonate ${firstName} ${lastName}?`,
    ...dialogConfig,
    onConfirm: (value, dialog) => {
      if (dialogConfig.onConfirm) {
        dialogConfig.onConfirm(value, dialog);
      }
      impersonateUser(personId, apiConfig);
    },
  });
}

export function openResetBankInfoDialog(user: { personId: number, firstName: string, lastName: string }, dialogConfig: Partial<BDialogConfig>={}, apiConfig: APIConfig={}) {
  openConfirmationDialog({
    title: 'Reset Bank Info?',
    message: `Are you sure you want to delete bank info for ${user.firstName} ${user.lastName}?`, 
    ...dialogConfig,
    onConfirm: (value, dialog) => {
      if (dialogConfig.onConfirm) {
        dialogConfig.onConfirm(value, dialog);
      }
      resetBankInfoByPersonId(user.personId, apiConfig);
    },
  });
}

export function openAddAddressModal(email: string, modalConfig: Partial<BModalConfig>={}) {
  openModal({
    component: TheAddAddressModal,
    props: {
      email,
    },
    ...modalConfig,
  });
}