
import draggable from 'vuedraggable';
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { ImageDTO } from '../types';

const __default__ = defineComponent({
    name: 'ThePhotoGallery',
    props: {
        name: {
            type: String,
            required: true,
        },
        photos: {
            type: Array as PropType<ImageDTO[]>,
            default: () => { return [] },
        },
        isDraggable: {
            type: Boolean,
            default: false,
        },
        isClickable: {
            type: Boolean,
            default: false,
        },
        selectMax: {
            type: Number,
            required: false,
        },
        numColumns: {
            type: Number,
            default: 3, 
        },
        highlightPhotos: {
            type: Array as PropType<string[]>,
            required: false,
        },
    },
    components: {
        draggable,
    },
    setup(props, { emit, attrs }) {
        const photosUpdated: Ref<ImageDTO[]> = ref(props.photos);
        const isDragging = ref(false);
        const selectedPhotos: Ref<ImageDTO[]> = ref([]);
        const numGalleryColumns = computed(() => props.numColumns);

        function isMoveAllowed({ relatedContext, draggedContext }) {
            if (!props.isDraggable) {
                return false;
            }

            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;

            return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
        }

        function selectPhoto(photo: ImageDTO) {
            if (!props.isClickable) {
                return;
            }

            let selectedPhotosIdx = selectedPhotos.value.findIndex(selectedPhoto => selectedPhoto.filename == photo.filename);
            if (selectedPhotosIdx >= 0) {
                selectedPhotos.value.splice(selectedPhotosIdx, 1);
            } else {
                if (props.selectMax == 1) {
                    selectedPhotos.value = [photo];
                } else {
                    selectedPhotos.value.push(photo);
                }
                emit('update:selectedPhotos', selectedPhotos.value);
            }
        }

        function isPhotoSelected(photo: ImageDTO) {
            return selectedPhotos.value.some(selectedPhoto => photo.vehicleListingPhotoId ? photo.vehicleListingPhotoId == selectedPhoto.vehicleListingPhotoId : photo.filename == selectedPhoto.filename);
        }

        return {
            photosUpdated,
            isMoveAllowed,
            isDragging,
            selectedPhotos,
            selectPhoto,
            isPhotoSelected,
            numGalleryColumns,
        }
    },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "6b2cfa22": (_vm.numGalleryColumns)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__