
import { defineComponent, PropType, Ref, ref } from 'vue';
import { useBidHistoryBuyers, useBidHistoryHighlights } from '../composables';
import { BidHistoryBid, BidHistoryListing } from '../types';
import { scrollToFirstElement, toCurrency } from '../utils';

import InfoHighlight from './Arbitrations/InfoHighlight.vue';
import Modal from './Modal.vue';
import TheBidHistory from './TheBidHistory.vue';
import TheInfoHeaderVehicleListing from './TheInfoHeaderVehicleListing.vue';

export default defineComponent({
    name: 'TheBidHistoryModal',
    props: {
        bidHistory: {
            type: Array as PropType<BidHistoryBid[]>,
            required: false,
        },
        vehicle: {
            type: Object as PropType<BidHistoryListing>,
            required: true,
        },
        highestBid: {
            type: Object,
            required: false,
        },
        highestProxyBid: {
            type: Object,
            required: false,
        },
    },
    components: {
        InfoHighlight,
        Modal,
        TheBidHistory,
        TheInfoHeaderVehicleListing,
    },
    setup(props, { emit, attrs }) {
        const { highlights } = useBidHistoryHighlights({
            bidHistory: props.bidHistory,
            highestBidAmount: props.highestBid?.amount,
            highestProxyBidAmount: props.highestProxyBid?.amount,
            reservePrice: props.vehicle.reservePrice,
            buyItNowPrice: props.vehicle.buyItNowPrice,    
            reserveMet: props.highestBid?.reserveMet,
        });

        const { buyers, stores } = useBidHistoryBuyers({ bidHistory: props.bidHistory })

        const focusedClassName: Ref<string | undefined> = ref(undefined);
        function focusBid(event) {
            focusedClassName.value = event;
            window.setTimeout(() => {
                scrollToFirstElement(event, { block: 'center' });
            }, 100);
        }

        return {
            toCurrency,
            highlights,
            focusBid,
            focusedClassName,
            buyers,
            stores,
        }
    },
});
