
import AppManageZip from './AppManageZip.vue';

export default {
    name: 'AppNavbarZip',
    components: {
        AppManageZip,
    },
    data() {
        return {
            menuOpen: false,
            hover: false,
        };
    },
    computed: {
        getDisplayZipValue() {
            return this.$store.getters.getTransportationZip;
        },
        underlineBoxGreen() {
            return this.menuOpen || this.hover;
        },
    },
    methods: {
        openMenu(active: boolean) {
            this.menuOpen = active;
        },
    },
}
