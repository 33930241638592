
import { defineComponent } from 'vue';

import Modal from './Modal.vue';

export default defineComponent({
    name: 'TheBuyerDetailsRebateModal',
    components: {
        Modal,
    },
});
