
import { defineComponent, Ref, ref } from 'vue';

import AppLoadingSkeleton from './AppLoadingSkeleton.vue';
import ButtonIcon from './ButtonIcon.vue';

export default defineComponent({
    name: 'TheListingDetailsCard',
    props: {
        showEmptyText: {
            type: Boolean,
            default: false,
        },
        showLoading: {
            type: Boolean,
            default: true,
        },
        useCollapse: {
            type: Boolean,
            default: true,
        },
        isExpandedInitial: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        AppLoadingSkeleton,
        ButtonIcon,
    },
    setup(props) {
        const isExpanded: Ref<boolean> = ref(props.isExpandedInitial);

        return {
            isExpanded,
        }
    }
});
