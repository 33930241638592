<template>
    <div class="initials-icon flex-row justify-center align-center">
        <span v-if="firstInitial || lastInitial">{{ firstInitial }}{{ lastInitial }}</span>
        <b-icon v-else icon="account" size="is-medium" type="is-white" style="opacity: 60%" />
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: 'TheAppInitialsIcon',
    props: {
        firstName: {
            type: String,
            required: false,
        },
        lastName: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const firstInitial = ref(props.firstName ? props.firstName[0] : undefined);
        const lastInitial = ref(props.lastName ? props.lastName[0] : undefined);

        if (props.name) {
            let splitName = props.name?.split(' ');
            firstInitial.value = splitName[0][0];
            lastInitial.value = splitName[1][0];
        }

        return {
            firstInitial,
            lastInitial,
        }
    },
});
</script>
<style scoped lang="postcss">
.initials-icon {
    font-size: 1.15rem;
    font-weight: 700;
    border: 3px solid;
    border-radius: 50%;
    width: 2em;
    height: 2em;
}
/* inherits parent color */
</style>
