
import { computed, defineComponent, PropType, ref } from 'vue';
import { ImageDTO } from '@/types';

import Modal from '@/components/Modal.vue';
import TheAnnouncement from '@/components/TheAnnouncement.vue';
import ThePhotoGallery from '@/components/ThePhotoGallery.vue';

export default defineComponent({
    name: 'ThePhotoGalleryModal',
    props: {
        photos: {
            type: Array as PropType<ImageDTO[]>,
            default: () => { return [] },
        },
    },
    emits: ['close', 'submit'],
    components: {
        TheAnnouncement,
        Modal,
        ThePhotoGallery,
    },
    setup(props, context) {
        const selectedPhotos = ref<ImageDTO[]>([]);
        const replacingAnnouncement = computed(() => selectedPhotos.value.some(photo => photo.announcement));

        return {
            selectedPhotos,
            replacingAnnouncement,
        }
    }
});
