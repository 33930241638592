var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppListingDetails',_vm._b({scopedSlots:_vm._u([{key:"images",fn:function(){return [_c('AppLoadingSkeleton',{attrs:{"height":"300px","width":"100%"}})]},proxy:true},{key:"vehicleBasics",fn:function(){return [_c('div',{staticClass:"my-3"},[_c('AppLoadingSkeleton',{attrs:{"height":"25px","width":"200px"}}),_c('AppLoadingSkeleton',{attrs:{"height":"24px","width":"230px"}}),_c('AppLoadingSkeleton',{attrs:{"height":"24px","width":"230px"}})],1)]},proxy:true}])},'AppListingDetails',{
        loadingAnnouncements: true,
        loadingBookoutOptions: true,
        loadingBidHistory: true,
        loadingTireTread: true,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }