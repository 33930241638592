
import { defineComponent, PropType } from 'vue';
import { SellerType, TitleStatus } from '../types';

import TheListingDetailsCard from './TheListingDetailsCard.vue';
import TheListingDetailsSellerStat from './TheListingDetailsSellerStat.vue';

export default defineComponent({
    name: 'TheListingDetailsSellerDetails',
    props: {
        sellerStoreId: {
            type: Number,
            required: false,
        },
        titleStatus: {
            type: String as PropType<TitleStatus>,
            required: false,
        },
        sellerType: {
            type: String as PropType<SellerType>,
            required: false,
        },
        sellerReason: {
            type: String,
            required: false,
        },
        store: {
            type: String,
            required: false,
        },
        facilitatingAuctionStoreName: {
            type: String,
            required: false,
        },
        showAllDetails: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        TheListingDetailsCard,
        TheListingDetailsSellerStat,
    }
});
