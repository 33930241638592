<template>
    <div class="flex-row align-center" 
        :class="{
            'text-center': centerText,
            'is-required': isRequired,
            'show-border': showBorder,
            'input-field-custom': useCustomStyles,
        }">
        <div class="field-image" v-if="hasImage">
            <slot name="img" />
        </div>

        <b-field
            :type="getValidationType"
            :message="getValidationMessage"
            :label="getLabel"
        >
            <b-input 
                v-if="type==='currency'"
                v-model="inputValue"
                @input="formatAndEmitIfValid"
                @keyup.native.enter="value => formatAndEmitIfValid(value, true)"
                @blur="$emit('blur')"
                :type="updatedType"
                :placeholder="placeholder"
                :id="id"
                :maxlength="getMaxLength"
                :disabled="$attrs.disabled ?? isDisabled"
                v-cleave="type==='currency' && masks.numeral ? masks.numeral : {}"
                :key="`cleave-${updateKey}`"
                :clearable="isClearable"
                :loading="isLoading || isLoadingReferral"
                :required="isRequired"
                v-bind="$attrs"
            />

            <b-numberinput 
                v-else-if="type==='numberInput'"
                v-model='inputValue'
                @input='formatAndEmitIfValid'
                @keyup.native.enter='value => formatAndEmitIfValid(value, true)'
                :type='updatedType'
                :placeholder='placeholder'
                :id='id'
                :disabled='isDisabled'
                :clearable='isClearable'
                :controls='showControls'
                :rounded='rounded'
                :required="isRequired"
            />

            <b-input
                v-else-if="type==='isNumberInputWithFormatting'"
                v-model='inputValue'
                @input='formatAndEmitIfValid'
                @keyup.native.enter='value => formatAndEmitIfValid(value, true)'
                :type='updatedType'
                :id='id'
                :disabled='isDisabled'
                :clearable='isClearable'
                v-cleave="handleisNumberInputWithFormattingCleave"
                :rounded='rounded'
                horizontal
                :required="isRequired"
                :pattern="pattern"
                :inputmode="inputmode"
            />

            <b-input
                v-else-if="isTextInput"
                v-model="inputValue"
                @input="formatAndEmitIfValid"
                @keyup.native.enter="value => formatAndEmitIfValid(value, true)"
                @blur="$emit('blur')"
                :type="updatedType"
                :placeholder="placeholder"
                :id="id"
                :maxlength="getMaxLength"
                :has-counter="false"
                :password-reveal="type === 'password'"
                :disabled="isDisabled"
                :clearable="isClearable"
                :loading="isLoading || isLoadingReferral"
                :required="isRequired"
            />
            
            <b-input
                v-else-if="type == 'textarea'"
                v-bind="$attrs"
                v-model="inputValue"
                @input="formatAndEmitIfValid"
                @icon-right-click="$emit('iconRightClick')"
                @keyup.native.enter="value => formatAndEmitIfValid(value, true)"
                @blur="$emit('blur')"
                @focus="$emit('focus')"
                type="textarea"
                :placeholder="placeholder"
                :id="id"
                :maxlength="getMaxLength"
                :has-counter="false"
                :disabled="isDisabled"
                :clearable="isClearable"
                :loading="isLoading || isLoadingReferral"
                :required="isRequired"
            />
            
            <b-input
                v-else-if="isNumberInput || isPhoneNumberInput"
                v-model="inputValue"
                @input="formatAndEmitIfValid"
                @blur="$emit('blur')"
                @keyup.native.enter="value => formatAndEmitIfValid(value, true)"
                :type="updatedType"
                :placeholder="placeholder"
                :maxlength="getMaxLength"
                :id="id"
                :has-counter="false"
                :disabled="isDisabled"
                :clearable="isClearable"
                :rounded='rounded'
                :required="isRequired"
                :pattern="pattern"
                :inputmode="inputmode"
            />

            <b-select
                v-else-if="type==='select'"
                v-model="inputValue"
                @input="formatAndEmitIfValid"
                :placeholder="placeholder"
                :required="isRequired"
                v-bind="$attrs"
            >
                <slot name="body" v-bind:possibleValues="possibleValues">
                    <option 
                        v-for="option,idx in possibleValues"
                        :key="`select-${idx}`"
                    >
                        {{ option }}
                    </option>
                </slot>
            </b-select>

            <b-dropdown
                v-else-if="type==='dropdown'"
                v-model="inputValue"
                v-bind="$attrs"
                @input="formatAndEmitIfValid"
                aria-role="list"
                :disabled="isDisabled"
                :position="dropdownPosition ? dropdownPosition : 'is-bottom-right'"
                :required="isRequired"
            >
                <template #trigger="{ active }">
                    <b-button 
                        :label="inputValue ? inputValue.text ?? inputValue : placeholder"
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                        :class="inputValue ? 'value' : 'placeholder'"
                        :disabled="isDisabled"
                    />
                </template>
                <div v-if="isLoading" class="loading-dropdown-text">Loading...</div>

                <b-dropdown-item 
                    v-for="option, idx in possibleValues"
                    :key="`select-${idx}`"
                    :value="option.value ? option.value : option"
                    aria-role="listitem"
                    class="flex-row align-center"
                >   
                <div v-if="colorCircle" class="color-circle" :style="{ background: getBackgroundColor(option?.text ?? option) }"></div>
                    <img
                        v-if="option.img"
                        class="dropdown-icon"
                        :src="getGraphic(option.img)"
                        :alt="option.imgAltText"
                    />
                    {{ option.text ? option.text : option }}
                </b-dropdown-item>

            </b-dropdown>
            <b-dropdown
                v-else-if="type==='multipleDropdown'"
                v-model="inputValue"
                @input="formatAndEmitIfValid"
                aria-role="list"
                :disabled="isDisabled"
                :multiple='isMultiple'
                :required="isRequired"
            >
                <template #trigger="{ active }">
                    <b-button 
                        :label='placeholder'
                        :icon-right="active ? 'menu-up' : 'menu-down'"
                        class='placeholder'
                    />
                </template>
                <div v-if="isLoading" class="loading-dropdown-text">Loading...</div>

                <b-dropdown-item 
                    v-for="option, idx in possibleValues"
                    :key="`select-${idx}`"
                    :value="option.value ? option.value : option"
                    aria-role="listitem"
                    class="flex-row align-center"
                >
                <div>
                    <span v-if="displayBox(option.value)" class="icon mdi mdi-radiobox-marked"></span>
                    <span v-else class="icon mdi mdi-radiobox-blank"></span>
                </div>
                    {{ option.text ? option.text : option }}
                    <div class="inputDisplayBoxContainer" v-if="displayBox(option.value) && option.boxDisplay">
                        <b-tag :type="option.price < 0 ? 'is-danger' : 'is-primary'" size="is-medium">{{option.boxDisplay}}</b-tag>
                    </div>
                </b-dropdown-item>

            </b-dropdown>
            
            <b-autocomplete
                v-else-if="type==='autocomplete'"
                v-model="inputValue"
                @input="formatAndEmitIfValid"
                @select="$emit('select', $event)"
                :id="id"
                :placeholder="placeholder"
                :data="filteredPossibleValues"
                :loading="isLoading"
                :disabled="isDisabled"
                :open-on-focus="openOnFocus"
                :clearable="isClearable"
                :required="isRequired"
                :field="$attrs.field"
            />

            <div v-if="addCustomController" class="control-custom">
                <b-button class="customControllerButton" type="is-static" :label='customControllerText' />
            </div>
        </b-field>

    </div>
</template>

<script>
import {
    phoneFormatter,
    validateEmail,
    validatePassword,
    validatePhone,
    validateReferralCode,
    validateVin,
    validateZip,
} from '../validation';
import debounce from 'lodash/debounce';
import {
    colorKeywordToRGB,
    getInputMasks,
    dollarAmountToInt
} from '../utils';

export default {
    inheritAttrs: false,
    name: 'InputField',
    // DON'T USE THIS COMPONENT unless you have to
    // we have newer AppInputField... components for most input types
    props: {
        id: {
            type: String,
            required: false, // use for focusOnElement
            default: '',
        },
        value: {
            type: String | Number | Array,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Enter value'
        },
        hasImage: {
            type: Boolean,
            required: false,
            default: false,
        },
        useCustomStyles: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            required: false,
            default: 'text',
            validation: (prop) => {
                const validTypes = [
                    'text',
                    'number',
                    'phoneNumber',  // number input, but should validate
                    'email',        // text input, but should validate
                    'vin',          // text input, but should validate
                    'referral',     // text input, but should validate
                    'password',     // text input, but should validate
                    'zip',          // number input, but should validate
                    'autocomplete', 
                    'select',
                    'dropdown',
                    'currency',
                ];

                return validTypes.includes(prop);
            }
        },
        isMultiple: {
            type: Boolean,
            required: false,
            default: false
        },
        showControls: {
            type: Boolean,
            required: false,
            default: false
        },
        maximumNumberLength: {
            type: Number,
            required: false,
            default: 0
        },
        minimumNumberLength: {
            type: Number,
            required: false,
            default: 0
        },
        possibleValues: {
            type: Array,
            required: false,
            // required if type is 'autocomplete' or 'select'
        },
        openOnFocus: {
            type: Boolean,
            required: false,
            default: false,
            // for autocomplete types, optional
        },
        shouldValidateCustom: {
            type: Boolean,
            required: false,
        },
        customValidator: {
            type: Function,
            required: false,
        },
        customInvalidMessage: {
            type: String,
            required: false,
            default: undefined,
        },
        centerText: {
            type: Boolean,
            required: false,
            default: false,
        },
        isClearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        validateFirst: {
            type: Boolean,
            required: false,
            default: false,
        },
        showBorder: {
            type: Boolean,
            required: false,
            default: false,
        },
        dropdownPosition: {
            type: String,
            required: false,
        },
        rounded: {
            type: Boolean,
            required: false,
            default: false
        },
        doNotShowSuccessLabel: {
            type: Boolean,
            required: false,
            default: false
        },
        doNotShowFailureLabel: {
            type: Boolean,
            required: false,
            default: false
        },
        cleaveType: {
            type: String,
            required: false,
            default: 'number',
            validation: (prop) => {
                const validTypes = [
                    'numberThousands',
                    'currency',
                    'number'
                ]
                return validTypes.includes(prop);
            }
        },
        addCustomController: {
            type: Boolean,
            required: false,
            default: false
        },
        customControllerText: {
            type: String,
            required: false,
            default: 'unit'
        },
        label: {
            type: String,
            required: false,
        },
        pattern: {
            type: String,
            required: false,
            default: '[0-9]*',
        },
        inputmode: {
            type: String,
            required: false,
            default: 'numeric',
        },
        colorCircle: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            inputValue: this.value,
            isLoadingReferral: false,
            updatedType: this.type,
            validateInputs: false,
            masks: {},
            updateKey: 0,
            isValidAsync: false,
            isCarmigoDirect: this.$store?.state.isCarmigoDirect,
            colors:{
                Black: '#000000',
                White: '#ffffff',
                Gray: '#C4C4C4',
                Blue: '#3856A3',
                Red: '#C61414',
                Green: '#266112',
                Yellow: '#E9E350',
                Burgundy: '#800020',
            },
        }
    },
    beforeMount() {
        this.masks = getInputMasks();
        this.updateKey++;
    },
    mounted() {
        if (this.validateFirst) this.formatAndEmitIfValid();
    },
    computed: {
        getLabel(){
            return this.label ? this.label : null;
        },
        handleisNumberInputWithFormattingCleave() {
            // Currency Cleave
            if(this.cleaveType === 'currency') {
                return this.masks.number ? this.masks.number : {}
            }

            // Number Cleave
            if(this.cleaveType === 'numberThousands') {
                return this.masks.number ? this.masks.number : {}
            }

            if(this.cleaveType === 'number') {
                return this.masks.number ? this.masks.number : {};
            }

            if(this.cleaveType === 'numberThousandsPlus') {
                if(this.value < this.maximumNumberLength){ 
                    return this.masks.number ? this.masks.number : {}
                }
                return this.masks.number ? this.masks.number : {}
            }
            
            if(this.cleaveType === 'numberPlus') {
                if(this.value < this.maximumNumberLength){ 
                    return this.masks.number ? this.masks.number : {}
                }
                return this.masks.number ? this.masks.number : {}
            }
        },
        getValidationType() {
            if (this.type === 'referral' && this.isLoadingReferral) return '';
            if (!this.shouldValidate) return '';

            // Don't show success or failure feedback
            if (this.doNotShowFailureLabel && this.doNotShowSuccessLabel) return '';
            if (this.doNotShowFailureLabel) return this.isValid ? 'is-success' : '';
            if (this.doNotShowSuccessLabel) return this.isValid ? '' : 'is-danger';

            // Show success or failure feedback
            return this.isValid || this.isValidAsync ? '' : 'is-danger'
        },
        getValidationMessage() {
            if (this.type === 'referral' && this.isLoadingReferral) return '';

            return this.shouldValidate
                ? this.isValid || this.isValidAsync 
                    ? ''
                    : this.customInvalidMessage 
                        ? this.customInvalidMessage
                        : this.invalidMessage
                : ''
        },
        getMaxLength() {
            switch (this.type) {
                case 'phoneNumber': 
                    return '14';
                case 'zip':
                    return '5';
                case 'vin':
                    return '17';
                case 'referral':
                    return '6';
            }
        },
        /////// input types & values ////////
        isTextInput() {
            const textTypes = ['text', 'email', 'vin', 'password', 'currency', 'referral'];
            const isTextType = textTypes.includes(this.type);
            if (isTextType) this.updatedType = 'text';
            if (this.type === 'password') this.updatedType = this.type;
            return isTextType;
        },
        isNumberInput() {
            const numberTypes = ['number', 'zip', 'numberInput', 'isNumberInputWithFormatting'];
            const isNumberType = numberTypes.includes(this.type);
            if (isNumberType) this.updatedType = 'number';
            return isNumberType;
        },
        isPhoneNumberInput() {
            const phoneTypes = ['phoneNumber'];
            const isPhoneNumberType = phoneTypes.includes(this.type);
            if (isPhoneNumberType) this.updatedType = 'tel';
            return isPhoneNumberType;
        },
        filteredPossibleValues() {
            return this.possibleValues?.filter((option) => {
                if (!this.inputValue) return true;

                const findValue = this.inputValue.toLowerCase();

                // If `field` is provided, use that field from the option for filtering
                const optionValue = this.$attrs.field ? option[this.$attrs.field] : option;

                return (
                    optionValue
                        .toString()
                        .toLowerCase()
                        .indexOf(findValue) >= 0
                );
            }) ?? [];
        },

        /////// validation ////////
        shouldValidate() {
            if (this.shouldValidateCustom !== undefined) {
                return this.shouldValidateCustom;
            }
            if (!this.inputValue) return false;
            if (this.type == 'currency' && !dollarAmountToInt(this.inputValue)) {
                return false;
            }
            if (this.customValidator) {
                return true;
            }
            const validateTypes = ['phoneNumber', 'email', 'vin', 'autocomplete', 'password', 'referral', 'zip', 'numberInput', 'isNumberInputWithFormatting'];
            return validateTypes.includes(this.type) && this.validateInputs;
        },
        isAsyncValidationType() {
            const asyncValidationTypes = ['referral'];
            return asyncValidationTypes.includes(this.type);
        },
        isValid() {
            if (this.customValidator) return this.customValidator(this.inputValue);
            switch (this.type) {
                case 'password': 
                    return validatePassword(this.inputValue);
                case 'phoneNumber': 
                    return validatePhone(this.inputValue);
                case 'email':
                    return validateEmail(this.inputValue);
                case 'vin':
                    return validateVin(this.inputValue);
                case 'autocomplete':
                    if (!this.inputValue) return true;
                    return this.possibleValues.includes(this.inputValue);
                case 'referral': 
                    return false;
                case 'zip':
                    return validateZip(this.inputValue);
                case 'numberInput':
                    return this.validateNumberInput();
                case 'isNumberInputWithFormatting':
                    return this.validateNumberInputWithFormatting();
                default:
                    return true;
            }
        },
        invalidMessage() {
            switch (this.type) {
                case 'phoneNumber': 
                    return 'Please enter a valid 10-digit phone number';
                case 'email':
                    return 'Please enter a valid email';
                case 'vin':
                    return 'Please enter a valid 17-digit VIN';
                case 'autocomplete':
                    return 'Please select from the suggested responses';
                case 'password':
                    return 'Password must be at least 8 characters long';
                case 'referral':
                    return 'Referral code is invalid';
                case 'zip':
                    return 'Please enter a valid zip';
                case 'isNumberInputWithFormatting':
                case 'numberInput':
                    return `Please enter a number between ${this.minimumNumberLength} and ${this.maximumNumberLength}`;
            }
        },
    },
    methods: {
        getBackgroundColor(value) {
            if (this.colors[value]) {
                return this.colors[value];
            }


            return colorKeywordToRGB(value);
        },
        displayBox(value) {
            if (this.inputValue?.findIndex((item) => item === value) >= 0) {
                return true;
            }
            return false;
        },
        validateNumberInput() {
            if (this.inputValue <= this.maximumNumberLength && this.inputValue >= this.minimumNumberLength) {
                return true;
            }
            return false;
        },
        validateNumberInputWithFormatting() {
            let validationNumber = this.inputValue;
            if(typeof validationNumber === 'string') validationNumber = dollarAmountToInt(validationNumber);
            if(validationNumber <= this.maximumNumberLength && validationNumber >= this.minimumNumberLength) {
                return true;
            }
            return false;
        },
        validateReferralCode,
        async asyncIsValid() {
            switch (this.type) {
                case 'referral':
                    this.isLoadingReferral = true;
                    var isValid = false;
                    if (this.inputValue.length === parseInt(this.getMaxLength)) {
                        isValid = await this.validateReferralCode(this.inputValue);
                    }
                    this.isLoadingReferral = false;
                    this.isValidAsync = isValid;
                    return isValid;

            }
        },
        getGraphic(graphicName) {
            var img = this.isCarmigoDirect
                ? require.context('../assets/greyscale/', false, /\.png$/)
                : require.context('../assets/', false, /\.png$/);

            return img(`./${graphicName}.png`);
        },
        formatInput() {
            switch (this.type) {
                case 'phoneNumber':
                    this.inputValue = phoneFormatter(this.inputValue);
                    return;
                case 'referral':
                    this.inputValue = this.inputValue.toUpperCase();
                    return;
                case 'email':
                    this.inputValue = this.inputValue.trim();
                    return;
            }
        },
        async formatAndEmitIfValid(value=null, fromEnter=false) {
            this.validateInputs = true;
            this.formatInput();

            var isValid = this.isValid;
            if (this.isAsyncValidationType) isValid = await this.asyncIsValid();
            if (!isValid) this.$emit('invalidInput', this.inputValue);
            if (isValid) {
                this.$emit('input', this.inputValue);
                this.$emit('update:value', this.inputValue);
            }
            if (fromEnter && isValid) this.$emit('keypressEnter', this.inputValue);
        },
    },
}
</script>

<style scoped lang='postcss'>
.dropdown-icon {
    width: 2rem;
    margin-right: 10px;
}
.field {
    width: 100%;
}
.field-image {
    width: 40px;
    height: 40px;
    margin-right: .65rem;
}

a.dropdown-item.is-active {
    background-color: var(--primary-light);
    color: var(--primary);
}

.loading-dropdown-text {
    padding: 1rem;
    font-style: italic;
}

.inputDisplayBoxContainer {
    display: flex;
    justify-content: right;
    width: 100%;
}
.color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid black;
    margin:5px 10px 5px 2px
}
</style>
