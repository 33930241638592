
import { defineComponent, PropType } from 'vue';

import AppListingDetails from './AppListingDetails.vue';
import AppLoadingSkeleton from './AppLoadingSkeleton.vue';

export default defineComponent({
    name: 'AppListingDetailsLoading',
    props: {},
    components: {
        AppListingDetails,
        AppLoadingSkeleton,
    },
});
