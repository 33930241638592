
import { defineComponent, PropType, Ref, ref } from 'vue';
import { MarketplaceListingOfferDataDTOFormatted } from '../types';

import TheListingDetailsCard from './TheListingDetailsCard.vue';
import TheMarketplaceListingOffersTable from './TheMarketplaceListingOffersTable.vue';

export default defineComponent({
    name: 'TheListingDetailsMarketplaceOffers',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: true,
        }
    },
    components: {
        TheListingDetailsCard,
        TheMarketplaceListingOffersTable,
    },
    setup(props, { emit, attrs }) {
        const offers: Ref<MarketplaceListingOfferDataDTOFormatted[]> = ref([]);
        const fetchedOffers: Ref<boolean> = ref(false);

        function setOffers(value) {
            offers.value = value;
            fetchedOffers.value = true;
        }

        return {
            offers,
            fetchedOffers,
            setOffers,
        }
    },
});
