import { computed, onBeforeUnmount, onMounted, ref } from "vue";

export function useBreakpoint() {
    onMounted(() => {
        window.addEventListener('resize', onWindowResize);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', onWindowResize);
    })

    const windowWidth = ref(window.innerWidth);
    function onWindowResize() {
        windowWidth.value = window.innerWidth;
    }

    // Adapted from https://bulma.io/documentation/overview/responsiveness/#breakpoints
    const bulmaBreakpoint = computed(() => {
        return {
            mobile: windowWidth.value <= 768,
            tablet: windowWidth.value >= 769 && windowWidth.value <= 1023,
            desktop: windowWidth.value >= 1024 && windowWidth.value <= 1215,
            widescreen: windowWidth.value >= 1216 && windowWidth.value <= 1407,
            fullhd: windowWidth.value >= 1408,
        }
    });

    return {
        windowWidth, 
        bulmaBreakpoint,
    }
}