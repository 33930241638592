import { 
    getDaysBeforeOrAfter,
} from './index';

export function isExpired(startDate, availableDays) {
    const now = new Date();
    const offerExpires = getDaysBeforeOrAfter(availableDays, 'after', new Date(startDate));
    const timeRemaining = offerExpires.getTime() - now.getTime();
    return timeRemaining <0;
}

export const mainAppStatuses = [
    'Registered',
    'InspectionScheduled',
    'Inspected',
    'AuctionScheduled',
    'Auctioning',
    'Negotiating',
    'Checkout',
    'Sold',
    'Completed',
    'NotSold',
    'Inactive'
];

export const dealerActionRequiredStatuses = [
    'DirectAwaitingOfferResponse',
    'DirectAwaitingDropoff',
    'DirectDropoffWindowExpired'
];

const styles = {
    actionRequired: {
        backgroundColor: 'var(--success-light)',
        color: 'var(--dark)'
    },
    noActionRequired: {
        backgroundColor: 'var(--grey-light)',
        color: 'var(--dark)'
    },
    danger: {
        backgroundColor: 'var(--danger)',
        color: 'var(--light)'
    },
    active: {
        backgroundColor: 'var(--grey)',
        color: 'var(--light)'
    },
    success: {
        backgroundColor: 'var(--success)',
        color: 'var(--light)'
    }
}
export const carmigoDirectStatuses = [
    {
        dbName: 'DirectSubmitted',
        displayName: {
            default: 'Profile Incomplete',
        },
        styles: {
            default: styles.noActionRequired,
            seller: styles.actionRequired,
        }
    },
    {
        dbName: 'DirectAwaitingOfferResponse',
        displayName: {
            default: 'Awaiting Dealer Response',
            dealer: 'Profile Complete',
        },
        styles: {
            default: styles.active,
            dealer: styles.actionRequired,
            seller: styles.noActionRequired,
        }
    },
    {
        dbName: 'DirectAwaitingCounterOfferResponse',
        displayName: {
            default: 'Dealer Counter Offered',
            dealer: 'Counter Offer Sent'
        },
        styles: {
            default: styles.active,
            seller: styles.actionRequired,
            dealer: styles.noActionRequired
        }
    },
    {
        dbName: 'DirectCounterOfferExpired',
        displayName: {
            default: 'Counter Offer Expired',
            admin: 'Dealer Counter Offered',
        },
        styles: {
            default: styles.danger,
        }
    },
    {
        dbName: 'DirectAwaitingDropoff',
        displayName: {
            default: 'Awaiting Dropoff'
        },
        styles: {
            default: styles.active,
            seller: styles.actionRequired,
            dealer: styles.actionRequired
        }
    },
    {
        dbName: 'DirectDropoffWindowExpired',
        displayName: {
            default: 'Dropoff Overdue',
            admin: 'Awaiting Dropoff',
        },
        styles: {
            default: styles.danger,
        }
    },
    {
        dbName: 'DirectAccepted',
        displayName: {
            default: 'Vehicle Sold',
            admin: 'Sold',
            dealer: 'Vehicle Purchased',
        },
        styles: {
            default: styles.success,
        }
    },
    {
        dbName: 'DirectDeclined',
        displayName: {
            default: 'No Sale',
        },
        styles: {
            default: styles.noActionRequired
        }
    }
];


export function getCarmigoDirectStatus(dbStatus: string): any {
    const matchingStatus = carmigoDirectStatuses.find(status => status.dbName === dbStatus);
    if (matchingStatus) {
        return matchingStatus;
    }
    return {
        dbName: dbStatus,
        displayName: "No Sale",
        adminDisplayName: dbStatus
    }
}

export function getCarmigoDirectStatusForUser(user: 'admin' | 'dealer' | 'seller', dbStatus: string) {
    if (!dbStatus) {
        return {
            dbName: dbStatus,
            displayName: 'Loading...',
            styles: styles.noActionRequired
        }
    }
    const matchingStatus = carmigoDirectStatuses.find(status => status.dbName === dbStatus);
    if (matchingStatus) {
        const status= {
            dbName: dbStatus,
            displayName: getCarmigoDirectStatusDisplayName(user, dbStatus),
            styles: getCarmigoDirectStatusStyles(user, dbStatus)
        };
        return status;
    }
    return {
        dbName: dbStatus,
        displayName: "No Sale",
        styles: styles.noActionRequired
    }
}

export function getCarmigoDirectStatusDisplayName(user: "admin" | "dealer" | "seller", dbStatus: string): string {
    const matchingStatus = getCarmigoDirectStatus(dbStatus);

    if (matchingStatus.displayName[user]) {
        return matchingStatus.displayName[user];
    }
    return matchingStatus.displayName.default;
}


export function getCarmigoDirectStatusesExcluding(excludeStatuses: string[]) {
    return carmigoDirectStatuses.filter(status => !excludeStatuses.includes(status.dbName));
}

export function isOfferDeclinedOrListedOnCarmigo(dbStatus: string) {
    return dbStatus === 'DirectDeclined' || mainAppStatuses.includes(dbStatus);
}

export function getCarmigoDirectStatusStyles(user, dbStatus) {
    const status = getCarmigoDirectStatus(dbStatus);

    if (status.styles[user]) {
        return status.styles[user];
    }
    return status.styles.default;
}

export const adminStatuses = getCarmigoDirectStatusesExcluding(['DirectCounterOfferExpired', 'DirectDropoffWindowExpired']);

export function isCarmigoDomain() {
    const domain = window.location.host;
      const carmigoDomains = [
        'app.carmigo.io',
        'dev.app.carmigo.io',
        'staging.app.carmigo.io',
        'localhost:8051'
      ];

      return carmigoDomains.includes(domain);
}
