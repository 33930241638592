var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"close":function($event){return _vm.$emit('close')},"confirm":_vm.saveAssignedInspector},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('TheAssignInspectorInput',_vm._b({on:{"select":function($event){_vm.assignedInspector = $event}}},'TheAssignInspectorInput',{ 
                vehicleListingId: _vm.vehicleListingId, 
                inspectorPersonId: _vm.inspectorPersonId,
                label: `Assign an inspector for listing #${_vm.vehicleListingId}`,
                appendToBody: true,
                customSubmit: true,
                autofocus: false,
            },false))]},proxy:true},{key:"buttonsLeft",fn:function(){return [(_vm.inspectorPersonId)?_c('AppButton',_vm._b({staticClass:"mr-6 no-border underline",on:{"click":_vm.removeAssignedInspector}},'AppButton',{
                label: 'Remove inspector',
                type: 'is-dark',
                loading: _vm.loadingRemoveInspector,
                outlined: true,
            },false)):_vm._e()]},proxy:true}])},'Modal',{
        isLoading: _vm.loadingSaveInspector,
        customLoading: true,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }