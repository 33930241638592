
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  props: {
    alertType: {
      type: String as PropType<'alert' | 'actionRequired'>, 
      required: false,
    },
    fill: {
      type: [String, Number], 
      required: false,
    }
  },
})
