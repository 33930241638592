<template>
  <section class="userContainer">
    <div class="titleContainer">
      <h1 class="is-size-3">{{ user.firstName }} {{ user.lastName }}</h1>
    </div>
    <div class="is-flex is-flex-direction-row spaceTop">
      <h2 class="is-size-5">
        Email:
      </h2>
      <p class="is-size-5 spaceLeft">{{ user.email }}</p>
    </div>
    <div class="is-flex is-flex-direction-row spaceTop">
      <h2 class="is-size-5">
        Phone:
      </h2>
      <p class="is-size-5 spaceLeft">{{ user.phoneNumber }}</p>
    </div>
    <div class="is-flex is-flex-direction-row spaceTop">
      <h2 class="is-size-5">
        Referral Code:
      </h2>
      <p class="is-size-5 spaceLeft">{{ user.referralCode }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReferralUserDetails",
  props: ["user"],
  data() {
    return {};
  },
};
</script>

<style></style>
