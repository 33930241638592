<template>
    <Modal 
        v-bind="{
            showButtons: ['cancel', 'submit'],
            isLoading: loadingSaveSellFee,
            customLoading: true,
        }"
        class="carmigo-modal"
        @close="$emit('close', { isCancelled: true })"
        @confirm="saveSellFee"
    >
        <template #header>
            Select Sell Fee
        </template>

        <template #body>
            <div v-if="loadingGetSellFee">
                Loading...
            </div>
            <div v-else>
                <div v-if="sellFeeTemplate" :key="`select-sell-fee-${sellFeeKey}`">
                    <div 
                        v-for="sellFee, idx in sellFeeTemplate" 
                        :key="`sell-fee-option-${idx}`"
                        class="my-2"
                    >
                        <div class="flex-row">
                            <b-radio 
                                v-model="selectedSellFeeIdx" 
                                :native-value="idx" 
                                @input="idx == finalIdx ? focusOnCustomInput() : ''"
                            />
                            <div
                                class="flex-row align-center justify-space-between"
                                style="width: 200px;"
                            >
                                <div class="pr-3">{{ sellFee.listingType }}</div>
                                <div class="pr-3 has-text-primary flex-row align-center fee">
                                    <InputField 
                                        v-if="sellFee.listingType == 'Other'" 
                                        v-model="sellFee.fee"
                                        type="currency"
                                        style="max-width: 100px;"
                                        @input="value => selectCustomSellFee(value)"
                                        id="custom-sell-fee-input"
                                    />
                                    <span v-else>{{ sellFee.fee | currency }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <InputField 
                        id="sell-fee-input"
                        v-model="sellFee"
                        :key="`sell-fee-input-${sellFeeKey}`"
                        type="currency"
                    />
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import { GET, POST, PUT } from '../../api';
import { focusOnElement, normalizeString, openDialogAlert, openToast } from '../../utils';
import InputField from '../InputField.vue';
import Modal from '../Modal.vue';

export default {
    name: 'SelectSellFeeModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        sellerStoreId: {
            type: Number,
            required: false,
        }
    },
    components: {
        InputField,
        Modal,
    },
    data() {
        return {
            loadingSaveSellFee: false,
            loadingGetSellFee: true,
            sellFee: undefined,
            listingType: undefined,
            sellFeeKey: 0,
            sellFeeTemplate: undefined,
            selectedSellFeeIdx: 0,
        }
    },
    async mounted() {
        await this.getSellFee();
        focusOnElement('sell-fee-input');
    },
    computed: {
        noSelection() {
            if (this.selectedSellFeeIdx == this.finalIdx && this.sellFeeTemplate) {
                return this.sellFeeTemplate[this.finalIdx]?.fee == '$';
            }
            return !this.selectedSellFeeIdx && this.selectedSellFeeIdx !== 0;
        },
        finalIdx() {
            return this.sellFeeTemplate?.length - 1;
        },
        selectedFeeFromTemplate() {
            if (this.noSelection || !this.sellFeeTemplate) {
                return undefined;
            }
            const selectedFee = this.sellFeeTemplate[this.selectedSellFeeIdx];
            return selectedFee;
        },
        selectedListingType() {
            if (!this.selectedFeeFromTemplate) {
                return undefined;
            }
            return this.selectedFeeFromTemplate.listingType == 'other' ? undefined : this.selectedFeeFromTemplate.listingType;
        }
    },
    methods: {
        async getSellFee() {
            await GET(`/vehicles/getSellFee/${this.vehicleListingId}`).then(res => {
                if (!res.data?.fee) {
                    this.useStoreSellFeeTemplate();
                    return;
                }
                this.listingType = res.data?.listingType;
                this.sellFee = res.data?.fee;
                this.sellFeeKey++;
                this.loadingGetSellFee = false;
            });
        },
        async saveSellFee() {
            this.loadingSaveSellFee = true;
            const selectedFeeFromTemplate = this.selectedFeeFromTemplate;
            const fee = selectedFeeFromTemplate ? selectedFeeFromTemplate.fee : this.sellFee;
            const listingType = this.selectedListingType == 'Other' ? undefined : this.selectedListingType;
            const dto = {
                vehicleListingId: this.vehicleListingId,
                fee: parseInt(normalizeString(fee.toString())),
                listingType
            }
            await POST(`/vehicles/saveSellFee`, dto).then(res => {
                this.loadingSaveSellFee = false;
                openToast('is-success', 'Saved sell fee!');
                this.$emit('close');
                this.$emit('success');
            }).catch(error => {
                this.loadingSaveSellFee = false;
                console.log('Error saving sell fee', error);
                openDialogAlert('Error saving sell fee', 'There was a problem saving the sell fee. If the problem persists, contact support.');
            });
        }, 
        addCustomSellFee() {
            this.sellFeeTemplate.push({
                listingType: 'Other',
                fee: undefined
            });
        },
        async useStoreSellFeeTemplate() {
            if (this.sellerStoreId) {
                // get store sell fee template
                await GET(`/company/getSellFeeTemplateByStoreId/${this.sellerStoreId}`)
                    .then(res => {
                        this.sellFeeTemplate = res.data.feeStructure;
                        this.addCustomSellFee();
                        this.sellFeeKey++;
                        this.loadingGetSellFee = false;
                    }).catch(error => {
                        console.log('Error in getStoreFeeTemplateByStoreId for storeId', this.sellerStoreId, error);
                        openDialogAlert('Error fetching fees', 'There was a problem getting the sell fee template for this store. If the problem continues, contact support.');
                    });
            } else {
                // get consumer sell fee template 
                await PUT(`/fee/getSellFeeStructure`, { feeTemplateId: 2 })
                    .then(res => {
                        this.sellFeeTemplate = res.data.feeStructure;
                        this.addCustomSellFee();
                        this.sellFeeKey++;
                        this.loadingGetSellFee = false;
                    }).catch(error => {
                        console.log('Error in getStoreFeeTemplateByStoreId for storeId', this.sellerStoreId, error);
                        openDialogAlert('Error fetching fees', 'There was a problem getting the sell fee template for this store. If the problem continues, contact support.');
                    });
            }
        },
        selectCustomSellFee(value) {
            if (value && value != '$') {
                this.selectedSellFeeIdx = this.finalIdx;
            }
        },
        focusOnCustomInput() {
            focusOnElement('custom-sell-fee-input');
        },
    },
}
</script>

<style scoped lang='postcss'>
.fee {
    height: 45px;
}
</style>