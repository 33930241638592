var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-row align-start"},[_c('InputField',_vm._b({staticClass:"width-100",attrs:{"value":_vm.messageInput},on:{"update:value":[function($event){_vm.messageInput=$event},function($event){return _vm.$emit('update:value', $event)}]}},'InputField',{
            ..._vm.$attrs.inputAttrs,
            type: _vm.$attrs.inputAttrs?.type ?? 'textarea',
            rows: _vm.$attrs.inputAttrs?.rows ?? 1,
            useCustomStyles: false,
        },false)),(_vm.showSubmitButton)?_c('AppButton',_vm._b({staticClass:"ml-2 carmigo-button",attrs:{"disabled":_vm.$attrs.buttonAttrs?.disabled ?? (_vm.$attrs.inputAttrs?.required && !_vm.messageInput.length)},on:{"click":function($event){return _vm.$emit('submit', _vm.messageInput)}}},'AppButton',{
            ..._vm.$attrs.buttonAttrs,
            label: _vm.$attrs.buttonAttrs?.label ?? 'Send',
            type: 'is-primary'
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }