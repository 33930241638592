
import Vue, { onBeforeUnmount, watch } from 'vue';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useBreakpoint, useDragNavigation } from '../composables';
import { CarouselItem } from '../types';
import { removeAllMagnifyingGlasses } from '../utils';

import AppCarousel from './AppCarousel.vue';
import AppImageMagnifier from './AppImageMagnifier.vue';

const __default__ = defineComponent({
    name: 'AppCarouselPhotos',
    props: {
        items: {
            type: Array as PropType<CarouselItem[]>,
            required: true,
        },
        enableZoom: {
            type: Boolean,
            default: true,
        },
        selectedPhotoId: {
            type: Number,
            required: false,
        },
        isGalleryView: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppCarousel,
        AppImageMagnifier,
    },
    emits: ['toggleGalleryView', 'update:selectedPhotoId'],
    setup(props, { emit, attrs }) {
        const { windowWidth } = useBreakpoint();

        const selectedPhotoIdx = ref(props.selectedPhotoId ? props.items.findIndex(item => item.id == props.selectedPhotoId) : 0);
        function slideGalleryListToSelectedIdx(selectedIdx: number) {
            if (!props.isGalleryView) {
                let item = props.items[selectedIdx];
                emit('update:selectedPhotoId', item ? item.id : undefined);
            }
            if (selectedIdx >= props.items.length) {
                selectedIdx = 0;
            }
            if (selectedIdx < 0) {
                selectedIdx = props.items.length - 1;
            }
            Vue.nextTick(() => {
                selectedPhotoIdx.value = selectedIdx;
                const element = document.getElementsByClassName('carousel-slides')[0] as HTMLElement;
                if (!element) {
                    return;
                }
                let offset = sumImageWidthsToIndex(selectedIdx);
                let numWraps = offset / windowWidth.value;

                let offsetRounded = 0;
                // only wrap when you get halfway
                if (numWraps >= 0.6) {
                    offsetRounded = offset - (windowWidth.value / 3); // slide to about half-width
                }
                element.style.transform = `translateX(-${offsetRounded}px)`;
            });
        }

        // get widths of each image in the carousel slide navigation, so we can slide there on click
        const imageWidths = computed(() => {
            windowWidth.value;
            return props.items.map((image, idx) => {
                let imageElement = document.querySelectorAll('.carousel-slide img')?.length 
                    ? document.querySelectorAll('.carousel-slide img')[idx] as HTMLElement 
                    : undefined;
                return imageElement?.offsetWidth;
            });
        });

        function sumImageWidthsToIndex(idx: number) {
            let imagesToIdx = imageWidths.value.slice(0, idx);
            return imagesToIdx.length ? imagesToIdx.reduce((total, currValue) => total + currValue) : 0;
        }

        const selectedPhoto = computed(() => {
            return selectedPhotoIdx.value && props.isGalleryView 
                ? props.items[selectedPhotoIdx.value] 
                : undefined;
        });

        onBeforeUnmount(() => {
            removeAllMagnifyingGlasses();
        });

        // for gallery image max height
        const carouselListHeight = ref(getCarouselListHeight())
        watch(() => windowWidth.value, () => {
            carouselListHeight.value = getCarouselListHeight();
        });
        
        function getCarouselListHeight() {
            let carouselList = document.querySelector('.carousel-list') as HTMLElement;
            return carouselList?.offsetHeight ? `${carouselList.offsetHeight}px` : '150px';
        }

        const isZoomedIn = ref(false);
        const { dragStart, dragEnd } = useDragNavigation({
            onDragForward: () => !isZoomedIn.value ? slideGalleryListToSelectedIdx(selectedPhotoIdx.value + 1) : undefined,
            onDragBackward: () => !isZoomedIn.value ? slideGalleryListToSelectedIdx(selectedPhotoIdx.value - 1) : undefined,
        });

        function emitToggleGalleryView(isGallery: boolean) {
            if (!isGallery) {
                selectedPhotoIdx.value = 0;
            }
            emit('toggleGalleryView', isGallery);
        }

        return {
            emitToggleGalleryView,
            selectedPhotoIdx,
            selectedPhoto,
            slideGalleryListToSelectedIdx,
            carouselListHeight,
            dragStart,
            dragEnd,
            isZoomedIn,
        }
    }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "25986542": (_vm.carouselListHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__