
import { defineComponent, PropType } from 'vue';
import { BidHistoryBid, HighestBidRefRecord } from '../types';

import TheBidHistory from './TheBidHistory.vue';
import TheListingDetailsCard from './TheListingDetailsCard.vue';

export default defineComponent({
    name: 'TheListingDetailsBidHistory',
    props: {
        bidHistory: {
            type: Array as PropType<BidHistoryBid[]>,
            default: () => [],
        },
        highestBid: {
            type: Object as PropType<HighestBidRefRecord>,
            required: false,
        },
        reservePrice: {
            type: Number,
            required: false,
        },
    },
    components: {
        TheBidHistory,
        TheListingDetailsCard,
    },
});
