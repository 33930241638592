
import { defineComponent } from 'vue';
import AppButton from './AppButton.vue';

export default defineComponent({
    inheritAttrs: false,
    name: 'AppButtonWithSecondaryInfo',
    props: {
        label: {
            type: String,
            required: true,
        },
        info: {
            type: [String, Number],
            required: false,
        }
    },
    components: {
        AppButton,  
    },
});
