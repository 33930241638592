var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ButtonIcon',_vm._b({on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggle.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggle.apply(null, arguments)}}},'ButtonIcon',{
      iconName: _vm.isToggled ? 'star' : 'star-outline',
      iconColor: 'has-text-primary',
      iconAttrs: {
        size: _vm.$attrs.size,
      },
      ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }