
import { computed, defineComponent, PropType, ref } from 'vue';
import { useUserRole } from '../composables';
import { BidHistoryBid, HighestBidRefRecord } from '../types';
import { sortBidsByTime } from '../utils';
import store from '@/vuex';

import TheBidInfoCard from './TheBidInfoCard.vue';

export default defineComponent({
    name: 'TheBidHistory',
    props: {
        bidHistory: {
            type: Array as PropType<BidHistoryBid[]>,
            default: () => [],
        },
        reservePrice: {
            type: Number,
            required: false,
        },
        highestBid: {
            type: Object as PropType<HighestBidRefRecord>,
            required: false,
        },
        focusedClassName: {
            type: String,
            required: false,
        }
    },
    components: {
        TheBidInfoCard,
    },
    setup(props, { emit, attrs }) {
        const refreshKey = ref(0);

        const bidHistorySorted = computed(() => {
            const bidHistory = props.bidHistory.map(bid => { 
                return {
                    ...bid, 
                    created: new Date(bid.created),
                }
            });
            refreshKey.value++;
            return sortBidsByTime(bidHistory);
        });

        const { isUserAdmin, isUserDsr } = useUserRole(store);

        function getBidFlags(bid: BidHistoryBid): Array<'auto' | 'proxy' | 'reserve met'> {
            if (!isUserAdmin.value || !isUserDsr.value) {
                return [];
            }
            let flags = [];
            if (bid.bidType.toLowerCase() == 'auto') {
                flags.push('auto');
            }
            if (bid.bidType.toLowerCase() == 'proxy') {
                flags.push('proxy');
            }
            if (bid.bidType.toLowerCase() !== 'proxy' && bid.amount == props.reservePrice) {
                flags.push('reserve met');
            }
            return flags;
        }

        return {
            getBidFlags,
            bidHistorySorted,
            refreshKey,
        }
    },
});
