import { fireEvent } from "@/segment";
import { openAlertDialog, openErrorDialog, openModal } from "./buefyUtils";
import { APIConfig, CreateProfileDTO, ProfileLiteDTO } from "@/types";
import store from '@/vuex';
import { User } from "@/composables";
import { registerLead } from "@/api";
import { pick } from "lodash";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess } from "./otherUtils";

import ForgotPasswordModal from '../components/ForgotPasswordModal.vue';

export function handleLoginError(error: any) {
    console.error(error);
    fireEvent('Login Failed');
    if (error.code === 'auth/invalid-verification-code') {
        openErrorDialog({
            title: 'Login failed',
            message: 'The verification code you entered was incorrect',
            error,
        });
    } else if (error.message === 'No User Found' || error?.error?.data?.message === 'User not found with the signInWithEmailAndPassword function') {
        openErrorDialog({
            title: 'Login failed',
            message: `We could not find an account with that email. Please confirm that your email is entered correctly, or Sign Up.`,
            error,
        });
    } else if (error.message === 'Wrong Password') {
        openErrorDialog({
            title: 'Login Failed',
            message: 'The password you entered is incorrect.',
            error,
        });
    } else if (error.code === 'Missing user profile on signup') {
        openErrorDialog({
            title: 'Sign up failed',
            message: `We're missing some profile details and were unable to sign you up. Please try again or contact support.`,
            error,
        });
    } else {
        openErrorDialog({
            title: 'Login failed',
            message: error.message ?? 'Something went wrong. Please try again.',
            error,
        });
    }
}

export function openResetPasswordModal(email: string) {
    openModal({
        component: ForgotPasswordModal,
        props: {
            email,
        },
        events: {
            resetPasswordSent: (email: string) => {
                openAlertDialog({
                    title: 'Password reset link sent',
                    message: `
                        <div style="text-align: center;">
                            <p style="font-size: 17px;">A password reset link has been sent to your email:</p>
                            <p style="color: #52aa57; font-weight: 600; font-size: 18px; padding: 1rem;">
                                ${email}
                            </p>
                            <div style="margin: auto; max-width: 300px;">Follow the link in your inbox to choose a new password and login again.</div>
                        </div>
                    `, 
                    type: 'is-primary',
                    confirmText: 'Close',
                });
            }
        }
    });

}

export async function registerLeadFromLocalStorage(config: APIConfig={}) {
    try {
        const profileFromLocalStorage = store.state.user as Partial<User> as CreateProfileDTO;
        const createProfileDTO = {
            ...pick(profileFromLocalStorage, ['firstName', 'lastName', 'email', 'phoneNumber', 'referralCode']),
            howDidYouHear: '',
        };
    
        await registerLead(createProfileDTO).then(profileDTO => {
            applyAPIConfigOnSuccess(profileDTO, config)
        });
    } catch(error) {
        applyAPIConfigOnError(error, config);
        handleLoginError({
            code: 'Missing user profile on signup',
        });
    }
}