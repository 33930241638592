
import { defineComponent, PropType } from 'vue';
import { VehiclePhotosDTO } from '../types';
import { openModal } from '../utils';

import AppButton from './AppButton.vue';
import EditPhotosModal from './EditPhotosModal.vue';
import TheEditListingPhotosModal from './TheEditListingPhotosModal.vue';

export default defineComponent({
    name: 'TheEditListingPhotosButton',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehiclePhotos: {
            type: Object as PropType<VehiclePhotosDTO>,
            default: () => { return {} },
        }
    },
    components: {
        AppButton,
    },
    emits: ['saved'],
    setup(props, { emit, attrs }) {
        function openEditPhotosModal() {
            openModal({
                component: EditPhotosModal,
                props: {
                    ...props,
                    photos: props.vehiclePhotos,
                    ...attrs,
                },
                events: {
                    saved: async (updatedPhotos: VehiclePhotosDTO) => {
                        emit('saved', updatedPhotos);
                    }
                }
            });
        }
        return {
            openEditPhotosModal,
        }
    },
});
