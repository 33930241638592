import { render, staticRenderFns } from "./DragAndDropAttachments.vue?vue&type=template&id=2e6a09da&scoped=true&"
import script from "./DragAndDropAttachments.vue?vue&type=script&lang=js&"
export * from "./DragAndDropAttachments.vue?vue&type=script&lang=js&"
import style0 from "./DragAndDropAttachments.vue?vue&type=style&index=0&id=2e6a09da&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6a09da",
  null
  
)

export default component.exports