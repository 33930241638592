
import { phoneFormatter } from '@/validation';

export default {
    name: 'UserDetailsCard',
    props: {
        firstName: {
            type: String,
            required: false
        },
        lastName: {
            type: String,
            required: false
        },
        email: {
            type: String,
            required: false
        },
        phoneNumber: {
            type: String,
            required: false
        }
    },
    methods: {
        phoneFormatter
    }
}
