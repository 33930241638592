import type firebase from 'firebase/compat/app';
import { ProfileDTO } from '@/types/ProfileDTO';
import { computed, type ComputedRef } from 'vue';
import { userRoleGetters, type RoleGetter, isVehicleAuctioned, isMarketplaceListingStatusInMarketplaceOrLater } from '@/utils';
import { useStore } from './useStore';
import { Store } from 'vuex';
import store from '@/vuex';
import { VehicleStatus } from '@/types';

export interface User extends firebase.auth.UserMetadata {
  uid: string,
  profile: ProfileDTO;
  stsTokenManager: Record<string, unknown>
}

/**
 * Get the current user from the store.
 */
export function useUser() {
    return computed<User>(() => store.state.user);
}

export function useUserRole(customStore?: Store<any>) {
    const store = customStore ?? useStore();
    const roleMap = {} as Record<RoleGetter, ComputedRef<boolean>>;

    userRoleGetters.forEach((role) => {
        roleMap[role] = computed(() => store.getters[role]);
    });

    return roleMap;
}

export function useRestrictDsrRole() {
    const { isUserDsr } = useUserRole();

    function isUserDsrAndListingHasGoneLive(status: VehicleStatus) {
        return isUserDsr.value && (isVehicleAuctioned(status) || isMarketplaceListingStatusInMarketplaceOrLater(status));
    }
    
    return {
        isUserDsrAndListingHasGoneLive,
    }
}

export function useLoggedInUser() {
    const loggedInUserPersonId: ComputedRef<number | undefined> = computed(() => store.state.user?.profile?.id ? parseInt(store.state.user.profile.id) : undefined);
    return {
        loggedInUserPersonId,
    }
}