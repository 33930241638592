
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconAndLabel',
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: [Array, String],
      required: true,
    },
    wrap: {
      type: Boolean,
    },
    isBold: {
      type: Boolean,
    },
    alignment: {
      type: String,
    },
    iconType: {
      type: String,
      required: false,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: false,
    }
  },
  computed: {
    multiline() {
      return Array.isArray(this.label);
    },
    getAlignment() {
      if (this.alignment === 'row') {
        return 'flex-row';
      }
      return 'flex-column align-center';
    },
  },
});
