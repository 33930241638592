
import { defineComponent, PropType } from 'vue';

export type InfoRow = { name: string, value: any };

export default defineComponent({
    name: 'AppInfoRows',
    props: {
        rows: {
            type: Array as PropType<InfoRow[]>
        }
    },
});
