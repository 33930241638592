<template>
    <div class="align-center justify-center" :class="'flex-' + alignment">
        <a v-if="imageUrl && fileType!='pdf'" :href="imageUrl" target="_blank">
            <img class="image-thumbnail" :class="imgSize" :src="imageUrl" alt=""/>
        </a>
         <a v-else :href="imageUrl" target="_blank">
            <img class="image-thumbnail" :class="imgSize" src="../../assets/pdf_icon.png"/>
        </a>
        <div class="flex-row align-center">
            <p>{{ shortenedFileName }}</p>
            <b-button 
              v-if="canDelete" 
              class="icon-button"
              @click="removeAttachment()"
            >
                <b-icon
                    icon="delete" 
                    size="is-small"
                />
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddedAttachment",
    props: [
        "imageUrl",
        "fileName",
        "canDelete",
        "alignment",
        "imgSize",
        "deleteAttachment",
        "fileType"
    ],
    computed: {
        shortenedFileName() {
            const ext = this.fileName.split('.')[1]
            const shortened = this.fileName.slice(0,5)
            if (this.fileName.length > 0) return `${shortened}...${ext}`
        },
    },
    methods: {
        removeAttachment() {
            this.$emit('delete', this.imageUrl, this.$vnode.key);
            return this.deleteAttachment(this.imageUrl, this.$vnode.key)
        },
    },
}
</script>
<style scoped lang="postcss">
p {
    font-size: .9em;
    margin: 0px 12px 5px;
    white-space: nowrap;
}


.button {
    margin-left: -3px;
}
</style>