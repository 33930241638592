
import { defineComponent, PropType, ref } from 'vue';
import { useEditableFields } from '../composables';
import { SellerType, TitleStatus } from '../types';

import BuefyDropdown from './BuefyDropdown.vue';
import EditableField from './Forms/EditableField.vue';
import TheListingDetailsSellerDetails from './TheListingDetailsSellerDetails.vue';

export default defineComponent({
    name: '',
    props: {
        isEditingMode: {
            type: Boolean,
            default: false,
        },
        titleStatus: {
            type: String as PropType<TitleStatus>,
            required: false,
        },
        sellerType: {
            type: String as PropType<SellerType>,
            required: false,
        },
        sellerReason: {
            type: String,
            required: false,
        },
        store: {
            type: String,
            required: false,
        },
        facilitatingAuctionStoreName: {
            type: String,
            required: false,
        },
        sellerStoreId: {
            type: Number,
            required: false,
        },
    },
    components: {
        BuefyDropdown,
        EditableField,
        TheListingDetailsSellerDetails,
    },
    emits: ['input'],
    setup(props, context) {
        const updatedFields = ref({
            titleStatus: props.titleStatus,
            sellerType: props.sellerType,
            sellerReason: props.sellerReason,
        });

        const { emitUpdates } = useEditableFields({ context })
        function emitUpdatedValues(updates) {
            emitUpdates(updatedFields.value);
        }

        return {
            updatedFields,
            emitUpdatedValues,
        }
    },
});
