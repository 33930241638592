import { APIConfig, ModifierOptions, TimeDTO } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, openErrorDialog } from "@/utils";
import { GET, PUT } from ".";

export async function getArbitrationRateBySellerStoreId(sellerStoreId: number, config: APIConfig={}): Promise<number | undefined> {
    return await GET(`/arbitration/arbitrationRate/${sellerStoreId}`, undefined, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => { 
            applyAPIConfigOnError(error, config);
        });
}

export async function getTimeToTitleBySellerStoreId(sellerPersonId: number | undefined, config: APIConfig={}): Promise<TimeDTO | undefined> {
    return await GET(`/vehicles/averageTimeToTitle/${sellerPersonId}`, undefined, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function getSellRateBySellerStoreId(sellerPersonId: number | undefined, config: APIConfig={}): Promise<number | undefined> {
    return await GET(`/orders/sellRate/${sellerPersonId}`, undefined, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function didSellerAcceptTermsAndConditions(vehicleListingId: number, config: APIConfig={}): Promise<boolean> {
    return await GET(`/vehicles/getTermsAcceptedBySellerForVehicleListing/${vehicleListingId}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to check terms & conditions acceptance',
                message: `We encountered an error while confirming whether the seller has accepted terms & conditions`,
                error,
            });
        });
}

export async function getSellerDashboardListings({ category, modifiers, config={} }: {
    category: string, 
    modifiers: ModifierOptions, 
    config?: APIConfig
}) {
    return await PUT(`/dealerInventory/dashboard/${category}`, { modifiers }, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}