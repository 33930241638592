export const ListingCardProps = {
    vehicleListingId: {
        type: Number,
        required: true,
    },
    marketplaceListingId: {
        type: Number,
        required: false,
    },
    heroPicUrl: {
        type: String,
        required: false,
    },
    year: {
        type: Number, 
        required: true,
    },
    make: {
        type: String,
        required: true,
    },
    model: {
        type: String,
        required: true,
    },
    trim: {
        type: String,
        required: false,
    },
    vin: {
        type: String,
        required: false,
    },
    miles: {
        type: Number,
        required: true,
    },
    city: {
        type: String,
        required: true,
    },
    state: {
        type: String,
        required: true,
    },
    sellerPersonId: {
        type: Number,
        required: true,
    },
    isReserveMet: {
        type: Boolean,
        default: false,
    },
    isCarmigoCertified: {
        type: Boolean,
        default: false,
    },
    isPowerSeller: {
        type: Boolean,
        default: false,
    },
    isConsumerSeller: {
        type: Boolean,
        default: false,
    },
    watching: {
        type: Boolean,
        default: false,
  },
    marketplaceCanBuyItNow: {
      type: Boolean,
      default: true,
    },
}
