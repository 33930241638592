
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue';
const __default__ = defineComponent({
    name: 'AppProgressBarCount',
    props: {
        currentCount: {
            type: Number,
            default: 0,
        },
        goalCount: {
            type: Number,
            required: true,
        },
    },
    setup(props, { emit, attrs }) {
        // max 100% complete
        const percentComplete = computed(() => {
            return Math.min(100, props.currentCount/props.goalCount * 100);
        });

        // width of the count element (currentCount / goalCount)
        const countElementWidth = ref(0);
        function getCountElementWidth() {
            let countElement = document.getElementById('current-count-container');
            countElementWidth.value = countElement ? (countElement.offsetWidth) : 0;
        }
        onMounted(getCountElementWidth);
        watch(() => props.currentCount, () => nextTick(getCountElementWidth));

        // completed bar width = percent complete
        // or at least as wide as the count display, or else count display would not be visible
        const completedBarWidth = computed(() => {
            return `max(${percentComplete.value}%, ${countElementWidth.value + 12}px)`;
        });


        // positioning the count display along the progress bar
        // min is 8px from left of progress bar (if currentCount is 0), otherwise positioned at the right edge of the completed bar
        const countLeftPosition = computed(() => {
            return `max(8px, calc(${percentComplete.value}% - ${countElementWidth.value + 10}px))`;
        });

        return {
            completedBarWidth,
            countLeftPosition,
        }
    },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "160f2f8d": (_vm.completedBarWidth),
  "2c91b0a4": (_vm.countLeftPosition)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__