<template>
    <section>
        <Loading v-if="submitting" />
        <div class="datepickers-container">
            <DateTimePicker
                class="datepicker"
                v-bind="{
                    ...$attrs,
                    ...$attrs.startTimeAttrs,
                    label: 'Start Time',
                    placeholder: 'Click to select the auction start time',
                    minPickableTime: minPickableStartTime,
                    returnSelectedTime: getSelectedTime,
                    initialValue: initialStartTime ? initialStartTime : null,
                    timepicker,
                }"
            />
            
            <DateTimePicker
                class="datepicker"
                :key="`endtime-${updateKey}`"
                v-bind="{
                    ...$attrs,
                    ...$attrs.endTimeAttrs,
                    label: 'End Time',
                    placeholder: 'Click to select the auction end time',
                    minPickableTime: minPickableEndTime,
                    returnSelectedTime: getSelectedTime,
                    initialValue: initialEndTimeUpdated ? initialEndTimeUpdated : null,
                    timepicker,
                }"
            />
        </div>
        
    </section>
</template>

<script>
import { 
    addHours,
    addMinutes,
    startOfHour,
    startOfMinute,
} from 'date-fns';

import { 
    formatAMPM,
    getNextDay2pmCst,
    openDialogAlert,
    openDialogConfirm,
    openToast,
} from '../utils';

import { POST } from '../api';

import DateTimePicker from '../views/Admin/DateTimePicker.vue';
import Loading from './Loading.vue';

export default {
    name: 'ScheduleAuctionDateTimePickers',
    props: {
        vehicleListingIds: {
            type: Array, // Number array
            required: false,
        },
        customConfirm: {
            type: Boolean,
            required: false,
            // triggers 'confirm' (openConfirmAuctionDialog) 
        },
        initialStartTime: {
            type: Date,
            required: false,
        },
        initialEndTime: {
            type: Date,
            required: false,
        },
        autofillEndTime: {
            type: Boolean,
            required: false,
        }
    },
    components: {
        DateTimePicker,
        Loading,
    },
    data() {
        return {
            isLoading: false,
            pickedStartTime: null,
            pickedEndTime: null,
            timepicker: {
                incrementMinutes: 1,
                incrementHours: 1,
                hourFormat: "12",
            },
            submitting: false,
            initialEndTimeUpdated: this.initialEndTime,
            updateKey: 0,
        }
    },
    watch: {
        pickedStartTime: function() {
            this.$emit('startTime', this.pickedStartTime);
            if (this.autofillEndTime) {
                this.nextDay2pm = getNextDay2pmCst(this.pickedStartTime);
                this.pickedEndTime = this.nextDay2pm;
                this.initialEndTimeUpdated = this.nextDay2pm;
                this.updateKey++;
            }
        },
        pickedEndTime: function() {
            this.$emit('endTime', this.pickedEndTime);
        },
        customConfirm: function() {
            if (this.customConfirm) this.openConfirmAuctionDialog();
        },
    },
    mounted() {
        if (this.initialStartTime) this.pickedStartTime = this.initialStartTime;
        if (this.initialEndTime) this.pickedEndTime = this.initialEndTime;

        if (this.customConfirm) {
            this.openConfirmAuctionDialog();
        } 
    },
    computed: {
        minPickableStartTime() {
            return(
                addHours(startOfHour(new Date()), 0) &&
                addMinutes(startOfMinute(new Date()), 1)
            );
        },
        minPickableEndTime() {
            return this.pickedStartTime ? addMinutes(this.pickedStartTime, 10) : null;
        },
        getVehicleIds() {
            return this.vehicleListingIds?.join(', ');
        }
    },
    filters: {
        formatAMPM,
    },
    methods: {
        openDialogAlert,
        openDialogConfirm,
        openToast,

        getSelectedTime(label, selectedTime) {
            switch (label) {
                case 'Start Time':
                    this.pickedStartTime = selectedTime;
                    break;
                case 'End Time': 
                    this.pickedEndTime = selectedTime;
                    break;
            }
        },

        async scheduleAuction() {
            this.submitting = true;
            await POST('vehicles/date', {
                vehicleIds: this.vehicleListingIds,
                auctionStart: this.pickedStartTime,
                auctionEnd: this.pickedEndTime,
            }).then(() => {
                this.openToast('is-success', 'Auction scheduled', 5000);
                this.$emit('success');
            }).catch((error) => {
                if (error.response) {
                    if (error.response.data.error.data.message) {
                        this.openDialogAlert('Unable to schedule auction', error.response.data.error.data.message);
                    }
                } else {
                    this.openDialogAlert(
                        'Unable to schedule auction', 
                        `Something went wrong while scheduling the auction for ${this.vehicleListingIds.length} vehicles: ${this.getVehicleIds}. Please contact support.`
                    );
                }
            });
            this.submitting = false;
            this.$emit('cancel');
        },

        openConfirmAuctionDialog() {
            const dialogTitle = `Confirm auction time`;
            const dialogMessage = `
            <div style="width: fit-content; margin: auto;">
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <p style="text-align: center;">Scheduling auction for ${this.vehicleListingIds?.length} vehicles:</p>
                    <p>${this.getVehicleIds}</p>
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: 1rem;">
                    <span>Start time:</span>
                    <span style="padding-left: 1rem; font-weight: bold;">${this.$options.filters.formatAMPM(this.pickedStartTime)}</span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: .25rem;">
                    <span>End time:</span>
                    <span style="padding-left: 1rem; font-weight: bold;">${this.$options.filters.formatAMPM(this.pickedEndTime)}</span>
                </div>
            </div>`;
            
            const dialogOnConfirm = this.scheduleAuction;
            const dialogOnCancel = this.cancelScheduleAuction;

            this.openDialogConfirm(dialogTitle, dialogMessage, dialogOnConfirm, dialogOnCancel);

        },

        cancelScheduleAuction() {
            this.$emit('cancel');
        }
    },
}
</script>

<style scoped lang='postcss'>
section {
    width: 100%;
}

.datepicker {
    margin: 1rem auto;
    margin-bottom: 0;
    width: 100%;
}

.datepickers-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & .datepicker:not(last-child) {
            margin-right: 0;
        }

    & .datepicker:last-child {
        margin-bottom: 1rem;
    }
}
</style>
