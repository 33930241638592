var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"width-100"},_vm._l((Object.keys(_vm.listObj)),function(label,idx){return _c('div',{key:idx,staticClass:"flex-row justify-space-between point9-rem list-row",class:{
            'background': _vm.delineated && idx%2==0,
            'is-large': _vm.isLarge,
            'is-total': label === 'Total'
        }},[_c('p',{staticClass:"label",class:{
            capitalize: _vm.capitalizeItems,
            'ml-5': _vm.indentedLabels.includes(label)
        }},[_vm._v(" "+_vm._s(_vm.useStartCase ? _vm.startCase(label) : label)+" ")]),_c('p',{staticClass:"value",class:{ 'weight-600': _vm.boldValues }},[_vm._v(_vm._s(_vm.listObj[label]))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }