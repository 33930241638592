
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useGetOfferDisplayValues, useValidateCounterOffer } from '../composables';
import { negotiationModalProps } from '../types';
import { dollarAmountToInt, focusOnElement, scrollToElement } from '../utils';

import AppButton from './AppButton.vue';
import InfoHeader from './Arbitrations/InfoHeader.vue';
import InputField from './InputField.vue';
import Modal from './Modal.vue';

export default defineComponent({
    name: 'TheNegotiationModal',
    props: {
        ...negotiationModalProps,
        showSendOfferButton: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        AppButton,
        InfoHeader,
        InputField,
        Modal,
    },
    emits: ['close', 'accept', 'reject', 'counter'],
    setup(props, context) {
        // info for display text
        const { yourOffer, theirOffer, otherUser } = useGetOfferDisplayValues(props.negotiatingUser, props.buyerOfferAmount, props.sellerOfferAmount);

        // counter offers
        const makingCounterOffer = ref(props.makingCounterOfferInitial);
        const counterOfferInputStr = ref();
        const counterOfferInputInt = computed(() => {
            return dollarAmountToInt(counterOfferInputStr.value);
        });

        watch(counterOfferInputStr, () => invalidCounterOfferInput.value = false);
        const invalidCounterOfferInput = ref(false);
        const disableSubmitCounterOffer = computed(() => {
            return !counterOfferInputInt.value || invalidCounterOfferInput.value;
        });

        const { validateCounterOffer, invalidCounterOfferMessage } = useValidateCounterOffer(props.buyerOfferAmount, props.sellerOfferAmount, props.isMarketplace);

        function scrollAndFocusCounterOfferInput() {
            const interval = setInterval(() => {
                var counteroffer = document.getElementById('counter-offer');
                if (counteroffer) {
                    clearInterval(interval);
                    scrollToElement('counter-offer');
                    focusOnElement('counteroffer-input', 400);
                }
            }, 100);
        }

        onMounted(() => {
            if (props.makingCounterOfferInitial) {
                scrollAndFocusCounterOfferInput();
            }
        });

        return {
            yourOffer,
            theirOffer,
            otherUser,
            makingCounterOffer,
            counterOfferInputStr,
            counterOfferInputInt,
            validateCounterOffer,
            invalidCounterOfferMessage,
            disableSubmitCounterOffer,
            scrollAndFocusCounterOfferInput,
            invalidCounterOfferInput,
        }
    }
});
