type firebaseDate = { _seconds: number };

export function firebaseDate2Date(firebaseDate: firebaseDate) {
    return new Date(firebaseDate._seconds * 1_000);
}

export function firebaseDate2Str(firebaseDate: firebaseDate) {
    const d = new Date(firebaseDate._seconds * 1_000);
    const format = (num: number, len = 2, add = 0) =>
        (num + add).toString().padStart(len, "0");
    return (
        `` +
        `${format(d.getMonth(), 2, 1)}` +
        `-${format(d.getDate(), 2, 0)}` +
        `-${format(d.getFullYear(), 4, 0)}` +
        " - " +
        `${format(d.getHours(), 2, 0)}` +
        `:${format(d.getMinutes(), 2, 0)}` +
        `:${format(d.getSeconds(), 2, 0)}`
    );
}
export function firebaseDate2DateStr(firebaseDate) {
    const dateStr = firebaseDate2Str(firebaseDate);
    return dateStr.split(" - ")[0];
}


export function firebaseDate2DateStrLong(firebaseDate: firebaseDate) {
    const date = firebaseDate2Date(firebaseDate)
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })

}

export function firebaseDate2TimeStr(firebaseDate) {
    const date = new Date(firebaseDate._seconds * 1_000);
    var hrs = date.getHours();
    var mins = date.getMinutes();

    // convert 24 hr to 12 hr, get AM/PM
    var hrs12 = hrs % 12;
    var ampm = "AM";
    if (hrs12 !== hrs) ampm = "PM";
    if (hrs12 === 0) hrs12 = 12;

    // add leading 0 to minutes if < 10
    var minString = "";
    mins < 10 ? (minString = `0${mins}`) : (minString = `${mins}`);

    const time = `${hrs12}:${minString} ${ampm}`;
    return time;
}
