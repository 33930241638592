
import { defineComponent, ref, watch } from 'vue';
import { useNotificationSettings } from '../composables';

import AppNotificationSettings from './AppNotificationSettings.vue';
import ButtonIcon from './ButtonIcon.vue';
import TheNotificationFeed from './TheNotificationFeed.vue';

export default defineComponent({
    name: 'TheNotificationFeedPanel',
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppNotificationSettings,
        ButtonIcon,
        TheNotificationFeed,
    },
    setup(props, { emit, attrs }) {
        const isOpen = ref(props.open);
        watch(() => props.open, () => toggleSidebar(props.open));

        const displayNotificationSettings = ref(false);

        const { notificationSettings, loadingNotificationSettings, getNotificationSettings } = useNotificationSettings();

        function toggleNotificationSettings(display: boolean) {
            displayNotificationSettings.value = display;
            if (display && !notificationSettings.value?.length) {
                getNotificationSettings();
            }
        }

        function toggleSidebar(open: boolean) {
            displayNotificationSettings.value = false;
            isOpen.value = open; 
            emit('update:open', open);
        }

        return {
            isOpen,
            displayNotificationSettings,
            notificationSettings,
            loadingNotificationSettings,
            toggleNotificationSettings,
            toggleSidebar,
        }
    },
});
