
import axios from 'axios';
import { defineComponent } from 'vue';
import { GET, POST } from '@/api';
import { openDialogAlert, openToast } from '@/utils';
import { formattedPhoneNumberToDigits, validateEmail, validatePhone } from '@/validation';
import { VueMaskDirective } from 'v-mask';

import Modal from '@/components/Modal.vue';
import debounce from 'lodash/debounce';
import InputFieldUnstyled from '@/components/InputFieldUnstyled.vue';
import TheEmployeeRoleToggles from './TheEmployeeRoleToggles.vue';


type DealerImportLiteDTO = {
  id: number,
  dealerName: string,
  address: string,
  state: string,
  zip: string,
}

export default defineComponent({
  name: 'TheDealerImportModal',
  components: {
    InputFieldUnstyled,
    Modal,
    TheEmployeeRoleToggles,
  },
  data() {
    return {
      dealers: [] as DealerImportLiteDTO[],
      isModalLoading: false,
      isFetchingDealerList: false,
      selectedDealer: null as null | DealerImportLiteDTO,
      buyer: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        roles: ['buyer'],
      },
    };
  },
  methods: {
    // eslint-disable-next-line prefer-arrow-callback, func-names
    handleDealerSearch: debounce(async function (dealerName: string) {
      if (!dealerName) {
        this.dealers = [];
        return;
      }

      this.isFetchingDealerList = true;
      this.resetBuyerForm();

      try {
        const { data } = await GET<DealerImportLiteDTO[]>(`/company/getDealerImportLite?dealerName=${dealerName}`);
        this.dealers = data;
      } catch {
        openDialogAlert('Error fetching dealers', 'There was a problem fetching dealers. If the problem persists, contact support.');
      } finally {
        this.isFetchingDealerList = false;
      }
    }, 300),
    async createStoreAndUser() {
      if (!this.selectedDealer) {
        return;
      }

      this.isModalLoading = true;

      try {
        await POST('/company/createStoreAndUserUsingDealerImportId', {
          dealerImportId: this.selectedDealer.id,
          newUser: {
            ...this.buyer,
            phoneNumber: formattedPhoneNumberToDigits(this.buyer.phoneNumber),
          },
        });
        this.resetBuyerForm();
        openToast('is-success', 'Your store has been created.');
        this.$parent.close();
      } catch (error) {
        const defaultTitle = 'Error creating store';
        const defaultErrorMessage = `There was a problem creating store for dealer ${this.selectedDealer.dealerName}. If the problem persists, contact support.`;
        if (axios.isAxiosError(error)) {
          openDialogAlert(error.response?.data?.title ?? defaultTitle, error.response?.data?.message ?? defaultErrorMessage);
        } else {
          openDialogAlert(defaultTitle, defaultErrorMessage);
        }
      } finally {
        this.isModalLoading = false;
      }
    },
    handleDealerSelect(option: DealerImportLiteDTO) {
      this.resetBuyerForm();
      this.selectedDealer = option;
    },
    resetBuyerForm() {
      this.buyer = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        roles: ['buyer']
      };
    },
  },
  directives: {
    mask: VueMaskDirective,
  },
  computed: {
    isFormInvalid() {
      return !this.buyer.firstName
      || !this.buyer.lastName
      || !validateEmail(this.buyer.email)
      || !validatePhone(this.buyer.phoneNumber);
    },
  },
});
