import { APIConfig } from '@/types';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, openErrorDialog } from '@/utils';
import { GET } from '.';

export async function checkForRecentVehicleRelistingByVin(vin: string, config: APIConfig={}) {
    return await GET(`/vehicles/checkForRecentVehicleRelistingByVin/${vin}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to check for recent relisting',
                message: `We encountered an error while checking if VIN ${vin} has already been listed recently`,
                error,
            });
        });
}
