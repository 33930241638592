var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"confirm":_vm.saveVehicle,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Edit Vehicle #"+_vm._s(_vm.vehicleListingId)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"flex-row"},[_c('AppInputFieldNumber',_vm._b({staticClass:"year-input",on:{"input":function($event){_vm.editVehicleForm.year = $event ? $event.toString() : undefined}}},'AppInputFieldNumber',{
                    value: _vm.editVehicleForm.year ? parseInt(_vm.editVehicleForm.year) : undefined,
                    label: 'Year',
                    disabled: _vm.loadingSaveVehicle,
                },false)),_c('AppInputFieldString',_vm._b({model:{value:(_vm.editVehicleForm.make),callback:function ($$v) {_vm.$set(_vm.editVehicleForm, "make", $$v)},expression:"editVehicleForm.make"}},'AppInputFieldString',{ 
                    fieldAttrs: { label: 'Make' },
                    disabled: _vm.loadingSaveVehicle,
                },false)),_c('AppInputFieldString',_vm._b({model:{value:(_vm.editVehicleForm.model),callback:function ($$v) {_vm.$set(_vm.editVehicleForm, "model", $$v)},expression:"editVehicleForm.model"}},'AppInputFieldString',{ 
                    fieldAttrs: { label: 'Model' },
                    disabled: _vm.loadingSaveVehicle,
                },false)),_c('AppInputFieldString',_vm._b({model:{value:(_vm.editVehicleForm.trim),callback:function ($$v) {_vm.$set(_vm.editVehicleForm, "trim", $$v)},expression:"editVehicleForm.trim"}},'AppInputFieldString',{ 
                    fieldAttrs: { label: 'Trim' },
                    disabled: _vm.loadingSaveVehicle,
                },false))],1),_c('AppInputFieldString',_vm._b({model:{value:(_vm.editVehicleForm.vin),callback:function ($$v) {_vm.$set(_vm.editVehicleForm, "vin", $$v)},expression:"editVehicleForm.vin"}},'AppInputFieldString',{ 
                fieldAttrs: { label: 'VIN' },
                disabled: _vm.loadingSaveVehicle,
            },false)),_c('div',{staticClass:"flex-row width-100 mt-5"},[_c('AppInputFieldNumber',_vm._b({staticClass:"width-100",model:{value:(_vm.editVehicleForm.mileage),callback:function ($$v) {_vm.$set(_vm.editVehicleForm, "mileage", $$v)},expression:"editVehicleForm.mileage"}},'AppInputFieldNumber',{ 
                    label: 'Mileage',
                    disabled: _vm.loadingSaveVehicle,
                },false)),_c('AppInputFieldString',_vm._b({staticClass:"width-100",model:{value:(_vm.editVehicleForm.color),callback:function ($$v) {_vm.$set(_vm.editVehicleForm, "color", $$v)},expression:"editVehicleForm.color"}},'AppInputFieldString',{ 
                    fieldAttrs: { label: 'Color' },
                    disabled: _vm.loadingSaveVehicle,
                },false)),_c('AutocompleteInput',_vm._b({staticClass:"width-100",on:{"select":function($event){_vm.editVehicleForm.condition = $event?.value}}},'AutocompleteInput',{ 
                    value: _vm.vehicleConditionOptions.find(condition => condition.value == _vm.editVehicleForm.condition),
                    label: 'Condition',
                    options: _vm.vehicleConditionOptions,
                    field: 'value',
                    appendToBody: true,
                    autofocus: false,
                    disabled: _vm.loadingSaveVehicle,
                },false))],1)]},proxy:true}])},'Modal',{
        customLoading: true,
        isLoading: _vm.loadingSaveVehicle,
        disableConfirm: _vm.disableConfirm,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }