
import { useStore } from '@/composables';
import { computed, defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'TheFastBookBookingPanel',
    setup() {
        const store = useStore();
        const extensionVersion = '2.1.0';
        const iframeUrl = computed(() => {
            const vin = store.state.fastBookBookingPanel.selectedVin;
            return `https://www.dealerslink.com/mobile/fastbook/valuatorPro/index.cfm?vin=${vin}&chromeExtension=${extensionVersion}`;
        });
        const isOpen = computed({
            get() {
                return store.state.fastBookBookingPanel.isOpen;
            },
            set(newValue: boolean) {
                store.commit('setFastBookBookingPanel', { isOpen: newValue });
            },
        });

        onMounted(() => {
            // Listens to the 'Close' button in the iframe provided by FastBook.
            // When the button is clicked, it closes the sidebar.
            window.addEventListener('message', ({ data }: MessageEvent) => {
                let parsedData: { event: string };

                if (typeof data === 'string' && data.charAt(0) === '{' && data.charAt(data.length - 1) === '}') {
                    parsedData = JSON.parse(data);
                    if (parsedData.event === 'onClickCloseChromeExtensionBookingPanel') {
                        isOpen.value = false;
                    }
                }
            }, false);
        });

        return {
            iframeUrl,
            isOpen,
        };
    },
});
