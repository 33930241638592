var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',{staticClass:"carmigo-modal",staticStyle:{"min-width":"50vw"},attrs:{"hideFooter":""},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('TheInfoHeaderVehicleListing',_vm._b({staticClass:"mb-3"},'TheInfoHeaderVehicleListing',{
                imageUrl: _vm.vehicle.heroPicUrl,
                title: `${_vm.vehicle.year} ${_vm.vehicle.brand} ${_vm.vehicle.model}`,
                subtitle: _vm.vehicle.vin,
            },false)),_c('div',{staticClass:"columns"},[_c('section',{staticClass:"column is-4 auction-info"},[_c('div',{staticClass:"auction-highlights"},_vm._l((Object.keys(_vm.highlights)),function(key){return _c('div',{key:`highlight-${key}`,staticClass:"hover",on:{"click":function($event){return _vm.focusBid(key)}}},[(_vm.highlights[key].value)?_c('InfoHighlight',_vm._b({staticClass:"mb-3"},'InfoHighlight',{
                                label: _vm.highlights[key].title,
                                info: _vm.toCurrency(_vm.highlights[key].value),
                                flag: _vm.highlights[key].flag,
                                size: 'large',
                                color: 'green',
                                background: 'blank', 
                            },false)):_vm._e()],1)}),0),_c('div',{staticClass:"auction-buyers"},[_c('div',{staticClass:"header"},[_c('h3',[_vm._v("Buyers")]),(_vm.buyers.length)?_c('span',[_vm._v("("+_vm._s(_vm.buyers.length)+")")]):_vm._e()]),_vm._l((_vm.buyers),function(buyer,idx){return _c('li',{key:`buyer-${idx}`,staticClass:"text-thin hover",attrs:{"title":"View most recent bid"},on:{"click":function($event){return _vm.focusBid(`buyer-${buyer.personId}`)}}},[_c('span',[_vm._v(_vm._s(buyer.name))])])})],2)]),_c('section',{staticClass:"column is-8"},[_c('div',{staticClass:"bid-history-header flex-row justify-space-between align-end"},[_c('h2',[_vm._v("Bid History")]),(_vm.bidHistory.length)?_c('span',[_vm._v(_vm._s(_vm.bidHistory.length)+" total")]):_vm._e()]),_c('TheBidHistory',_vm._b({},'TheBidHistory',{
                        bidHistory: _vm.bidHistory,
                        highestBid: _vm.highestBid,
                        highestProxyBid: _vm.highestProxyBid,
                        reservePrice: _vm.vehicle.reservePrice,
                        buyItNowPrice: _vm.vehicle.buyItNowPrice,
                        focusedClassName: _vm.focusedClassName,
                    },false))],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }