
import { defineComponent } from 'vue';
import { toCurrency } from '../utils';

import AppButtonWithSecondaryInfo from './AppButtonWithSecondaryInfo.vue';

export default defineComponent({
    inheritAttrs: false,
    name: 'TheBuyItNowButton',
    props: {
        buyItNowPrice: {
            type: Number,
            required: true,
        }
    },
    components: {
        AppButtonWithSecondaryInfo,  
    },
    setup() {
        return {
            toCurrency,
        }
    }
});
