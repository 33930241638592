var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-field',{class:_vm.dropdownClass,attrs:{"label":!_vm.isButton ? _vm.label : ''}},[_c('b-dropdown',_vm._b({key:_vm.updateKey,ref:"dropdown",on:{"active-change":function($event){return _vm.$emit('activeChange', $event)}},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleOnTouchDevice.apply(null, arguments)}},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_vm._t("trigger",function(){return [_c('b-button',_vm._b({staticClass:"dropdown-button carmigo-button",class:_vm.outlined ? 'bold justify-center' : '',on:{"blur":function($event){return _vm.handleclick(_vm.isDropDownOpened)}}},'b-button',{
                        iconRight: active ? 'menu-up' : 'menu-down',
                        type: _vm.isButton ? _vm.getButtonType : '',
                        disabled: _vm.disabled,
                        outlined: _vm.outlined,
                        ..._vm.$attrs.buttonAttrs,
                    },false),[_vm._t("buttonLabel",function(){return [_vm._v(" "+_vm._s(_vm.getButtonLabel)+" ")]})],2)]},{"active":active})]}}],null,true),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},'b-dropdown',{
            position: 'is-bottom-left',
            disabled: _vm.disabled,
            ..._vm.$attrs,
        },false),[(!_vm.options.length)?_c('div',{staticClass:"ml-2"},[_vm._t("empty")],2):_vm._e(),_vm._l((_vm.options),function(option,idx){return _c('b-dropdown-item',{key:`dropdown-item-${idx}`,attrs:{"aria-role":"listitem","value":option.name ?? option}},[_vm._t("dropdownItem",function(){return [_vm._v(" "+_vm._s(option)+" ")]},{"option":option})],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }