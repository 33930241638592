import { getNotifications, updateNotification } from "@/api";
import { MessageModality } from "@/enums";
import { database } from "@/firebase";
import { 
    InAppNotification, 
    InAppNotificationConfig, 
    InAppNotificationNotificationConfig, 
    InAppNotificationSnackbarConfig, 
    InAppNotificationToastConfig, 
    ModifierOptionsFilterObject,
    NotificationAction,
} from "@/types";
import { BNoticeConfig, BNotificationConfig, BSnackbarConfig } from "buefy/types/components";
import { openNotification, openSnackbar, openToastNotification } from "./buefyUtils";
import { notificationService } from "../service/notification.service";
import store from '@/vuex';

export const defaultNoticeConfig: Partial<BNoticeConfig> = {
    duration: 5000,
    indefinite: false,
    pauseOnHover: true,
    queue: false,
}

export const defaultSnackbarConfig: Partial<BSnackbarConfig> = {
    ...defaultNoticeConfig,
    actionText: undefined,
    position: 'is-bottom-left', 
    cancelText: 'Dismiss',
}

export const defaultToastConfig: Partial<BNoticeConfig> = {
    ...defaultNoticeConfig,
    indefinite: false,
}

export const defaultNotificationConfig: Partial<BNotificationConfig> = {
    ...defaultNoticeConfig,
    position: 'is-top-right',
    indefinite: true,
    autoClose: false,
    closable: true,
    iconPack: 'mdi',
}

export async function getUnreadNotificationIdsByUserIdFromNotificationRef(userId?: number): Promise<number[] | undefined> {
    if (!userId) {
        return undefined;
    }
    try {
        return await database.ref(`notification/${userId}/inAppNotifications`).get().then(res => {
            return res.val() 
                ? Object.keys(res.val()).map(id => Number(id))
                : undefined;
        });
    } catch(error) {
        console.log('ERROR in getUnreadNotificationIdsByUserIdFromNotificationRef', error);
        return undefined;
    }
}

export async function getAndDisplayUserNotifications(notificationIds?: number[]) {
    if (!notificationIds?.length) {
        return;
    }
    notificationIds.forEach(notificationId => {
        // only display if not displayed before or marked as read
        let notReadOrDisplayed = !store.state.notificationIdsRead.includes(notificationId) && !store.state.notificationIdsDisplayed.includes(notificationId);
        if (notReadOrDisplayed) {
            store.commit('addDisplayedNotificationId', notificationId);
            let modifiers = {
                filters: convertNotificationSearchToModifierSchema('id', notificationId)
            }
            getNotifications(modifiers).then((notifications: InAppNotification[] | undefined) => {
                if (notifications?.length) {
                    displayInAppNotification(notifications[0]);
                }
            });
        }
    });
}

export function convertNotificationSearchToModifierSchema(searchField?: 'id' | 'userId' | 'modalityId', searchValue?: string | number | number[]): ModifierOptionsFilterObject[] | undefined {
    if (!searchField || !searchValue) {
        return undefined;
    }
    let filters: ModifierOptionsFilterObject[] | undefined = [];
    switch(searchField) {
        case 'id':
            filters.push({
                property: 'notification.id',
                comparator: '=',
                values: searchValue,
            });
            break;
        case 'userId':
            filters.push({
                property: 'notification.userId',
                comparator: '=',
                values: searchValue,
            }, {
                property: 'notification.readDate',
                comparator: 'isNull',
                values: null,
            });
            break;
        case 'modalityId':
            filters.push({
                property: 'notification.modalityId',
                comparator: 'in',
                values: searchValue,
            });
            break;
        default:
            return undefined;
    }

    return filters.length ? filters : undefined;
}

export function formatInAppNotificationConfig(notification: InAppNotification): InAppNotificationConfig | undefined {
    switch(notification.modality) {
        case 'snackbar': 
            return {
                ...defaultSnackbarConfig,
                ...notification.config as InAppNotificationSnackbarConfig,
                onAction: getNotificationActionByName({ 
                    action: notification.action, 
                    notificationId: notification.id,
                    modalityId: MessageModality[notification.modality],
                }, { href: notification.href, markAsRead: true }),
            };
        case 'toast':
            return {
                ...defaultToastConfig,
                ...notification.config as InAppNotificationToastConfig,
            };
        case 'notification':
            return {
                ...defaultNotificationConfig,
                ...notification.config as InAppNotificationNotificationConfig,
                onAction: getNotificationActionByName({ 
                    action: notification.action,
                    notificationId: notification.id,
                    modalityId: MessageModality[notification.modality],
                }, { href: notification.href, markAsRead: true }),
            };
        case 'invisible':
            return {
                ...defaultNotificationConfig,
                ...notification.config as InAppNotificationNotificationConfig,
                onAction: getNotificationActionByName({ action: notification.action }, { href: notification.href, markAsRead: false }),
            };
        default: 
            return undefined;
    }
}

export function displayInAppNotification(notification: InAppNotification) {
    if (notification.audioCue) {
        notificationService.playAudioCue(notification.audioCue, { throttleInterval: 2000 });
    }
    switch(notification.modality) {
        case 'snackbar': 
            return openSnackbar(notification.config as InAppNotificationSnackbarConfig);
        case 'toast':
            return openToastNotification(notification.config as InAppNotificationToastConfig);
        case 'notification':
            return openNotification(notification.config as InAppNotificationNotificationConfig);
        default: 
            return;
    }
}

export function getNotificationActionByName(
    { action, notificationId, modalityId }: { action?: NotificationAction, notificationId?: number, modalityId?: number }, 
    { href, markAsRead }: { href?: string, markAsRead?: boolean }={}
): ((...args: any[]) => any) | undefined {
    let markNotificationReadCallback: (() => void) | undefined = undefined;
    if (markAsRead && notificationId && modalityId) {
        markNotificationReadCallback = () => {
            store.commit('addReadNotificationIds', [notificationId]);
            updateNotification({ notificationId, modalityId, notificationRead: true }) 
        }
    }

    switch (action) {
        case 'href':
            if (!href) {
                return markNotificationReadCallback; // undefined if !markAsRead
            }
            return () => {
                window.open(href, '_blank');
                if (markNotificationReadCallback) {
                    markNotificationReadCallback();
                }
            }
        default: 
            return markNotificationReadCallback; // undefined if !markAsRead
    }
}