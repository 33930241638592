import { APIConfig, BuyerRebate } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess } from "@/utils";
import { GET } from ".";

export async function getRebateDetailsForLoggedInBuyer(config: APIConfig={}): Promise<BuyerRebate[]> {
    return await GET(`/rebate/buyerRebateData`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}