var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-field',{attrs:{"label":_vm.label}},[_c('b-datetimepicker',_vm._b({ref:"datepicker",staticClass:"datetimepicker",on:{"input":function($event){return _vm.returnSelectedTime(_vm.label, _vm.selectedTime)},"focus":function($event){_vm.isInModal ? _vm.fixPositionInModal() : ''},"blur":function($event){return _vm.$emit('blur', _vm.selectedTime)}},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}},'b-datetimepicker',{
            ..._vm.$attrs,
            ..._vm.$attrs.datetimepickerAttrs,
            icon: 'calendar-today',
            minDatetime: _vm.minPickableTime,
            timepicker: _vm.timepicker,
            placeholder: _vm.placeholder,
        },false),[_c('template',{slot:"left"},[_c('button',{staticClass:"button is-danger",on:{"click":function($event){_vm.selectedTime = null}}},[_c('span',[_vm._v("Clear")])])]),_c('template',{slot:"right"},[_c('button',{staticClass:"button is-primary",on:{"click":function($event){return _vm.onConfirm()}}},[_c('span',[_vm._v("Confirm")])])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }