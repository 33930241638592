
import { computed, defineComponent, PropType } from 'vue';
import { InspectionUpdateBookoutOptions } from '../types';
import { toCurrency } from '../utils';

import AppInfoRows, { InfoRow } from './AppInfoRows.vue';
import TheListingDetailsCard from './TheListingDetailsCard.vue';

export default defineComponent({
    name: 'TheListingDetailsBidHistory',
    props: {
        bookoutOptions: {
            type: Array as PropType<InspectionUpdateBookoutOptions>,
            default: () => [],
        },
    },
    components: {
        AppInfoRows,
        TheListingDetailsCard,
    },
    setup(props, { emit, attrs }) {
        const selectedBookoutOptions = computed<{name: string}[]>(() => {
            const features = props.bookoutOptions
                .filter((feature) => feature.selected)
                .map((feature) => ({
                    name: feature.name,
                }));
            return features;
        });

        return {
            toCurrency,
            selectedBookoutOptions,
        };
    },
});
