import { render, staticRenderFns } from "./TheReserveMetFlag.vue?vue&type=template&id=0da96aaa&scoped=true&"
import script from "./TheReserveMetFlag.vue?vue&type=script&lang=ts&"
export * from "./TheReserveMetFlag.vue?vue&type=script&lang=ts&"
import style0 from "./TheReserveMetFlag.vue?vue&type=style&index=0&id=0da96aaa&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da96aaa",
  null
  
)

export default component.exports