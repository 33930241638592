var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"confirm":_vm.updateCompany,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Edit Company Name ")]},proxy:true},{key:"body",fn:function(){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateCompany.apply(null, arguments)}}},[_c('AppInputFieldString',_vm._b({on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateCompany.apply(null, arguments)}},model:{value:(_vm.companyUpdatedName),callback:function ($$v) {_vm.companyUpdatedName=$$v},expression:"companyUpdatedName"}},'AppInputFieldString',{
          fieldAttrs: { label: 'Company name' },
          placeholder: 'Company name',
          isClearable: true,
        },false))],1)]},proxy:true}])},'Modal',{
    confirmButtonAttrs: {
      disabled: !_vm.companyUpdatedName,
      loading: _vm.loading,
    },
    cancelButtonAttrs: {
      disabled: _vm.loading,
    },
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }