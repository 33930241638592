import { Ref, onMounted, onUnmounted, ref, shallowRef, unref, computed, watch } from "vue"
import { database } from '@/firebase';
import { getUserCurrentBid, updateWatchlist } from '@/api';
import { APIConfig, AuctionCurrentBidDTO, HighestBidRefRecord, HighestBidRefSnapshot } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, dollarAmountToInt, getBuyItNowUnavailableReason, isBuyItNowAvailable } from "@/utils";
import { validatePrice } from "@/validation";
import { useStore } from "./useStore";
import { useCancelToken } from "./fetch";

export function useSingleAuctionListener(
    vehicleListingId: number,
    onSnapshot: (payload: HighestBidRefSnapshot) => void
) {
    type DatabaseRef = ReturnType<typeof database['ref']>;

    let highestBidDatabaseRef: DatabaseRef | null = null;
    let listener: ReturnType<DatabaseRef['on']> | null = null;
    const highestBidSnapshot = ref<HighestBidRefRecord | null>(null);
    const loadingHighestBidListener = ref(true);

    onMounted(() => {
        onHighestBidRefChange();
    });

    onUnmounted(() => {
        if (highestBidDatabaseRef && listener) {
            highestBidDatabaseRef.off('value', listener);
        }
    });

    function onHighestBidRefChange() {
        const highestBidRef = database.ref(`highestBid/${vehicleListingId}`);
        highestBidDatabaseRef = highestBidRef;

        listener = highestBidRef.on('value', (snapshot) => {
            if (!snapshot.exists()) {
                return;
            }
            const oldValue = highestBidSnapshot.value as HighestBidRefRecord | null;
            const newValue = snapshot.val() as HighestBidRefRecord;
            onSnapshot({
                isInitialSnapshot: !oldValue,
                oldSnapshotValue: oldValue,
                newSnapshotValue: newValue,
            });
            if (!oldValue) {
                loadingHighestBidListener.value = false;
            }
            highestBidSnapshot.value = newValue;
        });
    }

    return {
        loadingHighestBidListener,
    }
}

export function useUpdateAuctionListingCardOnRdbChange() {
    const highestBid = ref<HighestBidRefRecord>({});
    const highestBidKey = ref(0);

    function updateAuction({ isInitialSnapshot, oldSnapshotValue, newSnapshotValue }: HighestBidRefSnapshot) {
        highestBid.value = newSnapshotValue;
        highestBidKey.value++;
    }

    return {
        updateAuction,
        highestBid,
        highestBidKey,
    }
}

export function useUpdateWatchlistStatus(vehicleListingId: Ref<number> | number, {
    emit,
}: {
    emit: (event: 'update:watching'| 'watching' | any, value: boolean) => void
}) {
    async function updateWatchlistStatus(value: boolean) {
        emit('update:watching', value);
        const { success } = await updateWatchlist(unref(vehicleListingId), value);
        if (!success) {
            emit('update:watching', !value);
        } else {
            emit('watching', value);
        }
    }

    return {
        updateWatchlistStatus,
    };
}

export function useValidatePricing(pricing: Ref<{
    startingPrice: string,
    reservePrice: string,
    buyItNowPrice: string,
    canBuyItNow: boolean,
}>) {
    const startingPriceInt = computed(() => dollarAmountToInt(pricing.value.startingPrice));
    const reservePriceInt = computed(() => dollarAmountToInt(pricing.value.reservePrice));
    const buyItNowPriceInt = computed(() => dollarAmountToInt(pricing.value.buyItNowPrice));

    const isStartingPriceValid = computed(() => {
        return !Boolean(validatePrice(startingPriceInt.value, 'starting', { isUndefinedInvalid: false }));
    });

    const startingPriceInvalidReason = computed(() => {
        return validatePrice(startingPriceInt.value, 'starting', { isUndefinedInvalid: false });
    });

    const isReservePriceValid = computed(() => {
        return !Boolean(validatePrice(reservePriceInt.value, 'reserve', { isUndefinedInvalid: false }));
    });

    const reservePriceInvalidReason = computed(() => {
        return validatePrice(reservePriceInt.value, 'reserve', { isUndefinedInvalid: false });
    });

    const isBuyItNowPriceValid = computed(() => {
        if (!pricing.value.canBuyItNow) {
            return true;
        }
        if (!buyItNowPriceInt.value) {
            return false;
        }
        return isBuyItNowAvailable({
            startingPrice: startingPriceInt.value,
            reservePrice: reservePriceInt.value,
            buyItNowPrice: buyItNowPriceInt.value,
        });
    });

    const buyItNowPriceInvalidReason = computed(() => {
        if (!pricing.value.canBuyItNow) {
            return undefined;
        }
        if (!buyItNowPriceInt.value) {
            return 'Required if Buy-It-Now is enabled';
        }
        return getBuyItNowUnavailableReason({
            startingPrice: startingPriceInt.value,
            reservePrice: reservePriceInt.value,
            buyItNowPrice: buyItNowPriceInt.value,
        });
    });
    
    return {
        isStartingPriceValid,
        startingPriceInvalidReason,
        isReservePriceValid,
        reservePriceInvalidReason,
        isBuyItNowPriceValid,
        buyItNowPriceInvalidReason,
    }
}

export function useAuctionActivity({ highestBid, userCurrentBid }: {
    highestBid: Ref<HighestBidRefRecord>,
    userCurrentBid?: Ref<AuctionCurrentBidDTO | undefined>,
}) {
    const store = useStore();
    const loggedInUID = parseInt(store.state.user.profile.id);

    const hasUserBid = computed(() => {
        if (!highestBid.value) {
            return false;
        }
        if (!highestBid.value.bidHistory?.length) {
            return false;
        }
        return highestBid.value.bidHistory.some(bid => parseInt(bid.buyer.id) === loggedInUID);
    });

    const isUserHighestBidder = computed(() => {
        if (!highestBid.value) {
            return false;
        }
        return parseInt(highestBid.value.buyer?.id ?? '') === loggedInUID;
    });

    const isProxyBidExpired = computed(() => {
        if (!highestBid.value?.amount || !userCurrentBid.value?.proxyBidAmount) {
            return false;
        }
        return userCurrentBid.value.proxyBidAmount < highestBid.value.amount;
    });

    const isAuctionEnded = computed(() => {
        if (!highestBid.value?.auctionEnd) {
            return false;
        }
        let auctionEndDate = new Date(highestBid.value.auctionEnd);
        let now = new Date();
        return auctionEndDate.getTime() < now.getTime();
    })

    return {
        hasUserBid,
        isUserHighestBidder,
        isProxyBidExpired,
        isAuctionEnded,
    }
}

export function useGetAuctionActivity({ currentBid }: {
    currentBid?: AuctionCurrentBidDTO
}={}) {
    const userCurrentBid: Ref<AuctionCurrentBidDTO | undefined> = ref(currentBid ?? undefined);
    const loadingUserCurrentBid: Ref<boolean> = ref(false);

    const { cancelToken, cancelPreviousRequest } = useCancelToken();

    async function getAuctionActivity(vehicleListingId: number, config: APIConfig={}) {
        cancelPreviousRequest();
        loadingUserCurrentBid.value = true;

        getUserCurrentBid(vehicleListingId, {}, {
            ...config,
            cancelToken: cancelToken.value?.token,
            onSuccess: (res) => {
                userCurrentBid.value = res;
                loadingUserCurrentBid.value = false;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: (error) => {
                loadingUserCurrentBid.value = false;
                applyAPIConfigOnError(error, config);
            },
        });
    }

    return {
        userCurrentBid,
        loadingUserCurrentBid,
        getAuctionActivity,
    }
}