import { defineComponent } from 'vue';
import { database } from '@/firebase';
import { mapGetters } from 'vuex';
import { throwConfettiFromSides } from '@/utils';
import { GET, POST } from '@/api';

/**
 * Play a sound and throw confetti if bidder wins giveaway
 */
export default defineComponent({
  data: () => ({
    giveawayRef: null as null | ReturnType<typeof database.ref>,
  }),
  computed: {
    ...mapGetters(['isUserBuyer']),
  },
  watch: {
    isUserBuyer: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.giveawayRef?.off();

          this.giveawayRef = database.ref('giveaway');

          this.giveawayRef.on('child_added', async (snapshot) => {
            const giveaway = snapshot.val();
            if (!giveaway.seen && giveaway.isUserWinner && (`${giveaway.personId}` === this.$store.state.user.profile.id)) {
              POST(`/vehicles/giveawaySeen/${snapshot.key}`, {})
                .catch(error => {
                  console.log('Error marking giveaway as seen', error);
                });
                throwConfettiFromSides();
              if (!this.giveawayModal) {
                this.giveawayModal = this.$buefy.dialog.alert({
                  title: `You're a winner!`,
                  message: `Congratulations! You're the 500th bidder! Look out for an email with more details.`,
                  confirmText: 'Close',
                  onConfirm: () => this.giveawayModal = null,
                });
              }
              // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
              const audio = new Audio(require('@/assets/sounds/ding.mp3'));
              audio.play();
            }
          });
        } else {
          this.giveawayRef?.off();
        }
      },
    },
  },
  destroyed() {
    this.giveawayRef?.off();
  },
});
