var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheNegotiationModal',_vm._b({on:{"accept":_vm.acceptNegotiationOffer,"reject":_vm.rejectNegotiationOffer,"counter":_vm.submitCounterOffer,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"counterOfferDetails",fn:function(){return [_c('AppCheckboxInput',_vm._b({model:{value:(_vm.sendNotifications),callback:function ($$v) {_vm.sendNotifications=$$v},expression:"sendNotifications"}},'AppCheckboxInput',{
                type: 'is-primary',
            },false),[_vm._v(" Send notifications ")])]},proxy:true}])},'TheNegotiationModal',{
        ..._vm.$props, 
        ..._vm.$attrs,
        highlightDetails: [
            {
                label: `Your Offer`,
                value: _vm.negotiatingUserOfferAmount,
                filter: _vm.toCurrency,
            },
            {
                label: `${_vm.capitalize(_vm.otherUser)}'s Offer`,
                value: _vm.otherUserOfferAmount,
                filter: _vm.toCurrency,
            },
        ],
        loadingAcceptOffer: _vm.loadingAcceptOffer,
        loadingRejectOffer: _vm.loadingRejectOffer,
        loadingSubmitCounterOffer: _vm.loadingSubmitCounterOffer,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }