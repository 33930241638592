export const notificationService = {
    lastAudioPlayedTime: 0,
    playAudioCue: (audioCue: string, { throttleInterval=0 }: { throttleInterval?: number }={}) => {
        let now = Date.now();
        if (now - notificationService.lastAudioPlayedTime > throttleInterval) {
            try {
                // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                notificationService.lastAudioPlayedTime = Date.now();
                const audio = new Audio(require(`@/assets/sounds/${audioCue}.mp3`));
                audio.play();
            } catch(error) {
                console.error(`Error playing audio cue: ${audioCue}`);
            }
        }
    },
}