
import { computed, defineComponent, PropType } from 'vue';
import { toCurrency } from '../utils';
import { overdriveService } from '../service/overdrive.service';

import CountdownTimer from './CountdownTimer.vue';
import TheOverdriveProgressBar from './TheOverdriveProgressBar.vue';

export default defineComponent({
    name: 'TheEngagedOverdriveProgressBar',
    props: {
        currentBidCount: {
            type: Number,
            default: 0,
        },
        currentGoalBidCount: {
            type: Number,
            required: true,
        },
        currentLevel: {
            type: Number,
            required: false,
        },
        upcomingLevel: {
            type: Number,
            required: false,
        },
        currentDiscount: {
            type: Number,
            required: false,
        },
        upcomingDiscount: {
            type: Number,
            required: false,
        },
        disengageOverdriveDate: {
            type: Date,
            required: false,
        },
    },
    components: {
        CountdownTimer,
        TheOverdriveProgressBar,
    },
    setup(props, { emit, attrs }) {
        const currentLevelIcon = computed(() => overdriveService.getOverdriveIconByLevel(props.currentLevel));
        
        return {
            toCurrency,
            currentLevelIcon,
        }
    },
});
