import { APIConfig, FilterFieldOptionsSchema, listAllUsersForAdminDTO, ModifierOptions, NegotiationTableUserDTO, VehicleStatusGroup } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, getUnreadNotificationIdsByUserIdFromNotificationRef, openErrorDialog, setDefaultTransportationZip } from "@/utils";
import axios, { AxiosRequestConfig } from 'axios';
import { GET, POST, PUT } from ".";
import store from '@/vuex';
import { User } from "@sentry/types";

export async function getNegotiatorsForAdmin(config: APIConfig={}) {
    return await GET(`/vehicles/peopleForNegotiationDropdown`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch negotiators',
                message: `We encountered an error while fetching DSRs and Account Managers with open negotiations.`,
                error,
            });
        });
}

export async function getNegotiations(filterSchema: FilterFieldOptionsSchema, config: APIConfig={}, requestConfig?: AxiosRequestConfig) {
    return await PUT(`/vehicles/negotiations`, filterSchema, requestConfig)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            if (axios.isCancel(error)) {
                return;
            };

            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to fetch negotiations',
                message: celebrateValidationMessage ?? `We encountered an error while fetching negotiations`,
                error,
            });
        });
}

export async function createAdminNote(vehicleListingId: number, note: string, config: APIConfig={}) {
    return await PUT(`/vehicles/${vehicleListingId}/adminAddNote`, { note })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Your note failed to save',
                message: celebrateValidationMessage ?? `We encountered an error while saving your note to vehicle ${vehicleListingId}. Contents: "${note}"`,
                error,
            });
        });
}

export async function getNegotiatingEmployees(config: APIConfig={}): Promise<NegotiationTableUserDTO[]> {
    return await GET(`/vehicles/peopleForNegotiationDropdown`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch negotiating employees',
                message: `We encountered an error while fetching Dealer Sales Representatives and Account Managers available for negotiations`,
                error,
            });
        });
}

export async function getAdminDashboardListingsByStatus(status: VehicleStatusGroup, payload: { paginationOffset?: number, paginationLimit?: number, orderBy?: string, orderByDirection?: 'asc' | 'desc' }, config: APIConfig={}) {
    return await PUT(`/vehicles/listAllForAdmin/${status}`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to fetch listings',
                message: celebrateValidationMessage ?? `We encountered an error while fetching admin listings for status ${status}`,
                error,
            });
        });
}

export async function resetBankInfoByPersonId(personId: number, config: APIConfig={}) {
    return await POST(`/plaid/resetBankInfo/${personId}`, {})
        .then((res) => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to reset bank info',
                message: `We encountered an error resetting bank info for person ${personId}. Please try again or contact support.`,
                error,
            });
        });
}


export async function impersonateUser(personId: number, config: APIConfig={}) {
    store.commit('stashRefreshToken');
    const response = await GET(`/auth/impersonateUser/${personId}`)
      .then(res => res.data) // applyAPIConfigOnSuccess at end of function
      .catch(error => {
        applyAPIConfigOnError(error, config);
        openErrorDialog({
          title: 'Failed to impersonate',
          message: `We encountered an error while impersonating person ${personId}. Please try again or contact support.`,
          error,
        });
      });
  
    const stashedToken = store.state.user.stsTokenManager.stashedRefreshToken;
  
    let user: Partial<User> = {};
    const userToken = response.user.stsTokenManager.accessToken;
    // deprecated
    localStorage.setItem('accessToken', userToken);
    user = {
      ...response.user,
      stsTokenManager: {
        ...response.user.stsTokenManager,
        stashedRefreshToken: stashedToken
      },
      isNewUser: response.additionalUserInfo.isNewUser,
      profile: response.profile,
    };
    store.commit('clearReadNotificationIds');
    store.commit('login', user);
    setDefaultTransportationZip();
  
    applyAPIConfigOnSuccess(response, config);
}

export async function getAdminUsersTableData(modifiers: ModifierOptions={}, config: APIConfig={}): Promise<listAllUsersForAdminDTO[]> {
    return await PUT(`/auth/getAllUsers`, { modifiers })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to fetch user',
                message: celebrateValidationMessage ?? `We encountered an error while searching for a user. Please try again or contact support.`,
                error,
            });
        });
}

export async function getAchEmailDetails(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/vehicles/${vehicleListingId}/achInitiatedData`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: `Failed to fetch listing details for ACH email`, 
                message: `We encountered an error fetching details for listing ${vehicleListingId}. Please try again or contact support.`,
                error,
            });
        });
}

export async function sendToSecondChance(vehicleListingId: number, reservePrice: number, config: APIConfig={}) {
    return await PUT(`/vehicles/${vehicleListingId}/sendToSecondChance`, { reservePrice })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: `Failed to send to Second Chance`,
                message: `We encountered an error while sending listing ${vehicleListingId} to Second Chance with reserve price ${reservePrice}`, 
                error,
                displayErrorInDialog: true,
            });
        });
}
