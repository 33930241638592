var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLoadingCheckoutData)?_c('Loading'):_c('TheCheckoutModal',_vm._b({on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"facilitating-auction-banner"},[_vm._v(" This auction is facilitated by "),_c('span',{staticClass:"bold italic"},[_vm._v(_vm._s(_vm.storeName))])])]},proxy:true},{key:"transportationTrigger",fn:function(){return [(!_vm.isCarmigoFacilitating('transportation'))?_c('p',{staticClass:"selected-option"},[_vm._v(" Handled by facilitating auction ")]):_vm._e()]},proxy:true},{key:"transportation",fn:function(){return [(!_vm.isCarmigoFacilitating('transportation'))?_c('p',[_vm._v(_vm._s(_vm.getStoreFacilitatedCheckoutServiceMessage('transportation', _vm.storeName)))]):_vm._e()]},proxy:true},{key:"arbitrationsTrigger",fn:function(){return [(!_vm.isCarmigoFacilitating('arbitrations'))?_c('p',{staticClass:"selected-option"},[_vm._v("Handled by facilitating auction")]):_vm._e()]},proxy:true},{key:"arbitrations",fn:function(){return [(!_vm.isCarmigoFacilitating('arbitrations'))?_c('p',[_vm._v(_vm._s(_vm.getStoreFacilitatedCheckoutServiceMessage('arbitrations', _vm.storeName)))]):_vm._e()]},proxy:true},{key:"titleTrigger",fn:function(){return [(!_vm.isCarmigoFacilitating('title'))?_c('p',{staticClass:"selected-option"},[_vm._v("Handled by facilitating auction")]):_vm._e()]},proxy:true},{key:"title",fn:function(){return [(!_vm.isCarmigoFacilitating('title'))?_c('p',[_vm._v(_vm._s(_vm.getStoreFacilitatedCheckoutServiceMessage('title', _vm.storeName)))]):_vm._e()]},proxy:true},{key:"payment",fn:function({ orderSummary }){return [_c('TheCheckoutPaymentForm',_vm._b({staticClass:"mb-4"},'TheCheckoutPaymentForm',{ 
                orderSummary,
                showOrderSummaryTotal: false,
            },false)),(!_vm.isCarmigoFacilitating('payment'))?_c('p',[_vm._v(_vm._s(_vm.getStoreFacilitatedCheckoutServiceMessage('payment', _vm.storeName)))]):_vm._e()]}}])},'TheCheckoutModal',{
        vehicleName: _vm.vehicleName,
        vehiclePrice: _vm.vehiclePrice,
        checkoutSteps: _vm.displayCheckoutSteps,
        checkoutData: _vm.checkoutData,
        showButtons: ['close'],
        isLoading: _vm.isLoadingSubmitCheckout,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }