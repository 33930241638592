
import { defineComponent } from 'vue';

import AppFlag from './AppFlag.vue';

export default defineComponent({
    name: 'TheReserveMetFlag',
    components: {
        AppFlag,
    }
});
