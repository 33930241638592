
import { defineComponent } from 'vue';
import InfoHeader from './Arbitrations/InfoHeader.vue';

export default defineComponent({
    name: 'TheInfoHeaderVehicleListing',
    props: {
        imageUrl: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
    },
    components: {
        InfoHeader,
    },
});
