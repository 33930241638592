import {
    Ref,
    computed,
    onUnmounted,
    ref,
    unref,
} from 'vue';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { request } from '@/api';

export type UseFetchOptions<T> = {
    /**
     * Whether to fetch the data immediately.
     */
    immediate?: boolean;
    /**
     * Sets the state to initialData before executing the promise.
     */
    resetOnExecute?: boolean
    /**
     * Whether to skip the error if the request was cancelled via cancelToken.
     */
    skipErrorOnCancel?: boolean;
    /**
     * Callback before the request is sent.
     */
    onBeforeFetch?: () => void;
    /**
     * Callback when error is caught.
     */
    onError?: (error: Error) => void;
    /**
     * Callback when success is caught.
     */
    onSuccess?: (response: T, data: Ref<T | undefined>) => void;
} & AxiosRequestConfig

export type UseFetchOptionsWithInitialData<T> = UseFetchOptions<T> & {
    /**
     * Initial data to use
     */
    initialData: T;
}
export type UseFetchOptionsWithoutInitialData<T> = UseFetchOptions<T> & {
    /**
     * Initial data to use
     */
    initialData?: undefined;
}

interface UseFetchReturn<T, B, E> {
    /**
     * Response data
     */
    data: Ref<T>;
    /**
     * Any errors that may have occurred
     */
    error: Ref<E | undefined>;
    /**
     * Indicates if the request is currently loading
     */
    loading: Ref<boolean>;
    /**
     * Manually call the request
     */
    execute: (body?: B) => Promise<void>
}

export function useFetch<T = unknown, B = Record<string, unknown>, E = Error>(
    routeOrFunction: string | Ref<string> | (() => string),
    options?: UseFetchOptionsWithInitialData<T>,
): UseFetchReturn<T, B, E>;

export function useFetch<T = unknown, B = Record<string, unknown>, E = Error>(
    routeOrFunction: string | Ref<string> | (() => string),
    options?: UseFetchOptionsWithoutInitialData<T>,
): UseFetchReturn<T | undefined, B, E>;

/**
 * A Vue composable that fetches data from an API or a function.
 *
 * @param {string | Ref<string> | (() => string)} routeOrFunction The url or function that returns a url to fetch data from.
 */
export function useFetch<T, B = Record<string, unknown>, E = Error>(
    routeOrFunction: string | Ref<string> | (() => string),
    options: UseFetchOptionsWithInitialData<T> | UseFetchOptionsWithoutInitialData<T> = {},
): UseFetchReturn<T | undefined, B, E> {
    const {
        immediate = true,
        initialData,
        resetOnExecute = false,
        skipErrorOnCancel = true,
        onBeforeFetch,
        onError,
        onSuccess,
        method = 'get',
        ...requestConfig
    } = options;
    const data = ref<T>(initialData!) as Ref<T | undefined>;
    const error = ref<E>();
    const loading = ref<boolean>(false);

    async function execute(body?: B): Promise<void> {
        onBeforeFetch?.();
        loading.value = true;
        if (resetOnExecute) {
            data.value = initialData;
        } else {
            data.value = undefined;
        }
        error.value = undefined;

        try {
            const url = typeof routeOrFunction === 'function' ? routeOrFunction() : unref(routeOrFunction);
            const { data: response } = await request(url, method, body || {}, requestConfig);
            data.value = response;
            onSuccess?.(response, data);
        } catch (err) {
            if (axios.isCancel(err) && skipErrorOnCancel) {
                return;
            }

            onError?.(err as Error);
            error.value = err as E;
        } finally {
            loading.value = false;
        }
    }

    if (immediate) {
        if (method !== 'get') {
            execute(options.data);
        } else {
            execute();
        }
    }

    return {
        execute,
        data,
        error,
        loading,
    };
}

/**
 * Returns a reference to cancel tokens & cancelers for the current request and previous request.
 * Use generateNewCancelToken to track a new request while also maintaining a reference to the previous request.
 * Use cancelPreviousRequest to cancel the previous request.
 * Use getCancelToken (computed) to get the current request's cancel token.
 * 
 * Automatically cancels any requests when the component is unmounted.
 * Automatically calls generateNewCancelToken 
 */

export function useCancelToken() {
    const cancelToken: Ref<CancelTokenSource | undefined> = ref(undefined);
    onUnmounted(cancelPreviousRequest);
    
    function createNewCancelToken() {
        cancelToken.value = axios.CancelToken.source();
    }

    function cancelPreviousRequest() {
        if (cancelToken.value) {
            cancelToken.value.cancel('Previous request cancelled');
        }
        createNewCancelToken();
    }

    return {
        cancelToken,
        createNewCancelToken,
        cancelPreviousRequest,
    }
}
