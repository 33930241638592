
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue';
import { useTransporationCost, useUser, useUserRole, useVdpModal } from '../composables';
import { BBVehicleFeature, DeliveryStatus, FixMe, HighestBidRefRecord, ImageDTO, InspectionConditionsDTO, MarketDataDTO, SRPListing, TitleStatus, VehicleListingAnnouncement, VehicleListingAnnouncementCategorizedDTO } from '../types';
import { orderSummary } from '../types/OrderSummary';
import { getIconColorByAnnouncementType, getLastPathName, goToLastPath, isVehicleStatusCheckoutOrLater } from '../utils';
import store from '@/vuex';
import router from '@/router';
import { fireEvent } from '@/segment';

import AppButton from './AppButton.vue';
import AppCarouselPhotos from './AppCarouselPhotos.vue';
import AppOverdrive from './AppOverdrive.vue';
import ButtonIcon from './ButtonIcon.vue';
import NavLinkWithAnimation from './NavLinkWithAnimation.vue';
import TheAnnouncement from './TheAnnouncement.vue';
import TheInoperableListingFlag from './TheInoperableListingFlag.vue';
import TheListingDetailsAnnouncements from './TheListingDetailsAnnouncements.vue';
import TheListingDetailsBidHistory from './TheListingDetailsBidHistory.vue';
import TheListingDetailsBookoutOptions from './TheListingDetailsBookoutOptions.vue';
import TheListingDetailsListingStats from './TheListingDetailsListingStats.vue';
import TheListingDetailsMarketData from './TheListingDetailsMarketData.vue';
import TheListingDetailsSellerDetails from './TheListingDetailsSellerDetails.vue';
import TheListingDetailsVehicleAttributes from './TheListingDetailsVehicleAttributes.vue';
import TheListingDetailsVehicleBasics from './TheListingDetailsVehicleBasics.vue';
import TheListingDetailsViewsAndCounts from './TheListingDetailsViewAndCounts.vue';
import TheTitleAndDeliveryStatusIcons from './TheTitleAndDeliveryStatusIcons.vue';
import TheReserveMetFlag from './TheReserveMetFlag.vue';

export default defineComponent({
    name: 'AppListingDetails',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: false,
        },
        vehiclePhotos: {
            type: Array as PropType<ImageDTO[]>,
            required: false,
        },
        announcements: {
            type: Object as PropType<VehicleListingAnnouncementCategorizedDTO>,
            required: false,
        },
        bookoutOptions: {
            type: Array as PropType<BBVehicleFeature[]>,
            required: false,
        },
        bidHistory: {
            type: Array as PropType<FixMe[]>,
            required: false,
        },
        order: {
            type: Object as PropType<orderSummary>,
            required: false,
        },
        titleStatus: {
            type: String as PropType<TitleStatus>,
            required: false,
        },
        deliveryStatus: {
            type: String as PropType<DeliveryStatus>,
            required: false,
        },
        highestBid: {
            type: Object as PropType<HighestBidRefRecord>,
            required: false,
        },
        transportationCost: {
            type: Number,
            required: false,
        },
        marketData: {
            type: Object as PropType<MarketDataDTO>,
            required: false,
        },
        averageMarketDataCost: {
            type: Number,
            required: false,
        },
        displayOverdrive: {
            type: Boolean,
            default: false,
        },
        vehicleListingPhotoId: {
            type: Number,
            required: false,
        },
    },
    components: {
        AppButton,
        AppCarouselPhotos,
        AppOverdrive,
        ButtonIcon,
        NavLinkWithAnimation,
        TheAnnouncement,
        TheInoperableListingFlag,
        TheListingDetailsAnnouncements,
        TheListingDetailsBidHistory,
        TheListingDetailsBookoutOptions,
        TheListingDetailsListingStats,
        TheListingDetailsMarketData,
        TheListingDetailsSellerDetails,
        TheListingDetailsVehicleAttributes,
        TheListingDetailsVehicleBasics,
        TheListingDetailsViewsAndCounts,
        TheReserveMetFlag,
        TheTitleAndDeliveryStatusIcons,
    },
    emits: ['update:vehicleListingPhotoId'],
    setup(props, { emit, attrs }) {
        const { isUserAdmin, isUserDsr } = useUserRole(store);
        const isLoggedIn = computed(() => store.getters.isAuthenticated);

        const { shareListing } = useVdpModal({
            vehicleListingId: props.listing?.id,
            router,
        });

        const isGalleryView: Ref<boolean> = ref(false);
        const getVehiclePhotos = computed(() => {
            let photos = [];
            if (!props.vehiclePhotos?.length) {
                photos = [{ image: props.listing?.heroImgUrl }];
            } else {
                photos = props.vehiclePhotos.map((photo, idx) => {
                    return {
                        ...photo,
                        image: photo.url,
                        id: photo.vehicleListingPhotoId,
                    }
                });
            }
            return photos;
        });

        const user = useUser();
        const purchasedVehicle = computed(() => {
            if (!user.value?.profile?.id) {
                return false;
            }
            return (props.order as orderSummary)?.buyerPersonId == parseInt(user.value.profile.id);
        });
        
        // transportation cost comes from AppListingRow if modal, or has to be fetched here if standalone VDP
        const transportationCostCalculated = ref(undefined);
        if (!attrs.isInModal && !props.transportationCost) {
            const { transportationCost: calculatedTransportationCost } = useTransporationCost({
                listingZipCode: props.listing?.zip,
                vehicleListingId: props.listing?.id,
            });

            watch(() => calculatedTransportationCost.value, () => {
                transportationCostCalculated.value = calculatedTransportationCost.value;
            });
        } 

        const displayVehicleListingPhotoId: Ref<number | undefined> = ref(props.vehicleListingPhotoId);
        watch(() => props.vehicleListingPhotoId, () => {
            displayVehicleListingPhotoId.value = props.vehicleListingPhotoId;
        });

        watch(() => displayVehicleListingPhotoId.value, () => {
            emit('update:vehicleListingPhotoId', displayVehicleListingPhotoId.value);
        });

        function showAnnouncementPhoto(vehicleListingPhotoId: number) {
            fireEvent('View announcement photo');
            displayVehicleListingPhotoId.value = vehicleListingPhotoId;
        }

        return {
            getIconColorByAnnouncementType,
            getLastPathName,
            goToLastPath,
            getVehiclePhotos,
            isGalleryView,
            isVehicleStatusCheckoutOrLater,
            isUserAdmin,
            isUserDsr,
            purchasedVehicle,
            transportationCostCalculated,
            store,
            shareListing,
            router,
            showAnnouncementPhoto,
            displayVehicleListingPhotoId,
            isLoggedIn,
        }
    }
});
