
import { PropType } from 'vue';
import { FeedbackType } from '../types';

export default {
    name: 'StatusTag',
    props: {
        status: {
            type: String,
            required: true,
        },
        type: {
            type: String as PropType<FeedbackType>,
            default: 'primary'
        },
        color: {
            type: String,
            required: false,
        }
    },
    computed: {
        getStyles() {
            return {
                background: this.getBackgroundColor(),
                color: this.getFontColor(),
                fontSize: '14px',
                fontWeight: '600',
            }
        }  
    },
    methods: {
        getBackgroundColor() {
            if (this.color) {
                return this.color;
            }
            return `var(--${this.type})`;
        },
        getFontColor() {
            switch (this.type) {
                case 'primary':
                case 'danger':
                default:
                    return '#fff';
                case 'primary-light':
                    return 'var(--primary-dark)';
                case 'grey-light':
                    return 'var(--dark)'
            }
        }
    },
}
