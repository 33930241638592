/* eslint-disable operator-linebreak */
/* eslint-disable linebreak-style */
import type { NavigationGuardNext, Route } from 'vue-router';
import { GET } from '@/api';
import { getRedirectPathByRole, hasRole, isVehicleSold, openToast } from '@/utils';
import store from '../vuex';
import { LoadingProgrammatic } from 'buefy';
import { NegotiationDTO, Roles } from '@/types';

export async function isVehicleComplete(to: Route, from: Route, next: NavigationGuardNext) {
  checkAuth(to, from, next, async () => {
    const { params: { vehicleListingId } = {} } = to;
    const [
      stepReservePrice,
      stepVehicleProfile,
      stepPhotos,
    ] = await Promise.all([
      GET(`vehicles/getReservePricePercentCompletion/${parseInt(vehicleListingId)}`).then((res) => res.data),
      GET(`vehicles/getVehicleProfileCompletePercent/${parseInt(vehicleListingId)}`).then((res) => res.data),
      GET(`pave/getPhotosPercentComplete/${parseInt(vehicleListingId)}`).then((res) => res.data),
    ]);

    if (stepVehicleProfile === 100 && stepReservePrice === 100 && stepPhotos === 100) {
      next();
      return;
    }
    else {
      next(`/welcomeBack/${vehicleListingId}`);
      return;
    }
  })
}

export async function returningUsersRedirect(to: Route, _from: Route, next: NavigationGuardNext) {
  
  // ensure users are logged in before we do anything
  const isLoggedIn = store.getters.isAuthenticated;
  if (!isLoggedIn) {

    // This will bring the users back after they login
    next({
      path: '/login',
      query: {
        redirect: to.fullPath,
      },
    });
    return;
  }

  type returnData = {
        vehicleStatus: string,
        questionnairePercentComplete?: number,
        pavePercentComplete: number,
        auctionBuilderReservePrice?: number,
        vehicleTermsAccepted?: boolean,
        bidHistoryLength?: number,
        negotiations: NegotiationDTO[]
  }
  
  const loadingComponent = LoadingProgrammatic.open({});
  
  try {
    const { params: { vehicleListingId } = {} } = to;
    const response: returnData = (await GET(`/vehicles/getVehicleInfoLimited/${vehicleListingId}`)).data;
    // There are 3 reasons why the response would not exist. 1 a user is attempting to access a vehicle that is not theirs 2. The vehicle does not exits, 3. The server blew up. In all cases we want to send them to submit a new vehicle
    if (!response) {
      return next('/home');
    }
    
    // 1
    if (
      (response.vehicleStatus === 'Registered') &&
      (response.questionnairePercentComplete == null)
      ) {
      loadingComponent.close();
      return next(`/sellingOptions/${vehicleListingId}`);
    }
    // 2
    if (
      (response.vehicleStatus === 'InstantOfferAccepted') &&
      (response.pavePercentComplete !== 100)
      ) {
      loadingComponent.close();
      return next(`/instantOfferPhotos/${vehicleListingId}`);
    }
    // 3
    if (
      (response.vehicleStatus === 'InstantOfferAccepted') &&
      (response.pavePercentComplete === 100)
    ) {
      loadingComponent.close();
      return next(`/getPaid/${vehicleListingId}`);
    }
    // 4
    if (
      (response.vehicleStatus === 'InstantOfferCompleted')
    ) {
      loadingComponent.close();
      return next(`/instantOfferCompleted/${vehicleListingId}`);
    }
    // 5
    if (
      (response.vehicleStatus === 'InstantOfferLost')
    ) {
      loadingComponent.close();
      return next(`/instantOfferLost/${vehicleListingId}`);
    }
    // 6
    if (
      (response.vehicleStatus === 'Registered') &&
      (response.questionnairePercentComplete !== 100)
    ) {
      loadingComponent.close();
      return next(`/questionnaire/${vehicleListingId}`);
    }
    // 7
    if (
      (response.vehicleStatus === 'Registered') &&
      (response.questionnairePercentComplete === 100) &&
      (!response.auctionBuilderReservePrice)
    ) {
      loadingComponent.close();
      return next(`/set-request-price/${vehicleListingId}`);
    }
    // 8
    if (
      (response.vehicleStatus === 'Registered') &&
      (response.questionnairePercentComplete === 100) &&
      (response.auctionBuilderReservePrice) && 
      (response.pavePercentComplete !== 100)
    ) {
      loadingComponent.close();
      return next(`/photos/${vehicleListingId}`);
    }
    // 9
    if (
      (response.vehicleStatus === 'Registered' || response.vehicleStatus === 'InspectionScheduled') &&
      (response.questionnairePercentComplete === 100) &&
      (response.auctionBuilderReservePrice) && 
      (response.pavePercentComplete === 100) &&
      (!response.vehicleTermsAccepted)
    ) {
      loadingComponent.close();
      return next(`/sellerAgreement/${vehicleListingId}`);
    }
    // 10
    if (
      (response.vehicleStatus === 'Inspected' || response.vehicleStatus === 'InspectionScheduled') &&
      (response.vehicleTermsAccepted)
    ) {
      loadingComponent.close();
      return next(`/preview/${vehicleListingId}`);
    }
    // 11
    if (
      ((response.vehicleStatus === 'AuctionScheduled' || response.vehicleStatus === 'Auctioning') && response.vehicleTermsAccepted) 
        || response.vehicleStatus === 'SecondChance'
    ) {
      loadingComponent.close();
      return next(`/vehicleOverview/${vehicleListingId}`);
    }
    // 12
    if (isVehicleSold(response.vehicleStatus)) {
      loadingComponent.close();
      return next(`/congratulations/${vehicleListingId}`);
    }
    // 13
    if (
      (response.vehicleStatus === 'NotSold') &&
      (!response.bidHistoryLength)
    ) {
      loadingComponent.close();
      return next(`/noOffers/${vehicleListingId}`);
    }
    // 14, 15, 16
    // vehicleListingId: 3
    if (
      (response.vehicleStatus === 'Negotiating') &&
      (response.negotiations.length)
    ) {

      const lastNegotiation = response.negotiations[response.negotiations.length - 1];

      // 14
      if (
        (!lastNegotiation.onBuyer) &&
        (response.negotiations.length <= 1)
      ) {
        loadingComponent.close();
        return next(`/minimumPriceNotMet/${vehicleListingId}`);
      }

      // 15
      if (
        (lastNegotiation.onBuyer)
      ) {
        loadingComponent.close();
        return next(`/counterOfferSubmitted/${vehicleListingId}`);
      }

      // 16 a,b
      if (
        ((!lastNegotiation.onBuyer && response.negotiations.length > 1) || (lastNegotiation.status === 'rejected' && !lastNegotiation.onBuyer))
      ) {
        loadingComponent.close();
        return next(`/newCounterOffer/${vehicleListingId}`);
      }
    }
    // 16 c
    if (
      (response.vehicleStatus === 'NotSold') &&
      (response.negotiations.length)
    ) {
      loadingComponent.close();
      return next(`/newCounterOffer/${vehicleListingId}`);
    }
  } catch (error: any) {
    console.log(error);
    loadingComponent.close();
    return next('/');
  }
  loadingComponent.close();
  return next('/');
}

export async function checkPaveStatus(to: Route, _from: Route, next: NavigationGuardNext) {
  const vehicleListingId = to.params.vehicleListingId;

  const vehicleStatus: string = await (await GET(`vehicles/getVehicleStatusByVehicleListingId/${vehicleListingId}`)).data;
  const pavePercentComplete: number = await (await GET(`pave/getPhotosPercentComplete/${vehicleListingId}`)).data;

  const instantOfferStatuses = ['InstantOfferAccepted', 'InstantOfferCompleted', 'InstantOfferLost'];

  if (pavePercentComplete === 100) {
    if (instantOfferStatuses.includes(vehicleStatus)) {
      return next(`/getPaid/${vehicleListingId}`);
    } else {
      return next(`/sellerAgreement/${vehicleListingId}`);
    }
  } else {
    if (to.name !== 'Take Photos') {
      return next(`/photos/${vehicleListingId}`);
    } else {
      next();
    }
  }

}

export function checkAuth(to: Route, _from: Route, next: NavigationGuardNext, cb?: () => void) {
  const isLoggedIn = store.getters.isAuthenticated;

  if (isLoggedIn && to.name === 'Login') {
    next(getRedirectPathByRole());
    return;
  }

  if (!isLoggedIn && to.name === 'Login') {
    next();
    return;
  }

  if (!isLoggedIn) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath,
      },
    });
    return;
  }

  if (cb) {
    cb();
    return;
  }

  next();
}

export function checkInspectorAuth(to: Route, from: Route, next: NavigationGuardNext) {
  checkAuth(to, from, next, () => {
    const { isUserInspector, isUserAdmin, isUserDsr } = store.getters;
    if (isUserInspector || isUserAdmin || isUserDsr) {
      next();
      return;
    }

    next(getRedirectPathByRole());
  });
}

export function checkSellerAuth(to: Route, from: Route, next: NavigationGuardNext) {
  checkAuth(to, from, next, () => {
    const { isUserSeller } = store.getters;
    if (isUserSeller) {
      next();
      return;
    }

    next(getRedirectPathByRole());
  });
}

export function checkAdminAuth(to: Route, from: Route, next: NavigationGuardNext) {
  checkAuth(to, from, next, () => {
    const { isUserAdmin, isUserDsr } = store.getters;
    if (isUserAdmin || isUserDsr) {
      next();
      return;
    }

    next(getRedirectPathByRole());
  });
}

export function checkStoreManagerAuth(to: Route, from: Route, next: NavigationGuardNext) {
  checkAuth(to, from, next, () => {
    const { isUserStoreManager } = store.getters;
    if (isUserStoreManager) {
      next();
      return;
    }

    next(getRedirectPathByRole());
  });
}

export function checkWithCustomRestriction(to: Route, from: Route, next: NavigationGuardNext) {
  const {
    isAuthenticated,
    isUserBuyer,
    isUserStoreManager,
    isUserCarmigoDirect,
    isUserAdmin,
    isUserDsr,
  } = store.getters as Record<string, boolean>;

  if (['Auctions', 'PastAuctions'].includes(to.name as string)) {
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    }

    if (!(isUserBuyer || isUserAdmin || isUserDsr || isUserStoreManager || isUserCarmigoDirect)) {
      next(getRedirectPathByRole());
    }

    next();
    return;
  }

  if (to.name === 'Get Estimate' && from.name === 'Select Vehicle') {
    store.commit('resetMyNewCar');
    store.commit('resetMyNewCarTemp');
  }

  if (to.name === 'Get Estimate' && (isUserBuyer || isUserStoreManager || isUserCarmigoDirect)) {
    next(getRedirectPathByRole());
    return;
  }

  next();
}

/**
 * @param forbiddenRoles -  if user has any of the forbidden roles, will redirect them using getRedirectPathByRole
 * @param redirectIfOnlyRole - optional (default false) - redirects only if one of the forbiddenRoles is the user's only role
 */
export function redirectForbiddenRoles(to: Route, from: Route, next: NavigationGuardNext, forbiddenRoles: Roles[], redirectIfOnlyRole: boolean=false) {
  const isLoggedIn = store.getters.isAuthenticated;

  if (!isLoggedIn) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath,
        source: to.query?.source ?? undefined,
      },
    });
    return;
  } 

  const hasForbiddenRole = hasRole(store.state.user, forbiddenRoles, redirectIfOnlyRole);
  if (hasForbiddenRole) {
    openToast('is-danger', 'Access denied');
    next(getRedirectPathByRole());
  } else {
    next();
  }
}

export function checkAuthAndIgnoreInstantOffer(to: Route, from: Route, next: NavigationGuardNext) {
  checkAuth(to, from, next, async() => {
    const vehicleListingId = to.params.vehicleListingId;
    return next(`/getMoreWithCarmigo/${vehicleListingId}`);
  });
}


export function getVehicleListingFilterParams(): string {
    return `:listingType?:savedFilter?:year?:make?:model?:trim?`;
} 
