var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TheCheckoutButton',_vm._b({},'TheCheckoutButton',{
        vehicleListingId: _vm.vehicleListingId,
        marketplaceListingId: _vm.marketplaceListingId,
        vehicleStatus: _vm.vehicleStatus,
        facilitatingAuctionStoreName: _vm.facilitatingAuctionStoreName,
        label: 'View Order Summary',
        type: 'is-grey',
        isReadOnly: !_vm.facilitatingAuctionStoreName,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }