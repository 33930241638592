<template>
    <div :class="`flex-${flexDirection} ${$attrs.disabled ? 'disabled' : ''}`" class="delete-buttons">
        <div 
            v-for="entry, idx in entries" 
            class="delete-button flex-row overflow-wrap align-center justify-space-between" 
            :class="styleType"
            :key="idx"
            @click="!$attrs.disabled ? emitClick($event, idx) : ''"
        >
            {{ entry }}
            <b-icon icon="close" size="is-small"/>
        </div>
    </div>
</template>

<script>
export default{
    name: "DeleteEntryButtons",
    props: {
        entries: {
            type: Array,
            required: true,
        },
        flexDirection: {
            type: 'row' | 'column',
            default: 'row',
        },
        styleType: {
            type: 'is-primary' | 'is-white',
            default: 'is-primary'
        }
    },
    emits: ['input', 'deleteEntryByIndex'],
    setup(props, { emit }) {
        function emitClick(value, idx) {
            emit('deleteEntryByIndex', idx);
            emit('input', value);
        }

        return {
            emitClick,
        }
    }
}
</script>

<style scoped lang="postcss">
.delete-buttons {
    display: flex;
    flex-wrap: wrap;

    &.disabled {
        opacity: 0.50;
    }
}
</style>
