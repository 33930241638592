var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheListingDetailsSellerDetails',_vm._b({scopedSlots:_vm._u([{key:"titleStatus",fn:function(){return [(_vm.isEditingMode)?_c('BuefyDropdown',_vm._b({staticClass:"width-100",attrs:{"initialValue":_vm.updatedFields.titleStatus},on:{"update:initialValue":function($event){return _vm.$set(_vm.updatedFields, "titleStatus", $event)},"update:initial-value":function($event){return _vm.$set(_vm.updatedFields, "titleStatus", $event)},"select":_vm.emitUpdatedValues}},'BuefyDropdown',{
                options: [
                    'With Seller',
                    'With Bank',
                    'Unknown',
                ],
                position: 'is-top-right',
                appendToBody: true,
                buttonAttrs: { expanded: true },
                expanded: true,
            },false)):_vm._e()]},proxy:true},{key:"sellerType",fn:function(){return [(_vm.isEditingMode)?_c('BuefyDropdown',_vm._b({staticClass:"width-100",attrs:{"initialValue":_vm.updatedFields.sellerType},on:{"update:initialValue":function($event){return _vm.$set(_vm.updatedFields, "sellerType", $event)},"update:initial-value":function($event){return _vm.$set(_vm.updatedFields, "sellerType", $event)},"select":_vm.emitUpdatedValues}},'BuefyDropdown',{
                options: [
                    'Individual',
                    'Dealer',
                    'Fleet',
                    'Bank',
                ],
                position: 'is-top-right',
                appendToBody: true,
                buttonAttrs: { expanded: true },
                expanded: true,
            },false)):_vm._e()]},proxy:true},{key:"sellerReason",fn:function(){return [(_vm.isEditingMode)?_c('EditableField',_vm._b({on:{"input":_vm.emitUpdatedValues},model:{value:(_vm.updatedFields.sellerReason),callback:function ($$v) {_vm.$set(_vm.updatedFields, "sellerReason", $$v)},expression:"updatedFields.sellerReason"}},'EditableField',{ customInputHandler: true },false)):_vm._e()]},proxy:true}])},'TheListingDetailsSellerDetails',{ ..._vm.$props, ..._vm.updatedFields, showAllDetails: true },false))
}
var staticRenderFns = []

export { render, staticRenderFns }