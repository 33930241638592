
import { computed, defineComponent, PropType } from 'vue';
import { getInputMasks } from '../utils';

export default defineComponent({
    name: 'AppInputFieldNumber',
    props: {
        type: {
            type: String as PropType<'number' | 'currency' | 'localeString'>, // Do not add to this
            default: 'number',
        },
        id: {
            type: String,
            required: false,
        }
    },
    emits: ['input', 'keypressEnter'],
    setup(props, context) {
        const getCleaveMask = computed(() => {
            const masks = getInputMasks();
            switch (props.type) {
                case 'number':
                default:
                    break;
                case 'currency':
                    return masks.numeral ? masks.numeral : {}
                case 'localeString':
                    return masks.numberThousands ?? {};
            }
        });

        return {
            getCleaveMask,
        }
    },
});
