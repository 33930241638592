var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppListingDetailsEditable',_vm._b({key:`inspector-vdp-${_vm.listingKey}`,on:{"input":function($event){_vm.addToUnsavedUpdates($event); _vm.updateListingFromUnsavedUpdates($event);},"updateVehiclePhotos":_vm.updateListingVehiclePhotos},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.isEditingMode)?_c('AppButton',_vm._b({on:{"click":function($event){return _vm.toggleEditingMode(false)}}},'AppButton',{
                label: 'Preview Listing',
                type: 'is-dark',
                expanded: true,
            },false)):_c('AppButton',_vm._b({on:{"click":function($event){return _vm.toggleEditingMode(true)}}},'AppButton',{
                label: 'Edit',
                iconLeft: 'pencil',
                type: 'is-dark',
                expanded: true,
                disabled: _vm.isDsrAndListingIsAuctioned,
            },false)),(_vm.hasUnsavedChanges)?_c('AppButton',_vm._b({on:{"click":function($event){return _vm.saveInspection();}}},'AppButton',{
                label: 'Save Edits',
                iconLeft: 'check-bold', 
                type: 'is-primary',
                loading: _vm.loadingSaveUpdates,
                expanded: true,
            },false)):_c('AppButton',_vm._b({on:{"click":function($event){return _vm.submit()}}},'AppButton',{
                label: 'Submit Listing',
                iconLeft: 'check-all',
                type: 'is-danger',
                disabled: _vm.loadingSaveUpdates,
                loading: _vm.loadingSubmitInspection,
                expanded: true,
            },false))]},proxy:true},{key:"carouselButtons",fn:function(){return [_c('div',{staticClass:"flex-row align-center justify-center mb-2"},[_c('h3',{staticClass:"mr-2"},[_vm._v("Assigned Inspector:")]),_c('TheAssignInspectorEditableField',_vm._b({},'TheAssignInspectorEditableField',{
                    vehicleListingId: _vm.vehicleListingId,
                    inspectorPersonId: _vm.listing.inspectorPersonId,
                },false))],1)]},proxy:true}])},'AppListingDetailsEditable',{
        isEditingMode: _vm.isEditingMode,
        listing: _vm.updatedListing,
        vehicleListingId: _vm.vehicleListingId,
        marketplaceListingId: _vm.marketplaceListingId,
        vehiclePhotos: _vm.vehiclePhotos,
        loadingVehiclePhotos: _vm.loadingVehiclePhotos,
        vehiclePhotosDTO: _vm.vehiclePhotosDTO,
        announcements: _vm.announcements,
        loadingAnnouncements: _vm.loadingAnnouncements,
        bookoutOptions: _vm.bookoutOptions,
        loadingBookoutOptions: _vm.loadingBookoutOptions,
        titleStatus: _vm.titleStatus,
        loadingTitleStatus: _vm.loadingTitleStatus,
        sellerReason: _vm.sellerReason,
        additionalPricingDetails: _vm.additionalPricingDetails,
        loadingAdditionalPricingDetails: _vm.loadingAdditionalPricingDetails,
        fixedButtons: true,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }