import { PropType } from 'vue';

export const BuyItNowModalProps = {
    vehicleListingId: {
        type: Number,
        required: true,
    },
    buyItNowPrice: {
        type: Number,
        required: true,
    },
    stores: {
        type: Array as PropType<{ id: string, name: string, zip?: string }[]>,
        required: true,
    },
    initialStoreId: {
        type: Number,
        required: false,
    },
    loadingSubmit: {
        type: Boolean,
        required: false,
    }
}