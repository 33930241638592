<template>
    <Modal
        customButtons
        :buttonsMiddle='buttonsMiddle'
        class="carmigo-modal"
        @close="$emit('close')"
    >
        <template #header>
            Vehicle Recently Listed
        </template>

        <template #body>
            <!-- Title (Image/vehicleInfo) -->
            <div class='relistedTitleContainer'>
                <!-- Image -->
                <div class='hoverEffect' field='image' width='100'>
                    <img
                        :src='
                        vehicleHeroImageUrl
                        '
                        alt='Picture of a car'
                        class='image-thumbnail small'
                    />
                </div>

                <!-- Basic Info -->
                <div class='hoverEffect'>
                    <div class='vehicleInfoText'>
                        <h1>
                            {{ year }} {{ make }} {{ model }}
                        </h1>
                        <div class='vinContainer'>
                            <p>
                                {{ vin }}
                            </p>
                            <CopyTextButton
                                :iconOnly='true'
                                :copyText='vin'
                                :displayText='vin'
                                style='margin-left: 10px; height: 18px;'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- confirmationScreen = false -->
            <div v-if='!confirmationScreen'>  
                <!-- Date -->
                <div class='centerContents'>
                    <p class='padding'>
                        This vehicle went to auction on {{ auctionStartDate.toLocaleDateString('en-US') }}
                    </p>
                </div>
                <!-- Seller Details Card -->
                <div>
                    <UserDetailsCard
                        :firstName='sellerFirstName'
                        :lastName='sellerLastName'
                        :phoneNumber='sellerPhoneNumber'
                        :email='sellerEmail'
                    />
                </div>
                <!-- Bottom Text -->
                <div class='centerContents'>
                    <p class='footerText'>
                        Would you like to proceed with the inspection?
                    </p>
                </div>
            </div>
            <!-- confirmationScreen = true -->
            <div v-else>
                <div class='centerContents'>
                    <p class='padding'>
                        Are you sure you want to change the status of vehicle {{ vehicleListingId }} to <span class='bold'>Inactive</span>?
                    </p>
                </div>
            </div>
        </template>
        <!-- Buttons -->
        <template  v-if='!confirmationScreen' #buttonsMiddle>
            <div 
                class='buttonContainer'
               
            >
                <b-button
                    label='Mark Vehicle Inactive'
                    class='buttonClass marginBottom'
                    @click="confirmationScreen = true; buttonsMiddle = false"
                    type='is-danger'
                />
                <b-button
                    label='Continue Inspection >'
                    class='buttonClass'
                    @click='$emit("close")'
                    type='is-primary'
                />
            </div>
        </template>
        <template v-else #buttonsRight>

                <b-button
                    label='Cancel'
                    @click='confirmationScreen = false; buttonsMiddle = true'
                    type='is-danger'
                    :loading='isLoading'
                />
                <b-button
                    label='Mark Inactive'
                    @click='markInactive()'
                    type='is-primary'
                    :loading='isLoading'
                />

        </template>
    </Modal>
</template>

<script>
import Modal from './Modal.vue';
import CopyTextButton from './CopyTextButton.vue';
import UserDetailsCard from './UserDetailsCard.vue';
import { PUT } from '../api';
import router from '@/router';

export default {
    name: 'VehicleRecentlyRelistedModal',
    components: {
        Modal,
        CopyTextButton,
        UserDetailsCard,
    },
    props: {
        make: {
            type: String,
            required: true,
            default: ''
        },
        model: {
            type: String,
            required: true,
            default: ''
        },
        year: {
            type: String,
            required: true,
            default: ''
        },
        vin: {
            type: String,
            required: true,
            default: ''
        },
        sellerFirstName: {
            type: String,
            required: true,
            default: ''
        },
        sellerLastName: {
            type: String,
            required: true,
            default: ''
        },
        sellerEmail: {
            type: String,
            required: true,
            default: ''
        },
        sellerPhoneNumber: {
            type: String,
            required: false,
            default: ''
        },
        auctionStartDate: {
            type: Date,
            required: true,
        },
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehicleHeroImageUrl: {
            type: String,
            required: true,
        }

    },
    data() {
        return {
            confirmationScreen: false,
            isLoading: false,
            buttonsMiddle: true
        }
    },
    methods: {
        async markInactive() {
            this.isLoading = true;
            await PUT('/vehicles/updateStatus', {
                vehicleId: this.vehicleListingId.toString(),
                status: 'Inactive'
            })
            .catch(() => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'Failed to mark Inactive, please try again',
                    type: 'is-danger',
                })
            })
            .then(() => {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `Vehicle ${this.vehicleListingId} has been marked as inactive`,
                    type: 'is-success',
                })
                this.$emit('close')
                router.push({
                    name: 'Inspector',
                });
            })
            .finally(() => this.isLoading = false);            
        },
    }
}
</script>

<style scoped lang='postcss'>
p {
    font-family: 'Poppins', sans-serif;
}
span {
    font-family: 'Poppins', sans-serif;
}
.relistedTitleContainer {
    display: flex;
}
.vehicleInfoText {
    & h1 {
        font-weight: 700;
        font-size: 1.1rem;
    }

    & .vinContainer {
        display: flex;
        & p {
            font-size: 0.9rem;
        }
    }
    @media screen and (max-width: 315px) {
      & h1 {
        font-size: 0.75rem;
      }

      & .vinContainer {
        & p {
            font-size: 0.65rem;
        }
    }
    }
}
.centerContents {
    display: flex;
    justify-content: center;

     & p {
        font-size: 1.1rem;
     }
}
.padding {
    padding: 1rem;
}
.footerText {
    font-weight: 600;
    padding: 1rem 1rem 0 1rem;
}
.buttonContainer {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 428px) {
      flex-direction: column;
    }
}
.marginBottom {
    margin-bottom: 5px;
}
.buttonClass {
    width: 200px;
}

</style>