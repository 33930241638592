
import { computed, defineComponent, PropType } from 'vue';
import { useAuctionNegotiateButton, useLoggedInUser } from '../composables';
import { Negotiation } from '../types';
import { CurrentNegotiationDTO } from '../types/CurrentNegotiationDTO';

import TheNegotiateButton from './TheNegotiateButton.vue';

export default defineComponent({
    name: 'TheNegotiateButtonAuction',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vin: {
            type: String,
            required: true,
        },
        negotiationHistory: {
            type: Array as PropType<Negotiation[]>, 
            required: false,
        },
        negotiationOffer: {
            type: Object as PropType<CurrentNegotiationDTO>,
            required: false,
        },
    },
    components: {
        TheNegotiateButton,
    },
    setup(props, { emit, attrs }) {
        const { loggedInUserPersonId } = useLoggedInUser();

        const {
            openNegotiationModal,
            isOfferPending,
            adminButtonLabel,
            negotiatingUser,
        } = useAuctionNegotiateButton({
            negotiatingPersonId: loggedInUserPersonId,
            negotiationOffer: computed(() => props.negotiationOffer),
        });

        return {
            adminButtonLabel, 
            isOfferPending,
            openNegotiationModal,
            negotiatingUser,
        }
    },
});
