
import { computed, defineComponent, ref, Ref } from 'vue';
import { useBreakpoint, useImageMagnifyingGlass } from '../composables';

import AppButton from './AppButton.vue';
import AppPinchZoom from './AppPinchZoom.vue';

export default defineComponent({
    name: 'AppImageMagnifier',
    props: {
        id: {
            type: String,
            default: 'magnify-img',
        },
        enableZoom: {
            type: Boolean,
            default: true,
        },
        height: {
            type: Number,
            default: 200,
        },
        width: {
            type: Number,
            default: 200,
        },
        zoomFactor: {
            type: Number,
            default: 2,
        },
        zoomImageUrl: {
            type: String,
            required: false,
        },
        useShowMagnifyingGlassButton: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppButton,
        AppPinchZoom,
    },
    emits: ['update:isZoomedIn'],
    setup(props, { emit, attrs }) {
        const imageLoaded = ref(false);
        const { bulmaBreakpoint } = useBreakpoint();

        const { addMagnifyingGlass, hideMagnifyingGlass } = useImageMagnifyingGlass({
            imageId: props.id,
            enableZoom: props.enableZoom,
            zoomFactor: props.zoomFactor,
            height: props.height,
            width: props.width,
            zoomImageUrl: props.zoomImageUrl,
        });

        const isZoomedIn: Ref<boolean> = ref(false);
        function hideMagnifyingGlassOnZoom(value) {
            emit('update:isZoomedIn', value);
            isZoomedIn.value = value;
            if (isZoomedIn.value) {
                hideMagnifyingGlass();
            }
        }

        const magnifyingGlassDisabled = ref(!props.enableZoom);
        if (props.useShowMagnifyingGlassButton) {
            magnifyingGlassDisabled.value = true;
        }

        const useMagnifyingGlass = computed(() => {
            return !isZoomedIn.value && !bulmaBreakpoint.value.mobile && !magnifyingGlassDisabled.value;
        });
        
        function toggleMagnifyingGlass() {
            magnifyingGlassDisabled.value = !magnifyingGlassDisabled.value;
            if (magnifyingGlassDisabled.value) {
                hideMagnifyingGlass();
            }
        }

        return {
            addMagnifyingGlass,
            hideMagnifyingGlass,
            hideMagnifyingGlassOnZoom,
            isZoomedIn,
            bulmaBreakpoint,
            useMagnifyingGlass,
            magnifyingGlassDisabled,
            imageLoaded,
            toggleMagnifyingGlass,
        }
    },
});
