var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"editable-field flex-row align-center"},[(!_vm.isEditing)?_c('div',{staticClass:"display-selected-value",on:{"click":function($event){_vm.isEditing = true}}},[_vm._t("selected",function(){return [(_vm.selectedValue)?_c('span',{staticClass:"selected-value hover has-text-primary"},[_vm._t("selectedValue",function(){return [_vm._v(_vm._s(_vm.selectedValue[_vm.displayKey]))]},null,{ value: _vm.selectedValue })],2):_c('span',{staticClass:"selected-value hover has-text-danger"},[_vm._v(" Select ")])]})],2):_c('div',{staticClass:"edit-selected-value"},[_vm._t("input",null,null,{ 
                selectedValue: _vm.selectedValue, 
                options: _vm.options,
                onSelect: (value) => {
                    _vm.selectedValue = value;
                    _vm.$emit('select', value);
                    _vm.$emit('update:value', value);
                    _vm.isEditing = false;
                }
            })],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }