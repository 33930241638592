import { database } from '@/firebase';
import { onMounted, onUnmounted, shallowRef } from 'vue';

export type DatabaseReference = ReturnType<typeof database['ref']>

export function useRdbListener<T>(path: string, onSnapshot: (payload: {
  isInitialSnapshot: boolean
  oldSnapshotValue: T | null
  newSnapshotValue: T | null
}) => void) {
    const dbRef = shallowRef<DatabaseReference | null>(null);
    const snapshotValue = shallowRef<T | null>(null);
    let listener: ReturnType<DatabaseReference['on']> | null = null;

    function onChange() {
        dbRef.value = database.ref(path);
        listener = dbRef.value.on('value', (snapshot) => {
            if (!snapshot.exists()) {
                return;
            }

            const oldValue = snapshotValue.value as T | null;
            const newValue = snapshot.val() as T;
            onSnapshot({
                isInitialSnapshot: !oldValue,
                oldSnapshotValue: oldValue,
                newSnapshotValue: newValue,
            });
            snapshotValue.value = newValue;
        });
    }

    onMounted(() => {
        onChange();
    });

    onUnmounted(() => {
        if (listener) {
            dbRef.value?.off('value', listener);
        }
    });
}
