<template>
      <div ref="modal" id="negotiations-modal" class="modal-card" style="height: auto; min-width: auto; max-width: 500px; margin: auto; overflow-y: auto;">
        <header class="modal-card-head flex-row justify-space-between">
            <p class="modal-card-title">
                {{ headerText }}
            </p>
            <button type="button" class="delete" @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <div>VIN: {{ vin }}</div>
          <div class="text-center">
            <!-- Negotiation rejected -->
            <div v-if="negotiationData.status==`rejected`" 
              class="rejected negotiation-card flex-column text-center">
              <p>The {{ otherUser }} has rejected your offer of {{ yourOffer | currency }}.</p>
              <p class="bold">You can either <span style="color: #52aa57;">accept</span> their final offer of {{ theirOffer | currency }}, 
                <br>or <span style="color: #ce3b3e;">reject</span> it and end negotiations.</p>
            </div>

            <!-- Offer details -->
            <div class="negotiation-details flex-row justify-center">
              <div class="offer negotiation-card flex-column justify-center">
                <div class="offer-text thinner">
                  {{ yourText }}
                </div>
                <div class="bold is-size-5">
                  {{ yourOffer | currency }}
                </div>
              </div>

              <div class="offer negotiation-card flex-column justify-center">
                <div class="offer-text thinner">
                  {{ theirText }}
                </div>
                <div class="bold is-size-5">
                  {{ theirOffer | currency }}
                </div>
              </div>
            </div>

            <div v-if="displayProxyBid" class="margin-1-em thinner">
              <span class="highest-proxy">Your highest proxy bid was </span>
              <span class="bold is-size-5">{{ highestProxyBid | currency }}</span>
            </div>

            <div class="negotiate-buttons flex-column width-100">
                <b-button 
                    type="is-primary" 
                    class="negotiate-button bold"
                    @click="acceptOffer">
                    <div class="button-label">
                        <b-icon icon="check-bold" size="is-small"/>
                        Accept {{ otherUserCap }}'s Offer
                    </div>
                </b-button>
                <b-button 
                    type="is-danger" 
                    class="negotiate-button bold"
                    @click="rejectOffer">
                    <div class="button-label">
                        <b-icon icon="close-thick" size="is-small"/>
                        Reject Offer
                    </div>
                </b-button>
                <b-button 
                    v-if="negotiationData.status!==`rejected`"
                    type="is-dark" 
                    class="negotiate-button bold"
                    @click="makeCounterOffer">
                    <div class="button-label">
                        <b-icon icon="reply" size="is-small"/>
                        Make a Counter Offer
                    </div>
                </b-button>

                <section id="counter-offer">
                  <div v-if="makingCounterOffer" class="counteroffer">
                    <h3 class="counteroffer-title">Submit a Counter Offer</h3>
                    <div class="counteroffer-text" >
                      <span class="counteroffer-amounts">
                        <b :class="{'red': offerTooLow}">greater than {{ negotiationData.highestBuyerOffer | currency}}</b> and
                        <b :class="{'red': offerTooHigh}">less than {{ negotiationData.lowestSellerOffer | currency}}</b>
                      </span>
                    </div>
                    <div class="counteroffer-input flex-row">
                        <b-numberinput class="width-100"
                            id="counteroffer-input"
                            :placeholder="counterOfferPlaceholder"
                            :controls="false"
                            v-model="counterOfferInput"
                            :disabled="negotiationData.status=='rejected'"
                            icon="currency-usd"
                        />
                        <b-button label="Send Offer" type="is-primary" class="bold"
                          :disabled="disableCounterOffer"
                            @click="submitCounterOffer"
                        />
                    </div>
                    <div
                      v-if="isUserAdmin || isUserDsr" 
                      class="disableNotificationsCheckboxContainer"
                    >
                      <b-checkbox
                        v-model="disableNotifications"
                        type='is-primary'
                        :native-value="disableNotifications"
                      >
                        Disable notifications
                      </b-checkbox>
                    </div>
                  </div>
                </section>
            </div>
          </div>
        </section>
        
        <footer class="modal-card-foot flex-row justify-end">
            <b-button label="Close" @click="$emit('close')" />
        </footer>
      </div>
</template>

<script>
import {
  isUserAdmin, isUserDsr, capitalizeWord, scrollToElement, focusOnElement, formatDollarAmount
} from "../utils";
export default {
  name: "Negotiations",
  props: [
    'negotiationData', 
    'firstNegotiation', 
    'highestProxyBid',
    'negotiatingFor',
    'vin',
  ],
  data() {
    return {
      counterOffer: '',
      rejected: false,
      onMe: false,
      onBuyer: this.negotiationData.onBuyer,
      sellerOffer: this.negotiationData.lowestSellerOffer,
      buyerOffer: this.negotiationData.highestBuyerOffer,
      makingCounterOffer: false,
      counterOfferPlaceholder: '',
      counterOfferInput: null,
      disableNotifications: true,
    }
  },
  mounted() {
    this.onMe = !this.onBuyer
    const role = this.$store.state.user.profile.role
    if (role.includes('buyer')) this.onMe = this.onBuyer
    if (this.onBuyer) this.counterOffer = this.negotiationData.highestBuyerOffer
    if (!this.onBuyer) this.counterOffer = this.negotiationData.lowestSellerOffer

    this.rejected = this.negotiationData.status === 'rejected'
  },
  methods: {
    scrollToElement,
    focusOnElement,
    acceptOffer() {
        this.makingCounterOffer = false;
        var purchaseText = 'The vehicle will be purchased for'
        if (this.onBuyer) purchaseText = 'You will purchase the vehicle for'
        const message = `Please confirm your acceptance of the ${this.otherUser}'s offer. 
        <br><br> ${purchaseText} <b>${formatDollarAmount(this.offerAmount)}</b>.`
        const title = 'Accept Offer'
        const onConfirm = () => {
            this.$emit('accept')
            this.$emit('close')
        }
        this.openConfirmDialog(title, message, onConfirm)
    },
    rejectOffer() {
        this.makingCounterOffer = false;
        var title;
        var message;
        var onConfirm;
        var rejectionType = 'firstRejection'
        if (this.rejected) rejectionType = 'rejectFinalOffer'
        switch(rejectionType) {
            case "firstRejection":
                title = "Reject Offer"
                message = `Please confirm your rejection of the ${this.otherUser}'s offer of <b>${formatDollarAmount(this.offerAmount)}</b>.
                <br><br>
                Your final offer of <b>${formatDollarAmount(this.finalOffer)}</b> will be sent to the ${this.otherUser} to accept or reject, and <b>negotiations will end</b>.`
                onConfirm = () => {
                    this.$emit('reject')
                    this.$emit('close')
                }
                break;
            case "rejectFinalOffer":
                title = "Reject Final Offer"
                message = `Please confirm your rejection of the ${this.otherUser}'s final offer of <b>${formatDollarAmount(this.offerAmount)}</b>. 
                <br><br>
                <b>Negotiations will end and the vehicle will not be purchased.</b>`
                onConfirm = () => {
                    this.$emit('rejectConfirm')
                    this.$emit('close')
                }
                break;
        }
        this.openDangerConfirmDialog(title, message, onConfirm)
    },
    makeCounterOffer() {
      this.makingCounterOffer = true;
      this.scrollAndFocusCounteroffer()
    },

    submitCounterOffer() {
      let counterOffer = {
        counterOffer: this.counterOfferInput,
        disableNotifications: false
      }

      if (this.isUserAdmin || this.isUserDsr) {
        counterOffer.disableNotifications = this.disableNotifications;
      }
      this.$emit('counter', counterOffer);
      this.$emit('close');
    },

    openConfirmDialog(title, message, onConfirm) {
        this.$buefy.dialog.confirm({
            title: `Confirm ${title}`,
            message,
            confirmText: title,
            type: 'is-primary',
            hasIcon: true,
            cancelText: 'Cancel',
            onConfirm,
        })
    },

    openDangerConfirmDialog(title, message, onConfirm) {
        this.$buefy.dialog.confirm({
            title: `Confirm ${title}`,
            message,
            confirmText: title,
            type: 'is-danger',
            hasIcon: true,
            cancelText: 'Cancel',
            onConfirm,
        })
    },

    scrollAndFocusCounteroffer() {
      var counteroffer = document.getElementById('counter-offer')
      const interval = setInterval(() => {
        counteroffer = document.getElementById('counter-offer')
        if (counteroffer) {
          clearInterval(interval)
          this.scrollToElement('counter-offer')
          this.focusOnElement('counteroffer-input')
        }
      }, 100)
    },

  },
  computed: {
    isUserAdmin,
    isUserDsr,
    headerText() {
      if (this.isUserAdmin || this.isUserDsr) return `Negotiate for ${this.negotiatingFor}`
      return `Negotiate with ${this.otherUser}`
    },
    offerAmount() {
      if (this.onBuyer) return this.negotiationData.lowestSellerOffer
      return this.negotiationData.highestBuyerOffer
    },
    otherUserCap() {
      return capitalizeWord(this.otherUser)
    },
    otherUser() {
      if (this.negotiationData.onBuyer) return `seller`
      return 'buyer'
    },
    displayProxyBid() {
      if (!this.onBuyer) return false
      return this.highestProxyBid > this.negotiationData.highestBuyerOffer
    },
    counterOfferAmount() {
      if (this.onBuyer) return this.negotiationData.highestBuyerOffer
      return this.negotiationData.lowestSellerOffer
    },
    amountRejected() {
        if (this.onBuyer) return this.negotiationData.highestBuyerOffer
        return this.negotiationData.lowestSellerOffer
    },
    finalOffer() {
        if (this.onBuyer) return this.negotiationData.highestBuyerOffer
        return this.negotiationData.lowestSellerOffer
    },
    buyerText() {
        if (this.firstNegotiation) return 'Highest Bid'
        return 'Offer'
    },
    sellerText(){
        if (this.firstNegotiation) return 'Minimum'
        return 'Offer'
    },
    yourText() {
        if (this.onBuyer) return `Your ${this.buyerText}`
        return `Your ${this.sellerText}`
    },
    theirText() {
      var final = ''
      if (this.rejected) final = 'Final'
      if (this.onBuyer) return `${this.otherUserCap}'s ${final} ${this.sellerText}`
      return `${this.otherUserCap}'s ${final} ${this.buyerText}`
    },
    yourOffer() {
        if (this.onBuyer) return this.negotiationData.highestBuyerOffer
        return this.negotiationData.lowestSellerOffer
    },
    theirOffer() {
        if (this.onBuyer) return this.negotiationData.lowestSellerOffer
        return this.negotiationData.highestBuyerOffer
    },

    disableCounterOffer() {
      const tooLow = this.counterOfferInput < this.negotiationData.highestBuyerOffer 
      const tooHigh = this.counterOfferInput > this.negotiationData.lowestSellerOffer
      return tooLow || tooHigh
    },
    offerTooLow() {
      return this.counterOfferInput < this.negotiationData.highestBuyerOffer 
    },
    offerTooHigh() {
      return this.counterOfferInput > this.negotiationData.lowestSellerOffer
    }
  }
} 
</script>

<style scoped lang="postcss">
.negotiation-details {
  width: 100%;

}
.highest-proxy {
  margin-right: .25rem;
}

.negotiation-card {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin: .25rem;
  padding: .5rem;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

  & .offer-text {
    margin-bottom: .5rem;
  }

  &.offer {
    width: 99%;
    height: 100px;
  }

  &.rejected {
    padding: 1rem;
    margin-bottom: .5rem;
    box-shadow: none;

    & p {
      margin-bottom: .5rem;
    }
  }
}

.negotiate-buttons {
    margin: 1rem 0;
    & .negotiate-button {
        margin: .2rem 0;
        padding: 1.25rem;

        & .button-label {
            width: 200px;
            text-align: left;

            & .icon {
                margin-right: .25rem;
            }
        }
    }  
}

.counteroffer {
    margin: 1rem;
    margin-bottom: 0;
    border-top: 1px solid #363636;

    & .counteroffer-title {
        margin: .5rem;
        margin-top: 1rem;
        font-size: 1.25rem;
        font-weight: 300;
    }

    & .counteroffer-input {
        margin: 1rem;
        margin-bottom: 0;

        & .button {
            margin-left: .5rem;
            width: 200px;
        }
    }

    & .red {
      color: #ce3b3e;
    }
}

@media screen and (min-width: 500px) {
  #negotiations-modal {
    min-width: 500px !important;
    width: 500px ;
  }
}

@media screen and (max-width: 400px) {
  .negotiation-details {
    flex-direction: column !important;
  }
}


@media screen and (max-width: 450px) {
    .counteroffer {
        & .counteroffer-text {
            display: flex;
            flex-direction: column;
        }

        & .counteroffer-input {
            flex-direction: column !important;

            & .button {
                width: 100% !important;
                margin-left: 0;
            }
        }
    } 
}


</style>
