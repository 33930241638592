
import { defineComponent, PropType } from 'vue';
import { InAppNotificationConfig } from '../types';

import AppButton from './AppButton.vue';

export default defineComponent({
    name: 'AppNotification',
    props: {
        notification: {
            type: Object as PropType<InAppNotificationConfig>
        }
    },
    components: {
        AppButton,
    },
    emits: ['close', 'onAction'],
});
