
import { defineComponent, PropType } from 'vue';
export default defineComponent({
    name: 'AppFlag',
    props: {
        title: {
            type: String,
            required: true,
        },
    }
});
