
import Vue from 'vue';
import {
  validateText, validateEmail, validatePhone, phoneFormatter, formattedPhoneNumberToDigits,
} from '@/validation';
import { addManager } from '../api';

import AppInputFieldString from './AppInputFieldString.vue';
import Modal from './Modal.vue';
import AppInputFieldPhoneNumber from './AppInputFieldPhoneNumber.vue';
import AppInputFieldEmail from './AppInputFieldEmail.vue';
import Loading from './ComponentLoading.vue';

export default Vue.extend({
  name: 'AddManager',
  props: ['companyId', 'canCancel'],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      shouldValidate: {
        firstName: false,
        lastName: false,
        email: false,
        phoneNumber: false,
      },
      loading: false,
    };
  },
  components: {
    AppInputFieldString,
    Modal,
    AppInputFieldPhoneNumber,
    AppInputFieldEmail,
    Loading,
  },
  computed: {
    validateForm(): boolean {
      return validateText(this.firstName)
        && validateText(this.lastName)
        && validateEmail(this.email)
        && validatePhone(this.phoneNumber);
    },
  },
  methods: {
    validateText,
    phoneFormatter,
    validatePhone,
    validateEmail,
    setPhoneNumber(phoneNumberInput: string) {
      this.phoneNumber = phoneNumberInput;
    },
    setEmailAddress(emailInput: string) {
      this.email = emailInput;
    },
    addManagerToCompany() {
      this.loading = true;
      const managerObj = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: formattedPhoneNumberToDigits(this.phoneNumber),
        companyId: this.companyId,
      };
      addManager(managerObj)
        .then(() => {
          this.loading = false;
          this.$buefy.toast.open({
            message: 'Successfully added the manager!',
            type: 'is-success',
          });
          this.$emit('close');
        })
        .then(() => this.$emit('getCompanies'))
        .catch((error) => {
          if (error.response.data.error.constraint === 'phone_number_unique') {
            this.$buefy.toast.open({
            message: error.response.data.message,
            duration: 3000,
            type: 'is-danger',
            });
            this.phoneNumber = '';
          }else{
            this.$buefy.toast.open({
              message: error.response.data.message,
              duration: 3000,
              type: 'is-danger',
            });
          }
          this.loading = false;
        });
    },
    clearFirstName() {
      this.firstName = '';
    },
    clearLastName() {
      this.lastName = '';
    },
  },
});
