
import { openToast } from '@/utils';
import { validateZip } from '../validation';

export default {
    name: 'AppManageZip',
    data() {
        return {
            zipValue: '',
            validated: false,
            loading: false,
            recentlySaved: false,
            displayErrorMessage: false,
        };
    },
    beforeMount() {
        this.zipValue = this.$store.getters.getTransportationZip;
    },
    methods: {
        openToast,
        validateZipInput() {
            this.zipValue = this.zipValue.replace(/\D/g, '');
            this.validated = validateZip(this.zipValue, true);
        },
        zipIsReadyToSave() {
            return (this.zipValue !== this.$store.getters.getTransportationZip && this.validated);
        },
        setNewZipCode() {
            try {
                if (this.$route.name === 'Auction Details') {
                    this.openToast('is-success', 'Updating estimated transport cost...');
                }
                this.$store.commit('updateTransportation', { zip: this.zipValue });
                this.recentlySaved = true;
                setTimeout(() => this.recentlySaved = false, 3000);
            } catch (error: any) {
                console.error('error: ', error);
                this.displayErrorMessage = true;
                this.recentlySaved = false;
                setTimeout(() => this.displayErrorMessage = false, 5000);
            };
        }
    },
    computed: {
        setSaveButtonLabel() {
            return (!this.loading && !this.recentlySaved && !this.displayErrorMessage) ? 'Save' : '';
        },
        disableSaveButton() {
            return (!this.zipIsReadyToSave() && !this.loading && !this.recentlySaved);
        },
        setButtonIcon() {
            if (this.displayErrorMessage) {
                return 'refresh';
            }
            if (!this.loading && this.recentlySaved) {
                return 'check';
            } 

            return '';
        },
    },

}
