import { APIConfig } from "@/types";
import { negotiationgStatus } from "@/types/NegotiationStatus";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, openErrorDialog, openToast, toCurrency } from "@/utils";
import { capitalize } from "lodash";
import { PUT } from ".";

export async function submitNegotiation(vehicleListingId: number, payload: {
    buyerId: string, // person ID
    sellerId: string, // person ID
    highestBuyerOffer: number,
    lowestSellerOffer: number,
    onBuyer: boolean,
    status: negotiationgStatus,
    disableNotifications?: boolean,
}, config: APIConfig={}) {
    return await PUT(`/vehicles/negotiating/${vehicleListingId}`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            console.error(error);
            applyAPIConfigOnError(error, config);
            const celebrateValidationError = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Could not complete negotiation', 
                message: celebrateValidationError ?? `We encountered an error while updating negotiations for vehicle ${vehicleListingId}. 
                    Update attempted: ${capitalize(payload.status)} 
                    with buyer offer of ${toCurrency(payload.highestBuyerOffer)}
                    and seller offer of ${toCurrency(payload.lowestSellerOffer)}`,
                error,
            });
        });
}

export async function getNegotiationAutocompleteOptionsForSellerOrBuyerName(searchValue: string, config: APIConfig={}) {
    return await PUT(`/vehicles/negotiations/autoComplete/SellerOrBuyerName`, { fullName: searchValue })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error);
            openToast('is-danger', `We couldn't fetch autocomplete results for name: '${searchValue}'`)
        });
}

export async function getNegotiationAutocompleteOptionsForStoreName(searchValue: string, config: APIConfig={}) {
    return await PUT(`/vehicles/negotiations/autoComplete/SellerOrBuyerStoreName`, { storeName: searchValue })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error);
            openToast('is-danger', `We couldn't fetch autocomplete results for store name: '${searchValue}'`);
        });
}

export async function getCurrentNegotiationOffer(vehicleListingId: number, config: APIConfig={}) {
    return await PUT(`/vehicles/${vehicleListingId}/currentNegotiationBid`, {}, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            const useDefaultErrorHandling = applyAPIConfigOnError(error, config);
            if (useDefaultErrorHandling) {
                openToast('is-danger', `We couldn't fetch the current negotiation offer for vehicle ${vehicleListingId}`);
            }
        });
}