<template>
    <div class="editable-table-field-container">
        <div 
            v-if="!editingField"
            @click.stop="clickToEdit ? editField() : ''"
            :class="edited ? 'has-text-primary' : 'has-text-dark'"
        >
            <slot name="field">
                <p>{{ filter ? filter(value) : value }}</p>
            </slot>
        </div>

        <DateTimePicker 
            v-else-if="type==='date'"
            :initialValue="value ? new Date(value) : value"
            :returnSelectedTime="saveDateInput"
            :isInModal="isInModal"
            @blur="saveOnBlur ? saveDateInput(null, value) : editingField = false"
            openOnMount
        />

        <InputField 
            v-else-if="['dropdown', 'text', 'currency'].includes(type)"
            v-bind="$attrs"
            v-model="editingValue"
            :id="id"
            :useCustomStyles="false"
            :type="type"
            @input="$emit('input', $event)"
            @invalidInput="$emit('invalidInput', $event)"
            @blur="checkForNextFocusedElement"
            @keyup.native.enter="saveOnBlur ? saveInput : $emit('enter', editingValue)"
            @keypressEnter="$emit('keypressEnter', editingValue); editingField = false;"
        />
        
        <b-input 
            v-else
            :id="id"
            :type="type"
            v-model="editingValue"
            @input="$emit('input', $event)"
            @keyup.native.enter="saveOnBlur ? saveInput : undefined"
            @keypress.native.enter="$emit('keypressEnter', editingValue); editingField = false"
            @blur="checkForNextFocusedElement"
        />

        <div v-if="editingField">
            <slot name="secondaryInput" v-bind="{ checkForNextFocusedElement, primaryInputValue: editingValue, toggleEditing }" />
        </div>
    </div>
</template>

<script>
import DateTimePicker from '../views/Admin/DateTimePicker.vue';
import InputField from './InputField.vue';

import { focusOnElement } from '../utils';

export default {
    name: 'EditableTableField',
    props: {
        rowIdx: {
            type: Number,
            required: false,
        },
        value: {
            required: false,
            type: Number | String | Date
        },
        clickToEdit: {
            type: Boolean,
            default: true,
        },
        edited: {
            type: Boolean,
            required: false,
            default: false,
        },
        id: {
            type: String,
            required: false,
            default: 'edit-table-value'
        },
        type: {
            type: String,
            required: false,
            default: 'text',
            validator: (prop) => {
                return [
                    'text',
                    'number',
                    'date',
                    'select',
                    'dropdown',
                    'currency',
                ].includes(prop);
            }
        },
        isInModal: {
            type: Boolean,
            default: false,
        },
        editingFieldInitial: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Function,
            required: false,
        },
        saveOnBlur: {
            type: Boolean,
            default: true,
        }
    },
    emits: ['input', 'blur', 'invalidInput', 'keypressEnter', 'enter', 'editing'],
    components: {
        DateTimePicker,
        InputField,
    },
    data() {
        return {
            editingField: this.editingFieldInitial,
            editingValue: this.value,
        }
    },
    methods: {
        saveInput() {
            if (!this.type === 'date' && (!this.editingValue || !this.editingField)) {
                this.editingField = false;
                return;
            }

            const valueChanged = this.type === 'date' && this.editingValue
                ? new Date(this.editingValue).getTime() != new Date(this.value).getTime()
                : this.editingValue != this.value;

            if (valueChanged || (this.type === 'date' && !this.editingValue)) {
                this.$emit('input', this.editingValue);
            }
            this.editingField = false;
        },
        editField() {
            this.$emit('editing', true);
            this.editingField = true;
            window.setTimeout(() => {
                focusOnElement(this.id);
            }, 300);
        },
        saveDateInput(label, value) {
            this.editingValue = value;
            this.editingField = false;
            this.saveInput();
        },
        checkForNextFocusedElement() {
            window.setTimeout(() => {
                let activeElement = document.activeElement;
                if (activeElement && Array.from(activeElement.classList).includes('input')) {
                    return;
                }
                if (this.saveOnBlur) {
                    this.saveInput();
                }
                this.editingField = false;
                this.$emit('blur');
            }, 200)
        },
        toggleEditing() {
            this.editingField = !this.editingField;
        }
    },
}
</script>
