export const EditAuctionProps = {
    vehicleListingId: {
        type: Number,
        required: true, 
    },
    startingPrice: {
        type: Number,
        required: false,
    },
    reservePrice: {
        type: Number,
        required: false,
    },
    buyItNowPrice: {
        type: Number,
        required: false,
    },
    canBuyItNow: {
        type: Boolean,
        default: false,
    },
    auctionStart: {
        type: Date,
        required: false,
    },
    auctionEnd: {
        type: Date,
        required: false,
    },
}
