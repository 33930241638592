var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.submitting)?_c('Loading'):_vm._e(),_c('div',{staticClass:"datepickers-container"},[_c('DateTimePicker',_vm._b({staticClass:"datepicker"},'DateTimePicker',{
                ..._vm.$attrs,
                ..._vm.$attrs.startTimeAttrs,
                label: 'Start Time',
                placeholder: 'Click to select the auction start time',
                minPickableTime: _vm.minPickableStartTime,
                returnSelectedTime: _vm.getSelectedTime,
                initialValue: _vm.initialStartTime ? _vm.initialStartTime : null,
                timepicker: _vm.timepicker,
            },false)),_c('DateTimePicker',_vm._b({key:`endtime-${_vm.updateKey}`,staticClass:"datepicker"},'DateTimePicker',{
                ..._vm.$attrs,
                ..._vm.$attrs.endTimeAttrs,
                label: 'End Time',
                placeholder: 'Click to select the auction end time',
                minPickableTime: _vm.minPickableEndTime,
                returnSelectedTime: _vm.getSelectedTime,
                initialValue: _vm.initialEndTimeUpdated ? _vm.initialEndTimeUpdated : null,
                timepicker: _vm.timepicker,
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }