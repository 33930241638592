var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AutocompleteInput',_vm._b({ref:"autocomplete",staticClass:"autocomplete",on:{"select":_vm.emitSelectedAddress,"input":function($event){_vm.addressUpdated = $event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.loadingAutocompleteOptions)?_c('p',[_vm._v("Loading...")]):_c('p',[_vm._v("No results")])]},proxy:true}])},'AutocompleteInput',{
        value: _vm.selectedOption,
        data: _vm.autocompleteOptions,
        placeholder: 'Start typing address...',
        icon: 'magnify',
        clearable: true,
        openOnFocus: true,
        field: 'address',
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }