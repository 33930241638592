
import { computed, ComputedRef, defineComponent, onMounted, PropType, reactive, Ref, ref, watch } from 'vue';
import { saveEditedVehicle } from '../api';
import { VehicleConditions } from '../enums';
import { AdminVehicleDetailsDTO, VehicleCondition, VehicleStatus } from '../types';
import { getEnumArray, openToast } from '../utils';

import AppInputFieldNumber from './AppInputFieldNumber.vue';
import AppInputFieldString from './AppInputFieldString.vue';
import AutocompleteInput from './AutocompleteInput.vue';
import Modal from './Modal.vue';

export type EditVehicleForm = {
    year?: string,
    make?: string,
    model?: string,
    trim?: string,
    vin?: string,
    color?: string,
    condition?: VehicleCondition,
    mileage?: number,
}

export default defineComponent({
    name: 'TheEditVehicleModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: false,
        },
        vehicleDetails: {
            type: Object as PropType<AdminVehicleDetailsDTO>,
            default: () => { return {} },
        },
    },
    components: {
        AppInputFieldNumber,
        AppInputFieldString,
        AutocompleteInput,
        Modal,
    },
    emits: ['close'],
    setup(props, { emit }) {
        const { year, make, model, trim, vin, color, condition, mileage } = props.vehicleDetails;
        const editVehicleForm = reactive({ year, make, model, trim, vin, color, condition, mileage });

        const vehicleConditionOptions = getEnumArray(VehicleConditions, (value) => { return { value, id: VehicleConditions[value]} });

        const loadingSaveVehicle: Ref<boolean> = ref(false);
        async function saveVehicle() {
            loadingSaveVehicle.value = true;
            await saveEditedVehicle(props.vehicleListingId, editVehicleForm, {
                onSuccess: () => {
                    loadingSaveVehicle.value = false;
                    openToast('is-success', 'Vehicle updated!');
                    emit('close');
                },
                onError: () => loadingSaveVehicle.value = false,
            });
        }

        const disableConfirm: ComputedRef<boolean> = computed(() => {
            return Object.keys(editVehicleForm).some(field => editVehicleForm[field] == undefined);
        });

        return {
            editVehicleForm,
            vehicleConditionOptions,
            saveVehicle,
            loadingSaveVehicle,
            disableConfirm,
        }
    },
});
