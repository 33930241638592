var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheListingDetailsCard',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex-row align-center"},[(_vm.numAnnouncements)?_c('b-icon',{staticClass:"mr-1",class:_vm.getMostSevereIconColor,attrs:{"icon":"alert-circle","custom-size":"mdi-24px"}}):_vm._e(),_c('h2',[_vm._v("Announcements")]),(_vm.numAnnouncements)?_c('span',{staticClass:"ml-1",staticStyle:{"font-weight":"400"}},[_vm._v("("+_vm._s(_vm.numAnnouncements)+")")]):_vm._e()],1)]},proxy:true},{key:"empty",fn:function(){return [_vm._v("No announcements")]},proxy:true},{key:"body",fn:function(){return [_vm._t("announcements",function(){return [_vm._t("announcementsTop"),_c('TheVehicleListingAnnouncements',_vm._b({key:`announcements-${_vm.$attrs.loading}`,on:{"viewPhoto":function($event){return _vm.$emit('viewPhoto', $event)},"edit":function($event){return _vm.$emit('edit', $event)},"delete":function($event){return _vm.$emit('delete', $event)}},scopedSlots:_vm._u([{key:"cardHeaderButton",fn:function(){return [_vm._t("cardHeaderButton")]},proxy:true}],null,true)},'TheVehicleListingAnnouncements',{
                    ..._vm.$props,
                    cardAttrs: {
                        open: false,
                        ...(_vm.$attrs.cardAttrs ?? {}),
                    },
                    unknownCardAttrs: _vm.$attrs.unknownCardAttrs,
                },false))]},null,{ getIconColorByAnnouncementType: _vm.getIconColorByAnnouncementType })]},proxy:true}],null,true)},'TheListingDetailsCard',{
    ..._vm.$attrs
},false))
}
var staticRenderFns = []

export { render, staticRenderFns }