<template>
    <div>
        <h1 class="count-number is-poppins" :class="`${numberSize} ${numberColor}`">{{ number }}</h1>
        <p class="count-label" :class="`${labelSize} ${labelColor}`">
            {{ makePluralIfPlural(label, number, false) }}
        </p>
    </div>
</template>

<script>
import { makePluralIfPlural } from '../utils';

export default {
    name: 'CountAndLabel',
    props: {
        number: {
            type: [Number, String],
            default: 1,
        },
        label: {
            type: String,
            default: 'car'
        },
        numberSize: {
            type: String,
            default: 'is-size-3 is-size-3-mobile',
        },
        labelSize: {
            type: String,
            default: 'is-size-5 is-size-6-mobile',
        },
        numberColor: {
            type: String,
            default: 'has-text-primary'
        },
        labelColor: {
            type: String,
            default: 'has-text-dark',
        }
    },
    methods: {
        makePluralIfPlural,
    },
}
</script>

<style scoped lang='postcss'>
.count-label {
    margin-top: -0.5rem;
}
</style>