var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"close":function($event){return _vm.$emit('close')},"confirm":function($event){_vm.$emit('submit', _vm.selectedPhotos); _vm.$emit('close')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ThePhotoGallery',_vm._b({attrs:{"selectedPhotos":_vm.selectedPhotos},on:{"update:selectedPhotos":function($event){_vm.selectedPhotos=$event},"update:selected-photos":function($event){_vm.selectedPhotos=$event}},scopedSlots:_vm._u([{key:"caption",fn:function({ photo, isSelected }){return [(photo.announcement)?_c('TheAnnouncement',_vm._b({staticClass:"photo-announcement position-absolute has-background-white has-text-dark"},'TheAnnouncement',{
                        announcement: photo.announcement,
                        isClickable: false,
                        iconAttrs: {
                            icon: isSelected ? 'close' : 'check-circle',
                        },
                    },false)):_vm._e()]}}])},'ThePhotoGallery',{
                photos: _vm.$props.photos,
                name: 'modal-gallery',
                ..._vm.$attrs,
            },false))]},proxy:true}])},'Modal',{
        customLoading: true,
        confirmButtonAttrs: {
            label: _vm.replacingAnnouncement ? 'Replace' : 'Confirm',
        },
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }