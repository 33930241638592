
import { defineComponent, Ref, ref } from 'vue';
import { addAddressToProfileByEmail } from '../api';
import { openToast } from '../utils';

import TheAddressAutocompleteInput from './TheAddressAutocompleteInput.vue';
import Modal from './Modal.vue';

export default defineComponent({
  name: 'TheAddAddressModal',
  props: {
    customConfirm: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      required: false,
    },
  },
  components: {
    TheAddressAutocompleteInput,
    Modal,
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const selectedAddress: Ref<string | undefined> = ref(undefined);
    const addressPlaceId: Ref<string | undefined> = ref(undefined);

    const loadingSaveAddress: Ref<boolean> = ref(false);
    async function saveAddress() {
      if (!props.email || !selectedAddress.value || !addressPlaceId.value) {
        return;
      }
      loadingSaveAddress.value = true;
      addAddressToProfileByEmail({ email: props.email, address: selectedAddress.value, placeId: addressPlaceId.value }, {
        onSuccess: () => {
          loadingSaveAddress.value = false;
          openToast('is-success', 'Address saved!');
          emit('close');
        },
        onError: () => loadingSaveAddress.value = false,
      });
    }

    return {
      selectedAddress,
      addressPlaceId,
      saveAddress,
      loadingSaveAddress,
    }
  }
});
