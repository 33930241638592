
import { defineComponent, PropType, ref } from 'vue';

import AppInputFieldString from './AppInputFieldString.vue';

export default defineComponent({
    name: 'TheEditLocationInputs',
    props: {
        zipCode: {
            type: String,
            required: false,
        },
        city: {
            type: String,
            required: false,
        },
        state: {
            type: String,
            required: false,
        }
    },
    components: {
        AppInputFieldString,
    },
    setup(props, { emit, attrs }) {
        const zipCodeUpdated = ref(props.zipCode);
        const cityUpdated = ref(props.city);
        const stateUpdated = ref(props.state);
        return {
            zipCodeUpdated,
            cityUpdated,
            stateUpdated,
        }
    },
});
