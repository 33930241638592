import { clearAllFilters, hasRole, mergeJSONs } from '@/utils';
import VuexPersistence from 'vuex-persist';
import Vue from 'vue';
import Vuex from 'vuex';
import type { FixMe, VehiclePriceInfo, Transportation, VehicleFilter, AnnouncementCategory, AnnouncementSubcategory } from '@/types';
import { myBasicInfoType } from './types/myBasicInfoType';
import { VehicleDTO } from './types/VehicleDTO';
import type { User } from './composables/user';

interface TemporaryVehicleProps {
  id: string
  trimStyle: string
  fullVin: string
  auctionExpectation: number
  selectedFeatures: string[]
}

type Vehicle = VehicleDTO & TemporaryVehicleProps

interface State {
  lastPath: string;
  myNewCar: Vehicle;
  myNewCarTemp: Vehicle;
  user: User;
  notificationIdsUnreadOnLogin?: number[];
  notificationIdsRead: number[];
  notificationIdsDisplayed: number[],
  actionRequiredCounts: {
    checkout?: number,
    negotiation?: number,
  },
  filters?: VehicleFilter,
  filterPreset?: VehicleFilter,
  filterPresetId?: number,
  listingFilters: string[];
  marketplaceFilterFields?: string[],
  marketplaceFilters?: VehicleFilter,
  marketplaceFilterPreset?: VehicleFilter,
  marketplaceFilterPresetId?: number,
  updateMyId: string
  referralCode: string
  testingGroup: string
  inspector: Record<string, FixMe>
  zip: string
  // TODO: Add default values to props below
  isCarmigoDirect?: boolean
  branding?: FixMe
  syncedServerDifferenceInMilliseconds?: number
  origin?: {
    publicId: string;
    name: string;
  },
  vehiclePriceInfo: Partial<VehiclePriceInfo>
  basicInfo: myBasicInfoType,
  needACH: boolean,
  transportation: Transportation
  askingPriceInfo: {
    originalAskingPrice: number;
    askingPriceId?: number;
    askingPriceReasonDescription?: string;
    competingOffererId?: number;
    competingOffererAmount?: number;
    payoffAmount?: number;
  }
  // Fastbook VIN Finder
  isFastBookExtensionInstalled: boolean
  fastBookBookingPanel: {
    isOpen: boolean;
    selectedVin: string;
  },
  // announcement categories
  announcementCategories?: AnnouncementCategory[],
  announcementSubcategories: { [key: string]: AnnouncementSubcategory[] },
}

Vue.use(Vuex);

const vuexPersistence = new VuexPersistence({
  key: 'carmunity-web-app',
  storage: window.localStorage,
  reducer(state: State) {
    // We return only the data we want to persist.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { fastBookBookingPanel, ...stateToBePersisted } = state;
    return stateToBePersisted;
  },
});

export default new Vuex.Store<State>({
  state: {
    lastPath: '',
    myNewCar: {} as Vehicle,
    myNewCarTemp: {} as Vehicle,
    user: {} as User,
    notificationIdsUnreadOnLogin: [] as number[],
    notificationIdsRead: [] as number[],
    notificationIdsDisplayed: [] as number[], // we separately track displayed vs. read as of CMG-3295, in which we stopped marking pop-up notifications as 'read' on display
    actionRequiredCounts: {
      checkout: 0,
      negotiation: 0,
    },
    listingFilters: [],
    updateMyId: '',
    filters: {
      sellerType: [],
      sellerStore: [],
      year: [],
      mileage: [],
      make: [],
      model: [],
      trim: [],
      bodyType: [],
      distance: 0,
      price: [],
      fuelType: [],
      zip: '',
      watching: false,
      carmigoInspected: false,
    },
    filterPreset: undefined,
    filterPresetId: undefined,
    marketplaceFilterFields: undefined,
    marketplaceFilters: {
      sellerType: [],
      sellerStore: [],
      year: [],
      mileage: [],
      make: [],
      model: [],
      trim: [],
      bodyType: [],
      distance: 0,
      price: [],
      fuelType: [],
      zip: '',
      watching: false,
      carmigoInspected: false,
    },
    marketplaceFilterPreset: undefined,
    marketplaceFilterPresetId: undefined,
    referralCode: '',
    testingGroup: '',
    inspector: {},
    zip: '',
    vehiclePriceInfo: {},
    basicInfo: {
      vehicleListing: {
        year: undefined,
        make: '',
        model: '',
        trim: '',
        heroImageUrl: '',
        expirationDate: undefined,
        vehicleListingId: undefined,
      },
    },
    needACH: false,
    transportation: {
      zip: '',
    },
    askingPriceInfo: {
      originalAskingPrice: 0,
    },
    isFastBookExtensionInstalled: false,
    fastBookBookingPanel: {
      isOpen: false,
      selectedVin: '',
    },
    announcementCategories: undefined,
    announcementSubcategories: {},
  },

  getters: {
    getPersonId: (state) => state.user?.profile?.id,
    getTransportationZip: (state) => state.transportation?.zip,
    getStores: (state) => (state.user?.profile?.stores ? state.user.profile.stores : []),
    getStoreIds: (state) => {
      const stores = state.user?.profile?.stores ? state.user.profile.stores : [];
      if (stores.length) {
        return stores.map(store => store.id);
      }
      return [];
    },
    isCarmigoDirect: () => {
      const allData = JSON.parse(localStorage.getItem('carmunity-web-app') as string);
      if (!allData) {
        return false;
      }
      return allData.isCarmigoDirect;
    },
    branding: () => {
      const allData = JSON.parse(localStorage.getItem('carmunity-web-app') as string);
      if (!allData) {
        return {};
      }
      return allData.branding;
    },
    localStorage: () => JSON.parse(localStorage.getItem('carmunity-web-app') as string),
    getVehicle: () => () => {
      const allData = JSON.parse(localStorage.getItem('carmunity-web-app') as string);
      if (!allData) {
        return {};
      }
      return allData.myNewCar;
    },
    user: () => {
      const allData = JSON.parse(localStorage.getItem('carmunity-web-app') as string);
      if (!allData) {
        return {} as User;
      }
      const { user } = allData;
      return user as User;
    },
    lastPath: (state) => state.lastPath,
    vehicle: () => {
      const allData = JSON.parse(localStorage.getItem('carmunity-web-app') as string);
      if (!allData) {
        return {};
      }
      return allData.myNewCar;
    },
    basicInfo: () => {
      const allData = JSON.parse(localStorage.getItem('carmunity-web-app') as string);
      if (!allData) {
        return {};
      }

      return allData.basicInfo;
    },
    isAuthenticated: (state) => Object.keys(state.user).includes('profile'),
    isUserAdmin: (state) => hasRole(state.user, 'admin'),
    isUserStoreManager: (state) => hasRole(state.user, 'storeManager'),
    isUserInspector: (state) => hasRole(state.user, 'inspector'),
    isUserSeller: (state) => hasRole(state.user, 'seller'),
    isUserBuyer: (state) => hasRole(state.user, 'buyer'),
    isUserCarmigoDirect: (state) => hasRole(state.user, 'carmigoDirect'),
    isUserImposter: (state) => hasRole(state.user, 'imposter'),
    isUserWholesaler: (state) => hasRole(state.user, 'wholesaler'),
    isUserDsr: (state) => hasRole(state.user, 'dsr'),
    filters: (state) => state.filters,

    getBuyerActionRequiredCount: (state) => {
      return (state.actionRequiredCounts?.checkout ?? 0) + (state.actionRequiredCounts?.negotiation ?? 0);
    },
  },

  mutations: {
    updateTransportation(state, payload) {
      state.transportation= mergeJSONs(state.transportation, payload);
    },
    SET_NEED_ACH(state, payload) {
      state.needACH = payload;
    },
    SET_REFERRAL_CODE(state, payload: string) {
      state.referralCode = payload;
    },
    isCarmigoDirect(state, payload) {
      state.isCarmigoDirect = payload;
    },
    updateBranding(state, payload) {
      state.branding = payload;
    },
    updateMyLastPath(state, fullPath) {
      state.lastPath = fullPath;
    },
    updateMyNewCar(state, payload) {
      state.myNewCar = mergeJSONs(state.myNewCar, payload);
    },
    updateMyNewCarTemp(state, payload) {
      state.myNewCarTemp = mergeJSONs(state.myNewCarTemp, payload);
    },
    updateOrigin(state, payload) {
      state.origin = payload;
    },
    resetMyNewCar(state) {
      state.myNewCar = {} as Vehicle;
      localStorage.removeItem('carmunity-web-app');
    },
    updateStores(state,payload) {
      state.user.profile.stores = payload;
    },
    resetMyNewCarTemp(state) {
      state.myNewCarTemp = {} as Vehicle;
    },
    login(state, payload) {
      state.user = payload;
    },
    setNotificationIdsUnreadOnLogin(state, payload) {
      state.notificationIdsUnreadOnLogin = payload;
    },
    addReadNotificationIds(state, payload: number[]) {
      state.notificationIdsRead = state.notificationIdsRead?.concat(payload);
    },
    addDisplayedNotificationId(state, payload) {
      state.notificationIdsDisplayed.push(payload);
    },
    clearReadNotificationIds(state) {
      state.notificationIdsRead = [];
    },
    setActionRequiredCounts(state, payload) {
      state.actionRequiredCounts = {
        ...state.actionRequiredCounts,
        ...payload,
      }
    },
    logout(state) {
      localStorage.removeItem('carmunity-web-app');
      state.user = {} as User;
    },
    refreshToken(state, payload: {accessToken: string, refreshToken: string}) {
      const sts = state.user.stsTokenManager;

      localStorage.setItem('accessToken', payload.accessToken);
      sts.accessToken = payload.accessToken;
      sts.refreshToken = payload.refreshToken;
    },
    removeStashedToken(state) {
      state.user.stsTokenManager.stashedRefreshToken = '';
    },
    resetTokensWithStash(state) {
      state.user.stsTokenManager.refreshToken = state.user.stsTokenManager.stashedRefreshToken;
      state.user.stsTokenManager.stashedRefreshToken = '';
      localStorage.removeItem('accessToken');
    },
    stashRefreshToken(state) {
      state.user.stsTokenManager.stashedRefreshToken = state.user.stsTokenManager.refreshToken;
    },
    updateListingsFilters(state, payload) {
      state.listingFilters = payload;
    },
    updateFilterPreset(state, payload) {
      state.filterPreset = payload;
    },
    updateFilterPresetId(state, payload) {
      state.filterPresetId = payload;
    },
    resetFilters(state, payload) {
      state.filters = clearAllFilters(state.filters);
      state.listingFilters = [];
    },
    resetFilterPresets(state, payload) {
      state.filterPreset = undefined;
      state.filterPresetId = undefined;
    },
    resetListingsFilters(state) {
      state.listingFilters = [];
    },
    addFilter(state, payload) {
      state.filters = payload;
    },
    addFilterToMarketplaceFilters(state, payload) {
      state.marketplaceFilters = payload;
    },
    updateMarketplaceFilters(state, payload) {
      state.marketplaceFilters = payload;
    },
    updateMarketplaceFilterFields(state, payload) {
      state.marketplaceFilterFields = payload;
    },
    updateMarketplaceFilterPreset(state, payload) {
      state.marketplaceFilterPreset = payload;
    },
    updateMarketplaceFilterPresetId(state, payload) {
      state.marketplaceFilterPresetId = payload;
    },
    resetMarketplaceFilters(state, payload) {
      state.marketplaceFilters = clearAllFilters(state.marketplaceFilters);
      state.marketplaceFilterFields = undefined;
    },
    resetMarketplaceFilterPresets(state, payload) {
      state.marketplaceFilterPreset = undefined;
      state.marketplaceFilterPresetId = undefined;
    },
    updateZip(state, payload) {
      state.zip = payload;
    },
    updateMyId(state, payload) {
      state.updateMyId = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setVehicle(state, payload) {
      state.myNewCar = payload;
    },
    setVehicleTemp(state, payload) {
      state.myNewCarTemp = payload;
    },
    setTestingGroup(state, payload) {
      state.testingGroup = payload;
    },
    saveInspector(state, payload) {
      state.inspector = payload;
    },
    updateMyDwollaURL(state, payload) {
      state.user.profile.dwollaCustomerURL = payload;
    },
    updateMyDwollaStatus(state, payload) {
      state.user.profile.dwollaStatus = payload;
    },
    setServerTimeDifference(state, milliseconds) {
      state.syncedServerDifferenceInMilliseconds = milliseconds;
    },
    resetOriginPublicId(state) {
      delete state.origin;
    },
    resetReferralCode(state) {
      state.referralCode = '';
    },
    setVehiclePriceInfo(state, payload: Partial<VehiclePriceInfo>) {
      state.vehiclePriceInfo = payload;
    },
    resetVehiclePriceInfo(state) {
      state.vehiclePriceInfo = {};
    },
    updateSelectedFilters(state, payload) {
      state.filters = payload;
    },
    resetSelectedFilters(state, payload) {
      state.filters = payload;
    },
    updateBasicInfo(state, payload) {
      state.basicInfo = payload;
    },
    updateBasicInfoVehicleListing(state, payload) {
      state.basicInfo.vehicleListing = payload;
    },
    resetBasicInfo(state) {
      state.basicInfo = {} as myBasicInfoType;
    },
    setRequestPriceInfo(state, payload: Partial<State['askingPriceInfo']>) {
      state.askingPriceInfo = {
        ...state.askingPriceInfo,
        ...payload,
      };
    },
    resetRequestPriceInfo(state) {
      state.askingPriceInfo = {
        originalAskingPrice: 0,
      }
    },
    setIsFastBookExtensionInstalled(state, payload) {
      state.isFastBookExtensionInstalled = payload;
    },
    setFastBookBookingPanel(state, payload) {
      state.fastBookBookingPanel = {
        ...state.fastBookBookingPanel,
        ...payload,
      };
    },
    updateAnnouncementCategories(state, payload) {
      state.announcementCategories = payload;
    },
    updateAnnouncementSubcategories(state, payload: { category: string, subcategories: AnnouncementSubcategory[] }) {
      state.announcementSubcategories[payload.category] = payload.subcategories;
    },
  },

  actions: {},

  plugins: [vuexPersistence.plugin],
});
