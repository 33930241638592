var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppButton',_vm._b({class:{
        'no-border underline no-background': _vm.noBorder,
    },on:{"click":function($event){return _vm.$emit('click', _vm.isPending)}}},'AppButton',{
        label: _vm.getButtonLabel,
        type: _vm.getButtonType,
        disabled: _vm.isPending && _vm.disablePending,
        expanded: true,
        ..._vm.$attrs
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }