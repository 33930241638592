import { assignStorePointOfContact, getArbitrationRateBySellerStoreId, getSellRateBySellerStoreId, getStoreById, getTimeToTitleBySellerStoreId } from '@/api';
import { addStore, updateStore } from '@/api/company';
import { APIConfig, StoresDTO, TimeDTO } from '@/types';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, openAssignPointOfContactConfirmationDialog, openToast } from '@/utils';
import { validateText } from '@/validation';
import { pick } from 'lodash';
import { computed, onMounted, reactive, Ref, ref } from 'vue';

export function useStorePointOfContact() {
    const loadingAssignPointOfContactStoreId: Ref<number | null> = ref(null);
    const loadingAssignPointOfContactUserId: Ref<number | null> = ref(null);

    function openPointOfContactConfirmationDialog({ storeName, storeId, employee }: { 
        storeName: string, 
        storeId: number, 
        employee: {
            userId: number, 
            firstName: string, 
            lastName: string 
        },
    }, config: APIConfig={}) {
        openAssignPointOfContactConfirmationDialog({ storeName, storeId, ...employee }, {
            onConfirm: () => {
                loadingAssignPointOfContactUserId.value = employee.userId;
                loadingAssignPointOfContactStoreId.value = storeId;
                assignStorePointOfContact({ storeId, userId: employee.userId }, {
                    onSuccess: (res) => {
                        loadingAssignPointOfContactUserId.value = null;
                        loadingAssignPointOfContactStoreId.value = null;
                        applyAPIConfigOnSuccess(res, config);
                    },
                    onError: (error) => {
                        loadingAssignPointOfContactUserId.value = null;
                        loadingAssignPointOfContactStoreId.value = null;
                        applyAPIConfigOnError(error as Error, config);
                    },
                })
            }
        });
    }

    return {
        loadingAssignPointOfContactStoreId,
        loadingAssignPointOfContactUserId,
        openPointOfContactConfirmationDialog,
    }
}

export function useCreateOrEditStore({ storeId, companyId, emit }: {
    storeId?: number,
    companyId?: number,
    emit: (event: 'close' | 'saved', ...args: any[]) => void,
}) {
    const store: Ref<Partial<StoresDTO> | undefined> = ref(undefined);
    const loadingStore = ref(false);

    onMounted(async() => {
        if (!storeId) {
            return;
        }
        loadingStore.value = true;
        await getStoreById(storeId, {
            onSuccess: (storeResult) => {
                store.value = pick(storeResult, [
                    'companyId',
                    'name',
                    'address',
                    'city',
                    'state',
                    'zip',
                    'dealerLicense',
                    'taxIdNumber',
                    'auctionAccessNumber',
                    'floorPlanProvider',
                    'floorPlanAccountNumber',
                    'marketId',
                    'dsrPersonId',
                    'accountManagerPersonId',
                    'officeManagerFirstName',
                    'officeManagerLastName',
                    'officeManagerEmail',
                    'officeManagerPhoneNumber',
                    'titleClerkFirstName',
                    'titleClerkLastName',
                    'titleClerkEmail',
                    'titleClerkPhoneNumber',
                    'titleStreet',
                    'titleCity',
                    'titleState',
                    'titleZip',
                    'transportStreet',
                    'transportCity',
                    'transportState',
                    'transportZip',
                    'canBid',
                ]) as Partial<StoresDTO>;

                store.value.emails = storeResult.ccEmails ?? [];
                loadingStore.value = false;
            },
            onError: () => loadingStore.value = false,
        });
    });

    function saveStorePreprocessing() {
        if (store.value?.emails) {
            store.value.emails = store.value.emails.filter(value => Boolean(value));
        }
    }

    const loadingSaveStore = ref(false);
    async function createStore() {
        if (!companyId) {
            return;
        }
        loadingSaveStore.value = true;
        saveStorePreprocessing();
        await addStore({ ...store.value!, companyId: companyId.toString() } as StoresDTO, {
            onSuccess: () => {
                loadingSaveStore.value = false;
                openToast('is-success', `Created ${store.value!.name}!`);
                emit('saved', store.value);
                emit('close');
            },
            onError: () => {
                loadingSaveStore.value = false;
            }
        });
    }

    async function saveEditedStore() {
        if (!storeId) {
            return;
        }
        loadingSaveStore.value = true;
        saveStorePreprocessing();
        await updateStore(storeId, store.value!, {
            onSuccess: () => {
                loadingSaveStore.value = false;
                openToast('is-success', `Updated ${store.value!.name}`);
                emit('saved', store.value);
                emit('close');
            }, 
            onError: () => {
                loadingSaveStore.value = false;
            }
        });
    }

    const isValidStore = computed(() => {
        return validateText(store.value?.name)
            && validateText(store.value?.address)
            && validateText(store.value?.city)
            && validateText(store.value?.state) 
            && validateText(store.value?.zip)
            && validateText(store.value?.dealerLicense)
            && validateText(store.value?.titleStreet)
            && validateText(store.value?.titleCity)
            && validateText(store.value?.titleState)
            && validateText(store.value?.titleZip)
            && validateText(store.value?.transportStreet)
            && validateText(store.value?.transportCity)
            && validateText(store.value?.transportState)
            && validateText(store.value?.transportZip);
    });

    return {
        store,
        loadingStore,
        loadingSaveStore,
        createStore,
        isValidStore,
        saveEditedStore,
    }
}

export function useStoreStats() {
    const storeStats: {
        arbitrationRate?: number,
        sellRate?: number,
        timeToTitleInDays?: TimeDTO,
    } = reactive({
        arbitrationRate: undefined,
        sellRate: undefined,
        timeToTitleInDays: undefined,
    });

    const loadingArbitrationRate: Ref<boolean> = ref(false);
    const loadingSellRate: Ref<boolean> = ref(false);
    const loadingTimeToTitle: Ref<boolean> = ref(false);

    async function getStoreArbitrationRate(storeId: number, config: APIConfig={}) {
        loadingArbitrationRate.value = true;
        await getArbitrationRateBySellerStoreId(storeId, {
            ...config,
            onSuccess: (res) => {
                storeStats.arbitrationRate = res;
                loadingArbitrationRate.value = false;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: (error) => {
                loadingArbitrationRate.value = false;
                applyAPIConfigOnError(error, config);
            },
        });
    }
    async function getStoreSellRate(storeId: number, config: APIConfig={}) {
        loadingSellRate.value = true;
        await getSellRateBySellerStoreId(storeId, {
            ...config,
            onSuccess: (res) => {
                storeStats.sellRate = res;
                loadingSellRate.value = false;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: (error) => {
                loadingSellRate.value = false;
                applyAPIConfigOnError(error, config);
            },
        });
    }
    async function getStoreTimeToTitle(storeId: number, config: APIConfig={}) {
        loadingTimeToTitle.value = true;
        await getTimeToTitleBySellerStoreId(storeId, {
            ...config,
            onSuccess: (res) => {
                storeStats.timeToTitleInDays = res;
                loadingTimeToTitle.value = false;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: (error) => {
                loadingTimeToTitle.value = false;
                applyAPIConfigOnError(error, config);
            },
        });
    }

    return {
        storeStats,
        loadingArbitrationRate,
        loadingSellRate,
        loadingTimeToTitle,
        getStoreSellRate,
        getStoreArbitrationRate,
    }
}