import { openErrorDialog } from "@/utils";
import { PUT } from ".";

export async function updateMarketDaySupply(payload: { inventoryId: number, mds: number }, config: {
    customErrorHandling?: boolean,
}={}) {
    return await PUT(`/dealerInventory/updateMarketDaySupply`, payload)
        .then(res => res.data)
        .catch(error => { 
            if (config?.customErrorHandling) {
                throw error;
            }
            openErrorDialog({
                title: 'Failed to update MDS',
                message: `We were unable to update the market day supply (MDS) for inventory ${payload.inventoryId}. MDS value: ${payload.mds}`,
                error,
            });
    });
}
